import {
  addMonths,
  endOfMonth,
  endOfYear,
  format,
  startOfMonth,
  startOfYear,
  subMonths,
  subYears,
} from 'date-fns';
import { Chart } from 'react-chartjs-2';
import _ from 'lodash';
import { isAuthorized } from '../../app/userImpersonate';
import { toast } from 'react-toastify';
import { convertToDate, formatDate, fullDateFormat, modifyDate } from '../../utilities/common';

export const CALENDAR = 'Calendar Year';
export const FINANCIAL = 'Financial Year';
let i18nTranslation;

export const setDefaultTranslation = (t) => {
  i18nTranslation = t;
};

export const transBackend = (message) =>
  _.inRange(message?.length, 2, 255) ? i18nTranslation(message) : message;

export const transCsvHeader = (csvContent) => {
  const csvRows = csvContent?.split('\n');
  if (!csvRows?.length || !csvRows[0].includes(',')) {
    return false;
  }
  const csvHeader = csvRows[0].split(',')
    .map(title => transBackend(title?.trim()))
    .join(',');
  csvRows[0] = csvHeader;
  return csvRows.join('\n');
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const formatNumber = (num, defaultValue = 0, isCost = false) => {
  if (['', undefined, null].includes(num) || isNaN(num)) {
    return defaultValue;
  }
  const value = Math.abs(Number(num));
  const negative = Number(num) < 0 ? '-' : '';
  const getDecimal = () => {
    switch (true) {
      case value < 10:
        return 2;
      case value < 100 && !isCost:
        return 1;
      case value >= 10 && isCost:
      default:
        return 0;
    }
  };
  const formatted = value.toFixed(getDecimal()).split('.');
  const first = formatted[0].replace(/(.)(?=(\d{3})+$)/g, '$1,');
  const second = formatted[1] && !/^[0]+$/.test(formatted[1]) ? `.${formatted[1]}` : '';
  const result = `${negative}${first}${second}`;
  if (result === '0' && value !== 0) {
    return '0.00';
  }
  return result;
};

export const parseFavouriteData = (search, dateFields = []) => {
  try {
    const params = new URLSearchParams(search);
    const frName = params.get('frName');
    const frUsrId = params.get('frUsrId');
    const filterQuery = params.get('filter');
    const decodedData = filterQuery ? atob(decodeURIComponent(filterQuery)) : '""';
    const filterData = JSON.parse(decodedData);
    dateFields.forEach(field => {
      if (filterData[field]) {
        filterData[field] = convertToDate(filterData[field]);
      }
    });
    return { frName, frUsrId, filterData };
  } catch (err) {
    console.log(err);
    return {};
  }
};

export const getCommonFilterPeriodRange = (data, deaultPeriodRange = null) => {
  const filterPeriod = data ? data.frRepFilterPeriodParam : null;
  switch (filterPeriod) {
    case '1':
      return {
        startDate: convertToDate(data.startDate),
        endDate: convertToDate(data.endDate),
      };
    case '2':
      const prevMonthFirstDate = startOfMonth(subMonths(new Date(data.baseDate), 1));
      const prevMonthLastDate = endOfMonth(prevMonthFirstDate);
      return {
        startDate: prevMonthFirstDate,
        endDate: prevMonthLastDate
      };
    case '3':
      return {
        startDate: subMonths(new Date(data.baseDate), 1),
        endDate: new Date(data.baseDate),
      };
    case '4':
      return {
        startDate: subMonths(new Date(data.baseDate), 3),
        endDate: new Date(data.baseDate)
      };
    case '5':
      return {
        startDate: subYears(new Date(data.baseDate), 1),
        endDate: new Date(data.baseDate)
      };
    default:
      if (deaultPeriodRange) {
        return {
          startDate: new Date(deaultPeriodRange.startDate),
          endDate: new Date(deaultPeriodRange.endDate),
        };
      }
      return {
        startDate: new Date(new Date().getFullYear(), 0, 1),
        endDate: new Date(new Date().getFullYear(), 11, 31)
      };
  }
};

export const dateRangeFilterPeriodOptions = (filters, t, category, showDetail) => {
  const labelMap = {
    fixedRange : 'Fixed range',
    previousYear : 'Previous year',
  };
  if (showDetail) {
    switch (category) {
      case 'emissions-by-top-10-contributors':
      case 'emissions-by-top-10-entities':
      case 'emissions-by-top-10-eps':
      case 'emissions-top-10-type':
        labelMap.fixedRange = t(
          'Fixed range ( {{year}} - {{type}}s)',
          { year: filters.reportYear, type: t(filters.reportYearType) },
        );
        labelMap.previousYear = t('Previous year (Calendar Year)');
        break;
      case 'moving-average-report':
        labelMap.fixedRange = t('Fixed range ( - {{endDate}})', { endDate: formatDate(filters.endDate) });
        labelMap.previousYear = t('Previous year ({{year}})', { year: subYears(new Date(filters.endDate), 1).getFullYear() });
        break;
      default:
        labelMap.fixedRange = t('Fixed range ({{startDate}} - {{endDate}})', {
          startDate: formatDate(filters.startDate),
          endDate: formatDate(filters.endDate),
        });
        labelMap.previousYear = t('Previous year');
        break;
    }
  }
  return [
    {
      label: labelMap.fixedRange,
      value: '1'
    },
    { label: t('Previous calendar month'), value: '2' },
    { label: t('Previous month'), value: '3' },
    { label: t('Previous quarter'), value: '4' },
    { label: labelMap.previousYear, value: '5' }
  ];
};

export const getWeeklyDateOptions = (t) => ([
  { label: t('Monday'), value: 1 },
  { label: t('Tuesday'), value: 2 },
  { label: t('Wednesday'), value: 3 },
  { label: t('Thursday'), value: 4 },
  { label: t('Friday'), value: 5 },
  { label: t('Saturday'), value: 6 },
  { label: t('Sunday'), value: 7 },
]);

export const parseDateRangeValue = (favReports, frId, t, showDateRange = false) => {
  const favReport = favReports.find((item) => frId === item.frId);
  if (favReport) {
    const filterParams = JSON.parse(favReport.frRepParamJson);
    const dateRangeOptions = dateRangeFilterPeriodOptions(filterParams, t);
    const target = dateRangeOptions.find(item => item.value === filterParams.frRepFilterPeriodParam);
    if (target?.value === '1' && showDateRange === false) {
      return t('Fixed range');
    }
    return target?.label || '';
  }
  return '';
};

export const getSendingIntervalOptions = (t) => ([
  {
    label: t('Weekly'),
    value: "WEEKLY",
  },
  {
    label: t('Monthly'),
    value: "MONTHLY",
  },
  {
    label: t('Quarterly'),
    value: "QUARTERLY",
  },
  {
    label: t('Yearly'),
    value: "YEARLY",
  },
]);

export const customFilterPeriodOptions = (filters, t, category, showDetail) =>
  dateRangeFilterPeriodOptions(filters, t, 'emissions-top-10-type', showDetail)
    .filter(item => ['1', '5'].includes(item.value));

export const getIntervalTypeOptions = (t) => ([
  { label: t('Annual'), value: 'annual' },
  { label: t('Monthly'), value: 'monthly' },
  { label: t('Quarterly'), value: 'quarterly' }
]);

export const getEmissionTypeOptions = (t) => ([
  { label: t('CO2-e (t)'), value: '1' },
  { label: t('Usage'), value: '2' }
]);

export const reportUnitByTypeId = (type, t) => {
  if (type === 1) {
    return { unit: 'tCO2e', title: t('CO2-e (t)') };
  }
  return { unit: 'mixed', title: t('Usage') };
};

export const parseUnitJson = (jsonData, currentUnits) => {
  if (!jsonData) {
    return [];
  }
  const getListUnits = (text) => {
    const data = JSON.parse(text);
    if (data && data[0] && data[0].includes('["')) {
      return getListUnits(data[0]);
    }
    return data;
  };
  const listUnits = getListUnits(jsonData);
  if (!currentUnits) {
    return _.uniq(listUnits);
  }
  listUnits.forEach((current) => {
    if (!currentUnits.find((last) => last === current)) {
      currentUnits.push(current);
    }
  });
};

const displayUnitsMap = {
  CUBIC_METERS: 'm³', //²
  CUBIC_FEET: 'ft³',
  DOLLARS: '$',
  GIGA_JOULES: 'Gj',
  GALLONS: 'gal',
  KILOWATTHOURS: 'kWh',
  KILO_WATT_HOURS: 'kWh',
  TONNES_KILO_METERS: 'tkm',
  KILO_METERS: 'km',
  KILO_LITERS: 'kl',
  KILO_LITRES: 'kl',
  LITRES: 'l'
};

export const displayTargetUnit = (listUnits, defaultUnit, isMultiDepth) => {
  if (!listUnits || !listUnits.length || listUnits.every(item => _.isEmpty(item))) {
    return defaultUnit;
  }
  const displayUnitHandler = (units) =>
    units
      .filter((unit) => !!unit)
      .map((item) => {
        if (displayUnitsMap[item]) {
          return displayUnitsMap[item];
        }
        return item.replace(/_/g, ' ');
      });
  if (isMultiDepth) {
    const parsedUnits = listUnits.reduce((acc, units) => acc.concat(units), []);
    return displayUnitHandler(_.uniq(parsedUnits));
  }
  return displayUnitHandler(listUnits);
};

export const reportYearList = () => {
  const currentYear = new Date().getFullYear();
  const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);
  const yearList = range(currentYear, currentYear - 16, -1);
  const reportYears = [];
  yearList.map((item) => {
    return reportYears.push({
      label: item,
      value: item
    });
  });
  return reportYears;
};

let colorIndex = 0;
const colorMaps = [
  "#f99e1b", "#0f8b43", "#41c5e7", "#b21f29", "#b7cd15", "#4a481d", "#b084bb",
  "#ffed7b", "#95e0d7", "#d84871", "#657734", "#216aa8", "#d21f2a", "#633f73",
  "#a8e0f9", "#fd5802", "#47ac87", "#005362", "#972068", "#ff7975",
];
const randomColor = () => {
  const threshold = 50;
  const parts = Array.from(Array(3), (_) => Math.floor(Math.random() * 256)).sort((a, b) => a - b);
  if (parts[2] - parts[0] < threshold) {
    const exclude = Math.min(255, parts[0] + threshold) - Math.max(0, parts[2] - threshold);
    parts[1] = Math.floor(Math.random() * (256 - exclude));
    if (parts[1] >= parts[2] - threshold) parts[1] += exclude;
  }
  parts
    .sort((a, b) => Math.random() < 0.5)
    .map((p) => ('0' + p.toString(16)).substr(-2))
    .join('');
  let color = '#' + parts.map((p) => ('0' + p.toString(16)).substr(-2)).join('');
  return color;
};
export const generateRandomColors = (isReset = false) => {
  if (isReset === true) {
    colorIndex = 0;
    return;
  }
  if (colorMaps[colorIndex]) {
    return colorMaps[colorIndex++];
  }
  return randomColor();
};

export const generateChartLegend = (chartRef, hiddenLegendIndex = null, setHiddenLegendsHandler = null) => {
  if (!chartRef.current) {
    return;
  }
  document.getElementById('js-legend').innerHTML = chartRef.current.chartInstance.generateLegend();
  const ci = chartRef.current.chartInstance;
  const datasets = ci.data.datasets;
  let legends = document.querySelectorAll('#js-legend ul li');
  [].map.call(legends, function (elem, elemIdx) {
    if (hiddenLegendIndex && elemIdx === hiddenLegendIndex) {
      elem.setAttribute("hidden", true);
    } else {
      if (setHiddenLegendsHandler) {
        elem.className = datasets[elemIdx].hidden ? 'strike' : '';
      }
      elem.addEventListener('click', function (e) {
        elem.classList.toggle('strike');
        const legendItem = ci.legend.legendItems.filter(
          (legendItem) => legendItem.text.replace(/\s+/g, ' ').trim() === elem.innerText
        );
        let index = legendItem[0].datasetIndex;

        let meta = ci.getDatasetMeta(index);
        meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;
        if (setHiddenLegendsHandler) {
          setHiddenLegendsHandler(index)
        }
        ci.update();
      });
    }
  });
};

export const createInitChartPlugins = (plugins, options = {}) => {
  const defaultPlugins = [
    {
      beforeDraw: function (chart) {
        const ctx = chart.ctx;
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, chart.width, chart.height);
        ctx.restore();
      },
      afterDatasetsDraw: (chartInstance) => {
        if (options?.displayChartAxesTotal && options.displayChartAxesTotal()) {
          const ctx = chartInstance.ctx;
          ctx.font = Chart.helpers.fontString(
            Chart.defaults.global.defaultFontSize,
            Chart.defaults.global.defaultFontStyle,
            Chart.defaults.global.defaultFontFamily
          );
          ctx.textAlign = 'left';
          ctx.textBaseline = 'bottom';
          ctx.fillStyle = 'black';
          ctx.fontWeight = 'bold';

          const totals = {};
          let atmost = 0;
          chartInstance.config.data.datasets.forEach((dataset, datasetIndex) => {
            if (chartInstance.isDatasetVisible(datasetIndex)) {
              atmost = datasetIndex;
              dataset.data.forEach((value, index) => {
                totals[index] = (totals[index] || 0) + value;
              });
            }
          });
          Object.keys(totals).map((key) => {
            const meta = chartInstance.controller.getDatasetMeta(atmost);
            const posX = meta.data[key]._model.x + 4;
            const posY = meta.data[key]._model.y;
            return ctx.fillText(formatNumber(totals[key]), posX, posY);
          });
        }
      }
    }
  ];
  return [
    ...defaultPlugins,
    ...plugins,
  ];
}

export const createInitChartConfig = (options) => {
  const commonConfigs = {
    indexAxis: 'y',
    maintainAspectRatio: false,
    legend: {
      display: false
    },
    tooltips: {
      mode: 'nearest',
      intersect: true,
      bodySpacing: 3,
      backgroundColor: '#000',
      filter: function (tooltipItem, data) {
        let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
        return value !== 0;
      },
      callbacks: {
      }
    },
    hover: {
      animationDuration: 0
    }
  };
  return _.merge(commonConfigs, options);
};

const translateReportUnits = (t, unitOptions) => unitOptions.map(({ label, options }) => ({
  label: label === 'other' ? t('Other previous input') : t(label),
  options,
}));

export const getReportUnitOptions = (t, reportUnits) => {
  const unitOptions = [];
  _.forOwn(reportUnits, (unitItems, cat) => {
    const unitOption = { label: cat, options: [] };
    _.forOwn(unitItems, (unitItem) => {
      unitOption.options.push({ label: unitItem.name, value: unitItem.shortName });
    });
    unitOptions.push(unitOption);
  });
  return translateReportUnits(t, unitOptions);
};

export const groupReportUnitOptions = (t, reportUnits, flatUnits) => {
  const unitOptions = [];
  flatUnits.forEach(unit => {
    _.forOwn(reportUnits, (unitItems, cat) => {
      _.forOwn(unitItems, (unitItem) => {
        const includeUnits = [...unitItem.aliasNames, unitItem.name, unitItem.shortName]
        if (includeUnits.includes(unit)) {
          const idx = unitOptions.findIndex(opt => opt.label === cat);
          if (idx !== -1) {
            unitOptions[idx].options.push({ label: unitItem.name, value: unit });
          } else {
            unitOptions.push({ label: cat, options: [{ label: unitItem.name, value: unit }] });
          }
        }
      });
    });
  });
  return translateReportUnits(t, unitOptions);
};

export const getCurrentReportPeriod = (usrOrgOptions) => {
  const { optReportPeriodType, optReportPeriodEnding } = usrOrgOptions || {};
  const now = new Date();
  let reportYear = now.getFullYear();
  const currentMonth = now.getMonth() + 1;
  if (optReportPeriodType && optReportPeriodEnding !== 12 && optReportPeriodEnding >= currentMonth) {
    reportYear -= 1;
  }
  return {
    reportYear,
    reportYearType: optReportPeriodType ? FINANCIAL : CALENDAR,
    reportPeriodEnding: optReportPeriodEnding,
  };
};

export const getReportYearTypeOptions = (t, rangePeriod) => {
  const options = [
    { label: t('Calendar Year'), value: CALENDAR },
  ];
  if (rangePeriod?.isReportPeriod) {
    const { startDate, endDate } = rangePeriod;
    options.push({
      value: FINANCIAL,
      label: t(
        'Financial Year ({{startYear}}-{{endYear}})',
        { startYear: format(startDate, 'MMM'), endYear: format(endDate, 'MMM') },
      ),
    });
  }
  options.push({
    value: 'SETUP',
    label: t('Change Report Period'),
  });
  return options;
};

export const setupReportRangePeriod = (history) => {
  if (isAuthorized(['cv_admin', 'cv_root'])) {
    history.push(`/admin/settings/report-period`);
  } else {
    toast.error(transBackend('Please contact admin to access this feature.'));
  }
};

export const getMonthAndQuarterNames = (t, startDatePeriod) => {
  const startDate = new Date(startDatePeriod);
  const fm = 'MMM';
  const fy = 'yyyy';
  const startYear = format(startDate, fy);
  const addToYear = [0];
  const quarterNames = [t('n/a')];
  let start, end;
  for (let i = 0; i < 4; i++) {
    if (i === 0) {
      start = startDate;
      end = addMonths(startDate, 2);
    } else {
      start = addMonths(startDate, 1);
      end = addMonths(startDate, 3);
    }

    if (format(end, fy) !== startYear) addToYear.push(1);
    else addToYear.push(0);

    quarterNames.push(
      `${format(modifyDate(startDate, start), fm)} - ${format(modifyDate(startDate, end), fm)}`
    );
  }
  const monthNames = [
    t('n/a'),
    format(modifyDate(startDate, startOfYear(startDate)), fm),
    ..._.range(1, 12, 1).map(() => format(modifyDate(startDate, addMonths(startDate, 1)), fm))
  ];
  return { monthNames, quarterNames, addToYear };
};
// get start report period
export const getStartDatePeriod = (reportRangePeriod, reportYearType) => {
  if (reportYearType === CALENDAR) {
    return format(startOfYear(reportRangePeriod.startDate), fullDateFormat);
  }
  return format(reportRangePeriod.startDate, fullDateFormat);
};

export const getReportRangePeriod = (usrOrgOptions, reportYear = null) => {
  const { optReportPeriodType, optReportPeriodEnding } = usrOrgOptions || {};
  if (!optReportPeriodType) {
    const startDate = reportYear ? new Date(`${reportYear}-01-01 00:00:00`) : new Date();
    return {
      isReportPeriod: !!optReportPeriodType,
      startDate: startOfYear(startDate),
      endDate: endOfYear(startDate),
    };
  }
  if (!reportYear) {
    reportYear = getCurrentReportPeriod(usrOrgOptions).reportYear;
  }
  const monthString = `0${optReportPeriodEnding}`.slice(-2);
  const nextYear = optReportPeriodEnding !== 12 ? 1 : 0;
  const endDate = new Date(`${Number(reportYear) + nextYear}-${monthString}-01 00:00:00`);
  return {
    isReportPeriod: !!optReportPeriodType,
    endDate: endOfMonth(endDate),
    startDate: subMonths(endDate, 11),
  };
};

export const getListReportOptions = (t) => {
  const reportOptions = [
    {
      label: t('Carbon FootPrint By Entity'),
      value: 'CFBE',
      path: 'carbon-footprint-by-entity',
    },
    {
      label: t('Carbon FootPrint By Full Emission Profile'),
      value: 'CFBP',
      path: 'carbon-footprint-by-full-emission-profile',
    },
    {
      label: t('Carbon FootPrint By Scope'),
      value: 'CFBC',
      path: 'carbon-footprint-by-scope',
    },
    {
      label: t('Data Gap'),
      value: 'DAGP',
      path: 'data-gap',
    },
    {
      label: t('Data Template IDs Report'),
      value: 'DTIR',
      path: 'data-template-ids',
    },
    {
      label: t('Data Validation'),
      value: 'DAVN',
      path: 'data-validation',
    },
    {
      label: t('Emissions By Asset'),
      value: 'EBAT',
      path: 'emissions-by-asset',
    },
    {
      label: t('Emissions By Entity Group'),
      value: 'EBEG',
      path: 'emissions-by-entity-group',
    },
    {
      label: t('Emissions By Entity Or Metric'),
      value: 'EBEM',
      path: 'emissions-by-entity-or-metric',
    },
    {
      label: t('Emissions By Top 10 Contributors'),
      value: 'T10C',
      path: 'emissions-by-top-10-contributors',
    },
    {
      label: t('Emissions By Top 10 Entities'),
      value: 'T10E',
      path: 'emissions-by-top-10-entities',
    },
    {
      label: t('Emissions by Top 10 Emission Profile Sources'),
      value: 'T10P',
      path: 'emissions-by-top-10-eps',
    },
    {
      label: t('Intensity Graph'),
      value: 'IYGH',
      path: 'intensity-graphs',
    },
    {
      label: t('Moving Average Report'),
      value: 'MART',
      path: 'moving-average-report',
    },
    {
      label: t('Transaction Report'),
      value: 'TNRT',
      path: 'transaction',
    },
  ];
  return _.sortBy(reportOptions, 'name');
};
