import React, { useEffect, useState, useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import PageHeader from '../../components/PageHeader';
import { LoadingState } from '../../utilities/constants';
import UserForm from './UserForm';
import { fetchUser, fetchUsers, selectUserById } from './usersSlice';
import { editUserApi } from './usersApi';
import { toast } from 'react-toastify';
import { checkUserEmailVerification, resendUserEmailVerification } from '../manage-users/usersApi';
import { useTranslation } from 'react-i18next';

const EditUser = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { userId } = useParams();
  const timeRef = useRef(null);
  const [isResendEmail, setIsResendEmail] = useState(0);
  const [resendDelayCount, setResendDelayCount] = useState(false);
  const [requestedId, setRequestedId] = useState(false);
  const user = useSelector((state) => selectUserById(state, userId));
  const userState = useSelector((state) => state.users.status);
  const userInfo = useSelector((state) => state.users.user);
  const orgId = userInfo.usrOrgId;

  const [saveUserState, setSaveUserState] = useState(LoadingState.idle);

  const isBusy = saveUserState === LoadingState.loading;

  const handleEditUser = async (data) => {
    try {
      setSaveUserState(LoadingState.loading);
      await editUserApi(orgId, userId, data);
      if (Number(userInfo.usrId) === Number(userId)) {
        dispatch(fetchUser({ userKeycloakId: userInfo.usrKeycloakId }));
      }
      toast.success(t('User updated successfuly'));
      history.push('/admin/users/manage');
    } catch (e) {
      setSaveUserState(LoadingState.idle);
      toast.error(e.message);
      throw e;
    }
  };

  const handleResendEmail = async () => {
    if (!isResendEmail || !user?.usrKeycloakId) {
      return;
    }
    setIsResendEmail(false);
    try {
      const res = await resendUserEmailVerification(orgId, user.usrKeycloakId);
      setIsResendEmail(res.success);
      setResendDelayCount(res.delayTime);
      toast.success(t('An activation email is sent'));
    } catch (ex) {
      console.log(ex);
      toast.error(t('An error occurred during activation, please try again!'));
    }
  };

  useEffect(() => {
    if (resendDelayCount && !timeRef.current) {
      const id = setInterval(() => {
        setResendDelayCount(prevCount => prevCount - 1);
      }, 1000);
      timeRef.current = id;
    } else if (resendDelayCount <= 1 && timeRef.current) {
      timeRef.current = clearInterval(timeRef.current);
      setResendDelayCount(0);
    }
    if (resendDelayCount) {
      console.log('Retry in:', resendDelayCount);
    }
  }, [resendDelayCount]);

  const handleCancelUser = () => {
    history.push('/admin/users/manage');
  };

  useEffect(() => {
    if (userState === LoadingState.idle) {
      dispatch(fetchUsers({ orgId }));
    }
  }, [orgId, userState, dispatch]);

  useEffect(() => {
    const { usrKeycloakId } = user || {};
    if (requestedId !== usrKeycloakId && orgId && usrKeycloakId) {
      setRequestedId(usrKeycloakId);
      checkUserEmailVerification(orgId, usrKeycloakId)
        .then((res) => {
          setIsResendEmail(!res.emailVerified)
          setResendDelayCount(!res.emailVerified ? res.delayTime : 0);
        });
    }
  }, [orgId, user, isResendEmail, requestedId]);

  return (
    <Container fluid>
      <Row>
        <Col>
          <PageHeader title={t("Edit User")} enableBackButton={true} />
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          {userState === LoadingState.succeeded && user && (
            <UserForm
              onSubmit={handleEditUser}
              buttonText={t("Save")}
              isResendEmail={isResendEmail}
              resendDelayCount={resendDelayCount}
              onResendEmail={handleResendEmail}
              initialValues={user}
              localeOptions={userInfo.localeOptions || []}
              disabled={isBusy}
              isEditing
              onCancel={handleCancelUser}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default EditUser;
