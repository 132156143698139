import { Fragment } from 'react';
import { Button, Card, Spinner } from 'react-bootstrap';
import { LoadingState } from '../../../utilities/constants';
import { useTranslation } from 'react-i18next';

export function DisclosureCardComponent({
  imgUrl,
  title,
  description,
  btnName,
  onClickHandle,
  disabled,
  getSdgDataByCompanyStatus,
  isKpiSetup
}) {
  const { t } = useTranslation();
  return (
    <Card className='mt-3 p-2'>
      <div className='disclosure-card'>
        <div className='image-section'>
          <img src={imgUrl} alt={title} />
        </div>
        <div className='text-section'>
          <div className='text-section-wrapper'>
            <h5 className='font-weight-bold'>{title}</h5>
            <p>{description}</p>
            {isKpiSetup ? (
              <Fragment>
                <Button
                  variant='primary'
                  onClick={onClickHandle}
                  className='text-uppercase'
                  disabled={disabled || getSdgDataByCompanyStatus === LoadingState.loading}
                >
                  {getSdgDataByCompanyStatus === LoadingState.loading ? (
                    <Spinner animation='border' />
                  ) : (
                    <Fragment>{t('Edit Setup')}</Fragment>
                  )}
                </Button>
              </Fragment>
            ) : (
              <Fragment>
                <Button
                  variant='primary'
                  onClick={onClickHandle}
                  className='text-uppercase'
                  disabled={disabled || getSdgDataByCompanyStatus === LoadingState.loading}
                >
                  {getSdgDataByCompanyStatus === LoadingState.loading ? (
                    <Spinner animation='border' />
                  ) : (
                    <Fragment>{btnName}</Fragment>
                  )}
                </Button>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
}
