import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import PageHeader from '../../components/PageHeader';
import { LoadingState } from '../../utilities/constants';
import BusinessMeasureForm from './BusinessMeasureForm';
import { editBusinessMeasure, fetchBusinessMeasures, selectBsnsMeasureById } from './businessMeasuresSlice';

const EditBusinessMeasure = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { bsnsMeasureId } = useParams();
  const bsnsMeasure = useSelector((state) => selectBsnsMeasureById(state, bsnsMeasureId));
  const bsnsMeasureState = useSelector((state) => state.businessMeasures.status);
  const orgId = useSelector((state) => state.users.user.usrOrgId);

  const handleEditBsnsMeasure = async (data) => {
    try {
      setIsLoading(true);
      const resultAction = await dispatch(editBusinessMeasure({ orgId, bsnsMeasureId, data }));
      unwrapResult(resultAction);
      history.push('/admin/setup/business-measures');
    } catch {
      setIsLoading(false);
    }
  };

  const handleCancelAsset = () => {
    history.push('/admin/setup/business-measures');
  };

  useEffect(() => {
    if (bsnsMeasureState === LoadingState.idle) {
      dispatch(fetchBusinessMeasures({ orgId }));
    }
  }, [orgId, bsnsMeasureState, dispatch]);

  return (
    <Container fluid>
      <Row>
        <Col>
          <PageHeader title="Edit Business Measure" />
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          {bsnsMeasureState === LoadingState.succeeded && bsnsMeasure && (
            <BusinessMeasureForm
              onSubmit={handleEditBsnsMeasure}
              buttonText="Save"
              initialValues={bsnsMeasure}
              isLoading={isLoading}
              isEditing
              onCancel={handleCancelAsset}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default EditBusinessMeasure;
