import React, { useMemo } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAssignedUsers } from './accountsSlice';
import DataTable from '../../components/DataTable';
import { useTranslation } from 'react-i18next';

const ViewAssignedUsers = ({ accountId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const assignedUsers = useSelector((state) => state.accounts.assignedUsers);
  const orgId = useSelector((state) => state.users.user.usrOrgId);

  const viewAssignedUsersTableColumns = useMemo(
    () => [
      {
        Header: t('User Name'),
        accessor: 'usrName'
      },
      {
        Header: t('First Name'),
        accessor: 'usrFirstname'
      },
      {
        Header: t('Last Name'),
        accessor: 'usrLastname'
      }
    ],
    [t]
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resultAction = await dispatch(fetchAssignedUsers({ orgId, accountId }));
        unwrapResult(resultAction);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [orgId, accountId, dispatch]);

  if (isLoading) return <Spinner animation="grow" />;

  return (
    <div>
      <h5 className="my-3 text-light">{t('Assigned Users')}</h5>
      <DataTable columns={viewAssignedUsersTableColumns} data={assignedUsers} />
    </div>
  );
};

export default ViewAssignedUsers;
