import React from 'react';
import { Row, Col, ListGroup, Image, Carousel } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import slide1Image from '../../../assets/images/coriolis/subslide1.jpg';
import slide2Image from '../../../assets/images/coriolis/subslide2.jpg';
import slide3Image from '../../../assets/images/coriolis/subslide3.jpg';
import slide4Image from '../../../assets/images/coriolis/subslide4.jpg';
import methodologyImage from '../../../assets/images/coriolis/methodology.png';
import GetStartedNow from './components/GetStartedNow';

const ScoringMethodology = () => {
  const { t } = useTranslation();
  const maxWidth = '600px';
  const imageStyle = { width: '100%', maxWidth };

  const renderCircleDotLine = (color, text, backgroundColor = undefined) => (
    <div className="methodology-line" style={{ color, backgroundColor }}>
      <span style={{ backgroundColor: color }} />
      {text}
    </div>
  );
  return (
    <>
      <Row>
        <Col md={8}>
          <h2>{t('CoriolisESG Underlying Scoring Methodology')}</h2>
        </Col>
        <Col md={4} style={{ textAlign: 'right' }}>
          <GetStartedNow />
        </Col>
      </Row>
      <Row className="methodology">
        <Col md={6} style={{ padding: 0 }}>
          <Carousel interval={10000} className="image-carousel" style={{ maxWidth }}>
            <Carousel.Item>
              <Image src={slide1Image} style={imageStyle} />
            </Carousel.Item>
            <Carousel.Item>
              <Image src={slide2Image} style={imageStyle} />
            </Carousel.Item>
            <Carousel.Item>
              <Image src={slide3Image} style={imageStyle} />
            </Carousel.Item>
            <Carousel.Item>
              <Image src={slide4Image} style={imageStyle} />
            </Carousel.Item>
          </Carousel>
        </Col>
        <Col md={6} style={{ padding: 0 }}>
          <p style={{ paddingTop: '1rem' }}><Image src={methodologyImage} style={{ width: '100%' }} /></p>
          <p>{t('Determine business activities impact on specific UN SDGs by color scheme, allowing for proactive mitigation strategies')}</p>
          <ListGroup variant="flush">
            <ListGroup.Item>{renderCircleDotLine('#1cae9f', t('No Negative Impact (implied positive)'))}</ListGroup.Item>
            <ListGroup.Item>{renderCircleDotLine('#e8833b', t('Business products or services identified as a dual-use Good and potentially used for military purpose.'))}</ListGroup.Item>
            <ListGroup.Item>{renderCircleDotLine('#d3445c', t('Negative Impact found'))}</ListGroup.Item>
            <ListGroup.Item>{renderCircleDotLine('#ffffff', t('No associated impact'), '#ced8e0')}</ListGroup.Item>
          </ListGroup>
          <p className="mt-3">{t('This is based on knowledge of a business and:')}</p>
          <ul className="knowledge-group">
            <li>{t('Mapping the business against 9000 HS codes (Products)')}</li>
            <li>{t('Mapping the business against 600 NACE codes (Industries)')}</li>
            <li>{t('2887 Central Product Classifications codes (Services & Products)')}</li>
          </ul>
        </Col>
      </Row>
    </>
  );
};

export default ScoringMethodology;
