import { post, get, put, remove } from '../../utilities/apiHelper';

// ============================================= Planned actions APIs OLD ================================

export const fetchActions = async (orgId) => {
  try {
    const result = await get(`/planned-reductions/${orgId}/action`, true);
    if (!result.data) throw new Error(result.data.message);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const fetchActionByActId = async (orgId, actId) => {
  try {
    const result = await get(`/planned-reductions/${orgId}/action/${actId}`, true);
    if (!result.data) throw new Error(result.data.message);
    return result.data;
  } catch (error) {
    throw error;
  }
}

export const fetchSuggestedActions = async () => {
  try {
    const result = await get(`/api/suggested-actions`, true);
    if (!result.data) throw new Error(result.data.message);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const createActionApi = async (orgId, requestData) => {
  try {
    const result = await post(`/planned-reductions/${orgId}/action`, requestData, true);
    if (!result.data) throw new Error(result.data.message);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const updateActionApi = async (orgId, actId, requestData) => {
  try {
    const result = await put(`/planned-reductions/${orgId}/action/${actId}`, requestData, true);
    if (!result.data) throw new Error(result.data.message);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const deleteActionApi = async (orgId, actId) => {
  try {
    const result = await remove(`/planned-reductions/${orgId}/action/${actId}`, true);
    if (!result.data) throw new Error(result.data.message);
    return result.data;
  } catch (error) {
    throw error;
  }
};

// ========================================================================================================

//Targets (Goals) APIs
export const createTargetApi = async (orgId, requestData) => {
  try {
    const result = await post(`/planned-reductions/${orgId}/target`, requestData, true);
    if (!result.data) throw new Error(result.data.message);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const updateTargetApi = async (orgId, targetId, requestData) => {
  try {
    const result = await put(`/planned-reductions/${orgId}/target/${targetId}`, requestData, true);
    if (!result.data) throw new Error(result.data.message);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const fetchAllTargets = async (orgId) => {
  try {
    const result = await get(`/planned-reductions/${orgId}/target`, true);
    if (!result.data) throw new Error(result.data.message);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const fetchSingleTarget = async (targetID, companyID) => {
  try {
    const result = await get(`/api/company/${companyID}/target/${targetID}`, true);
    if (!result.data) throw new Error(result.data.message);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const deleteTarget = async (targetID, companyID) => {
  try {
    const result = await remove(`/api/company/${companyID}/target/${targetID}`, true);
    if (!result.data) throw new Error(result.data.message);
    return result.data;
  } catch (error) {
    throw error;
  }
};

// Forecast
export const fetchReductionPlanByScope = async (orgId, requestData) => {
  try {
    const result = await post(`/planned-reductions/${orgId}/forecast-scope`, requestData, true);
    if (!result.data) throw new Error(result.data.message);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const fetchReductionPlanByEPS = async (orgId, requestData) => {
  try {
    const result = await post(`/planned-reductions/${orgId}/forecast-eps`, requestData, true);
    if (!result.data) throw new Error(result.data.message);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getFinancialStartMonthApi = async (orgId, requestData) => {
  try {
    const result = await post(`/planned-reductions/${orgId}/financial-start-month`, requestData, true);
    if (!result.data) throw new Error(result.data.message);
    return result.data;
  } catch (error) {
    throw error;
  }
};
