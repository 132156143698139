import React from 'react';
import { Row, Col, Image, ListGroup } from 'react-bootstrap';
import { FaCheckCircle } from 'react-icons/fa';
import coriolisImage from '../../../assets/images/coriolis/coriolis.png';
import { Trans, useTranslation } from 'react-i18next';
import { GoGoal } from "react-icons/go";
import { MdChecklistRtl } from "react-icons/md";
import { IoPeople } from "react-icons/io5";
import { GrDocumentPerformance } from "react-icons/gr";
import { CgInsights } from "react-icons/cg";
import GetStartedNow from './components/GetStartedNow';

const KeyFeatures = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Row>
        <Col md={9}>
          <h2>{t('KEY FEATURES')}</h2>
          <p style={{ textAlign: 'justify' }}>
            {t('By identifying and mitigating environmental, social, governance, and reputational risks for your business and supply chain, CarbonView provides a comprehensive solution for sustainable business operations.')}
          </p>
        </Col>
        <Col md={3} style={{ textAlign: 'right' }}>
          <GetStartedNow />
        </Col>
      </Row>
      <Row className="align-items-center key-feature-top">
        {/* Key Features Section */}
        <Col md={12}>
          <Row className="my-2">
            <Col md={4}>
              <h5><GoGoal size='1.2em' /> <div className="pt-1">{t('SDG Assessment')}</div></h5>
              <p>{t('Accurately assess your organization’s alignment with the SDGs, identifying strengths and areas for improvement.')}</p>
            </Col>
            <Col md={4}>
              <h5><MdChecklistRtl size='1.3em' /> <div className="pt-1">{t('Risk Management')}</div></h5>
              <p>{t('Identify and mitigate ESG risks, protecting your business reputation and financial performance.')}</p>
            </Col>
            <Col md={4}>
              <h5><IoPeople size='1.2em' /><div className="pt-1">{t('Stakeholder Engagement')}</div></h5>
              <p>{t('Build trust and transparency with investors, customers, employees, and communities.')}</p>
            </Col>
          </Row>
          <Row className="my-2">
            <Col md={2}></Col>
            <Col md={4}>
              <h5><GrDocumentPerformance size='1.2em' /><div className="pt-1">{t('Performance Tracking')}</div></h5>
              <p>{t('Monitor progress towards sustainability goals and demonstrate accountability.')}</p>
            </Col>
            <Col md={4}>
              <h5><CgInsights size='1.7em' style={{ marginLeft: '-5px' }} /><div className="pt-1">{t('Data-Driven Insights')}</div></h5>
              <p>{t('Leverage advanced analytics to uncover trends, patterns, and opportunities for sustainable growth')}.</p>
            </Col>
            <Col md={2}></Col>
          </Row>
        </Col>
      </Row>
      <Row className="key-feature-bot">
        <Col md={6}>
          <Row>
            <Col style={{ textAlign: 'center' }}>
              <Image
                src={coriolisImage}
                alt="ESG Performance Gauge"
                style={{ width: '60%', maxWidth: '312px' }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <p style={{ paddingTop: '20px' }}>{t('Checks activity against all areas of ESG, including:')}</p>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <ListGroup variant="flush">
                <ListGroup.Item><FaCheckCircle className="stick-icon" /> {t('GHG')}</ListGroup.Item>
                <ListGroup.Item><FaCheckCircle className="stick-icon" /> {t('Adverse News')}</ListGroup.Item>
                <ListGroup.Item><FaCheckCircle className="stick-icon" /> {t('Geospatial Analysis')}</ListGroup.Item>
                <ListGroup.Item><FaCheckCircle className="stick-icon" /> {t('Smart Governance')}</ListGroup.Item>
              </ListGroup>
            </Col>
            <Col md={8}>
              <ListGroup variant="flush italic-item">
                <ListGroup.Item>
                  <FaCheckCircle className="stick-icon" /> {t('UN Sustainable Development Goals (SDG)')}
                </ListGroup.Item>
                <ListGroup.Item>
                  <FaCheckCircle className="stick-icon" /> {t('EU Taxonomy')}
                </ListGroup.Item>
                <ListGroup.Item>
                  <FaCheckCircle className="stick-icon" /> {t('Sustainability Financial Disclosure Regulations (SFDR)')}
                </ListGroup.Item>
                <ListGroup.Item>
                  <FaCheckCircle className="stick-icon" /> {t('Supply Chain Regulations')}
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </Col>
        <Col md={6} style={{ paddingTop: '20px' }}>
          <h2>{t('SUPPLIER ESG RATING SCORECARD')}</h2>
          <p>{t('The World’s first automated ESG Scoring Solution')}</p>
          <ul>
            <li>
              <p>
                <Trans
                  i18nKey="Developed in collaboration with more than <highlight>70</highlight> <bold>financial institutions, banks, insurers, and industry bodies</bold>"
                  components={{ bold: <strong />, highlight: <strong className="esg-highlight" /> }}
                />
              </p>
            </li>
            <li>{t('Enhances supply chain transparency by measuring ESG impact throughout all supply chain tiers.')}</li>
          </ul>
        </Col>
      </Row>
    </div>
  );
};
// <Button variant="success" className="mt-4">{t('Get Started Now</Button>

export default KeyFeatures;