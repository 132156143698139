import { unwrapResult } from '@reduxjs/toolkit';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Form, Modal, Col, Button, Container, Row, Spinner } from 'react-bootstrap';
import PageHeader from '../../components/PageHeader';
import DataTable from '../../components/DataTable';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import ReactDatePicker from 'react-datepicker';
import { datePickerClass, datePickerValue, parseUserInfo } from '../../utilities/common';
import 'react-dropdown-tree-select/dist/styles.css';
import { fetchEmissionProfilesTree } from '../emission-profile/emissionProfileSlice';
import { LoadingState } from '../../utilities/constants';
import { ReactDropdownTreeSelectMemoized } from './dropdownContainer';
import { adaptData } from './data-adapter';
// import { IoTrashOutline } from 'react-icons/io5';
import { AiOutlineEdit } from 'react-icons/ai';
import {
  addBillSetting,
  // deleteBillSetting,
  fetchOrgBillSetting,
  fetchOrgBillSettings,
  updateBillSetting
} from './billSettingsSlice';
import { useTranslation } from 'react-i18next';

const schema = yup.object().shape({
  bsDesc: yup.string().nullable().notRequired().max(255, 'Description has a Maximum of 255 Characters'),
  bsSubject: yup.string().required('Mail subject identifier is required'),
  bsDateFrom: yup.string().required('Start date is required'),
  bsDateTo: yup.string().required('End date is required'),
  bsEnable: yup.string(),
  bsEmissionprofilesources: yup
    .array()
    .min(1, 'There should be a minimum of one Emission Profile Source selected')
    .required('There should be a minimum of one Emission Profile Source selected')
});

const BillSettings = () => {
  const [bsId, setBsId] = useState(null);
  const billSetting = useSelector((state) => state.billSettings.billSetting);
  const {
    handleSubmit,
    control,
    register,
    setValue,
    formState: { errors, isDirty, isValid },
    watch,
    reset
  } = useForm({
    defaultValues: {
      ...billSetting
    },
    resolver: yupResolver(schema),
    mode: 'onBlur'
  });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { orgId, usrOrgName: orgName, usrDateFormat } = parseUserInfo(useSelector((state) => state.users.user));
  const [selectEps, setSelectEps] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [saveBillSettingState, setSaveBillSettingState] = useState(LoadingState.idle);
  const [isEditing, setIsEditing] = useState(false);

  const billSettings = useSelector((state) => state.billSettings.billSettings);
  const loggedUserStatus = useSelector((state) => state.users.loggedUserStatus);
  const billSettingsStatus = useSelector((state) => state.billSettings.billSettingsStatus);
  const billSettingByIdStatus = useSelector((state) => state.billSettings.billSettingByIdStatus);
  const emissionProfileTree = useSelector((state) => state.emissionProfiles.tree);

  const watchStartDate = watch('bsDateFrom', null);
  const watchEndDate = watch('bsDateTo', null);

  const renameKeysOfEmissionProfileTree = useCallback((obj, changingKey) => {
    const keyValues = Object.keys(obj).map((key) => {
      if (changingKey === 'label') {
        let newKey = null;
        if (key === 'eprflName' || key === 'eprflcatName' || key === 'eprflsrcName') {
          newKey = changingKey;
        } else {
          newKey = key;
        }
        if (key === 'children') {
          obj[key] = obj[key].map(({ ...obj }) => renameKeysOfEmissionProfileTree(obj));
        }
        return {
          [newKey]: obj[key]
        };
      } else {
        let newKey = null;
        if (key === 'eprflId' || key === 'eprflcatId' || key === 'eprflsrcId') {
          newKey = changingKey;
        } else {
          newKey = key;
        }
        if (key === 'children') {
          obj[key] = obj[key].map(({ ...obj }) => renameKeysOfEmissionProfileTree(obj));
        }
        return {
          [newKey]: obj[key]
        };
      }
    });

    return Object.assign({}, ...keyValues);
  }, []);

  const removeKeys = useCallback(
    (obj) => {
      const formattedObj = Object.keys(obj).map((key) => {
        if (
          key !== 'eprflName' &&
          key !== 'eprflId' &&
          key !== 'eprflcatName' &&
          key !== 'eprflcatId' &&
          key !== 'eprflsrcId' &&
          key !== 'eprflsrcName' &&
          key !== 'children'
        ) {
          delete obj[key];
        }
        if (key === 'eprflName' || key === 'eprflcatName') {
          obj['disabled'] = true;
        }
        if (key === 'eprflsrcName') {
          obj['eprflsrcName'] = obj['eprflsrcName'].trim() + ' ' + obj['eprflsrcAssignedId'].trim();
        }
        if (key === 'children') {
          obj[key] = obj[key].map(({ ...obj }) => removeKeys(obj));
        }
        return obj;
      });
      const narrowedKeysObject = Object.assign({}, ...formattedObj);
      const formattedNarrowedKeysObject = renameKeysOfEmissionProfileTree(narrowedKeysObject, 'label');
      const finalObj = renameKeysOfEmissionProfileTree(formattedNarrowedKeysObject, 'value');
      return finalObj;
    },
    [renameKeysOfEmissionProfileTree]
  );

  const formattedEmissionProfileTree = [];

  let adaptedData = useMemo(() => {
    formattedEmissionProfileTree.push(removeKeys({ ...emissionProfileTree }));
    if (!formattedEmissionProfileTree) return null;
    return adaptData(formattedEmissionProfileTree, selectEps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formattedEmissionProfileTree]);

  const onChange = useMemo(
    () => (selectedValues) => {
      setSelectEps(selectedValues.map((val) => val.label));
    },
    []
  );

  const handleCloseModal = () => setShowModal(false);

  const closeModal = () => {
    setValue('bsDesc', null);
    setValue('bsDateFrom', null);
    setValue('bsDateTo', null);
    setValue('bsSubject', null);
    setValue('bsEmissionprofilesources', null);
    !isEditing && setSelectEps([]);
    reset();
    setShowModal(false);
  };

  const handleBillIntegration = async (data) => {
    const combinedData = {
      ...data,
      bsEmissionprofilesources: data.bsEmissionprofilesources.map((eps) => ({ bsepsEprflsrcId: eps.value })),
      bsOrgId: orgId
    };
    // closeModal();
    try {
      setSaveBillSettingState(LoadingState.loading);
      const resultAction = await dispatch(addBillSetting({ orgId, combinedData }));
      setSaveBillSettingState(LoadingState.succeeded);
      if (!resultAction.error) {
        unwrapResult(resultAction);
        reset();
      }
      history.push('/admin/setup/bill-settings');
    } catch {
      setSaveBillSettingState(LoadingState.failed);
    } finally {
      setShowModal(false);
    }
  };

  const editBillSetting = async (bsId) => {
    setSaveBillSettingState(LoadingState.loading);
    const resultAction = await dispatch(fetchOrgBillSetting({ orgId, bsId }));
    if (!resultAction.error) {
      unwrapResult(resultAction);
    }
    setSaveBillSettingState(LoadingState.succeeded);
    setShowModal(true);
    setIsEditing(true);
  };

  const findEPSDifference = (bsEmissionprofilesources) => {
    let currentEPS = bsEmissionprofilesources.map((eps) => eps.value || eps.bsepsEprflsrcId);
    const initialEPS = billSetting.bsEmissionprofilesources.map((eps) => eps.bsepsEprflsrcId);

    const intersect = [...initialEPS]
      .filter((eps) => currentEPS.includes(eps))
      .map((ieps) => {
        const intersectEPS = billSetting.bsEmissionprofilesources.filter(
          (epsource) => epsource.bsepsEprflsrcId === ieps
        );
        return {
          bsepsId: intersectEPS[0].bsepsId,
          bsepsEprflsrcId: ieps
        };
      });

    const difference1 = [...initialEPS]
      .filter((eps) => !currentEPS.includes(eps))
      .map((deps) => {
        const intersectEPS = billSetting.bsEmissionprofilesources.filter(
          (epsource) => epsource.bsepsEprflsrcId === deps
        );

        return { bsepsId: intersectEPS[0].bsepsId, bsepsEprflsrcId: deps, bsepsIsDeleted: true };
      });

    const difference2 = [...currentEPS]
      .filter((eps) => !initialEPS.includes(eps))
      .map((aeps) => {
        return { bsepsEprflsrcId: aeps };
      });

    const EPSDifference = difference1.concat(difference2);

    const finalDifference = EPSDifference.concat(intersect);

    return finalDifference;
  };

  const comparedFieldsData = (currentObj, initialObj) => {
    const comparedData = {};
    if (currentObj.bsDesc !== initialObj.bsDesc) {
      comparedData.bsDesc = currentObj.bsDesc;
    }
    if (currentObj.bsSubject !== initialObj.bsSubject) {
      comparedData.bsSubject = currentObj.bsSubject;
    }
    if (currentObj.bsDateFrom !== initialObj.bsDateFrom) {
      comparedData.bsDateFrom = currentObj.bsDateFrom;
    }
    if (currentObj.bsDateTo !== initialObj.bsDateTo) {
      comparedData.bsDateTo = currentObj.bsDateTo;
    }
    if (currentObj.bsEnable !== initialObj.bsEnable) {
      comparedData.bsEnable = currentObj.bsEnable;
    }

    comparedData.bsEmissionprofilesources = findEPSDifference({ ...currentObj }.bsEmissionprofilesources);

    comparedData.bsEmissionprofilesources = findEPSDifference({ ...currentObj }.bsEmissionprofilesources);

    return comparedData;
  };

  const handleEditBillSetting = async (data) => {
    const finalData = comparedFieldsData(data, billSetting);
    try {
      setSaveBillSettingState(LoadingState.loading);
      const resultAction = await dispatch(
        updateBillSetting({
          orgId,
          bsId: bsId,
          data: finalData
        })
      );
      setSaveBillSettingState(LoadingState.succeeded);
      if (!resultAction.error) {
        unwrapResult(resultAction);
        closeModal();
      }
      dispatch(fetchOrgBillSettings({ orgId }));
    } catch (e) {
      setSaveBillSettingState(LoadingState.failed);
    } finally {
      setIsEditing(false);
      setShowModal(false);
    }
  };

  useEffect(() => {
    if (billSettingsStatus === LoadingState.idle && loggedUserStatus === LoadingState.succeeded) {
      dispatch(fetchEmissionProfilesTree({ orgId }));
      dispatch(fetchOrgBillSettings({ orgId }));
    }
  }, [dispatch, orgId, loggedUserStatus, billSettingsStatus, billSetting]);

  useEffect(() => {
    if (billSetting) {
      let selectedEPS = [];
      if (Object.keys(billSetting).length !== 0) {
        selectedEPS = billSetting.bsEmissionprofilesources.map(
          (eps) =>
            eps.bsepsEmissionprofilesource.eprflsrcName.trim() +
            ' ' +
            eps.bsepsEmissionprofilesource.eprflsrcAssignedId.trim()
        );
      }
      setSelectEps(selectedEPS);
      reset(billSetting);
    }
  }, [billSetting, reset]);

  const columns = useMemo(
    () => [
      {
        Header: t('Billing Type'),
        accessor: (row) => {
          return t('BID Integration');
        },
        sortType: 'string'
      },
      {
        Header: t('Organization Name'),
        accessor: 'bsOrg.orgName',
        sortType: 'string'
      },
      {
        Header: t('Description'),
        accessor: 'bsDesc',
        sortType: 'string'
      },
      {
        Header: t('Enable'),
        accessor: (row) => {
          return row.bsEnable === 'Y' ? t('Yes') : t('No');
        }
      },
      {
        Header: '',
        accessor: 'actions',
        actionColumn: true,
        disableGlobalFilter: false,
        disableSortBy: true,
        Cell: (props) => {
          const { editBillSetting, row } = props;
          const { original } = row;
          return (
            <div className="d-flex action-buttons">
              <Button
                size="sm"
                className="mr-1"
                variant="outline-secondary"
                onClick={() => {
                  setBsId(original.bsId);
                  editBillSetting(original.bsId);
                }}
              >
                <AiOutlineEdit />
              </Button>
              {/* <Button size="sm" variant="outline-secondary" onClick={deleteRow}>
                <IoTrashOutline />
              </Button> */}
            </div>
          );
        }
      }
    ],
    [t]
  );

  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <PageHeader title={t("Manage Setting For Bill Integration")} enableBackButton={true} />
          </Col>
        </Row>
        <Row>
          <Col>
            {(billSettingsStatus === LoadingState.loading ||
              saveBillSettingState === LoadingState.loading ||
              billSettingByIdStatus === LoadingState.loading) && <Spinner className="loader" animation="grow" />}
            {billSettingsStatus === LoadingState.succeeded && (
              <DataTable
                autoResetPage={false}
                headerActions={
                  <Button
                    onClick={() => setShowModal(true)}
                    variant="secondary"
                    disabled={Object.keys(billSettings).length !== 0}
                  >
                    + {t('Add New')}
                  </Button>
                }
                columns={columns}
                data={billSettings}
                // onDeleteRow={handleDeleteBillSetting}
                actions={{
                  editBillSetting
                }}
              />
            )}
          </Col>
          <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header>
              <Modal.Title>{isEditing ? t('Update Bill') : t('Add New')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={isEditing ? handleSubmit(handleEditBillSetting) : handleSubmit(handleBillIntegration)}>
                <Form.Group controlId="modalForm.ControlInput1">
                  <Form.Label>Type*</Form.Label>
                  <Form.Control readOnly={true} value="BID Integration" />
                </Form.Group>
                <Form.Group controlId="modalForm.ControlInput2">
                  <Form.Label>Organization Name*</Form.Label>
                  <Form.Control readOnly={true} value={orgName} />
                </Form.Group>
                <Form.Group controlId="modalForm.ControlInput3">
                  <Form.Label>Description</Form.Label>
                  <Form.Control name="bsDesc" {...register('bsDesc')} isInvalid={errors.bsDesc} />
                  <Form.Control.Feedback type="invalid">{errors.bsDesc?.message}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="modalForm.ControlInput4">
                  <Form.Label>Mail Subject Identifier*</Form.Label>
                  <Form.Control name="bsSubject" {...register('bsSubject')} isInvalid={errors.bsSubject} />
                  <Form.Control.Feedback type="invalid">{errors.bsSubject?.message}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="custom-form-control">
                  <Form.Label>Date From*</Form.Label>
                  <Controller
                    name="bsDateFrom"
                    {...register('bsDateFrom')}
                    control={control}
                    render={({ field, fieldState: { invalid } }) => {
                      return (
                        <ReactDatePicker
                          dateFormat={usrDateFormat}
                          maxDate={!!watchEndDate ? new Date(watchEndDate) : null}
                          popperPlacement="bottom-end"
                          showMonthDropdown
                          showYearDropdown
                          {...datePickerValue(field.value, field.onChange)}
                          {...datePickerClass(invalid)}
                        />
                      );
                    }}
                    isInvalid={errors.bsDateFrom}
                  />
                  <Form.Control.Feedback type="invalid">{errors.bsDateFrom?.message}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="custom-form-control">
                  <Form.Label>Date To*</Form.Label>
                  <Controller
                    name="bsDateTo"
                    {...register('bsDateTo')}
                    control={control}
                    render={({ field, fieldState: { invalid } }) => {
                      return (
                        <ReactDatePicker
                          dateFormat={usrDateFormat}
                          minDate={!!watchStartDate ? new Date(watchStartDate) : null}
                          showDisabledMonthNavigation
                          showMonthDropdown
                          showYearDropdown
                          {...datePickerValue(field.value, field.onChange)}
                          {...datePickerClass(invalid)}
                        />
                      );
                    }}
                    isInvalid={errors.bsDateTo}
                  />
                  <Form.Control.Feedback type="invalid">{errors.bsDateTo?.message}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="custom-form-control">
                  <Form.Label>Emission Profile Sources*</Form.Label>
                  <Controller
                    name="bsEmissionprofilesources"
                    {...register('bsEmissionprofilesources')}
                    control={control}
                    render={({ field, fieldState: { invalid, error } }) => {
                      return (
                        <>
                          <ReactDropdownTreeSelectMemoized
                            texts={{ placeholder: 'Select Emission Profile Sources' }}
                            data={adaptedData}
                            onChange={(currentNode, selectedNodes) => {
                              field.onChange(selectedNodes);
                              onChange(selectedNodes);
                            }}
                            onFocus={() => {
                              return field.onTouched;
                            }}
                            onBlur={() => {
                              field.onBlur();
                            }}
                            mode="hierarchical"
                            clearSearchOnChange={true}
                            isInvalid={invalid}
                          />
                          <div className="invalid-result">{errors.bsEmissionprofilesources?.message}</div>
                        </>
                      );
                    }}
                  />
                </Form.Group>
                <Form.Group className="custom-form-control">
                  <Controller
                    name="bsEnable"
                    {...register('bsEnable')}
                    control={control}
                    defaultValue="Y"
                    render={({ field }) => {
                      const parsed = field.value === 'Y' ? true : false;
                      const format = () => {
                        field.onChange(field.value === 'Y' ? 'N' : 'Y');
                      };
                      return <Form.Check custom id="bsEnable" onChange={format} checked={parsed} label="Enable" />;
                    }}
                  />
                </Form.Group>
                <div>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      closeModal();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button className="ml-2" type="submit" variant="primary" disabled={!isDirty || !isValid}>
                    save
                  </Button>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
        </Row>
      </Container>
    </>
  );
};
export default BillSettings;
