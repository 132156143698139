import { config } from '../../utilities/config';
import keycloak from '../../keycloak';

export async function getTranslationBundlesApi(organizationId) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/translation`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    }
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function getTranslationGroupsApi() {
  const response = await fetch(`${config.API_BASE_URL}/translation/translation-group`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    }
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function getTranslationValuesByGroupApi(organizationId, translationBundleId, groupId) {
  const response = await fetch(
    `${config.API_BASE_URL}/${organizationId}/translation/${translationBundleId}/translation-group/${groupId}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${keycloak.token}`
      }
    }
  );
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function createTranslationValueApi(organizationId, data) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/translation/translation-value`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function updateTranslationValueApi(organizationId, translationValueId, data) {
  const response = await fetch(
    `${config.API_BASE_URL}/${organizationId}/translation/translation-value/${translationValueId}`,
    {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${keycloak.token}`
      },
      body: JSON.stringify(data)
    }
  );
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function createTranslationBundleApi(organizationId, data) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/translation/translation-bundle`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}
