import G01 from '../../../assets/images/sdg_social_share/Social-Share-G01.svg';
import G02 from '../../../assets/images/sdg_social_share/Social-Share-G02.svg';
import G03 from '../../../assets/images/sdg_social_share/Social-Share-G03.svg';
import G04 from '../../../assets/images/sdg_social_share/Social-Share-G04.svg';
import G05 from '../../../assets/images/sdg_social_share/Social-Share-G05.svg';
import G06 from '../../../assets/images/sdg_social_share/Social-Share-G06.svg';
import G07 from '../../../assets/images/sdg_social_share/Social-Share-G07.svg';
import G08 from '../../../assets/images/sdg_social_share/Social-Share-G08.svg';
import G09 from '../../../assets/images/sdg_social_share/Social-Share-G09.svg';
import G10 from '../../../assets/images/sdg_social_share/Social-Share-G10.svg';
import G11 from '../../../assets/images/sdg_social_share/Social-Share-G11.svg';
import G12 from '../../../assets/images/sdg_social_share/Social-Share-G12.svg';
import G13 from '../../../assets/images/sdg_social_share/Social-Share-G13.svg';
import G14 from '../../../assets/images/sdg_social_share/Social-Share-G14.svg';
import G15 from '../../../assets/images/sdg_social_share/Social-Share-G15.svg';
import G16 from '../../../assets/images/sdg_social_share/Social-Share-G16.svg';
import G17 from '../../../assets/images/sdg_social_share/Social-Share-G17.svg';

export function SDGSocialShareLogoComponent(props) {
  const logo = (props) => {
    switch (props) {
      case '1':
        return G01;
      case '2':
        return G02;
      case '3':
        return G03;
      case '4':
        return G04;
      case '5':
        return G05;
      case '6':
        return G06;
      case '7':
        return G07;
      case '8':
        return G08;
      case '9':
        return G09;
      case '10':
        return G10;
      case '11':
        return G11;
      case '12':
        return G12;
      case '13':
        return G13;
      case '14':
        return G14;
      case '15':
        return G15;
      case '16':
        return G16;
      case '17':
        return G17;
      default:
        return G01;
    }
  };

  return <img alt='card-logo' style={{ height: 300, width: 300 }} src={logo(props)} />;
}
