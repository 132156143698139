import React from 'react';
import { IoWarning } from 'react-icons/io5';

const Empty = ({ title = 'Sorry, Something went wrong', icon = false, statusCode = null }) => {
  let displaytitle;
  switch (statusCode) {
    case '400':
      displaytitle = 'Invalid input. Please check your input and try again';
      break;
    case '404':
      displaytitle = 'No results found.';
      break;
    case '500':
      displaytitle = 'Internal server error. Please contact your administrator';
      break;
    default:
      displaytitle = title;
  }

  return (
    <div className="h-100 d-flex flex-column justify-content-center align-items-center">
      {icon ? icon : <IoWarning size="5rem" color="#B8BEC3" />}
      <p className="text-muted">{displaytitle}</p>
    </div>
  );
};

export default Empty;
