import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { LoadingState } from '../../utilities/constants';
import {
  createTargetEmissionApi,
  editTargetEmissionApi,
  fetchTargetEmissionApi,
  removeTargetEmissionApi
} from './targetEmissionApi';

export const fetchTargetEmissions = createAsyncThunk('targetEmission/fetchTargetEmission', async (payload) => {
  try {
    const response = await fetchTargetEmissionApi(payload.orgId, payload.entityId);
    return response;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const createTargetEmission = createAsyncThunk(
  'targetEmission/createTargetEmission',
  async (payload, { dispatch }) => {
    try {
      await createTargetEmissionApi(payload.orgId, payload.entityId, payload.data);
      dispatch(fetchTargetEmissions({ orgId: payload.orgId, entityId: payload.entityId }));
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  }
);

export const editTargetEmission = createAsyncThunk(
  'targetEmission/editTargetEmission',
  async (payload) => {
    try {
      const targetEmission = await editTargetEmissionApi(
        payload.orgId,
        payload.entityId,
        payload.targetEmissionId,
        payload.data
      );
      return targetEmission;
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  }
);

export const removeTargetEmission = createAsyncThunk(
  'targetEmission/removeTargetEmission',
  async (payload) => {
    try {
      const targetEmission = await removeTargetEmissionApi(payload.orgId, payload.entityId, payload.targetEmissionId);
      return targetEmission;
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  }
);

const targetEmissionSlice = createSlice({
  name: 'targetEmissions',
  initialState: {
    targetEmissions: [],
    status: LoadingState.idle,
    error: null
  },
  reducers: {},
  extraReducers: {
    [fetchTargetEmissions.pending]: (state) => {
      state.status = LoadingState.loading;
    },
    [fetchTargetEmissions.fulfilled]: (state, action) => {
      state.status = LoadingState.succeeded;
      state.targetEmissions = action.payload;
    },
    [fetchTargetEmissions.rejected]: (state, action) => {
      state.status = LoadingState.failed;
      state.error = action.error.message;
    },
    [editTargetEmission.fulfilled]: (state, action) => {
      const existingIndex = state.targetEmissions.findIndex((i) => i.trgtemissnId === action.payload.targetEmissionId);
      if (existingIndex >= 0) state.targetEmissions[existingIndex] = action.payload;
    }
  }
});

export default targetEmissionSlice.reducer;
