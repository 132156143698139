import React, { useEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { setLeavePrompt } from '../manage-users/usersSlice';
import { useDispatch } from 'react-redux';
import LoadingButton from '../../components/LoadingButton';

const schema = yup.object().shape({
  acntAssignedId: yup
    .string()
    .max(15, 'Account ID cannot be exceed more than 15 Characters')
    .matches(/^[a-zA-Z0-9-_]*$/, {
      message: 'Id may contain only letters, numbers and hyphen'
    })
    .required('Account ID is required'),
  acntName: yup.string().required('Account name is required'),
  acntDesc: yup.string().nullable().notRequired().max(255, 'Description cannot be exceed more than 255 Characters')
});

const AccountForm = ({
  onSubmit,
  onCancel,
  initialValues = {},
  buttonText = 'Submit',
  isEditing = false,
  isLoading,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    reset
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
    mode: 'onChange'
  });

  const { t } = useTranslation();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setLeavePrompt(isDirty));
  }, [isDirty, dispatch]);

  const onSubmitHandler = async (data) => {
    try {
      dispatch(setLeavePrompt(false));
      await onSubmit(data);
    } catch (e) {
      dispatch(setLeavePrompt(isDirty));
    }
  };
  const onCancelHandler = () => onCancel();

  return (
    <Form onSubmit={handleSubmit(onSubmitHandler)}>
      <Row>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Account ID')} *</Form.Label>
            <Form.Control {...register('acntAssignedId')} isInvalid={errors.acntAssignedId} readOnly={isEditing} />
            <Form.Control.Feedback type="invalid">{errors.acntAssignedId?.message}</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Account Name')} *</Form.Label>
            <Form.Control {...register('acntName')} isInvalid={errors.acntName} />
            <Form.Control.Feedback type="invalid">{errors.acntName?.message}</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Description')}</Form.Label>
            <Form.Control {...register('acntDesc')} as="textarea" isInvalid={errors.acntDesc} />
            <Form.Control.Feedback type="invalid">{errors.acntDesc?.message}</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Button type="submit" disabled={!isValid || !isDirty || isLoading}>
        {buttonText}
      </Button>
      {!isEditing && (
        <Button variant="secondary" className="ml-4 btn-lg" onClick={onCancelHandler}>
          {t('Cancel')}
        </Button>
      )}
      {isEditing && (
        <Button
          variant="secondary"
          className="ml-4 btn-lg"
          onClick={() => {
            reset();
          }}
        >
          {t('Cancel')}
        </Button>
      )}
      <LoadingButton isLoading={isLoading} className="ml-4 btn-lg" />
    </Form>
  );
};

export default AccountForm;
