import { Row, Col, Form } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import EmissionSourceSelection from './EmissionSourceSelection';
import { useTranslation } from 'react-i18next';
import ReactDatePicker from "react-datepicker";
import { useSelector } from 'react-redux';
import { datePickerClass, datePickerValue, parseUserInfo } from '../../../utilities/common';

const EmissionSource = ({
  control,
  register,
  errors,
  setValue,
  isEditing,
  setSelectedEmissionSource,
  isCopyHightlight,
  selectedRelEpsNode,
  dataValues,
  watch
}) => {
  const { t } = useTranslation();
  const { usrDateFormat } = parseUserInfo(useSelector((state) => state.users.user));
  const isNotChangeEpsName = selectedRelEpsNode && dataValues && dataValues.eprflsrcName === selectedRelEpsNode.eprflsrcName;
  const isNotChangeEpsId = selectedRelEpsNode && dataValues && dataValues.eprflsrcAssignedId === selectedRelEpsNode.eprflsrcAssignedId;
  const watchStartDate = watch('eprflsrcStartDate', null);
  return (
    <>
      <Row>
        <Col>
          <Form.Group className="custom-form-control">
            <Controller
              name="eprflsrcEnabled"
              control={control}
              render={({ field }) => {
                const parsed = field.value === 'Y' ? true : false;
                const format = () => {
                  field.onChange(field.value === 'Y' ? 'N' : 'Y');
                };
                return (
                  <Form.Check
                    custom
                    id="eprflsrcEnabled"
                    onChange={format}
                    checked={parsed}
                    label={t('Active')}
                  />
                );
              }}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="custom-form-control">
            <Controller
              name="eprflsrcVisible"
              control={control}
              render={({ field }) => {
                const parsed = field.value === 'Y' ? true : false;
                const format = (e) => {
                  field.onChange(field.value === 'Y' ? 'N' : 'Y');
                };
                return (
                  <Form.Check
                    custom
                    id="eprflsrcVisible"
                    onChange={format}
                    checked={parsed}
                    label={t('Visible')}
                  />
                );
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Emission Source Name')} *</Form.Label>
            <Form.Control {...register('eprflsrcName')} isInvalid={errors.eprflsrcName || isCopyHightlight && isNotChangeEpsName} />
            <Form.Control.Feedback type="invalid">{errors.eprflsrcName?.message}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Emission Source ID')} *</Form.Label>
            <Form.Control {...register('eprflsrcAssignedId')} isInvalid={errors.eprflsrcAssignedId || isCopyHightlight && isNotChangeEpsId} />
            <Form.Control.Feedback type="invalid">{errors.eprflsrcAssignedId?.message}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Reporting Name')}</Form.Label>
            <Form.Control {...register('eprflsrcReportingName')} />
          </Form.Group>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Description')}</Form.Label>
            <Form.Control as="textarea" {...register('eprflsrcDesc')} isInvalid={errors.eprflsrcDesc} />
            <Form.Control.Feedback type="invalid">{errors.eprflsrcDesc?.message}</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <EmissionSourceSelection
            register={register}
            control={control}
            setValue={setValue}
            isEditing={isEditing}
            errors={errors}
            setSelectedEmissionSource={setSelectedEmissionSource}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Period Start Date')}</Form.Label>
            <Controller
              name="eprflsrcStartDate"
              control={control}
              render={({ field, fieldState: { invalid }, formState }) => {
                return (
                  <ReactDatePicker
                    isClearable
                    dateFormat={usrDateFormat}
                    popperPlacement="bottom-end"
                    showMonthDropdown
                    showYearDropdown
                    {...datePickerClass(invalid)}
                    {...datePickerValue(field.value, field.onChange)}
                  />
                );
              }}
            />
            <Form.Control.Feedback type="invalid">{errors.eprflsrcStartDate?.message}</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Period End Date')}</Form.Label>
            <Controller
              name="eprflsrcEndDate"
              control={control}
              render={({ field, fieldState: { invalid } }) => {
                return (
                  <ReactDatePicker
                    isClearable
                    minDate={!!watchStartDate ? new Date(watchStartDate) : null}
                    showDisabledMonthNavigation
                    dateFormat={usrDateFormat}
                    showMonthDropdown
                    showYearDropdown
                    {...datePickerClass(invalid)}
                    {...datePickerValue(field.value, field.onChange)}
                  />
                );
              }}
            />
            <Form.Control.Feedback type="invalid">{errors.eprflsrcEndDate?.message}</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <Form.Group className="custom-form-control">
            <Form.Label>
              {t('Sorting Position (empty to set sorting by alphabet)')}
            </Form.Label>
            <Form.Control onWheel={(e) => e.target.blur()} type="number" {...register('weight')} isInvalid={errors.weight} />
            <Form.Control.Feedback type="invalid">{errors.weight?.message}</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

export default EmissionSource;
