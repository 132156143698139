export const toYesNoUnknown = (value, t) => (value === 'Y' ? t('Yes') : value === 'N' ? t('No') : t('Unknown'));

export const toYesNo = (value, t) => (value === 'Y' ? t('Yes') : value === 'N' ? t('No') : t('Unknown'));

/**
 *
 * @param {number} chunkSize Size of chunk
 * @param {[]} array Array to chunk
 * @returns Chunked Array
 */
export const chunkArray = (array, chunkSize) =>
  Array.from({ length: Math.ceil(array.length / chunkSize) }, (v, i) =>
    array.slice(i * chunkSize, i * chunkSize + chunkSize)
  );
