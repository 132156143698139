import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Spinner, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingState } from '../../utilities/constants';
import TreeView from '../../components/TreeView';
import { recursiveMap } from '../../utilities/treeHelpers';
import {
  fetchEmissionProfileTreeByEntity,
  fetchAssignedUsersToSource,
  fetchAvailableUsersToSource,
  assignUserToSource,
  unAssignUserToSource
} from './usersSlice';
import { fetchEntityTree } from '../entity/entitySlice';
import PageHeader from '../../components/PageHeader';
import { unwrapResult } from '@reduxjs/toolkit';
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';
import NodeIcon from '../../components/NodeIcon';
import { useTranslation } from 'react-i18next';

const AssignUsersToSources = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const entityStatus = useSelector((state) => state.entities.status);
  const entityTree = useSelector((state) => state.entities.tree);
  const assignUsersToSource = useSelector((state) => state.users.assignmentStatus);
  const emissionProfileSourceTree = useSelector((state) => state.users.emissionProfileTreeByEntity);
  const assignedUsersToSource = useSelector((state) => state.users.assignedUsersToSource);
  const availableUsersToSource = useSelector((state) => state.users.availableUsersToSource);
  const orgId = useSelector((state) => state.users.user.usrOrgId);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [selectedEPS, setSelectedEPS] = useState(null);

  const EntityItem = ({ entity }) => {
    const handleSelectEntity = async () => {
      try {
        setSelectedEntity(entity.divId);
        setSelectedEPS(null);
        const entityId = entity.divId;
        const resultAction = await dispatch(fetchEmissionProfileTreeByEntity({ orgId, entityId }));
        unwrapResult(resultAction);
      } catch (e) {
        setSelectedEntity(null);
      }
    };
    return <span onClick={handleSelectEntity}>{entity.divName}</span>;
  };

  const EmissionProfileSourceItem = ({ emissionProfileSource }) => {
    const labelName =
      emissionProfileSource.eprflName ?? emissionProfileSource.eprflcatName ?? emissionProfileSource.eprflsrcName;
    const handleSelectEmissionProfileSource = async () => {
      try {
        setSelectedEPS(emissionProfileSource.eprflsrcId ?? null);
        const entityId = selectedEntity;
        const epsId = emissionProfileSource.eprflsrcId ?? null;
        if (epsId) {
          const resultAction1 = await dispatch(fetchAssignedUsersToSource({ orgId, entityId, epsId }));
          const resultAction2 = await dispatch(fetchAvailableUsersToSource({ orgId, entityId, epsId }));
          unwrapResult(resultAction1);
          unwrapResult(resultAction2);
        }
      } catch (e) {
        selectedEPS(null);
      }
    };
    return (
      <span onClick={handleSelectEmissionProfileSource}>
        {emissionProfileSource.eprflEstdId ? null : emissionProfileSource.eprflEstdId === null ? null : (
          <NodeIcon node={emissionProfileSource} />
        )}
        {labelName}
      </span>
    );
  };

  const transformedTree = recursiveMap(entityTree ?? {}, (n) => {
    return {
      value: n.divId,
      labelRaw: n.divName,
      label: <EntityItem entity={n} />,
      isActive: selectedEntity === n.divId
    };
  });
  transformedTree.isRoot = false;

  const transformedEPSTree = recursiveMap(emissionProfileSourceTree ?? {}, (n) => {
    return {
      value: n.eprflcatId,
      labelRaw: n.eprflsrcName || '',
      label: <EmissionProfileSourceItem emissionProfileSource={n} />,
      isActive: selectedEPS === n.eprflsrcId
    };
  });
  transformedEPSTree.isRoot = false;

  const handleAssignUserToSource = async ({ usrId }) => {
    try {
      const entityId = selectedEntity;
      const epsId = selectedEPS;
      const userId = usrId;
      const resultAction = await dispatch(assignUserToSource({ orgId, entityId, epsId, userId }));
      unwrapResult(resultAction);
    } catch (e) {}
  };

  const handleUnAssignUserToSource = async ({ usrId }) => {
    try {
      const entityId = selectedEntity;
      const epsId = selectedEPS;
      const userId = usrId;
      const resultAction = await dispatch(unAssignUserToSource({ orgId, entityId, epsId, userId }));
      unwrapResult(resultAction);
    } catch (e) {}
  };

  useEffect(() => {
    if (entityStatus === LoadingState.idle) {
      dispatch(fetchEntityTree({ orgId }));
    }
  }, [orgId, entityStatus, dispatch]);

  return (
    <Container fluid>
      <Row>
        <Col>
          <PageHeader title={t('Assign Users To Source')} />
        </Col>
      </Row>
      {entityStatus === LoadingState.loading && <Spinner animation="grow" />}
      {entityStatus === LoadingState.succeeded && (
        <Row>
          <Col>
            <Row className="mr-4">
              <Col className="ml-4 tiles-component">
                <span className="pl-1 pr-1 px-2 step-span">{t('Step {{from}} of {{to}}', {from: 1, to: 3})}</span>
                <PageHeader.Secondary title={t('Select Entity')} />
                <div className="px-1">
                  <TreeView
                    initExpandLevel={0}
                    tree={transformedTree}
                    searchFunction={(n, searchText) => n.labelRaw.toLowerCase().includes(searchText.toLowerCase())}
                    enableSearch
                  />
                </div>
              </Col>
              {transformedTree && !selectedEntity && <Col className='mr-4 mb-3"' />}
              {selectedEntity && emissionProfileSourceTree && (
                <Col className="ml-4 tiles-component">
                  <span className="pl-1 pr-1 px-2 step-span">{t('Step {{from}} of {{to}}', {from: 2, to: 3})}</span>
                  <PageHeader.Secondary title={t('Select Emission Profile Source')} />
                  <TreeView
                    initExpandLevel={0}
                    tree={transformedEPSTree}
                    searchFunction={(n, searchText) => n.labelRaw.toLowerCase().includes(searchText.toLowerCase())}
                    enableSearch
                  />
                </Col>
              )}
            </Row>
            <Row className="mr-4 mb-3">
              {selectedEPS && assignedUsersToSource && availableUsersToSource && (
                <Col className="mt-4 ml-4 tiles-component">
                  <span className="pl-1 pr-1 px-2 step-span">{t('Step {{from}} of {{to}}', {from: 3, to: 3})}</span>
                  <PageHeader.Secondary title="Select Users" />
                  <div className="pl-1 pr-1 px-2 d-flex">
                    <div className="mr-3 tile-table-wrapper">
                      <span className="mb-2 tiles-title">{t('Available Users')}</span>
                      <Table className="mt-2 tiles-table-component">
                        <tbody>
                          {availableUsersToSource.map((a) => (
                            <tr key={a.usrName} onClick={() => handleAssignUserToSource(a)}>
                              <td>{a.usrName}</td>
                              <td>
                                <RiArrowRightSLine />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                    <div className="tile-table-wrapper">
                      <span className="mb-2 tiles-title">{t('Assigned Users')}</span>
                      <Table className="mt-2 tiles-table-component">
                        <tbody>
                          {assignedUsersToSource.map((a) => (
                            <tr key={a.usrName} onClick={() => handleUnAssignUserToSource(a)}>
                              <td>
                                <RiArrowLeftSLine />
                              </td>
                              <td>{a.usrName}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Col>
              )}
              <Col className="mt-4 ml-4" />
            </Row>
          </Col>
        </Row>
      )}
      {assignUsersToSource === LoadingState.failed && <div>Something Went Wrong</div>}
    </Container>
  );
};

export default AssignUsersToSources;
