import React, { useEffect, useMemo } from 'react';
import { Button, Col, Container, Row, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import PageHeader from '../../components/PageHeader';
import { LoadingState } from '../../utilities/constants';
import { fetchBusinessMeasures } from './businessMeasuresSlice';
import { AiOutlineEdit } from 'react-icons/ai';
import { IoEyeOutline } from 'react-icons/io5';
import DataTable from '../../components/DataTable';
import { useTranslation } from 'react-i18next';

const ManageBusinessMeasures = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const match = useRouteMatch();
  const bsnsMeasureStatus = useSelector((state) => state.businessMeasures.status);
  const bsnsMeasures = useSelector((state) => state.businessMeasures.businessMeasures);
  const orgId = useSelector((state) => state.users.user.usrOrgId);
  const loggedUserStatus = useSelector((state) => state.users.loggedUserStatus);

  const bmTableColumns = useMemo(
    () => [
      {
        Header: t('Business Measure Id'),
        accessor: 'bsnsmsrtypAssignedId',
        sortType: 'string'
      },
      {
        Header: t('Business Measure Name'),
        accessor: 'bsnsmsrtypName',
        sortType: 'string'
      },
      {
        Header: t('Description'),
        accessor: 'bsnsmsrtypDesc',
        sortType: 'string'
      },
      {
        Header: '',
        accessor: 'actions',
        actionColumn: true,
        disableGlobalFilter: false,
        disableSortBy: true,
        Cell: ({ row }) => {
          const bm = row.original;
          return (
            <div className="d-flex action-buttons">
              <Link to={`${match.url}/${bm.bsnsmsrtypId}`}>
                <Button variant="outline-secondary" className="mr-3">
                  <IoEyeOutline />
                </Button>
              </Link>
              <Link to={`${match.url}/${bm.bsnsmsrtypId}/edit`}>
                <Button variant="outline-secondary">
                  <AiOutlineEdit />
                </Button>
              </Link>
            </div>
          );
        }
      }
    ],
    [match.url, t]
  );

  useEffect(() => {
    if (bsnsMeasureStatus === LoadingState.idle && loggedUserStatus === LoadingState.succeeded) {
      dispatch(fetchBusinessMeasures({ orgId }));
    }
  }, [orgId, loggedUserStatus, bsnsMeasureStatus, dispatch]);

  return (
    <Container fluid>
      <Row>
        <Col>
          <PageHeader
            title={t('Manage Business Measures')}
            actions={
              <Button to={`${match.url}/new`} as={Link}>
                + {t('Create Business Measure')}
              </Button>
            }
          />
        </Col>
      </Row>
      {bsnsMeasureStatus === LoadingState.loading && <Spinner animation="grow" />}
      {bsnsMeasureStatus === LoadingState.succeeded && (
        <Row>
          <Col>
            <DataTable columns={bmTableColumns} data={bsnsMeasures} />
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default ManageBusinessMeasures;
