import { components } from 'react-select';

const { Option } = components;

const SelectIconOption = (props) => {
  const {
    data: { label, Icon },
  } = props;

  return (
    <Option {...props}>
      <div>
        {Icon ? <Icon /> : null}
        <span className="align-middle">{label}</span>
      </div>
    </Option>
  );
}

export default SelectIconOption;
