import { unwrapResult } from '@reduxjs/toolkit';
import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PageHeader from '../../../components/PageHeader';
import { LoadingState } from '../../../utilities/constants';
import ActionForm from './ActionForm';
import { createAction } from '../reductionPlanSlice';

const NewAction = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const orgId = useSelector((state) => state.users.user.usrOrgId);
  const [saveActionState, setSaveActionState] = useState(LoadingState.idle);
  const isBusy = saveActionState === LoadingState.loading;

  const handleCreateAction = async (data) => {
    try {
      setSaveActionState(LoadingState.loading);
      const resultAction = await dispatch(createAction({ orgId, data }));
      if (!resultAction.error) {
        unwrapResult(resultAction);
        history.push('/reduction-plan/actions');
      }
      setSaveActionState(LoadingState.idle)
    } catch {
      setSaveActionState(LoadingState.idle);
    }
  };

  const handleCancelAction = () => {
    history.push('/reduction-plan/actions');
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <PageHeader
            title={t('Add action')}
            enableBackButton
          />
          <ActionForm
            onSubmit={handleCreateAction}
            buttonText={t('Create')}
            disabled={isBusy}
            onCancel={handleCancelAction}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default NewAction;
