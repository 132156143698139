import { Link } from 'react-router-dom';
import { IoMdSettings } from 'react-icons/io';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSdgDataByOrg, setFocusedGoal } from '../disclosureSlice';
import { LoadingState } from '../../../utilities/constants';
import placeholderAnalytics from '../../../assets/images/analytics.svg';
import { GoalsCardHeader } from '../disclosure-components/GoalsCardHeader';
import { DisclosureSetupModal } from '../disclosure-components/DisclosureModal';
import { KPIDataCaptureCard } from '../disclosure-components/KPIDataCaptureCard';
import { GoalAchievedComponent } from '../disclosure-components/GoalAchieveComponent';
import { Button, Card, Nav, Spinner, Tab } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export function ManageSDGs() {
  const  { t } = useTranslation();
  const [setupModalShow, setSetupModalShow] = useState(false);

  const { sdgData, getSdgDataByOrgStatus, focusedGoal } = useSelector((state) => state.disclosure);

  const userInfo = useSelector((state) => state.users.user);

  //Main two variables for modal and the page
  const [sdgsList, setSdgsList] = useState();
  const [refList, setRefList] = useState();
  const [sdgsDetails, setSdgsDetails] = useState();

  const fetchSDGs = async () => {
    setSdgsList(sdgsDetails);
    if (sdgsDetails.length > 0) {
      setSetupModalShow(true);
    }
  };
  const dispatch = useDispatch();

  useEffect(() => {
    if (userInfo && getSdgDataByOrgStatus === LoadingState.idle) {
      dispatch(getSdgDataByOrg(userInfo?.usrOrgId));
    }
  }, [userInfo, dispatch, getSdgDataByOrgStatus]);

  useEffect(() => {
    if (getSdgDataByOrgStatus === LoadingState.succeeded) {
      setSdgsDetails(sdgData);
      setRefList(sdgData);
    }
  }, [getSdgDataByOrgStatus, sdgData]);

  const isChecked = (kpis) => {
    const selectedKpis = [];
    kpis.forEach((kpi) => {
      if (kpi.isApplicable === 'Yes') {
        selectedKpis.push(kpi);
      } else if (kpi.isApplicable === 'No') {
        if (Object.keys(kpi?.latestRecord).length > 0) {
          selectedKpis.push(kpi);
        }
      }
    });
    if (selectedKpis.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const selectedItemList = (sdgsDetails) => {
    let selectedItems = sdgsDetails.filter((sdgItem) => isChecked(sdgItem.kpis));
    return selectedItems;
  };

  const getInitialTabIndex = (sdgsDetails) => {
    let selectedItems = selectedItemList(sdgsDetails);
    if (selectedItems.length > 0) {
      return Number(selectedItems[0]['orderNo']);
    } else {
      return 0;
    }
  };

  const checkFocusAvailable = (sdgsDetails, focusedIndex) => {
    if (focusedIndex) {
      let selectedItems = selectedItemList(sdgsDetails);
      let isFocusAvailable = selectedItems.find((item) => item.orderNo === focusedIndex[0]);
      if (isFocusAvailable) {
        return focusedIndex;
      } else {
        return getInitialTabIndex(sdgsDetails);
      }
    } else {
      return getInitialTabIndex(sdgsDetails);
    }
  };

  const showDataCaptureCard = (kpiItem) => {
    if (kpiItem.isApplicable === 'Yes') {
      return true;
    } else if (kpiItem.isApplicable === 'No') {
      if (Object.keys(kpiItem.latestRecord).length > 0) {
        return true;
      } else {
        return false;
      }
    } else if (kpiItem.isApplicable === 'N/A') {
      return false;
    } else {
      return false;
    }
  };

  const tabOnchange = (key) => {
    dispatch(setFocusedGoal([key]));
    // setCurrentTab([key]);
  };

  return (
    <Fragment>
      {/* Loading component */}
      <section>
        {(getSdgDataByOrgStatus === LoadingState.loading && !sdgsDetails?.length) && (
          <div className='loader-container'>
            <Spinner variant='primary' className='loader' animation='border' />
          </div>
        )}
      </section>
      <div className='inner-content disclosure-page container-fluid'>
        {/* Page header */}
        <section>
          <div className='page-title-wrapper'>
            <div className='d-flex justify-content-between mb-3'>
              <h1 className='page-title'>{t('Sustainable Development Goals')}</h1>
              <Button
                variant='outline-primary'
                onClick={fetchSDGs}
                disabled={getSdgDataByOrgStatus === LoadingState.loading}
              >
                {getSdgDataByOrgStatus === LoadingState.loading ? (
                  <Spinner animation='border' />
                ) : (
                  <Fragment>
                    <IoMdSettings /> {t('Edit setup')}
                  </Fragment>
                )}
              </Button>
            </div>

            <p>
              {t('Strengthen the sustainability performance of your business and learn about the Sustainable Development Goals. The SDG tool will provide your organization with a report which demonstrates your alignment with SDGs. In continuing with this process you accept the responsibility to fill in all of the KPIs accurately. See what you can achieve here!')}
            </p>
          </div>
        </section>
        <Card className='mt-5 disclosure-page-card'>
          {sdgsDetails && sdgsDetails.length > 0 && selectedItemList(sdgsDetails).length > 0 ? (
            <Tab.Container
              id='left-tabs-example'
              // defaultActiveKey={sdgsDetails && focusedGoal ? focusedGoal : getInitialTabIndex(sdgsDetails, focusedGoal)}
              defaultActiveKey={checkFocusAvailable(sdgData, focusedGoal)}
              // activeKey={currentTab}
              onSelect={tabOnchange}
            >
              <div className='container-wrapper'>
                <div className='navigation-menu'>
                  <Nav variant='pills' className='flex-column text-left'>
                    {sdgsDetails.map((sdgItem, idx) => {
                      return (
                        <Fragment>
                          {isChecked(sdgItem.kpis) && (
                            <Fragment key={idx}>
                              <Nav.Item className='mb-1'>
                                <Nav.Link
                                  eventKey={Number(sdgItem.orderNo)}
                                  style={{ color: 'black', fontWeight: '600' }}
                                >
                                  {t('{{orderNo}}. {{goalName}}', { orderNo: Number(sdgItem.orderNo), goalName: t(sdgItem.goalName)})}
                                </Nav.Link>
                              </Nav.Item>
                            </Fragment>
                          )}
                        </Fragment>
                      );
                    })}
                  </Nav>
                </div>
                <div className='tab-content-side'>
                  <Tab.Content>
                    {sdgsDetails && sdgsDetails.length > 0 && sdgsDetails.map((sdgItem, goalIdx) => {
                        return (
                          <Fragment>
                            {isChecked(sdgItem.kpis) && (
                              <Tab.Pane key={goalIdx} eventKey={Number(sdgItem.orderNo)}>
                                <GoalsCardHeader
                                  color={sdgItem?.color}
                                  goalName={sdgItem?.goalName}
                                  imagePath={sdgItem?.imagePath}
                                  description={sdgItem?.description}
                                  esgIndicators={sdgItem?.esgIndicators}
                                />

                                <GoalAchievedComponent
                                  goalName={sdgItem?.goalName}
                                  goalNo={sdgItem?.orderNo}
                                  kpis={sdgItem?.kpis}
                                />
                                <div className='mt-2 mb-2'>
                                  <p>
                                    <strong>{t('Note :')}</strong> <br />
                                    {t('Click on rating slider to capture KPI data.')}
                                    <br />
                                    {t('After completing the rating for each selected KPI to its maximum, you will be awarded a goal achieved badge.')}
                                  </p>
                                </div>
                                <div className='mt-4 kpi-card-wrapper'>
                                  {sdgItem.kpis &&
                                    sdgItem.kpis.length > 0 &&
                                    sdgItem.kpis.map((kpiItem, kpiIdx) => {
                                      return (
                                        <Fragment>
                                          {showDataCaptureCard(kpiItem) && (
                                            <KPIDataCaptureCard
                                              kpiItem={kpiItem}
                                              goalId={sdgItem.id}
                                              userInfo={userInfo}
                                              goalName={sdgItem.goalName}
                                              key={`${kpiIdx}-${goalIdx}`}
                                              isApplicable={kpiItem.isApplicable}
                                            />
                                          )}
                                        </Fragment>
                                      );
                                    })}
                                </div>
                              </Tab.Pane>
                            )}
                          </Fragment>
                        );
                      })}
                  </Tab.Content>
                </div>
              </div>
            </Tab.Container>
          ) : (
            <Fragment>
              <div className='inner-content planned-actions w-100'>
                <div className='d-lg-flex align-items-center justify-content-between'>
                  <div className='placeholder'>
                    <img src={placeholderAnalytics} alt='Planned Actions Placeholder' className='img-fluid' />
                    <h5>{t('You havent setup any KPIs yet.')}</h5>
                    <Link className='btn btn-secondary btn-dashboard' to={'/my-disclosure/disclosure'}>
                    {t('Go to Disclosure')}
                    </Link>
                  </div>
                </div>
              </div>
            </Fragment>
          )}
        </Card>
        {sdgsList && sdgsList.length > 0 && refList.length > 0 && sdgsList && getSdgDataByOrgStatus === LoadingState.succeeded && (
          <DisclosureSetupModal
            refList={refList}
            sdgsList={sdgsList}
            userInfo={userInfo}
            setSdgsList={setSdgsList}
            setupModalShow={setupModalShow}
            setSetupModalShow={setSetupModalShow}
            modalName={'Edit'}
          />
        )}
      </div>
    </Fragment>
  );
}
