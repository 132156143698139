import React from 'react';

const TitleDivider = ({ title }) => (
  <div className="form-divider mb-4 mt-4">
    <span>{title}</span>
    <div />
  </div>
);

export default TitleDivider;
