import React, { useMemo, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import PageHeader from '../../components/PageHeader';
import DataTable from '../../components/DataTable';
import { createEntityGroup } from './entityGroupsSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NewEntityGroup = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const assignedEntitiesToEntityGroup = useSelector((state) => state.entityGroups.assignedEntitiesToEntityGroup);
  const [showCreateEGModal, setShowCreateEGModal] = useState(false);
  const [selectedEGName, setSelectedEGName] = useState();
  const [isCreatingEG, setIsCreatingEG] = useState(false);
  const orgId = useSelector((state) => state.users.user.usrOrgId);

  const canCreateEG = selectedEGName && !isCreatingEG;

  const openCreateModal = () => setShowCreateEGModal(true);
  const closeCreateModal = () => {
    setSelectedEGName('');
    setShowCreateEGModal(false);
  };

  const assignedEntitiesGroupsTableColumns = useMemo(
    () => [
      {
        Header: t('Entity'),
        accessor: 'divName',
        sortType: 'string'
      },
      {
        Header: t('First Level Parent Entity'),
        accessor: 'divTopDivName',
        sortType: 'string'
      },
      {
        Header: t('Parent Entity'),
        accessor: 'divParentName',
        sortType: 'string'
      }
    ],
    [t]
  );

  const handleCreateEntityGroup = async () => {
    setIsCreatingEG(true);
    try {
      const data = {
        egName: selectedEGName
      };
      const resultAction = await dispatch(createEntityGroup({ orgId, data }));
      const egId = resultAction.payload.egId;
      unwrapResult(resultAction);
      closeCreateModal();
      setSelectedEGName(null);
      history.push(`/admin/settings/entity-groups/${egId}`);
    } catch (error) {
    } finally {
      setIsCreatingEG(false);
    }
  };

  return (
    <div>
      <Row>
        <Col>
          <PageHeader
            title={t("Manage Entity Groups")}
            actions={
              <div className="d-flex align-items-center form-group">
                <Button onClick={openCreateModal}>+ {t('Create Group')}</Button>
              </div>
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <DataTable columns={assignedEntitiesGroupsTableColumns} data={assignedEntitiesToEntityGroup} />
        </Col>
      </Row>
      <Modal show={showCreateEGModal} onHide={closeCreateModal} className="sisde-modal">
        <Modal.Header>
          <Modal.Title>{t('New Entity Group')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="custom-form-control">
              <Form.Label>{t('Group Name')} *</Form.Label>
              <Form.Control value={selectedEGName} onChange={(e) => setSelectedEGName(e.target.value)} type="text" />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeCreateModal}>
            {t('Cancel')}
          </Button>
          <Button variant="primary" onClick={handleCreateEntityGroup} disabled={!canCreateEG}>
            {t('Create')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default NewEntityGroup;
