import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect, useMemo, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import DataTable from '../../../components/DataTable';
import DownloadHelper from '../../../components/DownloadHelper';
import { LoadingState } from '../../../utilities/constants';
import { fetchEmissionDataUploads, getDownloadLink } from './uploadDataSlice';
import { formatDate, parseUserInfo } from '../../../utilities/common';

export const handleFileDownload = async (dispatch, upldflId, errorFile) => {
  try {
    const resultAction = await dispatch(getDownloadLink({ upldflId, errorFile }));
    const response = unwrapResult(resultAction);
    return response;
  } catch (error) {
    toast.error(error.message);
  }
};

const UploadDataTable = ({ uploadedReload, setUploadedReload }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { status, uploads } = useSelector((state) => state.uploadData);
  const sortedUploads = [...uploads].sort((a, b) => a.upldflId - b.upldflId);
  const [isInit, setIsInit] = useState(false);
  const [isReloadData, setIsReloadData] = useState(false);
  parseUserInfo(useSelector((state) => state.users.user));

  const tableColumns = useMemo(
    () => [
      {
        Header: t('Name'),
        accessor: 'upldflName',
        Cell: ({ row, value }) => (
          <DownloadHelper getLinkObject={() => handleFileDownload(dispatch, row.original.upldflId)} label={value} />
        )
      },
      {
        Header: t('Type'),
        accessor: 'upldflSourceType'
      },
      {
        Header: t('File Description'),
        accessor: 'upldflDesc'
      },
      {
        Header: t('Created By'),
        accessor: 'upldflCreatedBy'
      },
      {
        Header: t('Created Date'),
        accessor: 'upldflCreatedDate',
        Cell: ({ value }) => formatDate(value)
      },
      {
        Header: t('Status'),
        accessor: 'upldflStatus'
      },
      {
        Header: t('Errors'),
        accessor: 'upldflErrorCount',
        Cell: ({ row, value }) =>
          value > 0 ? (
            <DownloadHelper
              getLinkObject={() => handleFileDownload(dispatch, row.original.upldflId, true)}
              label={value}
            />
          ) : (
            <span style={{ padding: '0.375rem 0.75rem' }}>{value}</span>
          )
      }
    ],
    [t, dispatch]
  );

  useEffect(() => {
    const dispatchFetchData = () => dispatch(fetchEmissionDataUploads());
    if (!isInit) {
      setIsInit(true);
      dispatchFetchData();
    }
    if (uploadedReload > 0) {
      setTimeout(() => {
        setIsReloadData(true);
        dispatchFetchData();
        setUploadedReload(uploadedReload - 1);
      }, 10 * 1000);
    }
  }, [dispatch, isInit, setUploadedReload, uploadedReload]);

  if (status === LoadingState.loading && !isReloadData) return <Spinner animation="grow" />;

  return (
    <div>
      <DataTable
        columns={tableColumns}
        data={sortedUploads.map((upload) => ({
          ...upload,
          upldflCreatedDate: upload.upldflCreatedDate.substring(0, 10)
        }))}
        // data={[...uploads].sort((a, b) => a.upldflId - b.upldflId)}
        defaultSort="Uploaded Date"
      />
    </div>
  );
};

export default UploadDataTable;
