import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import { fetchEmissionsByEntityOrMetric } from '../reportsSlice';
import { LoadingState } from '../../../utilities/constants';
import { useSelector } from 'react-redux';
import { Bar } from 'react-chartjs-2';
import { Select } from '../configurations/Select';
import { useLocation } from 'react-router-dom';
import ReportContainer from '../ReportContainer';
import { useHistory } from 'react-router-dom';
import {
  setupReportRangePeriod,
  getCommonFilterPeriodRange,
  getCurrentReportPeriod,
  getReportYearTypeOptions,
  getReportRangePeriod,
  parseFavouriteData,
  getIntervalTypeOptions,
  getEmissionTypeOptions,
  reportYearList,
  generateRandomColors,
  generateChartLegend,
  formatNumber,
  reportUnitByTypeId,
  parseUnitJson,
  displayTargetUnit,
  createInitChartConfig,
  createInitChartPlugins,
  getMonthAndQuarterNames,
  getStartDatePeriod,
  CALENDAR,
} from '../reportHelper';
import { useTranslation } from 'react-i18next';

const EmissionsByEntityOrMetric = () => {
  const { t } = useTranslation();
  const chartRef = useRef(null);
  const history = useHistory();
  const { filterData } = parseFavouriteData(useLocation().search);
  const configRef = useRef(null);
  const userInfo = useSelector((state) => state.users.user);
  const currentReportPeriod = getCurrentReportPeriod(userInfo.usrOrgOptions);
  const [reportYear, setReportYear] = useState(currentReportPeriod.reportYear);
  const reportRangePeriod = getReportRangePeriod(userInfo.usrOrgOptions, reportYear);
  const [filterParams, setFilterParams] = useState({
    reportYearType: CALENDAR,
    intervalType: 'annual',
    reportYear: new Date().getUTCFullYear(),
    recentTime: null,
    emissionTypeId: 1,
    groupByType: 'entity',
    ...currentReportPeriod,
    ...(filterData || {})
  });
  const [chartData, setChartData] = useState({});
  const [selectedReportYearType, setSelectedReportYearType] = useState(filterParams.reportYearType);
  const emissionsByEntityOrMetric = useSelector((state) => state.reports.emissionsByEntityOrMetric);
  const emissionsByEntityOrMetricStatus = useSelector((state) => state.reports.emissionsByEntityOrMetricStatus);
  const [startDatePeriod, setStartDatePeriod] = useState(getStartDatePeriod(reportRangePeriod, filterParams.reportYearType));

  const changeDateRangePeriod = (usrOrgOptions, year) => {
    const rangePeriod = getReportRangePeriod(usrOrgOptions, year);
    if (rangePeriod) {
      configRef.current.setFormValue('startDate', rangePeriod.startDate);
      configRef.current.setFormValue('endDate', rangePeriod.endDate);
    }
  };
  const reportYearHanlder = (event) => {
    setReportYear(event.target.value);
    changeDateRangePeriod(userInfo.usrOrgOptions, event.target.value);
  };
  const selectedReportYearTypeHandler = ({ target }) => {
    if (target.value === 'SETUP') {
      setupReportRangePeriod(history);
    } else {
      setSelectedReportYearType(target.value);
      if (target.value === CALENDAR) {
        changeDateRangePeriod({}, null);
      } else {
        changeDateRangePeriod(userInfo.usrOrgOptions, reportYear);
      }
    }
  };
  const getFilterPeriodRangeHandler = (data) => {
    return getCommonFilterPeriodRange(data, reportRangePeriod);
  };

  const groupByTypeOptions = [
    { label: t('Entity'), value: 'entity' },
    { label: t('Entity Business Number'), value: 'entityBusinessNumber' },
    { label: t('Metric'), value: 'metric' },
    { label: t('Entity Group'), value: 'entityGroup' },
    { label: t('Emission Profile Group'), value: 'emissionProfileGroup' }
  ];

  const plugins = createInitChartPlugins([]);

  const add = (accumulator, a) => {
    return accumulator + a;
  };

  const getLabels = useCallback(() => {
    const labels = [];
    const dataset = [];
    const { unit } = reportUnitByTypeId(parseInt(filterParams.emissionTypeId), t);
    if (emissionsByEntityOrMetricStatus === LoadingState.succeeded) {
      const { monthNames, quarterNames, addToYear } = getMonthAndQuarterNames(t, startDatePeriod);
      let groups = [];
      const sortedEmissionsByEntityOrMetric = [...emissionsByEntityOrMetric].sort((a, b) => {
        if (a.year === b.year) return a.quarter - b.quarter;
        else return a.year > b.year ? 1 : -1;
      });
      sortedEmissionsByEntityOrMetric.forEach((bucket) => {
        if ('month' in bucket) labels.push(`${monthNames[bucket.month]} ${bucket.year}`);
        else if ('quarter' in bucket) labels.push(t('{{quarter}} {{year}}', {
          quarter: quarterNames[bucket.quarter],
          year: bucket.year + addToYear[bucket.quarter]
        }));
        else labels.push(bucket.year);
        bucket.values.forEach((child) => groups.push(child));
      });

      groups = groups.filter(
        (group, index, groups) => groups.map((e) => e.measure_or_id).indexOf(group.measure_or_id) === index
      );
      generateRandomColors(true);
      groups.forEach((a) => {
        const data = [];
        sortedEmissionsByEntityOrMetric.forEach((bucket) => {
          const found = bucket.values.find((child) => {
            if (child.measure_or_id === a.measure_or_id) return child;
            else return null;
          });
          if (found) {
            if (isNaN(found.value)) {
              data.push(0);
            } else {
              data.push(parseFloat(found.value));
            }
          } else data.push(0);
        });
        dataset.push({
          axis: 'y',
          label: a.name,
          data,
          units: parseUnitJson(a.unit),
          backgroundColor: generateRandomColors()
        });
      });
      dataset.forEach((singleDataset) => {
        singleDataset.title = singleDataset.label;
        singleDataset.total = singleDataset.data.reduce(add, 0);
        singleDataset.displayUnit = displayTargetUnit(singleDataset.units, unit);
        const { label, total, displayUnit } = singleDataset;
        singleDataset.label = `${label} (${formatNumber(total)} ${displayUnit})`;
      });
    }
    return { labels: labels, datasets: dataset };
  }, [
    t,
    emissionsByEntityOrMetricStatus,
    emissionsByEntityOrMetric,
    filterParams.emissionTypeId,
    startDatePeriod,
  ]);

  useEffect(() => {
    if (emissionsByEntityOrMetricStatus === LoadingState.loading) {
      setChartData({});
    }
    if (emissionsByEntityOrMetricStatus === LoadingState.succeeded) {
      setChartData(getLabels());
    }
  }, [
    emissionsByEntityOrMetricStatus, emissionsByEntityOrMetric, getLabels,
  ]);

  useEffect(() => {
    generateChartLegend(chartRef);
  }, [emissionsByEntityOrMetricStatus, chartData]);

  const fetchEmissionsByEntityOrMetricHandler = async (dispatch, payload) => {
    payload.data = {
      intervalType: filterParams.intervalType,
      emissionTypeId: parseInt(filterParams.emissionTypeId),
      groupByType: filterParams.groupByType,
      reportYear: parseInt(filterParams.reportYear || reportYear),
      reportYearType: filterParams.reportYearType,
      ...payload.data,
      reportPeriodEnding: currentReportPeriod.reportPeriodEnding,
    };
    return await dispatch(fetchEmissionsByEntityOrMetric(payload));
  };

  const getFavouriteFilterDataHandler = (params, filters) => {
    const paramsData = {
      ...params,
      intervalType: filters.intervalType,
      reportYear: parseInt(filters.reportYear || reportYear),
      emissionTypeId: parseInt(filters.emissionTypeId),
      groupByType: filters.groupByType,
      reportYearType: filters.reportYearType,
      reportPeriodEnding: currentReportPeriod.reportPeriodEnding,
    };
    setFilterParams(paramsData);
    setStartDatePeriod(getStartDatePeriod(reportRangePeriod, filters.reportYearType));
    return paramsData;
  };

  const options = useMemo(() => {
    const totalArray = [];
    if (chartData.datasets && chartData.datasets.length > 0 && emissionsByEntityOrMetricStatus === LoadingState.succeeded) {
      chartData.datasets[0].data.map((dataPoint, index) => {
        let total = dataPoint;
        let i = 1;
        while (i < chartData.datasets.length) {
          total += chartData.datasets[i].data[index];
          i++;
        }
        return totalArray.push(total);
      });
    }
    const { title } = reportUnitByTypeId(parseInt(filterParams.emissionTypeId), t);
    return createInitChartConfig({
      scales: {
        xAxes: [
          {
            stacked: true,
            gridLines: {
              drawOnChartArea: false
            },
          }
        ],
        yAxes: [
          {
            stacked: true,
            ticks: {
              beginAtZero: true,
              suggestedMax: Math.max(...totalArray) + (5 / 100) * Math.max(...totalArray)
            },
            scaleLabel: {
              display: true,
              labelString: title,
            }
          }
        ]
      },
      tooltips: {
        filter: function (tooltipItem, data) {
          let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return value !== 0;
        },
        callbacks: {
          label: (tooltipItem, data) => {
            const { title, displayUnit } = data.datasets[tooltipItem.datasetIndex];
            return `${title}: ${formatNumber(tooltipItem.value)} ${displayUnit}`;
          },
          beforeBody: (tooltipItem, data) => {
            let total = 0;
            data.datasets.forEach((dataset, datasetIndex) => {
              if (chartRef.current.chartInstance.isDatasetVisible(datasetIndex)) {
                total += dataset.data[tooltipItem[0].index] || 0;
              }
            });
            return t('Total: {{number}}', { number: formatNumber(total) });
          }
        },
      },
    });
  }, [t, chartData.datasets, emissionsByEntityOrMetricStatus, filterParams.emissionTypeId]);

  const parameters = [
    {
      key: t('Group by type'),
      value: filterParams.groupByType
        .split(/(?=[A-Z])/)
        .join(' ')
        .toLocaleLowerCase()
    },
    {
      key: t('Interval type'),
      value: filterParams.intervalType
    }
  ];

  const chartRender = () => {
    return (
      <>
        <div className="chart-graph">
          <Bar
            data={chartData}
            options={options}
            ref={chartRef}
            plugins={plugins}
          />
        </div>
        <div id="js-legend" className="chart-legend"></div>
      </>
    )
  };

  const extraConfigRender = (isFavReport) => {
    return (
      <>
        <Select
          disabled={isFavReport}
          label={t("Report Year Type")}
          name="reportYearType"
          options={getReportYearTypeOptions(t, reportRangePeriod)}
          onChange={selectedReportYearTypeHandler}
        />
        <Select
          disabled={isFavReport || selectedReportYearType === CALENDAR}
          label={t("Report Year")}
          name="reportYear"
          onChange={reportYearHanlder}
          options={reportYearList()}
        />
        <Select
          disabled={isFavReport}
          label={t("Interval")}
          name="intervalType"
          options={getIntervalTypeOptions(t)}
        />
        <Select
          disabled={isFavReport}
          label={t("CO2-e (t) or Usage")}
          name="emissionTypeId"
          options={getEmissionTypeOptions(t)}
        />
        <Select
          disabled={isFavReport}
          label={t("Group By")}
          name="groupByType"
          options={groupByTypeOptions}
        />
      </>
    );
  };

  return (
    <ReportContainer
      reportName={t('Emissions by Entity or Metric')}
      reportSubTitle={t('Emission Footprint Overview by Entity')}
      configRef={configRef}
      isShowAllocation
      fetchReportData={fetchEmissionsByEntityOrMetricHandler}
      fetchReportStatus={emissionsByEntityOrMetricStatus}
      reportData={emissionsByEntityOrMetric}
      reportRender={chartRender}
      extraConfigRender={extraConfigRender}
      getFavouriteFilterData={getFavouriteFilterDataHandler}
      getFilterPeriodRange={getFilterPeriodRangeHandler}
      extraConfigDefault={{
        reportYearType: filterParams.reportYearType,
        intervalType: filterParams.intervalType,
        reportYear: parseInt(filterParams.reportYear),
        emissionTypeId: parseInt(filterParams.emissionTypeId),
        groupByType: filterParams.groupByType,
      }}
      extraConfigFormState={{
        reportYearType: selectedReportYearType,
      }}
      chartRef={chartRef}
      extraParameters={parameters}
    />
  );
};

export default EmissionsByEntityOrMetric;
