import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { getYear } from 'date-fns';
import { toast } from 'react-toastify';
import { Col, Spinner, Card } from 'react-bootstrap';
import { Line } from 'react-chartjs-2';
import { fetchMovingAverageDashboard } from '../dashboardSlice';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingState } from '../../../utilities/constants';
import Empty from '../../../components/Empty';
import DashboardPrintButton from '../components/DashboardPrintButton';
import {
  createInitChartConfig,
  createInitChartPlugins,
  formatNumber,
  getMonthAndQuarterNames,
  getStartDatePeriod,
  getReportRangePeriod,
  getCurrentReportPeriod,
} from '../../reports/reportHelper';
import { useTranslation } from 'react-i18next';
import { parseUserInfo } from '../../../utilities/common';

const DashboardMovingAverage = ({ filters }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [data, setData] = useState({});
  const chartRef = useRef(null);
  const dataRef = useRef(null);

  const { orgId, usrDateFormat, usrTimezone } = parseUserInfo(useSelector((state) => state.users.user));
  const movingAverage = useSelector((state) => state.dashboard.movingAverageDashboard);
  const movingAverageStatus = useSelector((state) => state.dashboard.movingAverageStatusDashboard);
  const loggedUserStatus = useSelector((state) => state.users.loggedUserStatus);
  const movingAverageErrorStatus = useSelector((state) => state.dashboard.mvError);
  const userInfo = useSelector((state) => state.users.user);
  const currentReportPeriod = getCurrentReportPeriod(userInfo.usrOrgOptions);
  const reportRangePeriod = getReportRangePeriod(userInfo.usrOrgOptions, currentReportPeriod.reportYear);
  const startDatePeriod = getStartDatePeriod(reportRangePeriod, currentReportPeriod.reportYearType);

  const plugins = createInitChartPlugins([]);

  const getLabels = useCallback(() => {
    let labels = [];
    let data = [];
    if (movingAverageStatus === LoadingState.succeeded) {
      const { monthNames, quarterNames, addToYear } = getMonthAndQuarterNames(t, startDatePeriod);
      if (movingAverage.values.title === "Quarterly Emissions") {
        movingAverage.values.periods.forEach((bucket) => {
          labels.push(t('{{quarter}} {{year}}', {
            quarter: quarterNames[bucket.quarter],
            year: bucket.year + addToYear[bucket.quarter]
          }));
        });
      } else {
        movingAverage.values.periods.forEach((bucket) => {
          labels.push(`${monthNames[bucket.month]} ${bucket.year}`);
        });
      }
      const formattedMonthlyEmissionValues = movingAverage.values.values.map((value) => (value == null ? 0 : value));
      const formattedMovingAverageValues = movingAverage.movingAverages.values.map((value) =>
        value == null ? 0 : value
      );
      data = [
        {
          label: t(movingAverage.values.title),
          fill: false,
          lineTension: 0,
          backgroundColor: 'rgba(75,192,192,0.2)',
          borderColor: 'rgba(75,192,192,1)',
          borderWidth: 2,
          data: formattedMonthlyEmissionValues
        },
        {
          label: t(movingAverage.movingAverages.title),
          fill: false,
          lineTension: 0,
          backgroundColor: 'rgba(1, 167, 81, 0.2)',
          borderColor: 'rgba(1, 167, 81, 1)',
          borderWidth: 2,
          data: formattedMovingAverageValues
        }
      ];
    }
    return { labels: labels, datasets: data };
  }, [t, movingAverageStatus, movingAverage, startDatePeriod]);
  /*
  
  */
  useEffect(() => {
    if (movingAverageStatus === LoadingState.loading) {
      setData({});
    }
    if (movingAverageStatus === LoadingState.succeeded) {
      setData(getLabels());
    }
  }, [dispatch, orgId, movingAverageStatus, getLabels]);

  useEffect(() => {
    const getEndDate = () => {
      if (getYear(new Date()) === Number(filters.year)) {
        return new Date();
      }
      return new Date(filters.year, 11, 31);
    };
    const data = {
      usrTimezone,
      usrDateFormat,
      endDate: getEndDate(),
      entitiesIdsToInclude: filters.selectedEntity,
      epsIdsToInclude: [],
      intervalType: 'monthly',
      emissionTypeId: 1,
      isApplyExcludedEPSs: true,
    };
    if (orgId && loggedUserStatus === LoadingState.succeeded) {
      try {
        dispatch(fetchMovingAverageDashboard({ orgId, data }));
      } catch (e) {
        toast.error(e.message);
      }
    }
  }, [filters, orgId, dispatch, loggedUserStatus, usrTimezone, usrDateFormat]);

  const options = useMemo(() => {
    return createInitChartConfig({
      maintainAspectRatio: true,
      scales: {
        xAxes: [
          {
            gridLines: {
              drawOnChartArea: false
            }
          }
        ],
        yAxes: [
          {
            ticks: {
              suggestedMin: 0,
              suggestedMax: 5
            },
            scaleLabel: {
              display: true,
              labelString: t('Total - CO2-e (t)')
            }
          }
        ]
      },
      tooltips: {
        filter: function (tooltipItem, data) {
          let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return value !== 0;
        },
        callbacks: {
          label: (tooltipItem, data) => {
            const { label } = data.datasets[tooltipItem.datasetIndex];
            return t('{{label}}: {{number}} tCO2e', { label, number: formatNumber(tooltipItem.value) });
          },
        }
      }
    });
  }, [t]);

  return (
    <Col lg="6">
      <Card>
        <Card.Body>
          {/* <Card.Title>Moving Average Graph</Card.Title> */}
          <div className="mt-2 mb-4 chart_print_wrapper" id="dmv_chart" ref={dataRef}>
            <div className="dashboard-report-header">
              <strong className="report-text">{t('Moving Average Graph')}</strong>
              <div className="button-wrapper">
                <DashboardPrintButton
                  title={t("Moving Average Graph")}
                  id="mv_chart"
                  downloadRef={dataRef}
                  disabled={movingAverageErrorStatus ? true : false}
                />
              </div>
            </div>
            {movingAverageStatus === LoadingState.loading && <Spinner className="loader" animation="grow" />}
            {movingAverageStatus === LoadingState.succeeded && (
              <Line
                width={600}
                height={415}
                data={data}
                options={options}
                redraw={true}
                ref={chartRef}
                plugins={plugins}
              />
            )}
            {movingAverageStatus === LoadingState.failed && <Empty statusCode={movingAverageErrorStatus} />}
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default DashboardMovingAverage;
