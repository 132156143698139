import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Empty from '../../../components/Empty';
import { LoadingState } from '../../../utilities/constants';
import { createTarget, getTargets } from '../reductionPlanSlice';
import { Row, Col, Card, Button, Modal, Form, Spinner } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import Placeholder from '../../../assets/images/medal.svg';
import ChangeGoalModal from './targets-components/ChangeGoalModal';
import { Link } from 'react-router-dom';
import { Fragment } from 'react';
import placeholderAnalytics from '../../../assets/images/analytics.svg';
import { formatNumber } from '../../reports/reportHelper';
import { Trans, useTranslation } from 'react-i18next';
import { differenceInDays } from 'date-fns';

export function ManageTargets() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const mainActivityAnalytics = ['@todo need implement in finish analytic api'];
  const userInfo = useSelector((state) => state.users.user);
  const { targets, targetStatus, createTargetStatus, updateTargetStatus } = useSelector((state) => state.reductionPlan);
  const orgId = userInfo.usrOrgId;
  const [step1ModalShow, setStep1ModalShow] = useState(false);
  const [step2ModalShow, setStep2ModalShow] = useState(false);
  const [step3ModalShow, setStep3ModalShow] = useState(false);
  const [step4ModalShow, setStep4ModalShow] = useState(false);
  const [step5ModalShow, setStep5ModalShow] = useState(false);
  const [updateModalShow, setUpdateModalShow] = useState(false);

  const [updateGoal, setUpdateGoal] = useState('');
  const [targetYearDate, setTargetYearDate] = useState(null);
  const [targetStartYearDate, setTargetStartYearDate] = useState(null);

  // target inputs
  const [targetYear, setTargetYear] = useState(null);
  const [targetMonth, setTargetMonth] = useState(null);
  const [targetStartYear, setTargetStartYear] = useState(null);
  const [targetStartMonth, setTargetStartMonth] = useState(null);

  const originMonths = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
  const transMonths = [
    t('Jan'), t('Feb'), t('Mar'), t('Apr'), t('May'), t('Jun'),
    t('Jul'), t('Aug'), t('Sep'), t('Oct'), t('Nov'), t('Dec'),
  ].reduce((obj, val, idx) => {
    obj[originMonths[idx]] = val;
    return obj;
  }, {});
  useEffect(() => {
    if (orgId && targetStatus === LoadingState.idle) {
      dispatch(getTargets({ orgId }));
    }
  }, [dispatch, orgId, targetStatus]);

  const resetForm = () => {
    setTargetYearDate(null);
    setTargetStartYearDate(null);
    setTargetYear(null);
    setTargetMonth(null);
    setTargetStartYear(null);
    setTargetStartMonth(null);
  };

  async function handleSetTarget() {
    const formData = {};
    formData.targetYear = targetYear;
    formData.targetMonth = targetMonth;
    formData.targetStartYear = targetStartYear;
    formData.targetStartMonth = targetStartMonth;
    formData.isPrimaryTarget = true;
    formData.title = t('Become Net Zero');
    formData.description = t('Simple explainer of the NetZero Goal');

    const req = await dispatch(
      createTarget({
        data: formData,
        orgId
      })
    );
    if (req) {
      setStep5ModalShow(false);
    }
  }
  const remainDateCalculate = (endYear, endMonth) => {
    const targetMonthNo = originMonths.findIndex((x) => x === endMonth);
    const currentDate = new Date();
    const date2 = new Date(`${currentDate.getUTCFullYear()}-${currentDate.getUTCMonth() + 1}-${currentDate.getUTCDate()}`);
    const date1 = new Date(`${endYear}-${targetMonthNo + 1}-${lastday(Number(endYear), Number(targetMonthNo))}`);

    const diffInDays = differenceInDays(date1, date2);
    return Math.round(diffInDays);
  };

  const lastday = function (y, m) {
    return new Date(y, m + 1, 0).getDate();
  };

  return (
    <div className='inner-content container-fluid'>
      <div className='page-title-wrapper'>
        <h1 className='page-title'>{t('Goals')}</h1>
        <p>{t('Set the goals and targets that will help drive your business towards Net Zero.')}</p>
      </div>

      <section>
        {targetStatus === LoadingState.loading && (
          <div className='loader-container'>
            <Spinner variant='primary' className='loader' animation='border' />
          </div>
        )}
      </section>
      {targetStatus === LoadingState.failed && <Empty title={t('Sorry, Goals list was not found')} />}

      {targetStatus === LoadingState.succeeded && (
        <>
          {Object.keys(targets).length === 0 && (
            <Card className='goal-card placeholder-card text-center'>
              <div className='goal-card-image'>
                <img src={Placeholder} alt='Primary Goal' className='img-fluid' />
              </div>
              <label className='goal-label'>{t('Primary Goal')}</label>
              <h2 className='goal-title'>{t('Become Net Zero')}</h2>
              <p className='goal-description'>{t('Your business operates with Net Zero carbon emissions')}</p>
              <div>
                <Button onClick={() => setStep1ModalShow(true)}>{t('Set Goal')}</Button>
              </div>
            </Card>
          )}

          {Object.values(targets).map((target, index) => {
            return (
              <Fragment>
                {target.isPrimaryTarget && (
                  <Card className='goal-card primary-goal'>
                    <div className='edit-goal'>
                      <Button
                        className='text-uppercase'
                        onClick={() => {
                          setUpdateModalShow(true);
                          setUpdateGoal(targets[0]);
                        }}
                        variant='secondary'
                      >
                        {t('Change Goal')}
                      </Button>
                    </div>
                    <Card.Header>
                      <div className='goal-card-image'>
                        <img alt='' src={Placeholder} className='Primary Goal' />
                      </div>
                      <div>
                        <label className='goal-label mb-0'>{t('Primary Goal')}</label>
                        <h2 className='primary-goal-title '>
                          <Trans
                            i18nKey="Become Net Zero <style1>by </style1> <style2>{{targetMonth}}, {{targetYear}}</style2>"
                            values={{
                              targetMonth: transMonths[target.targetMonth],
                              targetYear: target.targetYear,
                            }}
                            components={{
                              style1: <span className='by' />,
                              style2: <span className='primary-goal-month' />,
                            }}
                          />
                        </h2>
                        <p className='mb-0'>
                          <Trans
                            i18nKey="Make sure to create <url>{{action}}</url> to help your business achieve this goal."
                            values={{ action: t('Action Items') }}
                            components={{
                              url: <Link to='/reduction-plan/actions' className='font-weight-bold text-success' />,
                            }}
                          />
                        </p>
                      </div>
                    </Card.Header>

                    {Object.keys(targets).length > 0 ? (
                      <Card.Body>
                        <Row>
                          <Col md={6} className='goal-countdown'>
                            <div className='ml-3'>
                              <label>{t('Countdown to the Goal')}</label>
                              <h4>
                                {t('{{total}} days to go', { total: formatNumber(remainDateCalculate(targets[0]?.targetYear, targets[0]?.targetMonth)) })}
                              </h4>
                            </div>
                          </Col>
                          <Col md={12} lg={6} className="d-flex justify-content-end">
                            <div className='goal-forecast'>
                              <div className='goal-stat mr-0 mr-md-2 mt-2 mt-md-0'>
                                {mainActivityAnalytics.length === 0 ? (
                                  <Fragment>
                                    <span>{t('Start year emissions will be available once you add emission records')}</span>
                                  </Fragment>
                                ) : (
                                  <Fragment>
                                    <Trans
                                      i18nKey="<line>You started with emitting</line> <break/> <line>{{currentEmision}}</line> <line>tCO2-e start in</line> <line>{{startYear}}</line>"
                                      values={{
                                        currentEmision: formatNumber(targets[0]?.currentEmision),
                                        startYear: targets[0]?.targetStartYear ? targets[0]?.targetStartYear : new Date().getFullYear(),
                                      }}
                                      components={{
                                        break: <br />,
                                        line: <span />,
                                      }}
                                    />
                                  </Fragment>
                                )}
                              </div>
                              <div className='goal-stat mr-0 mr-md-2 mt-2 mt-md-0'>
                                {mainActivityAnalytics.length === 0 ? (
                                  <Fragment>
                                    <span>
                                      <Trans
                                        i18nKey="Target year emission will be  <break /> available once you add emission records"
                                        components={{ break: <br /> }}
                                      />
                                    </span>
                                  </Fragment>
                                ) : (
                                  <Fragment>
                                    <Trans
                                      i18nKey="<line>You are forecast to emit</line> <break/> <line>{{forcastEmision}}</line> <line>tCO2-e in</line> <line>{{targetYear}}</line>"
                                      values={{
                                        forcastEmision: formatNumber(targets[0]?.forcastEmision),
                                        targetYear: targets[0]?.targetYear ? targets[0]?.targetYear : new Date().getFullYear(),
                                      }}
                                      components={{
                                        break: <br />,
                                        line: <span />,
                                      }}
                                    />
                                  </Fragment>
                                )}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Card.Body>
                    ) : (
                      <div className='inner-content planned-actions w-100'>
                        <div className='d-lg-flex align-items-center justify-content-between'>
                          <div className='placeholder'>
                            <img src={placeholderAnalytics} alt='Planned Actions Placeholder' className='img-fluid' />
                            <h5>{t('You haven’t added any emission record yet')}</h5>
                            <div className='center'>
                              <Link className='btn btn-secondary btn-dashboard' to={'/my-business/activities'}>
                                {t('Go to Business Activities')}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Card>
                )}
              </Fragment>
            );
          })}
        </>
      )}

      {/* <Card>
        <div>
          <span>Goal 2</span>
          <span>Lorem ipsum dolor sit amet</span>
        </div>
      </Card> */}

      <Modal
        animation={false}
        show={step1ModalShow}
        onHide={() => {
          resetForm();
          setStep1ModalShow(false);
        }}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        className='custom-modal set-goal-modal'
        backdrop='static'
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className='text-center'>
          <div className='set-goal-header'>
            <img alt='' src={Placeholder} className='Primary Goal' />
            <label>{t('Become Net Zero')}</label>
          </div>
          <h4 className='set-goal-title'>{t('You are about to do something really important.')}</h4>
          <p className='set-goal-description'>
            {t('In fact, it’s the most important thing you can do for the future sustainability of your business')}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='none'
            onClick={() => {
              resetForm();
              setStep1ModalShow(false);
              setStep5ModalShow(true);
            }}
          >
            {t('Skip to end')}
          </Button>
          <div className='set-goal-pagination'>
            <span className='active'></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <Button
            variant='primary'
            onClick={() => {
              resetForm();
              setStep1ModalShow(false);
              setStep2ModalShow(true);
            }}
          >
            {t('Tell me more')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={step2ModalShow}
        onHide={() => {
          resetForm();
          setStep2ModalShow(false);
        }}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        className='custom-modal set-goal-modal'
        backdrop='static'
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className='text-center'>
          <div className='set-goal-header'>
            <img alt='' src={Placeholder} className='Primary Goal' />
            <label>{t('Become Net Zero')}</label>
          </div>
          <h4 className='set-goal-title'>
            {t('Target setting can be difficult and requires thought and support from your organisation')}
          </h4>
          <p className='set-goal-description'>
            {t('It’s important to get your whole organisation on board and motivated to help achieve your target')}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='none'
            onClick={() => {
              resetForm();
              setStep2ModalShow(false);
              setStep5ModalShow(true);
            }}
          >
            {t('Skip to end')}
          </Button>
          <div className='set-goal-pagination'>
            <span></span>
            <span className='active'></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <Button
            variant='primary'
            onClick={() => {
              resetForm();
              setStep2ModalShow(false);
              setStep3ModalShow(true);
            }}
          >
            {t('Ok, Got it')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={step3ModalShow}
        onHide={() => {
          resetForm();
          setStep3ModalShow(false);
        }}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        className='custom-modal set-goal-modal'
        backdrop='static'
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className='text-center'>
          <div className='set-goal-header'>
            <img alt='' src={Placeholder} className='Primary Goal' />
            <label>{t('Become Net Zero')}</label>
          </div>
          <h4 className='set-goal-title'>
            {t('It’s really important to set a target that’s feasible and works for your business')}
          </h4>
          <p className='set-goal-description'>
            {t('In our experience, businesses generally take 3-5 years to become net zero')}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='none'
            onClick={() => {
              resetForm();
              setStep3ModalShow(false);
              setStep5ModalShow(true);
            }}
          >
            {t('Skip to end')}
          </Button>
          <div className='set-goal-pagination'>
            <span></span>
            <span></span>
            <span className='active'></span>
            <span></span>
            <span></span>
          </div>
          <Button
            variant='primary'
            onClick={() => {
              resetForm();
              setStep3ModalShow(false);
              setStep4ModalShow(true);
            }}
          >
            {t('Understood')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={step4ModalShow}
        onHide={() => {
          resetForm();
          setStep4ModalShow(false);
        }}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        className='custom-modal set-goal-modal'
        backdrop='static'
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className='text-center'>
          <div className='set-goal-header'>
            <img alt='' src={Placeholder} className='Primary Goal' />
            <label>{t('Become Net Zero')}</label>
          </div>
          <h4 className='set-goal-title'>{t('The planet needs you, so the sooner you act the better!')}</h4>
          <p className='set-goal-description'>
            {t('Our planet is in a really bad state, and we haven’t got a minute to waste!')}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='none'
            onClick={() => {
              resetForm();
              setStep4ModalShow(false);
              setStep5ModalShow(true);
            }}
          >
            {t('Skip to end')}
          </Button>
          <div className='set-goal-pagination'>
            <span></span>
            <span></span>
            <span></span>
            <span className='active'></span>
            <span></span>
          </div>
          <Button
            variant='primary'
            onClick={() => {
              resetForm();
              setStep4ModalShow(false);
              setStep5ModalShow(true);
            }}
          >
            {t('Let’s do this!')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={step5ModalShow}
        onHide={() => {
          resetForm();
          setStep5ModalShow(false);
        }}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        className='custom-modal set-goal-modal'
        backdrop='static'
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className='text-center'>
          <div className='set-goal-header'>
            <img alt='' src={Placeholder} className='Primary Goal' />
            <label>{t('Become Net Zero')}</label>
          </div>
          <h4 className='set-goal-title'>
            <span>{t('{{orgName}} started Net Zero journey on', { orgName: userInfo?.usrOrgName })}</span>
            <br />
            <div className='set-goal-date set-goal-year'>
              <DatePicker
                placeholderText='Year'
                defaultValue={targetStartYearDate}
                selected={targetStartYearDate}
                onChange={(date) => {
                  setTargetStartYearDate(date);
                  setTargetStartYear(new Date(date).getFullYear());
                }}
                showYearPicker
                dateFormat='yyyy'
              />
            </div>
            <div className='set-goal-date set-goal-month mb-3'>
              <Form.Control
                // defaultValue={targetStartMonth}
                defaultValue={targetStartMonth}
                as='select'
                size='sm'
                onChange={(e) => setTargetStartMonth(e.target.value)}
              >
                <option selected disabled hidden value={null}>
                  {t('Month')}
                </option>
                <option value='jan'>{t('January')}</option>
                <option value='feb'>{t('February')}</option>
                <option value='mar'>{t('March')}</option>
                <option value='apr'>{t('April')}</option>
                <option value='may'>{t('May')}</option>
                <option value='jun'>{t('June')}</option>
                <option value='jul'>{t('July')}</option>
                <option value='aug'>{t('August')}</option>
                <option value='sep'>{t('September')}</option>
                <option value='oct'>{t('October')}</option>
                <option value='nov'>{t('November')}</option>
                <option value='dec'>{t('December')}</option>
              </Form.Control>
            </div>
            <br />
            <span className='mt-2'>{t('and aims to be Net Zero by')}</span>
            <br />
            <div className='set-goal-date set-goal-year'>
              <DatePicker
                defaultValue={targetYearDate}
                placeholderText='Year'
                selected={targetYearDate}
                onChange={(date) => {
                  setTargetYearDate(date);
                  setTargetYear(new Date(date).getFullYear());
                }}
                showYearPicker
                dateFormat='yyyy'
                minDate={new Date()}
              />
            </div>
            <div className='set-goal-date set-goal-month'>
              <Form.Control
                defaultValue={targetMonth}
                as='select'
                size='sm'
                onChange={(e) => setTargetMonth(e.target.value)}
              >
                <option selected disabled hidden value={null}>
                  {t('Month')}
                </option>
                <option value='jan'>{t('January')}</option>
                <option value='feb'>{t('February')}</option>
                <option value='mar'>{t('March')}</option>
                <option value='apr'>{t('April')}</option>
                <option value='may'>{t('May')}</option>
                <option value='jun'>{t('June')}</option>
                <option value='jul'>{t('July')}</option>
                <option value='aug'>{t('August')}</option>
                <option value='sep'>{t('September')}</option>
                <option value='oct'>{t('October')}</option>
                <option value='nov'>{t('November')}</option>
                <option value='dec'>{t('December')}</option>
              </Form.Control>
            </div>
          </h4>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='none'
            onClick={() => {
              resetForm();
              setStep5ModalShow(false);
            }}
          >
            {t('Cancel')}
          </Button>
          <div className='set-goal-pagination'>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span className='active'></span>
          </div>
          <Button
            disabled={
              createTargetStatus === LoadingState.loading ||
              !targetStartYear ||
              !targetStartMonth ||
              !targetMonth ||
              !targetYear
            }
            variant='primary'
            onClick={() => {
              handleSetTarget();
            }}
          >
            {t('Set Goal')}
          </Button>
        </Modal.Footer>
      </Modal>
      {updateGoal && (
        <ChangeGoalModal
          orgId={orgId}
          updateModalShow={updateModalShow}
          setUpdateModalShow={setUpdateModalShow}
          orgName={userInfo?.usrOrgName}
          updateGoal={updateGoal}
          updateTargetStatus={updateTargetStatus}
          sites={[]}
        />
      )}
    </div>
  );
}
