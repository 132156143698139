import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm, useWatch } from 'react-hook-form';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import { datePickerClass, datePickerValue, parseUserInfo } from '../../utilities/common';
import { LoadingState } from '../../utilities/constants';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEmissionProfileUnits } from './emissionSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import DownloadHelper from '../../components/DownloadHelper';
import { handleFileDownload } from './upload-data/UploadDataTable';
import { FiDelete } from 'react-icons/fi';
import { fetchListCurrencies } from '../currency/currencySlice';
import Select from 'react-select';
import { setLeavePrompt } from '../manage-users/usersSlice';
import { uploadAttachmentApi } from './emissionApi';
import { toast } from 'react-toastify';
import LoadingButton from '../../components/LoadingButton';
import { groupReportUnitOptions } from '../reports/reportHelper';
import { fetchReportUnits } from '../organization/OrganizationSlice';

const EmissionForm = ({ onSubmit, entityName, EPName, disabled, buttonText, initialValues = {}, isEdit = false }) => {
  const { t } = useTranslation();
  const createSchema = yup.object().shape({
    eStartDate: yup.string().required(t('Start date is required')).typeError(t('Please enter a valid date')),
    eEndDate: yup.string().required(t('End date is required')).typeError(t('Please enter a valid date')),
    eContribution: yup.number().required(t('Contribution is required')).typeError(t('Contribution must be a number')),
    eContributionUnit: yup.string().required(t('Unit is required')).required(t('Contribution unit is required')),
    eComment: yup.string().nullable().notRequired().max(255, t('Comment has a Maximum of 255 Characters')),
    eCost: yup.string().nullable().transform(value => !value ? null : value)
      .matches(/^\d*(\.)?(\d{1,2})?$/, { message: t('Cost values must be a number and accepted to 2 decimal places') })
  });
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors, isValid, isDirty }
  } = useForm({
    defaultValues: { ...initialValues },
    resolver: yupResolver(createSchema),
    mode: 'onChange'
  });

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isShowUploadFile, setIsShowUploadFile] = useState(!initialValues?.eAttachment);
  const [disablePopup, setDisablePopup] = useState(false);
  const [unitState, setUnitState] = useState(LoadingState.idle);
  const [units, setUnits] = useState([]);
  const [showEditWarningModal, setShowEditWarningModal] = useState(false);
  const [saveEmissionData, setSaveEmissionData] = useState(null);
  const { orgId, usrDateFormat } = parseUserInfo(useSelector((state) => state.users.user));
  const orgReportUnits = useSelector((state) => state.organizations.reportUnits);

  const consumptionUnit = useWatch({
    control,
    name: 'eContributionUnit'
  });

  useEffect(() => {
    dispatch(fetchReportUnits(orgId));
  }, [dispatch, orgId]);

  const loggedUserStatus = useSelector((state) => state.users.loggedUserStatus);
  const currencyStatus = useSelector((state) => state.currencies.status);
  const listCurrencies = useSelector((state) => state.currencies.availableCurrencies);
  useEffect(() => {
    if (currencyStatus === LoadingState.idle && loggedUserStatus === LoadingState.succeeded) {
      dispatch(fetchListCurrencies({ orgId }));
    }
  }, [orgId, loggedUserStatus, currencyStatus, dispatch]);

  const handleConfirmEditEmission = () => {
    confirmSubmitHandler(saveEmissionData);
  };

  const closeConfirmModal = () => {
    setIsLoading(false);
    setShowEditWarningModal(false);
  }
  
  const handleSelectFile = (e) => setSelectedFile(e.target.files[0]);

  useEffect(() => {
    dispatch(setLeavePrompt(isDirty));
  }, [isDirty, dispatch]);

  const onSubmitHandler = async (data) => {
    try {
      setIsLoading(true);
      dispatch(setLeavePrompt(false));
      await doSubmitHandler(data);
    } catch (e) {
      setIsLoading(false);
      toast.error(e.message);
      dispatch(setLeavePrompt(isDirty));
    }
  };

  const doSubmitHandler = (data) => {
    if (isEdit) {
      setSaveEmissionData(data);
      setShowEditWarningModal(true);
    } else {
      confirmSubmitHandler(data);
    };
  };

  const confirmSubmitHandler = async (data) => {
    setDisablePopup(true);
    if (selectedFile) {
      const { key, name } = await uploadAttachmentApi(orgId, selectedFile);
      data.attachmentKey = key;
      data.attachmentName = name;
    } else if (!isShowUploadFile) {
      data.attachmentKey = 'KEEP';
      data.attachmentName = null;
    }
    if (data.eCost) {
      data.eCost = Number(data.eCost);
    }
    await onSubmit(data);
  };

  const watchStartDate = watch('eStartDate', null);
  const watchEndDate = watch('eEndDate', null);

  useEffect(() => {
    const fetchUnits = async () => {
      try {
        setUnitState(LoadingState.loading);
        const resultAction = await dispatch(fetchEmissionProfileUnits());
        if (resultAction.payload) {
          const result = unwrapResult(resultAction);
          setUnits(result);
        }
        setUnitState(LoadingState.succeeded);
      } catch {
        setUnitState(LoadingState.failed);
      }
    };
    fetchUnits();
  }, [dispatch]);

  const renderUploadFile = () => {
    if (isShowUploadFile) {
      return (
        <Form.Group>
          <Form.File id="formControlAttachmentFile" label={t("Attachment File")} onChange={handleSelectFile} />
        </Form.Group>
      );
    }
    const { eAttachment } = initialValues;
    return (
      <>
        <DownloadHelper getLinkObject={() => handleFileDownload(dispatch, eAttachment.upldflId)} label={eAttachment.upldflName} />
        <Button variant="link" onClick={() => setIsShowUploadFile(true)} style={{ color: 'red' }}>
          Remove <FiDelete className="ml-1" />
        </Button>
      </>
    );
  };
  return (
    <Form onSubmit={handleSubmit(onSubmitHandler)}>
      <Row>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Emission Source')}</Form.Label>
            <Form.Control value={EPName} readOnly />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Entity')}</Form.Label>
            <Form.Control value={entityName} readOnly />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Period Start Date')}*</Form.Label>
            <Controller
              name="eStartDate"
              control={control}
              render={({ field, fieldState: { invalid }, formState }) => {
                return (
                  <ReactDatePicker
                    isClearable
                    popperPlacement="bottom-end"
                    dateFormat={usrDateFormat}
                    maxDate={!!watchEndDate ? new Date(watchEndDate) : null}
                    showMonthDropdown
                    showYearDropdown
                    {...datePickerClass(invalid)}
                    {...datePickerValue(field.value, field.onChange)}
                  />
                );
              }}
            />
            <Form.Control.Feedback type="invalid">{errors.eStartDate?.message}</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Period End Date')}*</Form.Label>
            <Controller
              name="eEndDate"
              control={control}
              render={({ field, fieldState: { invalid } }) => {
                return (
                  <ReactDatePicker
                    isClearable
                    minDate={!!watchStartDate ? new Date(watchStartDate) : null}
                    showDisabledMonthNavigation
                    dateFormat={usrDateFormat}
                    showMonthDropdown
                    showYearDropdown
                    {...datePickerClass(invalid)}
                    {...datePickerValue(field.value, field.onChange)}
                  />
                );
              }}
            />
            <Form.Control.Feedback type="invalid">{errors.eEndDate?.message}</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Contribution')}*</Form.Label>
            <Form.Control {...register('eContribution')} isInvalid={errors.eContribution} />
            <Form.Control.Feedback type="invalid">{errors.eContribution?.message}</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Unit')}*</Form.Label>
            <Controller
              name="eContributionUnit"
              control={control}
              render={({ field }) => {
                const format = (d) => field.onChange(d?.value);
                const options = groupReportUnitOptions(t, orgReportUnits, units || []);
                const getSelectedUnit = () => {
                  let selected = { value: consumptionUnit, label: consumptionUnit };
                  options.forEach((item) => {
                    item.options.forEach((unit) => {
                      if (consumptionUnit === unit.value) {
                        selected = unit;
                      }
                    });
                  });
                  return selected;
                };
                return (
                  <Select
                    value={consumptionUnit ? getSelectedUnit() : null}
                    options={options}
                    onChange={format}
                    placeholder={t('Select Unit')}
                    isDisabled={unitState !== LoadingState.succeeded}
                  />
                );
              }}
            />
            <Form.Control.Feedback type="invalid">{errors.eContributionUnit?.message}</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Cost')}</Form.Label>
            <Form.Control
              onWheel={(e) => e.target.blur()}
              {...register('eCost')}
              isInvalid={errors.eCost}
            />
            <Form.Control.Feedback type="invalid">{errors.eCost?.message}</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Currency')}</Form.Label>
            <Controller
              name="eCurrencyId"
              control={control}
              render={({ field }) => {
                const value = field.value || null;
                const label = value ? listCurrencies.find(item => item.value === value)?.label : null;
                return <Select
                  value={{ value, label }}
                  options={listCurrencies}
                  onChange={(e) => field.onChange(e.value)}
                />;
              }}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Bill/Invoice Number')}</Form.Label>
            <Form.Control {...register('eContributorId')} isInvalid={errors.eContributorId} className="form-control" />
            <Form.Control.Feedback type="invalid">{errors.eContributorId?.message}</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Provider')}</Form.Label>
            <Form.Control {...register('eProvider')} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Comment')}</Form.Label>
            <Form.Control
              as="textarea"
              style={{ height: '100px' }}
              {...register('eComment')}
              isInvalid={errors.eComment}
            />
            <Form.Control.Feedback type="invalid">{errors.eComment?.message}</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Estimate')}</Form.Label>
            <Controller
              name="eEstimate"
              control={control}
              render={({ field }) => {
                const parsed = field.value === 'Y' ? true : false;
                const format = () => {
                  field.onChange(field.value === 'Y' ? 'N' : 'Y');
                };
                return (
                  <Form.Check
                    custom
                    id="eEstimate"
                    onChange={format}
                    checked={parsed}
                    label={t('Estimate')}
                  />
                );
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          {renderUploadFile()}
        </Col>
      </Row>
      <Button className="mt-3 mb-5" type="submit" disabled={!isValid || (!isDirty && !selectedFile) || disabled || isLoading}>
        {buttonText}
      </Button>
      <LoadingButton className="mt-3 mb-5 ml-1" isLoading={isLoading} />
      <Modal show={showEditWarningModal} onHide={closeConfirmModal} className="sisde-modal">
        <Modal.Header>
          <Modal.Title>{t('Confirm to update all linked emissions')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {t('Do you confirm to update all linked emissions too?')}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeConfirmModal}>
            {t('Cancel')}
          </Button>
          <Button variant="danger" onClick={handleConfirmEditEmission} disabled={disablePopup}>
            {t('Confirm')}
          </Button>
        </Modal.Footer>
      </Modal>
    </Form>
  );
};

export default EmissionForm;
