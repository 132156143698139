import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Form, Row } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import { datePickerClass, datePickerValue, parseUserInfo } from '../../utilities/common';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { selectRootEntity } from './entitySlice';
import { setLeavePrompt } from '../manage-users/usersSlice';
import { fetchListCurrencies } from '../currency/currencySlice';
import { LoadingState } from '../../utilities/constants';
import Select from 'react-select';
import { createEntityValidateSchema } from './validateSchema';
import LoadingButton from '../../components/LoadingButton';

const EntityForm = ({
  onSubmit,
  onCancel,
  onDelete,
  initialValues = {},
  parentId,
  buttonText = 'Create',
  isEditing = false,
}) => {
  const { t } = useTranslation();
  const createSchema = createEntityValidateSchema(t);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid, isDirty },
    watch
  } = useForm({
    defaultValues: {
      divEnabled: 'Y',
      divAllocEnabled: 'N',
      ...initialValues,
      divAllocationRules: initialValues?.divOperationControl ? 'divOperationControl' : 'divEquity',
      divCurrency: initialValues.divCurrency?.currencyId
    },
    resolver: yupResolver(createSchema),
    mode: 'onChange'
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLeavePrompt(isDirty));
  }, [isDirty, dispatch]);

  const { orgId, usrDateFormat } = parseUserInfo(useSelector((state) => state.users.user));
  const loggedUserStatus = useSelector((state) => state.users.loggedUserStatus);
  const currencyStatus = useSelector((state) => state.currencies.status);
  const listCurrencies = useSelector((state) => state.currencies.availableCurrencies);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (currencyStatus === LoadingState.idle && loggedUserStatus === LoadingState.succeeded) {
      dispatch(fetchListCurrencies({ orgId }));
    }
  }, [orgId, loggedUserStatus, currencyStatus, dispatch]);

  const onSubmitHandler = async (data) => {
    if (data.divAllocEnabled === 'Y' && data.divAllocationRules === 'divOperationControl') {
      data.divEquity = null;
    } else if (data.divAllocEnabled === 'Y') {
      data.divOperationControl = null;
    }
    try {
      setIsLoading(true);
      dispatch(setLeavePrompt(false));
      await onSubmit(data);
    } catch (e) {
      dispatch(setLeavePrompt(isDirty));
      setIsLoading(false);
    }
  };

  const rootEntity = useSelector(selectRootEntity);
  const [isEnabledLocalization, setIsEnabledLocalization] = useState(true);
  const [isShowLocalization, setShowLocalization] = useState(false);
  const onDeleteHandler = () => {
    dispatch(setLeavePrompt(false));
    onDelete();
  };
  const onCancelHandler = () => onCancel();

  useEffect(() => {
    if (initialValues?.divId === rootEntity?.divId) {
      setIsEnabledLocalization(false);
      setShowLocalization(false);
    }
  }, [rootEntity, parentId, initialValues?.divId]);

  const watchDivEffectiveStartDate = watch('divEffectiveStartDate', null);
  const watchDivEffectiveEndDate = watch('divEffectiveEndDate', null);
  const watchDivAllocStartDate = watch('divAllocStartDate', null);
  const watchDivAllocEndDate = watch('divAllocEndDate', null);
  const watchdivAllocEnabled = watch('divAllocEnabled', null);
  const watchDivAllocationRules = watch('divAllocationRules', null);

  return (
    <Form onSubmit={handleSubmit(onSubmitHandler)}>
      <div className={isEditing ? 'form-divider mb-4 mt-2' : 'form-divider mb-4 mt-4'}>
        <span>{t('General Details')}</span>
        <div></div>
      </div>
      <Row>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Entity Id')} *</Form.Label>
            <Form.Control {...register('divAssignedId')} isInvalid={errors.divAssignedId} />
            <Form.Control.Feedback type="invalid">{t(errors.divAssignedId?.message)}</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Entity Name')} *</Form.Label>
            <Form.Control {...register('divName')} isInvalid={errors.divName} />
            <Form.Control.Feedback type="invalid">{t(errors.divName?.message)}</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Form.Group className="custom-form-control">
        <Form.Label>{t('Entity Description')}</Form.Label>
        <Form.Control as="textarea" {...register('divDesc')} isInvalid={errors.divDesc} />
        <Form.Control.Feedback type="invalid">{t(errors.divDesc?.message)}</Form.Control.Feedback>
      </Form.Group>
      <Row>
        <Col>
          <Form.Group className="custom-form-control allocation-form">
            <Form.Label>{t('Effective Start Date')}</Form.Label>
            <Controller
              name="divEffectiveStartDate"
              control={control}
              render={({ field, fieldState: { invalid }, formState }) => {
                return (
                  <ReactDatePicker
                    isClearable
                    dateFormat={usrDateFormat}
                    maxDate={!!watchDivEffectiveEndDate ? new Date(watchDivEffectiveEndDate) : null}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    {...datePickerValue(field.value, field.onChange)}
                    {...datePickerClass(invalid)}
                  />
                );
              }}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Effective End Date')}</Form.Label>
            <Controller
              name="divEffectiveEndDate"
              control={control}
              render={({ field, fieldState: { invalid }, formState }) => {
                return (
                  <ReactDatePicker
                    isClearable
                    dateFormat={usrDateFormat}
                    minDate={!!watchDivEffectiveStartDate ? new Date(watchDivEffectiveStartDate) : null}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    {...datePickerValue(field.value, field.onChange)}
                    {...datePickerClass(invalid)}
                  />
                );
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Industry Code')}</Form.Label>
            <Form.Control {...register('divIndustryCode')} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Business Number')}</Form.Label>
            <Form.Control {...register('divBusinessNo')} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="custom-form-control">
            <Controller
              name="divEnabled"
              control={control}
              render={({ field }) => {
                const parsed = field.value === 'Y' ? true : false;
                const format = () => {
                  field.onChange(field.value === 'Y' ? 'N' : 'Y');
                };
                return (
                  <Form.Check
                    custom
                    id="divEnabled"
                    onChange={format}
                    checked={parsed}
                    label={t('Active')}
                  />
                );
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      <div className="form-divider mb-4 mt-4">
        <span>{t('Allocation Settings')}</span>
        <div></div>
      </div>
      <Row>
        <Col>
          <Form.Group className="custom-form-control">
            <Controller
              name="divAllocEnabled"
              control={control}
              render={({ field }) => {
                const parsed = field.value === 'Y' ? true : false;
                const format = () => field.onChange(field.value === 'Y' ? 'N' : 'Y');
                return (
                  <Form.Check
                    custom
                    id="divAllocEnabled"
                    onChange={format}
                    checked={parsed}
                    disabled={!isEnabledLocalization}
                    label={t('Enable Allocations')}
                  />
                );
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      <div style={{ display: watchdivAllocEnabled === 'Y' ? '' : 'none' }}>
        <Row>
          <Col>
            <Form.Group className="custom-form-control">
              <Form.Label>{t('Allocation Start Date')}</Form.Label>
              <Controller
                name="divAllocStartDate"
                control={control}
                render={({ field, fieldState: { invalid } }) => {
                  return (
                    <ReactDatePicker
                      isClearable
                      dateFormat={usrDateFormat}
                      maxDate={!!watchDivAllocEndDate ? new Date(watchDivAllocEndDate) : null}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      {...datePickerValue(field.value, field.onChange)}
                      {...datePickerClass(invalid)}
                    />
                  );
                }}
              />
              <Form.Control.Feedback type="invalid">{t(errors.divAllocStartDate?.message)}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="custom-form-control">
              <Form.Label>{t('Allocation End Date')}</Form.Label>
              <Controller
                name="divAllocEndDate"
                control={control}
                render={({ field, fieldState: { invalid } }) => {
                  return (
                    <ReactDatePicker
                      isClearable
                      dateFormat={usrDateFormat}
                      minDate={!!watchDivAllocStartDate ? new Date(watchDivAllocStartDate) : null}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      {...datePickerValue(field.value, field.onChange)}
                      {...datePickerClass(invalid)}
                    />
                  );
                }}
              />
              <Form.Control.Feedback type="invalid">{t(errors.divAllocEndDate?.message)}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="custom-form-control">
              <Form.Label>{t('Allocation Rules')}</Form.Label>
              <Controller
                name="divAllocationRules"
                control={control}
                render={({ field }) => {
                  const parsed = field.value ? field.value : null;
                  const format = (d) => field.onChange(d);
                  return (
                    <select defaultValue={parsed} selected={parsed} onChange={format} className="form-control">
                      <option value="divisionEquity">{t('Equity')}</option>
                      <option value="divOperationControl">{t('Operation Control')}</option>
                    </select>
                  );
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="custom-form-control input-percent">
              <Form.Label>{t('Equity')}</Form.Label>
              <Form.Control
                {...register('divEquity')}
                isInvalid={errors.divEquity && watchDivAllocationRules !== 'divOperationControl'}
                disabled={watchDivAllocationRules === 'divOperationControl'}
              />
              <Form.Control.Feedback type="invalid">{t(errors.divEquity?.message)}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="custom-form-control input-percent">
              <Form.Label>{t('Operation Control')}</Form.Label>
              <Form.Control
                {...register('divOperationControl')}
                isInvalid={errors.divOperationControl && watchDivAllocationRules === 'divOperationControl'}
                disabled={watchDivAllocationRules !== 'divOperationControl'}
              />
              <Form.Control.Feedback type="invalid">{t(errors.divOperationControl?.message)}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </div>
      <div className="form-divider mb-4 mt-4">
        <span>{t('Localization Settings')}</span>
        <div></div>
      </div>
      <Row>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Check
              custom
              id="is-show-localization"
              onChange={() => setShowLocalization(!isShowLocalization)}
              checked={isShowLocalization}
              label={t("Custom Location information")}
            />
          </Form.Group>
        </Col>
      </Row>
      <div style={{ display: isShowLocalization ? '' : 'none' }}>
        <Row>
          <Col>
            <Form.Group className="custom-form-control">
              <Form.Label>{t('Latitude')}</Form.Label>
              <Form.Control {...register('divLatitude')} isInvalid={errors.divLatitude} />
              <Form.Control.Feedback type="invalid">{t(errors.divLatitude?.message)}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="custom-form-control">
              <Form.Label>{t('Longitude')}</Form.Label>
              <Form.Control {...register('divLongitude')} isInvalid={errors.divLongitude} />
              <Form.Control.Feedback type="invalid">{t(errors.divLongitude?.message)}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="custom-form-control">
              <Form.Label>{t('Street')}</Form.Label>
              <Form.Control {...register('divStreet')} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="custom-form-control">
              <Form.Label>{t('City')}</Form.Label>
              <Form.Control {...register('divCity')} />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="custom-form-control">
              <Form.Label>{t('State')}</Form.Label>
              <Form.Control {...register('divState')} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="custom-form-control">
              <Form.Label>{t('Country')}</Form.Label>
              <Form.Control {...register('divCountry')} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="custom-form-control">
              <Form.Label>{t('Zip Code/ Postal Code')}</Form.Label>
              <Form.Control {...register('divZip')} />
            </Form.Group>
          </Col>
        </Row>
      </div>
      <Row>
        <Col lg={6}>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Currency')}</Form.Label>
            <Controller
              name="divCurrency"
              control={control}
              render={({ field }) => {
                const value = field.value || null;
                const label = value ? listCurrencies.find(item => item.value === value)?.label : null;
                return <Select
                  value={{ value, label }}
                  options={listCurrencies}
                  onChange={(e) => field.onChange(e.value)}
                />;
              }}
            />
            {errors.usrRole && errors.usrRole.type === 'required' && (
              <span className="required-span">{t(errors.usrRole.message)}</span>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Button type="submit" className="mt-3 mb-3" disabled={!isValid || !isDirty || isLoading}>
        {buttonText}
      </Button>
      {isEditing && (
        <Button variant="secondary" className="mt-3 mb-3 ml-3" onClick={onDeleteHandler}>
          {t('Delete')}
        </Button>
      )}
      {!isEditing && (
        <Button variant="secondary" className="mt-3 mb-3 ml-3" onClick={onCancelHandler}>
          {t('Cancel')}
        </Button>
      )}
      <LoadingButton isLoading={isLoading} className="mt-3 mb-3 ml-3" />
    </Form>
  );
};

export default EntityForm;
