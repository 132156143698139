import { config } from '../../utilities/config';
import keycloak from '../../keycloak';

export async function fetchAssetsApi(organizationId) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/asset`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    }
  });
  const data = await response.json();
  if (!response.ok) throw new Error(data.message);
  return data;
}

export async function createAssetApi(organizationId, data) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/asset`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function editAssetApi(organizationId, assetId, data) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/asset/${assetId}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function getAssignedEmissionSourcesApi(organizationId, assetId) {
  const response = await fetch(
    `${config.API_BASE_URL}/${organizationId}/asset/${assetId}/emission-profile/assigned-emission-source`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${keycloak.token}`
      }
    }
  );
  const data = await response.json();
  if (!response.ok) throw new Error(data.message);
  return data;
}

export async function getAssignedEntitiesApi(organizationId, assetId) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/asset/${assetId}/entity/assigned-entity`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    }
  });
  const data = await response.json();
  if (!response.ok) throw new Error(data.message);
  return data;
}

export async function fetchEmissionProfileTreeByAssetApi(organizationId, assetId) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/asset/${assetId}/emission-profile/tree`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    }
  });
  const data = await response.json();
  if (!response.ok) throw new Error(data.message);
  return data;
}

export async function fetchAssignedUsersToAssetsApi(organizationId, entityId, assetId, epsId) {
  const response = await fetch(
    `${config.API_BASE_URL}/${organizationId}/entity/${entityId}/asset/${assetId}/emission-profile/emission-profile-sources/${epsId}/assigned-users`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${keycloak.token}`
      }
    }
  );
  const data = await response.json();
  if (!response.ok) throw new Error(data.message);
  return data;
}

export async function fetchAvailableUsersToAssetsApi(organizationId, entityId, assetId, epsId) {
  const response = await fetch(
    `${config.API_BASE_URL}/${organizationId}/entity/${entityId}/asset/${assetId}/emission-profile/emission-profile-sources/${epsId}/available-users`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${keycloak.token}`
      }
    }
  );
  const data = await response.json();
  if (!response.ok) throw new Error(data.message);
  return data;
}

export async function assignUserToAssetApi(organizationId, entityId, assetId, epsId, userId) {
  const response = await fetch(
    `${config.API_BASE_URL}/${organizationId}/entity/${entityId}/asset/${assetId}/emission-profile/emission-profile-sources/${epsId}/assigned-users/${userId}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${keycloak.token}`
      }
    }
  );
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function unAssignUserToAssetApi(organizationId, entityId, assetId, epsId, userId) {
  const response = await fetch(
    `${config.API_BASE_URL}/${organizationId}/entity/${entityId}/asset/${assetId}/emission-profile/emission-profile-sources/${epsId}/assigned-users/${userId}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${keycloak.token}`
      }
    }
  );
  if (!response.ok) {
    const result = await response.json();
    throw new Error(result.message);
  }
}
