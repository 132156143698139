import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

const VerifySms2FaForm = ({
  onSubmit,
  onCancelHandler,
  onResentSmsHandler,
  disabled,
  mobileNumber,
}) => {
  const [isResentable, setIsResentable] = useState(null);
  const { t } = useTranslation();
  const validateSchema = yup.object().shape({
    code: yup.string()
      .required()
      .matches(/^[0-9]{6}$/, t('Please enter the valid verification code'))
  });
  useEffect(() => {
    if (isResentable === null) {
      setTimeout(() => setIsResentable(true), 10 * 1000);
    }
  }, [isResentable]);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm({
    defaultValues: {
      mobileNumber: '',
    },
    resolver: yupResolver(validateSchema),
    mode: 'onChange'
  });
  const onSubmitHandler = (data) => onSubmit(data);
  const onResentHandler = () => {
    if (!isResentable) {
      return;
    }
    setIsResentable(false);
    setTimeout(() => setIsResentable(true), 30 * 1000);
    onResentSmsHandler();
  };

  return (
    <Form onSubmit={handleSubmit(onSubmitHandler)}>
      <div className={'form-divider mb-4 mt-4'}>
        <span>
          {
            t(
              'Enter the 6-digit code that we sent to {{mobileNumber}} to finish setting up two-factor authentication',
              { mobileNumber }
            )
          }
        </span>
        <div></div>
      </div>
      <Row>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Active Code')} *</Form.Label>
            <Form.Control {...register('code')} isInvalid={errors.code} />
            <Form.Control.Feedback type="invalid">{errors.code?.message}</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Button type="submit" disabled={!isValid || !isDirty || disabled}>
        {t('Submit')}
      </Button>
      <Button disabled={!isResentable} variant="secondary" className="mt-3 mb-3 ml-3" onClick={onResentHandler}>
        {t('Didn’t get it? Resend')}
      </Button>
      <Button variant="secondary" className="mt-3 mb-3 ml-3" onClick={onCancelHandler}>
        {t('Cancel')}
      </Button>
    </Form>
  );
};

export default VerifySms2FaForm;
