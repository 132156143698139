import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import { fetchEmissionsByEntityGroup } from '../reportsSlice';
import { LoadingState } from '../../../utilities/constants';
import { useSelector } from 'react-redux';
import { Bar } from 'react-chartjs-2';
import { Select } from '../configurations/Select';
import { useLocation } from 'react-router-dom';
import ReportContainer from '../ReportContainer';
import { useHistory } from 'react-router-dom';
import {
  getCommonFilterPeriodRange,
  getCurrentReportPeriod,
  getReportYearTypeOptions,
  getReportRangePeriod,
  setupReportRangePeriod,
  parseFavouriteData,
  getIntervalTypeOptions,
  getEmissionTypeOptions,
  reportYearList,
  generateRandomColors,
  generateChartLegend,
  reportUnitByTypeId,
  formatNumber,
  parseUnitJson,
  displayTargetUnit,
  createInitChartConfig,
  createInitChartPlugins,
  getMonthAndQuarterNames,
  getStartDatePeriod,
} from '../reportHelper';
import { useTranslation } from 'react-i18next';

const EmissionsByEntityGroup = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const chartRef = useRef(null);
  const { filterData } = parseFavouriteData(useLocation().search);
  const configRef = useRef(null);
  const userInfo = useSelector((state) => state.users.user);
  const currentReportPeriod = getCurrentReportPeriod(userInfo.usrOrgOptions);
  const [reportYear, setReportYear] = useState(currentReportPeriod.reportYear);
  const reportRangePeriod = getReportRangePeriod(userInfo.usrOrgOptions, reportYear);
  const [filterParams, setFilterParams] = useState({
    reportYearType: 'Calendar Year',
    intervalType: 'quarterly',
    reportYear: new Date().getUTCFullYear(),
    recentTime: null,
    emissionTypeId: 1,
    ...currentReportPeriod,
    ...(filterData || {})
  });
  const [chartData, setChartData] = useState({});
  const [selectedReportYearType, setSelectedReportYearType] = useState(filterParams.reportYearType);
  const emissionsByEntityGroup = useSelector((state) => state.reports.emissionsByEntityGroup);
  const emissionsByEntityGroupStatus = useSelector((state) => state.reports.emissionsByEntityGroupStatus);
  const [startDatePeriod, setStartDatePeriod] = useState(getStartDatePeriod(reportRangePeriod, filterParams.reportYearType));

  const plugins = createInitChartPlugins([]);

  const add = (accumulator, a) => {
    return accumulator + a;
  };

  const getLabels = useCallback(() => {
    const labels = [];
    const dataset = [];
    const { unit } = reportUnitByTypeId(parseInt(filterParams.emissionTypeId), t);
    if (emissionsByEntityGroupStatus === LoadingState.succeeded) {
      let entityGroups = [];
      const { monthNames, quarterNames } = getMonthAndQuarterNames(t, startDatePeriod);
      emissionsByEntityGroup.forEach((bucket) => {
        if ('month' in bucket) labels.push(`${monthNames[bucket.month]} ${bucket.year}`);
        else if ('quarter' in bucket) labels.push(t('{{quarter}} {{year}}', {
          quarter: quarterNames[bucket.quarter],
          year: bucket.year
        }));
        else labels.push(bucket.year);
        bucket.children.forEach((child) => entityGroups.push(child));
      });
      entityGroups = entityGroups.filter(
        (entityGroup, index, entityGroups) => entityGroups.map((e) => e.eg_id).indexOf(entityGroup.eg_id) === index
      );
      generateRandomColors(true);
      const dataUnits = [];
      entityGroups.forEach((a) => {
        const data = [];
        emissionsByEntityGroup.forEach((bucket) => {
          const found = bucket.children.find((child) => {
            if (child.eg_id === a.eg_id) return child;
            return null;
          });
          if (found) {
            data.push(parseFloat(found.value));
            parseUnitJson(found.unit, dataUnits);
          } else {
            data.push(0);
          }
        });
        dataset.push({
          axis: 'y',
          label: a.eg_name,
          data,
          units: dataUnits,
          backgroundColor: generateRandomColors()
        });
      });

      dataset.forEach((singleDataset) => {
        singleDataset.title = singleDataset.label;
        singleDataset.total = singleDataset.data.reduce(add, 0);
        singleDataset.displayUnit = displayTargetUnit(singleDataset.units, unit);
        const { label, total, displayUnit } = singleDataset;
        singleDataset.label = `${label} (${formatNumber(total)} ${displayUnit})`;
      });
    }
    return { labels: labels, datasets: dataset };
  }, [
    t,
    emissionsByEntityGroupStatus,
    emissionsByEntityGroup,
    filterParams.emissionTypeId,
    startDatePeriod
  ]);

  const changeDateRangePeriod = (usrOrgOptions, year) => {
    const rangePeriod = getReportRangePeriod(usrOrgOptions, year);
    if (rangePeriod) {
      configRef.current.setFormValue('startDate', rangePeriod.startDate);
      configRef.current.setFormValue('endDate', rangePeriod.endDate);
    }
  };
  const reportYearHanlder = (event) => {
    setReportYear(event.target.value);
    changeDateRangePeriod(userInfo.usrOrgOptions, event.target.value);
  };
  const selectedReportYearTypeHandler = ({ target }) => {
    if (target.value === 'SETUP') {
      setupReportRangePeriod(history);
    } else {
      setSelectedReportYearType(target.value);
      if (target.value === 'Calendar Year') {
        changeDateRangePeriod({}, null);
      } else {
        changeDateRangePeriod(userInfo.usrOrgOptions, reportYear);
      }
    }
  };
  const getFilterPeriodRangeHandler = (data) => {
    return getCommonFilterPeriodRange(data, reportRangePeriod);
  };

  const fetchEmissionsByEntityGroupHandler = async (dispatch, payload) => {
    payload.data = {
      intervalType: filterParams.intervalType,
      emissionTypeId: parseInt(filterParams.emissionTypeId),
      recentTime: null,
      reportYear: parseInt(filterParams.reportYear || reportYear),
      reportYearType: filterParams.reportYearType,
      ...payload.data,
      reportPeriodEnding: currentReportPeriod.reportPeriodEnding,
    };
    return await dispatch(fetchEmissionsByEntityGroup(payload));
  };

  const getFavouriteFilterDataHandler = (params, filters) => {
    const paramsData = {
      ...params,
      intervalType: filters.intervalType,
      reportYear: parseInt(filters.reportYear || reportYear),
      reportYearType: filters.reportYearType,
      reportPeriodEnding: currentReportPeriod.reportPeriodEnding,
      emissionTypeId: parseInt(filters.emissionTypeId),
      recentTime: null,
    };
    setFilterParams(paramsData);
    setStartDatePeriod(getStartDatePeriod(reportRangePeriod, filters.reportYearType));
    return paramsData;
  };

  useEffect(() => {
    if (emissionsByEntityGroupStatus === LoadingState.loading) {
      setChartData({});
    }
    if (emissionsByEntityGroupStatus === LoadingState.succeeded) {
      setChartData(getLabels());
    }
  }, [
    emissionsByEntityGroupStatus, emissionsByEntityGroup, getLabels,
  ]);

  useEffect(() => {
    generateChartLegend(chartRef);
  }, [emissionsByEntityGroupStatus, chartData]);

  const options = useMemo(() => {
    const totalArray = [];
    if (chartData.datasets && chartData.datasets.length > 0 && emissionsByEntityGroupStatus === LoadingState.succeeded) {
      chartData.datasets[0].data.map((dataPoint, index) => {
        let total = dataPoint;
        let i = 1;
        while (i < chartData.datasets.length) {
          total += chartData.datasets[i].data[index];
          i++;
        }
        return totalArray.push(total);
      });
    }
    const { title } = reportUnitByTypeId(parseInt(filterParams.emissionTypeId), t);
    return createInitChartConfig({
      scales: {
        xAxes: [
          {
            stacked: true,
            gridLines: {
              drawOnChartArea: false
            }
          }
        ],
        yAxes: [
          {
            stacked: true,
            ticks: {
              beginAtZero: true,
              suggestedMax: Math.max(...totalArray) + (5 / 100) * Math.max(...totalArray)
            },
            scaleLabel: {
              display: true,
              labelString: title,
            }
          }
        ]
      },
      tooltips: {
        filter: function (tooltipItem, data) {
          let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return value !== 0;
        },
        callbacks: {
          label: (tooltipItem, data) => {
            const { title, displayUnit } = data.datasets[tooltipItem.datasetIndex];
            return `${title}: ${formatNumber(tooltipItem.value)} ${displayUnit}`;
          },
          beforeBody: (tooltipItem, data) => {
            let total = 0;
            data.datasets.forEach((dataset, datasetIndex) => {
              if (chartRef.current.chartInstance.isDatasetVisible(datasetIndex)) {
                total += dataset.data[tooltipItem[0].index] || 0;
              }
            });
            return t('Total: {{number}}', { number: formatNumber(total) });
          }
        },
      },
    });
  }, [t, chartData.datasets, emissionsByEntityGroupStatus, filterParams.emissionTypeId]);

  const chartRender = () => {
    return (
      <>
        <div className="chart-graph">
          <Bar
            data={chartData}
            options={options}
            ref={chartRef}
            plugins={plugins}
          />
        </div>
        <div id="js-legend" className="chart-legend"></div>
      </>
    )
  };

  const extraConfigRender = (isFavReport) => {
    return (
      <>
        <Select
          disabled={isFavReport}
          label={t("Report Year Type")}
          name="reportYearType"
          options={getReportYearTypeOptions(t, reportRangePeriod)}
          onChange={selectedReportYearTypeHandler}
        />
        <Select
          disabled={isFavReport || selectedReportYearType === 'Calendar Year'}
          label={t("Report Year")}
          name="reportYear"
          onChange={reportYearHanlder}
          options={reportYearList()}
        />
        <Select
          disabled={isFavReport}
          label={t("Interval")}
          name="intervalType"
          options={getIntervalTypeOptions(t)}
        />
        <Select
          disabled={isFavReport}
          label={t("CO2-e (t) or Usage")}
          name="emissionTypeId"
          options={getEmissionTypeOptions(t)}
        />
      </>
    );
  };

  return (
    <ReportContainer
      reportName={t('Emissions by Entity Group')}
      reportSubTitle={t('Emission Footprint Overview by Entity Group')}
      configRef={configRef}
      fetchReportData={fetchEmissionsByEntityGroupHandler}
      fetchReportStatus={emissionsByEntityGroupStatus}
      reportData={emissionsByEntityGroup}
      reportRender={chartRender}
      extraConfigRender={extraConfigRender}
      getFavouriteFilterData={getFavouriteFilterDataHandler}
      getFilterPeriodRange={getFilterPeriodRangeHandler}
      extraConfigDefault={{
        reportYearType: filterParams.reportYearType,
        intervalType: filterParams.intervalType,
        reportYear: parseInt(filterParams.reportYear),
        emissionTypeId: parseInt(filterParams.emissionTypeId),
      }}
      extraConfigFormState={{
        reportYearType: selectedReportYearType,
      }}
      chartRef={chartRef}
    />
  );
};

export default EmissionsByEntityGroup;
