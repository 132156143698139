import React, { useEffect, useRef, Fragment } from 'react';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { Col, Spinner, Card } from 'react-bootstrap';
import { LoadingState } from '../../../utilities/constants';
import { useDispatch, useSelector } from 'react-redux';
import Table from 'react-bootstrap/Table';
import Empty from '../../../components/Empty';
import { fetchCarbonFootprintByScopeDashboard } from '../dashboardSlice';
import DashboardPrintButton from '../components/DashboardPrintButton';
import { formatNumber } from '../../reports/reportHelper';
import { useTranslation } from 'react-i18next';
import { parseUserInfo } from '../../../utilities/common';

const DashboardCarbonFoorprintByScope = ({ filters, reportRangePeriod }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { orgId, usrDateFormat, usrTimezone } = parseUserInfo(useSelector((state) => state.users.user));
  const loggedUserStatus = useSelector((state) => state.users.loggedUserStatus);
  const carbonFootprintByScope = useSelector((state) => state.dashboard.carbonFootprintByScopeDashboard);
  const scopeTree = useSelector((state) => state.dashboard.scopesTreeDashboard);
  const carbonFootprintByScopeStatus = useSelector((state) => state.dashboard.carbonFootprintByScopeStatusDashboard);
  const scopeTreeStatus = useSelector((state) => state.dashboard.scopeTreeStatusDashboard);
  const carbonFootprintByScopeError = useSelector((state) => state.dashboard.cfbsError);
  const dataRef = useRef(null);
  const tableRef = useRef(null);
  useEffect(() => {
    const data = {
      usrTimezone,
      usrDateFormat,
      startDate: reportRangePeriod.startDate,
      endDate: reportRangePeriod.endDate,
      entitiesIdsToInclude: filters.selectedEntity,
      epsIdsToInclude: [],
      recentTime: null,
      scopeList: [],
      isApplyExcludedEPSs: true,
    };
    if (orgId && loggedUserStatus === LoadingState.succeeded) {
      try {
        dispatch(fetchCarbonFootprintByScopeDashboard({ orgId, data }));
      } catch (e) {
        toast.error(e.message);
      }
    }
  }, [
    filters.selectedEntity,
    orgId,
    dispatch,
    loggedUserStatus,
    reportRangePeriod.startDate,
    reportRangePeriod.endDate,
    usrTimezone,
    usrDateFormat,
  ]);
  const findScopeById = (id) => {
    const search = scopeTree.find((item) => item.id === id);
    return search || {};
  };
  const getData = () => {
    const data = _.orderBy(
      carbonFootprintByScope.map((item) => ({
        ...item,
        scope: findScopeById(item.scope_id).scope,
        records: _.orderBy(
          _.map(item.records),
          'per_co2_e_t',
          'asc'
        ),
      })),
      'scope',
      'asc'
    );
    if (data.length) {
      const scopeNames = data.map(item => item.scope?.split(':')[0]?.trim());
      const lastScope = scopeNames.length > 1 ? scopeNames.pop() : '';
      const message = lastScope ? 'Total {{scopes}} and {{lastScope}} Emissions (tCO2-e)' : 'Total {{scopes}} Emissions (tCO2-e)';
      const summaryData = {
        isSummary: true,
        scope: t(message, { scopes: scopeNames.join(', '), lastScope }),
        records: [],
      };
      data.forEach(({ records }) => {
        records.forEach((item) => {
          const fields = [
            'co2_e_t', 'per_co2_e_t', 'co2_t', 'ch4_t',
            'n2o_t', 'pfc_t', 'hfc_t', 'sf6_t', 'nf3_t'
          ];
          fields.forEach((key) => {
            summaryData[`total_${key}`] = (summaryData[`total_${key}`] || 0) + Number(item[key]);
          });
        });
      });
      data.unshift(summaryData);
    }
    return data;
  };
  return (
    <Col lg="6">
      <Card>
        <Card.Body>
          {/* <Card.Title>Carbon Footprint by Scope</Card.Title> */}
          <div className="mt-2 mb-4 print-wrapper" ref={dataRef} id="scope_table">
            <div className="dashboard-report-header">
              <strong className="report-text">{t('Emission Footprint Overview by Scope')}</strong>
              <div className="button-wrapper">
                <DashboardPrintButton
                  key="cf_by_scope_print"
                  title={t("Carbon Footprint by Scope")}
                  noScroll={true}
                  downloadRef={dataRef}
                  tableRef={tableRef}
                  disabled={carbonFootprintByScopeError ? true : false}
                />
              </div>
            </div>
            {(carbonFootprintByScopeStatus === LoadingState.loading || scopeTreeStatus === LoadingState.loading) && (
              <Spinner className="loader" animation="grow" />
            )}
            {carbonFootprintByScopeStatus === LoadingState.succeeded &&
              scopeTreeStatus === LoadingState.succeeded &&
              carbonFootprintByScope.length !== 0 && (
                // <div ref={dataRef} className="print-wrapper" id="scope_table">
                <Table bordered hover id="data-table" ref={tableRef}>
                  <thead>
                    <tr key="header">
                      <th key="col_1">{t('Scope')}</th>
                      <th key="col_2">{t('Emission Source')}</th>
                      <th key="col_5">{t('CO2-e (t)')}</th>
                      <th key="col_6">{t('% Total CO2-e (t)')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {getData().map((cfpbs, index) => {
                      return (
                        <Fragment key={index}>
                          <tr style={{ backgroundColor: '#004866', color: '#FFFFFF' }}>
                            <td key="col_1">{cfpbs.isSummary ? cfpbs.scope : t('SubTotal for {{scope}}', { scope: cfpbs.scope })}</td>
                            <td key="col_2"></td>
                            <td key="col_5">{formatNumber(cfpbs.total_co2_e_t)} </td>
                            <td key="col_6">{formatNumber(cfpbs.total_per_co2_e_t)}</td>
                          </tr>
                          <NestedRow records={cfpbs.records} />
                        </Fragment>
                      );
                    })}
                  </tbody>
                </Table>
                // </div>
              )}
            {carbonFootprintByScopeStatus === LoadingState.failed && carbonFootprintByScopeError && (
              <Empty title={carbonFootprintByScopeError} />
            )}
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
};

const NestedRow = ({ records }) => {
  return records.map((record, index) => {
    return (
      <tr key={index}>
        <td key="col_1">{record.scope}</td>
        <td key="col_2">{record.eps}</td>
        <td key="col_5">{formatNumber(record.co2_e_t)}</td>
        <td key="col_6">{formatNumber(record.per_co2_e_t)}</td>
      </tr>
    );
  });
};

export default DashboardCarbonFoorprintByScope;
