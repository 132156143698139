import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { useParams } from 'react-router';
import DataTable from '../../components/DataTable';
import { useTranslation } from 'react-i18next';
import { getAssignedEntities } from './assetsSlice';

const ViewAssignedEntities = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { assetId } = useParams();
  const assignedEntities = useSelector((state) => state.assets.assignedEntities);
  const orgId = useSelector((state) => state.users.user.usrOrgId);

  useEffect(() => {
    dispatch(getAssignedEntities({ orgId, assetId }));
  }, [orgId, assetId, dispatch]);

  const assignedTableColumns = useMemo(
    () => [
      {
        Header: t('Entity Name'),
        accessor: 'divName'
      },
      {
        Header: t('Entity Description'),
        accessor: 'divDesc'
      }
    ],
    [t]
  );
  if (assignedEntities === null) return <Spinner animation="grow" />;
  return (
    <div>
      <h5 className="my-3 text-light">{t('Assigned Entities')}</h5>
      <DataTable columns={assignedTableColumns} data={assignedEntities} />
    </div>
  );
};

export default ViewAssignedEntities;
