import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { toast } from 'react-toastify';
import { Col, Spinner, Card } from 'react-bootstrap';
import { LoadingState } from '../../../utilities/constants';
import { useDispatch, useSelector } from 'react-redux';
import { Bar } from 'react-chartjs-2';
import Empty from '../../../components/Empty';
import { fetchEmissionFootprintOverviewByEntityDashboard } from '../dashboardSlice';
import DashboardPrintButton from '../components/DashboardPrintButton';
import {
  createInitChartConfig,
  createInitChartPlugins,
  formatNumber,
  generateRandomColors,
  getMonthAndQuarterNames,
  getStartDatePeriod,
} from '../../reports/reportHelper';
import { useTranslation } from 'react-i18next';
import { parseUserInfo } from '../../../utilities/common';

const DashboardEmissionFootprintOverviewByEntity = ({ filters, reportRangePeriod, currentReportPeriod }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [data, setData] = useState({});
  const { orgId, usrDateFormat, usrTimezone } = parseUserInfo(useSelector((state) => state.users.user));
  const loggedUserStatus = useSelector((state) => state.users.loggedUserStatus);
  const emissionFootprintOverviewByEntity = useSelector(
    (state) => state.dashboard.emissionFootprintOverviewByEntityDashboard
  );
  const emissionFootprintOverviewByEntityStatus = useSelector(
    (state) => state.dashboard.emissionFootprintOverviewByEntityStatusDashboard
  );
  const emissionsByTop10EPSError = useSelector((state) => state.dashboard.efobeError);
  const chart = useRef(null);
  const dataRef = useRef(null);

  const plugins = createInitChartPlugins([], {
    displayChartAxesTotal: () => data.datasets && data.datasets.length > 0 &&
      emissionFootprintOverviewByEntityStatus === LoadingState.succeeded
  });
  const startDatePeriod = getStartDatePeriod(reportRangePeriod, currentReportPeriod.reportYearType);
  const getLabels = useCallback(() => {
    const labels = [];
    const dataset = [];
    if (emissionFootprintOverviewByEntityStatus === LoadingState.succeeded) {
      const { monthNames, quarterNames, addToYear } = getMonthAndQuarterNames(t, startDatePeriod);
      let groups = [];
      const sortedEmissionFootprintOverviewByEntityDashboard = [...emissionFootprintOverviewByEntity].sort((a, b) => {
        if (a.year === b.year) return a.quarter - b.quarter;
        else return a.year > b.year ? 1 : -1;
      });
      sortedEmissionFootprintOverviewByEntityDashboard.forEach((bucket) => {
        if ('month' in bucket) labels.push(`${monthNames[bucket.month]} ${bucket.year}`);
        else if ('quarter' in bucket) labels.push(t('{{quarter}} {{year}}', {
          quarter: quarterNames[bucket.quarter],
          year: bucket.year + addToYear[bucket.quarter]
        }));
        else labels.push(bucket.year);
        bucket.values.forEach((child) => groups.push(child));
      });

      groups = groups.filter(
        (group, index, groups) => groups.map((e) => e.measure_or_id).indexOf(group.measure_or_id) === index
      );
      generateRandomColors(true);
      groups.forEach((a) => {
        const data = [];
        sortedEmissionFootprintOverviewByEntityDashboard.forEach((bucket) => {
          const found = bucket.values.find((child) => {
            if (child.measure_or_id === a.measure_or_id) return child;
            else return null;
          });
          if (found) {
            if (isNaN(found.value)) {
              data.push(0);
            } else {
              data.push(parseFloat(found.value));
            }
          } else data.push(0);
        });
        dataset.push({
          axis: 'y',
          label: a.name,
          data,
          backgroundColor: generateRandomColors()
        });
      });
    }
    return { labels: labels, datasets: dataset };
  }, [t, emissionFootprintOverviewByEntityStatus, emissionFootprintOverviewByEntity, startDatePeriod]);

  useEffect(() => {
    if (emissionFootprintOverviewByEntityStatus === LoadingState.loading) {
      setData({});
    }
    if (emissionFootprintOverviewByEntityStatus === LoadingState.succeeded) {
      setData(getLabels());
    }
  }, [
    orgId,
    emissionFootprintOverviewByEntityStatus,
    emissionFootprintOverviewByEntity,
    getLabels,
    dispatch,
  ]);

  useEffect(() => {
    const data = {
      usrDateFormat,
      usrTimezone,
      startDate: reportRangePeriod.startDate,
      endDate: reportRangePeriod.endDate,
      reportYearType: currentReportPeriod.reportYearType,
      reportPeriodEnding: currentReportPeriod.reportPeriodEnding,
      reportYear: filters.year,
      intervalType: 'quarterly',
      groupByType: 'entity',
      emissionTypeId: 1,
      entitiesIdsToInclude: filters.selectedEntity,
      epsIdsToInclude: [],
      isApplyExcludedEPSs: true,
    };
    if (orgId && loggedUserStatus === LoadingState.succeeded) {
      try {
        dispatch(fetchEmissionFootprintOverviewByEntityDashboard({ orgId, data }));
      } catch (e) {
        toast.error(e.message);
      }
    }
  }, [
    filters,
    currentReportPeriod.reportYearType,
    currentReportPeriod.reportPeriodEnding,
    orgId,
    dispatch,
    loggedUserStatus,
    reportRangePeriod.startDate,
    reportRangePeriod.endDate,
    usrDateFormat,
    usrTimezone,
  ]);

  const options = useMemo(() => {
    const totalArray = [];
    if (
      data.datasets &&
      data.datasets.length > 0 &&
      emissionFootprintOverviewByEntityStatus === LoadingState.succeeded
    ) {
      data.datasets[0].data.map((dataPoint, index) => {
        let total = dataPoint;
        let i = 1;
        while (i < data.datasets.length) {
          total += data.datasets[i].data[index];
          i++;
        }
        return totalArray.push(total);
      });
    }
    return createInitChartConfig({
      maintainAspectRatio: true,
      scales: {
        xAxes: [
          {
            stacked: true,
            gridLines: {
              drawOnChartArea: false
            }
          }
        ],
        yAxes: [
          {
            stacked: true,
            ticks: {
              beginAtZero: true,
              suggestedMax: Math.max(...totalArray) + (5 / 100) * Math.max(...totalArray),
            },
            scaleLabel: {
              display: true,
              labelString: t('Total - CO2-e (t)')
            }
          }
        ]
      },
      tooltips: {
        filter: function (tooltipItem, data) {
          let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return value !== 0;
        },
        callbacks: {
          label: (tooltipItem, data) => {
            const { label } = data.datasets[tooltipItem.datasetIndex];
            return t('{{label}}: {{number}} tCO2e', { label, number: formatNumber(tooltipItem.value) });
          },
        }
      },
    });
  }, [t, data.datasets, emissionFootprintOverviewByEntityStatus]);

  return (
    <Col lg="6">
      <Card>
        <Card.Body>
          {/* <Card.Title>Emission Footprint Overview by Entity</Card.Title> */}
          <div className="mt-2 mb-4 chart_print_wrapper" id="efobe_chart" ref={dataRef}>
            <div className="dashboard-report-header">
              <strong className="report-text">{t('Emission Footprint Overview by Entity')}</strong>
              <div className="button-wrapper">
                <DashboardPrintButton
                  key="efobe_chart"
                  title={t("Emission Footprint Overview by Entity")}
                  id="efobe_chart"
                  downloadRef={dataRef}
                  disabled={emissionsByTop10EPSError ? true : false}
                />
              </div>
            </div>
            {emissionFootprintOverviewByEntityStatus === LoadingState.loading && (
              <Spinner className="loader" animation="grow" />
            )}
            {emissionFootprintOverviewByEntityStatus === LoadingState.succeeded &&
              emissionFootprintOverviewByEntity.length > 0 && (
                // <div id="efobe_chart" ref={dataRef} className="chart_print_wrapper">
                <Bar width={600} height={415} data={data} options={options} ref={chart} plugins={plugins} />
                // </div>
              )}
            {emissionFootprintOverviewByEntityStatus === LoadingState.failed && emissionsByTop10EPSError && (
              <Empty title={emissionsByTop10EPSError} />
            )}
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default DashboardEmissionFootprintOverviewByEntity;
