import Sorting from '../../../components/Sorting';

const ScopeTableHeader = ({
  name,
  title,
  isSorted = false,
  isSortedDesc = false,
  disabled,
  sortHandler,
}) => (
  <th onClick={() => disabled ? () => {} : sortHandler(name)}>
    {title}
    <Sorting
      column={{
        isSorted,
        isSortedDesc,
        disableSortBy: disabled,
      }}
    />
  </th>
);

export default ScopeTableHeader;
