import { Fragment } from 'react';
import { Badge, Col, Row } from 'react-bootstrap';
import { SDGLogoComponent } from './SDGLogoComponent';
import { useTranslation } from 'react-i18next';

export function KPICompactDetailCard({ color, kpiName, orderNo, goalName, imagePath, kpiDescription, esgIndicators }) {
  const { t } = useTranslation();
  return (
    <Fragment>
      <Row className='mt-3'>
        <Col className='kpi-compact-detail'>
          <div className='goals-card-header-wrapper'>
            <div className='image-side'>{SDGLogoComponent(imagePath)}</div>
            
            <div className='text-side'>
            <div className='text-side-header'>
                <div className='d-flex'>
                  <h5>{t('{{orderNo}}. {{goalName}}', { orderNo, goalName: t(goalName)})}</h5> 
                  <div className='kpi-version-label ml-2'>{t(kpiName)}</div>
                </div>
                <div>
                    {esgIndicators &&
                        esgIndicators.length > 0 &&
                        esgIndicators.map((esgIndicator, idx) => {
                          return (
                            <span>
                              <Badge key={idx} className='ml-2' style={{ backgroundColor: color }}>
                                {t(esgIndicator)}
                              </Badge>
                            </span>
                          );
                        })}
                </div>
            </div>
            <p>{t(kpiDescription)}</p>
            </div>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
}
