import jsonConfig from '../config.json';

const prod = {
  DEFAULT_LOCALE: process.env.REACT_APP_DEFAULT_LOCALE || jsonConfig.DEFAULT_LOCALE,
  KEYCLOAK_BASE_URL: process.env.REACT_APP_KEYCLOAK_BASE_URL ?? jsonConfig.KEYCLOAK_BASE_URL,
  KEYCLOAK_REALM: process.env.REACT_APP_KEYCLOAK_REALM ?? jsonConfig.KEYCLOAK_REALM,
  KEYCLOAK_CLIENT_ID: process.env.REACT_APP_KEYCLOAK_CLIENT_ID ?? jsonConfig.KEYCLOAK_CLIENT_ID,
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL ?? jsonConfig.API_BASE_URL,
  KEYCLOAK_BACKEND_CLIENT_ID: process.env.REACT_APP_KEYCLOAK_BACKEND_CLIENT_ID ?? jsonConfig.KEYCLOAK_BACKEND_CLIENT_ID,
  I18N_FALLBACK_LNG_ID: process.env.REACT_APP_I18N_FALLBACK_LNG_ID ?? jsonConfig.I18N_FALLBACK_LNG_ID,
  BUSINESS_TYPE_REVENUE_ID: process.env.REACT_APP_BUSINESS_TYPE_REVENUE_ID ?? jsonConfig.BUSINESS_TYPE_REVENUE_ID,
  USER_IDLE_TIMEOUT: process.env.REACT_APP_USER_IDLE_TIMEOUT ?? jsonConfig.USER_IDLE_TIMEOUT,
  CORIOLIS_STARTED_URL: process.env.CORIOLIS_STARTED_URL ?? jsonConfig.CORIOLIS_STARTED_URL,
};

const dev = {
  DEFAULT_LOCALE: process.env.REACT_APP_DEFAULT_LOCALE || jsonConfig.DEFAULT_LOCALE,
  KEYCLOAK_BASE_URL: 'https://auth.carbon-view.com',
  KEYCLOAK_REALM: 'CarbonView-DEV',
  KEYCLOAK_CLIENT_ID: 'carbonview-ui-dev',
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL ?? 'https://jeka9dfqbj.execute-api.ap-southeast-1.amazonaws.com/dev',
  KEYCLOAK_BACKEND_CLIENT_ID: 'carbonview-backend-dev',
  I18N_FALLBACK_LNG_ID: process.env.REACT_APP_I18N_FALLBACK_LNG_ID ?? '3',
  BUSINESS_TYPE_REVENUE_ID: process.env.REACT_APP_BUSINESS_TYPE_REVENUE_ID ?? '2222',
  USER_IDLE_TIMEOUT: process.env.REACT_APP_USER_IDLE_TIMEOUT ?? 1800,
  CORIOLIS_STARTED_URL: process.env.CORIOLIS_STARTED_URL ?? 'https://simblegroup.com/',
};

export const config = process.env.NODE_ENV === 'development' ? dev : prod;
