import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { LoadingState } from '../../utilities/constants';
import { createUserApi, editUserApi, fetchUserApi, fetchUsersApi, removeUserApi } from './usersApi';
import {
  fetchEmissionProfileTreeByAssetApi,
  fetchAssignedUsersToAssetsApi,
  fetchAvailableUsersToAssetsApi,
  assignUserToAssetApi,
  unAssignUserToAssetApi
} from '../assets/assetsApi';
import {
  fetchEmissionProfileTreeByEntityApi,
  fetchAssignedUsersToSourcesApi,
  fetchAvailableUsersToSourcesApi,
  assignUserToSourceApi,
  unAssignUserToSourceApi
} from '../entity/entityApi';

export const setLeavePrompt = createAsyncThunk('users/setLeavePromptState', async (value) => !!value);
export const setHistoryStack = createAsyncThunk('users/setHistoryStack', async (value) => value);

export const fetchUser = createAsyncThunk('users/fetchUser', async (payload) => {
  try {
    const response = await fetchUserApi(payload.userKeycloakId);
    return response;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const fetchUsers = createAsyncThunk('users/fetchUsers', async (payload) => {
  try {
    const response = await fetchUsersApi(payload.orgId);
    return response;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const createUser = createAsyncThunk('users/createUser', async (payload, { dispatch }) => {
  try {
    const { orgId, data } = payload;
    await createUserApi(orgId, data);
    toast.success('New user created');
    dispatch(fetchUsers({ orgId }));
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const editUser = createAsyncThunk('users/editUser', async (payload, { dispatch }) => {
  try {
    const { orgId, userId, data } = payload;
    const user = await editUserApi(orgId, userId, data);
    toast.success('User updated successfuly');
    dispatch(fetchUsers({ orgId }));
    return user;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const removeUser = createAsyncThunk('users/removeUser', async (payload) => {
  try {
    const user = await removeUserApi(payload.orgId, payload.userId);
    return user;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const fetchEmissionProfileTreeByAsset = createAsyncThunk(
  'users/fetchEmissionProfileTreeByAsset',
  async (payload) => {
    try {
      const response = await fetchEmissionProfileTreeByAssetApi(payload.orgId, payload.assetId);
      return response;
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  }
);

export const fetchAssignedUsersToAsset = createAsyncThunk('users/fetchAssignedUsersToAsset', async (payload) => {
  try {
    const response = await fetchAssignedUsersToAssetsApi(
      payload.orgId,
      payload.entityId,
      payload.assetId,
      payload.epsId
    );
    return response;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const fetchAvailableUsersToAsset = createAsyncThunk('users/fetchAvailableUsersToAsset', async (payload) => {
  try {
    const response = await fetchAvailableUsersToAssetsApi(
      payload.orgId,
      payload.entityId,
      payload.assetId,
      payload.epsId
    );
    return response;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const assignUserToAsset = createAsyncThunk('users/assignUserToAsset', async (payload, { dispatch }) => {
  const { orgId, entityId, assetId, epsId, userId } = payload;
  try {
    await assignUserToAssetApi(orgId, entityId, assetId, epsId, userId);
    await dispatch(fetchAssignedUsersToAsset({ orgId, entityId, assetId, epsId }));
    await dispatch(fetchAvailableUsersToAsset({ orgId, entityId, assetId, epsId }));
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const unAssignUserToAsset = createAsyncThunk('users/assignUserToAsset', async (payload, { dispatch }) => {
  const { orgId, entityId, assetId, epsId, userId } = payload;
  try {
    await unAssignUserToAssetApi(orgId, entityId, assetId, epsId, userId);
    await dispatch(fetchAssignedUsersToAsset({ orgId, entityId, assetId, epsId }));
    await dispatch(fetchAvailableUsersToAsset({ orgId, entityId, assetId, epsId }));
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const fetchEmissionProfileTreeByEntity = createAsyncThunk(
  'users/fetchEmissionProfileTreeByEntity',
  async (payload) => {
    try {
      const response = await fetchEmissionProfileTreeByEntityApi(payload.orgId, payload.entityId);
      return response;
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  }
);

export const fetchAssignedUsersToSource = createAsyncThunk('users/fetchAssignedUsersToSource', async (payload) => {
  try {
    const response = await fetchAssignedUsersToSourcesApi(payload.orgId, payload.entityId, payload.epsId);
    return response;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const fetchAvailableUsersToSource = createAsyncThunk('users/fetchAvailableUsersToSource', async (payload) => {
  try {
    const response = await fetchAvailableUsersToSourcesApi(payload.orgId, payload.entityId, payload.epsId);
    return response;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const assignUserToSource = createAsyncThunk('users/assignUserToSource', async (payload, { dispatch }) => {
  const { orgId, entityId, epsId, userId } = payload;
  try {
    await assignUserToSourceApi(orgId, entityId, epsId, userId);
    await dispatch(fetchAssignedUsersToSource({ orgId, entityId, epsId }));
    await dispatch(fetchAvailableUsersToSource({ orgId, entityId, epsId }));
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const unAssignUserToSource = createAsyncThunk('users/assignUserToSource', async (payload, { dispatch }) => {
  const { orgId, entityId, epsId, userId } = payload;
  try {
    await unAssignUserToSourceApi(orgId, entityId, epsId, userId);
    await dispatch(fetchAssignedUsersToSource({ orgId, entityId, epsId }));
    await dispatch(fetchAvailableUsersToSource({ orgId, entityId, epsId }));
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

const usersSlice = createSlice({
  name: 'users',
  initialState: {
    user: {},
    users: [],
    isLeavePrompt: false,
    historyStack: [],
    assignedUsersToAsset: [],
    availableUsersToAsset: [],
    emissionProfileTreeByAsset: null,
    assignedUsersToSource: [],
    availableUsersToSource: [],
    emissionProfileTreeByEntity: null,
    status: LoadingState.idle,
    loggedUserStatus: LoadingState.idle,
    assignmentStatus: LoadingState.idle,
    error: null
  },
  reducers: {},
  extraReducers: {
    [fetchUser.pending]: (state) => {
      state.loggedUserStatus = LoadingState.loading;
    },
    [fetchUser.fulfilled]: (state, action) => {
      state.loggedUserStatus = LoadingState.succeeded;
      state.user = action.payload;
    },
    [fetchUser.rejected]: (state, action) => {
      state.loggedUserStatus = LoadingState.failed;
      state.error = action.error.message;
    },
    [fetchUsers.pending]: (state) => {
      state.status = LoadingState.loading;
    },
    [fetchUsers.fulfilled]: (state, action) => {
      state.status = LoadingState.succeeded;
      state.users = action.payload;
    },
    [fetchUsers.rejected]: (state, action) => {
      state.status = LoadingState.failed;
      state.error = action.error.message;
    },
    [fetchEmissionProfileTreeByAsset.fulfilled]: (state, action) => {
      state.assignmentStatus = LoadingState.succeeded;
      state.emissionProfileTreeByAsset = action.payload;
    },
    [fetchAssignedUsersToAsset.fulfilled]: (state, action) => {
      let assignedUsersToAsset = action.payload;
      assignedUsersToAsset = assignedUsersToAsset.sort((a, b) => a.usrName.localeCompare(b.usrName));
      state.assignedUsersToAsset = assignedUsersToAsset;
    },
    [fetchAvailableUsersToAsset.fulfilled]: (state, action) => {
      let availableUsersToAsset = action.payload;
      availableUsersToAsset = availableUsersToAsset.sort((a, b) => a.usrName.localeCompare(b.usrName));
      state.availableUsersToAsset = availableUsersToAsset;
    },
    [fetchEmissionProfileTreeByEntity.fulfilled]: (state, action) => {
      state.assignmentStatus = LoadingState.succeeded;
      state.emissionProfileTreeByEntity = action.payload;
    },
    [fetchAssignedUsersToSource.fulfilled]: (state, action) => {
      let assignedUsersToSource = action.payload;
      assignedUsersToSource = assignedUsersToSource.sort((a, b) => a.usrName.localeCompare(b.usrName));
      state.assignedUsersToSource = assignedUsersToSource;
    },
    [fetchAvailableUsersToSource.fulfilled]: (state, action) => {
      let availableUsersToSource = action.payload;
      availableUsersToSource = availableUsersToSource.sort((a, b) => a.usrName.localeCompare(b.usrName));
      state.availableUsersToSource = availableUsersToSource;
    },
    [editUser.fulfilled]: (state, action) => {
      const existingIndex = state.users.findIndex((i) => i.usrId === action.payload.userId);
      if (existingIndex >= 0) state.users[existingIndex] = action.payload;
    },
    [setLeavePrompt.fulfilled] : (state, action) => {
      state.isLeavePrompt = action.payload;
    },
    [setHistoryStack.fulfilled] : (state, action) => {
      const stack = [...state.historyStack];
      if (stack.length >= 5) {
        stack.shift();
      }
      if (!stack.length || action.payload !== stack[stack.length-1]) {
        stack.push(action.payload);
        state.historyStack = stack;
      }
    },
  }
});

export const selectUserById = (state, userId) => {
  if (!state.users.users?.length === 0) return null;
  const parsedId = parseInt(userId);
  const found = state.users.users.find((a) => a.usrId === parsedId);
  return found ?? null;
};

export default usersSlice.reducer;
