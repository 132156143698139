import { toast } from 'react-toastify';
import { unwrapResult } from '@reduxjs/toolkit';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTrackBackground, Range } from 'react-range';
import { Button, Col, Modal, Row, Spinner } from 'react-bootstrap';
import { LoadingState } from '../../../utilities/constants';
import { createKpiData, getSdgDataByOrg } from '../disclosureSlice';
import success_response from '../../../assets/images/success_response.svg';
import error_response from '../../../assets/images/error_response.svg';
import { useTranslation } from 'react-i18next';

export function KPIDataCaptureFormModal({
  handlerModalClose,
  kpiDataFormModalShow,
  rating,
  setupId,
  kpiName,
  kpiDesc,
  isApplicable,
}) {
  // Range properties
  const STEP = 1;
  const MAX = 7;
  const MIN = 1;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { createKpiDataStatus } = useSelector((state) => state.disclosure);
  const userInfo = useSelector((state) => state.users.user);

  const [rangeValue, setRangeValue] = useState([1]);
  const [comment, setComment] = useState('');
  const [characterCount, setCharacterCount] = useState(0);

  const onChangeComment = (e) => {
    setCharacterCount(e.target.value.length);
    setComment(e.target.value);
  };
  const onChangeHandle = (x) => {
    setRangeValue(x);
  };

  useEffect(() => {
    setRangeValue([Number(rating || 1)]);
  }, [rating]);

  const onSaveKpiData = async () => {
    const kpiDataResponse = await dispatch(
      createKpiData({
        orgId: userInfo?.usrOrgId,
        keycloackId: userInfo?.usrKeycloackId,
        kpiSetupId: setupId,
        rating: `${rangeValue[0]}`,
        comment: comment,
        kpiFileId: null
      })
    );
    const kpiDataResult = unwrapResult(kpiDataResponse);
    if (!kpiDataResult.error) {
      handlerModalClose(false);
      const response = await dispatch(getSdgDataByOrg(userInfo?.usrOrgId));
      const result = unwrapResult(response);
      if (!result.error) {
        toast.success(t('KPI data added successfully'));
      }
    }
  };

  const kpiBadgeStyle = {
    backgroundColor: '#F0F4F8',
    color: '#102A43',
    fontSize: '12px',
    borderRadius: '4px',
    padding: ' 5px 10px',
    width: ' fit-content',
    fontWeight: '500'
  };

  return (
    <Fragment>
      <Modal
        animation={false}
        show={kpiDataFormModalShow}
        onHide={() => handlerModalClose(true)}
        size='md'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        backdrop='static'
        className='activities-modal'
      >
        <Modal.Header closeButton>
          <h5>{t('Rating Progress')}</h5>
          <span style={kpiBadgeStyle}>{kpiName}</span>
        </Modal.Header>

        <Modal.Body>
          <p className='description mt-2'>{t(kpiDesc)}</p>
          {isApplicable === 'Yes' ? (
            <Fragment>
              {rating !== '7' ? (
                <Row className='mt-4'>
                  <Col md={12}>
                    <div className='kpi-card-slider mb-4'>
                      <Range
                        values={rangeValue}
                        step={STEP}
                        min={MIN}
                        max={MAX}
                        onChange={onChangeHandle}
                        renderTrack={({ props, children }) => (
                          <div
                            onMouseDown={props.onMouseDown}
                            onTouchStart={props.onTouchStart}
                            style={{
                              ...props.style,
                              height: '36px',
                              display: 'flex',
                              width: '100%'
                            }}
                          >
                            <div
                              ref={props.ref}
                              style={{
                                height: '8px',
                                width: '100%',
                                borderRadius: '4px',
                                background: getTrackBackground({
                                  values: rangeValue,
                                  colors: ['#092159', '#F0F4F8'],
                                  min: MIN,
                                  max: MAX
                                }),
                                alignSelf: 'center'
                              }}
                            >
                              {children}
                            </div>
                          </div>
                        )}
                        renderThumb={({ props, isDragged }) => (
                          <div
                            {...props}
                            style={{
                              ...props.style,
                              height: '20px',
                              width: '20px',
                              borderRadius: '50%',
                              backgroundColor: '#092159',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                          >
                            <div
                              style={{
                                position: 'absolute',
                                top: '-18px',
                                color: '#102A43',
                                fontWeight: '600',
                                fontSize: '12px'
                              }}
                            >
                              {rangeValue}
                            </div>
                          </div>
                        )}
                      />
                    </div>
                  </Col>
                  <Col md={12}>
                    <textarea
                      rows='3'
                      className='form-control'
                      type='text'
                      placeholder={t('Add your comments on the progress..')}
                      onChange={onChangeComment}
                    />
                    <p className='text-danger'>{characterCount >= 256 && t('Maximum 255 characters only')}</p>{' '}
                  </Col>
                  <Col md={12} className='d-flex justify-content-end mt-4'>
                    {
                      createKpiDataStatus === LoadingState.loading 
                        ? <Spinner variant='primary' style={{ marginRight: '10px' }} animation='border' /> 
                        : null
                    }
                    <Button
                      size='sm'
                      disabled={!comment || characterCount >= 256 || createKpiDataStatus === LoadingState.loading}
                      variant='primary'
                      type='button'
                      onClick={onSaveKpiData}
                    >
                      {t('Save')}
                    </Button>
                  </Col>
                </Row>
              ) : (
                <div className='text-center'>
                  <img
                    className='mb-4'
                    alt='response'
                    style={{
                      width: '300px'
                    }}
                    src={success_response}
                  />
                  <h4 className='text-success mb-4'>{t('Success!')}</h4>
                  <div className='success mb-4'>{t('You have reached to the max rating')}</div>
                </div>
              )}
            </Fragment>
          ) : (
            <Fragment>
              <div className='text-center'>
                <img
                  className='mb-4'
                  alt='response'
                  style={{
                    width: '300px'
                  }}
                  src={error_response}
                />
                <h4 className='text-warning mb-4'>{t('Warning!')}</h4>
                <div className='success mb-4'>
                  {
                    t('Cannot capture data for this KPI. You have configured as Not Applicable. You may enable data capturing by updating the applicable status to “yes”')
                  }
                </div>
              </div>
            </Fragment>
          )}
        </Modal.Body>
      </Modal>
    </Fragment>
  );
}
