import { Pagination } from 'react-bootstrap';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';

function PaginationComponent(props) {
  const [selectedPage, setSelectedPage] = useState(props.pageIndex + 1);

  const pagination = (selectedPage) => {
    let current = selectedPage,
      last = props.pageCount,
      numbersBetween = 2,
      left = current - numbersBetween,
      right = current + numbersBetween + 2,
      pageNumbers = [],
      pageNumbersWithDots = [],
      dotIndex;

    for (let i = 1; i <= last; i++) {
      if (i === 1 || i === last || (i >= left && i < right)) {
        pageNumbers.push(i);
      }
    }

    for (let i of pageNumbers) {
      if (dotIndex) {
        if (i - dotIndex === 2) {
          pageNumbersWithDots.push(dotIndex + 1);
        } else if (i - dotIndex !== 1) {
          pageNumbersWithDots.push('...');
        }
      }
      pageNumbersWithDots.push(i);
      dotIndex = i;
    }
    return pageNumbersWithDots;
  };

  const paginationList = pagination(selectedPage);

  return (
    <div className="pagination-wrapper align-items-center">
      {props.length >= 7 && (
        <span>
          <Trans
            i18nKey="Showing {{from}} to {{to}} of {{total}}"
            values={{ 
              from: props.pageIndex === 0 ? props.pageIndex + 1 : props.pageIndex * props.pageSize + 1, 
              to: props.pageIndex + 1 === props.pageCount ? props.length : (props.pageIndex + 1) * props.pageSize,
              total: props.length,
            }}
          />
        </span>
      )}
      {props.length >= 7 && (
        <Pagination className="pagination-list">
          {paginationList.map((item, idx) => {
            if (item === '...') {
              return <Pagination.Ellipsis key={`paging-${idx}`} />;
            } else {
              return (
                <Pagination.Item
                  key={`paging-${idx}`}
                  className={selectedPage === item ? 'pagination-item-active mr-2' : 'pagination-item mr-2'}
                  onClick={() => {
                    props.gotoPage(item - 1);
                    setSelectedPage(item);
                  }}
                >
                  {item}
                </Pagination.Item>
              );
            }
          })}
        </Pagination>
      )}
    </div>
  );
}

export default PaginationComponent;
