import keycloak from '../../keycloak';
import { config } from '../../utilities/config';

export async function fetchFavouriteReportsApi(organizationId) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/favourite-report`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    }
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function createFavReportApi(organizationId, data) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/favourite-report`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function removeFavReportApi(organizationId, data) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/favourite-report`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}
