import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setLeavePrompt } from '../features/manage-users/usersSlice';
import DiscardModal from './DiscardModal';

const LeavePrompt = ({ isLeavePrompt }) => {
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const [nextLocation, setNextLocation] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();

  const onDiscardHandler = () => {
    setIsShowModal(false);
    setIsConfirmed(true);
    dispatch(setLeavePrompt(false));
  };

  const onCancelHandler = () => {
    setIsShowModal(false);
    setNextLocation(null);
  };

  useEffect(() => {
    if (isConfirmed && nextLocation) {
      history.push(nextLocation);
      setIsConfirmed(false);
      setNextLocation(null);
    }
  }, [isConfirmed, nextLocation, history, isLeavePrompt]);

  useEffect(() => {
    if (!isShowModal && !isConfirmed) {
      const unblock = history.block((location) => {
        if (isLeavePrompt) {
          setIsShowModal(true);
          setNextLocation(location);
          return false;
        }
        return true;
      });
      return () => unblock();
    }
  }, [isLeavePrompt, isConfirmed, history, isShowModal]);

  return (
    <DiscardModal
      isShowModal={isShowModal}
      onCancelHandler={onCancelHandler}
      onDiscardHandler={onDiscardHandler}
    />
  );
};

export default LeavePrompt;
