import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import { fetchEmissionsByTop10Contributors } from '../reportsSlice';
import { LoadingState } from '../../../utilities/constants';
import { useSelector } from 'react-redux';
import { HorizontalBar } from 'react-chartjs-2';
import { Select } from '../configurations/Select';
import { useLocation } from 'react-router-dom';
import ReportContainer from '../ReportContainer';
import { useHistory } from 'react-router-dom';
import {
  setupReportRangePeriod,
  getCurrentReportPeriod,
  getReportYearTypeOptions,
  getReportRangePeriod,
  customFilterPeriodOptions,
  parseFavouriteData,
  getEmissionTypeOptions,
  reportYearList,
  generateRandomColors,
  generateChartLegend,
  formatNumber,
  reportUnitByTypeId,
  parseUnitJson,
  displayTargetUnit,
  createInitChartConfig,
  createInitChartPlugins,
} from '../reportHelper';
import { useTranslation } from 'react-i18next';

const EmissionsByTop10Contributors = () => {
  const { t } = useTranslation();
  const chartRef = useRef(null);
  const history = useHistory();
  const { filterData } = parseFavouriteData(useLocation().search);
  const userInfo = useSelector((state) => state.users.user);
  const currentReportPeriod = getCurrentReportPeriod(userInfo.usrOrgOptions);
  const reportRangePeriod = getReportRangePeriod(userInfo.usrOrgOptions, currentReportPeriod.reportYear);
  const [filterParams, setFilterParams] = useState({
    reportYearType: 'Calendar Year',
    reportYear: new Date().getUTCFullYear(),
    recentTime: null,
    emissionTypeId: 1,
    groupByType: 'entity',
    ...currentReportPeriod,
    ...(filterData || {})
  });
  const [chartData, setChartData] = useState({});
  const emissionsByTop10Contributors = useSelector((state) => state.reports.emissionsByTop10Contributors);
  const emissionsByTop10ContributorsStatus = useSelector((state) => state.reports.emissionsByTop10ContributorsStatus);

  const selectedReportYearTypeHandler = ({ target }) => {
    if (target.value === 'SETUP') {
      setupReportRangePeriod(history);
    }
  };

  const groupByTypeOptions = [
    { label: t('Entity'), value: 'entity' },
    { label: t('Entity Business Number'), value: 'entityBusinessNumber' },
    { label: t('Metric'), value: 'metric' }
  ];

  const plugins = createInitChartPlugins([], {
    displayChartAxesTotal: () => chartData.datasets &&
      chartData.datasets.length > 0 &&
      emissionsByTop10ContributorsStatus === LoadingState.succeeded
  });

  const add = (accumulator, a) => {
    return accumulator + a;
  };

  const getLabels = useCallback(() => {
    const labels = [];
    const data = [];
    const { unit } = reportUnitByTypeId(parseInt(filterParams.emissionTypeId), t);
    if (emissionsByTop10ContributorsStatus === LoadingState.succeeded) {
      const insert = (arr, index, newItem) => [
        ...arr.slice(0, index),
        newItem,
        ...arr.slice(index, emissionsByTop10Contributors.length - 1)
      ];
      generateRandomColors(true);
      emissionsByTop10Contributors.map((item, itemIndex) => {
        if (labels.includes(item.contributor) === false) {
          labels.push(item.contributor);
          item.children.map((item) => {
            const dataList = new Array(emissionsByTop10Contributors.length - 1).fill(0);
            const foundIndex = data.findIndex((x) => {
              if (item.d_name) {
                return x.label === item.d_name;
              } else if (item.eps_name) {
                return x.label === item.eps_name;
              } else {
                return x.label === item.business_no;
              }
            });
            if (foundIndex === -1) {
              data.push({
                axis: 'y',
                label: item.d_name ? item.d_name : item.eps_name ? item.eps_name : item.business_no,
                data: insert(dataList, itemIndex, parseFloat(item.value)),
                backgroundColor: generateRandomColors(),
                contributor: item.contributor,
                units: parseUnitJson(item.unit),
              });
            } else {
              parseUnitJson(item.unit, data[foundIndex].units);
              data[foundIndex].contributor !== item.contributor
                ? (data[foundIndex].data = insert(data[foundIndex].data, itemIndex, parseFloat(item.value)))
                : (data[foundIndex].data[itemIndex] = data[foundIndex].data[itemIndex] + parseFloat(item.value));
            }
            return data;
          });
        }
        return labels;
      });
      data.forEach((singleDataset) => {
        singleDataset.title = singleDataset.label;
        singleDataset.total = singleDataset.data.reduce(add, 0);
        singleDataset.displayUnit = displayTargetUnit(singleDataset.units, unit);
        const { label, total, displayUnit } = singleDataset;
        singleDataset.label = `${label} (${formatNumber(total)} ${displayUnit})`;
      });
    }
    return { labels: labels, datasets: data };
  }, [t, emissionsByTop10ContributorsStatus, emissionsByTop10Contributors, filterParams.emissionTypeId]);

  useEffect(() => {
    if (emissionsByTop10ContributorsStatus === LoadingState.loading) {
      setChartData({});
    }
    if (emissionsByTop10ContributorsStatus === LoadingState.succeeded) {
      setChartData(getLabels());
    }
  }, [
    emissionsByTop10ContributorsStatus, emissionsByTop10Contributors, getLabels,
  ]);

  useEffect(() => {
    generateChartLegend(chartRef);
  }, [emissionsByTop10ContributorsStatus, chartData]);

  const fetchEmissionsByTop10ContributorsHandler = async (dispatch, payload) => {
    payload.data = {
      reportYearType: filterParams.reportYearType,
      reportYear: parseInt(filterParams.reportYear),
      emissionTypeId: parseInt(filterParams.emissionTypeId),
      groupByType: filterParams.groupByType,
      ...payload.data,
    };
    return await dispatch(fetchEmissionsByTop10Contributors(payload));
  };

  const getFavouriteFilterDataHandler = (params, filters) => {
    const paramsData = {
      ...params,
      reportYearType: filters.reportYearType,
      reportYear: parseInt(filters.reportYear),
      emissionTypeId: parseInt(filters.emissionTypeId),
      groupByType: filters.groupByType,
    };
    setFilterParams(paramsData);
    return paramsData;
  };

  const options = useMemo(() => {
    const totalArray = [];
    if (chartData.datasets && chartData.datasets.length > 0 && emissionsByTop10ContributorsStatus === LoadingState.succeeded) {
      chartData.datasets[0].data.map((dataPoint, index) => {
        let total = dataPoint;
        let i = 1;
        while (i < chartData.datasets.length) {
          total += chartData.datasets[i].data[index];
          i++;
        }
        return totalArray.push(total);
      });
    }
    const { title } = reportUnitByTypeId(parseInt(filterParams.emissionTypeId), t);
    return createInitChartConfig({
      scales: {
        xAxes: [
          {
            stacked: true,
            ticks: {
              beginAtZero: true,
              suggestedMax: Math.max(...totalArray) + (5 / 100) * Math.max(...totalArray)
            },
            scaleLabel: {
              display: true,
              labelString: title,
            }
          }
        ],
        yAxes: [
          {
            stacked: true,
            gridLines: {
              display: false
            },
            scaleLabel: {
              display: true,
              labelString: t('Provider')
            }
          }
        ]
      },
      tooltips: {
        filter: function (tooltipItem, data) {
          let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return value !== 0;
        },
        callbacks: {
          label: (tooltipItem, data) => {
            const { title, displayUnit } = data.datasets[tooltipItem.datasetIndex];
            return `${title}: ${formatNumber(tooltipItem.value)} ${displayUnit}`;
          }
        },
      },
    });
  }, [t, chartData.datasets, emissionsByTop10ContributorsStatus, filterParams.emissionTypeId]);

  const parameters = [
    {
      key: t('Report Year'),
      value: filterParams.reportYear,
    },
  ];


  const chartRender = () => {
    return (
      <>
        <div className="chart-graph">
          <HorizontalBar
            data={chartData}
            options={options}
            ref={chartRef}
            plugins={plugins}
          />
        </div>
        <div id="js-legend" className="chart-legend"></div>
      </>
    )
  };

  const extraConfigRender = (isFavReport) => {
    return (
      <>
        <Select
          disabled={isFavReport}
          label={t("Report Year Type")}
          name="reportYearType"
          options={getReportYearTypeOptions(t, reportRangePeriod)}
          onChange={selectedReportYearTypeHandler}
        />
        <Select
          disabled={isFavReport}
          label={t("Report Year")}
          name="reportYear"
          options={reportYearList()}
        />
        <Select
          disabled={isFavReport}
          label={t("CO2-e (t) or Usage")}
          name="emissionTypeId"
          options={getEmissionTypeOptions(t)}
        />
        <Select
          disabled={isFavReport}
          label={t("Group By")}
          name="groupByType"
          options={groupByTypeOptions}
        />
      </>
    );
  };

  return (
    <ReportContainer
      reportName={t('Emissions by Top 10 Contributors')}
      reportSubTitle={t('Top Ten Contributors by Entity')}
      fetchReportData={fetchEmissionsByTop10ContributorsHandler}
      fetchReportStatus={emissionsByTop10ContributorsStatus}
      reportData={emissionsByTop10Contributors}
      reportRender={chartRender}
      extraConfigRender={extraConfigRender}
      getFavouriteFilterData={getFavouriteFilterDataHandler}
      extraConfigDefault={{
        reportYearType: filterParams.reportYearType,
        intervalType: filterParams.intervalType,
        reportYear: parseInt(filterParams.reportYear),
        emissionTypeId: parseInt(filterParams.emissionTypeId),
        groupByType: filterParams.groupByType,
      }}
      chartRef={chartRef}
      extraParameters={parameters}
      isUseEndDate={false}
      isUseStartDate={false}
      getTimeSelectOptions={customFilterPeriodOptions}
    />
  );
};

export default EmissionsByTop10Contributors;
