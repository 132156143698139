import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import LoadingButton from './LoadingButton';

const DeleteConfirmModal = ({
  isShowModal,
  isLoading,
  closeConfirmModal,
  handleDeleteAction,
  messageRender,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      show={isShowModal}
      onHide={closeConfirmModal}
      className="sisde-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('Confirm to delete')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {messageRender(t)}
      </Modal.Body>
      <Modal.Footer>
        <LoadingButton isLoading={isLoading} />
        <Button variant="secondary" onClick={closeConfirmModal}>
          {t('Cancel')}
        </Button>
        <Button variant="danger" onClick={handleDeleteAction}>
          {t('Confirm')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteConfirmModal;
