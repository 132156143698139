export const LoadingState = {
  idle: 'idle',
  loading: 'loading',
  succeeded: 'succeeded',
  failed: 'failed'
};

export const UserRoleList = {
  cv_admin: 'ADMIN',
  cv_audit: 'AUDIT',
  cv_open: 'OPEN',
  cv_restricted: 'RESTRICTED',
  cv_restricted_audit: 'RESTRICTED AUDIT',
  cv_structured: 'STRUCTURED'
}
