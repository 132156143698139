import { config } from '../../utilities/config';
import keycloak from '../../keycloak';

export async function fetchTargetEmissionApi(organizationId, entityId) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/entity/${entityId}/target-emission`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    }
  });
  const data = await response.json();
  if (!response.ok) throw new Error(data.message);
  return data;
}

export async function createTargetEmissionApi(organizationId, entityId, data) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/entity/${entityId}/target-emission`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function editTargetEmissionApi(organizationId, entityId, targetEmissionId, data) {
  const response = await fetch(
    `${config.API_BASE_URL}/${organizationId}/entity/${entityId}/target-emission/${targetEmissionId}`,
    {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${keycloak.token}`
      },
      body: JSON.stringify(data)
    }
  );
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function removeTargetEmissionApi(organizationId, entityId, targetEmissionId) {
  const response = await fetch(
    `${config.API_BASE_URL}/${organizationId}/entity/${entityId}/target-emission/${targetEmissionId}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${keycloak.token}`
      }
    }
  );
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}
