import { config } from '../../utilities/config';
import keycloak from '../../keycloak';

export async function fetchListCurrenciesApi(organizationId) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/currencies`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    }
  });
  const data = await response.json();
  if (!response.ok) throw new Error(data.message);
  return data;
}