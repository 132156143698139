import { config } from '../../utilities/config';
import keycloak from '../../keycloak';

export async function fetchOrganizationDetailsApi() {
  const response = await fetch(`${config.API_BASE_URL}/organization`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    }
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function fetchBillSettingApi(organizationId) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/bill-setting`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    }
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function fetchBillSettingByIdApi(organizationId, bsId) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/bill-setting/${bsId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    }
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function addBillSettingApi(organizationId, data) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/bill-setting`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function updateBillSettingApi(organizationId, bsId, data) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/bill-setting/${bsId}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function deleteBillSettingApi(organizationId, bsId) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/bill-setting/${bsId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    }
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}
