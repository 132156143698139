import { config } from '../../utilities/config';
import keycloak from '../../keycloak';

export async function fetchEntityGroupsApi(organizationId) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/entity-groups`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    }
  });
  const data = await response.json();
  if (!response.ok) throw new Error(data.message);
  return data;
}

export async function createEntityGroupApi(organizationId, data) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/entity-groups`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function editEntityGroupApi(organizationId, egId, data) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/entity-groups/${egId}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function removeEntityGroupApi(organizationId, egId) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/entity-groups/${egId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    }
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function fetchAssignedEntitiesForEGApi(organizationId, egId) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/entity-groups/${egId}/assigned-entities`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    }
  });
  const data = await response.json();
  if (!response.ok) throw new Error(data.message);
  return data;
}

export async function fetchAvailableEntitiesToEGApi(organizationId, egId) {
  const response = await fetch(
      `${config.API_BASE_URL}/${organizationId}/entity-groups/${egId}/available-entities`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${keycloak.token}`
      }
    }
  );
  const data = await response.json();
  if (!response.ok) throw new Error(data.message);
  return data;
}

export async function assignMultiEntitiesApi(organizationId, egId, data) {
  const response = await fetch(
      `${config.API_BASE_URL}/${organizationId}/entity-groups/${egId}/assigned-entities`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${keycloak.token}`
        },
        body: JSON.stringify(data)
      }
  );
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}
