import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { LoadingState } from '../../utilities/constants';
import { recursiveFind } from '../../utilities/treeHelpers';
import { fetchEmissionProfileTreeByEntityApi } from '../entity/entityApi';
import { selectEntityById } from '../entity/entitySlice';
import {
  deleteEmissionApi,
  fetchEmissionDataApi,
  fetchEmissionProfileSourceUnitApi,
  fetchEmissionSourceUnitsApi,
  fetchSingleEmissionDataApi,
} from './emissionApi';
import { getEmissionProfileSourceFactorApi, getEmissionSourceFactorByEsrcIdApi } from '../emission-profile/emissionProfileApi';

export const fetchEmissionProfileTreeByEntity = createAsyncThunk(
  'emissions/fetchEmissionProfileTreeByEntity',
  async (payload) => {
    try {
      const response = await fetchEmissionProfileTreeByEntityApi(payload.orgId, payload.entityId);
      return response;
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  }
);

export const fetchEmissionProfileUnits = createAsyncThunk(
  'emissions/fetchEmissionProfileUnits',
  async (payload, thunkAPI) => {
    try {
      const { usrOrgId } = thunkAPI.getState().users.user;
      const selectedEP = getSelectedEP(thunkAPI.getState());
      let response;
      if (selectedEP.eprflsrcEsrcId) {
        response = await fetchEmissionSourceUnitsApi(usrOrgId, selectedEP.eprflsrcEsrcId);
      } else {
        response = await fetchEmissionProfileSourceUnitApi(usrOrgId, selectedEP.eprflsrcId);
      }
      return response;
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  }
);

export const fetchEmissionProfileUnitsByEPS = createAsyncThunk(
  'emissions/fetchEmissionProfileUnitsByEPS',
  async (payload, thunkAPI) => {
    try {
      const { usrOrgId } = thunkAPI.getState().users.user;
      let response;
      if (payload.eprflsrcEsrcId) {
        response = await fetchEmissionSourceUnitsApi(usrOrgId, payload.eprflsrcEsrcId);
      } else {
        response = await fetchEmissionProfileSourceUnitApi(usrOrgId, payload.eprflsrcId);
      }
      return response;
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  }
);

export const fetchEmissionProfileFactorByEPS = createAsyncThunk(
  'emissions/fetchEmissionProfileFactorByEPS',
  async (payload, thunkAPI) => {
    try {
      const { usrOrgId } = thunkAPI.getState().users.user;
      let response, result = null;
      if (payload.eprflsrc.eprflsrcEsrcId) {
        response = await getEmissionSourceFactorByEsrcIdApi(usrOrgId, payload.eprflsrc.eprflsrcEsrcId);
        result = response.factors;
      } else {
        response = await getEmissionProfileSourceFactorApi(usrOrgId, payload.eprflsrc.eprflsrcId);
        result = response?.eprflsrcConversionFactor;
      }
      return result;
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  }
);

export const fetchEmissionData = createAsyncThunk('emissions/fetchEmissionData', async (payload, thunkAPI) => {
  try {
    const { usrOrgId } = thunkAPI.getState().users.user;
    const { selectedEntity, selectedEP } = thunkAPI.getState().emissions;
    const response = await fetchEmissionDataApi(usrOrgId, selectedEntity, selectedEP);
    return response;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const deleteEmission = createAsyncThunk('emissions/deleteEmission', async (payload, { dispatch }) => {
  try {
    const { orgId, emissionId } = payload;
    const response = await deleteEmissionApi(orgId, emissionId);
    dispatch(fetchEmissionData());
    return response;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const fetchSingleEmissionData = createAsyncThunk(
  'emissions/fetchSingleEmissionData',
  async (payload, thunkAPI) => {
    try {
      const { usrOrgId } = thunkAPI.getState().users.user;
      const response = await fetchSingleEmissionDataApi(usrOrgId, payload.emissionId);
      return response;
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  }
);

const emissionSlice = createSlice({
  name: 'emissions',
  initialState: {
    selectedEntity: null,
    selectedEP: null,
    entityEPTree: null,
    status: LoadingState.idle,
    emissionData: [],
    emissionDataStatus: LoadingState.idle
  },
  reducers: {
    setSelectedEntity(state, action) {
      state.selectedEntity = action.payload;
      state.selectedEP = null;
      state.emissionData = [];
      state.emissionDataStatus = LoadingState.idle;
    },
    setSelectedEP(state, action) {
      state.selectedEP = action.payload;
    },
    resetEmissionData(state) {
      state.emissionData = [];
      state.emissionDataStatus = LoadingState.idle;
    }
  },
  extraReducers: {
    [fetchEmissionProfileTreeByEntity.pending]: (state) => {
      state.status = LoadingState.loading;
    },
    [fetchEmissionProfileTreeByEntity.fulfilled]: (state, action) => {
      state.status = LoadingState.succeeded;
      state.entityEPTree = action.payload;
    },
    [fetchEmissionProfileTreeByEntity.rejected]: (state) => {
      state.status = LoadingState.failed;
    },
    [fetchEmissionData.pending]: (state) => {
      state.emissionDataStatus = LoadingState.loading;
      state.emissionData = [];
    },
    [fetchEmissionData.fulfilled]: (state, action) => {
      state.emissionDataStatus = LoadingState.succeeded;
      state.emissionData = action.payload;
    },
    [fetchEmissionData.rejected]: (state, action) => {
      state.emissionDataStatus = LoadingState.failed;
      state.emissionData = [];
    }
  }
});

export const { setSelectedEntity, setSelectedEP, resetEmissionData } = emissionSlice.actions;

export const selectEmissionProfileSourceById = (state, id) => {
  if (!state.emissions.entityEPTree) return null;
  const found = recursiveFind(state.emissions.entityEPTree, (n) => n.eprflsrcId === parseInt(id));
  return found ? { ...found, children: null } : null;
};
export const getSelectedEntity = (state) => selectEntityById(state, state.emissions.selectedEntity);
export const getSelectedEP = (state) => selectEmissionProfileSourceById(state, state.emissions.selectedEP);

export default emissionSlice.reducer;
