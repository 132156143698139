import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { fetchEmissionsByTop10EPS } from '../reportsSlice';
import { LoadingState } from '../../../utilities/constants';
import { useSelector } from 'react-redux';
import { HorizontalBar } from 'react-chartjs-2';
import { Select } from '../configurations/Select';
import { useLocation } from 'react-router-dom';
import ReportContainer from '../ReportContainer';
import { useHistory } from 'react-router-dom';
import {
  setupReportRangePeriod,
  getCurrentReportPeriod,
  getReportYearTypeOptions,
  getReportRangePeriod,
  parseFavouriteData,
  getEmissionTypeOptions,
  reportYearList,
  generateRandomColors,
  generateChartLegend,
  customFilterPeriodOptions,
  reportUnitByTypeId,
  formatNumber,
  parseUnitJson,
  displayTargetUnit,
  createInitChartConfig,
  createInitChartPlugins,
} from '../reportHelper';
import { useTranslation } from 'react-i18next';

const EmissionsByTop10EPS = () => {
  const { t } = useTranslation();
  const chartRef = useRef(null);
  const history = useHistory();
  const { filterData } = parseFavouriteData(useLocation().search);
  const userInfo = useSelector((state) => state.users.user);
  const currentReportPeriod = getCurrentReportPeriod(userInfo.usrOrgOptions);
  const reportRangePeriod = getReportRangePeriod(userInfo.usrOrgOptions, currentReportPeriod.reportYear);
  const [filterParams, setFilterParams] = useState({
    reportYearType: 'Calendar Year',
    reportYear: new Date().getUTCFullYear(),
    emissionTypeId: 1,
    ...currentReportPeriod,
    ...(filterData || {})
  });
  const [chartData, setChartData] = useState({});
  const emissionsByTop10EPS = useSelector((state) => state.reports.emissionsByTop10EPS);
  const emissionsByTop10EPSStatus = useSelector((state) => state.reports.emissionsByTop10EPSStatus);

  const selectedReportYearTypeHandler = ({ target }) => {
    if (target.value === 'SETUP') {
      setupReportRangePeriod(history);
    }
  };

  const add = (accumulator, a) => {
    return accumulator + a;
  };

  const plugins = createInitChartPlugins([], {
    displayChartAxesTotal: () => chartData.datasets && chartData.datasets.length > 0 &&
      emissionsByTop10EPSStatus === LoadingState.succeeded,
  });

  const getLabels = useCallback(() => {
    const labels = [];
    const data = [];
    const { unit } = reportUnitByTypeId(parseInt(filterParams.emissionTypeId), t);
    if (emissionsByTop10EPSStatus === LoadingState.succeeded) {
      const insert = (arr, index, newItem) => {
        return [...arr.slice(0, index), newItem, ...arr.slice(index, emissionsByTop10EPS.length - 1)];
      };
      generateRandomColors(true);
      emissionsByTop10EPS.map((item, itemIndex) => {
        if (labels.includes(item.epsName) === false) {
          labels.push(item.epsName);
          item.children.map((entity) => {
            const dataList = new Array(emissionsByTop10EPS.length - 1).fill(0);
            const foundIndex = data.findIndex((x) => x.label === entity.d_name);
            if (foundIndex === -1) {
              data.push({
                axis: 'y',
                label: entity.d_name,
                data: insert(
                  dataList,
                  itemIndex,
                  entity.co2_et
                    ? !isNaN(entity.co2_et)
                      ? parseFloat(entity.co2_et)
                      : 0
                    : !isNaN(entity.consumption)
                      ? parseFloat(entity.consumption)
                      : 0
                ),
                backgroundColor: generateRandomColors(),
                epsName: item.epsName,
                units: parseUnitJson(entity.unit),
              });
            } else {
              parseUnitJson(entity.unit, data[foundIndex].units);
              data[foundIndex].epsName !== item.epsName
                ? (data[foundIndex].data = insert(
                  data[foundIndex].data,
                  itemIndex,
                  entity.co2_et
                    ? !isNaN(entity.co2_et)
                      ? parseFloat(entity.co2_et)
                      : 0
                    : !isNaN(entity.consumption)
                      ? parseFloat(entity.consumption)
                      : 0
                ))
                : (data[foundIndex].data[itemIndex] =
                  data[foundIndex].data[itemIndex] + parseFloat(entity.co2_et ? entity.co2_et : entity.consumption));
            }
            return data;
          });
        }

        return labels;
      });

      data.forEach((singleDataset) => {
        singleDataset.title = singleDataset.label;
        singleDataset.total = singleDataset.data.reduce(add, 0);
        singleDataset.displayUnit = displayTargetUnit(singleDataset.units, unit);
        const { label, total, displayUnit } = singleDataset;
        singleDataset.label = `${label} (${formatNumber(total)} ${displayUnit})`;
      });
    }
    return { labels: labels, datasets: data };
  }, [t, emissionsByTop10EPSStatus, emissionsByTop10EPS, filterParams.emissionTypeId]);

  const options = useMemo(() => {
    const totalArray = [];
    if (chartData.datasets && chartData.datasets.length > 0 && emissionsByTop10EPSStatus === LoadingState.succeeded) {
      chartData.datasets[0].data.map((dataPoint, index) => {
        let total = dataPoint;
        let i = 1;
        while (i < chartData.datasets.length) {
          total += chartData.datasets[i].data[index];
          i++;
        }
        return totalArray.push(total);
      });
    }
    const { title } = reportUnitByTypeId(parseInt(filterParams.emissionTypeId), t);
    return createInitChartConfig({
      interaction: {
        mode: 'interpolate',
        intersect: false,
        axis: 'x'
      },
      scales: {
        xAxes: [
          {
            stacked: true,
            ticks: {
              beginAtZero: true,
              suggestedMax: Math.max(...totalArray) + (5 / 100) * Math.max(...totalArray)
            },
            scaleLabel: {
              display: true,
              labelString: title,
            }
          }
        ],
        yAxes: [
          {
            stacked: true,
            gridLines: {
              display: false
            }
          }
        ]
      },
      tooltips: {
        filter: function (tooltipItem, data) {
          let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return value !== 0;
        },
        callbacks: {
          label: (tooltipItem, data) => {
            const { title, displayUnit } = data.datasets[tooltipItem.datasetIndex];
            return `${title}: ${formatNumber(tooltipItem.value)} ${displayUnit}`;
          }
        },
      },
    });
  }, [t, chartData.datasets, emissionsByTop10EPSStatus, filterParams.emissionTypeId]);


  useEffect(() => {
    if (emissionsByTop10EPSStatus === LoadingState.loading) {
      setChartData({});
    }
    if (emissionsByTop10EPSStatus === LoadingState.succeeded) {
      setChartData(getLabels());
    }
  }, [
    emissionsByTop10EPSStatus, emissionsByTop10EPS, getLabels,
  ]);

  useEffect(() => {
    generateChartLegend(chartRef);
  }, [emissionsByTop10EPSStatus, chartData]);

  const fetchEmissionsByTop10EPSHandler = async (dispatch, payload) => {
    payload.data = {
      reportYearType: filterParams.reportYearType,
      reportYear: parseInt(filterParams.reportYear),
      emissionTypeId: parseInt(filterParams.emissionTypeId),
      ...payload.data,
    };
    return await dispatch(fetchEmissionsByTop10EPS(payload));
  };

  const getFavouriteFilterDataHandler = (params, filters) => {
    const paramsData = {
      ...params,
      reportYearType: filters.reportYearType,
      reportYear: parseInt(filters.reportYear),
      emissionTypeId: parseInt(filters.emissionTypeId),
    };
    setFilterParams(paramsData);
    return paramsData;
  };

  const parameters = [
    {
      key: t('Report Year'),
      value: filterParams.reportYear,
    },
  ];

  const chartRender = () => {
    return (
      <>
        <div className="chart-graph">
          <HorizontalBar
            data={chartData}
            options={options}
            ref={chartRef}
            plugins={plugins}
          />
        </div>
        <div id="js-legend" className="chart-legend"></div>
      </>
    )
  };

  const extraConfigRender = (isFavReport) => {
    return (
      <>
        <Select
          disabled={isFavReport}
          label={t("Report Year Type")}
          name="reportYearType"
          options={getReportYearTypeOptions(t, reportRangePeriod)}
          onChange={selectedReportYearTypeHandler}
        />
        <Select
          disabled={isFavReport}
          label={t("Report Year")}
          name="reportYear"
          options={reportYearList()}
        />
        <Select
          disabled={isFavReport}
          label={t("CO2-e (t) or Usage")}
          name="emissionTypeId"
          options={getEmissionTypeOptions(t)}
        />
      </>
    );
  };

  return (
    <ReportContainer
      reportName={t('Emissions by Top 10 Emission Profile Sources')}
      reportSubTitle={t('Top Ten Emission Sources by Entity')}
      fetchReportData={fetchEmissionsByTop10EPSHandler}
      fetchReportStatus={emissionsByTop10EPSStatus}
      reportData={emissionsByTop10EPS}
      reportRender={chartRender}
      extraConfigRender={extraConfigRender}
      getFavouriteFilterData={getFavouriteFilterDataHandler}
      extraConfigDefault={{
        reportYearType: filterParams.reportYearType,
        reportYear: parseInt(filterParams.reportYear),
        emissionTypeId: parseInt(filterParams.emissionTypeId),
      }}
      chartRef={chartRef}
      extraParameters={parameters}
      isUseEndDate={false}
      isUseStartDate={false}
      getTimeSelectOptions={customFilterPeriodOptions}
    />
  );
};

export default EmissionsByTop10EPS;
