import React, { useState } from 'react';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import SecondarySidebar from '../../components/Sidebar/SecondarySidebar';
import RouteNotFound from '../../RouteNotFound';
import ManageWhitelabel from "../whitelabel/ManageWhitelabel";
import NewWhitelabel from "../whitelabel/NewWhitelabel";
import EditWhitelabel from "../whitelabel/EditWhitelabel";
import ManageOrganization from "../organization/ManageOrganization";
import NewOrganization from "../organization/NewOrganization";
import EditOrganization from "../organization/EditOrganization";
import ManageEmissionFactors from "../emission-factors/ManageEmissionFactors";
import CustomEmissionProfileSourceReport from "../emission-profile/CustomEmissionProfileSourceReport";
import ManageEnergyContentFactor from "../energy-content-factor/ManageEnergyContentFactor";

const RootAdminHome = () => {
  const [ expandedClass, setExpandedClass ] = useState('');
  const match = useRouteMatch();
  const { t } = useTranslation();
  const routes = [
    {
      path: `${match.url}/setup`,
      name: t('Setup'),
      subPaths: [
        { path: `${match.url}/setup/whitelabel`, name: 'Manage Whitelabel' },
        { path: `${match.url}/setup/organization`, name: 'Manage Organization' },
        { path: `${match.url}/setup/factors`, name: 'Manage Emission Factors' },
        { path: `${match.url}/setup/energy-factors`, name: 'Manage Energy Content Factors' },
        { path: `${match.url}/setup/reports/custom-emission-profile-sources`, name: 'Customer EPS Report' },
      ]
    }
  ];

  return (
    <div className="d-flex flex-row mw-100">
      <SecondarySidebar
        items={routes}
        onCollapse={(isExpanded) => setExpandedClass(isExpanded ? 'offset-expanded': '')}
      />
      <div className={`offset-second ${expandedClass}`}>
        <Switch>

          <Route exact path={`${match.path}/setup/whitelabel`}>
            <ManageWhitelabel />
          </Route>
          <Route exact path={`${match.path}/setup/whitelabel/new`}>
            <NewWhitelabel />
          </Route>
          <Route exact path={`${match.path}/setup/whitelabel/:whitelabelId/edit`}>
            <EditWhitelabel />
          </Route>
          <Route exact path={`${match.path}/setup/organization`}>
            <ManageOrganization />
          </Route>
          <Route exact path={`${match.path}/setup/organization/new`}>
            <NewOrganization />
          </Route>
          <Route exact path={`${match.path}/setup/organization/:orgId/edit`}>
            <EditOrganization />
          </Route>
          <Route exact path={`${match.path}/setup/factors`}>
            <ManageEmissionFactors/>
          </Route>
          <Route exact path={`${match.path}/setup/energy-factors`}>
            <ManageEnergyContentFactor/>
          </Route>
          <Route exact path={`${match.path}/setup/reports/custom-emission-profile-sources`}>
            <CustomEmissionProfileSourceReport/>
          </Route>
          <Route exact path={`${match.path}`}>
            <Redirect to={`${match.path}/setup/whitelabel`} />
          </Route>
          <Route path="*">
            <RouteNotFound />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default RootAdminHome;
