import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAssignedEpsForEpsGroup,
  fetchAvailableEpsToEpsGroup,
  assignMultiEmissionProfileSources,
} from './epsGroupSlice';
import PageHeader from '../../components/PageHeader';
import { useParams } from 'react-router-dom';
import DataMultiAssignment from '../../components/DataMultiAssignment';
import { useTranslation } from 'react-i18next';

const AssignEpsToEpg = () => {
  const dispatch = useDispatch();
  const { epsGroupId } = useParams();
  const { t } = useTranslation();

  const availableEps = useSelector((state) => state.epsGroups.availableEpsOfEntityGroup);
  const assignedEps = useSelector((state) => state.epsGroups.assignedEpsToEpsGroup);
  const orgId = useSelector((state) => state.users.user.usrOrgId);

  const [isLoading, setIsLoading] = useState(false);

  const handleAssignData = async (actionType, divIds) => {
    try {
      setIsLoading(true);
      const data = { type: actionType === 'Assign' ? 'ADD' : 'REMOVE', divIds };
      const resultAction = await dispatch(assignMultiEmissionProfileSources({ orgId, epsGroupId, ...data }));
      unwrapResult(resultAction);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    dispatch(fetchAssignedEpsForEpsGroup({ orgId, epsGroupId }));
    dispatch(fetchAvailableEpsToEpsGroup({ orgId, epsGroupId }));
  }, [orgId, epsGroupId, dispatch]);

  return (
    <Container fluid>
      <Row>
        <Col>
          <PageHeader
            title={t('Assign Emission Profile Sources')}
            enableBackButton={true}
          />
        </Col>
      </Row>
      <DataMultiAssignment
        isLoading={isLoading}
        multiTitle={t('Emission Profile Sources')}
        singleTitle={t('Emission Profile Source')}
        dataKey="eprflsrcId"
        dataName="eprflsrcName"
        dataSearch="eprflsrcAssignedId"
        availableData={availableEps}
        assignedData={assignedEps}
        handleAssignData={handleAssignData}
      />
    </Container>
  );
};

export default AssignEpsToEpg;
