import React, { useState } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import SecondarySidebar from '../../components/Sidebar/SecondarySidebar';
import RouteNotFound from '../../RouteNotFound';
import ManageSms2Fa from './TwoFactorAuthentication/ManageSms2Fa';

const AccountHome = () => {
  const [ expandedClass, setExpandedClass ] = useState('');
  const match = useRouteMatch();
  const { t } = useTranslation();

  const routes = [
    {
      path: `${match.url}/two-factor-authentication`,
      name: t('Two-Factor Authentication'),
      isLink: true
    },
  ];

  return (
    <div className="d-flex flex-row mw-100">
      <SecondarySidebar
        items={routes}
        onCollapse={(isExpanded) => setExpandedClass(isExpanded ? 'offset-expanded': '')}
      />
      <div className={`offset-second ${expandedClass}`}>
        <Switch>
          <Route exact path={`${match.path}/two-factor-authentication`}>
            <ManageSms2Fa />
          </Route>
          <Route exact path={match.path}>
            <Redirect to={`${match.path}/two-factor-authentication`} />
          </Route>
          <Route path="*">
            <RouteNotFound />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default AccountHome;
