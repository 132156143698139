import { useTranslation } from 'react-i18next';
import { SDGLogoComponent } from './SDGLogoComponent';
import { Badge, Card} from 'react-bootstrap';

export function GoalsCardHeader({ imagePath, goalName, color, description, esgIndicators }) {
  const { t } = useTranslation();
  return (
    <Card className='mt-3 border-0 p-0 text-left goals-card-header'>
      <div className='goals-card-header-wrapper'>
        <div className='image-side'>{SDGLogoComponent(imagePath)}</div>
        <div className='text-side'>
          <div className='text-side-header'>
              <h5>{t(goalName)}</h5>
              <div>
                {esgIndicators &&
                  esgIndicators.length > 0 &&
                  esgIndicators.map((esgIndicator) => {
                    return (
                        <Badge className='ml-2' style={{ backgroundColor: color }}>
                          {t(esgIndicator)}
                        </Badge>

                    );
                  })}
              </div>
          </div>
          <p>{t(description)}</p>
        </div>
      </div>
    </Card>
  );
}
