import { Col, Container, Row, Button, Spinner, Modal, Form } from 'react-bootstrap';
import PageHeader from '../../components/PageHeader';
import Select from 'react-select';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createTranslationBundle,
  createTranslationValue,
  getTranslationBundles,
  getTranslationValuesByGroup,
  updateTranslationValue
} from './translationsSlice';
import { LoadingState } from '../../utilities/constants';
import DataTable from '../../components/DataTable';
import { IoCloseOutline, IoSaveOutline } from 'react-icons/io5';
import { AiOutlineEdit } from 'react-icons/ai';
import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';

const ManageTranslations = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const orgId = useSelector((state) => state.users.user.usrOrgId);
  const translationStatus = useSelector((state) => state.translations.status);
  const translationBundles = useSelector((state) => state.translations.translationBundles);
  const translationGroups = useSelector((state) => state.translations.translationGroups);
  const translationValues = useSelector((state) => state.translations.translationValues);
  const translationValuesState = useSelector((state) => state.translations.translationValuesState);
  const [controlledPageIndex, setControlledPage] = useState(0);

  const [selectedTransBundleId, setSelectedTransBundleId] = useState(null);
  const [selectedTransGroupId, setSelectedTransGroupId] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [newTranslationName, setNewTranslationName] = useState(null);
  const [transationCreateState, setTransationCreateState] = useState(false);

  const columns = useMemo(() => {
    return [
      {
        Header: t('Translation Key'),
        accessor: 'uireskeyValue',
        sortType: 'string'
      },
      {
        Header: t('Translation Value'),
        accessor: 'uiresDeafaultValue',
        sortType: 'string'
      },
      {
        Header: t('Translation Value'),
        accessor: 'uiresValue',
        editable: true,
        EditCell: ({ cell, onCellValueChange }) => {
          const { updatedValue } = cell.state;
          const handleChange = (e) => onCellValueChange(e.target.value);
          return <input value={updatedValue} onChange={handleChange} className="form-control" />;
        }
      },
      {
        Header: '',
        accessor: 'actions',
        actionColumn: true,
        disableGlobalFilter: false,
        disableSortBy: true,
        EditCell: (props) => {
          const { cancelEditRow, saveRow } = props;
          return (
            <div className="action-buttons">
              <Button size="sm" className="mr-1" variant="outline-secondary" onClick={saveRow}>
                <IoSaveOutline />
              </Button>
              <Button size="sm" variant="outline-secondary" onClick={cancelEditRow}>
                <IoCloseOutline />
              </Button>
            </div>
          );
        },
        Cell: (props) => {
          const { editRow } = props;
          return (
            <div className="d-flex action-buttons">
              <Button size="sm" className="mr-1" variant="outline-secondary" onClick={editRow}>
                <AiOutlineEdit />
              </Button>
            </div>
          );
        }
      }
    ];
  }, [t]);

  const transBundlesOpt = translationBundles.map((b) => ({ value: b.uiresbndlId, label: b.uiresbndlValue }));
  const transGroupsOpt = translationGroups.map((g) => ({ value: g.uiresgrpId, label: g.uiresgrpValue }));

  const openCreateModal = () => setShowCreateModal(true);
  const closeCreateModal = () => {
    setShowCreateModal(false);
    setNewTranslationName(null);
  };
  const handleCreateTranslation = async () => {
    setTransationCreateState(true);
    try {
      const data = { uiresbndlValue: newTranslationName };
      const resultAction = await dispatch(createTranslationBundle({ data, orgId }));
      unwrapResult(resultAction);
      closeCreateModal();
      setNewTranslationName(null);
    } catch (error) {
    } finally {
      setSelectedTransBundleId(null);
      setSelectedTransGroupId(null);
      setTransationCreateState(false);
    }
  };
  const handleChangeTransBundle = (e) => {
    setSelectedTransBundleId(e.value);
    dispatch(
      getTranslationValuesByGroup({
        translationBundleId: e.value,
        groupId: selectedTransGroupId ? selectedTransGroupId : transGroupsOpt[0].value,
        orgId
      })
    );
  };
  const handleChangeTransGroup = (e) => {
    setSelectedTransGroupId(e.value);
    dispatch(
      getTranslationValuesByGroup({
        translationBundleId: selectedTransBundleId ? selectedTransBundleId : transBundlesOpt[0].value,
        groupId: e.value,
        orgId
      })
    );
  };

  const handleEditTransValue = async (edited, original) => {
    if (original.uiresId !== null) {
      const data = {
        orgId,
        translationValueId: original.uiresId,
        translationBundleId: selectedTransBundleId ? selectedTransBundleId : transBundlesOpt[0].value,
        groupId: selectedTransGroupId ? selectedTransGroupId : transGroupsOpt[0].value,
        data: {
          uiresValue: edited.uiresValue
        }
      };
      await dispatch(updateTranslationValue(data));
    } else {
      const data = {
        orgId,
        groupId: selectedTransGroupId ? selectedTransGroupId : transGroupsOpt[0].value,
        data: {
          uiresBundleId: selectedTransBundleId ? selectedTransBundleId : transBundlesOpt[0].value,
          uiresKeyId: original.uireskeyId,
          uiresValue: edited.uiresValue
        }
      };
      await dispatch(createTranslationValue(data));
    }
  };

  useEffect(() => {
    if (translationStatus === LoadingState.idle) {
      dispatch(getTranslationBundles({ orgId }));
    }
  }, [dispatch, translationStatus, orgId]);

  return (
    <Container fluid>
      <Row>
        <Col>
          <PageHeader title={t('Manage Translations')} />
        </Col>
      </Row>
      {translationStatus === LoadingState.loading && <Spinner animation="grow" />}
      {translationStatus === LoadingState.succeeded && (
        <Row className="mb-3">
          <Col md={3}>
            <div className="form-group flex-column">
              <span className="form-label">{t('Language')}</span>
              <Select
                value={
                  selectedTransBundleId
                    ? transBundlesOpt.filter((b) => b.value === selectedTransBundleId)
                    : transBundlesOpt[0]
                }
                options={transBundlesOpt}
                onChange={handleChangeTransBundle}
                placeholder="Select Translation"
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="form-group">
              <span className="form-label">{t('Group')}</span>
              <Select
                value={
                  selectedTransGroupId
                    ? transGroupsOpt.filter((g) => g.value === selectedTransGroupId)
                    : transGroupsOpt[0]
                }
                options={transGroupsOpt}
                onChange={handleChangeTransGroup}
                placeholder="Select Translation Group"
              />
            </div>
          </Col>
          <Col className="d-flex justify-content-end align-items-center mr-2">
            <Button onClick={openCreateModal}>+ {t("Create Translation")}</Button>
          </Col>
        </Row>
      )}
      {(translationStatus === LoadingState.succeeded && translationValuesState) === LoadingState.loading && (
        <Spinner animation="grow" />
      )}
      {translationStatus === LoadingState.succeeded && translationValuesState === LoadingState.succeeded && (
        <DataTable
          columns={columns}
          data={translationValues}
          onEditSubmit={handleEditTransValue}
          controlledPageIndex={controlledPageIndex}
          setControlledPage={setControlledPage}
        />
      )}
      <Modal show={showCreateModal} onHide={closeCreateModal} className="sisde-modal">
        <Modal.Header>
          <Modal.Title>{t("New Translation")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="custom-form-control">
              <Form.Label>{t("Translation Name")} *</Form.Label>
              <Form.Control value={newTranslationName} onChange={(e) => setNewTranslationName(e.target.value)} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeCreateModal}>
            {t('Cancel')}
          </Button>
          <Button
            variant="primary"
            onClick={handleCreateTranslation}
            disabled={!newTranslationName || transationCreateState}
          >
            {t('Create')}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default ManageTranslations;
