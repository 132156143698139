import React, { useEffect, useState, useMemo } from 'react';
import { Button, Col, Container, Row, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { LoadingState } from '../../utilities/constants';
import PageHeader from '../../components/PageHeader';
import TreeViewModal from '../../components/TreeViewModal';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  fetchEmissionProfilesTree,
  removeEmissionProfileCategory,
  selectEmissionProfileCategoryById
} from './emissionProfileSlice';
import Form from './form/EmissionProfileCategoryForm';
import NodeIcon from '../../components/NodeIcon';
import { recursiveMap } from '../../utilities/treeHelpers';
import { IoRepeatOutline } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { updateEmissionProfileCategoryApi } from './emissionProfileApi';
import { toast } from 'react-toastify';
import { setLeavePrompt } from '../manage-users/usersSlice';
import LoadingButton from '../../components/LoadingButton';

const EditEmissionProfileCategory = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { emissionProfileCategoryId } = useParams();

  const [showEmissionProfileSelector, setShowEmissionProfileSelector] = useState(false);
  const [editEmissionProfileState, setEditEmissionProfileState] = useState(false);
  const [removeEmissionProfileState, setRemoveEmissionProfileState] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isChangeEPSMode, setIsChangeEPSMode] = useState(false);

  const emissionProfilesStatus = useSelector((state) => state.emissionProfiles.status);
  const emissionProfileTree = useSelector((state) => state.emissionProfiles.tree);
  const emissionProfileCategory = useSelector((state) =>
    selectEmissionProfileCategoryById(state, emissionProfileCategoryId)
  );
  const orgId = useSelector((state) => state.users.user.usrOrgId);

  const closeModal = () => setShowEmissionProfileSelector(false);
  const openModal = (isChangeEps = false) => {
    setIsChangeEPSMode(isChangeEps === true);
    setShowEmissionProfileSelector(true);
  }

  const isChangedCategory = () =>
    selectedCategory && Number(emissionProfileCategory?.eprflcatParentId) !== Number(selectedCategory?.eprflcatId);
  const handleEditEmissionProfileCategory = async (data) => {
    try {
      if (isChangedCategory()) {
        data.parentId = selectedCategory.eprflcatId;
      }
      setEditEmissionProfileState(true);
      await updateEmissionProfileCategoryApi(orgId, emissionProfileCategory.eprflcatId, data);
      toast.success('Category updated successfully.');
      dispatch(fetchEmissionProfilesTree({ orgId }));
    } catch (error) {
      toast.error(error.message);
      throw error;
    } finally {
      setEditEmissionProfileState(false);
    }
  };

  const handleRemoveEmissionProfileCategory = async () => {
    try {
      dispatch(setLeavePrompt(false));
      setRemoveEmissionProfileState(true);
      const resultAction = await dispatch(removeEmissionProfileCategory({ orgId, emissionProfileCategoryId }));
      unwrapResult(resultAction);
      history.push('/admin/emission-profile');
    } catch (error) {
      setRemoveEmissionProfileState(false);
    }
  };

  const transformedTree = useMemo(() => recursiveMap(emissionProfileTree ?? {}, (n, parentNode) => {
    if (n.eprflId) {
      return {
        weight: n.weight,
        value: n.eprflId,
        labelRaw: n.eprflName,
        label: <span>{n.eprflName}</span>,
        isActive: false
      };
    }
    if (n.eprflcatId && Number(n.eprflcatId) === Number(emissionProfileCategoryId)) {
      if (!selectedCategory || selectedCategory.emissionProfileCategoryId !== emissionProfileCategoryId) {
        setSelectedCategory({ ...parentNode, emissionProfileCategoryId });
      }
      if (isChangeEPSMode) {
        return {
          weight: n.weight,
          value: n.eprflcatId,
          labelRaw: n.eprflcatName,
          label: <span>{n.eprflcatName}</span>,
          isActive: false,
          isIgnoreItem: true,
        };
      }
    }
    if (n.eprflcatId) {
      const checkIsAcitve = () => isChangeEPSMode && Number(n.eprflcatId) === Number(selectedCategory?.eprflcatId);
      const getLabelNode = () => {
        if (checkIsAcitve()) {
          return (
            <span>
              <NodeIcon node={n} />
              {n.eprflcatName}
            </span>
          );
        }
        if (isChangeEPSMode) {
          const changeEPSCategoryConfirmHandler = (node) => {
            closeModal();
            setSelectedCategory({ ...node, emissionProfileCategoryId });
          };
          return (
            <span className="pointer" onClick={() => changeEPSCategoryConfirmHandler(n)}>
              <NodeIcon node={n} />
              {n.eprflcatName}
            </span>
          );
        }
        return (
          <Link to={`/admin/emission-profile/category/${n.eprflcatId}/edit`}>
            <NodeIcon node={n} />
            {n.eprflcatName}
          </Link>
        );
      };
      return {
        weight: n.weight,
        value: n.eprflcatId,
        labelRaw: n.eprflcatName,
        label: getLabelNode,
        isActive: checkIsAcitve,
      };
    }
    if (n.eprflsrcId && !isChangeEPSMode) {
      return {
        weight: n.weight,
        value: n.eprflsrcId,
        labelRaw: n.eprflsrcName,
        label: (
          <Link to={`/admin/emission-profile/source/${n.eprflsrcId}/edit`}>
            <NodeIcon node={n} />
            {n.eprflsrcName}
          </Link>
        ),
        isActive: false,
        valueId: n.eprflsrcAssignedId
      };
    }
  }), [emissionProfileTree, isChangeEPSMode, selectedCategory, emissionProfileCategoryId]);
  transformedTree.isRoot = true;
  useEffect(() => {
    closeModal();
    if (emissionProfilesStatus === LoadingState.idle) {
      dispatch(fetchEmissionProfilesTree({ orgId }));
    }
  }, [orgId, emissionProfileCategoryId, emissionProfilesStatus, dispatch]);

  const isAllowDeleteCategory = () => {
    let parentId = emissionProfileCategory ? emissionProfileCategory.eprflcatParentId : 0;
    if (_.isNull(parentId)) {
      parentId = emissionProfileCategory.eprflcatEprflId;
    }
    return parentId && emissionProfileTree ? parentId !== emissionProfileTree.eprflId : false;
  };

  const renderActionButtons = ({ isValid, isDirty }) => {
    return (
      <>
        <Button type="submit" className="mt-3 mb-5" disabled={editEmissionProfileState || !isValid || !(isChangedCategory() || isDirty)}>
          {t('Save')}
        </Button>
        {isAllowDeleteCategory() ? (<Button
          className="ml-4 mt-3 mb-5"
          variant="secondary"
          disabled={removeEmissionProfileState}
          onClick={handleRemoveEmissionProfileCategory}
        >
          {t('Delete')}
        </Button>) : ''}
        <LoadingButton isLoading={editEmissionProfileState || removeEmissionProfileState} className="ml-4 mt-3 mb-5" />
      </>
    );
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <PageHeader
            title={t('Manage Emission Profile')}
            actions={
              <Button
                className="mr-1 d-flex flex-row justify-content-between align-items-center"
                variant="secondary"
                onClick={openModal}
              >
                <div className="mr-1">
                  <IoRepeatOutline fontSize={17} />
                </div>
                <div>{t('Select Emission Profile')}</div>
              </Button>
            }
          />
        </Col>
      </Row>
      {emissionProfilesStatus === LoadingState.loading && <Spinner animation="grow" />}
      {emissionProfilesStatus === LoadingState.succeeded && emissionProfileCategory && (
        <>
          <Row>
            <Col>
              <PageHeader.Secondary
                title={emissionProfileCategory.eprflcatName}
                actions={
                  <>
                    <Button
                      size="sm"
                      to={`/admin/emission-profile/category/${emissionProfileCategory.eprflcatId}/new`}
                      as={Link}
                      className="mr-1"
                      variant="outline-secondary"
                    >
                      {t('Add Category')}
                    </Button>
                    <Button
                      size="sm"
                      to={`/admin/emission-profile/category/${emissionProfileCategoryId}/source/new`}
                      as={Link}
                      className="mr-1"
                      variant="outline-secondary"
                    >
                      {t('Add Emission Source')}
                    </Button>
                  </>
                }
              />
            </Col>
          </Row>
          <Row className="my-2">
            <Col>
              <div className="form-divider">
                <span>{t('Edit Category')}</span>
                <div></div>
              </div>
              <Row>
                <Col md={8}>
                  {emissionProfileCategory?.eprflcatParentId &&
                    <Row>
                      <Col>
                        <div className="custom-form-control form-group">
                          <label className="form-label">{t('Parent Category')}</label>
                          <div className="mr-1 d-flex flex-row">
                            <select className="form-control" disabled>
                              <option value="">{selectedCategory ? selectedCategory.eprflcatName : t("Loading ...")}</option>
                            </select>
                            <Button className="ml-1" onClick={() => openModal(true)} variant="outline-secondary">
                              <IoRepeatOutline fontSize={17} />
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  }
                  <Form
                    isEditing={true}
                    key={`key-${emissionProfileCategory?.eprflcatId}`}
                    initialValues={emissionProfileCategory}
                    onSubmit={handleEditEmissionProfileCategory}
                    actionButtons={renderActionButtons}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <TreeViewModal
            tree={transformedTree}
            closeModal={closeModal}
            show={showEmissionProfileSelector}
            title={t('Select Emission Profile')}
          />
        </>
      )}
    </Container>
  );
};

export default EditEmissionProfileCategory;
