import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router';
import PageHeader from '../../components/PageHeader';
import { LoadingState } from '../../utilities/constants';
import OrganizationForm from './OrganizationForm';
import { createOrganizationApi } from './OrganizationApi';
import { toast } from 'react-toastify';

const NewOrganization = () => {
  const [saveState, setSaveState] = useState(LoadingState.idle);
  const history = useHistory();
  const isBusy = saveState === LoadingState.loading;
  //const orgId = useSelector((state) => state.users.user.usrOrgId);

  const handleCreateOrganization = async (data) => {
    try {
      setSaveState(LoadingState.loading);
      await createOrganizationApi(data);
      toast.success('New Organization is created');
      history.push('/rootadmin/setup/organization');
    } catch(e) {
      setSaveState(LoadingState.idle);
      toast.error(e.message);
      throw e;
    }
  };

  const handleCancel = () => {
    history.push('/rootadmin/setup/organization');
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <PageHeader title="Create Organization" />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={8}>
          <OrganizationForm onSubmit={handleCreateOrganization} buttonText="Create" disabled={isBusy} onCancel={handleCancel} />
        </Col>
      </Row>
    </Container>
  );
};

export default NewOrganization;
