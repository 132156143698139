import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { LoadingState } from '../../utilities/constants';
import { createBusinessMeasureApi, editBusinessMeasureApi, fetchBusinessMeasuresApi } from './businessMeasuresApi';

export const fetchBusinessMeasures = createAsyncThunk('businessMeasures/fetchBusinessMeasures', async (payload) => {
  try {
    const response = await fetchBusinessMeasuresApi(payload.orgId);
    return response;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const createBusinessMeasure = createAsyncThunk(
  'businessMeasures/createBusinessMeasure',
  async (payload, { dispatch }) => {
    try {
      const { orgId , data } = payload;
      await createBusinessMeasureApi(orgId, data);
      dispatch(fetchBusinessMeasures({ orgId }));
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  }
);

export const editBusinessMeasure = createAsyncThunk(
  'businessMeasures/editBusinessMeasure',
  async (payload) => {
    try {
      const businessMeasure = await editBusinessMeasureApi(payload.orgId, payload.bsnsMeasureId, payload.data);
      return businessMeasure;
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  }
);

const businessMeasuresSlice = createSlice({
  name: 'businessMeasures',
  initialState: {
    businessMeasures: [],
    status: LoadingState.idle,
    error: null
  },
  reducers: {},
  extraReducers: {
    [fetchBusinessMeasures.pending]: (state) => {
      state.status = LoadingState.loading;
    },
    [fetchBusinessMeasures.fulfilled]: (state, action) => {
      state.status = LoadingState.succeeded;
      state.businessMeasures = action.payload;
    },
    [fetchBusinessMeasures.rejected]: (state, action) => {
      state.status = LoadingState.failed;
      state.error = action.error.message;
    },
    [editBusinessMeasure.fulfilled]: (state, action) => {
      const existingIndex = state.businessMeasures.findIndex((i) => i.bsnsmsrtypId === action.payload.bsnsmsrtypId);
      if (existingIndex >= 0) state.businessMeasures[existingIndex] = action.payload;
    }
  }
});

export const selectBsnsMeasureById = (state, businessMeasureId) => {
  if (!state.businessMeasures.businessMeasures?.length === 0) return null;
  const parsedId = parseInt(businessMeasureId);
  const found = state.businessMeasures.businessMeasures.find((a) => a.bsnsmsrtypId === parsedId);
  return found ?? null;
};

export default businessMeasuresSlice.reducer;
