import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { FiChevronRight, FiChevronDown } from 'react-icons/fi';
import { HiOutlineMinusSm } from 'react-icons/hi';
import _ from 'lodash';
import { recursiveTreeFilter } from '../utilities/treeHelpers';
import { Form, InputGroup } from 'react-bootstrap';
import { IoSearchOutline } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';

const TreeItem = ({
  isRoot,
  node,
  treeState,
  setTreeState,
  level,
  initExpandLevel,
}) => {
  const { isActive = false } = node;
  const { isSearching, payload, action } = treeState;
  const defaultExpand = _.has(payload, node.value)
    ? payload[node.value]
    : level <= initExpandLevel;
  const [isExpanded, setIsExpanded] = useState(isSearching || defaultExpand);
  const hasChildren = node.children && node.children.length > 0;
  useEffect(() => {
    if (isSearching) {
      if (!isExpanded) {
        setIsExpanded(true);
      }
    } else {
      if (action === 'collapse' && level > initExpandLevel + 1 && isExpanded) {
        setIsExpanded(false);
      }
      if (action === 'expand' && !isExpanded) {
        setIsExpanded(true);
      }
    }
  }, [
    setIsExpanded,
    action,
    level,
    initExpandLevel,
    isExpanded,
    isSearching
  ]);
  const rootClasses = classnames('tree-item', {
    'ml-3': !isRoot,
    expanded: isRoot || level === 0 || isExpanded,
  });
  const activated = _.isFunction(isActive) ? isActive() : isActive;
  const labelClasses = classnames('tree-item-label p-1', {
    active: !isRoot && activated,
    'root-node pb-2': isRoot
  });
  const toggleExpand = () => {
    setTreeState({
      payload: { ...payload, [node.value]: !isExpanded },
      action: null,
      isSearching: false,
    });
    setIsExpanded(!isExpanded);
  };
  const icon = isRoot ? null : !hasChildren ? (
    <HiOutlineMinusSm className="minus-icon" />

  ) : isExpanded ? (
    <FiChevronDown onClick={toggleExpand} />
  ) : (
    <FiChevronRight onClick={toggleExpand} />
  );
  if (node.isIgnoreItem) {
    return null;
  }
  level++;
  return (
    <div className={rootClasses}>
      <div className={labelClasses}>
        {icon && <span className="tree-item-icon mr-1">{icon}</span>}
        {_.isFunction(node.label) ? node.label() : node.label}
      </div>
      <div className="tree-children">
        {node.children && node.children.length > 0 && node.children.map((childNode, idx) =>
          !_.isEmpty(childNode)
            ? <TreeItem
              level={level}
              initExpandLevel={initExpandLevel}
              key={`key-${idx}`}
              node={childNode}
              treeState={treeState}
              setTreeState={setTreeState}
            />
            : null)}
      </div>
    </div>
  );
};

const TreeView = ({
  tree,
  enableSearch = false,
  searchFunction = null,
  initExpandLevel = 1,
  ...props
}) => {
  const { t } = useTranslation();
  let [treeState, setTreeState] = useState({
    isSearching: false,
    action: null,
    payload: {}
  });
  if (props.setTreeState) {
    treeState = props.treeState;
    setTreeState = props.setTreeState;
  }
  const [searchText, setSearchText] = useState(null);
  const onSearchHandler = (value) => {
    setSearchText(value);
    const { action, payload } = treeState;
    setTreeState({
      payload,
      action: !value ? 'collapse' : action,
      isSearching: !!value && value.length > 1,
    });
  };
  const setTreeStateHandler = (action) => {
    const { payload, isSearching } = treeState;
    recursiveTreeFilter(tree, ((node) => {
      if (action === 'expand') {
        payload[node.value] = true;
      } else {
        delete payload[node.value];
      }
    }));
    setTreeState({ action, payload, isSearching });
  };
  let filteredTree = tree;
  if (enableSearch) {
    if (searchFunction === null) throw new Error(t('Search function not set'));
    filteredTree = recursiveTreeFilter(tree, (node) => (searchText ? searchFunction(node, searchText) : node));
  }
  return (
    <div className="tree-view">
      {enableSearch && (
        <Form.Group className="search-box">
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>
                {' '}
                <IoSearchOutline />{' '}
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              className="custom-form-control search-input"
              type="text"
              value={searchText || ''}
              onChange={(e) => onSearchHandler(e.target.value)}
              placeholder={t("Search")}
            />
          </InputGroup>
          <div className="tree-expand-action">
            <span onClick={() => setTreeStateHandler('expand')}>{t('Expand All')}</span>
            <strong>/</strong>
            <span onClick={() => setTreeStateHandler('collapse')}>{t('Collapse All')}</span>
          </div>
        </Form.Group>
      )}
      {filteredTree === null && <p>{t('Not Found')}</p>}
      {filteredTree && <TreeItem
        isRoot
        level={0}
        initExpandLevel={initExpandLevel}
        node={filteredTree}
        treeState={treeState}
        setTreeState={setTreeState}
      />}
    </div>
  );
};

export default TreeView;
