import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import PageHeader from '../../components/PageHeader';
import { LoadingState } from '../../utilities/constants';
import AccountForm from './AccountForm';
import { fetchAccounts, selectAccountById } from './accountsSlice';
import AssignEntities from './AssignedEntities';
import AssignedUsers from './AssignedUsers';
import { editAccountApi } from './accountsApi';
import { toast } from 'react-toastify';

const EditAccount = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { accountId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const account = useSelector((state) => selectAccountById(state, accountId));
  const accountState = useSelector((state) => state.accounts.status);
  const orgId = useSelector((state) => state.users.user.usrOrgId);

  const handleEditAccount = async (data) => {
    try {
      setIsLoading(true);
      await editAccountApi(orgId, accountId, data);
      toast.success('Account updated successfuly');
      history.push('/admin/users/accounts');
    } catch (error) {
      setIsLoading(false);
      toast.error(error.message);
      throw error;
    }
  };

  useEffect(() => {
    if (accountState === LoadingState.idle) {
      dispatch(fetchAccounts({ orgId }));
    }
  }, [orgId, accountState, dispatch]);

  return (
    <Container fluid>
      <Row>
        <Col>
          <PageHeader title="Edit Account" enableBackButton />
        </Col>
      </Row>
      <Row>
        <Col>
          {accountState === LoadingState.succeeded && account && (
            <Tabs defaultActiveKey="details" id={`${account.acntId}-tabs`} className="custom-tab-bar">
              <Tab eventKey="details" title="Account Details" className="custom-tab-content">
                <Row className="my-2">
                  <Col md={8}>
                    <AccountForm
                      onSubmit={handleEditAccount}
                      buttonText="Save"
                      initialValues={account}
                      isLoading={isLoading}
                      isEditing
                    />
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey="assignUsers" title="Assign Users" className="custom-tab-content">
                <Row className="my-2">
                  <Col md={12} lg={10}>
                    <AssignedUsers accountId={account.acntId} />
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey="assignEntities" title="Assign Entities" className="custom-tab-content">
                <Row className="my-2">
                  <Col md={12} lg={10}>
                    <AssignEntities accountId={account.acntId} />
                  </Col>
                </Row>
              </Tab>
            </Tabs>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default EditAccount;
