import * as yup from 'yup';

const emptyStringToNull = (value, originalValue) => {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null;
  }
  return value;
};

export const createEntityValidateSchema = (t) => {
  const getAllocationValidate = (isCallback) => {
    const validatePercent = () => yup.number()
      .moreThan(0, t('Must be greater than 0'))
      .max(100, t('Must be less than or equal to 100'));
    return yup.number()
      .when(['divAllocEnabled', 'divAllocationRules'], {
        is: (v1, v2) => isCallback(v1, v2),
        then: validatePercent().typeError(t('Must be a valid number')).required(t('Field is required')),
        otherwise: validatePercent().transform(emptyStringToNull).nullable(),
      });
  };
  return yup.object().shape({
    divName: yup.string().max(128).required(t('Entity name is required')),
    divAssignedId: yup
      .string()
      .matches(/^([A-Za-z0-9-_.\s]){1,15}$/, {
        message: t('Id may contain only characters, numbers, hyphen, space, dot and less than 15 characters')
      })
      .required(t('Entity ID is required')),
    divLatitude: yup
      .number()
      .min(-90, t('Must be greater than -90'))
      .max(90, t('Must be less than 90'))
      .typeError(t('Must be a valid number between -90 to 90'))
      .transform(emptyStringToNull)
      .nullable(),
    divLongitude: yup
      .number()
      .min(-180, t('Must be greater than -180'))
      .max(180, t('Must be less than 180'))
      .typeError(t('Must be a valid number between -180 to 180'))
      .transform(emptyStringToNull)
      .nullable(),
    divZip: yup.string().typeError(t('Must be a valid postcode or zip')).notRequired().transform(emptyStringToNull).nullable(),
    divEquity: getAllocationValidate((v1, v2) => v1 === 'Y' && v2 !== 'divOperationControl'),
    divOperationControl: getAllocationValidate((v1, v2) => v1 === 'Y' && v2 === 'divOperationControl'),
    divAllocStartDate: yup.string()
      .when(['divAllocEnabled'], {
        is: (v1) => v1 === 'Y',
        then: yup.string().typeError(t('This field is required')),
        otherwise: yup.string().transform(emptyStringToNull).nullable(),
      }),
    divDesc: yup.string().nullable().notRequired().max(255, t('Description cannot be exceed more than 255 Characters'))
  });
};
