import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router';
import PageHeader from '../../components/PageHeader';
import { LoadingState } from '../../utilities/constants';
import WhitelabelForm from './WhitelabelForm';
import { toast } from 'react-toastify';
import { createWhitelabelApi } from './WhitelabelApi';

const NewWhitelabel = () => {
  const [saveState, setSaveState] = useState(LoadingState.idle);
  const history = useHistory();
  const isBusy = saveState === LoadingState.loading;
  //const orgId = useSelector((state) => state.users.user.usrOrgId);

  const handleCreateWhitelabel = async (data) => {
    try {
      setSaveState(LoadingState.loading);
      await createWhitelabelApi(data);
      toast.success('New Whitelabel is created');
      history.push('/rootadmin/setup/whitelabel');
    } catch (error) {
      toast.error(error.message);
      setSaveState(LoadingState.idle);
      throw error;
    }
  };

  const handleCancel = () => {
    history.push('/rootadmin/setup/whitelabel');
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <PageHeader title="Create Whitelabel" />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={8}>
          <WhitelabelForm onSubmit={handleCreateWhitelabel} buttonText="Create" disabled={isBusy} onCancel={handleCancel} />
        </Col>
      </Row>
    </Container>
  );
};

export default NewWhitelabel;
