import React, { useEffect, useMemo, useState } from 'react';
import { Spinner, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import ReactDatePicker from 'react-datepicker';
import { datePickerValue, formatDate, parseUserInfo } from '../../utilities/common';
import * as yup from 'yup';

import DataTable, { getTargetUpdateValue, isEnableUpdateBtn } from '../../components/DataTable';
import AddDataControl from './AddDataControl';
import { fetchDataControls, createDataControl, editDataControl, removeDataControl } from './dataControlSlice';
import { LoadingState } from '../../utilities/constants';

import { AiOutlineEdit } from 'react-icons/ai';
import { IoTrashOutline } from 'react-icons/io5';
import { IoCloseOutline } from 'react-icons/io5';
import { IoSaveOutline } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';

const ManageDataControl = ({ entityId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const dataControlsStatus = useSelector((state) => state.dataControls.status);
  const dataControls = useSelector((state) => state.dataControls.dataControls);
  const { orgId, usrDateFormat } = parseUserInfo(useSelector((state) => state.users.user));
  const loggedUserStatus = useSelector((state) => state.users.loggedUserStatus);

  const schema = yup.object().shape({
    datactrlStartDate: yup.date().typeError('Invalid Start Date Value').required("Start Date Can't Be Empty"),
    datactrlEndDate: yup
      .date()
      .typeError('Invalid Start End Value')
      .min(yup.ref('datactrlStartDate'), "End Date Can't Be Before Start Date")
      .required("End Date Can't Be Empty")
  });

  const validateSchema = async (data) => {
    try {
      await schema.validate({
        datactrlStartDate: data.datactrlStartDate,
        datactrlEndDate: data.datactrlEndDate
      });
      return true;
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  };

  const addDataControl = async (startDate, endDate) => {
    try {
      const data = {
        datactrlStartDate: startDate,
        datactrlEndDate: endDate,
      };
      const validated = await validateSchema(data);
      if (validated) {
        setIsLoading(true);
        const resultAction = await dispatch(createDataControl({ orgId, entityId, data }));
        unwrapResult(resultAction);
        dispatch(fetchDataControls({ orgId, entityId }));
      }
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };
  const handleDeleteDataControl = async ({ datactrlId }) => {
    try {
      setIsLoading(true);
      const resultAction = await dispatch(removeDataControl({ orgId, entityId, dataControlId: datactrlId }));
      unwrapResult(resultAction);
      dispatch(fetchDataControls({ orgId, entityId }));
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };
  const handleEditDataControl = async (edited, original) => {
    try {
      const validated = await validateSchema(edited);
      if (validated) {
        setIsLoading(true);
        const resultAction = await dispatch(
          editDataControl({ orgId, entityId, dataControlId: original.datactrlId, data: edited })
        );
        unwrapResult(resultAction);
        dispatch(fetchDataControls({ orgId, entityId }));
      }
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: t('Start Date'),
        accessor: 'datactrlStartDate',
        Cell: ({ row }) => formatDate(row.original?.datactrlStartDate),
        editable: true,
        EditCell: ({ cell, onCellValueChange }) => {
          const { updatedValue } = cell.state;
          return (
            <ReactDatePicker
              dateFormat={usrDateFormat}
              isClearable
              className="form-control"
              maxDate={getTargetUpdateValue(cell, 'datactrlEndDate', (val) => new Date(val))}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              {...datePickerValue(updatedValue, onCellValueChange)}
            />
          );
        }
      },
      {
        Header: t('End Date'),
        accessor: 'datactrlEndDate',
        editable: true,
        Cell: ({ row }) => formatDate(row.original?.datactrlEndDate),
        EditCell: ({ cell, onCellValueChange }) => {
          const { updatedValue } = cell.state;
          return (
            <ReactDatePicker
              isClearable
              className="form-control"
              minDate={getTargetUpdateValue(cell, 'datactrlStartDate', (val) => new Date(val))}
              dateFormat={usrDateFormat}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              {...datePickerValue(updatedValue, onCellValueChange)}
            />
          );
        }
      },
      {
        Header: '',
        accessor: 'actions',
        actionColumn: true,
        disableGlobalFilter: false,
        disableSortBy: true,
        EditCell: ({ cancelEditRow, saveRow, cell }) => {
          return (
            <div className="d-flex action-buttons">
              <Button
                disabled={!isEnableUpdateBtn(cell, ['datactrlEndDate', 'datactrlStartDate'])}
                size="sm"
                className="mr-1"
                variant="outline-secondary"
                onClick={saveRow}
              >
                <IoSaveOutline />
              </Button>
              <Button size="sm" variant="outline-secondary" onClick={cancelEditRow}>
                <IoCloseOutline />
              </Button>
            </div>
          );
        },
        Cell: (props) => {
          const { deleteRow, editRow } = props;
          return (
            <div className="d-flex action-buttons">
              <Button size="sm" className="mr-1" variant="outline-secondary" onClick={editRow}>
                <AiOutlineEdit />
              </Button>
              <Button size="sm" variant="outline-secondary" onClick={deleteRow}>
                <IoTrashOutline />
              </Button>
            </div>
          );
        }
      }
    ],
    [t, usrDateFormat]
  );

  useEffect(() => {
    if (loggedUserStatus === LoadingState.succeeded) {
      dispatch(fetchDataControls({ orgId, entityId }));
    }
  }, [orgId, loggedUserStatus, entityId, dispatch]);

  return (
    <div>
      <h5 className="my-3 text-light">{t('Data Control')}</h5>
      {dataControlsStatus === LoadingState.loading && <Spinner animation="grow" />}
      {dataControlsStatus === LoadingState.succeeded && (
        <div>
          <DataTable
            columns={columns}
            data={dataControls}
            onEditSubmit={handleEditDataControl}
            onDeleteRow={handleDeleteDataControl}
            footer={<AddDataControl onSubmit={addDataControl} isLoading={isLoading} />}
          />
        </div>
      )}
    </div>
  );
};

export default ManageDataControl;
