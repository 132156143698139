import React, { useEffect, useState, useMemo } from 'react';
import { Col, Container, Row, Spinner, Tab, Modal, Button, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { LoadingState } from '../../utilities/constants';
import PageHeader from '../../components/PageHeader';
import TreeViewModal from '../../components/TreeViewModal';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  fetchEmissionProfilesTree,
  removeEmissionProfileSource,
  selectEmissionProfileSourceById,
  fetchEmissionProfilesTreeByScope,
} from './emissionProfileSlice';
import { changeEmissionProfileCategoryApi, updateEmissionProfileSourceApi } from './emissionProfileApi'
import Form from './form/EmissionProfileSourceForm';
import NodeIcon from '../../components/NodeIcon';
import AssignEntities from './AssignEntities';
import AssignAssets from './AssignAssets';
import { recursiveMap } from '../../utilities/treeHelpers';
import { IoRepeatOutline } from 'react-icons/io5';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { setLeavePrompt } from '../manage-users/usersSlice';
import LoadingButton from '../../components/LoadingButton';

const EditEmissionProfileSource = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { emissionProfileSourceId } = useParams();

  const [showEmissionProfileSelector, setShowEmissionProfileSelector] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const [isChangeEPSMode, setIsChangeEPSMode] = useState(false);
  const [editEmissionProfileState, setEditEmissionProfileState] = useState(false);
  const [removeEmissionProfileState, setRemoveEmissionProfileState] = useState(false);
  const [changeToCategory, setChangeToCategory] = useState({});
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const emissionProfileStatus = useSelector((state) => state.emissionProfiles.status);
  const emissionProfileTree = useSelector((state) => state.emissionProfiles.tree);
  const emissionProfileTreeByScope = useSelector((state) => state.emissionProfiles.scopedTree);
  const emissionProfileTreeByScopeStatus = useSelector((state) => state.emissionProfiles.scopedTreeStatus);
  const emissionProfile = useSelector((state) => selectEmissionProfileSourceById(state, emissionProfileSourceId));
  const orgId = useSelector((state) => state.users.user.usrOrgId);

  const closeModal = () => setShowEmissionProfileSelector(false);
  const openModal = (isChangeEps = false) => {
    setIsChangeEPSMode(isChangeEps === true);
    setShowEmissionProfileSelector(true);
  }

  const closeConfirmationModal = () => setShowConfirmationModal(false);

  const handleEditEmissionProfileSource = async (data) => {
    try {
      setEditEmissionProfileState(true);
      await updateEmissionProfileSourceApi(orgId, emissionProfileSourceId, data);
      dispatch(fetchEmissionProfilesTree({ orgId }));
      toast.success('Emission Source has been saved.');
      setEditEmissionProfileState(false);
    } catch (error) {
      setEditEmissionProfileState(false);
      toast.error(error.message);
      throw error;
    }
  };

  const handleRemoveEmissionProfileSource = async () => {
    try {
      setRemoveEmissionProfileState(true);
      dispatch(setLeavePrompt(false));
      const resultAction = await dispatch(removeEmissionProfileSource({ orgId, emissionProfileSourceId }));
      unwrapResult(resultAction);
      history.push('/admin/emission-profile');
    } catch (error) {
      setRemoveEmissionProfileState(false);
    }
  };

  const onCancelHandler = (handleReset) => handleReset();
  const changeEPSCategoryHandler = async () => {
    try {
      setIsLoading(true);
      const data = { eprflsrcEprflcatId: changeToCategory.eprflcatId };
      await changeEmissionProfileCategoryApi(orgId, emissionProfileSourceId, data);
      dispatch(fetchEmissionProfilesTree({ orgId }));
      toast.success('The category has been changed');
      setShowConfirmationModal(false);
    } catch (e) {
      toast.error(e.message);
    }
    setIsLoading(false);
  };

  const renderActionButtons = ({ saveAllowed, isDirty, handleReset }) => {
    return (
      <>
        <Button type="submit" className="mt-3 mb-5" disabled={editEmissionProfileState || !saveAllowed}>
          {t('Save')}
        </Button>
        <Button
          variant="secondary"
          className="ml-4 mt-3 mb-5"
          onClick={() => onCancelHandler(handleReset)}
          disabled={!isDirty}
        >
          {t('Reset')}
        </Button>
        <Button
          className="ml-4 mt-3 mb-5"
          variant="secondary"
          onClick={handleRemoveEmissionProfileSource}
          disabled={removeEmissionProfileState}
        >
          {t('Delete')}
        </Button>
        <LoadingButton className="ml-4 mt-3 mb-5" isLoading={editEmissionProfileState || removeEmissionProfileState} />
      </>
    );
  };
  const transformedTree = useMemo(() => recursiveMap(emissionProfileTree ?? {}, (n, parentNode) => {
    if (n.eprflId) {
      return {
        weight: n.weight,
        value: n.eprflId,
        labelRaw: n.eprflName,
        label: <span>{n.eprflName}</span>,
        isActive: false
      };
    }
    if (n.eprflsrcId && Number(n.eprflsrcId) === Number(emissionProfileSourceId)) {
      setSelectedCategory(parentNode);
    }
    if (n.eprflcatId) {
      const checkIsAcitve = () => isChangeEPSMode && Number(n.eprflcatId) === Number(selectedCategory?.eprflcatId);
      const getLabelNode = () => {
        if (checkIsAcitve()) {
          return (
            <span>
              <NodeIcon node={n} />
              {n.eprflcatName}
            </span>
          );
        }
        if (isChangeEPSMode) {
          const changeEPSCategoryConfirmHandler = (node) => {
            closeModal();
            setChangeToCategory(node);
            setShowConfirmationModal(true);
          };
          return (
            <span className="pointer" onClick={() => changeEPSCategoryConfirmHandler(n)}>
              <NodeIcon node={n} />
              {n.eprflcatName}
            </span>
          );
        }
        return (
          <Link to={`/admin/emission-profile/category/${n.eprflcatId}/edit`}>
            <NodeIcon node={n} />
            {n.eprflcatName}
          </Link>
        );
      };
      return {
        weight: n.weight,
        value: n.eprflcatId,
        labelRaw: n.eprflcatName,
        label: getLabelNode,
        isActive: checkIsAcitve,
      };
    }
    if (n.eprflsrcId && !isChangeEPSMode) {
      return {
        weight: n.weight,
        value: n.eprflsrcId,
        labelRaw: n.eprflsrcName,
        label: (
          <Link to={`/admin/emission-profile/source/${n.eprflsrcId}/edit`}>
            <NodeIcon node={n} />
            {n.eprflsrcName}
          </Link>
        ),
        valueId: n.eprflsrcAssignedId,
        isActive: parseInt(emissionProfileSourceId) === n.eprflsrcId
      };
    }
  }), [emissionProfileTree, isChangeEPSMode, emissionProfileSourceId, selectedCategory?.eprflcatId]);
  transformedTree.isRoot = true;
  useEffect(() => {
    closeModal();
    if (emissionProfileStatus === LoadingState.idle) {
      dispatch(fetchEmissionProfilesTree({ orgId }));
    }
    if (emissionProfileTreeByScopeStatus === LoadingState.idle) {
      dispatch(fetchEmissionProfilesTreeByScope({ orgId }));
    }
  }, [orgId, emissionProfileSourceId, emissionProfileStatus, emissionProfileTreeByScopeStatus, dispatch]);
  return (
    <Container fluid>
      <Row>
        <Col>
          <PageHeader
            title={t('Manage Emission Profile')}
            actions={
              <Button
                className="mr-1 d-flex flex-row justify-content-between align-items-center"
                variant="secondary"
                onClick={() => openModal(false)}
              >
                <div className="mr-1">
                  <IoRepeatOutline fontSize={17} />
                </div>
                <div>{t('Select Emission Profile')}</div>
              </Button>
            }
            enableBackButton
          />
        </Col>
      </Row>
      {emissionProfileStatus === LoadingState.loading && <Spinner animation="grow" />}
      {emissionProfileStatus === LoadingState.succeeded && emissionProfile && (
        <>
          <Row>
            <Col>
              <PageHeader.Secondary title={emissionProfile ? emissionProfile.eprflsrcName : 'Loading'} />
              <Tabs defaultActiveKey="details" id={`${emissionProfile.eprflsrcId}-tabs`} className="custom-tab-bar">
                <Tab
                  eventKey="details"
                  title={t('Emission Profile Source Details')}
                  className="custom-tab-content"
                >
                  <Row className="my-2">
                    <Col md={9}>
                      <Row>
                        <Col>
                          <div className="custom-form-control form-group">
                            <label className="form-label">Emission Source Category</label>
                            <div className="mr-1 d-flex flex-row">
                              <select className="form-control" disabled>
                                <option value="">{selectedCategory ? selectedCategory.eprflcatName : "Loading ..."}</option>
                              </select>
                              <Button className="ml-1" onClick={() => openModal(true)} variant="outline-secondary">
                                <IoRepeatOutline fontSize={17} />
                              </Button>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Form
                        key={emissionProfile?.eprflsrcId}
                        isEditing
                        initialValues={emissionProfile}
                        onSubmit={handleEditEmissionProfileSource}
                        tree={emissionProfileTree}
                        scopedTree={emissionProfileTreeByScope}
                        renderActionButtons={renderActionButtons}
                      />
                    </Col>
                  </Row>
                </Tab>
                <Tab
                  eventKey="assignEntities"
                  title={t('Assign Entities')}
                  className="custom-tab-content"
                >
                  <Row className="my-2">
                    <Col md={12}>
                      <AssignEntities emissionProfileSourceId={emissionProfileSourceId} />
                    </Col>
                  </Row>
                </Tab>
                <Tab
                  eventKey="assignAssets"
                  title={t('Assign Assets')}
                  className="custom-tab-content"
                >
                  <Row className="my-2">
                    <Col md={12}>
                      <AssignAssets emissionProfileSourceId={emissionProfileSourceId} />
                    </Col>
                  </Row>
                </Tab>
              </Tabs>
            </Col>
          </Row>

          <TreeViewModal
            tree={transformedTree}
            closeModal={closeModal}
            show={showEmissionProfileSelector}
            title={t('Select Emission Profile')}
          />

          <Modal show={showConfirmationModal} onHide={closeConfirmationModal} backdrop="static">
            <Modal.Header>
              <Modal.Title>{t('Change the Emission Source Category')}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ textAlign: 'justify' }}>
              <Trans
                i18nKey="Do you want to change the category <bold>{{from}}</bold> to <bold>{{to}}</bold>"
                values={{
                  from: selectedCategory?.eprflcatName,
                  to: changeToCategory?.eprflcatName,
                }}
                components={{ bold: <strong /> }}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => {
                  closeConfirmationModal();
                }}
              >
                {t('Cancel')}
              </Button>
              <Button
                disabled={isLoading}
                onClick={changeEPSCategoryHandler}
                variant="primary"
              >
                {!isLoading ? t('Ok') : '...'}
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </Container>
  );
};

export default EditEmissionProfileSource;
