import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { LoadingState } from '../../utilities/constants';
import { fetchListCurrenciesApi } from './currencyApi';

export const fetchListCurrencies = createAsyncThunk('currencies/fetchListCurrencies', async (payload) => {
  try {
    const response = await fetchListCurrenciesApi(payload.orgId);
    return response.map(({ currencyId, currencySymbol }) =>
      ({ label: currencySymbol, value: currencyId }));
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const currencySlice = createSlice({
  name: 'currencies',
  initialState: {
    status: LoadingState.idle,
    availableCurrencies: [],
  },
  reducers: {},
  extraReducers: {
    [fetchListCurrencies.pending]: (state, action) => {
      state.status = LoadingState.loading;
    },
    [fetchListCurrencies.fulfilled]: (state, action) => {
      state.status = LoadingState.succeeded;
      state.availableCurrencies = action.payload;
    },
    [fetchListCurrencies.rejected]: (state, action) => {
      state.status = LoadingState.failed;
      state.error = action.error.message;
    },
  }
});

export default currencySlice.reducer;
