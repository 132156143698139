import React, { useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FiUpload } from 'react-icons/fi';
import PageHeader from '../../../components/PageHeader';
import UploadDataForm from './UploadDataForm';
import UploadDataTable from './UploadDataTable';

const ManageUploadData = () => {
  const { t } = useTranslation();
  const [showUploadDataModal, setShowUploadDataModal] = useState(false);

  const closeModal = () => setShowUploadDataModal(false);
  const openModal = () => setShowUploadDataModal(true);
  const [uploadedReload, setUploadedReload] = useState(0);

  const onUploadHandler = () => setUploadedReload(5);

  return (
    <Container fluid>
      <Row>
        <Col>
          <PageHeader
            title={t('Emission Data')}
            actions={
              <Button variant="secondary" onClick={openModal}>
                <FiUpload className="mr-1" />
                {t('Upload Emission Data')}
              </Button>
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <UploadDataTable
            uploadedReload={uploadedReload}
            setUploadedReload={setUploadedReload}
          />
        </Col>
      </Row>
      <UploadDataForm
        showModal={showUploadDataModal}
        closeModal={closeModal}
        onUploadHandler={onUploadHandler}
      />
    </Container>
  );
};

export default ManageUploadData;
