import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { fetchCarbonFootprintByEntity } from '../reportsSlice';
import ReportContainer from '../ReportContainer';
import { getDisplayColumns } from '../configurations/Configuration';
import {
  parseFavouriteData,
  formatNumber,
  getCommonFilterPeriodRange,
  getCurrentReportPeriod,
  getReportRangePeriod,
} from '../reportHelper';
import { useTranslation } from 'react-i18next';

const CarbonFootPrintByEntity = () => {
  const { t } = useTranslation();
  const reportKey = 'carbon-foot-print-by-entity';
  const { filterData } = parseFavouriteData(useLocation().search);
  const userInfo = useSelector((state) => state.users.user);
  const currentReportPeriod = getCurrentReportPeriod(userInfo.usrOrgOptions);
  const reportRangePeriod = getReportRangePeriod(userInfo.usrOrgOptions, currentReportPeriod.reportYear);
  const carbonFootPrintByEntity = useSelector((state) => state.reports.carbonFootPrintByEntity);
  const carbonFootPrintStatus = useSelector((state) => state.reports.carbonFootPrintStatus);
  const [displayColumns, setDisplayColumns] = useState(getDisplayColumns(
    reportKey,
    filterData?.displayColumns,
    { co2_e_t: true, per_co2_e_t: true, usage: true, unit: true },
  ));
  const getFilterPeriodRangeHandler = (data) => {
    return getCommonFilterPeriodRange(data, reportRangePeriod);
  };
  const fetchCarbonFootprintByEntityHandler = async (dispatch, payload) => {
    return dispatch(fetchCarbonFootprintByEntity(payload));
  };

  const getFavouriteFilterDataHandler = (params) => {
    return {
      ...params,
      displayColumns,
    };
  };

  const getDirectValuesRows = (rowArr) => {
    return rowArr.map((row, index) => {
      return (
        <tr key={index}>
          <td>{row.d_name}</td>
          <td>{row.eps_name}</td>
          {displayColumns?.usage ? (<td>{formatNumber(row.usage)}</td>) : null}
          {displayColumns?.unit ? (<td>{row.unit}</td>) : null}
          {displayColumns?.usage_tj ? (<td>{formatNumber(row.usage_tj)}</td>) : null}
          {displayColumns?.per_usage_tj ? (<td>{formatNumber(row.per_usage_tj)}</td>) : null}
          {displayColumns?.co2_e_t ? (<td>{formatNumber(row.co2_e_t)}</td>) : null}
          {displayColumns?.per_co2_e_t ? (<td>{formatNumber(row.per_co2_e_t)}</td>) : null}
          {displayColumns?.allocation ? (<td>{row.allocation}</td>) : null}
        </tr>
      );
    });
  };

  function getLevelColor(maxLevel, currentLevel) {
    const maxG = 72;
    const maxB = 102;
    const minG = 180;
    const minB = 220;
    const currentLevelGValueDifference = Math.floor(((minG - maxG) / maxLevel) * currentLevel);
    const currentLevelBValueDifference = Math.floor(((minB - maxG) / maxLevel) * currentLevel);
    return `#00${(maxG + currentLevelGValueDifference).toString(16)}${(maxB + currentLevelBValueDifference).toString(
      16
    )}`;
  }

  const recursiveMapChilds = (childsArr, maxLevels) => {
    if (childsArr === undefined) return null;
    return childsArr.map((child, idx) => {
      const childRowColor = getLevelColor(maxLevels, child.divLevel);
      return (
        <React.Fragment key={`cbfe-${idx}`}>
          <tr style={{ backgroundColor: childRowColor, color: '#FFFFFF' }}>
            <td>{child.divName}</td>
            <td></td>
            {displayColumns?.usage ? (<td>{formatNumber(child.summarizedUsage)}</td>) : null}
            {displayColumns?.unit ? (<td></td>) : null}
            {displayColumns?.usage_tj ? (<td>{formatNumber(child.summarizedUsage_tj)}</td>) : null}
            {displayColumns?.per_usage_tj ? (<td>{formatNumber(child.summarizedPer_usage_tj)}</td>) : null}
            {displayColumns?.co2_e_t ? (<td>{formatNumber(child.summarizedCo2_e_t)}</td>) : null}
            {displayColumns?.per_co2_e_t ? (<td>{formatNumber(child.summarizedPer_co2_e_t)}</td>) : null}
            {displayColumns?.allocation ? (<td>{child.allocation}</td>) : null}
          </tr>
          {child.directValues ? getDirectValuesRows(child.directValues) : null}
          {child.children ? recursiveMapChilds(child.children, maxLevels) : null}
        </React.Fragment>
      );
    });
  };

  const tableRender = (tableRef) => {
    return (
      <Table bordered hover id="data-table" ref={tableRef}>
        <thead>
          <tr>
            <th>{t('Entity')}</th>
            <th>{t('Emission Source')}</th>
            {displayColumns?.usage ? (<th>{t('Usage')}</th>) : null}
            {displayColumns?.unit ? (<th>{t('Unit')}</th>) : null}
            {displayColumns?.usage_tj ? (<th>{t('Usage (TJ)')}</th>) : null}
            {displayColumns?.per_usage_tj ? (<th>{t('% Total Usage (TJ)')}</th>) : null}
            {displayColumns?.co2_e_t ? (<th>{t('CO2-e (t)')}</th>) : null}
            {displayColumns?.per_co2_e_t ? (<th>{t('% Total CO2-e (t)')} </th>) : null}
            {displayColumns?.allocation ? (<th>{t('% Allocation')} </th>) : null}
          </tr>
        </thead>
        <tbody>
          <tr style={{ backgroundColor: '#004866', color: '#FFFFFF' }}>
            <td>{carbonFootPrintByEntity.divName}</td>
            <td></td>
            {displayColumns?.usage ? (<td>{formatNumber(carbonFootPrintByEntity.summarizedUsage)}</td>) : null}
            {displayColumns?.unit ? (<td></td>) : null}
            {displayColumns?.usage_tj ? (<td>{formatNumber(carbonFootPrintByEntity.summarizedUsage_tj)}</td>) : null}
            {displayColumns?.per_usage_tj ? (<td>{formatNumber(carbonFootPrintByEntity.summarizedPer_usage_tj)}</td>) : null}
            {displayColumns?.co2_e_t ? (<td>{formatNumber(carbonFootPrintByEntity.summarizedCo2_e_t)}</td>) : null}
            {displayColumns?.per_co2_e_t ? (<td>{formatNumber(carbonFootPrintByEntity.summarizedPer_co2_e_t)}</td>) : null}
            {displayColumns?.allocation ? (<td>{carbonFootPrintByEntity.allocation}</td>) : null}
          </tr>
          {carbonFootPrintByEntity.directValues
            ? getDirectValuesRows(carbonFootPrintByEntity.directValues)
            : null}
          {carbonFootPrintByEntity.children
            ? recursiveMapChilds(carbonFootPrintByEntity.children, carbonFootPrintByEntity.maxEntityLevels)
            : null}
        </tbody>
      </Table>
    );
  };

  return (
    <ReportContainer
      isShowAllocation
      reportName={t('Carbon Footprint by Entity')}
      reportSubTitle={t('Emission Footprint Overview by Entity')}
      fetchReportData={fetchCarbonFootprintByEntityHandler}
      fetchReportStatus={carbonFootPrintStatus}
      reportData={carbonFootPrintByEntity}
      reportRender={tableRender}
      getFavouriteFilterData={getFavouriteFilterDataHandler}
      getFilterPeriodRange={getFilterPeriodRangeHandler}
      reportColumnConfig={{
        key: reportKey,
        items: [
          { value: 'co2_e_t', label: t('CO2-e (t)') },
          { value: 'per_co2_e_t', label: t('% Total CO2-e (t)') },
          { value: 'usage', label: t('Usage') },
          { value: 'unit', label: t('Unit') },
          { value: 'usage_tj', label: t('Usage (TJ)') },
          { value: 'per_usage_tj', label: t('% Total Usage (TJ)') },
          { value: 'allocation', label: t('% Allocation') }
        ],
        active: displayColumns,
        setActive: setDisplayColumns,
      }}
    />
  );
};

export default CarbonFootPrintByEntity;
