import React, { useState, useEffect, useMemo } from 'react';
import Table from 'react-bootstrap/Table';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { LoadingState } from '../../../utilities/constants';
import { fetchCarbonFootprintByScope, fetchScopes } from '../reportsSlice';
import ReportContainer from '../ReportContainer';
import ScopeTableHeader from '../configurations/ScopeTableHeader';
import {
  formatNumber,
  parseFavouriteData,
  getCommonFilterPeriodRange,
  getCurrentReportPeriod,
  getReportRangePeriod,
} from '../reportHelper';
import { useTranslation } from 'react-i18next';

const NestedRow = ({ records }) => {
  return records.map((record, idx) => {
    return (
      <tr key={`key-ntr-${idx}`}>
        <td>{record.scope}</td>
        <td>{record.eps}</td>
        <td>{formatNumber(record.usage)}</td>
        <td>{record.unit}</td>
        <td>{formatNumber(record.co2_e_t)}</td>
        <td>{formatNumber(record.per_co2_e_t)}</td>
        <td>{formatNumber(record.co2_t, '-')}</td>
        <td>{formatNumber(record.ch4_t, '-')}</td>
        <td>{formatNumber(record.n2o_t, '-')}</td>
        <td>{formatNumber(record.pfc_t, '-')}</td>
        <td>{formatNumber(record.hfc_t, '-')}</td>
        <td>{formatNumber(record.sf6_t, '-')}</td>
        <td>{formatNumber(record.nf3_t, '-')}</td>
      </tr>
    );
  });
};

const CarbonFootPrintByScope = () => {
  const { t } = useTranslation();
  const { filterData } = parseFavouriteData(useLocation().search);
  const userInfo = useSelector((state) => state.users.user);
  const currentReportPeriod = getCurrentReportPeriod(userInfo.usrOrgOptions);
  const reportRangePeriod = getReportRangePeriod(userInfo.usrOrgOptions, currentReportPeriod.reportYear);
  const carbonFootPrintByScope = useSelector((state) => state.reports.carbonFootPrintByScope);
  const carbonFootPrintStatus = useSelector((state) => state.reports.carbonFootPrintByScopeStatus);
  const scopeTree = useSelector((state) => state.reports.scopesTree);
  const scopeTreeStatus = useSelector((state) => state.reports.scopeTreeStatus);
  const [selectedScopes, setSelectedScopes] = useState(filterData && filterData.scopeList ? filterData.scopeList : []);
  const [isInit, setIsInit] = useState(false);
  const [sortInfo, setSortInfo] = useState({ name: 'total', isSortedDesc: true });

  const getFilterPeriodRangeHandler = (data) => {
    return getCommonFilterPeriodRange(data, reportRangePeriod);
  };

  const fetchCarbonFootprintByScopeHandler = async (dispatch, payload) => {
    payload.data.scopeList = selectedScopes;
    payload.data.recentTime = null;
    return await dispatch(fetchCarbonFootprintByScope(payload));
  };

  const fetchRelatedDataHandler = async (dispatch, payload) => {
    dispatch(fetchScopes(payload.orgId));
  };

  const getFavouriteFilterDataHandler = (params) => ({
    ...params,
    scopeList: selectedScopes
  });

  useEffect(() => {
    if (!isInit && !selectedScopes.length && scopeTreeStatus === LoadingState.succeeded) {
      setIsInit(true);
      setSelectedScopes(scopeTree.map(({ id }) => id));
    }
  }, [scopeTree, scopeTreeStatus, isInit, selectedScopes.length]);

  const extraConfigRender = (isFavReport) => {
    return (
      <div className="configuration-col ">
        <Form.Group className="custom-form-control">
          <Form.Label className="d-flex">{t('Scope')}</Form.Label>
          <Select
            placeholder={t('Select a scope')}
            value={scopeTree
              .filter(({ id }) => selectedScopes.includes(id))
              .map(({ id, scope }) => ({ label: scope, value: id }))}
            onChange={(val) => setSelectedScopes(val.map(({ value }) => value))}
            isMulti
            isSearchable={false}
            isDisabled={isFavReport}
            options={scopeTree.map(({ id, scope }) => ({ label: scope, value: id }))}
          />
        </Form.Group>
      </div>
    );
  };

  const sortHandler = (name) => {
    if (sortInfo.name === name) {
      setSortInfo({ name, isSortedDesc: !sortInfo.isSortedDesc });
    } else {
      setSortInfo({ name, isSortedDesc: false });
    }
  };

  const tableData = useMemo(() => {
    const findScopeById = (id) => {
      const search = scopeTree.find((item) => item.id === id);
      return search || {};
    };
    const { name, isSortedDesc } = sortInfo;
    const parentKey = 'scope';
    const childKey = name === 'scope' ? 'eps' : name;
    const data = _.orderBy(
      carbonFootPrintByScope.map((item) => ({
        ...item,
        total: Number(item.total_per_co2_e_t),
        co2e: Number(item.total_co2_e_t),
        co2_t: Number(item.total_co2_t),
        ch4_t: Number(item.total_ch4_t),
        n2o_t: Number(item.total_n2o_t),
        pfc_t: Number(item.total_pfc_t),
        hfc_t: Number(item.total_hfc_t),
        sf6_t: Number(item.total_sf6_t),
        nf3_t: Number(item.total_nf3_t),
        records: _.orderBy(
          _.map(item.records, (record) => ({
            ...record,
            total: Number(record.per_co2_e_t),
            co2e: Number(record.co2_e_t),
            usage: Number(record.usage),
            co2_t: Number(record.co2_t),
            ch4_t: Number(record.ch4_t),
            n2o_t: Number(record.n2o_t),
            pfc_t: Number(record.pfc_t),
            hfc_t: Number(record.hfc_t),
            sf6_t: Number(record.sf6_t),
            nf3_t: Number(record.nf3_t)
          })),
          childKey,
          isSortedDesc ? 'desc' : 'asc'
        ),
        scope: findScopeById(item.scope_id).scope
      })),
      parentKey,
      'asc',
    );
    if (data.length) {
      const scopeNames = data.map(item => item.scope?.split(':')[0]?.trim());
      const lastScope = scopeNames.length > 1 ? scopeNames.pop() : '';
      const message = lastScope ? 'Total {{scopes}} and {{lastScope}} Emissions (tCO2-e)' : 'Total {{scopes}} Emissions (tCO2-e)';
      const summaryData = {
        isSummary: true,
        scope: t(message, { scopes: scopeNames.join(', '), lastScope }),
        records: [],
      };
      data.forEach(({ records }) => {
        records.forEach((item) => {
          const fields = [
            'co2_e_t', 'per_co2_e_t', 'co2_t', 'ch4_t',
            'n2o_t', 'pfc_t', 'hfc_t', 'sf6_t', 'nf3_t'
          ];
          fields.forEach((key) => {
            summaryData[`total_${key}`] = (summaryData[`total_${key}`] || 0) + Number(item[key]);
          });
        });
      });
      data.unshift(summaryData);
    }
    return data;
  }, [t, sortInfo, carbonFootPrintByScope, scopeTree]);

  const tableRender = (tableRef) => {
    const headers = [
      { name: 'scope', title: t('Scope'), disabled: true },
      { name: 'eps', title: t('Emission Source') },
      { name: 'usage', title: t('Usage') },
      { name: 'unit', title: t('Units') },
      { name: 'co2e', title: t('CO2-e (t)') },
      { name: 'total', title: t('% Total CO2-e (t)') },
      { name: 'co2_t', title: t('CO2 (t)') },
      { name: 'ch4_t', title: t('CH4 (t)') },
      { name: 'n2o_t', title: t('N2O (t)') },
      { name: 'pfc_t', title: t('PFC (t)') },
      { name: 'hfc_t', title: t('HFC (t)') },
      { name: 'sf6_t', title: t('SF6 (t)') },
      { name: 'nf3_t', title: t('NF3 (t)') }
    ];
    return (
      <Table bordered hover id="data-table" ref={tableRef}>
        <thead>
          <tr>
            {headers.map((item) => (
              <ScopeTableHeader
                key={`c-${item.name}`}
                name={item.name}
                title={item.title}
                disabled={item.disabled}
                sortHandler={sortHandler}
                isSorted={sortInfo.name === item.name}
                isSortedDesc={sortInfo.isSortedDesc}
              />
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.map((cfpbs, index) => {
            return (
              <React.Fragment key={`esct-${index}`}>
                <tr style={{ backgroundColor: '#004866', color: '#FFFFFF' }}>
                  <td>{cfpbs.isSummary ? cfpbs.scope : t('SubTotal for {{scope}}', { scope: cfpbs.scope })}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>{formatNumber(cfpbs.total_co2_e_t)} </td>
                  <td>{formatNumber(cfpbs.total_per_co2_e_t)}</td>
                  <td>{formatNumber(cfpbs.total_co2_t, '-')} </td>
                  <td>{formatNumber(cfpbs.total_ch4_t, '-')}</td>
                  <td>{formatNumber(cfpbs.total_n2o_t, '-')} </td>
                  <td>{formatNumber(cfpbs.total_pfc_t, '-')} </td>
                  <td>{formatNumber(cfpbs.total_hfc_t, '-')} </td>
                  <td>{formatNumber(cfpbs.total_sf6_t, '-')} </td>
                  <td>{formatNumber(cfpbs.total_nf3_t, '-')} </td>
                </tr>
                <NestedRow records={cfpbs.records} />
              </React.Fragment>
            );
          })}
        </tbody>
      </Table>
    );
  };
  return (
    <ReportContainer
      isShowAllocation
      reportName={t('Carbon Footprint by Scope')}
      reportSubTitle={t('Emission Footprint Overview by Scope')}
      fetchReportData={fetchCarbonFootprintByScopeHandler}
      fetchReportStatus={carbonFootPrintStatus}
      reportData={tableData}
      reportRender={tableRender}
      extraConfigRender={extraConfigRender}
      fetchRelatedData={fetchRelatedDataHandler}
      getFavouriteFilterData={getFavouriteFilterDataHandler}
      getFilterPeriodRange={getFilterPeriodRangeHandler}
    />
  );
};

export default CarbonFootPrintByScope;
