import { Button, Modal } from 'react-bootstrap';
import { React } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { deleteAction } from '../reductionPlanSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export const DeleteAction = ({
  orgId,
  deleteAct,
  showDeleteWarningModal,
  closeDeleteWarningModal,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleConfirmDeleteAction = async () => {
    try {
      const resultAction = await dispatch(deleteAction({ orgId, actId: deleteAct.actId }));
      unwrapResult(resultAction);
      history.push('/reduction-plan/actions');
    } catch (error) {
      toast.error(error.message);
    } finally {
      closeDeleteWarningModal();
    }
  };

  return (
    <Modal className="sisde-modal" show={showDeleteWarningModal} onHide={closeDeleteWarningModal}>
      <Modal.Header>
        <Modal.Title>{t('Warning')}!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {t('Are you sure that you want to permanently delete action {{actName}}?', { actName: deleteAct?.actName })}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={handleConfirmDeleteAction}>
          {t('Delete')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default DeleteAction;
