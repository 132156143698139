import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Container, Button, Spinner } from 'react-bootstrap';
import { fetchEmissionProfilesTree } from './emissionProfileSlice';
import PageHeader from '../../components/PageHeader';
import TreeViewModal from '../../components/TreeViewModal';
import { LoadingState } from '../../utilities/constants';
import { recursiveMap } from '../../utilities/treeHelpers';
import { Link } from 'react-router-dom';
import { IoRepeatOutline } from 'react-icons/io5';
import { AiOutlineFileSearch } from 'react-icons/ai';
import NodeIcon from '../../components/NodeIcon';
import Empty from '../../components/Empty';
import { useTranslation } from 'react-i18next';

const ManageEmissionProfile = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [showEmissionProfileSelector, setShowEmissionProfileSelector] = useState(true);

  const emissionProfileStatus = useSelector((state) => state.emissionProfiles.status);
  const emissionProfileTree = useSelector((state) => state.emissionProfiles.tree);
  const orgId = useSelector((state) => state.users.user.usrOrgId);
  const loggedUserStatus = useSelector((state) => state.users.loggedUserStatus);

  const closeModal = () => setShowEmissionProfileSelector(false);
  const openModal = () => setShowEmissionProfileSelector(true);

  useEffect(() => {
    if (emissionProfileStatus === LoadingState.idle && loggedUserStatus === LoadingState.succeeded) {
      dispatch(fetchEmissionProfilesTree({ orgId }));
    }
  }, [orgId, loggedUserStatus, emissionProfileStatus, dispatch]);

  const transformedTree = recursiveMap(emissionProfileTree ?? {}, (n) => {
    if (n.eprflId) {
      return {
        weight: n.weight,
        value: n.eprflId,
        labelRaw: n.eprflName,
        label: <span>{n.eprflName}</span>,
        isActive: false
      };
    }
    if (n.eprflcatId) {
      return {
        weight: n.weight,
        value: n.eprflcatId,
        labelRaw: n.eprflcatName,
        label: (
          <Link to={`/admin/emission-profile/category/${n.eprflcatId}/edit`}>
            <NodeIcon node={n} /> {n.eprflcatName}
          </Link>
        ),
        isActive: false
      };
    }
    if (n.eprflsrcId) {
      return {
        weight: n.weight,
        value: n.eprflsrcId,
        labelRaw: n.eprflsrcName,
        label: (
          <Link to={`/admin/emission-profile/source/${n.eprflsrcId}/edit`}>
            <NodeIcon node={n} />
            {n.eprflsrcName}
          </Link>
        ),
        isActive: false,
        valueId: n.eprflsrcAssignedId
      };
    }
  });
  transformedTree.isRoot = true;

  return (
    <Container fluid className="center-empty-component">
      <Row>
        <Col>
          <PageHeader
            title={t('Manage Emission Profile')}
            actions={
              <Button
                className="mr-1 d-flex flex-row justify-content-between align-items-center"
                variant="secondary"
                onClick={openModal}
              >
                <div className="mr-1">
                  <IoRepeatOutline fontSize={17} />
                </div>
                <div>{t('Select Emission Profile')}</div>
              </Button>
            }
          />
        </Col>
      </Row>
      {emissionProfileStatus === LoadingState.loading && <Spinner animation="grow" />}
      {emissionProfileStatus === LoadingState.succeeded && (
        <>
          <Empty
            title={t('Select Emission Profile to Show Data')}
            icon={<AiOutlineFileSearch size="5rem" color="#B8BEC3" />}
          />
          <TreeViewModal
            tree={transformedTree}
            closeModal={closeModal}
            show={showEmissionProfileSelector}
            title={t('Select Emission Profile')}
           />
        </>
      )}
    </Container>
  );
};

export default ManageEmissionProfile;
