import React, { useEffect, useMemo } from 'react';
import { Button, Col, Container, Row, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import { AiOutlineEdit } from 'react-icons/ai';
import { IoEyeOutline } from 'react-icons/io5';

import PageHeader from '../../components/PageHeader';
import { LoadingState } from '../../utilities/constants';
import { fetchWhitelabels } from './WhitelabelSlice';
import DataTable from '../../components/DataTable';

const ManageWhitelabel = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const stateStatus = useSelector((state) => state.whitelabels.status);
  const whitelabels = useSelector((state) => state.whitelabels.whitelabels);
  const orgId = useSelector((state) => state.users.user.usrOrgId);
  const loggedUserStatus = useSelector((state) => state.users.loggedUserStatus);

  const tableColumns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        sortType: 'string'
      },
      {
        Header: 'Product Name',
        accessor: 'productName',
        sortType: 'string'
      },
      {
        Header: 'Domain',
        accessor: 'domain',
        sortType: 'string'
      },
      {
        Header: 'Logo',
        accessor: 'logoUrl',
        disableGlobalFilter: false,
        disableSortBy: true,
        Cell: ({ row }) => {
          const { original } = row;
          return original.logoUrl ? (
            <div className="table-logo" style={{ backgroundImage: `url(${original.logoUrl})` }}>
              {' '}
            </div>
          ) : (
            ''
          );
        }
      },
      {
        Header: 'Login Logo',
        accessor: 'loginLogoUrl',
        disableGlobalFilter: false,
        disableSortBy: true,
        Cell: ({ row }) => {
          const { original } = row;
          return original.loginLogoUrl ? (
            <div className="table-logo" style={{ backgroundImage: `url(${original.loginLogoUrl})` }}>
              {' '}
            </div>
          ) : (
            ''
          );
        }
      },
      {
        Header: '',
        accessor: 'actions',
        actionColumn: true,
        disableGlobalFilter: false,
        disableSortBy: true,
        Cell: ({ row }) => {
          const { original } = row;
          return (
            <div className="action-buttons">
              <Button
                to={`${match.url}/${original.wlbId}`}
                size="sm"
                className="mr-1"
                variant="outline-secondary"
                as={Link}
              >
                <IoEyeOutline />
              </Button>
              <Button
                to={`${match.url}/${original.wlbId}/edit`}
                size="sm"
                className="mr-1"
                variant="outline-secondary"
                as={Link}
              >
                <AiOutlineEdit />
              </Button>
            </div>
          );
        }
      }
    ],
    [match.url]
  );

  useEffect(() => {
    if (loggedUserStatus === LoadingState.succeeded) dispatch(fetchWhitelabels());
  }, [orgId, loggedUserStatus, dispatch]);

  return (
    <Container fluid>
      <Row>
        <Col>
          <PageHeader
            title={'Manage Whitelabels'}
            actions={
              <Button to={`${match.url}/new`} as={Link}>
                + Create Whitelabel
              </Button>
            }
          />
        </Col>
      </Row>
      {stateStatus === LoadingState.loading && <Spinner animation="grow" />}
      {stateStatus === LoadingState.succeeded && (
        <Row>
          <Col>
            <DataTable columns={tableColumns} data={whitelabels} />
          </Col>
        </Row>
      )}
      {stateStatus === LoadingState.failed && <div>Something Went Wrong</div>}
    </Container>
  );
};

export default ManageWhitelabel;
