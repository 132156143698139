import React, { useEffect, useMemo, useState } from 'react';
import { Col, Container, Row, Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { AiOutlineDelete } from 'react-icons/ai';
import { setLeavePrompt } from '../manage-users/usersSlice';
import { fetchEmissionProfilesTree, fetchExcludedEPSs } from './emissionProfileSlice';
import DataTable from '../../components/DataTable';
import { recursiveMap } from '../../utilities/treeHelpers';
import NodeIcon from '../../components/NodeIcon';
import { LoadingState } from '../../utilities/constants';
import TreeViewModal from '../../components/TreeViewModal';
import LoadingButton from '../../components/LoadingButton';
import { addExcludedEPSsApi, removeExcludedEPSsApi } from './emissionProfileApi';

const ManageReportExcluded = () => {
  const [isLoading, setIsLoading] = useState(false);
  const orgId = useSelector((state) => state.users.user.usrOrgId);
  const emissionProfileStatus = useSelector((state) => state.emissionProfiles.status);
  const excludedEPSs = useSelector((state) => state.emissionProfiles.excludedEPSs);
  const emissionProfileTree = useSelector((state) => state.emissionProfiles.tree);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [showTreeSelector, setShowTreeSelector] = useState(false);
  const [selectedNodes, setSelectedNodes] = useState([]);

  const openModal = () => {
    if (!selectedNodes.length) {
      setSelectedNodes([...excludedEPSs]);
    }
    setShowTreeSelector(true);
  };
  const closeModal = () => setShowTreeSelector(false);

  const handleAddExcludedEps = (n, removeIdx = null) => {
    const newNodes = [...selectedNodes];
    if (isLoading) {
      return;
    }
    if (removeIdx === -1) {
      newNodes.push(n);
    } else {
      newNodes.splice(removeIdx, 1);
    }
    setSelectedNodes(newNodes);
    dispatch(setLeavePrompt(true));
  };

  const handleRemoveEPS = async (id) => {
    await removeExcludedEPSsApi(orgId, id, true);
    toast.success(t('The Emission Profile Source has been removed from excluded list.'))
    dispatch(fetchExcludedEPSs({ orgId }));
  };

  const onSubmitHandler = async () => {
    setIsLoading(true);
    try {
      await addExcludedEPSsApi(orgId, { epsIds: selectedNodes.map(item => item.eprflsrcId) }, false);
      setIsLoading(false);
      dispatch(setLeavePrompt(false));
      dispatch(fetchExcludedEPSs({ orgId }));
      setSelectedNodes([]);
      closeModal();
    } catch (err) {
      setIsLoading(false);
      toast.error(err.message);
    }
  };

  const recursiveTree = recursiveMap(emissionProfileTree ?? {}, (n) => {
    if (n.eprflId) {
      return {
        value: n.eprflId,
        labelRaw: n.eprflName,
        label: <span>{n.eprflName}</span>,
        isActive: false
      };
    }
    if (n.eprflcatId) {
      return {
        value: n.eprflcatId,
        labelRaw: n.eprflcatName,
        label: (
          <span>
            <NodeIcon node={n} />
            {n.eprflcatName}
          </span>
        ),
        isActive: false
      };
    }
    if (n.eprflsrcId) {
      const idx = selectedNodes.findIndex(item => item.eprflsrcId === n.eprflsrcId);
      return {
        value: n.eprflsrcId,
        labelRaw: n.eprflsrcName,
        label: (
          <span onClick={() => handleAddExcludedEps(n, idx)}>
            <Form.Check custom onChange={() => { }} checked={idx !== -1} label={n.eprflsrcName} />
          </span>
        ),
        isActive: false,
      };
    }
  });
  recursiveTree.isRoot = true;

  useEffect(() => {
    dispatch(fetchExcludedEPSs({ orgId }));
  }, [dispatch, orgId]);

  useEffect(() => {
    if (emissionProfileStatus === LoadingState.idle) {
      dispatch(fetchEmissionProfilesTree({ orgId }));
    }
  }, [dispatch, emissionProfileStatus, orgId]);

  const excludedDataColumns = useMemo(
    () => [
      {
        Header: t('EPS Id'),
        accessor: 'eprflsrcId',
        id: 'eprflsrcId',
        sortType: 'number'
      },
      {
        Header: t('EPS Name'),
        accessor: 'eprflsrcName',
        id: 'eprflsrcName',
        sortType: 'string'
      },
      {
        Header: t('Action'),
        accessor: 'actions',
        actionColumn: true,
        disableGlobalFilter: false,
        disableSortBy: true,
        Cell: ({ row, handleRemoveEPS }) => {
          return (
            <div className="action-buttons">
              <Button
                onClick={() => handleRemoveEPS(row.original.eprflsrcId)}
                size="sm"
                className="mr-1"
                variant="outline-secondary"
              >
                <AiOutlineDelete />
              </Button>
            </div>
          );
        },
      }
    ],
    [t]
  );

  const renderActionHandler = () => {
    const isDontChange = _.isEqual(excludedEPSs.map(item => item.eprflsrcId), selectedNodes.map(item => item.eprflsrcId));
    const isDirty = selectedNodes.length && !isDontChange;
    return (
      <>
        <LoadingButton isLoading={isLoading} />
        <Button variant="secondary" onClick={closeModal}>{t('Cancel')}</Button>
        <Button variant="primary" onClick={onSubmitHandler} disabled={isLoading || !isDirty}>{t('Submit')}</Button>
      </>
    )
  };

  return (
    <Container fluid>
      <Row className="my-2 manage-report-page">
        <Col>
          <h4>{t('Excluded Emission Profile Sources')}</h4>
          <Row className="my-2 mt-2">
            <Col md={8}>
              <Row>
                <Col>
                  <Form.Group className="custom-form-control">
                    <em>{t('The excluded emission profile is automatically excluded from reports in Dashboard only. For reports in Report tab, this is not applied.')}</em>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button
                    onClick={openModal}
                    className="mb-3"
                    type="button"
                    disabled={isLoading}>
                    {t('+ Add EPS to list')}
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <DataTable
                    autoResetPage={false}
                    columns={excludedDataColumns}
                    data={excludedEPSs}
                    actions={{ handleRemoveEPS }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <TreeViewModal
        tree={recursiveTree}
        closeModal={closeModal}
        show={showTreeSelector}
        title={t('Select Emission Profiles')}
        footerHandler={renderActionHandler}
      />
    </Container>
  );
};

export default ManageReportExcluded;
