import React, { useEffect, useMemo } from 'react';
import { Button, Col, Container, Row, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';
import PageHeader from '../../components/PageHeader';
import { LoadingState, UserRoleList } from '../../utilities/constants';
import { fetchUsers } from './usersSlice';
import { IoEyeOutline } from 'react-icons/io5';
import { AiOutlineEdit } from 'react-icons/ai';
import { FaUserSecret } from 'react-icons/fa';
import DataTable from '../../components/DataTable';
import { useTranslation } from 'react-i18next';
import Empty from '../../components/Empty';
import * as userImpersonate from '../../app/userImpersonate';
import keycloak from '../../keycloak';

const ManageUsers = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const { t } = useTranslation();
  const userStatus = useSelector((state) => state.users.status);
  const users = useSelector((state) => state.users.users);
  const orgId = useSelector((state) => state.users.user.usrOrgId);
  const currImpersonate = userImpersonate.read(keycloak?.subject);
  const isRootAuthorized = userImpersonate.isRootAuthorized();
  const userTableColumns = useMemo(
    () => [
      {
        Header: t('User Name'),
        accessor: 'usrName',
        sortType: 'string'
      },
      {
        Header: t('First Name'),
        accessor: 'usrFirstname',
        sortType: 'string'
      },
      {
        Header: t('Last Name'),
        accessor: 'usrLastname',
        sortType: 'string'
      },
      {
        Header: t('Role'),
        accessor: (row) => {
          return UserRoleList[row.usrRole];
        }
      },
      {
        Header: t('Enabled'),
        accessor: (row) => {
          return row.usrEnabled === 'Y' ? t('Yes') : t('No');
        }
      },
      {
        Header: '',
        accessor: 'actions',
        actionColumn: true,
        disableGlobalFilter: false,
        disableSortBy: true,
        Cell: ({ row }) => {
          const user = row.original;
          const renderImpersonate = () => {
            if (!isRootAuthorized
              || !user.usrKeycloakId
              || keycloak.subject === user.usrKeycloakId
              || currImpersonate?.usrKeycloakId === user.usrKeycloakId) {
              return null;
            }
            return (
              <Button
                onClick={() => {
                  userImpersonate.applyTo(user, user.usrRole, keycloak?.subject);
                  toast.warn(`Impersonating to ${user?.usrEmail || user?.usrName}...`);
                  setTimeout(() => window.location.href = '/', 2000);
                }}
                size="sm"
                className="mr-1"
                variant="outline-secondary"
                title="Impersonate to this user"
              >
                <FaUserSecret />
              </Button>
            );
          };
          return (
            <div className="d-flex action-buttons">
              <Link to={`${match.url}/${user.usrId}`}>
                <Button variant="outline-secondary" className="mr-3">
                  <IoEyeOutline />
                </Button>
              </Link>
              <Link to={`${match.url}/${user.usrId}/edit`}>
                <Button variant="outline-secondary">
                  <AiOutlineEdit />
                </Button>
              </Link>
              {renderImpersonate()}
            </div>
          );
        }
      }
    ],
    [match.url, t, currImpersonate, isRootAuthorized]
  );

  useEffect(() => {
    dispatch(fetchUsers({ orgId }));
  }, [orgId, dispatch]);

  return (
    <Container fluid>
      <Row>
        <Col>
          <PageHeader
            title={t('Manage Users')}
            actions={
              <Button to={`${match.url}/new`} as={Link}>
                + {t('Create User')}
              </Button>
            }
          />
        </Col>
      </Row>
      {userStatus === LoadingState.loading && <Spinner animation="grow" />}
      {userStatus === LoadingState.succeeded && (
        <Row>
          <Col>
            <DataTable columns={userTableColumns} data={users} />
          </Col>
        </Row>
      )}
      {userStatus === LoadingState.failed && <Empty title="Sorry, Something went wrong" />}
    </Container>
  );
};

export default ManageUsers;
