import { useEffect } from 'react';
import { Form, Row, Col, Modal, Button } from 'react-bootstrap';
import { useForm, useWatch } from 'react-hook-form';
import EmissionSourceLink from './EmissionSourceLink';
import EmissionOtherSourceLink from './EmissionOtherSourceLink';
import EmissionSourceRelevant from './EmissionSourceRelevant';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import EmissionSource from './EmissionSource';
import CustomEmissionSource from './CustomEmissionSource';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectEmissionProfileSourceById } from '../emissionProfileSlice';
import { setLeavePrompt } from '../../manage-users/usersSlice';
import { fetchReportUnits } from '../../organization/OrganizationSlice';

const schema = yup.object().shape({
  eprflsrcName: yup.string().required('Emission Source Name is required'),
  eprflsrcAssignedId: yup
    .string()
    .required('Emission Source ID is required')
    .matches(/^([A-Za-z0-9-_.\s]){1,15}$/, {
      message: 'Id may contain only characters, numbers, hyphen, space, dot and less than 15 characters'
    }),
  eprflsrcReportingName: yup.string().transform(convertNullToEmptyString),
  eprflsrcDesc: yup
    .string()
    .transform(convertNullToEmptyString)
    .max(255, 'Description cannot exceed more than 255 characters'),
  eprflsrcCo2ConversionFactor: yup.string().transform(convertNullToEmptyString),
  eprflsrcCo2Uncertainty: yup.string().transform(convertNullToEmptyString),
  eprflsrcCh4ConversionFactor: yup.string().transform(convertNullToEmptyString),
  eprflsrcCh4Uncertainty: yup.string().transform(convertNullToEmptyString),
  eprflsrcN2oConversionFactor: yup.string().transform(convertNullToEmptyString),
  eprflsrcN2oUncertainty: yup.string().transform(convertNullToEmptyString),
  eprflsrcSf6ConversionFactor: yup.string().transform(convertNullToEmptyString),
  eprflsrcSf6Uncertainty: yup.string().transform(convertNullToEmptyString),
  eprflsrcNf3ConversionFactor: yup.string().transform(convertNullToEmptyString),
  eprflsrcNf3Uncertainty: yup.string().transform(convertNullToEmptyString),
  eprflsrcHfcConversionFactor: yup.string().transform(convertNullToEmptyString),
  eprflsrcHfcUncertainty: yup.string().transform(convertNullToEmptyString),
  eprflsrcPfcConversionFactor: yup.string().transform(convertNullToEmptyString),
  eprflsrcPfcUncertainty: yup.string().transform(convertNullToEmptyString),
  custom: yup.string().transform(convertNullToEmptyString),
  gas: yup.string().transform(convertNullToEmptyString),
  eprflsrcConsumptionUnit: yup
    .string()
    .transform(convertNullToEmptyString)
    .when('custom', {
      is: 'Y',
      then: yup.string().transform(convertNullToEmptyString).required('Consumption Unit is required')
    }),
  eprflsrcConversionFactor: yup
    .string()
    .transform(convertNullToEmptyString)
    .when(['custom', 'gas'], {
      is: (custom, gas) => custom === 'Y' && gas === 'N',
      then: yup.string().transform(convertNullToEmptyString).required('Emission Factor is required')
    }),
  eprflsrcConversionFactortj: yup
    .string()
    .transform(convertNullToEmptyString)
    .when(['custom', 'gas'], {
      is: (custom, gas) => custom === 'Y' && gas === 'N',
      then: yup.string().transform(convertNullToEmptyString).required('Emission Content Factor is required')
    }),
  eprflsrcUncertainty: yup
    .string()
    .transform(convertNullToEmptyString)
    .when(['custom', 'gas'], {
      is: (custom, gas) => custom === 'Y' && gas === 'N',
      then: yup.string().transform(convertNullToEmptyString)
    }),
  eprflsrcStartDate: yup.string().nullable().notRequired(),
  eprflsrcEndDate: yup.string().nullable().notRequired(),
  weight: yup.number()
    .min(1, 'Sorting position be greater than or equal to 1')
    .typeError('Sorting position must be a number')
    .transform((v, val) => !val ? null : Number(val))
    .nullable(),
});

function convertNullToEmptyString(value, originalValue) {
  if (originalValue === null) return '';
  return originalValue;
}

function convertEmptyStringToNull(obj) {
  const newObj = JSON.parse(JSON.stringify(obj));
  Object.keys(newObj).forEach((key) => {
    if (newObj[key] === '') newObj[key] = null;
  });
  return newObj;
}

const EmissionProfileForm = ({
  onSubmit,
  initialValues = {},
  tree,
  scopedTree,
  isEditing = false,
  renderActionButtons,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showLinkedEpsConfirmationModal, setShowLinkedEpsConfirmationModal] = useState(null);
  const [formData, setFormData] = useState(null);
  const [selectedRelEpsNode, setSelectedRelEpsNode] = useState(null);
  const [isCopyHightlight, setCopyHightlight] = useState(null);
  const [selectedEmissionSource, setSelectedEmissionSource] = useState(isEditing ? initialValues.eprflsrcEsrcId : null);
  const emissionProfile = useSelector((state) => selectEmissionProfileSourceById(state, initialValues.eprflsrcId));
  const orgId = useSelector((state) => state.users.user.usrOrgId);

  useEffect(() => {
    dispatch(fetchReportUnits(orgId));
  }, [dispatch, orgId]);

  const openLinkedEpsConfirmationModal = () => setShowLinkedEpsConfirmationModal(true);
  const closeLinkedEpsConfirmationModal = () => setShowLinkedEpsConfirmationModal(false);

  const handleSelectedRelEpsNode = (value) => {
    setSelectedRelEpsNode(value);
    setCopyHightlight(!!value);
  };
  const handleReset = () => {
    handleSelectedRelEpsNode(null);
    reset();
  };

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    watch,
    formState: { errors, isDirty, isValid }
  } = useForm({
    defaultValues: {
      custom: isEditing && !initialValues.eprflsrcEsrcId ? 'Y' : 'N',
      gas: isEditing && !initialValues.eprflsrcConversionFactortj && !initialValues.eprflsrcEsrcId ? 'Y' : 'N',
      linked: isEditing && initialValues.eprflsrcLinkedId ? 'Y' : 'N',
      otherLinked: isEditing && initialValues.eprflsrcOtherLinkedIds ? 'Y' : 'N',
      eprflsrcEnabled: 'Y',
      eprflsrcVisible: 'Y',
      eprflsrcMeterReading: 'N',
      eprflsrcName: '',
      eprflsrcAssignedId: '',
      showGeneralEmissionFromEps: '',
      eprflsrcLinkedId: '',
      eprflsrcOtherLinkedIds: null,
      ...initialValues
    },
    resolver: yupResolver(schema),
    mode: 'onChange'
  });

  useEffect(() => {
    dispatch(setLeavePrompt(isDirty))
  }, [isDirty, dispatch]);

  const eprflsrcName = useWatch({
    control,
    name: 'eprflsrcName'
  });

  const eprflsrcAssignedId = useWatch({
    control,
    name: 'eprflsrcAssignedId'
  });

  const eprflsrcReportingName = useWatch({
    control,
    name: 'eprflsrcReportingName'
  });

  const eprflsrcEsrcId = useWatch({
    control,
    name: 'eprflsrcEsrcId'
  });

  const custom = useWatch({
    control,
    name: 'custom'
  });

  const gas = useWatch({
    control,
    name: 'gas'
  });

  const eprflsrcConsumptionUnit = useWatch({
    control,
    name: 'eprflsrcConsumptionUnit'
  });

  const eprflsrcConversionFactor = useWatch({
    control,
    name: 'eprflsrcConversionFactor'
  });

  const eprflsrcConversionFactortj = useWatch({
    control,
    name: 'eprflsrcConversionFactortj'
  });

  const linked = useWatch({
    control,
    name: 'linked'
  });

  const otherLinked = useWatch({
    control,
    name: 'otherLinked'
  });

  const eprflsrcLinkedId = useWatch({
    control,
    name: 'eprflsrcLinkedId'
  });
  const eprflsrcOtherLinkedIds = useWatch({
    control,
    name: 'eprflsrcOtherLinkedIds'
  });

  let saveAllowed = false;
  if (custom === 'N') {
    if (eprflsrcEsrcId) {
      if (eprflsrcName && !errors?.eprflsrcAssignedId) {
        saveAllowed = true && isDirty;
      } else {
        saveAllowed = false;
      }
    } else {
      saveAllowed = false;
    }
  } else {
    if (gas === 'N') {
      if (
        eprflsrcName &&
        eprflsrcAssignedId &&
        !errors?.eprflsrcAssignedId &&
        eprflsrcConsumptionUnit &&
        eprflsrcConversionFactor &&
        eprflsrcConversionFactortj
      ) {
        saveAllowed = true && isDirty;
      } else {
        saveAllowed = false;
      }
    } else {
      if (eprflsrcName && eprflsrcAssignedId && !errors?.eprflsrcAssignedId && eprflsrcConsumptionUnit) {
        saveAllowed = true && isDirty;
      } else {
        saveAllowed = false;
      }
    }
  }

  const doSubmitHandler = async(data) => {
    data = convertEmptyStringToNull(data);
    setFormData(data);
    if (!eprflsrcReportingName) setValue('eprflsrcReportingName', eprflsrcName);
    if (isEditing) {
      if (linked === 'Y' && eprflsrcLinkedId?.eprflsrcId !== initialValues.eprflsrcLinkedId?.eprflsrcId) {
        if (typeof eprflsrcLinkedId === 'number' && eprflsrcLinkedId === initialValues.eprflsrcLinkedId?.eprflsrcId)
          await onSubmit(data);
        else openLinkedEpsConfirmationModal();
      } else {
        if (linked === 'Y' && eprflsrcLinkedId && initialValues.eprflsrcLinkedId === null)
          openLinkedEpsConfirmationModal();
        else if (otherLinked === 'Y' && eprflsrcOtherLinkedIds && eprflsrcOtherLinkedIds.length) {
          openLinkedEpsConfirmationModal();
        }
        else await onSubmit(data);
      }
    } else {
      if (linked === 'Y' && eprflsrcLinkedId) {
        openLinkedEpsConfirmationModal();
      } else if (otherLinked === 'Y' && eprflsrcOtherLinkedIds && eprflsrcOtherLinkedIds.length) {
        openLinkedEpsConfirmationModal();
      } else {
        await onSubmit(data);
      }
    }
  };

  const onSubmitHandler = async(data, isConfirmed = false) => {
    try {
      dispatch(setLeavePrompt(false));
      if (isConfirmed) {
        await onSubmit(data);
      } else {
        await doSubmitHandler(data);
      }
    } catch (e) {
      dispatch(setLeavePrompt(isDirty));
    }
  };

  return (
    <Form onSubmit={handleSubmit((data) => onSubmitHandler(data, false))}>
      {!isEditing && (
        <Row>
          <Col>
            <EmissionSourceRelevant
              control={control}
              setValue={setValue}
              tree={tree}
              selectedRelEpsNode={selectedRelEpsNode}
              setSelectedRelEpsNode={handleSelectedRelEpsNode}
            />
          </Col>
        </Row>
      )}
      <div className="form-divider">
        <span>{t('Emission SourceDetails')}</span>
        <div></div>
      </div>
      <EmissionSource
        control={control}
        register={register}
        errors={errors}
        watch={watch}
        setValue={setValue}
        isEditing={isEditing}
        setSelectedEmissionSource={setSelectedEmissionSource}
        selectedRelEpsNode={selectedRelEpsNode}
        isCopyHightlight={isCopyHightlight}
        dataValues={{ eprflsrcName, eprflsrcAssignedId }}
      />
      <div className="form-divider">
        <span>{t('Custom Emission Source Details')}</span>
        <div></div>
      </div>
      <CustomEmissionSource
        control={control}
        setValue={setValue}
        register={register}
        errors={errors}
        isEditing={isEditing}
      />
      <div className="form-divider">
        <span>{t('Linked Emission Profile Sources')}</span>
        <div></div>
      </div>
      <Row>
        <EmissionSourceLink
          control={control}
          setValue={setValue}
          tree={scopedTree}
          selectedEmissionSource={selectedEmissionSource}
          emissionProfile={emissionProfile}
        />
      </Row>
      <Row>
        <EmissionOtherSourceLink
          control={control}
          setValue={setValue}
          tree={scopedTree}
          selectedEmissionSource={selectedEmissionSource}
          emissionProfile={emissionProfile}
        />
      </Row>
      {renderActionButtons({ saveAllowed: saveAllowed && isValid, isDirty, handleReset })}
      <Modal show={showLinkedEpsConfirmationModal} onHide={closeLinkedEpsConfirmationModal} backdrop="static">
        <Modal.Header>
          <Modal.Title>{t('Link Emission Profile Source')}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: 'justify' }}>
          {t('Selecting Full fuel cycle/ other linked EPS will automatically assign any activity for this emission profile source to the chosen emission profile source as well. Are you sure you want to proceed?')}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeLinkedEpsConfirmationModal}>
            {t('Cancel')}
          </Button>
          <Button
            onClick={() => {
              closeLinkedEpsConfirmationModal();
              onSubmitHandler(formData, true);
            }}
            variant="primary"
          >
            {t('Ok')}
          </Button>
        </Modal.Footer>
      </Modal>
    </Form>
  );
};

export default EmissionProfileForm;
