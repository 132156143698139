import React from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaUserSecret } from 'react-icons/fa';
import {
  BiSupport,
  BiHelpCircle,
  BiLogOut,
  BiUser,
  BiLock,
  BiBookOpen,
  BiFlag,
} from 'react-icons/bi';
import LogoIcon from '../../assets/images/carbonview-logo.png';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as userImpersonate from '../../app/userImpersonate';
import { setAppLocale } from '../../utilities/common';
import keycloak from '../../keycloak';

const Header = () => {

  const { t, i18n } = useTranslation();
  const localeOptions = useSelector((state) => state.users.user.localeOptions);
  const userInfo = useSelector((state) => state.users.user);

  const handleLogInOut = () => {
    userImpersonate.stop();
    if (keycloak.authenticated) {
      keycloak.logout();
    } else {
      keycloak.login();
    }
  };
  const handleChangeTransBundle = (e) => {
    const locale = e.value.toString();
    i18n.changeLanguage(locale);
    setAppLocale(locale);
  };
  const navHelpTitle = (<span><BiHelpCircle className={'nav-icons'} size={24} /> {t('Help')}</span>);
  const navAccountTitle = (<span><BiUser className={'nav-icons'} size={24} /> {t('Account')}</span>);
  const getCurrentLanguageTitle = () => {
    const current = localeOptions?.find((b) => b.value === i18n?.language) || localeOptions[0];
    return (
      <span>
        <BiFlag className={'nav-icons'} size={24} />
        {t('Language: {{name}}', { name: current ? current.label : 'English' })}
      </span>
    );
  };

  const renderStopImpersonate = () => {
    const user = userImpersonate.read(keycloak?.subject);
    if (!user) {
      return null;
    }
    return (
      <Nav.Link
        title={`Impersonating as: ${user.usrEmail || user.usrName}`}
        onClick={() => {
          userImpersonate.stop();
          window.location.href = '/';
        }}
        className="header-sub-title btn btn-outline-danger stop-impersonate"
      >
        <FaUserSecret className={'nav-icons'} size={24} />
        <span className="ml-0">{t('Stop impersonate')}</span>
      </Nav.Link>
    );
  };

  return (
    <div className="header main-header">
      <Navbar expand="lg" className="header-nav">
        <Navbar.Brand href="/" className="logo">
          <div className="d-flex align-items-center">
            <img alt="" src={LogoIcon} width="50" height="50" className="d-inline-block align-top" />
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto nav__toolbar__dropdown align-items-center">
            {renderStopImpersonate()}
            <NavDropdown title={navHelpTitle} className="header-sub-title mr-2">
              <NavDropdown.Item target="blank" href="https://help.carbon-view.com/" className="header-sub-title">
                <BiBookOpen className={'nav-icons'} size={24} />
                <span className="ml-0">{t('Documentation')}</span>
              </NavDropdown.Item>
              <NavDropdown.Item href="https://simblegroup.zendesk.com/hc/en-us/requests/new" className="header-sub-title">
                <BiSupport className={'nav-icons'} size={24} />
                <span className="ml-0">{t('Contact Support')}</span>
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown alignRight={true} title={navAccountTitle} className="header-sub-title mr-2">
              <NavDropdown.Item as="span" className="header-sub-title">
                <BiUser className={'nav-icons'} size={24} />
                <span className="ml-0">{userInfo?.usrEmail || userInfo?.usrName}</span>
              </NavDropdown.Item>
              <NavDropdown title={getCurrentLanguageTitle()} className="dropdown-item header-sub-title mr-2">
                {localeOptions?.map(item => (
                  <NavDropdown.Item key={item.value} className="header-sub-title" onClick={() => handleChangeTransBundle(item)}>
                    <span className="ml-0">{item.label}</span>
                  </NavDropdown.Item>
                ))}
              </NavDropdown>
              <NavDropdown.Item as={Link} to="/accounts/two-factor-authentication" className="header-sub-title">
                <BiLock className={'nav-icons'} size={24} />
                <span className="ml-0">{t('Two-Factor Authentication')}</span>
              </NavDropdown.Item>
              <NavDropdown.Item className="header-sub-title d-inline-flex" onClick={() => handleLogInOut()}>
                <BiLogOut className="nav-icons" size={24} />
                <span className="ml-0">{t('Log out')}</span>
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default Header;
