import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { updateTarget } from '../../reductionPlanSlice';
import Placeholder from '../../../../assets/images/medal.svg';
import { LoadingState } from '../../../../utilities/constants';
import { unwrapResult } from '@reduxjs/toolkit';
import { setLeavePrompt } from '../../../manage-users/usersSlice';
import DiscardModal from '../../../../components/DiscardModal';
import { useTranslation } from 'react-i18next';


export default function ChangeGoalModal({
  updateModalShow,
  setUpdateModalShow,
  orgName,
  orgId,
  updateGoal,
  updateTargetStatus,
}) {
  const { t } = useTranslation();
  const target_utc = new Date(Date.UTC(updateGoal?.targetYear));
  const target_start_utc = new Date(Date.UTC(updateGoal?.targetStartYear));
  const dispatch = useDispatch();
  const [targetYearDate, setTargetYearDate] = useState(target_utc);
  const [targetYear, setTargetYear] = useState();
  const [targetMonth, setTargetMonth] = useState(updateGoal.targetMonth);
  const [targetStartYearDate, setTargetStartYearDate] = useState(target_start_utc);
  const [targetStartYear, setTargetStartYear] = useState();
  const [targetStartMonth, setTargetStartMonth] = useState(updateGoal.targetStartMonth);
  const isLeavePrompt = useSelector((state) => state.users.isLeavePrompt);
  const setFormChange = () => dispatch(setLeavePrompt(true));
  const [showInformationDisplayModal, setShowInformationDisplayModal] = useState(false);

  const handleExit = () => {
    setSetupModalShowHandler();
    setShowInformationDisplayModal(false);
  };
  const handleCancel = () => {
    setShowInformationDisplayModal(false);
  };

  const onHideHandler = () => {
    if (isLeavePrompt) {
      setShowInformationDisplayModal(true);
    } else {
      setUpdateModalShow(false);
    }
  };

  const setSetupModalShowHandler = () => {
    setUpdateModalShow(false);
    dispatch(setLeavePrompt(false));
  }

  async function handleUpdateTarget() {
    const formData = {};
    formData.targetYear = targetYear;
    formData.targetMonth = targetMonth;
    formData.targetStartYear = targetStartYear;
    formData.targetStartMonth = targetStartMonth;
    formData.isPrimaryTarget = true;
    formData.title = t('Become Net Zero');
    formData.description = t('Simple explainer of the NetZero Goal');

    const targetUpdateResponse = await dispatch(
      updateTarget({
        data: formData,
        orgId,
        targetId: updateGoal.id,
      })
    );

    const targetUpdateResult = unwrapResult(targetUpdateResponse);
    if (!targetUpdateResult.error) {
      setSetupModalShowHandler();
    }

  }

  return (
    <>
      <Modal
        show={updateModalShow}
        onHide={() => onHideHandler()}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        className='custom-modal set-goal-modal'
        backdrop='static'
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className='set-goal-header'>
            <img alt='' src={Placeholder} className='Primary Goal' />
            <label>{t('Become Net Zero')}</label>
          </div>
          <h4 className='set-goal-title'>
            <span>{t('{{orgName}} started Net Zero journey on', { orgName })}</span>
            <br />
            <div className='set-goal-date set-goal-year'>
              <DatePicker
                selected={targetStartYearDate}
                onChange={(date) => {
                  setTargetStartYearDate(date);
                  setTargetStartYear(new Date(date).getFullYear());
                  setFormChange();
                }}
                showYearPicker
                dateFormat='yyyy'
              />
            </div>
            <div className='set-goal-date set-goal-month mb-3'>
              <Form.Control
                defaultValue={updateGoal.targetStartMonth}
                as='select'
                size='sm'
                onChange={(e) => {
                  setTargetStartMonth(e.target.value);
                  setFormChange();
                }}
              >
                <option value='jan'>{t('January')}</option>
                <option value='feb'>{t('February')}</option>
                <option value='mar'>{t('March')}</option>
                <option value='apr'>{t('April')}</option>
                <option value='may'>{t('May')}</option>
                <option value='jun'>{t('June')}</option>
                <option value='jul'>{t('July')}</option>
                <option value='aug'>{t('August')}</option>
                <option value='sep'>{t('September')}</option>
                <option value='oct'>{t('October')}</option>
                <option value='nov'>{t('November')}</option>
                <option value='dec'>{t('December')}</option>
              </Form.Control>
            </div>
            <br />
            <span className='mt-2'>{t('and aims to be Net Zero by')}</span>
            <br />
            <div className='set-goal-date set-goal-year'>
              <DatePicker
                selected={targetYearDate}
                onChange={(date) => {
                  setTargetYearDate(date);
                  setTargetYear(new Date(date).getFullYear());
                  setFormChange();
                }}
                showYearPicker
                dateFormat='yyyy'
              />
            </div>
            <div className='set-goal-date set-goal-month'>
              <Form.Control
                defaultValue={updateGoal.targetMonth}
                as='select'
                size='sm'
                onChange={(e) => {
                  setTargetMonth(e.target.value);
                  setFormChange();
                }}
              >
                <option value='jan'>{t('January')}</option>
                <option value='feb'>{t('February')}</option>
                <option value='mar'>{t('March')}</option>
                <option value='apr'>{t('April')}</option>
                <option value='may'>{t('May')}</option>
                <option value='jun'>{t('June')}</option>
                <option value='jul'>{t('July')}</option>
                <option value='aug'>{t('August')}</option>
                <option value='sep'>{t('September')}</option>
                <option value='oct'>{t('October')}</option>
                <option value='nov'>{t('November')}</option>
                <option value='dec'>{t('December')}</option>
              </Form.Control>
            </div>
          </h4>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="text-uppercase"
            variant='none'
            onClick={() => onHideHandler()}
          >
            {t('Cancel')}
          </Button>
          <Button
            className="text-uppercase"
            variant='primary'
            onClick={() => {
              handleUpdateTarget();
            }}
            disabled={updateTargetStatus === LoadingState.loading}
          >
            {t('Change Goal')}
          </Button>
        </Modal.Footer>
      </Modal>
      <DiscardModal
        onCancelHandler={handleCancel}
        isShowModal={showInformationDisplayModal}
        onDiscardHandler={handleExit} />
    </>
  );
}
