import React, { useState, useEffect } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import Select from 'react-select';
import * as yup from 'yup';

const FormData = ({ t, showModal, closeModal, units, onSubmitHandler }) => {
  const schema = yup.object().shape({
    factor: yup.number().required(t('Factor is required field')).typeError(t('Invalid Number Value')),
    unit: yup.string().required(t('Unit is required field'))
  });
  const [selectedUnit, setSelectedUnit] = useState();
  const [factorValue, setFactorValue] = useState();
  const {
    clearErrors,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange'
  });
  const onSubmit = () => {
    if(selectedUnit && factorValue) {
      onSubmitHandler({unit: selectedUnit.value, factor: factorValue});
    }
    setFactorValue(null)
    setSelectedUnit(null)
    closeModal()
  }

  useEffect(() => {
    clearErrors(['selectedUnit', 'factorValue']);
    setValue('selectedUnit', null)
    setValue('factorValue', null)
  }, [clearErrors, setValue]);

  const getUnitsOptions = () => {
    if (!units) {
      return [];
    }
    let selectOpts = Object.keys(units).map((key, index) => {
      return {
        key: index,
        label: `${key} (${units[key].join(',')})`,
        value: key
      };
    });
    selectOpts = _.orderBy(selectOpts, 'value', 'asc');
    return selectOpts;
  };
  const canSubmit = selectedUnit && factorValue;
  return (
    <Modal show={showModal} onHide={closeModal} >
      <Modal.Header>
        <Modal.Title>{t('Create Energy Content Factor')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Select Unit')} *</Form.Label>
            <Select
              name="unit"
              value={selectedUnit}
              options={getUnitsOptions()}
              onChange={(e) => {
                setSelectedUnit(e)
              }}
              placeholder={'Select an unit'}
            />
            {errors.unit && <span className="required-span">{errors.unit.message}</span>}
          </Form.Group>
          <Form.Group className="custom-form-control">
            <Form.Label> {t('Factor')} *</Form.Label>
            <Form.Control
              name="factor"
              value={factorValue}
              onChange={(e) => setFactorValue(e.target.value)}
              type="number"
            />
            {errors.factor && <span className="required-span">{errors.factor.message}</span>}
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          {t('Cancel')}
        </Button>
        <Button variant="primary" onClick={onSubmit} disabled={!canSubmit}>
          {t('Create')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FormData;
