import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PrimarySidebar = ({ items = [] }) => {
  const { t } = useTranslation();

  return (
    <div className="sidebar sidebar--primary flex-column flex-shrink-0">
      {items.map((item) => (
        <div key={item.path} className="nav-item">
          <NavLink className="sidebar__link" to={item.path}>
            <div className="icon-label-group">
              <div className="nav-icon">{item.icon}</div>
              <p className="nav-label">{t(item.name)}</p>
            </div>
          </NavLink>
        </div>
      ))}
    </div>
  );
};

export default PrimarySidebar;
