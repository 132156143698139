import React from 'react';
import { IoPrintOutline } from 'react-icons/io5';
import { useReactToPrint } from 'react-to-print';

const DashboardPrintButton = ({ downloadRef, noScroll = false, title = '', disabled = false, tableRef = null }) => {
  const handlePrint = useReactToPrint({
    // documentTitle: title,
    onBeforeGetContent: () => {
      document.title = title;
      if (downloadRef.current && downloadRef.current.id) {
        if (noScroll) {
          let source = document.getElementById(tableRef.current.id);
          source.classList.remove('responsive-table');
        }
      }
    },
    content: () => downloadRef.current,
    onAfterPrint: () => {
      if (downloadRef.current && downloadRef.current.id) {
        if (noScroll) {
          let source = document.getElementById(tableRef.current.id);
          source.classList.add('responsive-table');
        }
      }
    }
  });

  return (
    <button variant="secondary" className="custom-dashboard-button" onClick={handlePrint} disabled={disabled}>
      <IoPrintOutline fontSize={17} />
    </button>
  );
};
export default DashboardPrintButton;
