import { Col, Container, Row, Button, Spinner } from 'react-bootstrap';
import { React, useState } from 'react';
import PageHeader from '../../../components/PageHeader';
import { useEffect, useMemo } from 'react';
import Empty from '../../../components/Empty';
import DataTable from '../../../components/DataTable';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getActions } from '../reductionPlanSlice';
import { IoEyeOutline, IoTrashOutline } from 'react-icons/io5';
import { AiOutlineEdit } from 'react-icons/ai';
import { Link, useRouteMatch } from 'react-router-dom/cjs/react-router-dom';
import { LoadingState } from '../../../utilities/constants';
import DeleteAction from './DeleteAction';
import { format } from 'date-fns';
import { formatNumber } from '../../reports/reportHelper';
import { getMonthDateFormat, parseUserInfo } from '../../../utilities/common';

export const ManageActions = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const match = useRouteMatch();

  const [showDeleteWarningModal, setShowDeleteWarningModal] = useState(false);
  const [deleteAct, setDeleteAct] = useState(null);

  const { actions, actionStatus } = useSelector((state) => state.reductionPlan);
  const { orgId, usrDateFormat } = parseUserInfo(useSelector((state) => state.users.user));

  const monthDateFormat = getMonthDateFormat(usrDateFormat);

  const handleDeleteAction = (act) => {
    setDeleteAct(act);
    setShowDeleteWarningModal(true);
  };

  const closeDeleteWarningModal = () => setShowDeleteWarningModal(false);

  const actionTableColumns = useMemo(
    () => [
      {
        Header: t('Name'),
        accessor: 'actName',
        sortType: 'string'
      },
      {
        Header: t('Entity'),
        accessor: 'actDiv.divName',
        sortType: 'string'
      },
      {
        Header: t('Purpose'),
        accessor: 'actPurpose',
        disableGlobalFilter: false,
        Cell: ({ value }) => {
          return t(value);
        },
        sortType: 'string'
      },
      {
        Header: t('CO2-e reduction (tonnes)'),
        accessor: 'actTotalReduction',
        Cell: ({ value }) => {
          return formatNumber(value);
        },
        disableGlobalFilter: false,
        sortType: 'number'
      },
      {
        Header: t('Start Month'),
        accessor: 'actStartMonth',
        disableGlobalFilter: false,
        Cell: ({ value }) => {
          return format(new Date(value), monthDateFormat);
        },
        sortType: 'string'
      },
      {
        Header: t('End Month'),
        accessor: 'actEndMonth',
        disableGlobalFilter: false,
        Cell: ({ value }) => {
          return format(new Date(value), monthDateFormat);
        },
        sortType: 'string'
      },
      {
        Header: '',
        accessor: 'actions',
        actionColumn: true,
        disableGlobalFilter: false,
        disableSortBy: true,
        Cell: ({ row }) => {
          const action = row.original;
          return (
            <div className="d-flex action-buttons">
              <Link to={`${match.url}/${action.actId}/view`}>
                <Button variant="outline-secondary" className="mr-3">
                  <IoEyeOutline />
                </Button>
              </Link>
              <Link to={`${match.url}/${action.actId}/edit`}>
                <Button variant="outline-secondary" className="mr-3">
                  <AiOutlineEdit />
                </Button>
              </Link>
              <Button variant="outline-secondary" className="mr-3" onClick={() => handleDeleteAction(action)}>
                <IoTrashOutline />
              </Button>
            </div>
          );
        }
      }
    ],
    [match.url, t, monthDateFormat]
  );
  useEffect(() => {
    if (orgId && actionStatus === LoadingState.idle) {
      dispatch(getActions({ orgId: orgId }));
    }
  }, [dispatch, orgId, actionStatus]);
  return (
    <Container fluid>
      <Row>
        <Col>
          <PageHeader
            title={t('Actions')}
            actions={
              <Button to={`${match.url}/new`} as={Link}>
                + {t('Create Action')}
              </Button>
            }
          />
        </Col>
      </Row>
      {actionStatus === LoadingState.loading && <Spinner animation="grow" />}
      {actionStatus === LoadingState.succeeded && (
        <Row>
          <Col>
            <DataTable columns={actionTableColumns} data={actions} />
          </Col>
        </Row>
      )}
      {actionStatus === LoadingState.failed && <Empty title={t('Sorry, Something went wrong')} />}
      <DeleteAction
        orgId={orgId}
        deleteAct={deleteAct}
        showDeleteWarningModal={showDeleteWarningModal}
        closeDeleteWarningModal={closeDeleteWarningModal}
      />
    </Container>
  );
};
