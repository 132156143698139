import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import PageHeader from '../../components/PageHeader';
import { LoadingState } from '../../utilities/constants';
import EmissionForm from './EmissionForm';
import { fetchSingleEmissionData, getSelectedEntity, getSelectedEP } from './emissionSlice';
import { updateEmissionApi } from './emissionApi';
import { toast } from 'react-toastify';

const EditEmission = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { emissionId } = useParams();
  const [emission, setEmission] = useState(null);
  const [saveEmissionState, setSaveEmissionState] = useState(LoadingState.idle);
  const selectedEntity = useSelector(getSelectedEntity);
  const selectedEP = useSelector(getSelectedEP);
  const isBusy = saveEmissionState === LoadingState.loading;
  const orgId = useSelector((state) => state.users.user.usrOrgId);

  const handleEditEmission = async (data) => {
    try {
      setSaveEmissionState(LoadingState.loading);
      const requestData = { ...data, eDivId: selectedEntity.divId, eEprflsrcId: selectedEP.eprflsrcId };
      await updateEmissionApi(orgId, emissionId, requestData);
      setSaveEmissionState(LoadingState.succeeded);
      history.push('/emissions/data');
    } catch(error) {
      setSaveEmissionState(LoadingState.idle);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resultAction = await dispatch(fetchSingleEmissionData({ emissionId }));
        if (resultAction.payload) {
          const result = unwrapResult(resultAction);
          setEmission(result);
        }
      } catch (error) {}
    };
    fetchData();
  }, [dispatch, emissionId]);

  if (!selectedEntity || !selectedEP) {
    return <Redirect to={`/emissions/data`} />;
  }

  return (
    <Container fluid>
      <Row>
        <Col>
          <PageHeader
            title={t('Edit Emission')}
            enableBackButton
          />
          {emission && (
            <EmissionForm
              onSubmit={handleEditEmission}
              initialValues={emission}
              EPName={selectedEP?.eprflsrcName}
              entityName={selectedEntity?.divName}
              buttonText={t('Save')}
              disabled={isBusy}
              isEdit={true}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default EditEmission;
