import React from 'react';
import { IoPrintOutline } from 'react-icons/io5';
import { Button } from 'react-bootstrap';
import { useReactToPrint } from 'react-to-print';
import { legend_configuration } from '../features/reports/style-configurations';
import { useTranslation } from 'react-i18next';

const PrintButton = ({ downloadRef, disabled }) => {
  const { t } = useTranslation();
  const handlePrint = useReactToPrint({
    onBeforeGetContent: () => {
      if (downloadRef.current && downloadRef.current.id) {
        let doc = document.getElementById(downloadRef.current.id);
        if (doc.querySelector('.chart-legend')) {
          doc.querySelector('.chart-legend').style['height'] = 'auto';
          // doc.querySelector('#js-legend_iebe').style['height'] = 'auto';
        }
        if (doc.querySelector('.responsive-table')) {
          doc.querySelector('.responsive-table').style['overflow'] = 'hidden';
          doc.querySelector('.responsive-table').style['display'] = 'inline';
        }
      }
    },
    content: () => downloadRef.current,
    onAfterPrint: () => {
      if (downloadRef.current && downloadRef.current.id) {
        let doc = document.getElementById(downloadRef.current.id);
        if (doc.querySelector('.chart-legend')) {
          doc.querySelector('.chart-legend').style['height'] = legend_configuration.chart_legend_height;
          // doc.querySelector('#js-legend_iebe').style['height'] =legend_configuration.chart_legend_height;
        }
        if (doc.querySelector('.responsive-table')) {
          doc.querySelector('.responsive-table').style['overflow'] = 'auto';
          doc.querySelector('.responsive-table').style['display'] = 'block';
        }
      }
    }
  });

  return (
    <Button
      variant="secondary"
      className="d-flex align-items-center justify-content-center"
      onClick={handlePrint}
      disabled={disabled}
    >
      <div className="mr-3">
        <IoPrintOutline fontSize={17} />
      </div>
      <div>{t('Print')}</div>
    </Button>
  );
};
export default PrintButton;
