import React, { useEffect } from 'react';
import { Col, Container, Row, Spinner, Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import PageHeader from '../../components/PageHeader';
import { LoadingState } from '../../utilities/constants';
import { chunkArray } from '../../utilities/formatting';
import { fetchBusinessMeasures, selectBsnsMeasureById } from './businessMeasuresSlice';
import { useTranslation } from 'react-i18next';

const ViewBusinessMeasure = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { bsnsMeasureId } = useParams();
  const bsnsMeasure = useSelector((state) => selectBsnsMeasureById(state, bsnsMeasureId));
  const bsnsMeasureState = useSelector((state) => state.businessMeasures.status);
  const isBusy = bsnsMeasureState === LoadingState.loading;
  const orgId = useSelector((state) => state.users.user.usrOrgId);

  const displayItems = bsnsMeasure
    ? [
        {
          label: t('Business Measure Id'),
          value: bsnsMeasure.bsnsmsrtypAssignedId
        },
        { label: t('Business Measure Name'), value: bsnsMeasure.bsnsmsrtypName },
        { label: t('Description'), value: bsnsMeasure.bsnsmsrtypDesc }
      ]
    : [];

  const itemRows = chunkArray(displayItems, 2);

  useEffect(() => {
    if (bsnsMeasureState === LoadingState.idle) {
      dispatch(fetchBusinessMeasures({ orgId }));
    }
  }, [orgId, bsnsMeasureState, dispatch]);

  return (
    <Container fluid>
      {isBusy && <Spinner animation="grow" />}
      {!isBusy && bsnsMeasure && (
        <>
          <Row>
            <Col>
              <PageHeader
                title={t('View Business Measure')}
                enableBackButton={true}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={8}>
              <Tabs defaultActiveKey="details" id={`${bsnsMeasure.id}-tabs`} className="custom-tab-bar">
                <Tab
                  eventKey="details"
                  title={t('Business Measure Details')}
                  className="custom-tab-content"
                >
                  {itemRows.map((row, i) => (
                    <Row key={i} className="mt-4">
                      {row.map((col) => (
                        <Col md={6} key={col.label}>
                          <div className="data-view">
                            <label className="label">{col.label}</label>
                            <div className="data">{col.value || '-'}</div>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  ))}
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default ViewBusinessMeasure;
