import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { isAuthorized } from '../app/userImpersonate';

function PrivateRoute({ component: Component, roles, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => {
        return isAuthorized(roles)
          ? <Component {...props} />
          : <Redirect to="/" />
      }}
    />
  );
}

export default PrivateRoute;
