import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { LoadingState } from '../../utilities/constants';
import {
  fetchAccountsApi,
  removeAccountApi,
  assignUserApi,
  fetchAssignedUsersApi,
  fetchAvailableUsersApi,
  unAssignUserApi,
  fetchAssignedEntitiesApi,
  fetchAvailableEntitiesApi,
  unAssignEntityApi,
  assignEntityApi
} from './accountsApi';

export const fetchAccounts = createAsyncThunk('accounts/fetchAccounts', async (payload) => {
  try {
    const response = await fetchAccountsApi(payload.orgId);
    return response;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const removeAccount = createAsyncThunk('accounts/removeAccount', async (payload, { dispatch }) => {
  try {
    const account = await removeAccountApi(payload.orgId, payload.accountId);
    return account;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const fetchAssignedUsers = createAsyncThunk('accounts/fetchAssignedUsers', async (payload) => {
  try {
    const response = await fetchAssignedUsersApi(payload.orgId, payload.accountId);
    return response;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const fetchAvailableUsers = createAsyncThunk('accounts/fetchAvailableUsers', async (payload) => {
  try {
    const response = await fetchAvailableUsersApi(payload.orgId, payload.accountId);
    return response;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const assignUser = createAsyncThunk('accounts/assignUser', async (payload, { dispatch }) => {
  const { orgId, accountId, userId } = payload;
  try {
    await assignUserApi(orgId, accountId, { userId });
    await dispatch(fetchAssignedUsers({ orgId, accountId }));
    await dispatch(fetchAvailableUsers({ orgId, accountId }));
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const unAssignUser = createAsyncThunk('accounts/unAssignUser', async (payload, { dispatch }) => {
  const { orgId, accountId, userId } = payload;
  try {
    await unAssignUserApi(orgId, accountId, userId);
    await dispatch(fetchAssignedUsers({ orgId, accountId }));
    await dispatch(fetchAvailableUsers({ orgId, accountId }));
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const fetchAssignedEntities = createAsyncThunk('accounts/fetchAssignedEntities', async (payload) => {
  try {
    const response = await fetchAssignedEntitiesApi(payload.orgId, payload.accountId);
    return response;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const fetchAvailableEntities = createAsyncThunk('accounts/fetchAvailableEntities', async (payload) => {
  try {
    const response = await fetchAvailableEntitiesApi(payload.orgId, payload.accountId);
    return response;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const assignEntity = createAsyncThunk('accounts/assignEntity', async (payload, { dispatch }) => {
  const { orgId, accountId, entityId } = payload;
  try {
    await assignEntityApi(orgId, accountId, { entityId });
    await dispatch(fetchAssignedEntities({ orgId, accountId }));
    await dispatch(fetchAvailableEntities({ orgId, accountId }));
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const unAssignEntity = createAsyncThunk('accounts/unAssignEntity', async (payload, { dispatch }) => {
  const { orgId, accountId, entityId } = payload;
  try {
    await unAssignEntityApi(orgId, accountId, entityId);
    await dispatch(fetchAssignedEntities({ orgId, accountId }));
    await dispatch(fetchAvailableEntities({ orgId, accountId }));
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

const accountsSlice = createSlice({
  name: 'accounts',
  initialState: {
    accounts: [],
    assignedUsers: [],
    availableUsers: [],
    assignedEntities: [],
    availableEntities: [],
    status: LoadingState.idle,
    error: null
  },
  reducers: {},
  extraReducers: {
    [fetchAccounts.pending]: (state) => {
      state.status = LoadingState.loading;
    },
    [fetchAccounts.fulfilled]: (state, action) => {
      state.status = LoadingState.succeeded;
      state.accounts = action.payload;
    },
    [fetchAccounts.rejected]: (state, action) => {
      state.status = LoadingState.failed;
      state.error = action.error.message;
    },
    [fetchAssignedUsers.fulfilled]: (state, action) => {
      state.assignedUsers = action.payload;
    },
    [fetchAvailableUsers.fulfilled]: (state, action) => {
      state.availableUsers = action.payload;
    },
    [fetchAssignedEntities.fulfilled]: (state, action) => {
      state.assignedEntities = action.payload;
    },
    [fetchAvailableEntities.fulfilled]: (state, action) => {
      state.availableEntities = action.payload;
    },
  }
});

export const selectAccountById = (state, accountId) => {
  if (!state.accounts.accounts?.length === 0) return null;
  const parsedId = parseInt(accountId);
  const found = state.accounts.accounts.find((a) => a.acntId === parsedId);
  return found ?? null;
};

export default accountsSlice.reducer;
