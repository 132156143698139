import { useState } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import { recursiveMap } from '../../../utilities/treeHelpers';
import TreeViewModal from '../../../components/TreeViewModal';
import NodeIcon from '../../../components/NodeIcon';
import { useTranslation } from 'react-i18next';

const EmissionSourceRelevant = ({ setValue, tree, selectedRelEpsNode, setSelectedRelEpsNode }) => {
  const { t } = useTranslation();

  const [showTreeSelector, setShowTreeSelector] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [prevSelectedRelEpsNode, setPrevSelectedRelEpsNode] = useState(null);

  const openModal = () => setShowTreeSelector(true);
  const closeModal = () => setShowTreeSelector(false);
  const openConfirmationModal = () => setShowConfirmationModal(true);
  const closeConfirmationModal = () => setShowConfirmationModal(false);

  const handleChangeRelEpsNode = (n) => {
    const values = Object.keys(n).map((key) => ({ name: key, value: n[key] }));
    values.forEach(({ name, value }) => setValue(name, value));
    setValue('relEps', 'Y');
    setValue('relEpsId', n.eprflsrcId, { shouldDirty: true });
    setValue('eprflsrcName', n.eprflsrcName);
    setValue('eprflsrcAssignedId', n.eprflsrcAssignedId);
    setValue('showGeneralEmissionFromEps', false);
    if (!n.eprflsrcEsrcId) {
      setValue('custom', 'Y');
      if (n.eprflsrcConversionFactortj) {
        setValue('gas', 'N');
      } else {
        setValue('gas', 'Y');
        setValue('eprflsrcConversionFactor', '');
        setValue('eprflsrcConversionFactortj', '');
        setValue('eprflsrcUncertainty', '');
      }
    } else {
      setValue('eprflsrcConsumptionUnit', '');
      setValue('eprflsrcConversionFactor', '');
      setValue('eprflsrcConversionFactortj', '');
      setValue('eprflsrcUncertainty', '');
      setValue('eprflsrcCo2ConversionFactor', '');
      setValue('eprflsrcCo2Uncertainty', '');
      setValue('eprflsrcCh4ConversionFactor', '');
      setValue('eprflsrcCh4Uncertainty', '');
      setValue('eprflsrcN2oConversionFactor', '');
      setValue('eprflsrcN2oUncertainty', '');
      setValue('eprflsrcSf6ConversionFactor', '');
      setValue('eprflsrcSf6Uncertainty', '');
      setValue('eprflsrcNf3ConversionFactor', '');
      setValue('eprflsrcNf3Uncertainty', '');
      setValue('eprflsrcHfcConversionFactor', '');
      setValue('eprflsrcHfcGwp', '');
      setValue('eprflsrcHfcUncertainty', '');
      setValue('eprflsrcPfcConversionFactor', '');
      setValue('eprflsrcPfcGwp', '');
      setValue('eprflsrcPfcUncertainty', '');
      setValue('custom', 'N');
      setValue('gas', 'N');

      // Set the 'relEps' field so that selected general emission source data can be set
      setValue('showGeneralEmissionFromEps', true);
    }
    setValue('linked', 'N');
    setValue('eprflsrcLinkedId', false);
    closeModal();
  };

  const transformedTree = recursiveMap(tree ?? {}, (n) => {
    if (n.eprflId) {
      return {
        weight: n.weight,
        value: n.eprflId,
        labelRaw: n.eprflName,
        label: <span>{n.eprflName}</span>,
        isActive: false,
        linked: true
      };
    }
    if (n.eprflcatId) {
      return {
        weight: n.weight,
        value: n.eprflcatId,
        labelRaw: n.eprflcatName,
        label: (
          <span>
            <NodeIcon node={n} />
            {n.eprflcatName}
          </span>
        ),
        isActive: false,
        linked: true
      };
    }
    if (n.eprflsrcId) {
      return {
        weight: n.weight,
        value: n.eprflsrcId,
        labelRaw: n.eprflsrcName,
        label: (
          <span
            onClick={() => {
              openConfirmationModal();
              setPrevSelectedRelEpsNode(selectedRelEpsNode);
              setSelectedRelEpsNode(n);
              closeModal();
            }}
          >
            <NodeIcon node={n} />
            {n.eprflsrcName}
          </span>
        ),
        valueId: n.eprflsrcAssignedId,
        isActive: selectedRelEpsNode?.eprflsrcId === n.eprflsrcId
      };
    }
  });
  transformedTree.isRoot = true;

  return (
    <>
      <Form.Group className="custom-form-control">
        <Form.Label>{t('Relevant Emission Profile Source')}</Form.Label>
        <div>
          <Button onClick={openModal} variant="outline-secondary">
            {selectedRelEpsNode ? t('Select') : t('Select')}
          </Button>
          {selectedRelEpsNode && <span className="ml-3">{selectedRelEpsNode.eprflsrcName}</span>}
        </div>
      </Form.Group>
      <TreeViewModal
        tree={transformedTree}
        closeModal={closeModal}
        show={showTreeSelector}
        title={t('Select Emission Profile')}
      />
      <Modal show={showConfirmationModal} onHide={closeConfirmationModal} backdrop="static">
        <Modal.Header>
          <Modal.Title>{t('Relevant Emission Profile')}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: 'justify' }}>
          Please select the appropriate Emission Standard, Emission Source Type and Emission Source for your new
          Emission Profile. You may add new Emission Source Name and Emission Source ID as your wish. Please note that
          the assigned entities and the assets from the previous Emission Profile will be copied across automatically.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              if (prevSelectedRelEpsNode) {
                setSelectedRelEpsNode(prevSelectedRelEpsNode);
              } else {
                setSelectedRelEpsNode(null);
              }
              closeConfirmationModal();
            }}
          >
            {t('Cancel')}
          </Button>
          <Button
            onClick={() => {
              handleChangeRelEpsNode(selectedRelEpsNode);
              closeConfirmationModal();
            }}
            variant="primary"
          >
            {t('Ok')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EmissionSourceRelevant;
