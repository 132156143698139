import { useEffect, useState } from 'react';
import { Form, Button, Col } from 'react-bootstrap';
import { useWatch, Controller } from 'react-hook-form';
import { recursiveMap } from '../../../utilities/treeHelpers';
import TreeViewModal from '../../../components/TreeViewModal';
import NodeIcon from '../../../components/NodeIcon';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { fetchSingleEmissionSourceData } from '../emissionProfileSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const EmissionSourceLink = ({ control, setValue, tree, selectedEmissionSource, emissionProfile }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [showTreeSelector, setShowTreeSelector] = useState(false);
  const [selectedNode, setSelectedNode] = useState(null);

  const openModal = () => setShowTreeSelector(true);
  const closeModal = () => setShowTreeSelector(false);

  const eprflsrcLinkedId = useWatch({
    control,
    name: 'eprflsrcLinkedId'
  });

  const linked = useWatch({
    control,
    name: 'linked'
  });

  const handleChangeLinkedEps = async (n) => {
    const isCustomProfile = emissionProfile?.eprflsrcEsrcId === null;
    // Parent - Has emission sources          Child - Custom EPS
    // Parent - Has emission sources          Child - Has emission sources

    // Parent - Custom EPS -                  Child - Has emission sources
    // Parent - Custom EPS                    Child - Custom EPS
    if (isCustomProfile) {
      if (n?.eprflsrcConsumptionUnit === emissionProfile?.eprflsrcConsumptionUnit) {
        setValue('eprflsrcLinkedId', n.eprflsrcId, { shouldDirty: true });
        setSelectedNode(n);
        closeModal();
      } else {
        toast.error('No relevant emission factor found for given profile and unit');
      }

      if (emissionProfile?.eprflsrcEsrcId) {
        const epsEsrcId = emissionProfile.eprflsrcEsrcId;
        const resultAction = await dispatch(fetchSingleEmissionSourceData({ epsEsrcId }));
        const selectedES = unwrapResult(resultAction);
        const factor = selectedES.factors?.find((f) => f.unit === n.eprflsrcConsumptionUnit);
        if (!factor) {
          toast.error('No relevant emission factor found for given profile and unit');
        } else {
          setValue('eprflsrcLinkedId', n.eprflsrcId, { shouldDirty: true });
          setSelectedNode(n);
          closeModal();
        }
      }
    } else {
      if (selectedEmissionSource && n?.eprflsrcEsrc?.eprflsrcEsrcFactors) {
        const resultAction = await dispatch(fetchSingleEmissionSourceData({ selectedEmissionSource }));
        const selectedES = unwrapResult(resultAction);
        const contributionUnit = selectedES.factors.filter((obj1) =>
          n.eprflsrcEsrc?.eprflsrcEsrcFactors?.some((obj2) => obj1.unit === obj2.unit)
        );
        if (contributionUnit.length === 0) {
          toast.error('No relevant emission factor found for given profile and unit');
        } else {
          setValue('eprflsrcLinkedId', n.eprflsrcId, { shouldDirty: true });
          setSelectedNode(n);
          closeModal();
        }
      } else {
        if (selectedEmissionSource) {
          const resultAction = await dispatch(fetchSingleEmissionSourceData({ selectedEmissionSource }));
          const selectedES = unwrapResult(resultAction);
          const factor = selectedES.factors?.find((f) => f.unit === n.eprflsrcConsumptionUnit);
          if (!factor) {
            toast.error('No relevant emission factor found for given profile and unit');
          } else {
            setValue('eprflsrcLinkedId', n.eprflsrcId, { shouldDirty: true });
            setSelectedNode(n);
            closeModal();
          }
        } else {
          toast.error('No relevant emission factor found for given profile and unit');
        }
      }
    }
  };
  const fullFuelCycleTree = {
    ...tree,
    children: tree?.children.filter(category => category.eprflcatId === tree.fullFuelCycleScopeId),
  };
  const recursiveTree = recursiveMap(fullFuelCycleTree, (n) => {
    if (n.eprflId) {
      return {
        weight: n.weight,
        value: n.eprflId,
        labelRaw: n.eprflName,
        label: <span>{n.eprflName}</span>,
        isActive: false
      };
    }
    if (n.eprflcatId) {
      return {
        weight: n.weight,
        value: n.eprflcatId,
        labelRaw: n.eprflcatName,
        label: (
          <span>
            <NodeIcon node={n} />
            {n.eprflcatName}
          </span>
        ),
        isActive: false
      };
    }
    if (n.eprflsrcId) {
      return {
        weight: n.weight,
        value: n.eprflsrcId,
        labelRaw: n.eprflsrcName,
        label: (
          <span onClick={() => handleChangeLinkedEps(n)}>
            <NodeIcon node={n} />
            {n.eprflsrcName}
          </span>
        ),
        isActive:
          (typeof eprflsrcLinkedId === 'object' && eprflsrcLinkedId !== null
            ? eprflsrcLinkedId.eprflsrcId
            : eprflsrcLinkedId) === n.eprflsrcId
      };
    }
  });
  recursiveTree.isRoot = true;

  useEffect(() => {
    if (typeof eprflsrcLinkedId === 'object' && eprflsrcLinkedId !== null) {
      setSelectedNode(eprflsrcLinkedId);
      setValue('eprflsrcLinkedId', eprflsrcLinkedId.eprflsrcId);
    }
  }, [eprflsrcLinkedId, setValue]);

  return (
    <>
      <Col>
        <Form.Group className="custom-form-control">
          <Controller
            name="linked"
            control={control}
            render={({ field }) => {
              const parsed = field.value === 'Y' ? true : false;
              const format = () => {
                field.onChange(field.value === 'Y' ? 'N' : 'Y');
                setValue('eprflsrcLinkedId', null);
                setSelectedNode(null);
              };
              return (
                <Form.Check
                  custom
                  id="linked"
                  onChange={format}
                  checked={parsed}
                  label={t('Full Fuel Cycle')}
                />
              );
            }}
          />
        </Form.Group>
      </Col>
      {linked === 'Y' && (
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Linked EPS Full Fuel Cycle')}</Form.Label>
            <div>
              <Button onClick={openModal} variant="outline-secondary">
                {selectedNode ? t('Select') : t('Select')}
              </Button>
              {selectedNode && <span className="ml-3">{selectedNode.eprflsrcName}</span>}
            </div>
          </Form.Group>
          <TreeViewModal
            tree={recursiveTree}
            closeModal={closeModal}
            show={showTreeSelector}
            title={t('Select Emission Profile')}
           />
        </Col>
      )}
    </>
  );
};

export default EmissionSourceLink;
