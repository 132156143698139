import { Fragment, useState } from 'react';
import { Button } from 'react-bootstrap';
import { SDGSocialShareModal } from './SDGSocialShareModal';
import { FaShare } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

export function GoalAchievedComponent({ goalNo, goalName, kpis }) {
  const { t } = useTranslation();
  const [showSDGSocialShareModal, setShowSDGSocialShareModal] = useState(false);

  const onClickShare = () => {
    setShowSDGSocialShareModal(true);
  };

  const isGoalAchieved = (kpis) => {
    let selectedKpis = kpis.filter((kpi) => kpi?.isApplicable === 'Yes');
    let achievedKpis = kpis.filter((kpi) => kpi?.latestRecord?.rating === '7' && kpi?.isApplicable === 'Yes');
    if (selectedKpis.length === achievedKpis.length && selectedKpis.length !== 0) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Fragment>
      {kpis && isGoalAchieved(kpis) && (
        <Fragment>
          <div className='success-share mt-3'>
            <div className='goal-achieved'>{t('Goal Achieved')}</div>
            <Button variant='secondary' className='share-btn' onClick={onClickShare}>
              <FaShare /> {t('Share')}
            </Button>
          </div>

          <SDGSocialShareModal
            setShowSDGSocialShareModal={setShowSDGSocialShareModal}
            showSDGSocialShareModal={showSDGSocialShareModal}
            goalNo={goalNo}
            goalName={goalName}
          />
        </Fragment>
      )}
    </Fragment>
  );
}
