import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { Col, Spinner, Card } from 'react-bootstrap';
import { LoadingState } from '../../../utilities/constants';
import { useDispatch, useSelector } from 'react-redux';
import { HorizontalBar } from 'react-chartjs-2';
import Empty from '../../../components/Empty';
import { fetchEmissionsByTop10EPSDashboard } from '../dashboardSlice';
import DashboardPrintButton from '../components/DashboardPrintButton';
import {
  formatNumber,
  createInitChartPlugins,
  createInitChartConfig,
  generateRandomColors,
} from '../../reports/reportHelper';
import { useTranslation } from 'react-i18next';

const DashboardEmissionsByTop10EPS = ({ filters, currentReportPeriod }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [data, setData] = useState({});
  const reportYearType = currentReportPeriod.reportYearType;
  const orgId = useSelector((state) => state.users.user.usrOrgId);
  const loggedUserStatus = useSelector((state) => state.users.loggedUserStatus);
  const emissionsByTop10EPS = useSelector((state) => state.dashboard.emissionsByTop10EPSDashboard);
  const emissionsByTop10EPSStatus = useSelector((state) => state.dashboard.emissionsByTop10EPSStatusDashboard);
  const emissionsByTop10EPSError = useSelector((state) => state.dashboard.eTop10epsError);
  const chartRef = useRef(null);
  const dataRef = useRef(null);

  const plugins = createInitChartPlugins([], {
    displayChartAxesTotal: () => data.datasets && data.datasets.length > 0 &&
      emissionsByTop10EPSStatus === LoadingState.succeeded
  });

  const getLabels = useCallback(() => {
    const labels = [];
    const data = [];
    if (emissionsByTop10EPSStatus === LoadingState.succeeded) {
      const insert = (arr, index, newItem) => {
        return [...arr.slice(0, index), newItem, ...arr.slice(index, emissionsByTop10EPS.length - 1)];
      };
      generateRandomColors(true);
      emissionsByTop10EPS.map((item, itemIndex) => {
        if (labels.includes(item.epsName) === false) {
          labels.push(item.epsName);
          item.children.map((entity) => {
            const dataList = new Array(emissionsByTop10EPS.length - 1).fill(0);
            const foundIndex = data.findIndex((x) => x.label === entity.d_name);
            if (foundIndex === -1) {
              data.push({
                axis: 'y',
                label: entity.d_name,
                data: insert(
                  dataList,
                  itemIndex,
                  entity.co2_et
                    ? !isNaN(entity.co2_et)
                      ? parseFloat(entity.co2_et)
                      : 0
                    : !isNaN(entity.consumption)
                      ? parseFloat(entity.consumption)
                      : 0
                ),
                backgroundColor: generateRandomColors(),
                epsName: item.epsName
              });
            } else {
              data[foundIndex].epsName !== item.epsName
                ? (data[foundIndex].data = insert(
                  data[foundIndex].data,
                  itemIndex,
                  entity.co2_et
                    ? !isNaN(entity.co2_et)
                      ? parseFloat(entity.co2_et)
                      : 0
                    : !isNaN(entity.consumption)
                      ? parseFloat(entity.consumption)
                      : 0
                ))
                : (data[foundIndex].data[itemIndex] =
                  data[foundIndex].data[itemIndex] + parseFloat(entity.co2_et ? entity.co2_et : entity.consumption));
            }
            return data;
          });
        }
        return labels;
      });
    }
    data.forEach((item) => {
      const total = _.sum(item.data);
      item.title = item.label;
      item.label = t('{{label}}: {{number}} tCO2e', { label: item.label, number: formatNumber(total) });
    });
    return { labels, datasets: data };
  }, [t, emissionsByTop10EPSStatus, emissionsByTop10EPS]);

  useEffect(() => {
    if (emissionsByTop10EPSStatus === LoadingState.loading) {
      setData({});
    }

    if (emissionsByTop10EPSStatus === LoadingState.succeeded) {
      setData(getLabels());
    }
  }, [orgId, emissionsByTop10EPSStatus, emissionsByTop10EPS, getLabels, dispatch, filters]);

  useEffect(() => {
    const data = {
      reportYearType: reportYearType,
      reportYear: filters.year,
      emissionTypeId: 1,
      entitiesIdsToInclude: filters.selectedEntity,
      epsIdsToInclude: [],
      isApplyExcludedEPSs: true,
    };
    if (orgId && loggedUserStatus === LoadingState.succeeded) {
      try {
        dispatch(fetchEmissionsByTop10EPSDashboard({ orgId, data }));
      } catch (e) {
        toast.error(e.message);
      }
    }
  }, [filters, orgId, dispatch, loggedUserStatus, reportYearType]);

  const options = useMemo(() => {
    const totalArray = [];
    if (data.datasets && data.datasets.length > 0 && emissionsByTop10EPSStatus === LoadingState.succeeded) {
      data.datasets[0].data.map((dataPoint, index) => {
        let total = dataPoint;
        let i = 1;
        while (i < data.datasets.length) {
          total += data.datasets[i].data[index];
          i++;
        }
        return totalArray.push(total);
      });
    }
    return createInitChartConfig({
      maintainAspectRatio: true,
      interaction: {
        mode: 'interpolate',
        intersect: false,
        axis: 'x'
      },
      layout: {
        padding: {
          bottom: 25
        }
      },
      scales: {
        xAxes: [
          {
            stacked: true,
            ticks: {
              beginAtZero: true,
              suggestedMax: Math.max(...totalArray) + (8 / 100) * Math.max(...totalArray),
            },
            scaleLabel: {
              display: true,
              labelString: t('Total - CO2-e (t)')
            }
          }
        ],
        yAxes: [
          {
            stacked: true,
            gridLines: {
              display: false
            },
            ticks: {
              callback: function (value) {
                return _.truncate(value, { length: 30 });
              },
            },
          }
        ]
      },
      tooltips: {
        filter: function (tooltipItem, data) {
          let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return value !== 0;
        },
        callbacks: {
          label: (tooltipItem, data) => {
            const { title } = data.datasets[tooltipItem.datasetIndex];
            return t('{{label}}: {{number}} tCO2e', { label: title, number: formatNumber(tooltipItem.value) });
          }
        },
      },
    });
  }, [t, data.datasets, emissionsByTop10EPSStatus]);

  return (
    <Col lg="6">
      <Card>
        <Card.Body>
          {/* <Card.Title>Top 10 Emission Sources by Entity</Card.Title> */}
          <div className="mt-2 mb-4 chart_print_wrapper" id="dashboard-top-10-eps-legend-container" ref={dataRef}>
            <div className="dashboard-report-header">
              <strong className="report-text">{t('Top Ten Emission Sources by Entity')}</strong>
              <div className="button-wrapper">
                <DashboardPrintButton
                  title={t("Top 10 Emission Sources by Entity")}
                  id="top10eps_chart"
                  downloadRef={dataRef}
                  disabled={emissionsByTop10EPSError ? true : false}
                  chartLegend="dashboard-top-10-eps-legend"
                />
              </div>
            </div>
            {emissionsByTop10EPSStatus === LoadingState.loading && <Spinner className="loader" animation="grow" />}
            {emissionsByTop10EPSStatus === LoadingState.succeeded && emissionsByTop10EPS.length > 0 && (
              // <div id="eTop10eps_chart" className="chart_print_wrapper" ref={dataRef}>
              <>
                <HorizontalBar width={600} height={415} data={data} options={options} ref={chartRef} plugins={plugins} />
                <div id="dashboard-top-10-eps-legend" className="chart-legend" style={{ display: 'none' }}></div>
              </>
              // </div>
            )}
            {emissionsByTop10EPSStatus === LoadingState.failed && emissionsByTop10EPSError && (
              <Empty title={emissionsByTop10EPSError} />
            )}
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default DashboardEmissionsByTop10EPS;
