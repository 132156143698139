import React, { useRef, useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Col, Container, Row } from 'react-bootstrap';
import { Select } from '../reports/configurations/Select';
import PageHeader from '../../components/PageHeader';
import Configuration from './Configuration';
import { TreeViewSelector } from '../reports/configurations/TreeViewSelector';
import DashboardMovingAverage from './dashboard-report-components/DashboardMovingAverage';
import DashboardEmissionsByTop10EPS from './dashboard-report-components/DashboardEmissionsByTop10EPS';
import { useTranslation } from 'react-i18next';
import DashboardEmissionFootprintOverviewByEntity from './dashboard-report-components/DashboardEmissionFootprintOverviewByEntity';
import DashboardCarbonFoorprintByScope from './dashboard-report-components/DashboardCarbonFootprintByScope';
import { LoadingState } from '../../utilities/constants';
import { fetchOrganizationEmissionSummary, fetchOrganizationListYears, fetchScopesDashboard } from './dashboardSlice';
import {
  formatNumber,
  getCurrentReportPeriod,
  getReportRangePeriod,
} from '../reports/reportHelper';
import { fetchEntityTree } from '../entity/entitySlice';
import _ from 'lodash';
import { parseUserInfo } from '../../utilities/common';

function Dashboard() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dataRef = useRef(null);
  const { orgId, usrDateFormat, usrTimezone, ...userInfo } = parseUserInfo(useSelector((state) => state.users.user));
  const currentReportPeriod = getCurrentReportPeriod(userInfo.usrOrgOptions);
  const [filters, setfilters] = useState({ year: currentReportPeriod.reportYear, selectedEntity: [] });
  const [reportRangePeriod, setReportRangePeriod] = useState(getReportRangePeriod(userInfo.usrOrgOptions, filters.year));
  const [selectedEntity, setSelectedEntity] = useState([]);
  const loggedUserStatus = useSelector((state) => state.users.loggedUserStatus);
  const entityTree = useSelector((state) => state.entities.tree);
  const organizationEmissionSummary = useSelector(
    (state) => state.dashboard.organizationEmissionSummary
  );
  const organizationListYears = useSelector(
    (state) => state.dashboard.organizationEmissionListYears
  );
  const organizationEmissionSummaryStatus = useSelector(
    (state) => state.dashboard.organizationEmissionSummaryStatus
  );
  const reportYearList = () => {
    const currentYear = currentReportPeriod.reportYear;
    if (organizationListYears.length) {
      const yearList = organizationListYears
        .map(item => ({
          label: item.target_year,
          value: Number(item.target_year),
        }))
        .filter(item => item.value <= currentYear);
      if (!yearList.find(item => item.value === currentReportPeriod.reportYear)) {
        yearList.push({ label: currentReportPeriod.reportYear, value: currentReportPeriod.reportYear });
      }
      return _.orderBy(yearList, 'value', 'desc');
    }
    const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);
    const yearList = range(currentYear, currentYear - 4, -1);
    const reportYears = [];
    yearList.map((item) => {
      return reportYears.push({
        label: item,
        value: item
      });
    });
    return reportYears;
  };
  useEffect(() => {
    if (orgId) {
      try {
        const listYearsData = {
          currentDate: new Date(),
          reportYearType: currentReportPeriod.reportYearType,
          reportPeriodEnding: currentReportPeriod.reportPeriodEnding,
        };
        const summaryData = {
          usrDateFormat,
          usrTimezone,
          startDate: reportRangePeriod.startDate,
          endDate: reportRangePeriod.endDate,
        };
        dispatch(fetchOrganizationEmissionSummary({ orgId, data: summaryData }));
        dispatch(fetchOrganizationListYears({ orgId, data: listYearsData }));
        dispatch(fetchEntityTree({ orgId }));
        dispatch(fetchScopesDashboard(orgId));
      } catch (e) {
        toast.error(e.message);
      }
    }
  }, [
    orgId,
    dispatch,
    currentReportPeriod.reportYearType,
    currentReportPeriod.reportPeriodEnding,
    reportRangePeriod.startDate,
    reportRangePeriod.endDate,
    usrTimezone,
    usrDateFormat,
  ]);

  const onSubmitHandler = async (values) => {
    setReportRangePeriod(getReportRangePeriod(userInfo.usrOrgOptions, values.Year));
    setfilters({ year: values.Year, selectedEntity: selectedEntity });
  };

  const totalEmission = useMemo(() => {
    if (
      organizationEmissionSummaryStatus === LoadingState.succeeded &&
      organizationEmissionSummary?.length
    ) {
      return {
        target: organizationEmissionSummary.find(item => item.key === 'target')?.data?.co2_e_t,
        lifetime: organizationEmissionSummary.find(item => item.key === 'lifetime')?.data?.co2_e_t,
      };
    }
    return { target: 0, lifetime: 0 };
  }, [organizationEmissionSummaryStatus, organizationEmissionSummary]);

  return (
    <Container fluid className="mt-3 mb-5 dashboard">
      <Col>
        <PageHeader.Reports title={t('Dashboard')} enableBackButton={false} />
        <Row>
          <Col>
            <Configuration onSubmit={onSubmitHandler} primaryBtnText={t('Generate Report')}>
              <Select label={t("Year")} name="Year" options={reportYearList()} />
              <TreeViewSelector
                label={t("Entity")}
                options={entityTree}
                selectedEntity={selectedEntity}
                setSelectedEntity={setSelectedEntity}
              />
            </Configuration>
          </Col>
          <Col>
            <div className="configuration-form-control">
              <div className="configuration-row" style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                <h4>{t('Total Emissions')}</h4>
                <div style={{ display: 'flex' }}>
                  <div className="configuration-col">
                    <div className="custom-form-control form-group">
                      <label className="form-label">
                        {t('{{year}}: {{value}} tCO2e', { year: filters.year, value: formatNumber(totalEmission.target) })}
                      </label>
                    </div>
                  </div>
                  <div className="configuration-col">
                    <div className="custom-form-control form-group">
                      <label className="form-label">
                        {t('Lifetime: {{value}} tCO2e', { value: formatNumber(totalEmission.lifetime) })}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        {loggedUserStatus === LoadingState.succeeded && (
          <div id="dashboard_charts" ref={dataRef}>
            <Row className="mt-3">
              <DashboardEmissionFootprintOverviewByEntity
                filters={filters}
                currentReportPeriod={currentReportPeriod}
                reportRangePeriod={reportRangePeriod}
              />
              <DashboardEmissionsByTop10EPS
                filters={filters}
                currentReportPeriod={currentReportPeriod}
              />
            </Row>
            <Row className="mt-3">
              <DashboardCarbonFoorprintByScope
                reportRangePeriod={reportRangePeriod}
                filters={filters}
              />
              <DashboardMovingAverage filters={filters} />
            </Row>
          </div>
        )}
      </Col>
    </Container>
  );
}

export default Dashboard;
