import React from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import gettingImage from '../../../assets/images/coriolis/getting.png';
import { useTranslation } from 'react-i18next';
import GetStartedNow from './components/GetStartedNow';

const GettingStarted = () => {
  const { t } = useTranslation();
  return (
    <Row className="align-items-center coriolis-section">
      <Col md={5}>
        <h2>{t('AUTOMATED ESG SCORING FOR YOUR BUSINESS')}</h2>
        <p>
          <em>
            {t('Empower businesses to achieve sustainability goals and mitigate risks via ESG performance measurement and management.')}
          </em>
        </p>
        <GetStartedNow />
      </Col>
      <Col md={7}>
        <Image src={gettingImage} alt="Showing ESG report" fluid />
      </Col>
    </Row>
  );
};

export default GettingStarted;
