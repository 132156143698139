import _ from 'lodash';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { formatDate } from '../../../utilities/common';

function Parameters({ props }) {
  return (
    <Col md={8} className="parameters-section ml-2">
      <div className="ml-3 mt-4">
        {props.map((param, i) => (
          <Row key={i} className="mb-4">
            <Col md={2}>
              <span className="font-weight-bold">{param.key} :</span>
            </Col>
            <Col md={9} >
              <span style={{ 'letterSpacing': 0.30 }}>
                {_.isDate(param.value) ? formatDate(param.value) : param.value}
              </span>
            </Col>
          </Row>
        ))}
      </div>
    </Col>
  );
}

export default Parameters;
