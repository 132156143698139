import React, { useState } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import SecondarySidebar from '../../components/Sidebar/SecondarySidebar';
import EditAsset from '../assets/EditAsset';
import ManageAssets from '../assets/ManageAssets';
import NewAsset from '../assets/NewAsset';
import ViewAsset from '../assets/ViewAsset';
import EditBusinessMeasure from '../business-measure/EditBusinessMeasure';
import ManageBusinessMeasures from '../business-measure/ManageBusinessMeasures';
import NewBusinessMeasure from '../business-measure/NewBusinessMeasure';
import ViewBusinessMeasure from '../business-measure/ViewBusinessMeasure';
import EditEntity from '../entity/EditEntity';
import EntityHome from '../entity/EntityHome';
import NewEntity from '../entity/NewEntity';
import ManageUsers from '../manage-users/ManageUsers';
import NewUser from '../manage-users/NewUser';
import EditUser from '../manage-users/EditUser';
import ViewUser from '../manage-users/ViewUser';
import EmissionProfileHome from '../emission-profile/EmissionProfileHome';
import EditEmissionProfileCategory from '../emission-profile/EditEmissionProfileCategory';
import NewEmissionProfileCategory from '../emission-profile/NewEmissionProfileCategory';
import NewEmissionProfileSource from '../emission-profile/NewEmissionProfileSource';
import EditEmissionProfileSource from '../emission-profile/EditEmissionProfileSource';
import RouteNotFound from '../../RouteNotFound';
import NewAccount from '../manage-accounts/NewAccount';
import EditAccount from '../manage-accounts/EditAccount';
import ViewAccount from '../manage-accounts/ViewAccount';
import ManageAccount from '../manage-accounts/ManageAccount';
import AssignUsersToSources from '../manage-users/AssignUsersToSources';
import AssignUsersToAssets from '../manage-users/AssignUsersToAssets';
import ManageEntityGroups from '../entity-groups/ManageEntityGroups';
import AssignEntitiesToEG from '../entity-groups/AssignEntitiesToEG';
import EntityGroupsHome from '../entity-groups/EntityGroupsHome';
import ManageEpsGroups from '../emission-profile-groups/ManageEpsGroups';
import EpsGroupsHome from '../emission-profile-groups/EpsGroupsHome';
import AssignEpsToEpg from '../emission-profile-groups/AssignEpsToEpg';
import ManageTranslations from '../manage-translations/ManageTranslations';
import ManageReportPeriod from '../organization/ManageReportPeriod';
import ManageReportUnit from '../organization/ManageReportUnit';
import ManageDataTemplates from '../manage-data-templates/ManageDataTemplates';
import BulkInsert from '../bulk-insert/BulkInsert';
import BillSettings from '../bill-settings/BillSettings';
import ManageReportExcluded from '../emission-profile/ManageReportExcluded';
import ManageFavouriteSchedules from '../schedule-reports/ManageFavouriteSchedules';
import NewFavouriteSchedule from '../schedule-reports/NewFavouriteSchedule';
import EditFavouriteSchedule from '../schedule-reports/EditFavouriteSchedule';

const AdminHome = () => {
  const match = useRouteMatch();
  const { t } = useTranslation();
  const [ expandedClass, setExpandedClass ] = useState('');
  const routes = [
    {
      path: `${match.url}/setup`,
      name: t('Setup'),
      subPaths: [
        { path: `${match.url}/setup/entity`, name: t('Manage Entity') },
        { path: `${match.url}/setup/assets`, name: t('Manage Assets') },
        { path: `${match.url}/setup/business-measures`, name: t('Manage Business Measures') },
        { path: `${match.url}/setup/bulk-insert`, name: t('Bulk Insert Data') },
        { path: `${match.url}/setup/bill-settings`, name: t('Manage Bill Integration') }
      ]
    },
    {
      path: `${match.url}/users`,
      name: t('Manage Users'),
      subPaths: [
        { path: `${match.url}/users/manage`, name: t('Manage Users') },
        { path: `${match.url}/users/accounts`, name: t('Manage Accounts') },
        { path: `${match.url}/users/assign-sources`, name: t('Assign users to sources') },
        { path: `${match.url}/users/assign-assets`, name: t('Assign users to assets') }
      ]
    },
    {
      path: `${match.url}/emission-profile`,
      name: t('Emission Profile'),
      subPaths: [{ path: `${match.url}/emission-profile`, name: t('Manage Emission Profile') }]
    },
    {
      path: `${match.url}/settings`,
      name: t('Global Settings'),
      subPaths: [
        { path: `${match.url}/settings/translations`, name: t('Manage Translations') },
        { path: `${match.url}/settings/data-template`, name: t('Manage Data Template') },
        {
          path: `${match.url}/settings/emission-profile-groups`,
          name: t('Manage Emission Profile Groups')
        },
        { path: `${match.url}/settings/entity-groups`, name: t('Manage Entity Group') },
        { path: `${match.url}/settings/report-period`, name: t('Manage Report Period') },
        { path: `${match.url}/settings/report-units`, name: t('Manage Report Units') },
      ]
    },
    {
      path: `${match.url}/reports`,
      name: t('Reports'),
      subPaths: [
        { path: `${match.url}/reports/scheduled`, name: t('Scheduled Reports') },
        { path: `${match.url}/reports/excluded-emission-profile-sources`, name: t('Excluded EPS') },
      ]
    }
  ];

  return (
    <div className="d-flex flex-row mw-100">
      <SecondarySidebar
        items={routes}
        onCollapse={(isExpanded) => setExpandedClass(isExpanded ? 'offset-expanded': '')}
      />
      <div className={`offset-second ${expandedClass}`}>
        <Switch>
          <Route exact path={`${match.path}/setup/entity/:entityId/edit`}>
            <EditEntity />
          </Route>
          <Route exact path={`${match.path}/setup/entity/:entityId/new`}>
            <NewEntity />
          </Route>
          <Route path={`${match.path}/setup/entity`}>
            <EntityHome />
          </Route>
          <Route exact path={`${match.path}/setup/assets/new`}>
            <NewAsset />
          </Route>
          <Route exact path={`${match.path}/setup/assets/:assetId/edit`}>
            <EditAsset />
          </Route>
          <Route exact path={`${match.path}/setup/assets/:assetId`}>
            <ViewAsset />
          </Route>
          <Route path={`${match.path}/setup/assets`}>
            <ManageAssets />
          </Route>
          <Route exact path={`${match.path}/setup/business-measures/new`}>
            <NewBusinessMeasure />
          </Route>
          <Route exact path={`${match.path}/setup/business-measures/:bsnsMeasureId/edit`}>
            <EditBusinessMeasure />
          </Route>
          <Route exact path={`${match.path}/setup/business-measures/:bsnsMeasureId`}>
            <ViewBusinessMeasure />
          </Route>
          <Route exact path={`${match.path}/reports/excluded-emission-profile-sources`}>
            <ManageReportExcluded />
          </Route>
          <Route path={`${match.path}/setup/business-measures`}>
            <ManageBusinessMeasures />
          </Route>
          <Route path={`${match.path}/setup/bulk-insert`}>
            <BulkInsert />
          </Route>
          <Route path={`${match.path}/setup/bill-settings`}>
            <BillSettings />
          </Route>
          <Route exact path={`${match.path}/users/manage/new`}>
            <NewUser />
          </Route>
          <Route exact path={`${match.path}/users/manage/:userId/edit`}>
            <EditUser />
          </Route>
          <Route exact path={`${match.path}/users/manage/:userId`}>
            <ViewUser />
          </Route>
          <Route path={`${match.path}/users/manage`}>
            <ManageUsers />
          </Route>
          <Route exact path={`${match.path}/emission-profile`}>
            <EmissionProfileHome />
          </Route>
          <Route exact path={`${match.path}/emission-profile/category/:emissionProfileCategoryId/source/new`}>
            <NewEmissionProfileSource />
          </Route>
          <Route exact path={`${match.path}/emission-profile/category/:emissionProfileCategoryId/new`}>
            <NewEmissionProfileCategory />
          </Route>
          <Route exact path={`${match.path}/emission-profile/category/:emissionProfileCategoryId/edit`}>
            <EditEmissionProfileCategory />
          </Route>
          <Route exact path={`${match.path}/emission-profile/source/:emissionProfileSourceId/edit`}>
            <EditEmissionProfileSource />
          </Route>
          <Route exact path={match.path}>
            <Redirect to={`${match.path}/setup/entity`} />
          </Route>
          <Route exact path={`${match.path}/users/accounts/new`}>
            <NewAccount />
          </Route>
          <Route exact path={`${match.path}/users/accounts/:accountId/edit`}>
            <EditAccount />
          </Route>
          <Route exact path={`${match.path}/users/accounts/:accountId`}>
            <ViewAccount />
          </Route>
          <Route path={`${match.path}/users/accounts`}>
            <ManageAccount />
          </Route>
          <Route path={`${match.path}/users/assign-assets`}>
            <AssignUsersToAssets />
          </Route>
          <Route path={`${match.path}/users/assign-sources`}>
            <AssignUsersToSources />
          </Route>
          <Route exact path={`${match.path}/settings/emission-profile-groups`}>
            <EpsGroupsHome />
          </Route>
          <Route exact path={`${match.path}/settings/emission-profile-groups/:epsGroupId`}>
            <ManageEpsGroups />
          </Route>
          <Route exact path={`${match.path}/settings/emission-profile-groups/:epsGroupId/assign-eps`}>
            <AssignEpsToEpg />
          </Route>
          <Route exact path={`${match.path}/settings/entity-groups/:egId/assign-entities`}>
            <AssignEntitiesToEG />
          </Route>
          <Route exact path={`${match.path}/settings/entity-groups/:egId`}>
            <ManageEntityGroups />
          </Route>
          <Route exact path={`${match.path}/settings/entity-groups`}>
            <EntityGroupsHome />
          </Route>
          <Route exact path={`${match.path}/settings/translations`}>
            <ManageTranslations />
          </Route>
          <Route exact path={`${match.path}/settings/report-period`}>
            <ManageReportPeriod />
          </Route>
          <Route exact path={`${match.path}/settings/report-units`}>
            <ManageReportUnit />
          </Route>
          <Route exact path={`${match.path}/settings/data-template`}>
            <ManageDataTemplates />
          </Route>
          <Route exact path={`${match.path}/reports/scheduled`}>
            <ManageFavouriteSchedules />
          </Route>
          <Route exact path={`${match.path}/reports/scheduled/create`}>
            <NewFavouriteSchedule />
          </Route>
          <Route exact path={`${match.path}/reports/scheduled/:scheduleId/edit`}>
            <EditFavouriteSchedule />
          </Route>
          
          <Route path="*">
            <RouteNotFound />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default AdminHome;
