import { Button, Form, Modal } from 'react-bootstrap';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const BaseValueForm = ({
  baseValue,
  showBaseValueModal,
  closeBaseValueModal,
  handleConfirmBaseValue,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(baseValue);

  return (
    <Modal className="sisde-modal" show={showBaseValueModal} onHide={closeBaseValueModal} animation={false}>
      <Modal.Body>
        <p>
          {t('To calculate forecast emission reduction, emission data, action plans & target must be input. Currently, emission data')} <b>{t('is not sufficient to start visualizing.')}</b>
          <br></br>
          {t('Please input a temporary base value below to start forecasting')}:
        </p>
        <Form onSubmit={() => handleConfirmBaseValue(value)}>
          <Form.Group className="custom-form-control base-value-input">
            <Form.Control
              value={value}
              onChange={(e) => setValue(e.target.value)}
              type="number"
            />
            <b>{t('tonnes of CO2-e')}</b>
          </Form.Group>
        </Form>
        <small className="text-muted">*{t('This temporary base value is only used for forecast calculation when no emission data available. It will not be saved and cannot replace/ create/ represent any emission data records.')}</small>
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" variant="danger" onClick={() => handleConfirmBaseValue(value)}>
          {t('Save')}
        </Button>
        <Button variant="secondary" onClick={closeBaseValueModal}>
          {t('Cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default BaseValueForm;
