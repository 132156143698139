import React, { useMemo, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import PageHeader from '../../components/PageHeader';
import DataTable from '../../components/DataTable';
import { unwrapResult } from '@reduxjs/toolkit';
import { useHistory } from 'react-router-dom';
import { createEpsGroup } from './epsGroupSlice';
import { useTranslation } from 'react-i18next';

const NewEpsGroup = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const assignedEpsToEpsGroup = useSelector((state) => state.epsGroups.assignedEpsToEpsGroup);
  const [showCreateEGModal, setShowCreateEGModal] = useState(false);
  const [selectedEGName, setSelectedEGName] = useState();
  const [isCreatingEG, setIsCreatingEG] = useState(false);
  const orgId = useSelector((state) => state.users.user.usrOrgId);

  const canCreateEG = selectedEGName && !isCreatingEG;

  const openCreateModal = () => setShowCreateEGModal(true);
  const closeCreateModal = () => {
    setSelectedEGName(null);
    setShowCreateEGModal(false);
  };

  const columns = useMemo(
    () => [
      {
        Header: t('Emission Profile Source'),
        accessor: 'eprflsrcName',
        sortType: 'string'
      },
      {
        Header: t('Emission Source'),
        id: 'eprflsrcEsrc',
        accessor: (row) => row.eprflsrcEsrc?.esrcName,
        sortType: 'string'
      },
      {
        Header: t('Profile Category'),
        id: 'eprflsrcEprflcat',
        accessor: (row) => row.eprflsrcEprflcat?.eprflcatName,
        sortType: 'string'
      },
      {
        Header: t('Top Level Category'),
        id: 'eprflsrcTopEprflcat',
        accessor: (row) => row.eprflsrcTopEprflcat?.eprflcatName,
        sortType: 'string'
      },
      {
        Header: t('Consumption Unit'),
        accessor: 'eprflsrcConsumptionUnit',
        sortType: 'string'
      }
    ],
    [t]
  );

  const handleCreateEpsGroup = async () => {
    setIsCreatingEG(true);
    try {
      const data = {
        epgName: selectedEGName
      };
      const resultAction = await dispatch(createEpsGroup({ orgId, data }));
      const epsGroupId = resultAction.payload.epgId;
      unwrapResult(resultAction);
      closeCreateModal();
      setSelectedEGName(null);
      history.push(`/admin/settings/emission-profile-groups/${epsGroupId}`);
    } catch (error) {
    } finally {
      setIsCreatingEG(false);
    }
  };

  return (
    <div>
      <Row>
        <Col>
          <PageHeader
            title={t('Manage Emission Profile Groups')}
            actions={
              <div className="d-flex align-items-center form-group">
                <Button onClick={openCreateModal}>
                  + {t('Emission Profile Groups')}
                </Button>
              </div>
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <DataTable columns={columns} data={assignedEpsToEpsGroup} />
        </Col>
      </Row>
      <Modal show={showCreateEGModal} onHide={closeCreateModal} className="sisde-modal">
        <Modal.Header>
          <Modal.Title>{t('New Emission Profile Group')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="custom-form-control">
              <Form.Label>{t('Group Name')} *</Form.Label>
              <Form.Control value={selectedEGName} onChange={(e) => setSelectedEGName(e.target.value)} type="text" />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeCreateModal}>
            {t('Cancel')}
          </Button>
          <Button variant="primary" onClick={handleCreateEpsGroup} disabled={!canCreateEG}>
            {t('Create')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default NewEpsGroup;
