import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { getFullDataTemplatesApi, getDataTemplatesApi, updateDataTemplatesApi } from './dataTemplatesApi';
import { LoadingState } from '../../utilities/constants';

export const getFullDataTemplates = createAsyncThunk(
  'dataTemplates/getDataTemplates',
  async (payload, { dispatch }) => {
    try {
      const response = await getFullDataTemplatesApi(payload);
      await dispatch(getCustomDataTemplates(payload));
      return response;
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  }
);

export const getCustomDataTemplates = createAsyncThunk(
  'dataTemplates/getCustomDataTemplates',
  async (payload, { dispatch }) => {
    try {
      const response = await getDataTemplatesApi(payload);
      return response;
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  }
);

export const updateDataTemplates = createAsyncThunk(
  'dataTemplates/updateDataTemplates',
  async (payload, { dispatch, getState }) => {
    try {
      const { organizationId, data } = payload;
      const response = await updateDataTemplatesApi(organizationId, data);
      return response;
    } catch (error) {
      toast.error(error.message);
      dispatch(getFullDataTemplates(payload.organizationId));
      throw error;
    }
  }
);

const dataTemplatesSlice = createSlice({
  name: 'dataTemplates',
  initialState: {
    dataTemplateItems: [],
    status: LoadingState.idle,
    updateDataTemplatesStatus: LoadingState.idle,
    customDataTemplates: [],
    error: null
  },
  reducers: {
    updateTemplateOrderUp: (state) => {
      const dataTemplateItems = state.dataTemplates.dataTemplateItems;
      const arr = dataTemplateItems.map((dti) => {
        const highestPosition = Math.max(dataTemplateItems.map((item) => item.ecsvtemplateDefaultPosition));
        if (dti.position === 1) {
          return { ecsvtemplateId: dti.ecsvtemplateId, position: highestPosition };
        }
        let newPosition = parseInt(dti.ecsvtemplateDefaultPosition) - 1;
        return { ecsvtemplateId: dti.ecsvtemplateId, position: newPosition };
      });
      state.dataTemplates.dataTemplateItems = state.dataTemplates.dataTemplateItems.map((temp) => {
        const item = arr.find((pl) => pl.id === temp.ecsvtemplateId);
        if (!!item) {
          return { ...item, ecsvtemplateDefaultPosition: item.position };
        }
        return null;
      });
    }
  },
  extraReducers: {
    [getFullDataTemplates.pending]: (state, action) => {
      state.status = LoadingState.loading;
    },
    [getFullDataTemplates.fulfilled]: (state, action) => {
      state.status = LoadingState.succeeded;
      state.dataTemplateItems = action.payload;
    },
    [getFullDataTemplates.rejected]: (state, action) => {
      state.status = LoadingState.failed;
      state.error = action.error.message;
    },
    [getCustomDataTemplates.pending]: (state, action) => {
      state.status = LoadingState.loading;
    },
    [getCustomDataTemplates.fulfilled]: (state, action) => {
      state.customDataTemplates = action.payload;
      state.status = LoadingState.succeeded;
    },
    [getCustomDataTemplates.rejected]: (state, action) => {
      state.status = LoadingState.failed;
    },
    [updateDataTemplates.pending]: (state, action) => {
      state.updateDataTemplatesStatus = LoadingState.loading;
    },
    [updateDataTemplates.fulfilled]: (state, action) => {
      state.customDataTemplates = action.payload;
      state.updateDataTemplatesStatus = LoadingState.succeeded;
    },
    [updateDataTemplates.rejected]: (state, action) => {
      state.updateDataTemplatesStatus = LoadingState.failed;
    }
  }
});

export const { updateTemplateOrderUp } = dataTemplatesSlice.actions;
export default dataTemplatesSlice.reducer;
