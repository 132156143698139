import { Form } from 'react-bootstrap';
import React from 'react';

export function Select({ register, options, label, name, ...rest }) {
  rest.key = name || 'unkown';
  return (
    <div className="configuration-col">
      <Form.Group className="custom-form-control">
        <Form.Label>{label}</Form.Label>
        <select className="form-control" {...register(name)} {...rest}>
          {options.map((value, idx) => (
            <option key={idx} value={value.value}>{value.label}</option>
          ))}
        </select>
      </Form.Group>
    </div>
  );
}
