import { Form } from 'react-bootstrap';
import React from 'react';
import { Controller } from 'react-hook-form';

export function Input({ register, label, control, name, ...rest }) {
  return (
    <div className="configuration-col">
      <Form.Group className="custom-form-control">
        <Form.Label className="d-flex">{label}</Form.Label>
        <Controller
          name={name}
          control={control}
          render={({ field }) => {
            return <input className="form-control" {...register(name)} {...rest} {...field} />;
          }}
        />
      </Form.Group>
    </div>
  );
}
