import { useEffect, useState } from 'react';
import {Form} from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useWatch } from 'react-hook-form';
import {
  getReportingStandards,
  getEmissionSources,
  getEmissionSourceTypes,
  getEmissionProfileUnits, getEmissionSourceFactorByEsrcId
} from '../emissionProfileSlice';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import _ from 'lodash';
import {useParams} from "react-router-dom";
import { groupReportUnitOptions } from '../../reports/reportHelper';

const EmissionSourceSelection = ({ control, setValue, isEditing, setSelectedEmissionSource }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { emissionProfileSourceId } = useParams();

  const reportingStandards = useSelector((state) => state.emissionProfiles.reportingStandards);
  const emissionSourceTypes = useSelector((state) => state.emissionProfiles.emissionSourceTypes);
  const emissionSources = useSelector((state) => state.emissionProfiles.emissionSources);
  const emissionSourceUnits = useSelector((state) => state.emissionProfiles.emissionSourceUnits);
  const orgReportUnits = useSelector((state) => state.organizations.reportUnits);
  const factors = useSelector((state) => state.emissionProfiles.emissionFactors);
  const orgId = useSelector((state) => state.users.user.usrOrgId);
  const [isMissingEsp, setIsMissingEsp] = useState(false);

  const eprflsrcEsrc = useWatch({
    control,
    name: 'eprflsrcEsrc'
  });

  const showGeneralEmissionFromEps = useWatch({
    control,
    name: 'showGeneralEmissionFromEps'
  });

  const custom = useWatch({
    control,
    name: 'custom'
  });

  const getSelectedEsrcEstd = () => ({
    label: eprflsrcEsrc.esrcEstd?.estdName,
    value: eprflsrcEsrc.esrcEstd?.estdId,
  });
  const [selectedEmisionStd, setSelectedEmisionStd] = useState(eprflsrcEsrc ? getSelectedEsrcEstd() : null);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [emissionFactor, setEmissionFactor] = useState(null);
  if (eprflsrcEsrc && selectedEmisionStd === null) {
    setSelectedEmisionStd(getSelectedEsrcEstd());
  }

  const getReportingStandardsOptions = () => {
    if (isEditing && eprflsrcEsrc && !isMissingEsp) {
      return [
        {
          value: "",
          label: eprflsrcEsrc && custom === 'N' 
            ? eprflsrcEsrc.esrcEstd.estdName
            : t("Select Emission Standard"),
        },
      ]
    }
    if (!reportingStandards) {
      return [];
    }
    const selectOpts = [
      {
        label: t("Recent Emission Standard"),
        options: []
      },
      {
        label: t("Available Emission Standard"),
        options: []
      },
    ];
    reportingStandards.forEach(std => {
      const idx = std.recentUsedDate ? 0 : 1;
      selectOpts[idx].options.push({
        value: std.estdId,
        label: std.estdName,
        time: std.recentUsedDate,
      });
    });
    if (!selectOpts[0].options.length) {
      selectOpts.shift();
      return selectOpts;
    }
    selectOpts[0].options = _.orderBy(selectOpts[0].options, 'time', 'desc');
    return selectOpts;
  };

  const getEmissionSourceTypesOptions = () => {
    if (!emissionSourceTypes || !reportingStandards) return null;
    return emissionSourceTypes.map((typ) => {
      return (
        <option
          key={typ.esrcuitypEnumOrdinalId}
          value={typ.esrcuitypEnumOrdinalId}
          selected={showGeneralEmissionFromEps && typ.esrcuitypEnumOrdinalId === eprflsrcEsrc?.esrcUiType?.esrcuitypEnumOrdinalId}
        >
          {typ.esrcuitypLabel}
        </option>
      );
    });
  };
  const getEmissionSourcesOptions = () => {
    if (!emissionSources || !emissionSourceTypes) return null;
    return emissionSources.map((typ) => {
      return (
        <option
          key={typ.esrcId}
          value={typ.esrcId}
          selected={showGeneralEmissionFromEps && typ.esrcId === eprflsrcEsrc?.esrcId}
        >
          {typ.esrcName}
        </option>
      );
    });
  };

  const disableReportingStdFld = getReportingStandardsOptions() ? false : true;
  const disableSourceTypFld = getEmissionSourceTypesOptions() ? false : true;
  const disableSourceFld = getEmissionSourcesOptions() ? false : true;

  const handleChangeReportingStd = (e) => {
    setValue('eprflsrcEsrcId', null);
    if(!eprflsrcEsrc && isEditing) {
      setIsMissingEsp(true);
    }
    setSelectedEmisionStd(e);
    dispatch(getEmissionSourceTypes({ orgId, standardId: e.value }));
  };
  const handleChangeEmissionSourceTyp = (e) => {
    setValue('eprflsrcEsrcId', null);
    dispatch(getEmissionSources({
      orgId,
      emissionSourceTypeId: e.target.value,
      emissionStandardId: selectedEmisionStd.value,
    }));
  };
  const handleChangeEmissionSource = (e) => {
    setValue('eprflsrcEsrcId', e.target.value, { shouldDirty: true });
    setSelectedEmissionSource(e.target.value);
  };

  const handleChangeUnit = (e) => {
    setSelectedUnit(e);
    if(factors && e.value) {
      setEmissionFactor(factors.find(n => n.unit === e.value));
    } else {
      setEmissionFactor(null);
    }

    //dispatch(getEmissionSourceTypes({ orgId, standardId: e.value }));
  };

  useEffect(() => {
    dispatch(getReportingStandards({ orgId }));
    if (showGeneralEmissionFromEps) {
      dispatch(getEmissionSourceTypes({ orgId, standardId: eprflsrcEsrc.esrcEstd.estdId }));
      dispatch(getEmissionSources({
        orgId,
        emissionSourceTypeId: eprflsrcEsrc.esrcUiType.esrcuitypEnumOrdinalId,
        emissionStandardId: eprflsrcEsrc.esrcEstd.estdId,
      }));
    }
    if(isEditing && eprflsrcEsrc) {
      dispatch(getEmissionProfileUnits({ orgId, eprflsrcEsrcId: eprflsrcEsrc?.esrcId, emissionProfileId: emissionProfileSourceId }));
      dispatch(getEmissionSourceFactorByEsrcId({ orgId, emissionSourceId: eprflsrcEsrc?.esrcId}));
    }
  }, [orgId, showGeneralEmissionFromEps, eprflsrcEsrc, custom, isEditing, dispatch, emissionProfileSourceId]);

  return (
    <>
      <Form.Group className="custom-form-control">
        <Form.Label>{t('Emission Standard')}</Form.Label>
        <Select
          value={selectedEmisionStd}
          options={custom === 'N' ? getReportingStandardsOptions() : []}
          onChange={handleChangeReportingStd}
          placeholder={t('Select Emission Standard')}
          isDisabled={disableReportingStdFld || custom === 'Y' || (isEditing && eprflsrcEsrc)}
        />
      </Form.Group>
      <Form.Group className="custom-form-control">
        <Form.Label>{t('Emission Source Type')}</Form.Label>
        <Form.Control
          required
          as="select"
          onChange={handleChangeEmissionSourceTyp}
          disabled={disableSourceTypFld || custom === 'Y' || (isEditing && !isMissingEsp)}
        >
          {(!isEditing || isMissingEsp) ? (
            <>
              <option value="" hidden>
                Select Emission Source Type
              </option>
              {custom === 'N' ? getEmissionSourceTypesOptions() : null}
            </>
          ) : (
            <option value="">
              {custom === 'N' && eprflsrcEsrc ? eprflsrcEsrc.esrcUiType?.esrcuitypLabel : 'Select Emission Source Type'}
            </option>
          )}
        </Form.Control>
      </Form.Group>
      <Form.Group className="custom-form-control">
        <Form.Label>{t('Emission Source')}</Form.Label>
        <Form.Control
          required
          as="select"
          onChange={handleChangeEmissionSource}
          disabled={disableSourceFld || custom === 'Y' || (isEditing && eprflsrcEsrc)}
        >
          {(!isEditing || isMissingEsp) ? (
            <>
              <option value="" hidden>
                Select Emission Source
              </option>
              {custom === 'N' ? getEmissionSourcesOptions() : null}
            </>
          ) : (
            <option value="">{custom === 'N' && eprflsrcEsrc ? eprflsrcEsrc.esrcName : 'Select Emission Source'}</option>
          )}
        </Form.Control>
      </Form.Group>
      {isEditing && custom === 'N' ? (
      <Form.Group className="custom-form-control">
        <Form.Label>Select Unit to see the factor value</Form.Label>
        <Select
          value={selectedUnit}
          options={groupReportUnitOptions(t, orgReportUnits, emissionSourceUnits || [])}
          onChange={handleChangeUnit}
          placeholder={t('Select Unit')}
        />
      </Form.Group>

          ) : null}
      {isEditing && emissionFactor && custom === 'N' ? (
      <Form.Group className="custom-form-control">
        <Form.Label>Emission Factor Value</Form.Label>
        <div>
          {(emissionFactor ? `${emissionFactor.co2eFactor} (CO2: ${emissionFactor.co2Factor}  CH4: ${emissionFactor.ch4Factor}  NO2: ${emissionFactor.n2oFactor})` : '0')
          + ' (kg CO2-e/ ' + selectedUnit?.value + ')'}
        </div>
      </Form.Group>
          ) : null}

    </>
  );
};

export default EmissionSourceSelection;
