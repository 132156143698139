import React, { useEffect } from 'react';
import { Container, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useRouteMatch } from 'react-router';
import Empty from '../../components/Empty';
import { LoadingState } from '../../utilities/constants';
import { fetchAssignedEntitiesForEG, fetchEntityGroups } from './entityGroupsSlice';
import NewEntityGroup from './NewEntityGroup';

const EntityHome = () => {
  const match = useRouteMatch();
  const dispatch = useDispatch();

  const entityGroupStatus = useSelector((state) => state.entityGroups.status);
  const entityGroups = useSelector((state) => state.entityGroups.entityGroups);
  const orgId = useSelector((state) => state.users.user.usrOrgId);

  useEffect(() => {
    if (entityGroupStatus === LoadingState.idle) {
      dispatch(fetchEntityGroups({ orgId }));
    }
    if (entityGroupStatus === LoadingState.succeeded && entityGroups?.length > 0) {
      const egId = entityGroups[0].egId;
      dispatch(fetchAssignedEntitiesForEG({ orgId, egId }));
    }
  }, [orgId, entityGroupStatus, entityGroups, dispatch]);

  return (
    <Container style={{ paddingLeft: '0px', paddingRight: '0px' }}>
      {entityGroupStatus === LoadingState.loading && <Spinner animation="grow" />}
      {entityGroupStatus === LoadingState.succeeded && entityGroups?.length > 0 ? (
        <Redirect to={`${match.url}/${entityGroups[0].egId}`} />
      ) : (
        <NewEntityGroup />
      )}
      {entityGroupStatus === LoadingState.failed && <Empty title="Sorry, Something went wrong" />}
    </Container>
  );
};

export default EntityHome;
