import { Form } from 'react-bootstrap';
import React from 'react';
import ReactDatePicker from 'react-datepicker';
import { Controller } from 'react-hook-form';

export function DatePicker({
  control,
  label,
  name,
  disabled,
  dateFormat,
  readOnly,
  maxDate = null,
  minDate = null,
  onChangeHandler = () => { }
}) {
  const handleDateChangeRaw = (e) => {
    if (!e.target.value || e.target.value.length < 1) {
      e.preventDefault();
    }
  };

  return (
    <div className="configuration-col">
      <Form.Group className="custom-form-control">
        <Form.Label>{label}</Form.Label>
        <Controller
          name={name}
          control={control}
          render={({ field }) => {
            const parsed = field.value ? field.value : null;
            const format = (d) => {
              onChangeHandler(name, d);
              return field.onChange(d);
            };
            return (
              <ReactDatePicker
                selected={parsed}
                onChange={format}
                dateFormat={dateFormat}
                className="form-control"
                popperPlacement="bottom-end"
                showMonthDropdown
                showYearDropdown
                onChangeRaw={handleDateChangeRaw}
                disabled={!!disabled}
                readOnly={!!readOnly}
                maxDate={maxDate}
                minDate={minDate}
              />
            );
          }}
        />
      </Form.Group>
    </div>
  );
}
