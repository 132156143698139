import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import PageHeader from '../../components/PageHeader';
import { LoadingState } from '../../utilities/constants';
import WhitelabelForm from './WhitelabelForm';
import { fetchWhitelabels, selectWhitelabelById} from './WhitelabelSlice';
import { useTranslation } from 'react-i18next';
import { editWhitelabelApi } from './WhitelabelApi';
import { toast } from 'react-toastify';

const EditWhitelabel = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { whitelabelId } = useParams();
  const [saveState, setSaveState] = useState(LoadingState.idle);
  const stateStatus = useSelector((state) => state.whitelabels.status);
  const whitelabel = useSelector((state) => selectWhitelabelById(state, whitelabelId));
  const isBusy = saveState === LoadingState.loading;
  //const orgId = useSelector((state) => state.users.user.usrOrgId);

  const handleEdit = async (data) => {
    try {
      setSaveState(LoadingState.loading);
      await editWhitelabelApi(whitelabelId, data );
      toast.success('Whitelabel successfully updated');
      history.push('/rootadmin/setup/whitelabel');
    } catch (error) {
      toast.error(error.message);
      setSaveState(LoadingState.idle);
      throw error;
    }
  };

  const handleCancel = () => {
    history.push('/rootadmin/setup/whitelabel');
  };

  useEffect(() => {
    if (stateStatus === LoadingState.idle) {
      dispatch(fetchWhitelabels());
    }
  }, [ stateStatus, dispatch]);

  return (
    <Container fluid>
      <Row>
        <Col>
          <PageHeader title={'Update Whitelabel'} />
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          {stateStatus === LoadingState.succeeded && whitelabel && (
            <WhitelabelForm
              onSubmit={handleEdit}
              buttonText={t('Save')}
              initialValues={whitelabel}
              disabled={isBusy}
              isEditing
              onCancel={handleCancel}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default EditWhitelabel;
