import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { Container, Col, Row } from 'react-bootstrap';
import { LoadingState } from '../../../utilities/constants';
import PageHeader from '../../../components/PageHeader';
import Configuration from '../configurations/Configuration';
import { fetchDataTemplateIDsReport } from '../reportsSlice';
import { downloadCsv } from '../../../utilities/common';
import { useTranslation } from 'react-i18next';
import { transCsvHeader } from '../reportHelper';

const DataTemplateIds = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const orgId = useSelector((state) => state.users.user.usrOrgId);
  const loggedUserStatus = useSelector((state) => state.users.loggedUserStatus);
  const title = t('Data Template IDs Report');

  const onSubmitHandler = async () => {
    try {
      const resultAction = await dispatch(fetchDataTemplateIDsReport({ orgId }));
      if (!resultAction.error) {
        unwrapResult(resultAction);
      }
      const csvContent = transCsvHeader(resultAction.payload?.data);
      if (csvContent === false) {
        toast.error(t('Invalid csv file content!'));
      } else {
        downloadCsv(csvContent, 'data_template_ids_report.csv');
      }
    } catch (e) {
      toast.error(e.message);
    }
  };
  return (
    <Container fluid className="mt-3">
      <Col>
        <PageHeader.Reports title={title} enableBackButton />
        <Row>
          {loggedUserStatus === LoadingState.succeeded && (
            <Col>
              <Configuration onSubmit={onSubmitHandler} primaryBtnText={t("Download Report")} dropFavOption={true} />
            </Col>
          )}
        </Row>
      </Col>
    </Container>
  );
};

export default DataTemplateIds;
