import React, { useEffect } from 'react';
import { Col, Container, Row, Spinner, Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Empty from '../../components/Empty';
import PageHeader from '../../components/PageHeader';
import { LoadingState } from '../../utilities/constants';
import { chunkArray } from '../../utilities/formatting';
import { fetchAccounts, selectAccountById } from './accountsSlice';
import ViewAssignedUsers from './ViewAssignedUsers';
import ViewAssignedEntities from './ViewAssignedEntities';
import { useTranslation } from 'react-i18next';

const ViewAccount = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { accountId } = useParams();
  const account = useSelector((state) => selectAccountById(state, accountId));
  const accountState = useSelector((state) => state.accounts.status);
  const isBusy = accountState === LoadingState.loading;
  const orgId = useSelector((state) => state.users.user.usrOrgId);

  const displayItems = account
    ? [
        { label: t('Account ID'), value: account.acntAssignedId },
        { label: t('Account Name'), value: account.acntName },
        { label: t('Description'), value: account.acntDesc }
      ]
    : [];

  const itemRows = chunkArray(displayItems, 2);

  useEffect(() => {
    if (accountState === LoadingState.idle) {
      dispatch(fetchAccounts({ orgId }));
    }
  }, [orgId, accountState, dispatch]);

  return (
    <Container fluid>
      {isBusy && <Spinner animation="grow" />}
      {!isBusy && account && (
        <>
          <Row>
            <Col>
              <PageHeader title={t('View Account')} enableBackButton />
            </Col>
          </Row>
          <Row>
            <Col>
              <Tabs defaultActiveKey="details" id={`${account.acntId}-tabs`} className="custom-tab-bar">
                <Tab
                  eventKey="details"
                  title={t('Account Details')}
                  className="custom-tab-content mt-3"
                >
                  <Row>
                    <Col lg={8}>
                      {itemRows.map((row, i) => (
                        <Row key={i} className="mt-4">
                          {row.map((col) => (
                            <Col md={6} key={col.label}>
                              <div className="data-view">
                                <label className="label">{col.label}</label>
                                <div className="data">{col.value || '-'}</div>
                              </div>
                            </Col>
                          ))}
                        </Row>
                      ))}
                    </Col>
                  </Row>
                </Tab>
                <Tab
                  eventKey="assignAssets"
                  title={t('Assign Users')}
                  className="custom-tab-content"
                >
                  <ViewAssignedUsers accountId={account.acntId} />
                </Tab>
                <Tab
                  eventKey="businessMeasures"
                  title={t('Assign Entities')}
                  className="custom-tab-content"
                >
                  <ViewAssignedEntities accountId={account.acntId} />
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </>
      )}
      {!isBusy && !account && <Empty title="Sorry, account was not found" />}
    </Container>
  );
};

export default ViewAccount;
