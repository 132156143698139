import { combineReducers } from '@reduxjs/toolkit';
// Reducers
import entityReducer from '../features/entity/entitySlice';
import assetsReducer from '../features/assets/assetsSlice';
import businessMeasuresReducer from '../features/business-measure/businessMeasuresSlice';
import entityBusinessMeasuresReducer from '../features/entity-business-measure/entityBusinessMeasureSlice';
import dataControlReducer from '../features/data-control/dataControlSlice';
import targetEmissionReducer from '../features/target-emission/targetEmissionSlice';
import usersReducer from '../features/manage-users/usersSlice';
import emissionProfilesReducer from '../features/emission-profile/emissionProfileSlice';
import accountsReducer from '../features/manage-accounts/accountsSlice';
import entityGroupsReducer from '../features/entity-groups/entityGroupsSlice';
import epsGroupsReducer from '../features/emission-profile-groups/epsGroupSlice';
import translationsReducer from '../features/manage-translations/translationsSlice';
import emissionsReducer from '../features/emissions/emissionSlice';
import reportsReducer from '../features/reports/reportsSlice';
import favouriteReportsReducer from '../features/add-to-favourite/favReportSlice';
import uploadDataReducer from '../features/emissions/upload-data/uploadDataSlice';
import dataTemplatesReducer from '../features/manage-data-templates/dataTemplatesSlice';
import dashboardReducer from '../features/dashboard/dashboardSlice';
import bulkInsertReducer from '../features/bulk-insert/bulkInsertSlice';

import whitelabelsReducer from '../features/whitelabel/WhitelabelSlice';
import organizationsReducer from '../features/organization/OrganizationSlice';
import disclosureReducer from '../features/disclosure/disclosureSlice';
import reductionPlanReducer from '../features/reduction-plan/reductionPlanSlice';
import billSettingsReducer from '../features/bill-settings/billSettingsSlice';
import emissionFactorsReducer from '../features/emission-factors/emissionFactorsSlice';
import energyContentReducer from '../features/energy-content-factor/EnergyContentSlice';
import currencyReducer from '../features/currency/currencySlice';
import schedulesReducer from '../features/schedule-reports/schedulesSlice';

const rootReducer = combineReducers({
  entities: entityReducer,
  assets: assetsReducer,
  businessMeasures: businessMeasuresReducer,
  dataControls: dataControlReducer,
  entityBusinessMeasures: entityBusinessMeasuresReducer,
  targetEmissions: targetEmissionReducer,
  users: usersReducer,
  emissionProfiles: emissionProfilesReducer,
  accounts: accountsReducer,
  entityGroups: entityGroupsReducer,
  epsGroups: epsGroupsReducer,
  translations: translationsReducer,
  emissions: emissionsReducer,
  reports: reportsReducer,
  uploadData: uploadDataReducer,
  dataTemplates: dataTemplatesReducer,
  dashboard: dashboardReducer,
  bulkInsert: bulkInsertReducer,
  currencies: currencyReducer,
  whitelabels: whitelabelsReducer,
  organizations: organizationsReducer,
  disclosure: disclosureReducer,
  reductionPlan: reductionPlanReducer,
  favouriteReports: favouriteReportsReducer,
  billSettings: billSettingsReducer,
  factors: emissionFactorsReducer,
  energyContent:  energyContentReducer,
  schedules: schedulesReducer,
});

export default rootReducer;
