import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import PageHeader from '../../components/PageHeader';
import { LoadingState } from '../../utilities/constants';
import { fetchFavouriteSchedules, selectScheduleById } from './schedulesSlice';
import { updateFavouriteScheduleApi } from './scheduleApi';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import FavouriteScheduleForm from './FavouriteScheduleForm';

const EditFavouriteSchedule = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { scheduleId } = useParams();
  const schedule = useSelector((state) => selectScheduleById(state, scheduleId));
  const favouriteSchedulesStatus = useSelector((state) => state.schedules.status);
  const orgId = useSelector((state) => state.users.user.usrOrgId);

  const [saveScheduleState, setSaveScheduleState] = useState(LoadingState.idle);

  const isBusy = saveScheduleState === LoadingState.loading;

  const handleEditSchedule = async (data) => {
    try {
      setSaveScheduleState(LoadingState.loading);
      await updateFavouriteScheduleApi(orgId, scheduleId, data);
      toast.success(t('Schedule updated successfuly'));
      history.push('/admin/reports/scheduled');
    } catch (e) {
      setSaveScheduleState(LoadingState.idle);
      toast.error(e.message);
      throw e;
    }
  };

  const handleCancelSchedule = () => {
    history.push('/admin/reports/scheduled');
  };

  useEffect(() => {
    if (favouriteSchedulesStatus === LoadingState.idle) {
      dispatch(fetchFavouriteSchedules({ orgId }));
    }
  }, [orgId, favouriteSchedulesStatus, dispatch]);

  return (
    <Container fluid>
      <Row>
        <Col>
          <PageHeader title={t("Edit schedule")} enableBackButton={true} />
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          {favouriteSchedulesStatus === LoadingState.succeeded && schedule && (
            <FavouriteScheduleForm
              onSubmit={handleEditSchedule}
              buttonText={t("Save")}
              initialValues={schedule}
              disabled={isBusy}
              isEditing
              onCancel={handleCancelSchedule}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default EditFavouriteSchedule;
