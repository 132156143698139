import React, { useEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { setLeavePrompt } from '../manage-users/usersSlice';
import { useDispatch, useSelector } from 'react-redux';
import { createEmailInputValidateSchema, createUserValidateSchema } from './validateSchema';
import LoadingButton from '../../components/LoadingButton';
import { fetchFavReports } from '../add-to-favourite/favReportSlice';
import {
  getSendingIntervalOptions,
  getWeeklyDateOptions,
  parseDateRangeValue,
} from '../reports/reportHelper';
import _ from 'lodash';
import MultiTextInput from '../../components/MultiTextInput';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { LoadingState } from '../../utilities/constants';
import SelectIconOption from '../../components/SelectIconOption';
import { FaUser, FaUserFriends } from 'react-icons/fa';

const FavouriteScheduleForm = ({
  onSubmit,
  onCancel,
  initialValues = {},
  buttonText = 'Submit',
  isEditing = false,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const { createScheduleSchema, editScheduleSchema } = createUserValidateSchema(t);
  const { usrOrgId: orgId } = useSelector((state) => state.users.user);
  const favReportsStatus = useSelector((state) => state.favouriteReports.status);
  const favReports = useSelector((state) => state.favouriteReports.favReports);

  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isDirty },
    setValue,
  } = useForm({
    defaultValues: {
      ...initialValues,
    },
    resolver: yupResolver(isEditing ? editScheduleSchema : createScheduleSchema),
    mode: 'onChange'
  });
  const dispatch = useDispatch();

  const onSubmitHandler = async (data) => {
    try {
      dispatch(setLeavePrompt(false));
      await onSubmit(data);
    } catch (e) {
      dispatch(setLeavePrompt(isDirty));
    }
  };

  useEffect(() => {
    if (favReportsStatus === LoadingState.idle) {
      dispatch(fetchFavReports({ orgId }));
    }
  }, [dispatch, orgId, favReportsStatus]);

  useEffect(() => {
    dispatch(setLeavePrompt(isDirty));
  }, [isDirty, dispatch]);

  const onCancelHandler = () => onCancel();
  const sendingIntervalOptions = getSendingIntervalOptions(t);
  const cvFrId = useWatch({
    control,
    name: 'cvFrId'
  });
  const sendingInterval = useWatch({
    control,
    name: 'sendingInterval'
  });

  const validateEmailSchema = createEmailInputValidateSchema();
  const onEmailInputValidate = (email) => {
    try {
      validateEmailSchema.validateSync({ email });
      return true;
    } catch {
      return false;
    }
  };

  const getReceiveDateOptions = () => {
    switch (sendingInterval) {
      case 'WEEKLY':
        return getWeeklyDateOptions(t);
      default:
        return _.range(1, 32, 1).map(num => ({
          value: num,
          label: num,
        }));
    }
  };

  const getFavouriteOptions = () =>
    favReports.map(item => ({
      label: item.frName,
      value: item.frId,
      Icon: () => item.frShared === 'Y' ? <FaUserFriends size="1.2em" className="mr-2" /> : <FaUser className="mr-2" />
    }));

  return (
    <Form onSubmit={handleSubmit(onSubmitHandler)}>
      <Row>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Report')} *</Form.Label>
            <Controller
              name="cvFrId"
              control={control}
              render={({ field }) => {
                const format = (d) => field.onChange(d.value);
                const selectFav = field.value ? favReports.find((item) => field.value === item.frId) : null;
                return (
                  <Select
                    value={selectFav ? { value: selectFav.frId, label: selectFav.frName } : null}
                    options={getFavouriteOptions()}
                    onChange={format}
                    components={{ Option: SelectIconOption }}
                    placeholder={t("Select a favourite report")}
                  />
                );
              }}
            />
            <Form.Control.Feedback type="invalid">{errors.cvFrId?.message}</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Date Range')} *</Form.Label>
            <Form.Control
              readOnly={true}
              value={parseDateRangeValue(favReports, cvFrId, t, true)}
            />
            <Form.Control.Feedback type="invalid">{errors.usrEmail?.message}</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Sending Interval')} *</Form.Label>
            <Controller
              name="sendingInterval"
              control={control}
              render={({ field }) => {
                const format = (d) => {
                  field.onChange(field.value = d.value);
                  setValue('receiveDate', '');
                };
                return (
                  <Select
                    value={field.value && sendingIntervalOptions.find((a) => field.value === a.value)}
                    options={sendingIntervalOptions}
                    onChange={format}
                    placeholder={t("Select a interval")}
                  />
                );
              }}
            />
            <Form.Control.Feedback type="invalid">{errors.sendingInterval?.message}</Form.Control.Feedback>
            <Form.Text muted>
              <AiOutlineQuestionCircle className='mt-n1 mr-1' />
              {t('Years and quarters are as default Report Period')}
            </Form.Text>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Receive Date')} *</Form.Label>
            <Controller
              name="receiveDate"
              control={control}
              render={({ field }) => {
                const format = (d) => field.onChange(field.value = d.value);
                const options = [
                  { value: '', label: t('Select an interval') },
                  ...getReceiveDateOptions(),
                ];
                return (
                  <Select
                    value={field.value && options.find((a) => Number(field.value) === a.value)}
                    options={options}
                    onChange={format}
                    placeholder={t("Select a date")}
                  />
                );
              }}
            />
            <Form.Control.Feedback type="invalid">{errors.receiveDate?.message}</Form.Control.Feedback>
            <Form.Text muted>
              <AiOutlineQuestionCircle className='mt-n1 mr-1' />
              {t('The receive date is after the sending intervals ends. (e.g: Next Monday, 23rd of next month, 15th of the 1st month of the year)')}
            </Form.Text>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Emails')} *</Form.Label>
            <Controller
              name="emails"
              control={control}
              render={({ field }) => {
                const format = (d) => {
                  field.onChange(d?.length ? d.map(item => item.value) : []);
                }
                const options = initialValues?.emails ? initialValues.emails.map(email => ({ value: email, label: email })) : [];
                return (
                  <MultiTextInput
                    defaultValue={options}
                    placeholder={t('Enter emails to automatically send out')}
                    onChange={format}
                    onValidate={onEmailInputValidate}
                    maxLength={20}
                  />
                );
              }}
            />
            <Form.Control.Feedback type="invalid">{errors.eprflsrcConsumptionUnit?.message}</Form.Control.Feedback>
            <Form.Text muted>
              <AiOutlineQuestionCircle className='mt-n1 mr-1' />
              {t('Please separate each email with either a comma or a space.')}
            </Form.Text>
          </Form.Group>
        </Col>
      </Row>
      <Button
        className="mb-5"
        type="submit"
        disabled={!isValid || !isDirty || disabled}
      >
        {buttonText}
      </Button>
      <Button variant="secondary" className="ml-4 mb-5" onClick={onCancelHandler}>
        {t('Cancel')}
      </Button>
      <LoadingButton isLoading={disabled} className="ml-4 mb-5" />
    </Form>
  );
};

export default FavouriteScheduleForm;
