import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { LoadingState } from '../../utilities/constants';
import * as scheduleApi from './scheduleApi';


export const fetchFavouriteSchedules = createAsyncThunk('schedules/fetchFavouriteSchedules', async (payload) => {
  try {
    const response = await scheduleApi.fetchFavouriteScheduleApi(payload.orgId);
    return response;
  } catch (err) {
    toast.error(err.message);
    throw err;
  }
});

export const selectScheduleById = (state, scheduleId) => {
  if (state.schedules.favouriteSchedules?.length) {
    const parsedId = parseInt(scheduleId);
    return state.schedules.favouriteSchedules.find(item => item.id === parsedId);
  }
  return null;
};

const schedulesSlice = createSlice({
  name: 'schedules',
  initialState: {
    favouriteSchedules: [],
    status: LoadingState.idle,
    error: null
  },
  reducers: {},
  extraReducers: {
    [fetchFavouriteSchedules.pending]: (state) => {
      state.status = LoadingState.loading;
    },
    [fetchFavouriteSchedules.fulfilled]: (state, action) => {
      state.status = LoadingState.succeeded;
      state.favouriteSchedules = action.payload;
    },
    [fetchFavouriteSchedules.rejected]: (state, action) => {
      state.status = LoadingState.failed;
      state.error = action.error.message;
    },
  }
});

export default schedulesSlice.reducer;
