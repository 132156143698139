import React, { useRef, useState, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { Button, Col, Container, Row, Spinner, Form } from 'react-bootstrap';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { FiSettings } from 'react-icons/fi';
import { unwrapResult } from '@reduxjs/toolkit';
import { useLocation } from 'react-router-dom';
import { LoadingState } from '../../utilities/constants';
import Empty from '../../components/Empty';
import PageHeader from '../../components/PageHeader';
import Configuration from './configurations/Configuration';
import { DatePicker } from './configurations/DatePicker';
import Parameters from './configurations/Parameters';
import { fetchEmissionProfilesTree } from '../emission-profile/emissionProfileSlice';
import { TreeViewSelector } from './configurations/TreeViewSelector';
import PrintButton from '../../components/PrintButton';
import Download from './configurations/Download';
import AddToFavourite from '../add-to-favourite/AddToFavourite';
import {
  parseFavouriteData,
  getCommonFilterPeriodRange,
  dateRangeFilterPeriodOptions,
} from './reportHelper';
import { useTranslation } from 'react-i18next';
import { parseUserInfo } from '../../utilities/common';
import { recursiveMap } from '../../utilities/treeHelpers';
import { fetchEntityTree } from '../entity/entitySlice';

const errorRenderHandler = (error, fetchReportStatus) =>
  fetchReportStatus === LoadingState.failed && error ? <Empty title={error} /> : null;

const ReportContainer = ({
  reportName,
  reportSubTitle,
  fetchReportData,
  fetchReportStatus,
  reportData,
  reportRender,
  errorRender = errorRenderHandler,
  extraConfigDefault = {},
  extraConfigFormState = {},
  isUseStartDate = true,
  isUseEndDate = true,
  isUseEntityTree = true,
  isUseEmissionProfileTree = true,
  underHeaderNote = () => { },
  isShowAllocation = false,
  chartRef = null,
  configRef = null,
  downloadOptions = null,
  primaryBtnText,
  dropFavOption,
  startDateLabel = null,
  endDateLabel = null,
  extraParameters = [],
  extraConfigRender = () => null,
  bottomConfigRender = () => null,
  fetchRelatedData = () => { },
  getFavouriteFilterData = (params) => params,
  getTimeSelectOptions = dateRangeFilterPeriodOptions,
  getFilterPeriodRange = getCommonFilterPeriodRange,
  exportDataHandler = null,
  reportColumnConfig = {}
}) => {
  const { t, i18n } = useTranslation();
  if (_.isNull(startDateLabel)) {
    startDateLabel = t('Start Date');
  }
  if (_.isNull(endDateLabel)) {
    endDateLabel = t('End Date');
  }
  const [isInit, setIsInit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const location = useLocation();
  const reportPath = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
  const { filterData, frUsrId, frName } = parseFavouriteData(location.search);
 
  const filterPeriodRange = getFilterPeriodRange(filterData);
  const dispatch = useDispatch();
  const dataRef = useRef();
  const tableRef = useRef(null);
  const [startDate, setStartDate] = useState(filterPeriodRange.startDate);
  const [endDate, setEndDate] = useState(filterPeriodRange.endDate);
  const [selectedEntity, setSelectedEntity] = useState(filterData ? filterData.entitiesIdsToInclude : []);
  const [selectedEPS, setSelectedEPS] = useState(filterData ? filterData.epsIdsToInclude : []);
  const [openAddToFav, setOpenAddToFav] = useState(false);
  const [filterParams, setFilterParams] = useState({});
  const [timeSelectOptions, setTimeSelectOptions] = useState([]);
  const [isApplyAllocation, setIsApplyAllocation] = useState(filterData ? filterData.isApplyAllocation : false);

  const { orgId, userId, usrDateFormat, usrTimezone } = parseUserInfo(useSelector((state) => state.users.user));
  const [openSidebar, setOpenSidebar] = useState(false);
  const [reportRarameter, setReportRarameter] = useState(false);
  const [formData, setFormData] = useState({ startDate, endDate });

  const error = useSelector((state) => state.reports.error);
  const entityTree = useSelector((state) => state.entities.tree);
  const emissionProfileTree = useSelector((state) => state.emissionProfiles.tree);

  const title = frName ? `${frName} [${reportName}]` : reportName;
  const subTitle = frName ? `${frName} [${reportSubTitle}]` : reportSubTitle;

  const isFavReport = !!frUsrId;
  const isOwnerFavReport = isFavReport && Number(frUsrId) === Number(userId);

  const entityList = useMemo(() => {
    const listItems = [];
    recursiveMap(entityTree ?? {}, (n , parentNode) => {
      if (parentNode && (!selectedEntity.length || selectedEntity.includes(Number(n.divId)))) {
        listItems.push(n.divName);
      }
      return {};
    });
    return listItems.join(', ');
  }, [entityTree, selectedEntity]);

  useEffect(() => {
    if (!isInit) {
      const data = {
        entitiesIdsToInclude: selectedEntity,
        epsIdsToInclude: selectedEPS,
        usrDateFormat,
        usrTimezone,
      };
      if (isUseStartDate) {
        data.startDate = startDate;
      }
      if (isUseEndDate) {
        data.endDate = endDate;
      }
      setIsInit(true);
      fetchReportData(dispatch, { orgId, data });
      dispatch(fetchEmissionProfilesTree({ orgId }));
      fetchRelatedData(dispatch, { orgId, data });
      dispatch(fetchEntityTree({ orgId }));
    }
  }, [
    orgId, isInit,
    selectedEntity, selectedEPS, startDate, endDate, usrDateFormat,
    dispatch, fetchReportData, fetchRelatedData, usrTimezone,
    isUseStartDate, isUseEndDate,
  ]);

  const onSubmitHandler = async (filters, e) => {
    const defaultData = {
      usrDateFormat: usrDateFormat,
      usrTimezone,
    };
    if (isUseStartDate) {
      defaultData.startDate = filters.startDate;
    }
    if (isUseEndDate) {
      defaultData.endDate = filters.endDate;
    }
    if (e.target.id === 'generate-report') {
      setIsSubmitting(true);
      setOpenAddToFav(false);
      if (isUseStartDate) {
        setStartDate(defaultData.startDate);
      }
      if (isUseEndDate) {
        setEndDate(defaultData.endDate);
      }
      const data = getFavouriteFilterData({
        ...defaultData,
        entitiesIdsToInclude: selectedEntity,
        epsIdsToInclude: selectedEPS,
        isApplyAllocation,
      }, filters);
      try {
        const resultAction = await fetchReportData(dispatch, { orgId, data });
        if (!resultAction.error) {
          unwrapResult(resultAction);
        }
      } catch (e) {
        toast.error(e.message);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      const data = getFavouriteFilterData({
        ...defaultData,
        baseDate: new Date(),
        entitiesIdsToInclude: selectedEntity,
        epsIdsToInclude: selectedEPS,
        isApplyAllocation,
      }, filters);
      setFilterParams(data);
      setTimeSelectOptions(getTimeSelectOptions(data, t, null, true));
      setOpenAddToFav(true);
    }
  };

  const manageShowModal = (show) => {
    setOpenAddToFav(show);
  };

  const parameters = [
    {
      key: t('Entities'),
      value: entityList,
    },
    ...extraParameters,
  ];
  if (isUseEndDate) {
    parameters.unshift(...[
      {
        key: endDateLabel,
        value: endDate
      },
    ]);
  }

  if (isUseStartDate) {
    parameters.unshift(...[
      {
        key: startDateLabel,
        value: startDate
      },
    ]);
  }

  const renderFavouriteDateRange = () => {
    const timeOptions = getTimeSelectOptions(filterData, t, null, false);
    const rangeId = filterData?.frRepFilterPeriodParam || '1';
    const rangeName = timeOptions.find(item => item.value === rangeId);
    return (
      <div className="configuration-col">
        <Form.Group className="custom-form-control">
          <Form.Label>{t('Date Range')}</Form.Label>
          <Form.Control type="text" value={rangeName ? rangeName.label : t('Unknown')} readOnly />
        </Form.Group>
      </div>
    );
  };

  const allcationConfigRender = () => {
    if (!isShowAllocation || !selectedEntity || !selectedEntity.length) {
      return null;
    }
    return (
      <div className="configuration-col" key="allocation-cdg-1">
        <Form.Group className="custom-form-control">
          <Form.Group className="custom-form-control">
            <Form.Check
              custom
              id="allocationEnabled"
              onChange={(e) => setIsApplyAllocation(e.target.checked)}
              checked={isApplyAllocation}
              label={t("Apply Allocation")}
              disabled={isFavReport}
            />
          </Form.Group>
        </Form.Group>
      </div>
    );
  };
  const isDisabledRange = extraConfigFormState?.reportYearType
    && extraConfigFormState?.reportYearType !== 'Calendar Year';
  const onFormChangeHandler = (field, value) => {
    if (value !== formData[field]) {
      setFormData((prevState) => ({
        ...prevState,
        [field]: value
      }));
    }
  };
  const renderDatePicker = (name, extraProps) => {
    const labelMaps = { startDate: startDateLabel, endDate: endDateLabel };
    return (
      <DatePicker
        readOnly={isDisabledRange}
        label={labelMaps[name]}
        dateFormat={usrDateFormat}
        name={name}
        onChangeHandler={onFormChangeHandler}
        {...extraProps} />
    );
  };

  const reportSidebarHandler = () => {
    if (!openSidebar) {
      return null;
    }
    return (
      <div>
        <Configuration
          ref={configRef}
          defaultValues={{
            startDate: startDate,
            endDate: endDate,
            ...extraConfigDefault,
          }}
          isSubmitting={isSubmitting}
          onSubmit={onSubmitHandler}
          onFormChange={onFormChangeHandler}
          reportRarameter={reportRarameter}
          setReportRarameter={setReportRarameter}
          isOwnerFavReport={isOwnerFavReport}
          isFavReport={isFavReport}
          favReportName={frName}
          setOpenSidebar={setOpenSidebar}
          reportColumnConfig={reportColumnConfig}
          primaryBtnText={primaryBtnText}
          dropFavOption={dropFavOption}
        >
          {isUseStartDate ? renderDatePicker('startDate', { maxDate: formData.endDate || null }) : null}
          {isUseEndDate ? renderDatePicker('endDate', { minDate: formData.startDate || null }) : null}
          {isFavReport ? renderFavouriteDateRange() : null}
          {extraConfigRender(isFavReport)}
          {isUseEntityTree ?
            <TreeViewSelector
              label={t("Entity")}
              options={entityTree}
              selectedEntity={selectedEntity}
              setSelectedEntity={setSelectedEntity}
              disabled={isFavReport}
            /> : null}
          {allcationConfigRender()}
          {isUseEmissionProfileTree ?
            <TreeViewSelector
              label={t("Emission Source")}
              options={emissionProfileTree}
              selectedEPS={selectedEPS}
              setSelectedEPS={setSelectedEPS}
              disabled={isFavReport}
            /> : null}
          {bottomConfigRender(isFavReport)}
        </Configuration>
      </div>
    );
  };

  const containerClass = `report-container d-flex flex-row ${openSidebar ? 'sidebar-active' : ''}`;
  return (
    <div className={containerClass}>
      <div className="report-main">
        <Container fluid>
          <PageHeader.Reports
            title={title}
            enableBackButton={true}
            actions={
              <div className="d-flex">
                <Button
                  variant="primary"
                  type="submit"
                  className={`mr-3 d-flex align-items-center justify-content-center ${openSidebar ? 'active' : ''}`}
                  onClick={() => (setOpenSidebar(!openSidebar))}
                >
                  <div className="mr-3">
                    <FiSettings fontSize={17} />
                  </div>
                  <div>{t('Configure')}</div>
                </Button>
                <PrintButton downloadRef={dataRef} disabled={reportRarameter} />
                <Download
                  chartRef={chartRef ? chartRef : tableRef}
                  dataRef={chartRef ? dataRef : null}
                  isCanvas={chartRef ? true : false}
                  exportDataHandler={exportDataHandler}
                  options={downloadOptions ? downloadOptions : (chartRef ? ['csv', 'pdf', 'png', 'jpeg', 'svg'] : ['csv', 'pdf'])}
                  reportName={title}
                  reportSubTitle={subTitle}
                  locale={i18n?.language}
                  disabled={reportRarameter}
                />
                {openAddToFav ? (
                  <AddToFavourite
                    reportPath={reportPath}
                    filterJsonParams={filterParams}
                    reportName={reportName}
                    filterPeriodOptions={timeSelectOptions}
                    show={true}
                    manageModal={manageShowModal}
                  />
                ) : null}
              </div>
            }
          />
          {underHeaderNote()}

          <Row>
            <Col>
              <div className="mt-2 mb-4 download-class">
                {fetchReportStatus === LoadingState.loading && <Spinner animation="grow" />}
                {fetchReportStatus === LoadingState.succeeded && reportData && !reportRarameter && (
                  <div
                    ref={dataRef}
                    className={`print-wrapper ${chartRef ? 'chart-container' : ''}`}
                    id={chartRef ? 'chart_legend' : 'entity_table'}
                  >
                    <div className="pb-3 report-header">
                      <strong className="report-text">{subTitle}</strong>
                    </div>
                    {reportRender(chartRef ? chartRef : tableRef)}
                  </div>
                )}
                {fetchReportStatus === LoadingState.succeeded && reportRarameter && <Parameters props={parameters} />}
                {errorRender(error, fetchReportStatus)}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="report-sidebar flex-column flex-shrink-0">
        {reportSidebarHandler()}
      </div>
    </div>
  );
};

export default ReportContainer;
