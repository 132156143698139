import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingState } from '../../utilities/constants';
import { fetchEntityTree } from '../entity/entitySlice';
import EmissionDataTable from './EmissionDataTable';
import { getSelectedEP } from './emissionSlice';
import EntityEPSelector from './EntityEPSelector';

const ManageEmissions = () => {
  const dispatch = useDispatch();
  const orgId = useSelector((state) => state.users.user.usrOrgId);
  const { status } = useSelector((state) => state.entities);
  const selectedEP = useSelector(getSelectedEP);

  useEffect(() => {
    if (status === LoadingState.idle) {
      dispatch(fetchEntityTree({ orgId }));
    }
  }, [dispatch, orgId, status]);

  return (
    <Container fluid>
      <Row>
        <Col>
          <EntityEPSelector />
        </Col>
      </Row>
      {selectedEP && (
        <Row>
          <Col>
            <EmissionDataTable />
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default ManageEmissions;
