import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { LoadingState } from '../../utilities/constants';
import {
  fetchWhitelabelsApi,
  createWhitelabelApi,
} from './WhitelabelApi';

export const fetchWhitelabels = createAsyncThunk('whitelabels/fetch', async () => {
  try {
    const response = await fetchWhitelabelsApi();
    return response;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const createWhitelabel = createAsyncThunk('whitelabels/create', async (payload, { dispatch }) => {
  try {
    const { data } = payload;
    await createWhitelabelApi(data);
    toast.success('New Whitelabel is created');
    dispatch(fetchWhitelabels());
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

const whitelabelSlice = createSlice({
  name: 'whitelabels',
  initialState: {
    whitelabels: [],
    status: LoadingState.idle,
    error: null
  },
  reducers: {},
  extraReducers: {
    [fetchWhitelabels.pending]: (state) => {
      state.status = LoadingState.loading;
    },
    [fetchWhitelabels.fulfilled]: (state, action) => {
      state.status = LoadingState.succeeded;
      state.whitelabels = action.payload;
    },
    [fetchWhitelabels.rejected]: (state, action) => {
      state.status = LoadingState.failed;
      state.error = action.error.message;
    },
  }
});

export const selectWhitelabelById = (state, id) => {
  if (!state.whitelabels.whitelabels?.length === 0) return null;
  const parsedId = parseInt(id);
  const found = state.whitelabels.whitelabels.find((a) => a.wlbId === parsedId);
  return found ?? null;
};

export const convertToTree = (state) => ({ children: state.whitelabels.whitelabels });

export default whitelabelSlice.reducer;
