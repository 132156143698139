import React from 'react';
import CarbonFootPrintByEntity from './report-components/CarbonFootPrintByEntity';
import MovingAverage from './report-components/MovingAverage';
import EmissionsByTop10Entities from './report-components/EmissionsByTop10Entities';
import EmissionsByTop10EPS from './report-components/EmissionsByTop10EPS';
import EmissionsByEntityGroup from './report-components/EmissionsByEntityGroup';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import RouteNotFound from '../../RouteNotFound';
import ViewReports from './ViewReports';
import EmissionsByAsset from './report-components/EmissionsByAsset';
import EmissionsByTop10Contributors from './report-components/EmissionsByTop10Contributors';
import Transaction from './report-components/Transaction';
import DataGap from './report-components/DataGap';
import DataTemplateIds from './report-components/DataTemplateIds';
import CarbonFootPrintByScope from './report-components/CarbonFootPrintByScope';
import CarbonFootPrintByFullEmissionProfile from './report-components/CarbonFootPrintByFullEmissionProfile';
import DataValidation from './report-components/DataValidation';
import IntensityReports from './report-components/IntensityReports';
import EmissionsByEntityOrMetric from './report-components/EmissionsByEntityOrMetric';

const ReportsHome = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.path}`}>
        <ViewReports />
      </Route>
      <Route exact path={`${match.path}/moving-average-report`}>
        <MovingAverage showHeaders={true} />
      </Route>
      <Route exact path={`${match.path}/emissions-by-top-10-entities`}>
        <EmissionsByTop10Entities />
      </Route>
      <Route exact path={`${match.path}/emissions-by-top-10-eps`}>
        <EmissionsByTop10EPS showHeaders={true} />
      </Route>
      <Route exact path={`${match.path}/carbon-footprint-by-entity`}>
        <CarbonFootPrintByEntity />
      </Route>
      <Route exact path={`${match.path}/carbon-footprint-by-scope`}>
        <CarbonFootPrintByScope />
      </Route>
      <Route exact path={`${match.path}/carbon-footprint-by-full-emission-profile`}>
        <CarbonFootPrintByFullEmissionProfile />
      </Route>
      <Route exact path={`${match.path}/emissions-by-asset`}>
        <EmissionsByAsset />
      </Route>
      <Route exact path={`${match.path}/emissions-by-entity-group`}>
        <EmissionsByEntityGroup />
      </Route>
      <Route exact path={`${match.path}/emissions-by-top-10-contributors`}>
        <EmissionsByTop10Contributors />
      </Route>
      <Route exact path={`${match.path}/transaction`}>
        <Transaction />
      </Route>
      <Route exact path={`${match.path}/data-gap`}>
        <DataGap />
      </Route>
      <Route exact path={`${match.path}/data-template-ids`}>
        <DataTemplateIds />
      </Route>
      <Route exact path={`${match.path}/data-validation`}>
        <DataValidation />
      </Route>
      <Route exact path={`${match.path}/intensity-graphs`}>
        <IntensityReports />
      </Route>
      <Route exact path={`${match.path}/emissions-by-entity-or-metric`}>
        <EmissionsByEntityOrMetric />
      </Route>
      <Route exact path={`${match.path}/scheduled-reports`}>
        {/* <ScheduledReports /> */}
      </Route>
      <Route path="*">
        <RouteNotFound />
      </Route>
    </Switch>
  );
};
export default ReportsHome;
