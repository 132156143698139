import React, { useEffect, useState, useMemo } from 'react';
import { Button, Col, Container, Modal, Row, Spinner, Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { LoadingState } from '../../utilities/constants';
import { IoRepeatOutline } from 'react-icons/io5';
import {
  fetchEntityTree,
  selectEntityById,
  removeEntity,
  selectRootEntity,
} from './entitySlice';
import { changeEntityParent, editEntityApi } from  './entityApi';
import { toast } from 'react-toastify';
import EntityFrom from './EntityForm';
import TreeViewModal from '../../components/TreeViewModal';
import PageHeader from '../../components/PageHeader';
import ManageDataControl from '../data-control/ManageDataControl';
import ManageEntityBusinessMeasure from '../entity-business-measure/ManageEntityBusinessMeasure';
import ManageTargetEmission from '../target-emission/ManageTargetEmission';
import AssignAssets from './AssignAssets';
import { unwrapResult } from '@reduxjs/toolkit';
import GeneralDocuments from './GeneralDocuments';
import { recursiveMap } from '../../utilities/treeHelpers';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const EditEntity = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const entityStatus = useSelector((state) => state.entities.status);
  const { entityId } = useParams();
  const entity = useSelector((state) => selectEntityById(state, entityId));
  const entityTree = useSelector((state) => state.entities.tree);
  const [showEntitySelector, setShowEntitySelector] = useState(false);
  const [editEntityState, setEditEntityState] = useState(false);
  const orgId = useSelector((state) => state.users.user.usrOrgId);
  const loggedUserStatus = useSelector((state) => state.users.loggedUserStatus);
  const rootEntity = useSelector(selectRootEntity);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedParent, setSelectedParent] = useState({});
  const [changeToParent, setChangeToParent] = useState({});
  const [isChangeParentMode, setIsChangeParentMode] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const closeConfirmationModal = () => setShowConfirmationModal(false);
  const changeParentHandler = async() => {
    try {
      setIsLoading(true);
      const data = { parentId: changeToParent.divId };
      await changeEntityParent(orgId, entityId, data);
      dispatch(fetchEntityTree({ orgId }));
      toast.success(t('The parent entity has been changed'));
      setShowConfirmationModal(false);
    } catch (e) {
      toast.error(e.message);
    }
    setIsLoading(false);
  };

  const transformedTree = useMemo(() => recursiveMap(entityTree ?? {}, (n, parentNode) => {
    let isIgnoreItem = false;
    if (Number(n.divId) === Number(entityId)) {
      isIgnoreItem = true;
      setSelectedParent(parentNode);
    }
    if (isChangeParentMode) {
      const changeParentConfirmHandler = (node) => {
        closeModal();
        setChangeToParent(node);
        setShowConfirmationModal(true);
      };
      const checkIsAcitve = () => Number(n.divId) === Number(selectedParent?.divId);
      const getLabel = () => {
        if (checkIsAcitve()) {
          return (<span>{n.divName}</span>);
        }
        return (<span onClick={() => changeParentConfirmHandler(n)}>{n.divName}</span>);
      };
      return {
        value: n.divId,
        labelRaw: n.divName,
        label: getLabel,
        showCheckbox: true,
        isIgnoreItem,
        isActive: checkIsAcitve,
        valueId: n.divAssignedId
      };
    }
    return {
      value: n.divId,
      labelRaw: n.divName,
      label: <Link to={`/admin/setup/entity/${n.divId}/edit`}>{n.divName}</Link>,
      showCheckbox: true,
      valueId: n.divAssignedId,
      isActive: parseInt(entityId) === n.divId
    };
  }), [entityTree, entityId, isChangeParentMode, selectedParent?.divId]);
  transformedTree.isRoot = true;

  const closeModal = () => setShowEntitySelector(false);
  const openModal = (isChangeParent = false) => {
    setIsChangeParentMode(isChangeParent);
    setShowEntitySelector(true);
  }

  const handleEditEntity = async (data) => {
    try {
      setEditEntityState(true);
      await editEntityApi(orgId, entityId, data);
      toast.success(t('The entity successfully updated'));
      dispatch(fetchEntityTree({ orgId }));
      setEditEntityState(false);
    } catch (e) {
      toast.error(e.message || t('Something went wrong'));
      setEditEntityState(false);
      throw e;
    }
  };

  const handleDeleteEntity = async () => {
    try {
      const resultAction = await dispatch(removeEntity({ orgId, entityId }));
      unwrapResult(resultAction);
      toast.success(t('The entity has been deleted!'));
      history.push(`/admin/setup/entity/${rootEntity.divId}/edit`);
    } catch (err) {
      console.log(err);
      if (err.message.includes('violates foreign key')) {
        toast.error(t('The entity cannot be deleted until all of relations data have been removed.'));
      } else {
        toast.error(err.message || t('Something went wrong'));
      }
    }
  };

  useEffect(() => {
    closeModal();
    if (entityStatus === LoadingState.idle && loggedUserStatus === LoadingState.succeeded) {
      dispatch(fetchEntityTree({ orgId }));
    }
  }, [orgId, loggedUserStatus, entityStatus, entityId, dispatch]);

  return (
    <Container fluid>
      <Row>
        <Col>
          <PageHeader
            title={t('Manage Entity')}
            actions={
              <Button className="mr-1" variant="primary" onClick={() => openModal(false)}>
                {t('Select Entity')}
              </Button>
            }
          />
        </Col>
      </Row>
      {entityStatus === LoadingState.loading && <Spinner animation="grow" />}
      {entityStatus === LoadingState.succeeded && entity && (
        <Row>
          <Col>
            <PageHeader.Secondary
              title={entity ? entity.divName : t('Loading')}
              actions={
                <Button
                  size="sm"
                  to={`/admin/setup/entity/${entityId}/new`}
                  as={Link}
                  className="mr-1"
                  variant="secondary"
                >
                  {t('Add Entity')}
                </Button>
              }
            />
            <Tabs defaultActiveKey="details" id={`${entity.divId}-tabs`} className="custom-tab-bar">
              <Tab
                eventKey="details"
                title={t('Entity Details')}
                className="custom-tab-content"
              >
                <Row className="my-2">
                  <Col md={8}>
                    {Number(rootEntity.divId) !== Number(entityId) && (<Row>
                      <Col>
                        <div className="custom-form-control form-group">
                          <label className="form-label">{t('Parent Entity')}</label>
                          <div className="mr-1 d-flex flex-row">
                            <select className="form-control" disabled>
                              <option value="">{selectedParent ? selectedParent.divName : t("Loading ...")}</option>
                            </select>
                            <Button className="ml-1" onClick={() => openModal(true)} variant="outline-secondary">
                              <IoRepeatOutline fontSize={17} />
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    )}
                    <EntityFrom
                      key={entity.divId}
                      initialValues={entity}
                      parentId={entity.divParentId}
                      buttonText={t('Save')}
                      onSubmit={handleEditEntity}
                      disabled={editEntityState}
                      isEditing={true}
                      onDelete={handleDeleteEntity}
                    />
                  </Col>
                </Row>
              </Tab>
              <Tab
                eventKey="assignAssets"
                title={t('Assign Assets')}
                className="custom-tab-content"
                mountOnEnter={true}
                unmountOnExit={true}
              >
                <Row className="my-2">
                  <Col md={12} lg={12}>
                    <AssignAssets entityId={entityId} />
                  </Col>
                </Row>
              </Tab>
              <Tab
                eventKey="businessMeasures"
                title={t('Business Measures')}
                className="custom-tab-content"
                mountOnEnter={true}
                unmountOnExit={true}
              >
                <Row className="my-2">
                  <Col md={12} lg={12}>
                    <ManageEntityBusinessMeasure entityId={entityId} buttonText="Save" onSubmit={handleEditEntity} />
                  </Col>
                </Row>
              </Tab>
              <Tab
                eventKey="targetEmission"
                title={t('Target Emission')}
                className="custom-tab-content"
                mountOnEnter={true}
                unmountOnExit={true}
              >
                <Row className="my-2">
                  <Col md={12} lg={12}>
                    <ManageTargetEmission entityId={entityId} buttonText="Save" onSubmit={handleEditEntity} />
                  </Col>
                </Row>
              </Tab>
              <Tab
                eventKey="dataControl"
                title={t('Data Control')}
                className="custom-tab-content"
                mountOnEnter={true}
                unmountOnExit={true}
              >
                <Row className="my-2">
                  <Col md={12} lg={12}>
                    <ManageDataControl entityId={entityId} buttonText="Save" onSubmit={handleEditEntity} />
                  </Col>
                </Row>
              </Tab>
              <Tab
                eventKey="generalDocuments"
                title={t('General Documents')}
                className="custom-tab-content"
                mountOnEnter={true}
                unmountOnExit={true}
              >
                <Row className="my-2">
                  <Col md={12} lg={12}>
                    <GeneralDocuments entityId={entityId} />
                  </Col>
                </Row>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      )}
      {entityStatus === LoadingState.failed && <div>{t('Something Went Wrong')}</div>}
      <TreeViewModal
        initExpandLevel={0}
        tree={transformedTree}
        closeModal={closeModal}
        show={showEntitySelector}
        title={t('Select Entity')}
      />
      <Modal show={showConfirmationModal} onHide={closeConfirmationModal} backdrop="static">
        <Modal.Header>
          <Modal.Title>{t('Change the Entity Parent')}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: 'justify' }}>
          <Trans
            i18nKey="Do you want to change this entity parent from <bold>{{from}}</bold> to <bold>{{to}}</bold>"
            values={{ from: selectedParent?.divName, to: changeToParent?.divName }}
            components={{ bold: <strong /> }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              console.log('cacncel')
              closeConfirmationModal();
            }}
          >
            {t('Cancel')}
          </Button>
          <Button
            disabled={isLoading}
            onClick={changeParentHandler}
            variant="primary"
          >
            {!isLoading ? t('Ok') : '...'}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default EditEntity;
