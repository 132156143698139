import React, { useCallback, useEffect, useState } from 'react';
import { Col, Container, Row, Form, Button, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Select } from '../reports/configurations/Select';
import { updateReportUnit } from './OrganizationApi';
import { fetchUser, setLeavePrompt } from '../manage-users/usersSlice';
import { fetchReportUnits } from './OrganizationSlice';
import { LoadingState } from '../../utilities/constants';
import LoadingButton from '../../components/LoadingButton';

const ManageReportUnit = () => {
  const [isLoading, setIsLoading] = useState(false);
  const userInfo = useSelector((state) => state.users.user);
  const orgReportUnits = useSelector((state) => state.organizations.reportUnits);
  const orgUnitStatus = useSelector((state) => state.organizations.unitStatus);
  const history = useHistory();
  const [optReportUnits, setOptReportUnits] = useState(userInfo?.usrOrgOptions?.optReportUnits || {});
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isCanBack = useSelector((state) => state.users.historyStack.length > 1);
  const goBack = () => isCanBack ? history.goBack() : history.push('/');

  const onSubmitHandler = async () => {
    setIsLoading(true);
    try {
      await updateReportUnit(userInfo.usrOrgId, { ...optReportUnits });
      dispatch(setLeavePrompt(false));
      dispatch(await fetchUser({ userKeycloakId: userInfo.usrKeycloakId }));
      toast.success(t('The report units has been saved.'));
      setIsLoading(false);
    } catch (e) {
      toast.error(t('System error, please try again.'));
      setIsLoading(false);
    }
  };

  const getIsDirty = useCallback(() => {
    const saveReportUnits = userInfo?.usrOrgOptions?.optReportUnits || {};
    return !_.isEqual(saveReportUnits, optReportUnits);
  }, [optReportUnits, userInfo?.usrOrgOptions?.optReportUnits]);

  useEffect(() => {
    dispatch(fetchReportUnits(userInfo.usrOrgId));
  }, [dispatch, userInfo.usrOrgId]);

  useEffect(() => {
    const isDirty = getIsDirty();
    dispatch(setLeavePrompt(isDirty));
  }, [getIsDirty, dispatch]);

  const reportUnitChangeHandler = (event, category) => {
    const value = event.target.value ? Number(event.target.value) : '';
    setOptReportUnits({
      ...optReportUnits,
      [category]: value,
    });
  };

  const reportUnitList = (category) => {
    const listOptions = [
      { id: '', name: t('None') },
      ...orgReportUnits[category],
    ];
    return listOptions.map(item => ({
      value: item.id, label: item.name,
    }));
  };

  const renderReportCategoryUnits = () => {
    if (orgUnitStatus !== LoadingState.succeeded) {
      return <Spinner animation="grow" />
    }
    return _.keys(orgReportUnits).filter(category => category !== 'other').map((category) => (
      <Row key={`key-${category}`} className="report-units-input">
        <Col>
          <Form.Group className="custom-form-control report-year-range">
            <Select
              register={() => { }}
              label={t(category)}
              name={category}
              value={optReportUnits[category] || ''}
              options={reportUnitList(category)}
              onChange={(e) => reportUnitChangeHandler(e, category)}
            />
          </Form.Group>
        </Col>
      </Row>
    ));
  };

  return (
    <Container fluid>
      <Row className="my-2 manage-report-page">
        <Col>
          <h4>{t('Manage Report Units')}</h4>
          <Row className="my-2 mt-2">
            <Col md={8}>
              <Form>
                <Row>
                  <Col>
                    <Form.Group className="custom-form-control">
                      <em>{t('The selected units will be applied to every report where data is input within the system suggestion unit list. Consequently, data is converted as ratio standard. Otherwise, if the input unit is not selected in the system suggestion unit list, data can not be converted but keep as input.')}</em>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="report-units-input">
                  <Col>
                    <Form.Group className="custom-form-control report-year-range" style={{ marginBottom: '10px' }}>
                      <label class="form-label"><h5>{t('Category')}</h5></label>
                      <div class="form-control" style={{ border: 'none' }}><h5>{t('Default unit for report')}</h5></div>
                    </Form.Group>
                  </Col>
                </Row>
                {renderReportCategoryUnits()}
              </Form>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                className="mt-3 mb-5 mr-2"
                type="button"
                onClick={goBack}
                variant="secondary">
                {t('Cancel')}
              </Button>
              <Button
                onClick={onSubmitHandler}
                className="mt-3 mb-5 pr-10"
                type="submit"
                disabled={isLoading || !getIsDirty()}>
                {t('Save')}
              </Button>
              <LoadingButton className="mt-3 mb-5" isLoading={isLoading} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default ManageReportUnit;
