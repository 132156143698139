import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { LoadingState } from '../../utilities/constants';
import {
  fetchEpsGroupsApi,
  createEpsGroupApi,
  editEpsGroupApi,
  removeEpsGroupApi,
  fetchAssignedEpsForEpsGroupApi,
  fetchAvailableEpsToEpsGroupApi,
  assignEpsToEpsGroupApi,
} from './epsGroupsApi';

export const fetchEpsGroups = createAsyncThunk('epsGroups/fetchEpsGroups', async (payload) => {
  try {
    const response = await fetchEpsGroupsApi(payload.orgId);
    return response;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const createEpsGroup = createAsyncThunk('epsGroups/createEpsGroup', async (payload) => {
  try {
    const epsGroup = await createEpsGroupApi(payload.orgId, payload.data);
    toast.success('New emission-profile-group Created');
    return epsGroup;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const editEpsGroup = createAsyncThunk('epsGroups/editEpsGroup', async (payload, { dispatch }) => {
  try {
    const { orgId, epsGroupId, data } = payload;
    const epsGroup = await editEpsGroupApi(orgId, epsGroupId, data);
    toast.success('Successfully updated');
    dispatch(fetchEpsGroups({ orgId }));
    return epsGroup;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const removeEpsGroup = createAsyncThunk('epsGroups/removeEpsGroup', async (payload, { dispatch }) => {
  try {
    const { orgId, epsGroupId } = payload;
    const epsGroup = await removeEpsGroupApi(orgId, epsGroupId);
    dispatch(fetchEpsGroups({ orgId }));
    return epsGroup;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const fetchAssignedEpsForEpsGroup = createAsyncThunk(
  'epsGroups/fetchAssignedEpsForEpsGroup',
  async (payload) => {
    try {
      const response = await fetchAssignedEpsForEpsGroupApi(payload.orgId, payload.epsGroupId);
      return response;
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  }
);

export const fetchAvailableEpsToEpsGroup = createAsyncThunk(
  'epsGroups/fetchAvailableEpsToEpsGroup',
  async (payload) => {
    try {
      const response = await fetchAvailableEpsToEpsGroupApi(payload.orgId, payload.epsGroupId);
      return response;
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  }
);

export const assignMultiEmissionProfileSources = createAsyncThunk('epsGroups/assign-emission-profile-sources', async (payload, { dispatch }) => {
  try {
    const { orgId, epsGroupId, type, divIds } = payload;
    await assignEpsToEpsGroupApi(orgId, epsGroupId, { type, divIds });
    await dispatch(fetchAssignedEpsForEpsGroup({ orgId, epsGroupId }));
    await dispatch(fetchAvailableEpsToEpsGroup({ orgId, epsGroupId }));
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

const epsGroupSlice = createSlice({
  name: 'epsGroups',
  initialState: {
    epsGroups: [],
    assignedEpsToEpsGroup: [],
    availableEpsOfEntityGroup: [],
    status: LoadingState.idle,
    assignmentStatus: LoadingState.idle,
    error: null
  },
  reducers: {},
  extraReducers: {
    [fetchEpsGroups.pending]: (state) => {
      state.status = LoadingState.loading;
    },
    [fetchEpsGroups.fulfilled]: (state, action) => {
      state.status = LoadingState.succeeded;
      state.epsGroups = action.payload;
    },
    [fetchEpsGroups.rejected]: (state, action) => {
      state.status = LoadingState.failed;
      state.error = action.error.message;
    },
    [fetchAssignedEpsForEpsGroup.pending]: (state) => {
      state.assignmentStatus = LoadingState.loading;
    },
    [fetchAssignedEpsForEpsGroup.fulfilled]: (state, action) => {
      state.assignmentStatus = LoadingState.succeeded;
      state.assignedEpsToEpsGroup = action.payload;
    },
    [fetchAssignedEpsForEpsGroup.rejected]: (state, action) => {
      state.assignmentStatus = LoadingState.failed;
      state.error = action.error.message;
    },
    [fetchAvailableEpsToEpsGroup.fulfilled]: (state, action) => {
      state.availableEpsOfEntityGroup = action.payload;
    },
    [editEpsGroup.fulfilled]: (state, action) => {
      const existingIndex = state.epsGroups.findIndex((i) => i.epgId === action.payload.epsGroupId);
      if (existingIndex >= 0) state.epsGroups[existingIndex] = action.payload;
    }
  }
});

export const selectEpsGroupById = (state, epsGroupId) => {
  if (!state.epsGroups.epsGroups?.length === 0) return null;
  const parsedId = parseInt(epsGroupId);
  const found = state.epsGroups.epsGroups.find((a) => a.epgId === parsedId);
  return found ?? null;
};

export default epsGroupSlice.reducer;
