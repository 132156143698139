import React, { useState, useMemo } from 'react';
import _ from 'lodash';
import Table from 'react-bootstrap/Table';
import { useSelector } from 'react-redux';
import { fetchCarbonFootprintByFullEmissionProfile, fetchScopes } from '../reportsSlice';
import ReportContainer from '../ReportContainer';
import ScopeTableHeader from '../configurations/ScopeTableHeader';
import {
  formatNumber,
  getCommonFilterPeriodRange,
  getCurrentReportPeriod,
  getReportRangePeriod,
} from '../reportHelper';
import { useTranslation } from 'react-i18next';

const CarbonFootPrintByFullEmissionProfile = () => {
  const { t } = useTranslation();
  const carbonFootPrintByFullEmissionProfile = useSelector(
    (state) => state.reports.carbonFootPrintByFullEmissionProfile
  );
  const scopeTree = useSelector((state) => state.reports.scopesTree);
  const [sortInfo, setSortInfo] = useState({ name: 'total', isSortedDesc: true });
  const carbonFootPrintFullEmissionStatus = useSelector((state) => state.reports.carbonFootPrintFullEmissionStatus);

  const userInfo = useSelector((state) => state.users.user);
  const currentReportPeriod = getCurrentReportPeriod(userInfo.usrOrgOptions);
  const reportRangePeriod = getReportRangePeriod(userInfo.usrOrgOptions, currentReportPeriod.reportYear);

  const getFilterPeriodRangeHandler = (data) => {
    return getCommonFilterPeriodRange(data, reportRangePeriod);
  };

  const fetchCarbonFootprintByFullEmissionProfileHandler = async (dispatch, payload) => {
    await dispatch(fetchScopes(payload.orgId));
    return dispatch(fetchCarbonFootprintByFullEmissionProfile(payload));
  };

  const sortHandler = (name) => {
    if (sortInfo.name === name) {
      setSortInfo({ name, isSortedDesc: !sortInfo.isSortedDesc });
    } else {
      setSortInfo({ name, isSortedDesc: false });
    }
  };
  const getRecordValuesRows = (rowArr, indexC) => {
    return rowArr.map((row, index) => {
      return (
        <tr key={`rfeps-${indexC}${index}`}>
          <td key="c1">{row.scope}</td>
          <td key="c2">{row.eps}</td>
          <td key="c4">{formatNumber(row.usage)}</td>
          <td key="c3">{row.unit}</td>
          <td key="c5">{formatNumber(row.co2_e_t)}</td>
          <td key="c6">{formatNumber(row.per_co2_e_t)}</td>
        </tr>
      );
    });
  };

  const tableData = useMemo(() => {
    const findScopeById = (id) => {
      const search = scopeTree.find((item) => item.id === id);
      return search || {};
    };
    const { name, isSortedDesc } = sortInfo;
    const parentKey = 'scope';
    const childKey = name === 'scope' ? 'eps' : name;
    const data = _.orderBy(
      carbonFootPrintByFullEmissionProfile.map((item) => ({
        ...item,
        total: Number(item.total_per_co2_e_t),
        co2e: Number(item.total_co2_e_t),
        records: _.orderBy(
          _.map(item.records, (record) => ({
            ...record,
            total: Number(record.per_co2_e_t),
            co2e: Number(record.co2_e_t),
            usage: Number(record.usage)
          })),
          childKey,
          isSortedDesc ? 'desc' : 'asc'
        ),
        scope: findScopeById(item.scope_id).scope
      })),
      parentKey,
      'asc'
    );
    if (data.length) {
      const scopeNames = data.map(item => item.scope?.split(':')[0]?.trim());
      const lastScope = scopeNames.length > 1 ? scopeNames.pop() : '';
      const message = lastScope ? 'Total {{scopes}} and {{lastScope}} Emissions (tCO2-e)' : 'Total {{scopes}} Emissions (tCO2-e)';
      const summaryData = {
        isSummary: true,
        scope: t(message, { scopes: scopeNames.join(', '), lastScope }),
        records: [],
      };
      data.forEach(({ records }) => {
        records.forEach((item) => {
          ['per_co2_e_t', 'co2_e_t'].forEach((key) => {
            summaryData[`total_${key}`] = (summaryData[`total_${key}`] || 0) + Number(item[key]);
          });
        });
      });
      data.unshift(summaryData);
    }
    return data;
  }, [t, sortInfo, carbonFootPrintByFullEmissionProfile, scopeTree]);

  const tableRender = (tableRef) => {
    const headers = [
      { name: 'scope', title: t('Scope'), disabled: true },
      { name: 'eps', title: t('Emission Source') },
      { name: 'usage', title: t('Usage') },
      { name: 'unit', title: t('Units') },
      { name: 'co2e', title: t('CO2-e (t)') },
      { name: 'total', title: t('% Total CO2-e (t)') }
    ];
    return (
      <Table ref={tableRef} bordered hover id="data-tableFEP">
        <thead>
          <tr key="header">
            {headers.map((item) => (
              <ScopeTableHeader
                key={`c-${item.name}`}
                name={item.name}
                title={item.title}
                disabled={item.disabled}
                sortHandler={sortHandler}
                isSorted={sortInfo.name === item.name}
                isSortedDesc={sortInfo.isSortedDesc}
              />
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.map((rowData, index) => {
            return (
              <React.Fragment key={`fesp-${index}`}>
                <tr style={{ backgroundColor: '#004866', color: '#FFFFFF' }}>
                  <td>{rowData.isSummary ? rowData.scope : t('SubTotal for {{scope}}', { scope: rowData.scope })}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>{formatNumber(rowData.total_co2_e_t)}</td>
                  <td>{formatNumber(rowData.total_per_co2_e_t)}</td>
                </tr>
                {rowData.records ? getRecordValuesRows(rowData.records, index) : null}
              </React.Fragment>
            );
          })}
        </tbody>
      </Table>
    );
  };
  return (
    <ReportContainer
      isShowAllocation
      reportName={t('Carbon Footprint by Full Emission Profile')}
      reportSubTitle={t('Carbon Footprint by Full Emission Profile')}
      getFilterPeriodRange={getFilterPeriodRangeHandler}
      fetchReportData={fetchCarbonFootprintByFullEmissionProfileHandler}
      fetchReportStatus={carbonFootPrintFullEmissionStatus}
      reportData={tableData}
      reportRender={tableRender}
    />
  );
};

export default CarbonFootPrintByFullEmissionProfile;
