import { Fragment } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import sdgLogo from '../../../assets/images/sdg-logo.svg';

export function SDGPromptModal({ setSetupModalShow, showSDGPromptModal, setShowSDGPromptModal }) {
  const onClickHandle = () => {
    setShowSDGPromptModal(false);
    setSetupModalShow(true);
  };
  return (
    <Fragment>
      <Modal
        animation={false}
        show={showSDGPromptModal}
        onHide={() => {
          setShowSDGPromptModal(false);
        }}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        backdrop='static'
        className='activities-modal sdg-promt-modal'
      >
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body>
          <Row className='d-flex justify-content-center'>
            <Col lg='8'>
              <div className='text-center'>
                <img alt='card-logo' src={sdgLogo} />

                <h4>Sustainable Development Goals</h4>

                <p className='text-muted'>
                  Strengthen the sustainability performance of your business and learn about the Sustainable Development
                  Goals. The SDG tool will provide your organization with a report which demonstrates your alignment
                  with SDGs. In continuing with this process you accept the responsibility to fill in all of the KPIs
                  accurately. See what you can achieve here!
                </p>
              </div>
              <Button className='mt-4' onClick={onClickHandle}>
                Setup Now
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
}
