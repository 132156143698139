import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { LoadingState } from '../../utilities/constants';
import {
  fetchOrganizationDetailsApi,
  addBillSettingApi,
  fetchBillSettingApi,
  updateBillSettingApi,
  deleteBillSettingApi,
  fetchBillSettingByIdApi
} from './billSettingsApi';

export const fetchOrganizationDetails = createAsyncThunk('billSettings/organization', async () => {
  try {
    const response = await fetchOrganizationDetailsApi();
    return response;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const fetchOrgBillSettings = createAsyncThunk('billSettings/get-settings', async (payload) => {
  try {
    const { orgId } = payload;
    const response = await fetchBillSettingApi(orgId);
    return response;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const fetchOrgBillSetting = createAsyncThunk('billSettings/get-setting', async (payload) => {
  try {
    const { orgId, bsId } = payload;
    const response = await fetchBillSettingByIdApi(orgId, bsId);
    return response;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const addBillSetting = createAsyncThunk('billSettings/add', async (payload, { dispatch }) => {
  try {
    const { orgId, combinedData } = payload;
    await addBillSettingApi(orgId, combinedData);
    toast.success('New bill setting is created');
    dispatch(fetchOrgBillSettings({ orgId }));
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const updateBillSetting = createAsyncThunk('billSettings/update', async (payload, { dispatch }) => {
  try {
    const { orgId, bsId, data } = payload;
    await updateBillSettingApi(orgId, bsId, data);
    toast.success('Bill setting successfully updated');
    dispatch(fetchOrgBillSettings({ orgId }));
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const deleteBillSetting = createAsyncThunk('billSettings/delete', async (payload, { dispatch }) => {
  try {
    const { orgId, bsId } = payload;
    await deleteBillSettingApi(bsId);
    dispatch(fetchOrgBillSettings({ orgId }));
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const billSettingsSlice = createSlice({
  name: 'billSettings',
  initialState: {
    billSettings: [],
    billSetting: {},
    org: [],
    billSettingsStatus: LoadingState.idle,
    billSettingByIdStatus: LoadingState.idle,
    orgStatus: LoadingState.idle
  },
  reducers: {},
  extraReducers: {
    [fetchOrganizationDetails.pending]: (state) => {
      state.orgStatus = LoadingState.loading;
    },
    [fetchOrganizationDetails.fulfilled]: (state, action) => {
      state.orgStatus = LoadingState.succeeded;
      state.org = action.payload;
    },
    [fetchOrganizationDetails.rejected]: (state) => {
      state.orgStatus = LoadingState.failed;
    },
    [fetchOrgBillSettings.pending]: (state) => {
      state.billSettingsStatus = LoadingState.loading;
    },
    [fetchOrgBillSettings.fulfilled]: (state, action) => {
      state.billSettingsStatus = LoadingState.succeeded;
      state.billSettings = action.payload;
    },
    [fetchOrgBillSettings.rejected]: (state) => {
      state.billSettingsStatus = LoadingState.failed;
    },
    [fetchOrgBillSetting.pending]: (state) => {
      state.billSettingByIdStatus = LoadingState.loading;
    },
    [fetchOrgBillSetting.fulfilled]: (state, action) => {
      state.billSettingByIdStatus = LoadingState.succeeded;
      state.billSetting = action.payload;
    },
    [fetchOrgBillSetting.rejected]: (state) => {
      state.billSettingByIdStatus = LoadingState.failed;
    }
  }
});

// export const selectBillSettingById = (state, bsId) => {
//   if (state.billSettings.billSettings.length === 0) return null;
//   const parsedId = parseInt(bsId);
//   const found = state.billSettings.billSettings.find((bs) => bs.bsId === parsedId);
//   return found ?? null;
// };
export default billSettingsSlice.reducer;
