import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import React from 'react';

function Sorting(props) {
  return <>
    {!props.column.disableSortBy && !props.column.isSorted && (
      <span className='ml-1 table-sort-span'>
         <BiChevronUp className='table-sort-icon' />
         <BiChevronDown />
      </span>
    )}
    {props.column.isSorted ? (
      props.column.isSortedDesc ? (
        <span className='ml-1 table-sort-span'>
           <BiChevronUp className='table-sort-icon table-sort-selected' />
           <BiChevronDown />
        </span>
      ) : (
        <span className='ml-1 table-sort-span'>
           <BiChevronUp className='table-sort-icon' />
           <BiChevronDown className='table-sort-selected' />
        </span>
      )
    ) : (
      ''
    )}
  </>;
}

export default  Sorting;
