import { Col, ToggleButtonGroup, Container, ToggleButton, Row, Button, ButtonGroup, Spinner } from 'react-bootstrap';
import { React, useCallback } from 'react';
import { ImArrowUp, ImArrowDown } from 'react-icons/im';
import PageHeader from '../../components/PageHeader';
import { toast } from 'react-toastify';
// import { useTranslation } from 'react-i18next';
import { HiDownload } from 'react-icons/hi';
import { useState, useEffect, useMemo } from 'react';
import { LoadingState } from '../../utilities/constants';
import Empty from '../../components/Empty';
import DataTable from '../../components/DataTable';
import { useDispatch, useSelector } from 'react-redux';
import { getFullDataTemplates, updateDataTemplates } from './dataTemplatesSlice';
import { useTranslation } from 'react-i18next';

const ManageDataTemplates = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isOpenReOrder, setOpenReOrder] = useState(false);
  const [isOpenDataEls, setOpenDataEls] = useState(true);
  const dataTemplateStatus = useSelector((state) => state.dataTemplates.status);
  const orgId = useSelector((state) => state.users.user.usrOrgId);
  const customDataTemplateItems = useSelector((state) => state.dataTemplates.customDataTemplates);
  const dataTemplateItems = useSelector((state) => state.dataTemplates.dataTemplateItems);

  const currentCheckedItems = customDataTemplateItems.map(({ ecsvtemplateId, ecsvtemplatePosition }) => ({
    ecsvtemplateId,
    ecsvtemplatePosition
  }));

  const downloadReport = () => {
    const rows = [customDataTemplateItems.map(({ ecsvtemplateDataElement }) => ecsvtemplateDataElement)];
    const csvContent = 'data:text/csv;charset=utf-8,' + rows.map((e) => e.join(',')).join('\n');
    const encodedUri = encodeURI(csvContent);
    var link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'my_data.csv');
    document.body.appendChild(link); // Required for FF
    link.click();
  };
  async function handleCheck(evt, id) {
    const template = sortedDataTemplates.find((item) => item.ecsvtemplateId === id);

    if (!template) {
      toast.error('Error! Template Not Found');
      return;
    }

    if (!!evt) {
      const updatedTemplates = [
        ...currentCheckedItems,
        { ecsvtemplateId: id, ecsvtemplatePosition: template.ecsvtemplateDefaultPosition }
      ];
      await dispatch(updateDataTemplates({ organizationId: orgId, data: updatedTemplates }));
    } else {
      const updatedTemplates = currentCheckedItems.filter((dt) => dt.ecsvtemplateId !== id);
      await dispatch(updateDataTemplates({ organizationId: orgId, data: updatedTemplates }));
    }
  }
  const handleMoveDown = useCallback(
  (id) => {
    const template = customDataTemplateItems.find(({ ecsvtemplateId }) => ecsvtemplateId === id);
    if (!template) {
      toast.error('Error! Template Not Found');
      return;
    }
    const maxPosition = Math.max.apply(
      null,
      customDataTemplateItems.map((cdt) => cdt.ecsvtemplatePosition)
    );
    // if position is # already
    if (template.ecsvtemplatePosition === maxPosition) {
      return;
    } else {
      const nextPosition = template.ecsvtemplatePosition + 1;
      const updatedCheckedItems = currentCheckedItems.map((item) => {
        if (item.ecsvtemplatePosition === nextPosition) {
          return { ecsvtemplateId: item.ecsvtemplateId, ecsvtemplatePosition: item.ecsvtemplatePosition - 1 };
        }
        if (item.ecsvtemplateId === id) {
          return { ecsvtemplateId: item.ecsvtemplateId, ecsvtemplatePosition: nextPosition };
        }
        return item;
      });
      dispatch(updateDataTemplates({ organizationId: orgId, data: updatedCheckedItems }));
    }
  },[customDataTemplateItems, currentCheckedItems, dispatch, orgId])

  const handleMoveUp = useCallback(
  (id) => {
    const template = customDataTemplateItems.find(({ ecsvtemplateId }) => ecsvtemplateId === id);
    if (!template) {
      toast.error('Error! Template Not Found');
      return;
    }

    if (template.ecsvtemplatePosition === 1) {
      return;
    } else {
      const nextPosition = template.ecsvtemplatePosition - 1;
      const updatedCheckedItems = currentCheckedItems.map((item) => {
        if (item.ecsvtemplatePosition === nextPosition) {
          return { ecsvtemplateId: item.ecsvtemplateId, ecsvtemplatePosition: item.ecsvtemplatePosition + 1 };
        }
        if (item.ecsvtemplateId === id) {
          return { ecsvtemplateId: item.ecsvtemplateId, ecsvtemplatePosition: nextPosition };
        }
        return item;
      });
      dispatch(updateDataTemplates({ organizationId: orgId, data: updatedCheckedItems }));
    }
  },[currentCheckedItems,customDataTemplateItems, dispatch, orgId])
  
  const reOrderDataElColumns = useMemo(
    () => [
      {
        Header: t('No.'),
        accessor: 'ecsvtemplatePosition',
        id:'ecsvtemplatePosition',
        sortType: 'number'
      },
      {
        Header: t('Data Element'),
        accessor: 'ecsvtemplateDataElement',
        sortType: 'string'
      },
      {
        Header: t('Position'),
        accessor: (row) => {
          return (
            <ButtonGroup style={{outline:'none !important',outlineOffset:'none !important'}}>
              <Button variant="none" onClick={() => handleMoveDown(row.ecsvtemplateId)}>
                <ImArrowDown />
              </Button>
              <Button variant="none" onClick={() => handleMoveUp(row.ecsvtemplateId)}>
                <ImArrowUp />
              </Button>
            </ButtonGroup>
          );
        },
        id:'ecsvtemplateId',
      }
    ],
    [t,handleMoveDown,handleMoveUp]
  );
  const selectDataElColumns = useMemo(
    () => [
      {
        Header: t('Category'),
        accessor: 'ecsvtemplateCategoryId',
        sortType: 'string'
      },
      {
        Header: t('Data Elements'),
        accessor: 'ecsvtemplateDataElement',
        sortType: 'string'
      },
      {
        Header: t('Include'),
        accessor: 'ecsvtemplateMandatory',
        sortType: 'string',
        editable: true,
        actionColumn: true,
        Cell: (props) => {
          const { handleCheck, row, value } = props;
          return (
            <input
              onChange={(e) => handleCheck(e.target.checked, row.original.ecsvtemplateId)}
              type="checkbox"
              checked={!!row.original.isChecked || value === 'Y'}
              disabled={value === 'Y'}
            />
          );
        }
      }
    ],
    [t]
  );
  const sortedDataTemplates = dataTemplateItems
    .map((item) => {
      const isChecked = customDataTemplateItems
        .map((a) => a.ecsvtemplateId)
        .find((tmplId) => tmplId === item.ecsvtemplateId);
      return { ...item, isChecked: !!isChecked };
    })
    .sort((a, b) =>
      a.ecsvtemplateMandatory < b.ecsvtemplateMandatory ? 1 : b.ecsvtemplateMandatory < a.ecsvtemplateMandatory ? -1 : 0
    );

 
  useEffect(() => {
    if (dataTemplateStatus === LoadingState.idle) {
      dispatch(getFullDataTemplates(orgId));
    }
  }, [dispatch, orgId, dataTemplateStatus]);
  return (
    <Container fluid>
      <Row>
        <Col>
          <PageHeader
            title={t('Manage Data Template')}
            actions={
              <Button
                className="mr-1 d-flex flex-row justify-content-between align-items-center"
                variant="primary"
                onClick={() => downloadReport()}
              >
                <div className="mr-1">
                  <HiDownload fontSize={17} />
                </div>
                <div>{t('Generate CSV Template')}</div>
              </Button>
            }
          />
        </Col>
      </Row>
      <Row>
        <Col className="ml-2 mt-4 mb-4">
          <div className="d-inline-flex">
            <ToggleButtonGroup name="radio-group" type="radio" value={!!isOpenDataEls ? isOpenDataEls : isOpenReOrder}>
              <ToggleButton
                variant="secondary"
                active
                id="toggle-check"
                type="radio"
                value={isOpenDataEls}
                checked={isOpenDataEls}
                onClick={() => {
                  setOpenDataEls(true);
                  setOpenReOrder(false);
                }}
              >
                {t('Select Data Elements')}
              </ToggleButton>
              <ToggleButton
                id="toggle-check"
                variant="secondary"
                type="radio"
                value={isOpenReOrder}
                checked={isOpenReOrder}
                onClick={() => {
                  setOpenReOrder(true);
                  setOpenDataEls(false);
                }}
              >
                {t('Re-order Data Elements')}
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          {dataTemplateStatus === LoadingState.succeeded && !!sortedDataTemplates.length && !!isOpenDataEls && (
            <DataTable
              autoResetPage={false}
              columns={selectDataElColumns}
              data={sortedDataTemplates}
              actions={{ handleCheck }}
            />
          )}
          {dataTemplateStatus === LoadingState.succeeded && !!isOpenReOrder && (
            <DataTable
              defaultSort="ecsvtemplatePosition"
              isDescending={false}
              columns={reOrderDataElColumns}
              data={customDataTemplateItems}
              actions={{ handleMoveUp, handleMoveDown }}
            />
          )}
        </Col>
      </Row>

      {dataTemplateStatus === LoadingState.loading && <Spinner animation="grow" />}
      <Row>
        <Col>{dataTemplateStatus === LoadingState.failed && <Empty title="Sorry, Something went wrong" />}</Col>
      </Row>
    </Container>
  );
};
export default ManageDataTemplates;
