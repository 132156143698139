import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { LoadingState } from '../../utilities/constants';
import {
  fetchEmissionsByTop10EPSApi,
  fetchMovingAverageApi,
  fetchEntitiesApi,
  fetchEmissionsByEntityOrMetricApi,
  fetchCarbonFootprintByScopeApi,
  fetchScopesApi,
  fetchOrganizationEmissionSummaryApi,
  fetchOrganizationListYearsApi,
} from '../reports/reportsApi';

export const fetchOrganizationEmissionSummary = createAsyncThunk(
  'dashboard/fetchOrganizationEmissionSummary',
  async (payload) => {
    try {
      const response = await fetchOrganizationEmissionSummaryApi(payload.orgId, payload.data);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchOrganizationListYears = createAsyncThunk(
  'dashboard/fetchOrganizationListYears',
  async (payload) => {
    try {
      const response = await fetchOrganizationListYearsApi(payload.orgId, payload.data);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchMovingAverageDashboard = createAsyncThunk(
  'dashboard/fetchMovingAverageDashboard',
  async (payload) => {
    try {
      const response = await fetchMovingAverageApi(payload.orgId, payload.data);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchEmissionsByTop10EPSDashboard = createAsyncThunk(
  'dashboard/fetchEmissionsByTop10EPS',
  async (payload) => {
    try {
      const response = await fetchEmissionsByTop10EPSApi(payload.orgId, payload.data);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchEmissionFootprintOverviewByEntityDashboard = createAsyncThunk(
  'dashboard/fetchEmissionFootprintOverviewByEntity',
  async (payload) => {
    try {
      const response = await fetchEmissionsByEntityOrMetricApi(payload.orgId, payload.data);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchCarbonFootprintByScopeDashboard = createAsyncThunk(
  'dashboard/fetchCarbonFootprintByScope',
  async (payload) => {
    try {
      const response = await fetchCarbonFootprintByScopeApi(payload.orgId, payload.data);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchEntitiesDashboard = createAsyncThunk('dashboard/fetchEntities', async (payload) => {
  try {
    const response = await fetchEntitiesApi(payload.orgId, payload.selectedEntity);
    return response;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const fetchScopesDashboard = createAsyncThunk('dashboard/fetchScopes', async (orgId) => {
  try {
    const response = await fetchScopesApi(orgId);
    return response;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    movingAverageDashboard: {},
    emissionsByTop10EPSDashboard: [],
    emissionFootprintOverviewByEntityDashboard: [],
    carbonFootprintByScopeDashboard: [],
    entityList: null,
    scopesTreeDashboard: [],
    movingAverageStatusDashboard: LoadingState.idle,
    emissionsByTop10EPSStatusDashboard: LoadingState.idle,
    emissionFootprintOverviewByEntityStatusDashboard: LoadingState.idle,
    carbonFootprintByScopeStatusDashboard: LoadingState.idle,
    entityListStatus: LoadingState.idle,
    scopeTreeStatusDashboard: LoadingState.idle,
    organizationEmissionSummaryStatus: LoadingState.idle,
    organizationEmissionListYears: [],
    error: null,
    mvError: null,
    efobeError: null,
    cfbsError: null,
    eTop10epsError: null
  },
  reducers: {},
  extraReducers: {
    [fetchMovingAverageDashboard.pending]: (state) => {
      state.movingAverageStatusDashboard = LoadingState.loading;
    },
    [fetchMovingAverageDashboard.fulfilled]: (state, action) => {
      state.movingAverageStatusDashboard = LoadingState.succeeded;
      state.movingAverageDashboard = action.payload;
    },
    [fetchMovingAverageDashboard.rejected]: (state, action) => {
      state.movingAverageStatusDashboard = LoadingState.failed;
      state.mvError = action.error.message;
    },
    [fetchEmissionsByTop10EPSDashboard.pending]: (state) => {
      state.emissionsByTop10EPSStatusDashboard = LoadingState.loading;
    },
    [fetchEmissionsByTop10EPSDashboard.fulfilled]: (state, action) => {
      state.emissionsByTop10EPSStatusDashboard = LoadingState.succeeded;
      state.emissionsByTop10EPSDashboard = action.payload;
    },
    [fetchEmissionsByTop10EPSDashboard.rejected]: (state, action) => {
      state.emissionsByTop10EPSStatusDashboard = LoadingState.failed;
      state.eTop10epsError = action.error.message;
    },
    [fetchEntitiesDashboard.pending]: (state) => {
      state.entityListStatus = LoadingState.loading;
    },
    [fetchEntitiesDashboard.fulfilled]: (state, action) => {
      state.entityListStatus = LoadingState.succeeded;
      state.entityList = action.payload;
    },
    [fetchEntitiesDashboard.rejected]: (state, action) => {
      state.entityListStatus = LoadingState.failed;
      state.error = action.error.message;
    },
    [fetchEmissionFootprintOverviewByEntityDashboard.pending]: (state) => {
      state.emissionFootprintOverviewByEntityStatusDashboard = LoadingState.loading;
    },
    [fetchEmissionFootprintOverviewByEntityDashboard.fulfilled]: (state, action) => {
      state.emissionFootprintOverviewByEntityStatusDashboard = LoadingState.succeeded;
      state.emissionFootprintOverviewByEntityDashboard = action.payload;
    },
    [fetchEmissionFootprintOverviewByEntityDashboard.rejected]: (state, action) => {
      state.emissionFootprintOverviewByEntityStatusDashboard = LoadingState.failed;
      state.efobeError = action.error.message;
    },
    [fetchCarbonFootprintByScopeDashboard.pending]: (state) => {
      state.carbonFootprintByScopeStatusDashboard = LoadingState.loading;
    },
    [fetchCarbonFootprintByScopeDashboard.fulfilled]: (state, action) => {
      state.carbonFootprintByScopeStatusDashboard = LoadingState.succeeded;
      state.carbonFootprintByScopeDashboard = action.payload;
    },
    [fetchCarbonFootprintByScopeDashboard.rejected]: (state, action) => {
      state.carbonFootprintByScopeStatusDashboard = LoadingState.failed;
      state.cfbsError = action.error.message;
    },
    [fetchScopesDashboard.pending]: (state) => {
      state.scopeTreeStatusDashboard = LoadingState.loading;
    },
    [fetchScopesDashboard.fulfilled]: (state, action) => {
      state.scopeTreeStatusDashboard = LoadingState.succeeded;
      state.scopesTreeDashboard = action.payload;
    },
    [fetchScopesDashboard.rejected]: (state, action) => {
      state.scopeTreeStatusDashboard = LoadingState.failed;
      state.error = action.error.message;
    },
    [fetchOrganizationEmissionSummary.pending]: (state) => {
      state.organizationEmissionSummaryStatus = LoadingState.loading;
    },
    [fetchOrganizationEmissionSummary.fulfilled]: (state, action) => {
      state.organizationEmissionSummaryStatus = LoadingState.succeeded;
      state.organizationEmissionSummary = action.payload;
    },
    [fetchOrganizationListYears.fulfilled]: (state, action) => {
      state.organizationEmissionListYears = action.payload.data;
    },
    [fetchOrganizationEmissionSummary.rejected]: (state, action) => {
      state.organizationEmissionSummaryStatus = LoadingState.failed;
      state.error = action.error.message;
    }
  }
});

export default dashboardSlice.reducer;
