import styled from 'styled-components';

export const Legend = styled.div`
  /* position: absolute; */
  li span {
    display: inline-block;
    width: 36px;
    height: 12px;
    margin-right: 5px;
  }
  ul {
    overflow: hidden;
    width: 100%;
  }
  li {
    cursor: pointer;
    width: 33.33%;
    float: left;
    list-style: none;
  }
`;

export const ChartContainer = styled.div`
  width: 100%;
`;

export const LegendContainer = styled.div`
  height: 100%;
  overflow: auto;
  padding-top: 40px;
`;
