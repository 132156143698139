import { HorizontalBar } from 'react-chartjs-2';
import { Fragment, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export function SDGGoalSummaryGraph({ sDGGoalSummaryGraphData }) {
  const { t } = useTranslation();
  const chartRef = useRef(null);
  const options = {
    maintainAspectRatio: false,
    legend: {
      display: false,
      position: 'bottom'
    },
    scales: {
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: t('Overall Performance(%)')
          },
          ticks: {
            beginAtZero: true,
            stepSize: 10,
            padding: 5
          },
          gridLines: {
            drawTicks: false,
        }
        }
      ],
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: t('Goals'),
          },
          gridLines: {
            display: false,
            drawTicks: false,
        },
        ticks: {
          padding: 15
        }
        }
      ]
    },
    // indexAxis: 'y',
    legendCallback: function (chart) {
      if (chart) {
        var ul = document.createElement('ul');

        chart.data.labels.forEach(function (label, datasetIndex) {
          // const value = chart.data.datasets[0].data[datasetIndex] || 0;
          ul.innerHTML += `
            <li key='${datasetIndex}'>
               <span style="background-color: ${chart.data.datasets[0].backgroundColor[datasetIndex]}"></span>
               ${label}
            </li> `;
        });
        return ul.outerHTML;
      }
    },
    hover: {
      animationDuration: 0
    }
  };

  const data = {
    labels: sDGGoalSummaryGraphData.map((item) => t('{{orderNo}}. {{goalName}}', { goalName: t(item.goalName), orderNo: item.index + 1})),
    datasets: [
      {
        data: sDGGoalSummaryGraphData.map((item) => item.goal),
        backgroundColor: [
          '#E5243B',
          '#DEA63B',
          '#4D9E38',
          '#C41A2E',
          '#FF3B21',
          '#26BDE3',
          '#FCC20A',
          '#A31A42',
          '#FC6926',
          '#DE1266',
          '#FC9E24',
          '#BF8C2E',
          '#407D45',
          '#0A96D9',
          '#57BF2B',
          '#00699E',
          '#1A476B'
        ]
      }
    ]
  };

  return (
    <Fragment>
      <Row>
        <Col md={12} id="goal-summary-chart">
          <HorizontalBar ref={chartRef} options={options} data={data} />
        </Col>
      </Row>
    </Fragment>
  );
}
