import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import PageHeader from '../../components/PageHeader';
import { LoadingState } from '../../utilities/constants';
import AssetForm from './AssetForm';
import { createAssetApi } from './assetsApi';
import { toast } from 'react-toastify';

const NewAsset = () => {
  const [saveAssetState, setSaveAssetState] = useState(LoadingState.idle);
  const history = useHistory();
  const isBusy = saveAssetState === LoadingState.loading;
  const orgId = useSelector((state) => state.users.user.usrOrgId);

  const handleCreateAsset = async (data) => {
    try {
      setSaveAssetState(LoadingState.loading);
      await createAssetApi(orgId, data);
      toast.success('New asset is created');
      history.push('/admin/setup/assets');
    } catch (err) {
      setSaveAssetState(LoadingState.idle);
      toast.error(err.message);
      throw err;
    }
  };

  const handleCancelAsset = () => {
    history.push('/admin/setup/assets');
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <PageHeader title="Create Asset" />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={8}>
          <AssetForm onSubmit={handleCreateAsset} buttonText="Create" disabled={isBusy} onCancel={handleCancelAsset} />
        </Col>
      </Row>
    </Container>
  );
};

export default NewAsset;
