import { unwrapResult } from '@reduxjs/toolkit';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDocuments, generateAttachmentDownloadUrl } from './entitySlice';
import { IoTrashOutline } from 'react-icons/io5';
import { IoDownloadOutline } from 'react-icons/io5';
import { FiUpload } from 'react-icons/fi';
import DataTable from '../../components/DataTable';
import { LoadingState } from '../../utilities/constants';
import { Trans, useTranslation } from 'react-i18next';
import { createDocumentApi, deleteAttachmentApi, uploadDocumentApi } from './entityApi';
import LoadingButton from '../../components/LoadingButton';
import { toast } from 'react-toastify';

const GeneralDocuments = ({ entityId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showUploadFileModal, setShowUploadFileModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileDescription, setSelectedFileDescription] = useState();
  const [isUploading, setIsUploading] = useState(false);
  const [showDeleteWarningModal, setShowDeleteWarningModal] = useState(false);
  const [deleteFileRow, setDeleteFileRow] = useState(null);
  const [deleteFileName, setDeleteFileName] = useState('');
  const documents = useSelector((state) => state.entities.documents);
  const status = useSelector((state) => state.entities.generalDocumentStatus);
  const orgId = useSelector((state) => state.users.user.usrOrgId);

  const canUpload = selectedFile && selectedFileDescription && !isUploading;
  const handleDeleteDocument = (row) => {
    setDeleteFileRow(row);
    setDeleteFileName(row.name);
    setShowDeleteWarningModal(true);
  };
  const handleConfirmDeleteFile = async () => {
    try {
      setIsUploading(true);
      await deleteAttachmentApi(orgId, entityId, deleteFileRow.id);
      dispatch(fetchDocuments({ orgId, entityId }));
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsUploading(false);
      setDeleteFileRow(null);
      setDeleteFileName('');
      setShowDeleteWarningModal(false);
    }
  };
  const openModal = () => setShowUploadFileModal(true);
  const closeModal = () => {
    setShowUploadFileModal(false);
    setSelectedFileDescription(null);
  };
  const closeConfirmModal = () => setShowDeleteWarningModal(false);
  const handleSelectFile = (e) => setSelectedFile(e.target.files[0]);
  const handleUploadDocument = async () => {
    setIsUploading(true);
    try {
      const { key, name } = await uploadDocumentApi(orgId, entityId, selectedFile);
      await createDocumentApi(orgId, entityId, { key, name, desc: selectedFileDescription });
      dispatch(fetchDocuments({ orgId, entityId }));
      closeModal();
      setSelectedFile(null);
      setSelectedFileDescription(null);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsUploading(false);
    }
  };

  const handleFileDownload = useCallback(
    async (uploadedFileId) => {
      try {
        const resultAction = await dispatch(
          generateAttachmentDownloadUrl({ entityId: parseInt(entityId), uploadedFileId: uploadedFileId })
        );
        const response = unwrapResult(resultAction);
        return response;
      } catch (error) {}
    },
    [entityId, dispatch]
  );

  const tableColumns = useMemo(
    () => [
      {
        Header: t('File Name'),
        accessor: 'name',
        sortType: 'string'
      },
      {
        Header: t('Description'),
        accessor: 'description',
        sortType: 'string'
      },
      {
        Header: '',
        accessor: 'actions',
        actionColumn: true,
        disableGlobalFilter: false,
        disableSortBy: true,
        Cell: (props) => {
          const { handleDeleteDocument, row } = props;
          return (
            <div className="d-flex action-buttons">
              <DownloadHelper getLinkObject={async () => await handleFileDownload(row.original.id)} />
              <Button size="sm" variant="outline-secondary" onClick={() => handleDeleteDocument(row.original)}>
                <IoTrashOutline />
              </Button>
            </div>
          );
        }
      }
    ],
    [handleFileDownload, t]
  );

  useEffect(() => {
    dispatch(fetchDocuments({ orgId, entityId }));
  }, [orgId, entityId, dispatch]);

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <h5 className="my-3 text-light">{t('General Documents')}</h5>
        <Button size="sm" variant="secondary" onClick={openModal}>
          <FiUpload className="mr-1" />
          {t('Upload File')}
        </Button>
      </div>
      {status === LoadingState.succeeded && (
        <DataTable columns={tableColumns} data={documents} actions={{ handleDeleteDocument }} />
      )}
      <Modal show={showUploadFileModal} onHide={closeModal} className="sisde-modal">
        <Modal.Header>
          <Modal.Title>{t("Upload Document")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.File id="exampleFormControlFile1" label={t("Select File")} onChange={handleSelectFile} />
            </Form.Group>
            <Form.Group className="custom-form-control">
              <Form.Label> {t("Description")} *</Form.Label>
              <Form.Control
                value={selectedFileDescription}
                onChange={(e) => setSelectedFileDescription(e.target.value)}
                type="text"
                as="textarea"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <LoadingButton isLoading={isUploading} />
          <Button variant="secondary" onClick={closeModal}>
            {t('Cancel')}
          </Button>
          <Button variant="primary" onClick={handleUploadDocument} disabled={!canUpload}>
            {t('Upload File')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeleteWarningModal} onHide={closeConfirmModal} className="sisde-modal">
        <Modal.Header>
          <Modal.Title>{t('Warning!')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <Trans
              i18nKey="Are you sure that you want to permanently delete <bold>{{fileName}}</bold>?"
              values={{ fileName: deleteFileName }}
              components={{ bold: <strong /> }}
            />
          </p>
        </Modal.Body>
        <Modal.Footer>
          <LoadingButton isLoading={isUploading} />
          <Button variant="danger" onClick={handleConfirmDeleteFile} disabled={isUploading}>
            {t('Delete')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const DownloadHelper = ({ getLinkObject }) => {
  const handleDownload = async () => {
    const result = await getLinkObject();
    const blob = await fetch(result.objectUrl).then((r) => r.blob());
    const blobUrl = URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    document.body.appendChild(anchor);
    anchor.href = blobUrl;
    anchor.setAttribute('download', result.fileName);
    anchor.click();
    anchor.target = '_blank';
    anchor.parentNode.removeChild(anchor);
  };
  return (
    <Button size="sm" className="mr-1" variant="outline-secondary" onClick={handleDownload}>
      <IoDownloadOutline />
    </Button>
  );
};

export default GeneralDocuments;
