import React, { useEffect, useState, useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import PageHeader from '../../components/PageHeader';
import { LoadingState } from '../../utilities/constants';
import OrganizationForm from './OrganizationForm';
import { toast } from 'react-toastify';
import { fetchOrganizations, selectOrganizationById } from './OrganizationSlice';
import { useTranslation } from 'react-i18next';
import { checkUserEmailVerification, resendUserEmailVerification } from '../manage-users/usersApi';
import { editOrganizationApi } from './OrganizationApi';

const EditOrganization = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const timeRef = useRef(null);
  const history = useHistory();
  const { orgId } = useParams();
  const [saveState, setSaveState] = useState(LoadingState.idle);
  const [isResendEmail, setIsResendEmail] = useState(0);
  const [resendDelayCount, setResendDelayCount] = useState(false);
  const [requestedId, setRequestedId] = useState(false);
  const stateStatus = useSelector((state) => state.organizations.status);
  const organization = useSelector((state) => selectOrganizationById(state, orgId));
  const isBusy = saveState === LoadingState.loading;
  const userOrgId = useSelector((state) => state.users.user.usrOrgId);

  const handleEdit = async (data) => {
    try {
      setSaveState(LoadingState.loading);
      await editOrganizationApi(orgId, data);
      toast.success('organizations successfully updated');
      history.push('/rootadmin/setup/organization');
    } catch (e) {
      setSaveState(LoadingState.idle);
      toast.error(e.message);
      throw e;
    }
  };

  const handleResendEmail = async () => {
    if (!isResendEmail || !organization?.rootUser?.usrKeycloakId) {
      return;
    }
    setIsResendEmail(0);
    try {
      const res = await resendUserEmailVerification(userOrgId, organization?.rootUser?.usrKeycloakId);
      setIsResendEmail(res.success);
      setResendDelayCount(res.delayTime);
      toast.success('An activation email is sent');
    } catch (ex) {
      console.log(ex);
      toast.error('An error occurred during activation, please try again!');
    }
  };

  const handleCancel = () => {
    history.push('/rootadmin/setup/organization');
  };

  useEffect(() => {
    if (stateStatus === LoadingState.idle) {
      dispatch(fetchOrganizations());
    }
  }, [stateStatus, dispatch]);

  useEffect(() => {
    return () => {
      if (timeRef.current) {
        timeRef.current = clearInterval(timeRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const { usrKeycloakId } = organization?.rootUser || {};
    if (requestedId !== usrKeycloakId && userOrgId && usrKeycloakId) {
      setRequestedId(usrKeycloakId);
      checkUserEmailVerification(userOrgId, usrKeycloakId)
        .then((res) => {
          setIsResendEmail(!res.emailVerified);
          setResendDelayCount(!res.emailVerified ? res.delayTime : 0);
        });
    }
  }, [organization?.rootUser, userOrgId, requestedId]);

  useEffect(() => {
    if (resendDelayCount && !timeRef.current) {
      const id = setInterval(() => {
        setResendDelayCount(prevCount => prevCount - 1);
      }, 1000);
      timeRef.current = id;
    } else if (resendDelayCount <= 1 && timeRef.current) {
      timeRef.current = clearInterval(timeRef.current);
      setResendDelayCount(0);
    }
    if (resendDelayCount) {
      console.log('Retry in:', resendDelayCount);
    }
  }, [resendDelayCount]);

  const initialValues = organization ? {
    ...organization,
    usrEmail: organization?.rootUser?.usrName || organization?.rootUser?.usrEmail,
    usrFirstname: organization?.rootUser?.usrFirstname,
    usrLastname: organization?.rootUser?.usrLastname,
  } : {};
  return (
    <Container fluid>
      <Row>
        <Col>
          <PageHeader title={'Update Organization'} />
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          {stateStatus === LoadingState.succeeded && organization && (
            <OrganizationForm
              onSubmit={handleEdit}
              onResendEmail={handleResendEmail}
              resendDelayCount={resendDelayCount}
              buttonText={t('Save')}
              initialValues={initialValues}
              disabled={isBusy}
              isEditing
              onCancel={handleCancel}
              isResendEmail={isResendEmail}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default EditOrganization;
