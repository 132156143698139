import { Form } from 'react-bootstrap';
import React from 'react';
import { Controller } from 'react-hook-form';

export function Check({ control, name, label, formLabellabel, disabled }) {
  return (
    <div className="configuration-col">
      <Form.Group className="custom-form-control">
        <Form.Label className="d-flex">{formLabellabel}</Form.Label>
        <Controller
          name={name}
          control={control}
          render={({ field }) => {
            const parsed = field.value === true ? true : false;
            const format = () => {
              field.onChange(field.value === true ? false : true);
            };
            return (
              <Form.Group className="custom-form-control">
                <Form.Check custom id={name} onChange={format} checked={parsed} label={label} disabled={!!disabled} />
              </Form.Group>
            );
          }}
        />
      </Form.Group>
    </div>
  );
}
