import React, { useState } from 'react';
import Table from 'react-bootstrap/Table';
import { Form } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { fetchDataGap } from '../reportsSlice';
import { Select } from '../configurations/Select';
import { fetchAssets, convertToTree } from '../../assets/assetsSlice';
import { TreeViewSelector } from '../configurations/TreeViewSelector';
import ReportContainer from '../ReportContainer';
import {
  formatNumber,
  getCommonFilterPeriodRange,
  parseFavouriteData,
} from '../reportHelper';
import { useTranslation } from 'react-i18next';
import { subYears } from 'date-fns';

const DataGap = () => {
  const { t } = useTranslation();
  const { filterData } = parseFavouriteData(useLocation().search);
  const [selectedAsset, setSelectedAsset] = useState(filterData ? filterData.assetIdsToInclude || [] : []);
  const [emissionTypeId, setEmissionTypeId] = useState(filterData ? filterData.emissionTypeId || 1 : 1);
  const [showAssetColumn, setShowAssetColumn] = useState(filterData ? !!filterData.showAssetColumn : false);

  const dataGap = useSelector((state) => state.reports.dataGap);
  const dataGapStatus = useSelector((state) => state.reports.dataGapStatus);
  const assetTree = useSelector((state) => convertToTree(state));

  const fetchDataGapHandler = async (dispatch, payload) => {
    if (!payload.data?.emissionTypeId) {
      payload.data.emissionTypeId = Number(emissionTypeId);
    }
    payload.data.assetIdsToInclude = selectedAsset;
    return await dispatch(fetchDataGap(payload));
  };

  const fetchRelatedDataHandler = async (dispatch, payload) => {
    dispatch(fetchAssets(payload));
  };

  const getFavouriteFilterDataHandler = (params, formData) => {
    if (formData) {
      setEmissionTypeId(formData.emissionTypeId);
    }
    return {
      ...params,
      assetIdsToInclude: selectedAsset,
      emissionTypeId: formData ? Number(formData.emissionTypeId) : emissionTypeId,
      showAssetColumn,
    };
  };

  const emissionTypeIdOptions = [
    { label: t('CO2-e (t)'), value: '1' },
    { label: t('Usage'), value: '2' },
  ];

  const getBuckets = () => (dataGap.length ? dataGap[0].monthlyBuckets.map((b) => b.period) : []);

  const extraConfigRender = (isFavReport) => {
    return (
      <>
        <Select
          disabled={isFavReport}
          label={t("CO2-e (t) or Usage")}
          name="emissionTypeId"
          options={emissionTypeIdOptions}
        />
        <div className="configuration-col" key="ext-cdg-1">
          <Form.Group className="custom-form-control">
            <Form.Label className="d-flex">{t('Assets column')}</Form.Label>
            <Form.Group className="custom-form-control">
              <Form.Check
                custom
                id="divEnabled"
                onChange={(e) => setShowAssetColumn(e.target.checked)}
                checked={showAssetColumn}
                label={t("Include")}
                disabled={isFavReport}
              />
            </Form.Group>
          </Form.Group>
        </div>
        <TreeViewSelector
          key="ext-cdg-2"
          label={t("Asset")}
          options={assetTree}
          selectedAsset={selectedAsset}
          setSelectedAsset={setSelectedAsset}
        />
      </>
    );
  };

  const tableRender = (tableRef) => {
    return (
      <Table bordered hover className="responsive-table" ref={tableRef} id="data-table">
        <thead>
          <tr>
            <th>{t('Entity')}</th>
            <th>{t('Emission Source')}</th>
            {showAssetColumn && <th>{t('Asset')}</th>}
            <th>{t('Unit')}</th>
            <th>{t('Measure')}</th>
            {getBuckets().map((b, index) => (
              <th key={index}>{b}</th>
            ))}
            <th>{t('Total')}</th>
            <th>{t('Total Days')}</th>
            <th>{t('Daily Average')}</th>
            <th>{t('Missing Data Flag')}</th>
          </tr>
        </thead>
        <tbody>
          {dataGap.map((d, index) => {
            return (
              <tr key={index}>
                <td>{d.divName}</td>
                <td>{d.epsName}</td>
                {showAssetColumn && <td>{d.assetName}</td>}
                <td>{d.unit}</td>
                <td>{d.measure}</td>
                {d.monthlyBuckets.map((m, index) => (
                  <td key={`${index}-td`} style={{ color: m.missing ? 'red' : '' }}>
                    {m.value ? formatNumber(m.value) : '-'}
                  </td>
                ))}
                <td>{formatNumber(d.total)}</td>
                <td>{formatNumber(d.totalDays)}</td>
                <td>{formatNumber(d.average)}</td>
                <td>{d.missing}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  };

  const customDateRangeHandler = (data) => getCommonFilterPeriodRange(data, {
    startDate: subYears(new Date(), 1),
    endDate: new Date()
  });

  return (
    <ReportContainer
      reportName={t('Data Gap')}
      reportSubTitle={t('Data Gap Report')}
      fetchReportData={fetchDataGapHandler}
      fetchReportStatus={dataGapStatus}
      reportData={dataGap}
      downloadOptions={['csv']}
      reportRender={tableRender}
      extraConfigRender={extraConfigRender}
      getFavouriteFilterData={getFavouriteFilterDataHandler}
      fetchRelatedData={fetchRelatedDataHandler}
      getFilterPeriodRange={customDateRangeHandler}
    />
  );

};

export default DataGap;
