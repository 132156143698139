import { config } from '../../utilities/config';
import keycloak from '../../keycloak';

export async function fetchUserApi(userKeycloakId) {
  const response = await fetch(`${config.API_BASE_URL}/user/${userKeycloakId}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    }
  });
  const data = await response.json();
  if (!response.ok) throw new Error(data.message);
  return data;
}

export async function checkUserEmailVerification(organizationId, userKeycloakId) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/users/email-verification/${userKeycloakId}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    }
  });
  const data = await response.json();
  if (!response.ok) throw new Error(data.message);
  return data;
}

export async function resendUserEmailVerification(organizationId, usrKeycloakId) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/users/email-verification`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`,
    },
    body: JSON.stringify({ usrKeycloakId }),
  });
  const data = await response.json();
  if (!response.ok) throw new Error(data.message);
  return data;
}

export async function fetchUsersApi(organizationId) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/users`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    }
  });
  const data = await response.json();
  if (!response.ok) throw new Error(data.message);
  return data;
}

export async function createUserApi(organizationId, data) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/users`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function editUserApi(organizationId, userId, data) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/users/${userId}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  if (!response.ok) throw new Error(response.statusText);
  return result;
}

export async function removeUserApi(organizationId, userId) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/users/${userId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    }
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}
