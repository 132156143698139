import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Spinner, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingState } from '../../utilities/constants';
import Select from 'react-select';
import {
  fetchTargetEmissions,
  createTargetEmission,
  editTargetEmission,
  removeTargetEmission
} from './targetEmissionSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import AddRowTargetEmissionForm from './AddRowTargetEmissionForm';
import DataTable, { getTargetUpdateValue } from '../../components/DataTable';
import ReactDatePicker from 'react-datepicker';
import { datePickerValue, formatDate, parseUserInfo } from '../../utilities/common';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { IoCloseOutline } from 'react-icons/io5';
import { IoSaveOutline } from 'react-icons/io5';
import { AiOutlineEdit } from 'react-icons/ai';
import { IoTrashOutline } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import { fetchBusinessMeasures } from '../business-measure/businessMeasuresSlice';
import { fetchEntityBusinessMeasures } from '../entity-business-measure/entityBusinessMeasureSlice';

const ManageTargetEmission = ({ entityId }) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const targetEmissionStatus = useSelector((state) => state.targetEmissions.status);
  const targetEmissions = useSelector((state) => state.targetEmissions.targetEmissions);
  const bsnsMeasures = useSelector((state) => state.businessMeasures.businessMeasures);
  const entityBusinessMeasuresStatus = useSelector((state) => state.entityBusinessMeasures.status);
  const entityBusinessMeasures = useSelector((state) => state.entityBusinessMeasures.entityBusinessMeasures);
  const businessMeasuresStatus = useSelector((state) => state.businessMeasures.status);
  const { orgId, usrDateFormat } = parseUserInfo(useSelector((state) => state.users.user));
  const loggedUserStatus = useSelector((state) => state.users.loggedUserStatus);

  const schema = yup.object().shape(
    {
      trgtemissnStartDate: yup.date().required("Start Date Can't Be Empty").typeError("Start Date Can't Be Empty"),
      trgtemissnEndDate: yup
        .date()
        .typeError("End Date Can't Be Empty")
        .min(yup.ref('trgtemissnStartDate'), "End Date Can't Be Before Start Date")
        .required("End Date Can't Be Empty"),
      trgtemissnType: yup.string().required("Target Emission Type Can't Be Empty"),
      trgtemissnEmissnData: yup
        .string()
        .test(
          'notBothAtTheSameTime',
          'Target Emission and Target Emission Reduction (%) cannot be added at once',
          function (trgtemissnEmissnData) {
            const { trgtemissnEmissnReduction } = this.parent;
            if (trgtemissnEmissnReduction && trgtemissnEmissnData) {
              return false;
            }
            return true;
          }
        )
        .when(['trgtemissnEmissnReduction'], {
          is: (trgtemissnEmissnReduction) => !trgtemissnEmissnReduction,
          then: yup.string().required('Emission data is required')
        }),
      trgtemissnEmissnReduction: yup
        .string()
        .test(
          'notBothAtTheSameTime',
          'Target Emission and Target Emission Reduction (%) cannot be added at once',
          function (trgtemissnEmissnReduction) {
            const { trgtemissnEmissnData } = this.parent;
            if (trgtemissnEmissnData && trgtemissnEmissnReduction) {
              return false;
            }
            return true;
          }
        )
        .when(['trgtemissnEmissnData'], {
          is: (trgtemissnEmissnData) => !trgtemissnEmissnData,
          then: yup.string().required('Emission reduction is required')
        }),
      trgtemissnTargetScope: yup.string().required("Target Scope Can't Be Empty"),
      trgtemissnDesc: yup.string()
    },
    [['trgtemissnEmissnData', 'trgtemissnEmissnReduction']]
  );
  let targetEmissionScopeOptions = useMemo(() => [{ value: 'Emissions', label: 'Emissions' }], []);

  useEffect(() => {
    if (loggedUserStatus === LoadingState.succeeded) {
      dispatch(fetchTargetEmissions({ orgId, entityId }));
      dispatch(fetchEntityBusinessMeasures({ orgId, entityId }));
      dispatch(fetchBusinessMeasures({ orgId }));
    }
  }, [orgId, loggedUserStatus, entityId, dispatch]);

  const getEntityBusinessMeasuresOptions = useCallback(() => {
    // let formattedEntityBusinessMeasures = [
    //   ...new Map(entityBusinessMeasures.map((item) => [item['label'], item])).values()
    // ];
    return entityBusinessMeasures.map((eBusinessMeasure) => {
      const filteredBS = bsnsMeasures.find(
        (businessMeasure) => businessMeasure.bsnsmsrtypId === eBusinessMeasure.bsnsmsrBsnsmsrtypId
      );
      return { value: filteredBS.bsnsmsrtypName, label: filteredBS.bsnsmsrtypName };
    });
  }, [bsnsMeasures, entityBusinessMeasures]);

  useEffect(() => {
    if (
      loggedUserStatus === LoadingState.succeeded &&
      businessMeasuresStatus === LoadingState.succeeded &&
      entityBusinessMeasuresStatus === LoadingState.succeeded
    ) {
      getEntityBusinessMeasuresOptions().map((v) => targetEmissionScopeOptions.push(v));
    }
  }, [
    targetEmissionScopeOptions,
    getEntityBusinessMeasuresOptions,
    businessMeasuresStatus,
    entityBusinessMeasuresStatus,
    loggedUserStatus
  ]);
  const targetEmissionTypeOptions = useMemo(
    () => [
      { value: 'Planned', label: 'Planned' },
      { value: 'Forecast', label: 'Forecast' }
    ],
    []
  );

  const validateSchema = async (data) => {
    try {
      await schema.validate({
        trgtemissnStartDate: data.trgtemissnStartDate,
        trgtemissnEndDate: data.trgtemissnEndDate,
        trgtemissnType: data.trgtemissnType,
        trgtemissnEmissnData: data.trgtemissnEmissnData ? data.trgtemissnEmissnData : '',
        trgtemissnEmissnReduction: data.trgtemissnEmissnReduction ? data.trgtemissnEmissnReduction : '',
        trgtemissnTargetScope: data.trgtemissnTargetScope,
        trgtemissnDesc: data.trgtemissnDesc
      });
      return true;
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  };

  const handleAddTargetEmission = async (data) => {
    try {
      const validated = await validateSchema(data);
      if (validated) {
        setIsLoading(true);
        const resultAction = await dispatch(createTargetEmission({ orgId, entityId, data }));
        unwrapResult(resultAction);
        dispatch(fetchTargetEmissions({ orgId, entityId }));
      }
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteTargetEmission = async ({ trgtemissnId }) => {
    try {
      setIsLoading(true);
      const resultAction = await dispatch(removeTargetEmission({ orgId, entityId, targetEmissionId: trgtemissnId }));
      unwrapResult(resultAction);
      dispatch(fetchTargetEmissions({ orgId, entityId }));
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditTargetEmission = async (edited, original) => {
    try {
      const data = {
        ...edited,
        trgtemissnType:
          edited.trgtemissnType instanceof Object ? edited.trgtemissnType.value.value : edited.trgtemissnType,
        trgtemissnTargetScope:
          edited.trgtemissnTargetScope instanceof Object
            ? edited.trgtemissnTargetScope.value.value
            : edited.trgtemissnTargetScope
      };
      const validated = await validateSchema(data);
      if (validated) {
        setIsLoading(true);
        const resultAction = await dispatch(
          editTargetEmission({ orgId: orgId, entityId: entityId, targetEmissionId: original.trgtemissnId, data: data })
        );
        unwrapResult(resultAction);
        dispatch(fetchTargetEmissions({ orgId, entityId }));
      }
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: t('Type'),
        accessor: 'trgtemissnType',
        editable: true,
        EditCell: ({ cell, onCellValueChange }) => {
          const { updatedValue } = cell.state;
          const parsed =
            updatedValue instanceof Object ? updatedValue.value : { value: updatedValue, label: updatedValue };
          const format = (d) => onCellValueChange({ value: d, label: d });
          return (
            <Select
              options={targetEmissionTypeOptions}
              menuPortalTarget={document.body}
              className="select"
              value={parsed}
              onChange={format}
              menuPlacement="top"
            />
          );
        }
      },
      {
        Header: t('Start Date'),
        accessor: 'trgtemissnStartDate',
        Cell: ({ row }) => formatDate(row.original?.trgtemissnStartDate),
        editable: true,
        EditCell: ({ cell, onCellValueChange }) => {
          const { updatedValue } = cell.state;
          return (
            <ReactDatePicker
              isClearable
              className="form-control"
              maxDate={getTargetUpdateValue(cell, 'trgtemissnEndDate', (val) => new Date(val))}
              dateFormat={usrDateFormat}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              {...datePickerValue(updatedValue, onCellValueChange)}
            />
          );
        }
      },
      {
        Header: t('End Date'),
        accessor: 'trgtemissnEndDate',
        Cell: ({ row }) => formatDate(row.original?.trgtemissnEndDate),
        editable: true,
        EditCell: ({ cell, onCellValueChange }) => {
          const { updatedValue } = cell.state;
          return (
            <ReactDatePicker
              isClearable
              className="form-control"
              minDate={getTargetUpdateValue(cell, 'trgtemissnStartDate', (val) => new Date(val))}
              dateFormat={usrDateFormat}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              {...datePickerValue(updatedValue, onCellValueChange)}
            />
          );
        }
      },
      {
        Header: t('Target Emission (tonnes CO2-e)'),
        accessor: 'trgtemissnEmissnData',
        editable: true,
        EditCell: ({ cell, onCellValueChange }) => {
          const { updatedValue } = cell.state;
          const parsed = updatedValue instanceof Object ? updatedValue.target.value : updatedValue;
          const format = (d) => onCellValueChange(d instanceof Object ? d.target.value : d);
          return <input value={parsed} onChange={format} />;
        }
      },
      {
        Header: t('Target Emission Reduction (%)'),
        accessor: 'trgtemissnEmissnReduction',
        editable: true,
        EditCell: ({ cell, onCellValueChange }) => {
          const { updatedValue } = cell.state;
          const parsed = updatedValue instanceof Object ? updatedValue.target.value : updatedValue;
          const format = (d) => onCellValueChange(d instanceof Object ? d.target.value : d);
          return <input value={parsed} onChange={format} />;
        }
      },
      {
        Header: t('Target Scope'),
        accessor: 'trgtemissnTargetScope',
        editable: true,
        EditCell: ({ cell, onCellValueChange }) => {
          const { updatedValue } = cell.state;
          const parsed = updatedValue.value ? updatedValue.value : { value: updatedValue, label: updatedValue };
          const format = (d) => onCellValueChange({ value: d, label: d });
          return (
            <Select
              options={[...new Map(targetEmissionScopeOptions.map((item) => [item['label'], item])).values()]}
              menuPortalTarget={document.body}
              className="select"
              value={parsed}
              onChange={format}
              menuPlacement="top"
            />
          );
        }
      },
      {
        Header: t('Description'),
        accessor: 'trgtemissnDesc',
        editable: true,
        EditCell: ({ cell, onCellValueChange }) => {
          const { updatedValue } = cell.state;
          const parsed = updatedValue;
          const format = (d) => onCellValueChange(d instanceof Object ? d.target.value : d);
          return <input value={parsed} onChange={format} />;
        }
      },
      {
        Header: '',
        accessor: 'actions',
        actionColumn: true,
        disableGlobalFilter: false,
        disableSortBy: true,
        EditCell: (props) => {
          const { cancelEditRow, saveRow } = props;
          return (
            <div className="d-flex action-buttons">
              <Button size="sm" variant="outline-secondary" onClick={saveRow}>
                <IoSaveOutline />
              </Button>
              <Button size="sm" className="mr-1" variant="outline-secondary" onClick={cancelEditRow}>
                <IoCloseOutline />
              </Button>
            </div>
          );
        },
        Cell: (props) => {
          const { deleteRow, editRow } = props;
          return (
            <div className="d-flex action-buttons">
              <Button size="sm" className="mr-1" variant="outline-secondary" onClick={editRow}>
                <AiOutlineEdit />
              </Button>
              <Button size="sm" variant="outline-secondary" onClick={deleteRow}>
                <IoTrashOutline />
              </Button>
            </div>
          );
        }
      }
    ],
    [targetEmissionScopeOptions, targetEmissionTypeOptions, t, usrDateFormat]
  );

  return (
    <div className="manage-target-emission-table">
      {targetEmissionStatus === LoadingState.loading &&
        businessMeasuresStatus === LoadingState.loading &&
        entityBusinessMeasuresStatus === LoadingState.loading && <Spinner animation="grow" />}
      {targetEmissionStatus === LoadingState.succeeded &&
        businessMeasuresStatus === LoadingState.succeeded &&
        entityBusinessMeasuresStatus === LoadingState.succeeded && (
          <DataTable
            columns={columns}
            data={targetEmissions}
            onEditSubmit={handleEditTargetEmission}
            onDeleteRow={handleDeleteTargetEmission}
            footer={
              <AddRowTargetEmissionForm
                onSubmit={handleAddTargetEmission}
                isLoading={isLoading}
                bsnsMeasures={[
                  ...new Map(getEntityBusinessMeasuresOptions().map((item) => [item['label'], item])).values()
                ]}
              />
            }
          />
        )}
    </div>
  );
};

export default ManageTargetEmission;
