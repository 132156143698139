import Keycloak from 'keycloak-js';
import { config } from './utilities/config';

const keycloakConfig = {
  url: `${config.KEYCLOAK_BASE_URL}/auth`,
  realm: config.KEYCLOAK_REALM,
  clientId: config.KEYCLOAK_CLIENT_ID
};

const keycloak = new Keycloak(keycloakConfig);

export default keycloak;
