import { useState } from 'react';
import CreatableSelect from 'react-select/creatable';

const components = {
  DropdownIndicator: null,
};

const createOption = (label) => ({
  label,
  value: label,
});

const MultiTextInput = ({
  defaultValue,
  onChange,
  placeholder = '',
  onValidate = () => true,
  isSubmitByEnter = true,
  isSubmitByTab = true,
  submitKeys = [',', ' '],
  maxLength = null,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState(defaultValue || []);

  const isAddNewValue = (newValue, val) => {
    const isAllowed = !maxLength || newValue.length < maxLength;
    if (val && isAllowed && onValidate(val)) {
      return true;
    }
    return false;
  };

  const onSelectChangeHandler = (newValue) => {
    setValue(newValue);
    onChange(newValue);
  }

  const copyPasteHandler = (event) => {
    if (!inputValue) {
      return false;
    }
    let sperateKey;
    submitKeys.some((key) => {
      if (inputValue.includes(key)) {
        sperateKey = key;
        return true;
      }
      return false;
    });
    if (sperateKey) {
      event.preventDefault();
      const newValue = [...value];
      inputValue.split(sperateKey).forEach((val) => {
        const parsedVal = val.trim();
        if (isAddNewValue(newValue, parsedVal)) {
          newValue.push(createOption(parsedVal));
        }
      });
      setValue(newValue);
      onChange(newValue);
      setInputValue('');
    }
    return false;
  };

  const handleKeyDown = (event) => {
    if (copyPasteHandler(event)) {
      return;
    }
    switch (true) {
      case isSubmitByEnter && event.key === 'Enter':
      case isSubmitByTab && event.key === 'Tab':
      case submitKeys.includes(event.key):
        event.preventDefault();
        if (isAddNewValue(value, inputValue)) {
          setValue((prev) => {
            const newValue = [...prev, createOption(inputValue)];
            onChange(newValue);
            return newValue;
          });
          setInputValue('');
        }
        break;
      default:
        break;
    }
  };

  return (
    <CreatableSelect
      components={components}
      inputValue={inputValue}
      isClearable
      isMulti
      menuIsOpen={false}
      onChange={onSelectChangeHandler}
      onInputChange={(newValue) => setInputValue(newValue)}
      onKeyDown={handleKeyDown}
      placeholder={placeholder}
      value={value}
    />
  );
};

export default MultiTextInput;
