import React, { useEffect, useMemo } from 'react';
import { Button, Col, Container, Row, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AiOutlineEdit } from 'react-icons/ai';
import { FaUserSecret } from 'react-icons/fa';
import PageHeader from '../../components/PageHeader';
import { LoadingState } from '../../utilities/constants';
import { fetchOrganizations } from './OrganizationSlice';
import DataTable from '../../components/DataTable';
import {fetchWhitelabels} from "../whitelabel/WhitelabelSlice";
import * as userImpersonate from '../../app/userImpersonate';
import keycloak from '../../keycloak';

const ManageOrganization = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const stateStatus = useSelector((state) => state.organizations.status);
  const organizations = useSelector((state) => state.organizations.organizations);
  const whitelabels = useSelector((state) => state.whitelabels.whitelabels);
  const orgId = useSelector((state) => state.users.user.usrOrgId);
  const loggedUserStatus = useSelector((state) => state.users.loggedUserStatus);

  const tableColumns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'orgName',
        sortType: 'string'
      },
      {
        Header: 'Description',
        accessor: 'orgDesc',
        disableGlobalFilter: false,
        disableSortBy: true
      },
      {
        Header: 'Admin',
        accessor: (row) => {
          return row?.rootUser?.usrEmail || row?.rootUser?.usrName;
        },
        sortType: 'string'
      },
      {
        Header: 'Domain',
        accessor: 'orgWlbId',
        disableGlobalFilter: false,
        disableSortBy: true,
        Cell: ({ row }) => {
          const { original } = row;
          const wl = whitelabels.find(wl => wl.wlbId === original.orgWlbId)
          return wl ? (
            <span>
              {wl.name}({wl.domain})
            </span>
          ) : (
            ''
          );
        }
      },
      {
        Header: '',
        accessor: 'actions',
        actionColumn: true,
        disableGlobalFilter: false,
        disableSortBy: true,
        Cell: ({ row }) => {
          const { original } = row;
          const user = original?.rootUser;
          return (
            <div className="action-buttons">
              <Button
                to={`${match.url}/${original.orgId}/edit`}
                size="sm"
                className="mr-1"
                variant="outline-secondary"
                as={Link}
              >
                <AiOutlineEdit />
              </Button>
              {
                user?.usrKeycloakId
                  ? (
                    <Button
                      onClick={() => {
                        userImpersonate.applyTo(user, 'cv_admin', keycloak?.subject);
                        toast.warn(`Impersonating to ${user?.usrEmail || user?.usrName}...`);
                        setTimeout(() => window.location.href = '/', 2000);
                      }}
                      size="sm"
                      className="mr-1"
                      variant="outline-secondary"
                      title="Impersonate to this organization"
                    >
                      <FaUserSecret />
                    </Button>
                  )
                  : null
              }
            </div>
          );
        }
      }
    ],
    [match.url, whitelabels]
  );

  useEffect(() => {
    if (loggedUserStatus === LoadingState.succeeded) {
        dispatch(fetchWhitelabels());
        dispatch(fetchOrganizations());
    }

  }, [orgId, loggedUserStatus, dispatch]);

  return (
    <Container fluid>
      <Row>
        <Col>
          <PageHeader
            title={'Manage Organization'}
            actions={
              <Button to={`${match.url}/new`} as={Link}>
                + Create Organization
              </Button>
            }
          />
        </Col>
      </Row>
      {stateStatus === LoadingState.loading && <Spinner animation="grow" />}
      {stateStatus === LoadingState.succeeded && (
        <Row>
          <Col>
            <DataTable columns={tableColumns} data={organizations} />
          </Col>
        </Row>
      )}
      {stateStatus === LoadingState.failed && <div>Something Went Wrong</div>}
    </Container>
  );
};

export default ManageOrganization;
