import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { LoadingState } from '../../utilities/constants';
import {
  fetchAssetsApi,
  getAssignedEmissionSourcesApi,
  getAssignedEntitiesApi
} from './assetsApi';

export const fetchAssets = createAsyncThunk('assets/fetchAssets', async (payload) => {
  try {
    const response = await fetchAssetsApi(payload.orgId);
    return response;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const getAssigedEmissionSources = createAsyncThunk('assets/getAssignedEmissionSources', async (payload) => {
  try {
    const emissionSources = await getAssignedEmissionSourcesApi(payload.orgId, payload.assetId);
    return emissionSources;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const getAssignedEntities = createAsyncThunk('assets/getAssignedEntities', async (payload) => {
  try {
    const entities = await getAssignedEntitiesApi(payload.orgId, payload.assetId);
    return entities;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

const assetsSlice = createSlice({
  name: 'assets',
  initialState: {
    assets: [],
    assignedEmissionSources: null,
    assignedEntities: null,
    status: LoadingState.idle,
    error: null
  },
  reducers: {},
  extraReducers: {
    [fetchAssets.pending]: (state) => {
      state.status = LoadingState.loading;
    },
    [fetchAssets.fulfilled]: (state, action) => {
      state.status = LoadingState.succeeded;
      state.assets = action.payload;
    },
    [fetchAssets.rejected]: (state, action) => {
      state.status = LoadingState.failed;
      state.error = action.error.message;
    },
    [getAssigedEmissionSources.pending]: (state, action) => {
      state.assignedEmissionSources = null;
    },
    [getAssigedEmissionSources.fulfilled]: (state, action) => {
      state.assignedEmissionSources = action.payload;
    },
    [getAssignedEntities.pending]: (state, action) => {
      state.assignedEntities = null;
    },
    [getAssignedEntities.fulfilled]: (state, action) => {
      state.assignedEntities = action.payload;
    },
  }
});

export const selectAssetById = (state, assetId) => {
  if (!state.assets.assets?.length === 0) return null;
  const parsedId = parseInt(assetId);
  const found = state.assets.assets.find((a) => a.assetId === parsedId);
  return found ?? null;
};

export const convertToTree = (state) => ({ children: state.assets.assets });

export default assetsSlice.reducer;
