import React, { useCallback, useEffect } from 'react';
import { Col, Form, Row, CloseButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { LoadingState } from '../../../utilities/constants';
import { Controller, useWatch } from 'react-hook-form';
import Select from 'react-select';
import { formatNumber } from '../../reports/reportHelper';
import { numericKeydownHandler } from '../../../utilities/common';
import CircleTooltip from '../../../components/CircleTooltip';

const ReductionForm = ({
  register,
  errors,
  setValue,
  getValues,
  control,
  actEprflsrcIdx,
  reductionIdx,
  units,
  unitState,
  handleDeleteReduction,
  watchReductions,
  watchStartMonth,
  factor,
  emissionData,
  reductions,
  actTotalReduction
}) => {
  const { t } = useTranslation();

  const reductionUnit = useWatch({
    control,
    name: `actEprflsrcs[${actEprflsrcIdx}].reductions[${reductionIdx}].reductionUnit`
  })

  const reductionTarget = useWatch({
    control,
    name: `actEprflsrcs[${actEprflsrcIdx}].reductions[${reductionIdx}].reductionTarget`
  })

  const reductionPrincipalValue = useWatch({
    control,
    name: `actEprflsrcs[${actEprflsrcIdx}].reductions[${reductionIdx}].reductionPrincipalValue`
  })

  const isGetPreviousYearEmission = useWatch({
    control,
    name: `actEprflsrcs[${actEprflsrcIdx}].reductions[${reductionIdx}].isGetPreviousYearEmission`
  })

  const handleCalculatePlannedReduction = useCallback(async () => {
    if (reductionTarget && reductionUnit && (reductionUnit === "%" || (reductionUnit !== "%" && factor))) {
      let value = 0, factorValue;
      if (reductionUnit !== "%") {
        if (Array.isArray(factor) && factor.length > 0) {
          const factorOfUnit = factor.find((item) => item.unit === reductionUnit)
          factorValue = factorOfUnit?.co2eFactor / 1000
        } else {
          factorValue = factor;
        }
        value = factorValue * reductionTarget;
      } else {
        if (!reductionPrincipalValue) {
          return true;
        }
        value = reductionPrincipalValue * reductionTarget * 0.01
      }
      const oldValue = getValues(`actEprflsrcs[${actEprflsrcIdx}].reductions[${reductionIdx}].plannedReduction`) ?? 0;
      setValue(`actEprflsrcs[${actEprflsrcIdx}].reductions[${reductionIdx}].plannedReduction`, value);
      setValue("actTotalReduction", actTotalReduction + value - parseFloat(oldValue)); //recalculate total reduction
    }
  }, [reductionUnit, reductionTarget, reductionPrincipalValue, actEprflsrcIdx, reductionIdx, factor, actTotalReduction, getValues, setValue]);

  useEffect(() => {
    handleCalculatePlannedReduction();
  }, [handleCalculatePlannedReduction])

  const handleCheckbox = async () => {
    let emissionDataValue;
    emissionDataValue = emissionData;
    setValue(`actEprflsrcs[${actEprflsrcIdx}].reductions[${reductionIdx}].reductionPrincipalValue`, emissionDataValue);
    handleCalculatePlannedReduction();
  }

  const handleUncheckCheckbox = () => {
    if (isGetPreviousYearEmission) {
      setValue(`actEprflsrcs[${actEprflsrcIdx}].reductions[${reductionIdx}].isGetPreviousYearEmission`, false);
    }
  }

  return (
    <>
      <Row>
        {reductionUnit === "%" &&
          <Col md={3}>
            <Form.Group className="custom-form-control mb-1">
              <Form.Label>{t('Principal value (tonnes of eCO2)')} *</Form.Label>
              <Form.Control
                type='text'
                {...register(`actEprflsrcs[${actEprflsrcIdx}].reductions[${reductionIdx}].reductionPrincipalValue`)}
                isInvalid={errors.actEprflsrcs?.[actEprflsrcIdx]?.reductions?.[reductionIdx]?.reductionPrincipalValue}
                placeholder={t('Enter reduction principal value')}
                onInput={handleUncheckCheckbox}
                onKeyDown={numericKeydownHandler}
              />
              <Form.Control.Feedback type="invalid">{errors.actEprflsrcs?.[actEprflsrcIdx]?.reductions?.[reductionIdx]?.reductionPrincipalValue?.message}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        }
        <Col md={3}>
          <Form.Group className="custom-form-control mb-1">
            <Form.Label>{t('Target')} *</Form.Label>
            <Form.Control
              type='number'
              {...register(`actEprflsrcs[${actEprflsrcIdx}].reductions[${reductionIdx}].reductionTarget`)}
              isInvalid={errors.actEprflsrcs?.[actEprflsrcIdx]?.reductions?.[reductionIdx]?.reductionTarget}
              placeholder={t('Enter reduction target')}
            />
            <Form.Control.Feedback type="invalid">{errors.actEprflsrcs?.[actEprflsrcIdx]?.reductions?.[reductionIdx]?.reductionTarget?.message}</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md={2}>
          <Form.Group className="custom-form-control mb-1">
            <Form.Label>{t('Unit')} *</Form.Label>
            <Controller
              name={`actEprflsrcs[${actEprflsrcIdx}].reductions[${reductionIdx}].reductionUnit`}
              control={control}
              disabled={unitState !== LoadingState.succeeded}
              render={({ field }) => {
                let options = [...units];
                if (watchReductions) {
                  options = options.filter((unit) =>
                    unit.value === watchReductions[reductionIdx]?.reductionUnit ||
                    watchReductions.findIndex((reduction) => reduction?.reductionUnit === unit.value) === -1
                  );
                }
                const onChangeUnit = (e) => {
                  return field.onChange(e.value);
                }
                return <Select
                  options={options}
                  onChange={onChangeUnit}
                  value={units?.find((unit) => field.value === unit.value)}
                  placeholder={t('Select a unit')}
                />;
              }}
            />
            <Form.Control.Feedback type="invalid">{errors.actEprflsrcs?.[actEprflsrcIdx]?.reductions?.[reductionIdx]?.reductionUnit?.message}</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group className="custom-form-control mb-1">
            <Form.Label>{t('Planned CO2-e reduction (tonnes)')} *</Form.Label>
            <Form.Control
              {...register(`actEprflsrcs[${actEprflsrcIdx}].reductions[${reductionIdx}].plannedReduction`)}
              value={formatNumber(getValues(`actEprflsrcs[${actEprflsrcIdx}].reductions[${reductionIdx}].plannedReduction`))}
              readOnly
            />
          </Form.Group>
        </Col>
        <CloseButton hidden={reductions.length <= 1} className="mt-3" onClick={() => handleDeleteReduction(reductionIdx)} />
      </Row>
      {reductionUnit === "%" &&
        <Row className="mb-1">
          <Col>
            <Controller
              name={`actEprflsrcs[${actEprflsrcIdx}].reductions[${reductionIdx}].isGetPreviousYearEmission`}
              control={control}
              render={({ field }) => {
                const format = (event) => {
                  if (event.target.checked) {
                    handleCheckbox();
                  }
                  field.onChange(event.target.checked);
                };
                return (
                  <Form.Check>
                    <Form.Check.Input
                      disabled={!watchStartMonth || !emissionData}
                      onChange={format}
                      checked={isGetPreviousYearEmission}
                    />
                    <Form.Check.Label>
                      {t('Principal value is retrieved from emission of previous year')}
                      <CircleTooltip
                        title={t('If there is no data of the previous year or user hasn\'t selected Action start month yet, this checkbox cannot be selected.')}
                      />
                    </Form.Check.Label>
                  </Form.Check>
                );
              }}
            />
          </Col>
        </Row>
      }
    </>
  );
};

export default ReductionForm;
