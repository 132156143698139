import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import LoadingButton from '../../../components/LoadingButton';

const UploadDataConfirmForm = ({ 
  showConfirmationModal,
  closeConfirmationModal,
  uploadDataHandler,
  isUploading,
}) => {

  const { t } = useTranslation();

  return (
    <Modal show={showConfirmationModal} onHide={closeConfirmationModal} backdrop="static">
      <Modal.Header>
        <Modal.Title>{t('File Already Exists')}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ textAlign: 'justify' }}>
        {t('A file with the same name already exists. Overwriting it will permanently delete the current contents. Do you want to replace the file?')}
      </Modal.Body>
      <Modal.Footer>
        <LoadingButton isLoading={isUploading} />
        <Button
          variant="secondary"
          onClick={() => {
            closeConfirmationModal();
          }}
        >
          {t('Cancel')}
        </Button>
        <Button
          onClick={uploadDataHandler}
          variant="primary"
          disabled={isUploading}
        >
          {t('Yes, replace the file')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UploadDataConfirmForm;
