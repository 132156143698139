import React, { useEffect, useState, useMemo } from 'react';
import { Col, Container, Row, Spinner, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingState } from '../../utilities/constants';
import TreeView from '../../components/TreeView';
import { recursiveMap } from '../../utilities/treeHelpers';
import {
  fetchEmissionProfileTreeByAsset,
  fetchAssignedUsersToAsset,
  fetchAvailableUsersToAsset,
  assignUserToAsset,
  unAssignUserToAsset
} from './usersSlice';
import { fetchEntityTree, fetchAssignedAssets } from '../entity/entitySlice';
import PageHeader from '../../components/PageHeader';
import { unwrapResult } from '@reduxjs/toolkit';
import DataTable from '../../components/DataTable';
import { RiArrowRightSLine, RiArrowLeftSLine } from 'react-icons/ri';
import NodeIcon from '../../components/NodeIcon';
import { useTranslation } from 'react-i18next';

const AssignUsersToSources = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const entityStatus = useSelector((state) => state.entities.status);
  const entityTree = useSelector((state) => state.entities.tree);
  const assignUsersToAsset = useSelector((state) => state.users.assignmentStatus);
  const emissionProfileSourceTree = useSelector((state) => state.users.emissionProfileTreeByAsset);
  const assignedAssets = useSelector((state) => state.entities.assignedAssets);
  const assignedUsersToAsset = useSelector((state) => state.users.assignedUsersToAsset);
  const availableUsersToAsset = useSelector((state) => state.users.availableUsersToAsset);
  const orgId = useSelector((state) => state.users.user.usrOrgId);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [selectedEPS, setSelectedEPS] = useState(null);

  const EntityItem = ({ entity }) => {
    const handleSelectEntity = async () => {
      try {
        setSelectedEntity(entity.divId);
        setSelectedAsset(null);
        setSelectedEPS(null);
        const entityId = entity.divId;
        const resultAction = await dispatch(fetchAssignedAssets({ orgId, entityId }));
        unwrapResult(resultAction);
      } catch (e) {
        setSelectedEntity(null);
      }
    };
    return <span onClick={handleSelectEntity}>{entity.divName}</span>;
  };

  const EmissionProfileSourceItem = ({ emissionProfileSource }) => {
    const labelName =
      emissionProfileSource.eprflName ?? emissionProfileSource.eprflcatName ?? emissionProfileSource.eprflsrcName;
    const handleSelectEmissionProfileSource = async () => {
      try {
        setSelectedEPS(emissionProfileSource.eprflsrcId ?? null);
        const entityId = selectedEntity ?? null;
        const assetId = selectedAsset ?? null;
        const epsId = emissionProfileSource.eprflsrcId ?? null;
        if (entityId && assetId && epsId) {
          const resultAction1 = await dispatch(fetchAssignedUsersToAsset({ orgId, entityId, assetId, epsId }));
          const resultAction2 = await dispatch(fetchAvailableUsersToAsset({ orgId, entityId, assetId, epsId }));
          unwrapResult(resultAction1);
          unwrapResult(resultAction2);
        }
      } catch (e) {}
    };
    return (
      <span onClick={handleSelectEmissionProfileSource}>
        {emissionProfileSource.eprflEstdId ? null : emissionProfileSource.eprflEstdId === null ? null : (
          <NodeIcon node={emissionProfileSource} />
        )}
        {labelName}
      </span>
    );
  };

  const transformedTree = recursiveMap(entityTree ?? {}, (n) => {
    return {
      value: n.divId,
      labelRaw: n.divName,
      label: <EntityItem entity={n} />,
      isActive: selectedEntity === n.divId,
      valueId: n.divAssignedId
    };
  });
  transformedTree.isRoot = false;

  const transformedEPSTree = recursiveMap(emissionProfileSourceTree ?? {}, (n) => {
    return {
      value: n.eprflcatId,
      labelRaw: n.eprflsrcName || '',
      label: <EmissionProfileSourceItem emissionProfileSource={n} />,
      isActive: selectedEPS === n.eprflsrcId
    };
  });
  transformedEPSTree.isRoot = false;

  const handleSelectAsset = async (asset) => {
    try {
      setSelectedAsset(asset.assetId);
      setSelectedEPS(null);
      const assetId = asset.assetId;
      const resultAction = await dispatch(fetchEmissionProfileTreeByAsset({ orgId, assetId }));
      unwrapResult(resultAction);
    } catch (e) {
      setSelectedAsset(null);
    }
  };

  const handleAssignUserToAsset = async ({ usrId }) => {
    try {
      const entityId = selectedEntity;
      const assetId = selectedAsset;
      const epsId = selectedEPS;
      const userId = usrId;
      const resultAction = await dispatch(assignUserToAsset({ orgId, entityId, assetId, epsId, userId }));
      unwrapResult(resultAction);
    } catch (e) {}
  };

  const handleUnAssignUserToAsset = async ({ usrId }) => {
    try {
      const entityId = selectedEntity;
      const assetId = selectedAsset;
      const epsId = selectedEPS;
      const userId = usrId;
      const resultAction = await dispatch(unAssignUserToAsset({ orgId, entityId, assetId, epsId, userId }));
      unwrapResult(resultAction);
    } catch (e) {}
  };

  useEffect(() => {
    if (entityStatus === LoadingState.idle) {
      dispatch(fetchEntityTree({ orgId }));
    }
  }, [orgId, entityStatus, dispatch]);

  const assetTableColumns = useMemo(
    () => [
      {
        Header: t('Asset ID'),
        accessor: 'assetAssignedId'
      },
      {
        Header: t('Asset Name'),
        accessor: 'assetName',
        sortType: 'string'
      },
      {
        Header: t('Description'),
        accessor: 'assetDesc',
        sortType: 'string'
      }
    ],
    [t]
  );

  return (
    <Container fluid>
      <Row>
        <Col>
          <PageHeader title={t('Assign Users To Asset')} />
        </Col>
      </Row>
      {entityStatus === LoadingState.loading && <Spinner animation="grow" />}
      {entityStatus === LoadingState.succeeded && (
        <Row>
          <Col>
            <Row className="mr-4">
              <Col className="ml-4 tiles-component">
                <span className="pl-1 pr-1 px-2 step-span">{t('Step {{from}} of {{to}}', {from: 1, to: 4})}</span>
                <PageHeader.Secondary title={t('Select Entity')} />
                <div className="px-1">
                  <TreeView
                    initExpandLevel={0}
                    tree={transformedTree}
                    searchFunction={(n, searchText) => n.labelRaw.toLowerCase().includes(searchText.toLowerCase())}
                    enableSearch
                  />
                </div>
              </Col>
              {transformedTree && !selectedEntity && <Col className='mr-4 mb-3"' />}
              {selectedEntity && assignedAssets && (
                <Col className="ml-4 tiles-component">
                  <span className="pl-1 pr-1 px-2 step-span">{t('Step {{from}} of {{to}}', {from: 2, to: 4})}</span>
                  <PageHeader.Secondary title={t('Select Asset')} />
                  <div className="px-1">
                    <DataTable
                      columns={assetTableColumns}
                      data={assignedAssets}
                      onRowSelect={handleSelectAsset}
                      dataTableColor="bg-white"
                    />
                  </div>
                </Col>
              )}
            </Row>
            <Row className="mr-4 mb-3">
              {selectedAsset && emissionProfileSourceTree && (
                <Col className="mt-4 ml-4 tiles-component">
                  <span className="pl-1 pr-1 px-2 step-span">{t('Step {{from}} of {{to}}', {from: 3, to: 4})}</span>
                  <PageHeader.Secondary title={t('Select Emission Profile Source')} />
                  <div className="px-1">
                    <TreeView
                      initExpandLevel={0}
                      tree={transformedEPSTree}
                      searchFunction={(n, searchText) => n.labelRaw.toLowerCase().includes(searchText.toLowerCase())}
                      enableSearch
                    />
                  </div>
                </Col>
              )}
              {selectedEPS && (
                <Col className="mt-4 ml-4 tiles-component">
                  <span className="pl-1 pr-1 px-2 step-span">{t('Step {{from}} of {{to}}', {from: 4, to: 4})}</span>
                  <PageHeader.Secondary title="Select Users" />
                  <div className="pl-1 pr-1 px-2 d-flex">
                    <div className="mr-3 tile-table-wrapper">
                      <span className="mb-2 tiles-title">{t('Available Users')}</span>
                      <Table className="mt-2 tiles-table-component">
                        <tbody>
                          {availableUsersToAsset.map((a) => (
                            <tr key={a.usrName} onClick={() => handleAssignUserToAsset(a)}>
                              <td>{a.usrName}</td>
                              <td>
                                <RiArrowRightSLine />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                    <div className="tile-table-wrapper">
                      <span className="mb-2 tiles-title">{t('Assigned Users')}</span>
                      <Table className="mt-2 tiles-table-component">
                        <tbody>
                          {assignedUsersToAsset.map((a) => (
                            <tr key={a.usrName} onClick={() => handleUnAssignUserToAsset(a)}>
                              <td>
                                <RiArrowLeftSLine />
                              </td>
                              <td>{a.usrName}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Col>
              )}
              {selectedAsset && !selectedEPS && <Col className="mt-4 ml-4" />}
            </Row>
          </Col>
        </Row>
      )}
      {assignUsersToAsset === LoadingState.failed && <div>Something Went Wrong</div>}
    </Container>
  );
};

export default AssignUsersToSources;
