import { config } from '../../utilities/config';
import keycloak from '../../keycloak';

export async function fetchAccountsApi(organizationId) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/accounts`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    }
  });
  const data = await response.json();
  if (!response.ok) throw new Error(data.message);
  return data;
}

export async function createAccountApi(organizationId, data) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/accounts`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function editAccountApi(organizationId, accountId, data) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/accounts/${accountId}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  if (!response.ok) throw new Error(response.statusText);
  return result;
}

export async function removeAccountApi(organizationId, accountId) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/accounts/${accountId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    }
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function assignUserApi(organizationId, accountId, data) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/accounts/${accountId}/user`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function fetchAssignedUsersApi(organizationId, accountId) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/accounts/${accountId}/users`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    }
  });
  const data = await response.json();
  if (!response.ok) throw new Error(data.message);
  return data;
}

export async function fetchAvailableUsersApi(organizationId, accountId) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/accounts/${accountId}/available-users`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    }
  });
  const data = await response.json();
  if (!response.ok) throw new Error(data.message);
  return data;
}

export async function unAssignUserApi(organizationId, accountId, userId) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/accounts/${accountId}/user/${userId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    }
  });
  if (!response.ok) {
    const result = await response.json();
    throw new Error(result.message);
  }
}

export async function assignEntityApi(organizationId, accountId, data) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/accounts/${accountId}/entity`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function fetchAssignedEntitiesApi(organizationId, accountId) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/accounts/${accountId}/entities`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    }
  });
  const data = await response.json();
  if (!response.ok) throw new Error(data.message);
  return data;
}

export async function fetchAvailableEntitiesApi(organizationId, accountId) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/accounts/${accountId}/available-entities`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    }
  });
  const data = await response.json();
  if (!response.ok) throw new Error(data.message);
  return data;
}

export async function unAssignEntityApi(organizationId, accountId, entityId) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/accounts/${accountId}/entity/${entityId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    }
  });
  if (!response.ok) {
    const result = await response.json();
    throw new Error(result.message);
  }
}
