import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { datePickerClass, datePickerValue, parseUserInfo } from '../../utilities/common';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { setLeavePrompt } from '../manage-users/usersSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import LoadingButton from '../../components/LoadingButton';

const createSchema = yup.object().shape({
  assetName: yup.string().max(128).required('Asset name is required'),
  assetAssignedId: yup
    .string()
    .matches(/^([A-Za-z0-9-]){1,15}$/, {
      message: 'Id may contain only characters, numbers, hyphen and less than 15 characters'
    })
    .required('Asset ID is required')
});

const editSchema = yup.object().shape({
  assetName: yup.string().max(128).required('Asset name is required')
});

const AssetForm = ({
  onSubmit,
  onCancel,
  initialValues = {},
  buttonText = 'Create',
  isEditing = false
}) => {
  const {
    register,
    watch,
    handleSubmit,
    control,
    formState: { errors, isValid, isDirty }
  } = useForm({
    defaultValues: { assetEnabled: 'Y', ...initialValues },
    resolver: yupResolver(isEditing ? editSchema : createSchema),
    mode: 'onChange'
  });
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLeavePrompt(isDirty));
  }, [isDirty, dispatch]);

  const watchAssetDateIntroduced = watch('assetDateIntroduced', null);
  const watchAssetDateOutOfService = watch('assetDateOutOfService', null);
  const { usrDateFormat } = parseUserInfo(useSelector((state) => state.users.user));
  const [isLoading, setIsLoading] = useState(false);

  const onSubmitHandler = async (data) => {
    try {
      setIsLoading(true);
      dispatch(setLeavePrompt(false));
      await onSubmit(data);
    } catch (e) {
      setIsLoading(false);
      dispatch(setLeavePrompt(isDirty));
    }
  };
  const onCancelHandler = () => onCancel();

  return (
    <Form onSubmit={handleSubmit(onSubmitHandler)}>
      <Row>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Asset ID')} *</Form.Label>
            <Form.Control {...register('assetAssignedId')} readOnly={isEditing} isInvalid={errors.assetAssignedId} />
            <Form.Control.Feedback type="invalid">{errors.assetAssignedId?.message}</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Asset Name')} *</Form.Label>
            <Form.Control {...register('assetName')} isInvalid={errors.assetName} />
            <Form.Control.Feedback type="invalid">{errors.assetName?.message}</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Date Introduced')}</Form.Label>
            <Controller
              name="assetDateIntroduced"
              control={control}
              render={({ field, fieldState: { invalid } }) => {
                return (
                  <ReactDatePicker
                    isClearable
                    dateFormat={usrDateFormat}
                    maxDate={!!watchAssetDateOutOfService ? new Date(watchAssetDateOutOfService) : null}
                    popperPlacement="bottom-end"
                    showMonthDropdown
                    showYearDropdown
                    {...datePickerValue(field.value, field.onChange)}
                    {...datePickerClass(invalid)}
                  />
                );
              }}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Date Out of Service')}</Form.Label>
            <Controller
              name="assetDateOutOfService"
              control={control}
              render={({ field, fieldState: { invalid } }) => {
                return (
                  <ReactDatePicker
                    isClearable
                    dateFormat={usrDateFormat}
                    minDate={!!watchAssetDateIntroduced ? new Date(watchAssetDateIntroduced) : null}
                    showMonthDropdown
                    showYearDropdown
                    {...datePickerValue(field.value, field.onChange)}
                    {...datePickerClass(invalid)}
                  />
                );
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Description')}</Form.Label>
            <Form.Control as="textarea" {...register('assetDesc')} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Reason Removed from Service')}</Form.Label>
            <Form.Control {...register('assetReasonRemovedFromService')} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex align-items-center">
          <Form.Group className="custom-form-control">
            <Controller
              name="assetEnabled"
              control={control}
              render={({ field }) => {
                const parsed = field.value === 'Y' ? true : false;
                const format = () => {
                  field.onChange(field.value === 'Y' ? 'N' : 'Y');
                };
                return (
                  <Form.Check
                    custom
                    id="assetEnabled"
                    onChange={format}
                    checked={parsed}
                    label={t('Active')}
                  />
                );
              }}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('GL Code')}</Form.Label>
            <Form.Control {...register('assetGlCode')} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('ID Type')}</Form.Label>
            <Form.Control {...register('assetIdType')} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Business Unit')}</Form.Label>
            <Form.Control {...register('assetBusinessUnit')} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Serial Number')}</Form.Label>
            <Form.Control {...register('assetSerialNumber')} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Address')}</Form.Label>
            <Form.Control {...register('assetAddress')} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Suburb')}</Form.Label>
            <Form.Control {...register('assetSuburb')} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Postcode')}</Form.Label>
            <Form.Control {...register('assetPostcode')} />
          </Form.Group>
        </Col>
      </Row>
      <Button className="mt-3 mb-5" type="submit" disabled={!isValid || !isDirty || isLoading}>
        {buttonText}
      </Button>
      <Button variant="secondary" className="ml-4 mt-3 mb-5" onClick={onCancelHandler}>
        {t('Cancel')}
      </Button>
      <LoadingButton isLoading={isLoading} className="ml-4 mt-3 mb-5" />
    </Form>
  );
};

export default AssetForm;
