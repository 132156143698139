import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { LoadingState } from '../../utilities/constants';
import { recursiveFind } from '../../utilities/treeHelpers';
import {
  assignAssetApi,
  fetchAssignedAssetsApi,
  fetchAvailableAssetsApi,
  fetchDocumentsApi,
  fetchEntityTreeApi,
  generateAttachmentDownloadUrlApi,
  removeEntityApi,
  unAssignAssetApi,
} from './entityApi';
import { fetchAssets } from '../assets/assetsSlice';

export const fetchEntityTree = createAsyncThunk('entities/fetchEntityTree', async (payload) => {
  try {
    const response = await fetchEntityTreeApi(payload.orgId);
    return response;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const removeEntity = createAsyncThunk('entities/removeEntity', async (payload, { dispatch }) => {
  try {
    const { orgId, entityId } = payload;
    const entity = await removeEntityApi(orgId, entityId);
    dispatch(fetchEntityTree({ orgId }));
    return entity;
  } catch (error) {
    // toast.error(error.message);
    throw error;
  }
});

export const fetchAssignedAssets = createAsyncThunk('entities/fetchAssignedAssets', async (payload) => {
  try {
    const response = await fetchAssignedAssetsApi(payload.orgId, payload.entityId);
    return response;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const fetchAvailableAssets = createAsyncThunk('entities/fetchAvailableAssets', async (payload) => {
  try {
    const response = await fetchAvailableAssetsApi(payload.orgId, payload.entityId);
    return response;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const fetchDocuments = createAsyncThunk('entities/fetchDocuments', async (payload) => {
  try {
    const response = await fetchDocumentsApi(payload.orgId, payload.entityId);
    return response;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const generateAttachmentDownloadUrl = createAsyncThunk('entities/getDownloadLink', async (payload, thunkAPI) => {
  try {
    const { usrOrgId } = thunkAPI.getState().users.user;
    const response = await generateAttachmentDownloadUrlApi(usrOrgId, payload.entityId, payload.uploadedFileId);
    return response;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const assignAsset = createAsyncThunk('entities/assignAsset', async (payload, { dispatch }) => {
  try {
    const { orgId, entityId, assetId } = payload;
    await assignAssetApi(orgId, entityId, { assetId });
    await dispatch(fetchAssignedAssets({ orgId, entityId }));
    await dispatch(fetchAvailableAssets({ orgId, entityId }));
    dispatch(fetchAssets({ orgId }));
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const unAssignAsset = createAsyncThunk('entities/unAssignAsset', async (payload, { dispatch }) => {
  try {
    const { orgId, entityId, assetId } = payload;
    await unAssignAssetApi(orgId, entityId, assetId);
    await dispatch(fetchAssignedAssets({ orgId, entityId }));
    await dispatch(fetchAvailableAssets({ orgId, entityId }));
    dispatch(fetchAssets({ orgId }));
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const entitySlice = createSlice({
  name: 'entities',
  initialState: {
    tree: null,
    assignedAssets: [],
    availableAssets: [],
    documents: [],
    status: LoadingState.idle,
    generalDocumentStatus: LoadingState.idle,
    error: null
  },
  reducers: {},
  extraReducers: {
    [fetchEntityTree.pending]: (state, action) => {
      state.status = LoadingState.loading;
    },
    [fetchEntityTree.fulfilled]: (state, action) => {
      state.status = LoadingState.succeeded;
      state.tree = action.payload;
    },
    [fetchEntityTree.rejected]: (state, action) => {
      state.status = LoadingState.failed;
      state.error = action.error.message;
    },
    [fetchAssignedAssets.fulfilled]: (state, action) => {
      state.assignedAssets = action.payload;
    },
    [fetchAvailableAssets.fulfilled]: (state, action) => {
      state.availableAssets = action.payload;
    },
    [fetchDocuments.pending]: (state, action) => {
      state.generalDocumentStatus = LoadingState.loading;
    },
    [fetchDocuments.fulfilled]: (state, action) => {
      state.generalDocumentStatus = LoadingState.succeeded;
      state.documents = action.payload;
    },
    [fetchDocuments.rejected]: (state, action) => {
      state.generalDocumentStatus = LoadingState.failed;
      state.error = action.error.message;
    },
    [removeEntity.fulfilled]: (state, action) => {
      state.status = LoadingState.succeeded;
      state.tree = action.payload;
    }
  }
});

export const selectRootEntity = (state) => {
  return state.entities.tree ? { ...state.entities.tree, children: null } : {};
};

export const selectEntityById = (state, entityId) => {
  if (!state.entities.tree) return null;
  const found = recursiveFind(state.entities.tree, (n) => n.divId === parseInt(entityId));
  return found ? { ...found, children: null } : null;
};

export default entitySlice.reducer;
