import { useEffect, useState } from 'react';
import { throttle } from 'throttle-debounce';

const activityEvents = [
  'mousemove',
  'mousedown',
  'resize',
  'keydown',
  'touchstart',
  'wheel'
];

const useIdle = (seconds, initialState = false, events = activityEvents) => {
  const [isIdle, setIsIdle] = useState(initialState);

  useEffect(() => {
    let mounted = true;
    let timeout;
    let localIsIdle = isIdle;
    const setIdleState = (newState) => {
      if (mounted) {
        localIsIdle = newState;
        setIsIdle(newState);
      }
    };

    const onEvent = throttle(500, () => {
      if (localIsIdle) {
        setIdleState(false);
      }
      clearTimeout(timeout);
      timeout = setTimeout(() => setIdleState(true), seconds * 1000);
    });
    const onVisibility = () => {
      if (!document.hidden) {
        onEvent();
      }
    };

    for (let i = 0; i < events.length; i++) {
      window.addEventListener(events[i], onEvent);
    }
    document.addEventListener('visibilitychange', onVisibility);

    timeout = setTimeout(() => setIdleState(true), seconds * 1000);

    return () => {
      mounted = false;
      for (let i = 0; i < events.length; i++) {
        window.removeEventListener(events[i], onEvent);
      }
      document.removeEventListener('visibilitychange', onVisibility);
    };
  }, [seconds, events, isIdle]);

  return isIdle;
};

export default useIdle;