import { unwrapResult } from '@reduxjs/toolkit';
import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ActiveSms2FaForm from './ActiveSms2FaForm';
import VerifySms2FaForm from './VerifySms2FaForm';
import RemoveSms2FaForm from './RemoveSms2FaForm';
import { useTranslation } from 'react-i18next';
import { fetchUser } from '../../../features/manage-users/usersSlice';
import { activeSms2FaApi, removeSms2FaApi, verifySms2FaApi } from '../accountApi';

const ManageSms2Fa = () => {
  const [isVerifyStep, setIsVerifyStep] = useState(false);
  const [currentActiveValues, setCurrentActiveValues] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const userInfo = useSelector((state) => state.users.user);
  const userKeycloakId = userInfo?.usrKeycloakId;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleActiveSms2Fa = async (values) => {
    try {
      setIsLoading(true);
      await activeSms2FaApi(values.currentPassword, values.mobileNumber);
      setCurrentActiveValues(values);
      setIsLoading(false);
      setIsVerifyStep(true);
    } catch (e) {
      toast.error(e.message || t('Something went wrong'));
      setIsLoading(false);
    }
  };

  const handleResentSms2Fa = async () => {
    try {
      const { currentPassword, mobileNumber } = currentActiveValues;
      setIsLoading(true);
      await activeSms2FaApi(currentPassword, mobileNumber);
      setIsLoading(false);
      toast.success(t('We just sent a text message with a verification code to {{mobile}}', { mobile: mobileNumber }));
    } catch (e) {
      toast.error(e.message || 'Something went wrong');
      setIsLoading(false);
    }
  };

  const handleVerifySms2Fa = async (values) => {
    try {
      setIsLoading(true);
      await verifySms2FaApi(values.code);
      const resultAction = await dispatch(fetchUser({ userKeycloakId }));
      if (resultAction.payload) {
        unwrapResult(resultAction);
      }
      setIsLoading(false);
      setIsVerifyStep(false);
      toast.success(t('Two-Factor Authentication is On! We will send a verification code to this number for the new login of your account'));
    } catch (e) {
      toast.error(e.message || t('Something went wrong'));
      setIsLoading(false);
    }
  };

  const handleRemoveSms2Fa = async (values) => {
    try {
      setIsLoading(true);
      await removeSms2FaApi(values.currentPassword);
      const resultAction = await dispatch(fetchUser({ userKeycloakId }));
      if (resultAction.payload) {
        unwrapResult(resultAction);
      }
      setIsLoading(false);
      toast.success(t('Two-Factor Authentication is Off'));
    } catch (e) {
      toast.error(e.message || t('Something went wrong'));
      setIsLoading(false);
    }
  };

  const renderActiveForm = () => {
    if (userInfo.usrIsSms2Fa || isVerifyStep) {
      return null;
    }
    return (
      <ActiveSms2FaForm
        onSubmit={handleActiveSms2Fa}
        disabled={isLoading}
      />
    );
  };

  const renderVerifyForm = () => {
    if (!isVerifyStep) {
      return null;
    }
    const { mobileNumber } = currentActiveValues;
    return (
      <VerifySms2FaForm
        onSubmit={handleVerifySms2Fa}
        disabled={isLoading}
        onCancelHandler={() => setIsVerifyStep(false)}
        onResentSmsHandler={handleResentSms2Fa}
        mobileNumber={mobileNumber}
      />
    );
  };

  const renderRemoveForm = () => {
    if (!userInfo.usrIsSms2Fa || isVerifyStep) {
      return null;
    }
    const mobileNumber = (userInfo.usrMobileNumber || '').replace(/\d(?!\d{0,3}$)/g, '*');
    return (
      <RemoveSms2FaForm
        onSubmit={handleRemoveSms2Fa}
        disabled={isLoading}
        initialValues={{ mobileNumber }}
      />
    );
  };

  return (
    <Container fluid>
      <Row className="my-2">
        <Col>
          <h4>{t('Two-Factor Authentication Setting')}</h4>
          <Row className="my-2">
            <Col md={8}>
              {renderVerifyForm()}
              {renderActiveForm()}
              {renderRemoveForm()}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default ManageSms2Fa;
