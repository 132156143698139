import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import SecondarySidebar from '../../components/Sidebar/SecondarySidebar';
import RouteNotFound from '../../RouteNotFound';
import ViewEmission from './ViewEmission';
import ManageEmissions from './ManageEmissions';
import NewEmission from './NewEmission';
import ManageUploadData from './upload-data/ManageUploadData';
import EditEmission from './EditEmission';

const EmissionHome = () => {
  const [ expandedClass, setExpandedClass ] = useState('');
  const match = useRouteMatch();
  const { t } = useTranslation();
  const routes = [
    {
      path: `${match.url}/data`,
      name: t('Manage Emission Data'),
      isLink: true
    },
    {
      path: `${match.url}/upload`,
      name: t('Upload Data'),
      isLink: true
    }
  ];

  return (
    <div className="d-flex flex-row">
      <SecondarySidebar
        items={routes}
        onCollapse={(isExpanded) => setExpandedClass(isExpanded ? 'offset-expanded': '')}
      />
      <div className={`offset-second ${expandedClass}`}>
        <Switch>
          <Route exact path={`${match.path}/data`}>
            <ManageEmissions />
          </Route>
          <Route exact path={`${match.path}/data/:emissionId/edit`}>
            <EditEmission />
          </Route>
          <Route exact path={`${match.path}/data/new`}>
            <NewEmission />
          </Route>
          <Route exact path={`${match.path}/data/:emissionId`}>
            <ViewEmission />
          </Route>
          <Route exact path={`${match.path}/upload`}>
            <ManageUploadData />
          </Route>
          <Route exact path={match.path}>
            <Redirect to={`${match.path}/data`} />
          </Route>
          <Route path="*">
            <RouteNotFound />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default EmissionHome;
