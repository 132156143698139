import React from 'react';
import { Row, Col, ListGroup, Card, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import processImage from '../../../assets/images/coriolis/process.jpg';
import GetStartedNow from './components/GetStartedNow';

const ScoringProcess = () => {
  const { t } = useTranslation();
  const renderCircleNumber = (number, isLatest) => {
    return (
      <>
        <div className="circle-number"><span>{number}</span></div>
        {!isLatest ? <div className="circle-connector"></div> : null}
      </>
    )
  };
  return (
    <div>
      <Row>
        <Col md={8}>
          <h2>{t('CoriolisESG Underlying Scoring process')}</h2>
          <p style={{ textAlign: 'justify' }}>
            {t('Our automated ESG scoring solution delivers unbiased assessments based on data from trusted sources, considering industry and geographic factors to provide accurate results aligned with global sustainability frameworks.')}
          </p>
        </Col>
        <Col md={4} style={{ textAlign: 'right' }}>
          <GetStartedNow />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={7}>
          <ListGroup className="mt-4 circle-container">
            <ListGroup.Item>
              <div className="d-flex">
                {renderCircleNumber(1)}
                <div className="circle-body">
                  <h5>{t('Data Collection')}</h5>
                  <p className="italic-item">
                    {t('Collect and aggregate data from multiple databases, and other sources, including company filings, news articles, trade documentation, government, and industry reports.')}</p>
                </div>
              </div>
            </ListGroup.Item>
            <ListGroup.Item>
              <div className="d-flex">
                {renderCircleNumber(2)}
                <div className="circle-body">
                  <h5>{t('Analysis')}</h5>
                  <p className="italic-item">
                    {t('Use its proprietary algorithms, regulatory parameters, and industry frameworks to analyse the data and generate ESG scores for each company.')}</p>
                </div>
              </div>
            </ListGroup.Item>
            <ListGroup.Item>
              <div className="d-flex">
                {renderCircleNumber(3, true)}
                <div className="circle-body">
                  <h5>{t('Reporting')}</h5>
                  <p className="italic-item">
                    {t('A comprehensive report is returned, including the ESG scores for individual companies, along with detailed risk analysis and insights. The report also highlights areas for improvement.')}</p>
                </div>
              </div>
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col md={5} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          <Col md={10} style={{ padding: 0 }}>
            <Card>
              <Card.Body>
                <Image src={processImage} style={{ width: '100%', maxWidth: '680px' }} />
              </Card.Body>
            </Card>
          </Col>
        </Col>
      </Row>
    </div>
  );
};

export default ScoringProcess;
