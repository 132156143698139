import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import PageHeader from '../../components/PageHeader';
import { LoadingState } from '../../utilities/constants';
import {
  fetchEmissionProfilesTree,
  fetchEmissionProfilesTreeByScope
} from './emissionProfileSlice';
import Form from './form/EmissionProfileSourceForm';
import { useTranslation } from 'react-i18next';
import { createEmissionProfileSourceApi } from './emissionProfileApi';
import { toast } from 'react-toastify';
import LoadingButton from '../../components/LoadingButton';

const NewEmissionProfileSource = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { emissionProfileCategoryId } = useParams();

  const emissionProfileStatus = useSelector((state) => state.emissionProfiles.status);
  const emissionProfileTree = useSelector((state) => state.emissionProfiles.tree);
  const emissionProfileTreeByScope = useSelector((state) => state.emissionProfiles.scopedTree);
  const emissionProfileTreeByScopeStatus = useSelector((state) => state.emissionProfiles.scopedTreeStatus);
  const orgId = useSelector((state) => state.users.user.usrOrgId);

  const [saveEmissionProfileSourceState, setSaveEmissionProfileSourceState] = useState(LoadingState.idle);
  const [isFormDirty, setIsFormDirty] = useState(false);

  const handleCreateEmissionProfileSource = async (data) => {
    try {
      setSaveEmissionProfileSourceState(LoadingState.loading);
      const result = await createEmissionProfileSourceApi(orgId, {
        ...data,
        eprflsrcEprflcatId: emissionProfileCategoryId,
      });
      toast.success('New Emission Source created.');
      dispatch(fetchEmissionProfilesTree({ orgId }));
      dispatch(fetchEmissionProfilesTreeByScope({ orgId }));
      history.push(`/admin/emission-profile/source/${result.eprflsrcId}/edit`);
    } catch (e) {
      setSaveEmissionProfileSourceState(LoadingState.idle);
      toast.error(e.message);
      throw e;
    }
  };

  const handleFormDirty = (isDirty) => {
    setIsFormDirty(isDirty);
  };

  const renderActionButtons = ({ saveAllowed, isDirty, handleReset }) => {
    return (
      <>
        <Button
          type="submit"
          className="mt-3 mb-5"
          disabled={saveEmissionProfileSourceState !== LoadingState.idle || !saveAllowed}
        >
          {t('Create')}
        </Button>
        <Button
          variant="secondary"
          className="ml-4 mt-3 mb-5"
          onClick={() => onCancelHandler(handleReset)}
          disabled={!isDirty}
        >
          {t('Reset')}
        </Button>
        <LoadingButton isLoading={saveEmissionProfileSourceState !== LoadingState.idle} className="ml-4 mt-3 mb-5" />
      </>
    );
  };

  const onCancelHandler = (handleReset) => handleReset();

  useEffect(() => {
    if (emissionProfileStatus === LoadingState.idle) {
      dispatch(fetchEmissionProfilesTree({ orgId }));
    }
    if (emissionProfileTreeByScopeStatus === LoadingState.idle) {
      dispatch(fetchEmissionProfilesTreeByScope({ orgId }));
    }
  }, [orgId, emissionProfileStatus, emissionProfileTreeByScopeStatus, dispatch, saveEmissionProfileSourceState]);

  return (
    <Container fluid>
      <Row>
        <Col>
          <PageHeader
            title={t('Add Emission Profile Source')}
            enableBackButton
            isDirty={isFormDirty}
          />
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <Form
            onSubmit={handleCreateEmissionProfileSource}
            tree={emissionProfileTree}
            scopedTree={emissionProfileTreeByScope}
            renderActionButtons={renderActionButtons}
            handleFormDirty={handleFormDirty}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default NewEmissionProfileSource;
