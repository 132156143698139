import React from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IoChevronBack } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const PageHeader = ({ title, actions = null, enableBackButton = false }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const isCanBack = useSelector((state) => state.users.historyStack.length > 1);
  const goBack = () => isCanBack ? history.goBack() : history.push('/');

  return (
    <>
      {enableBackButton && (
        <Button className="back-button" variant="link" onClick={() => (goBack())}>
          <IoChevronBack /> {t('Back')}
        </Button>
      )}
      <div className="d-flex flex-row justify-content-between my-4 pageheader page-header">
        <h4 className="mb-0">
          <strong>{title}</strong>
        </h4>
        {actions && <div className="pageheader__actions">{actions}</div>}
      </div>
    </>
  );
};

const SecondaryPageHeader = ({ title, actions = null }) => {
  return (
    <div className="d-flex flex-row align-items-center mb-3 pageheader--secondary">
      <h5 className="mb-0 pr-3 ">
        {' '}
        <strong>{title}</strong>{' '}
      </h5>
      {actions && <div className="pageheader--secondary__actions">{actions}</div>}
    </div>
  );
};

const ReportsPageHeader = ({ title, enableBackButton = false, actions = null }) => {
  const history = useHistory();
  // const handleGoBack = () => history.goBack();
  const { t } = useTranslation();
  const goBack = () => history.push('/reports');

  return (
    <div className="align-items-center mt-2 mb-2 report-header-container">
      {enableBackButton && (
        <Button className="back-button mb-2" variant="link" onClick={() => goBack()}>
          <IoChevronBack /> {t('Back')}
        </Button>
      )}
      <div className="d-flex flex-row ml-2 align-items-center">
        <h4 className="pr-3 ">{title}</h4>
        {actions && <div className="pageheader--secondary__actions">{actions}</div>}
      </div>
    </div>
  );
};

PageHeader.Secondary = SecondaryPageHeader;
PageHeader.Reports = ReportsPageHeader;

export default PageHeader;
