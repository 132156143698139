import { Fragment } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { SDGSocialShareLogoComponent } from './SDGSocialShareLogoComponent';
import { useTranslation } from 'react-i18next';

export function SDGSocialShareModal({ showSDGSocialShareModal, setShowSDGSocialShareModal, goalNo, goalName }) {
  const onClickHandle = () => {};
  const {  t } = useTranslation();
  return (
    <Fragment>
      <Fragment>
        <Modal
          animation={false}
          show={showSDGSocialShareModal}
          onHide={() => {
            setShowSDGSocialShareModal(false);
          }}
          size='md'
          aria-labelledby='contained-modal-title-vcenter'
          centered
          backdrop='static'
          className='activities-modal'
        >
          <Modal.Header closeButton></Modal.Header>

          <Modal.Body>
            <Row className='text-center'>
              <Col md={12}>{SDGSocialShareLogoComponent(goalNo)}</Col>
              <Col md={12}>
                <h2 className='text-success font-weight-bold'>{t('Awesome!')}</h2>
              </Col>
              <Col md={12}>
                <p className='text-muted'>
                {t('You have contributed to {{goalName}} of the Millennium Development Goals established by the UN.', { goalName: t(goalName) })}
                </p>
              </Col>
              <Col md={12}>
                <Button onClick={onClickHandle}>{t('Share on Social media')}</Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </Fragment>
    </Fragment>
  );
}
