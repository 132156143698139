import React, { useEffect } from 'react';
import { Col, Container, Row, Spinner, Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Empty from '../../components/Empty';
import PageHeader from '../../components/PageHeader';
import { LoadingState } from '../../utilities/constants';
import { chunkArray, toYesNo } from '../../utilities/formatting';
import { fetchUsers, selectUserById } from './usersSlice';
import { config } from '../../utilities/config';

const ViewUser = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { userId } = useParams();
  const user = useSelector((state) => selectUserById(state, userId));
  const userState = useSelector((state) => state.users.status);
  const userInfo = useSelector((state) => state.users.user);
  const orgId = useSelector((state) => state.users.user.usrOrgId);
  const isBusy = userState === LoadingState.loading;

  const optLocale = user?.optLocale || config.DEFAULT_LOCALE;

  const formatUserRole = (userRole) => {
    const exactUserRole = userRole.substring(3, userRole.length);

    const displayRole = exactUserRole.replace('_', ' ');

    return displayRole && displayRole[0].toUpperCase() + displayRole.slice(1);
  };

  const displayItems = user
    ? [
        { label: t('User Id'), value: user.usrId },
        { label: t('User Name'), value: user.usrName },
        { label: t('E-Mail'), value: user.usrEmail },
        { label: t('First Name'), value: user.usrFirstname },
        { label: t('Last Name'), value: user.usrLastname },
        { label: t('Enabled'), value: toYesNo(user.usrEnabled, t) },
        { label: t('Role'), value: formatUserRole(user.usrRole) },
        { label: t('Language'), value: userInfo?.localeOptions?.find(item => item.value === optLocale)?.label },
        //  { label: t('Date Format'), value: last },
        { label: t('Date Format'), value: user.usrDateFormat },
        { label: t('Time Zone'), value: user.usrTimeZone },
        { label: t('Time Format'), value: user.usrTimeFormat },
        { label: t('Number Format'), value: user.usrNumberFormat },
        { label: t('Number Scaling (emission gases)'), value: user.usrPreferredScaleDigits }
      ]
    : [];

  const itemRows = chunkArray(displayItems, 2);

  useEffect(() => {
    if (userState === LoadingState.idle) {
      dispatch(fetchUsers({ orgId }));
    }
  }, [orgId, userState, dispatch]);

  return (
    <Container fluid>
      {isBusy && <Spinner animation="grow" />}
      {!isBusy && user && (
        <>
          <Row>
            <Col>
              <PageHeader title={t("View User")} enableBackButton={true} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Tabs defaultActiveKey="details" id={`${user.id}-tabs`} className="custom-tab-bar">
                <Tab eventKey="details" title={t("User Details")} className="custom-tab-content mt-3">
                  <Row>
                    <Col lg={8}>
                      {itemRows.map((row, i) => (
                        <Row key={i} className="mt-4">
                          {row.map((col) => (
                            <Col md={6} key={col.label}>
                              <div className="data-view">
                                <label className="label">{col.label}</label>
                                <div className="data">{col.value || '-'}</div>
                              </div>
                            </Col>
                          ))}
                        </Row>
                      ))}
                    </Col>
                  </Row>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </>
      )}
      {!isBusy && !user && <Empty title="Sorry, user was not found" />}
    </Container>
  );
};

export default ViewUser;
