import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { setLeavePrompt } from '../../manage-users/usersSlice';
import { useDispatch } from 'react-redux';

const EmissionProfileCategoryForm = ({ onSubmit, initialValues = {}, actionButtons, isEditing }) => {
  const { t } = useTranslation();
  const schema = yup.object().shape({
    eprflcatName: yup.string().required(t('Category name is required')),
    eprflcatDesc: yup.string(),
    weight: yup.number()
      .min(1, t('Sorting position be greater than or equal to 1'))
      .typeError(t('Sorting position must be a number'))
      .transform((v, val) => !val ? null : Number(val))
      .nullable(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid }
  } = useForm({
    defaultValues: initialValues,
    mode: 'onChange',
    resolver: yupResolver(schema)
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLeavePrompt(isDirty))
  }, [isDirty, dispatch]);

  const onSubmitHandler = async(data) => {
    try {
      dispatch(setLeavePrompt(false));
      await onSubmit(data);
    } catch (e) {
      dispatch(setLeavePrompt(isDirty));
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmitHandler)}>
      <Row>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Category Name')} *</Form.Label>
            <Form.Control readOnly={isEditing && !initialValues.eprflcatParentId} {...register('eprflcatName')} isInvalid={errors.eprflcatName} />
            <Form.Control.Feedback type="invalid">{errors.eprflcatName?.message}</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Category Description')}</Form.Label>
            <Form.Control as="textarea" {...register('eprflcatDesc')} isInvalid={errors.eprflcatDesc} />
            <Form.Control.Feedback type="invalid">{errors.eprflcatDesc?.message}</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>
              {t('Sorting Position (empty to set sorting by alphabet)')}
            </Form.Label>
            <Form.Control onWheel={(e) => e.target.blur()} type="number" {...register('weight')} isInvalid={errors.weight} />
            <Form.Control.Feedback type="invalid">{errors.weight?.message}</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      {actionButtons({ isValid, isDirty })}
    </Form>
  );
};

export default EmissionProfileCategoryForm;
