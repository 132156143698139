import { config } from '../../utilities/config';
import keycloak from '../../keycloak';

export async function fetchEntityBusinessMeasuresApi(organizationId, entityId) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}}/entity/${entityId}/business-measure`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    }
  });
  const data = await response.json();
  if (!response.ok) throw new Error(data.message);
  return data;
}

export async function assignEntityBusinessMeasureApi(organizationId, entityId, data) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}}/entity/${entityId}/business-measure`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function updateEntityBusinessMeasureApi(organizationId, entityId, bsnsmsrId, data) {
  const response = await fetch(
    `${config.API_BASE_URL}/${organizationId}/entity/${entityId}/business-measure/${bsnsmsrId}`,
    {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${keycloak.token}`
      },
      body: JSON.stringify(data)
    }
  );
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function removeEntityBusinessMeasureApi(organizationId, entityId, bsnsmsrId) {
  await fetch(`${config.API_BASE_URL}/${organizationId}/entity/${entityId}/business-measure/${bsnsmsrId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    }
  });
}
