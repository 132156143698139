import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import TitleDivider from '../../../components/TitleDivider';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import ReactDatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEntityTree } from '../../entity/entitySlice';
import { LoadingState } from '../../../utilities/constants';
import { fetchEmissionProfileTreeByEntity } from '../../emissions/emissionSlice';
import { recursiveMap } from '../../../utilities/treeHelpers';
import NodeIcon from '../../../components/NodeIcon';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import EPSForm from './EPSForm';
import TreeViewModal from '../../../components/TreeViewModal';
import DeleteAction from './DeleteAction';
import { datePickerClass, datePickerValue, getMonthDateFormat, parseUserInfo } from '../../../utilities/common';
import { setLeavePrompt } from '../../manage-users/usersSlice';
import { formatNumber } from '../../reports/reportHelper';
const ActionForm = ({
  onSubmit,
  onCancel,
  initialValues = {},
  buttonText = 'Submit',
  isEditing = false,
  disabled,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { orgId, usrDateFormat, usrTimezone } = parseUserInfo(useSelector((state) => state.users.user));
  const PURPOSE_TYPE = {
    REDUCTION: 'Reduction',
  };
  const actionSchema = yup.object().shape({
    actName: yup.string().required(t('Name is required')),
    actStartMonth: yup.string().transform(value => !value ? '' : value).required(t('Start Month is required')),
    actEndMonth: yup.string().transform(value => !value ? '' : value).required(t('End Month is required')),
    actPurpose: yup.string(),
    actDescription: yup.string().nullable(),
    divId: yup.number().required(),
    actTotalReduction: yup.number().required(t('Total CO2-e reduction is required')),
    actEprflsrcs: yup.array().min(1).of(
      yup.object().shape({
        eprflsrcId: yup.number().required(t('Emission profile source is required')),
        reductions: yup.array().min(1).of(
          yup.object().shape({
            reductionTarget: yup.number().typeError(t('Target must be a number')).required(t('Target is required')),
            reductionUnit: yup.string().required(t('Unit is required')),
            plannedReduction: yup.number().typeError(t('Planned CO2-e reduction must be a number')).required(t('Planned CO2-e reduction is required')),
            reductionPrincipalValue: yup.number().typeError(t('Principal value must be a number')).nullable().when('reductionUnit', {
              is: '%',
              then: yup.number().required(t('Principal value is required'))
            }),
          })
        )
      }),
    )
  });
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors, isValid, isDirty },
    reset
  } = useForm({
    defaultValues: {
      actTotalReduction: 0,
      ...initialValues
    },
    resolver: yupResolver(actionSchema),
    mode: 'onChange'
  });

  const { fields: actEprflsrcs, append: appendActEprflsrc, remove: removeActEprflsrc } = useFieldArray({
    control,
    name: "actEprflsrcs",
    defaultValues: []
  });

  const [actPurpose, setActPurpose] = useState(initialValues.actPurpose ?? PURPOSE_TYPE.REDUCTION);
  const handlePurposeChange = (event) => setActPurpose(event.target.value);

  const [showDeleteEPSWarningModal, setShowDeleteEPSWarningModal] = useState(false);
  const [deleteEPS, setDeleteEPS] = useState(null);
  const [deleteEPSIdx, setDeleteEPSIdx] = useState();
  const [showFormulaGuideline, setShowFormulaGuideline] = useState(false);
  const [showChangeEntityWarningModal, setShowChangeEntityWarningModal] = useState(false);
  const [divId, setDivId] = useState(initialValues.divId);
  const [actDiv, setActDiv] = useState(initialValues.actDiv);

  const loadingEntities = useSelector((state) => state.entities.status) === LoadingState.loading;
  const loadingEP = useSelector((state) => state.emissions.status) === LoadingState.loading;

  const [showDeleteWarningModal, setShowDeleteWarningModal] = useState(false);
  const [deleteAct, setDeleteAct] = useState(null);

  const entityTree = useSelector((state) => state.entities.tree);
  const entityStatus = useSelector((state) => state.entities.status);
  const [showEntitySelector, setShowEntitySelector] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState(null);

  const EPTree = useSelector((state) => state.emissions.entityEPTree);
  const [showEPSelector, setShowEPSelector] = useState(false);

  const openEntityModal = () => setShowEntitySelector(true);
  const closeEntityModal = () => setShowEntitySelector(false);

  const actTotalReduction = useWatch({
    control,
    name: "actTotalReduction",
  });

  const watchActEprflsrcs = useWatch({
    control,
    name: "actEprflsrcs"
  })

  const watchStartMonth = useWatch({
    control,
    name: "actStartMonth"
  });

  const watchEndMonth = useWatch({
    control,
    name: "actEndMonth"
  });

  const openEPModal = () => {
    setShowEPSelector(true);
    dispatch(fetchEmissionProfileTreeByEntity({ orgId, entityId: divId }));
  }
  const closeEPModal = () => setShowEPSelector(false);
  const handleSelectEntity = (entity) => {
    if (divId) {
      setSelectedEntity(entity);
      setShowChangeEntityWarningModal(true);
    } else {
      setDivId(entity.divId)
      setActDiv(entity)
      setValue("divId", entity.divId)
      dispatch(fetchEmissionProfileTreeByEntity({ orgId, entityId: entity.divId }));
      closeEntityModal();
      setShowEPSelector(true);
    }
  };

  const handleConfirmChangeEntity = () => {
    reset({
      actName: getValues("actName"),
      actStartMonth: getValues("actStartMonth"),
      actEndMonth: getValues("actEndMonth"),
      actPurpose: getValues("actPurpose"),
      actDescription: getValues("actDescription"),
      divId: selectedEntity.divId,
      actTotalReduction: 0,
    })
    setDivId(selectedEntity.divId)
    setActDiv(selectedEntity)
    dispatch(fetchEmissionProfileTreeByEntity({ orgId, entityId: selectedEntity.divId }));
    closeChangeEntityWarningModal();
    closeEntityModal();
    setShowEPSelector(true);
    setSelectedEntity(null);
  }

  const handleSelectEP = (eps, removeIdx = null) => {
    if (removeIdx === -1) {
      appendActEprflsrc({
        eprflsrcId: eps.eprflsrcId,
        eprflsrc: eps,
        reductions: [{
          isGetPreviousYearEmission: false,
          reductionPrincipalValue: null,
          reductionTarget: null,
          reductionUnit: null,
          plannedReduction: 0,
        },]
      })
    } else {
      handleDeleteEPS(eps, removeIdx);
    }
  };

  const handleDeleteEPS = async (eps, removeIdx) => {
    let isFilled = false;
    const watchActEprflsrc = watchActEprflsrcs.find((actEprflsrc) => parseInt(actEprflsrc.eprflsrcId) === parseInt(eps.eprflsrcId))
    if (watchActEprflsrc?.reductions) {
      for (let i = 0; i < watchActEprflsrc.reductions.length; i++) {
        const reduction = watchActEprflsrc.reductions[i];
        for (let j = 0; j < Object.values(reduction).length; j++) {
          const value = Object.values(reduction)[j];
          if (Object.keys(reduction)[j] !== "id" && value) {
            isFilled = true;
            i = watchActEprflsrc.reductions.length; // break
            break;
          }
        }
      }
    }
    if (isFilled) {
      setShowDeleteEPSWarningModal(true);
      setDeleteEPS(eps);
      setDeleteEPSIdx(removeIdx);
    } else {
      let newActTotalReduction = actTotalReduction;
      const deletedAcEprflsrc = watchActEprflsrcs[removeIdx];
      deletedAcEprflsrc?.reductions.forEach((reduction) => newActTotalReduction -= parseFloat(reduction.plannedReduction))
      setValue("actTotalReduction", Number(newActTotalReduction)); //recalculate total reduction
      removeActEprflsrc(removeIdx);
    }
  }

  const handleConfirmDeleteEPS = () => {
    let newActTotalReduction = actTotalReduction;
    const deletedAcEprflsrc = watchActEprflsrcs[deleteEPSIdx];
    deletedAcEprflsrc?.reductions.forEach((reduction) => newActTotalReduction -= parseFloat(reduction.plannedReduction))
    setValue("actTotalReduction", Number(newActTotalReduction)); //recalculate total reduction
    removeActEprflsrc(deleteEPSIdx);
    setDeleteEPS(null);
    setDeleteEPSIdx(null);
    setShowDeleteEPSWarningModal(false);
  };

  const closeConfirmModal = () => setShowDeleteEPSWarningModal(false);
  const closeFormulaGuidelineModal = () => setShowFormulaGuideline(false);
  const closeChangeEntityWarningModal = () => setShowChangeEntityWarningModal(false);
  const closeDeleteWarningModal = () => setShowDeleteWarningModal(false);

  const transformedEntityTree = recursiveMap(entityTree ?? {}, (n) => ({
    value: n.divId,
    valueId: n.divAssignedId,
    labelRaw: n.divName,
    isAssigned: n.divIsAssigned ?? false,
    label: (
      <div className={`flex-grow-1 ${n.divIsAssigned ? "enabled" : "disabled"}`} onClick={() => n.divIsAssigned ? handleSelectEntity(n) : null}>
        {n.divName}
      </div>
    )
  }));

  const transformedEPTree = recursiveMap(EPTree ?? {}, (n) => {
    if (n.eprflId) {
      return {
        value: n.eprflId,
        labelRaw: n.eprflName,
        label: <span>{n.eprflName}</span>,
        isActive: false
      };
    }
    if (n.eprflcatId) {
      return {
        value: n.eprflcatId,
        labelRaw: n.eprflcatName,
        label: (
          <span>
            <NodeIcon node={n} />
            {n.eprflcatName}
          </span>
        ),
        isActive: false
      };
    }
    if (n.eprflsrcId) {
      const idx = actEprflsrcs ? actEprflsrcs.findIndex(item => item.eprflsrcId === n.eprflsrcId) : -1;
      return {
        value: n.eprflsrcId,
        labelRaw: n.eprflsrcName,
        label: (
          <span onClick={() => handleSelectEP(n, idx)}>
            <Form.Check custom checked={idx !== -1} label={n.eprflsrcName} />
          </span>
        ),
        isActive: idx !== -1
      };
    }
  });

  const onSubmitHandler = async (data) => {
    try {
      dispatch(setLeavePrompt(false));
      await onSubmit(data);
    } catch (e) {
      dispatch(setLeavePrompt(isDirty));
    }
  }

  useEffect(() => {
    dispatch(setLeavePrompt(isDirty));
  }, [isDirty, dispatch]);

  const onCancelHandler = () => onCancel();
  const onDeleteHandler = () => {
    setDeleteAct(initialValues);
    setShowDeleteWarningModal(true);
  };

  useEffect(() => {
    if (entityStatus === LoadingState.idle) {
      dispatch(fetchEntityTree({ orgId }));
    }
  }, [orgId, entityStatus, dispatch]);

  const monthDateFormat = getMonthDateFormat(usrDateFormat);
  return (
    <Container fluid>
      <Form onSubmit={handleSubmit(onSubmitHandler)}>
        <Row>
          <Col lg={12}>
            <Row>
              <Col md={6}>
                <Form.Group className="custom-form-control">
                  <Form.Label>{t('Name')} *</Form.Label>
                  <Form.Control
                    {...register('actName')}
                    isInvalid={errors.actName}
                    placeholder={t('Enter your action name')}
                  />
                  <Form.Control.Feedback type="invalid">{errors.actName?.message}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className="custom-form-control">
                  <Form.Label>{t('Start Month')} *</Form.Label>
                  <Controller
                    name="actStartMonth"
                    control={control}
                    render={({ field, fieldState: { invalid } }) => {
                      return (
                        <ReactDatePicker
                          isClearable
                          dateFormat={monthDateFormat}
                          popperPlacement="bottom-end"
                          showMonthYearPicker
                          maxDate={!!watchEndMonth ? Date.parse(watchEndMonth) : null}
                          placeholderText={t('Select Start Month')}
                          {...datePickerClass(invalid)}
                          {...datePickerValue(field.value, field.onChange)}
                        />
                      );
                    }}
                  />
                  <Form.Control.Feedback type="invalid">{errors.actStartMonth?.message}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className="custom-form-control">
                  <Form.Label>{t('End Month')} *</Form.Label>
                  <Controller
                    name="actEndMonth"
                    control={control}
                    render={({ field, fieldState: { invalid } }) => {
                      return (
                        <ReactDatePicker
                          isClearable
                          dateFormat={monthDateFormat}
                          minDate={!!watchStartMonth ? Date.parse(watchStartMonth) : null}
                          popperPlacement="bottom-end"
                          showMonthYearPicker
                          placeholderText={t('Select End Month')}
                          {...datePickerClass(invalid)}
                          {...datePickerValue(field.value, field.onChange)}
                        />
                      );
                    }}
                  />
                  <Form.Control.Feedback type="invalid">{errors.actEndMonth?.message}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="custom-form-control horizontal-radio-group">
                  <Form.Label>{t('Purpose')} *</Form.Label>
                  <Form.Check
                    type="radio"
                    {...register('actPurpose')}
                    id={PURPOSE_TYPE.REDUCTION}
                    value={PURPOSE_TYPE.REDUCTION}
                    checked={actPurpose === PURPOSE_TYPE.REDUCTION}
                    label={t('Reduction')}
                    onChange={handlePurposeChange}
                  />
                  <Form.Control.Feedback type="invalid">{errors.actPurpose?.message}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="custom-form-control mb-0">
                  <Form.Label>{t('Description')}</Form.Label>
                  <Form.Control as="textarea"
                    {...register('actDescription')}
                    isInvalid={errors.actDescription}
                    placeholder={t('Enter description')}
                  />
                  <Form.Control.Feedback type="invalid">{errors.actDescription?.message}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <TitleDivider title={t('Applied to')} />
            {loadingEntities ? (
              <Spinner animation="grow" />
            ) : (
              <Row>
                <Col md={8}>
                  <Form.Group className="custom-form-control">
                    <Form.Label>{t('Entity')} *
                      <AiOutlineQuestionCircle
                        className='mt-n1 ml-2' style={{ cursor: 'pointer' }} onClick={() => setShowFormulaGuideline(true)}
                      />
                    </Form.Label>
                    <div className="d-flex flex-row">
                      <Button className='display-block mr-2' variant="secondary" onClick={openEntityModal}>
                        {t('Select Entities')}
                      </Button>
                      <Form.Control
                        value={actDiv?.divName}
                        placeholder={t('Entity Name')}
                        disabled
                      />
                    </div>
                    <Form.Control
                      {...register("divId")}
                      isInvalid={errors.divId}
                      value={divId}
                      readOnly
                      hidden
                    />
                  </Form.Group>
                </Col>
              </Row>
            )}
            {actDiv &&
              (loadingEP ? (
                <Spinner animation="grow" />
              ) : (
                <Row>
                  <Col>
                    <Form.Group className="custom-form-control">
                      <Form.Label>{t('Emission Profile Source (EPS)')} *</Form.Label>
                      <Button className='display-block' variant="secondary" onClick={openEPModal}>
                        {t('Select Emission Profile Source')}
                      </Button>
                    </Form.Group>
                  </Col>
                </Row>
              ))
            }
            {actEprflsrcs?.length > 0 && actEprflsrcs.map((actEprflsrc, actEprflsrcIdx) => (
              <Row key={actEprflsrc.id}>
                <Col>
                  <EPSForm
                    key={actEprflsrc.id}
                    register={register}
                    errors={errors}
                    setValue={setValue}
                    getValues={getValues}
                    control={control}
                    actEprflsrc={actEprflsrc}
                    actEprflsrcIdx={actEprflsrcIdx}
                    handleDeleteEPS={handleDeleteEPS}
                    watchStartMonth={watchStartMonth}
                    orgId={orgId}
                    divId={divId}
                    actTotalReduction={actTotalReduction}
                    usrDateFormat={usrDateFormat}
                    usrTimezone={usrTimezone}
                  />
                </Col>
              </Row>
            ))}
            <Row>
              <Col md={6}>
                <Form.Group className="custom-form-control">
                  <Form.Label>{t('Total CO2-e reduction (tonnes)')} *</Form.Label>
                  <Form.Control
                    isInvalid={errors.actTotalReduction}
                    value={formatNumber(actTotalReduction)}
                    disabled
                  />
                  <Form.Control.Feedback type="invalid">{errors.actTotalReduction?.message}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Row>
        <Button
          className="mb-5"
          type="submit"
          disabled={!isValid || !isDirty || disabled}
        >
          {buttonText}
        </Button>
        <Button variant="secondary" className="ml-4 btn-lg mb-5" onClick={onCancelHandler}>
          {t('Cancel')}
        </Button>
        {isEditing && (
          <Button
            variant="secondary"
            className="ml-4 btn-lg mb-5"
            onClick={() => {
              reset();
            }}
            disabled={!isDirty}
          >
            {t('Reset')}
          </Button>
        )}
        {isEditing && (
          <Button variant="secondary" className="ml-4 btn-lg mb-5" onClick={onDeleteHandler}>
            {t('Delete')}
          </Button>
        )}
      </Form>
      <TreeViewModal
        tree={transformedEntityTree}
        closeModal={closeEntityModal}
        show={showEntitySelector}
        backdrop={!!actDiv}
        title={t('Select Entities')}
      />
      <TreeViewModal
        tree={transformedEPTree}
        closeModal={closeEPModal}
        show={showEPSelector}
        title={t('Select Emission Profile Source')}
      />
      <Modal show={showChangeEntityWarningModal} onHide={closeChangeEntityWarningModal} className="sisde-modal">
        <Modal.Header>
          <Modal.Title>{t('Warning')}!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {t('Are you sure that you want to change Entity? Once you change, you cannot undo and all reduction inputs will be discarded.')}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleConfirmChangeEntity}>
            {t('Confirm')}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showDeleteEPSWarningModal} onHide={closeConfirmModal} className="sisde-modal">
        <Modal.Header>
          <Modal.Title>{t('Warning')}!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {t('Are you sure that you want to permanently delete EPS {{epsName}}?', { epsName: deleteEPS?.eprflsrc?.eprflsrcName })}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleConfirmDeleteEPS}>
            {t('Delete')}
          </Button>
        </Modal.Footer>
      </Modal>
      <DeleteAction
        orgId={orgId}
        deleteAct={deleteAct}
        showDeleteWarningModal={showDeleteWarningModal}
        closeDeleteWarningModal={closeDeleteWarningModal}
      />
      <Modal show={showFormulaGuideline} onHide={closeFormulaGuidelineModal} className="sisde-modal">
        <Modal.Header>
          <Modal.Title><b>{t('Total planned CO2-e reduction amount per EPS')}</b> ({t('tonnes')})</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p><b>{t('Total planned CO2-e reduction amount per EPS')}</b> {t('is the sum of results from inputs of different units.')}</p>
          <b>{t('For unit')} %:</b>
          <ul className='m-0'>
            <li>{t('Target input with unit % is the constant annual compound reduction rate.')}</li>
            <li>{t('Principal (initial) value can be the actual data retrieved from the year before Start date or manual input.')}</li>
            <li><b>Er = Po(1-(1-r)^y)</b></li>
          </ul>
          <p>(Er: {t('Reduced emission')}; Po: {t('principal value')}; r: {t('target input')}; y: {t('no. applied years')})</p>
          <b>{t('For other units')}:</b>
          <ul className='m-0'>
            <li>{t('Target input with other units (kg, kL, etc) is the reduction in usage of corresponding source.')}</li>
            <li><b>Er = F * r</b></li>
          </ul>
          <p>(Er: {t('Reduced emission')}; r: {t('target input)')}</p>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default ActionForm;
