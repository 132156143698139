import keycloak from '../../keycloak';
import { config } from '../../utilities/config';

export async function fetchCarbonFootprintByEntityApi(organizationId, data) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/reports/carbon-footprint-by-entity`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  if (!response.ok) {
    throw new Error(result.message);
  }
  return result;
}

export async function fetchCarbonFootprintByScopeApi(organizationId, data) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/reports/carbon-footprint-by-scope`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function fetchMovingAverageApi(organizationId, data) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/reports/moving-average`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function fetchEmissionsByTop10EntitiesApi(organizationId, data) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/reports/emissions-by-top-10-entities`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function fetchEmissionsByTop10EPSApi(organizationId, data) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/reports/emissions-by-top-10-eps`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function fetchEmissionsByTop10ContributorsApi(organizationId, data) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/reports/emissions-by-top-10-contributors`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function fetchEntitiesApi(organizationId, data) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/reports/entities`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function fetchScopesApi(organizationId) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/emission-profile/scope`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    }
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function fetchEmissionsByAssetApi(organizationId, data) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/reports/emissions-by-asset`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function fetchTransactionReportApi(organizationId, data) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/reports/transaction`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function fetchDataGapApi(organizationId, data) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/reports/data-gap`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function fetchEmissionsByEntityGroupApi(organizationId, data) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/reports/emissions-by-entity-group`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function fetchDataTemplateIDsReportApi(organizationId) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/reports/data-template-ids`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    }
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function fetchDataValidationApi(organizationId, data) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/reports/data-validation`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function fetchCarbonFootprintByFullEmissionProfileApi(organizationId, data) {
  const orgId = organizationId;
  const response = await fetch(`${config.API_BASE_URL}/${orgId}/reports/carbon-footprint-by-full-ep`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  if (!response.ok) {
    throw new Error(result.message);
  }
  return result;
}

export async function fetchIntensityEmissionsByEntityApi(organizationId, data) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/reports/intensity-emissions-by-entity`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',

      Authorization: `Bearer ${keycloak.token}`
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function fetchIntensityBusinessMeasuresByEntityApi(organizationId, data) {
  const response = await fetch(
    `${config.API_BASE_URL}/${organizationId}/reports/intensity-business-measures-by-entity`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',

        Authorization: `Bearer ${keycloak.token}`
      },
      body: JSON.stringify(data)
    }
  );
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function fetchBusinessMeasuresApi(organizationId) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/business-measure`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    }
  });
  const result = response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function fetchEmissionsByEntityOrMetricApi(organizationId, data) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/reports/emission-by-entity-or-metric`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function fetchOrganizationEmissionSummaryApi(organizationId, data) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/reports/summary`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function fetchOrganizationListYearsApi(organizationId, data) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/reports/list-emission-years`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}
