import React, { useState } from 'react';
import { Container, Carousel } from 'react-bootstrap';
import GettingStarted from './GettingStarted';
import KeyFeatures from './KeyFeatures';
import ScoringProcess from './ScoringProcess';
import ScoringMethodology from './ScoringMethodology';

const ManageCoriolis = () => {
  const maxSelectedIndex = 3;
  const classNames = ['manage-coriolis'];
  const [selectedIndex, setSelectedIndex] = useState(0);
  switch (selectedIndex) {
    case 0:
      classNames.push('coriolis-first');
      break;
    case maxSelectedIndex:
      classNames.push('coriolis-last');
      break;
    default:
      break;
  }
  return (
    <Container fluid className={classNames.join(' ')}>
      <Carousel
        className="coriolis-carousel"
        interval={null}
        onSelect={(idx) => setSelectedIndex(idx)}
        indicators={false}
      >
        <Carousel.Item>
          <GettingStarted />
        </Carousel.Item>
        <Carousel.Item>
          <KeyFeatures />
        </Carousel.Item>
        <Carousel.Item>
          <ScoringProcess />
        </Carousel.Item>
        <Carousel.Item>
          <ScoringMethodology />
        </Carousel.Item>
      </Carousel>
      <span className="coriolis-pagination">{selectedIndex + 1}/{maxSelectedIndex + 1}</span>
    </Container>
  );
};

export default ManageCoriolis;