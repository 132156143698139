export const recursiveMap = (node, handler, parentNode = null) => {
  if (!node.children || node.children.length === 0) {
    return { ...handler(node, parentNode) };
  }
  return { ...handler(node, parentNode), children: node.children.map((c) => recursiveMap(c, handler, node)) };
};

export const recursiveFind = (node, predicate) => {
  if (predicate(node)) return node;
  if (!node.children) return null;
  for (const child of node.children) {
    const found = recursiveFind(child, predicate);
    if (found) return found;
  }
};

export const recursiveTreeFilter = (node, predicate) => {
  if (!node.children || node.children.length === 0) {
    const match = predicate(node);
    return match
      ? {
          ...node
        }
      : null;
  }
  const compare =  (a, b) => {
    if (a.weight && b.weight) {
      return a.weight < b.weight ? -1 : 1;
    }
    if (a.labelRaw?.toUpperCase() < b.labelRaw?.toUpperCase()) {
      return -1
    }
    if (a.labelRaw?.toUpperCase() > b.labelRaw?.toUpperCase()) {
       return 1
    }
    if(a.labelRaw < b.labelRaw) {
      return -1
    } else {
      return 1
    }
  }
  const nonOrderedChildren = node.children.filter(item => !item.weight).sort(compare);
  const children = node.children.filter(item => !!item.weight)
    .sort(compare).concat(nonOrderedChildren)
    .map((c) => recursiveTreeFilter(c, predicate));
  const childrenMatch = children.filter((c) => c !== null).length > 0;
  const selfMatch = predicate(node);
  const match = selfMatch || childrenMatch;
  if (!match) return null;
  return {
    ...node,
    children: children.filter((c) => c !== null)
  };
};
