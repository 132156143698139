import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { LoadingState } from '../../utilities/constants';
import {
  createEnergyContentFactorApi,
  fetchEnergyContentFactorUploadsApi,
  fetchUnitListApi,
  removeEnergyContentFactorApi,
  updateEnergyContentFactorApi
} from './EnergyContentFactorApi';

export const fetchData = createAsyncThunk('energy-content-factor/fetch', async () => {
  try {
    const response = await fetchEnergyContentFactorUploadsApi();
    return response;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});
export const fetchUnitList = createAsyncThunk('energy-content-factor/fetch-unit', async () => {
  try {
    const response = await fetchUnitListApi();
    return response;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const createFactor = createAsyncThunk('energy-content-factor/create', async (payload, { dispatch }) => {
  try {
    const factor = await createEnergyContentFactorApi(payload.data);
    toast.success('Factors successfully created');
    dispatch(fetchData());
    return factor;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const editFactor = createAsyncThunk('energy-content-factor/edit', async (payload, { dispatch }) => {
  try {
    const factor = await updateEnergyContentFactorApi(payload.id, payload.data);
    toast.success('Factors successfully updated');
    dispatch(fetchData());
    return factor;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const deleteFactor = createAsyncThunk('energy-content-factor/delete', async (payload, { dispatch }) => {
  try {
    const factor = await removeEnergyContentFactorApi(payload.id);
    toast.success('Factors successfully removed');
    dispatch(fetchData());
    return factor;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

const energyContentSlice = createSlice({
  name: 'energyContent',
  initialState: {
    factors: [],
    units: null,
    status: LoadingState.idle,
    error: null
  },
  reducers: {},
  extraReducers: {
    [fetchData.pending]: (state) => {
      state.status = LoadingState.loading;
    },
    [fetchData.fulfilled]: (state, action) => {
      state.status = LoadingState.succeeded;
      state.factors = action.payload;
    },
    [fetchUnitList.pending]: (state) => {
      state.status = LoadingState.loading;
    },
    [fetchUnitList.fulfilled]: (state, action) => {
      state.status = LoadingState.succeeded;
      state.units = action.payload;
    },
    [deleteFactor.pending]: (state) => {
      state.status = LoadingState.loading;
    },
    [deleteFactor.rejected]: (state, action) => {
      state.status = LoadingState.failed;
      state.error = action.error.message;
    },
    [editFactor.pending]: (state) => {
      state.status = LoadingState.loading;
    },
    [editFactor.rejected]: (state, action) => {
      state.status = LoadingState.failed;
      state.error = action.error.message;
    },
    [createFactor.pending]: (state) => {
      state.status = LoadingState.loading;
    },
    [createFactor.rejected]: (state, action) => {
      state.status = LoadingState.failed;
      state.error = action.error.message;
    },

  }
});

export default energyContentSlice.reducer;
