import _ from 'lodash';
import axios from 'axios';
import { config } from '../utilities/config';
import keycloak from '../keycloak';

const storeKey = 'ipt-data';
let impersonateData = null;
let isSetupApiRequest = false;

export const isRootAuthorized = () => {
  if (keycloak.authenticated && keycloak) {
    return keycloak.hasResourceRole('cv_root', config.KEYCLOAK_BACKEND_CLIENT_ID);
  }
  return false;
};

export const applyTo = (user, userRole, rootId) => {
  if (!user.usrKeycloakId || !userRole) {
    throw new Error('Can not impersonate to this user');
  }
  const {
    usrKeycloakId,
    usrOrgId,
    usrEmail,
    usrName,
    usrFirstname,
    usrLastname,
  } = user;
  localStorage.setItem(storeKey, JSON.stringify({
    usrKeycloakId,
    usrOrgId,
    usrName,
    usrEmail,
    usrFirstname,
    usrLastname,
    userRole,
    rootId,
  }));
};

export const stop = () => {
  localStorage.removeItem(storeKey);
};

export const setupApiRequests = (usrKeyCloakId) => {
  if (isSetupApiRequest || !read(usrKeyCloakId)) {
    return;
  }
  axios.interceptors.request.use((config) => {
    if (config.headers && config.headers.Authorization) {
      config.headers.Authorization = getToken(config.headers.Authorization, usrKeyCloakId);
    }
    return config;
  });
  const originalFetch = window.fetch;
  window.fetch = function () {
    if (arguments[1] && arguments[1]?.headers?.Authorization) {
      arguments[1].headers.Authorization = getToken(arguments[1].headers.Authorization, usrKeyCloakId);
    }
    return originalFetch.apply(this, arguments);
  };
  isSetupApiRequest = true;
};

export const getToken = (token, usrKeyCloakId) => {
  if (!read(usrKeyCloakId)) {
    return token;
  }
  const iptToken = `${impersonateData.usrKeycloakId};${impersonateData.userRole}`;
  return `Impersonate=${iptToken} ${token}`;
};

export const getKeycloakId = (usrKeycloakId) => {
  if (!read(usrKeycloakId)) {
    return usrKeycloakId;
  }
  return impersonateData.usrKeycloakId;
};

export const isAuthorized = (roles) => {
  const currImpersonate = read(keycloak?.subject);
  if (currImpersonate) {
    return roles.some(r => {
      return currImpersonate.userRole === r;
    });
  }
  if (keycloak?.authenticated && roles) {
    return roles.some(r => {
      return keycloak.hasResourceRole(r, config.KEYCLOAK_BACKEND_CLIENT_ID);
    });
  }
  return false;
}

export const read = (usrKeycloakId) => {
  if (_.isEmpty(impersonateData)) {
    const jsonText = localStorage.getItem(storeKey);
    if (jsonText) {
      try {
        const data = JSON.parse(jsonText);
        if (data.usrKeycloakId && data.rootId === usrKeycloakId) {
          impersonateData = data;
        } else {
          stop();
        }
      } catch (ex) {
        console.log(ex);
      }
    }
  }
  return impersonateData;
};
