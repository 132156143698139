import { config } from '../../utilities/config';
import { uploadToS3 } from '../../utilities/apiHelper';
import keycloak from '../../keycloak';

export async function fetchBulkInsertUploadsApi(organizationId) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/bulk-insert`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    }
  });
  const result = response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function uploadBulkInsertApi(organizationId,  uploadType, file) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/bulk-insert/generate`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${keycloak.token}`
    },
    body: JSON.stringify({ uploadType }),
  });
  const signedPost = await response.json();
  if (!response.ok) throw new Error(signedPost.message);
  await uploadToS3(signedPost, file);
  return { key: signedPost.fields.key, name: file.name };
}

export async function createBulkInsertApi(organizationId,  data) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/bulk-insert`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${keycloak.token}`
    },
    body: JSON.stringify(data),
  });
  const result = response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function downloadBulkInsertFileApi(organizationId, uploadedFileId, errorFile = false) {
  let url = `${config.API_BASE_URL}/${organizationId}/bulk-insert/${uploadedFileId}`;
  if (errorFile) url = `${url}/errors`;
  const response = await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    }
  });
  const data = response.json();
  if (!response.ok) throw new Error(data.message);
  return data;
}
