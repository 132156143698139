import { Fragment, useEffect, useState } from 'react';
import sdgLogo from '../../assets/images/sdg-logo.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getSdgDataByOrg } from './disclosureSlice';
import { LoadingState } from '../../utilities/constants';
import { Col, Row, Spinner } from 'react-bootstrap';
import { SDGPromptModal } from './disclosure-components/SDGPromptModal';
// import { KpiSummaryGraph } from './disclosure-components/KPISummaryGraph';
import placeholderAnalytics from '../../../src/assets/images/analytics.svg';
import { DisclosureSetupModal } from './disclosure-components/DisclosureModal';
import { SDGGoalSummaryGraph } from './disclosure-components/SDGGoalSummaryGraph';
import { DisclosureCardComponent } from './disclosure-components/DisclosureCardComponent';
import { unwrapResult } from '@reduxjs/toolkit';
import DownloadSummary from './disclosure-components/DownloadSummary';
import { useTranslation } from 'react-i18next';
import { formatNumber } from '../reports/reportHelper';

export function ManageDisclosure() {
  const { t } = useTranslation();
  const disclosureCardDetails = [
    {
      title: t('Sustainable Development Goals'),
      description: t('Strengthen the sustainability performance of your business and learn about the Sustainable Development Goals. The SDG tool will provide your organization with a report which demonstrates your alignment with SDGs. In continuing with this process you accept the responsibility to fill in all of the KPIs accurately. See what you can achieve here!'),
      imgUrl: sdgLogo,
      btnName: t('Try it now'),
      disabled: false
    },
    {
      title: t('Upcoming measures'),
      description: t('Account for other achievements such as the modern slavery act compliance and disclosure. Assess and disclose your risk from climate change with our upcoming tools.'),
      imgUrl:
        'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAkGBxISEhUSEhIVFRUXFxgVGBUXFxcYFxcaFRoXFxUXFRgaHSggGBolHRUXITEhJSkrLi4uFx8zODMtNygtLisBCgoKBQUFDgUFDisZExkrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrK//AABEIAOEA4QMBIgACEQEDEQH/xAAXAAEBAQEAAAAAAAAAAAAAAAAAAQIH/8QAIBABAQEAAgICAwEAAAAAAAAAABEBAvAhMUGxEoHhwf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/xAAUEQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIRAxEAPwDtUUUAMAUQBQACBAQAAACIsAAABFBFQgKIsAEAVAoJ+ODXgBF+0AUqLQVMCgoICkAEAAAACACKgKhoBSAAQAAABFBRKoIABVABagAoAaAAACCoAAAVAFQKAAAF7/AAAAAD8u9wS98gKIoGaqKAACgAAAAaBUVAAAEUgILiAGgBoqAAUAAEoUAVMUBUXAMABQACgBoAIAAigAAAACKAkFATRUAgAHkJqgzipgC1UAXBFAVIoAAAACAABQAoAAABgAAAIBuAAv4hAGcKFBYEAUxFAIALRFABAUQAKAAAAAFCgFAARQEFQDwqUBMKKCZimYAL6SGAoYAVUAWiAKioAUAAAAgAAAAAACKIArNUEUoABnfQCooBhAAMANVF0EFQAAAACAABAAgAAAIqQFEnZqggAGi1APoFAgAGCoCosQAACgAAAAAAAAAFABCgKEAQUBFEBUxUBQIAAACggAEAAIAIugAQACACKACaALMAAEqgCAKJqgGBQFQAVAAAEWgCAUFQUAAAAAAEVABUvfKggHe+QBUgAAC4gC0QBSooCKAgAAAAAKAAAAAAACeVSKCYYzn6awAzVNARQEUAMAAA0EFQAAAAAwwAqwAAAAAAASd8qnhQOPynfsATj6Tj8gDfH2fAAus761QF1nj/AKAHL3+jh6UBMN9/pQE5LyAEw4/KgM8veHP0ALhxADj7NUBdTn8AC8k4gCAA/9k=',
      btnName: t('Coming Soon'),
      disabled: true
    }
  ];

  const dispatch = useDispatch();

  const [sdgsList, setSdgsList] = useState([]);
  const [refList, setRefList] = useState([]);
  const [isKpiSetup, setIsKpiSetup] = useState(false);

  const [setupModalShow, setSetupModalShow] = useState(false);
  const [showSDGPromptModal, setShowSDGPromptModal] = useState(false);

  const userInfo = useSelector((state) => state.users.user);
  const { getSdgDataByOrgStatus } = useSelector((state) => state.disclosure);
  const { sdgData } = useSelector((state) => state.disclosure);

  const fetchSDGs = async () => {
    if (isKpiSetup) {
      const sdgResponse = await dispatch(getSdgDataByOrg(userInfo?.usrOrgId));
      const sdgResult = unwrapResult(sdgResponse);
      if (!sdgResult.error) {
        setSdgsList([]);
        setSdgsList(sdgResult);
        if (sdgResult.length > 0) {
          // setShowSDGPromptModal(true);
          setSetupModalShow(true);
        }
      }
    } else {
      const sdgResponse = await dispatch(getSdgDataByOrg(userInfo?.usrOrgId));
      const sdgResult = unwrapResult(sdgResponse);
      if (!sdgResult.error) {
        setSdgsList([]);
        setSdgsList(sdgResult);
        if (sdgResult.length > 0) {
          setShowSDGPromptModal(true);

        }
      }
    }
  };

  const formatSDGGoalSummaryGraphData = () => {
    const maxRatingValue = 7;
    const goalsOverallPerformance = sdgsList
      .map((sdg, index) => {
        return {
          sdgItem: sdg,
          index: index
        };
      })
      .filter((sdg) => sdg.sdgItem.isSelected === true)
      .map((sdg) => {
        const totalKpis = sdg.sdgItem.kpis.length;
        const applicableKpis = sdg.sdgItem.kpis.filter((kpi) => kpi.isApplicable === 'Yes');
        const ratedKpis = sdg.sdgItem.kpis.filter(
          (kpi) => kpi.isApplicable === 'Yes' && Object.keys(kpi.latestRecord).length !== 0
        );
        if (ratedKpis.length > 0) {
          const totalRating = ratedKpis.reduce(
            (accumulator, kpi) => accumulator + parseFloat(Number(kpi.latestRecord.rating) / maxRatingValue),
            0
          );
          const totalAverageScore = formatNumber((totalRating / applicableKpis.length) * 100);
          const overallPerformance = formatNumber(totalAverageScore * (applicableKpis.length / totalKpis));

          return {
            index: sdg.index,
            goal: overallPerformance,
            goalName: sdg.sdgItem.goalName,
          };
        } else {
          return {
            index: sdg.index,
            goal: 0.0,
            goalName: sdg.sdgItem.goalName,
          };
        }
      });
    return goalsOverallPerformance;
  };

  const checkIsKpiSetup = (sdgData) => {
    const selectedKpis = [];
    sdgData.forEach((sdgGoal) => {
      sdgGoal.kpis.forEach((kpi) => {
        if (kpi.isApplicable !== 'N/A') {
          selectedKpis.push(kpi);
        }
      });
    });

    return selectedKpis.length > 0 ? true : false;
  };

  useEffect(() => {
    if (getSdgDataByOrgStatus === LoadingState.idle) {
      dispatch(getSdgDataByOrg(userInfo?.usrOrgId));
    }

    if (getSdgDataByOrgStatus === LoadingState.succeeded) {
      setSdgsList(sdgData);
      setRefList(sdgData);
      setIsKpiSetup(checkIsKpiSetup(sdgData));
    }
  }, [getSdgDataByOrgStatus, dispatch, userInfo, sdgData]);
  const sDGGoalSummaryData = formatSDGGoalSummaryGraphData();
  return (
    <Fragment>
      <div className='inner-content disclosure-page container-fluid'>
        {/* Loading component */}
        <section>
          {getSdgDataByOrgStatus === LoadingState.loading && (
            <div className='loader-container'>
              <Spinner variant='primary' className='loader' animation='border' />
            </div>
          )}
        </section>
        <div className='page-title-wrapper'>
          <h1 className='page-title'>{t('Disclosure')}</h1>
          <p>
            {t('Why stop at carbon tracking and reduction when you can make your business more sustainable by having a positive impact not just on the environment but also on your community, society, and economy. Check our Disclosure section to achieve overall sustainability.')}
          </p>
        </div>
        {getSdgDataByOrgStatus === LoadingState.succeeded && sdgData.length > 0 && userInfo && (
          <Row>
            {/* Disclosure Component */}
            <Col md={6}>
              <DisclosureCardComponent
                onClickHandle={fetchSDGs}
                setSetupModalShow={setSetupModalShow}
                title={disclosureCardDetails[0].title}
                imgUrl={disclosureCardDetails[0].imgUrl}
                btnName={disclosureCardDetails[0].btnName}
                disabled={disclosureCardDetails[0].disabled}
                description={disclosureCardDetails[0].description}
                getSdgDataByOrgStatus={getSdgDataByOrgStatus}
                isKpiSetup={isKpiSetup}
              />
            </Col>
            <Col md={6}>
              <DisclosureCardComponent
                title={disclosureCardDetails[1].title}
                imgUrl={disclosureCardDetails[1].imgUrl}
                btnName={disclosureCardDetails[1].btnName}
                disabled={disclosureCardDetails[1].disabled}
                description={disclosureCardDetails[1].description}
              />
            </Col>
            {/* <Col md={6} className='mt-5'>
              <KpiSummaryGraph/>
            </Col> */}
            {sDGGoalSummaryData.every((item) => item.goal === 0) ? (
              <Col className='mt-5'>
                <div className='inner-content planned-actions w-100'>
                  <div className='d-lg-flex align-items-center justify-content-between'>
                    <div className='placeholder'>
                      <h4>{t('KPI Performance')}</h4>
                      <img src={placeholderAnalytics} alt='Planned Actions Placeholder' className='img-fluid' />
                      <h5>{t('KPI performance will be updated once sufficient data is available.')}</h5>
                    </div>
                  </div>
                </div>
              </Col>
            ) : (
              <Col className='mt-5'>
                <h4 className='sgd-summary-header'>
                  <span>{t('KPI Performance')}</span>
                  <DownloadSummary
                    orgId={userInfo?.usrOrgId}
                    sdgData={sdgData}
                    sDGGoalSummaryData={sDGGoalSummaryData}
                  />
                </h4>
                <SDGGoalSummaryGraph sDGGoalSummaryGraphData={sDGGoalSummaryData} />
              </Col>
            )}
          </Row>
        )}
        {sdgsList.length > 0 && refList.length > 0 && userInfo && getSdgDataByOrgStatus === LoadingState.succeeded && (
          <Fragment>
            {/* Disclosure Modal */}
            <DisclosureSetupModal
              refList={refList}
              userInfo={userInfo}
              sdgsList={sdgsList}
              setSdgsList={setSdgsList}
              setupModalShow={setupModalShow}
              setSetupModalShow={setSetupModalShow}
            />
            {/* Disclosure Message Modal */}
            <SDGPromptModal
              setShowSDGPromptModal={setShowSDGPromptModal}
              showSDGPromptModal={showSDGPromptModal}
              setSetupModalShow={setSetupModalShow}
            />
          </Fragment>
        )}
      </div>
    </Fragment>
  );
}
