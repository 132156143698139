import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LoadingState } from '../../utilities/constants';
import { toast } from 'react-toastify';
import {
  fetchCarbonFootprintByEntityApi,
  fetchDataGapApi,
  fetchEmissionsByAssetApi,
  fetchDataTemplateIDsReportApi,
  fetchDataValidationApi,
  fetchEmissionsByEntityGroupApi,
  fetchEmissionsByTop10ContributorsApi,
  fetchEmissionsByTop10EntitiesApi,
  fetchEmissionsByTop10EPSApi,
  fetchEntitiesApi,
  fetchMovingAverageApi,
  fetchTransactionReportApi,
  fetchScopesApi,
  fetchCarbonFootprintByScopeApi,
  fetchCarbonFootprintByFullEmissionProfileApi,
  fetchIntensityEmissionsByEntityApi,
  fetchIntensityBusinessMeasuresByEntityApi,
  fetchBusinessMeasuresApi,
  fetchEmissionsByEntityOrMetricApi
} from './reportsApi';

export const fetchCarbonFootprintByEntity = createAsyncThunk(
  'reports/fetchCarbonFootprintByEntity',
  async (payload) => {
    try {
      const response = await fetchCarbonFootprintByEntityApi(payload.orgId, payload.data);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchMovingAverage = createAsyncThunk('reports/fetchMovingAverage', async (payload) => {
  try {
    const response = await fetchMovingAverageApi(payload.orgId, payload.data);
    return response;
  } catch (error) {
    throw error;
  }
});

export const fetchEmissionsByTop10Entities = createAsyncThunk(
  'reports/fetchEmissionsByTop10Entities',
  async (payload) => {
    try {
      const response = await fetchEmissionsByTop10EntitiesApi(payload.orgId, payload.data);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchEmissionsByTop10EPS = createAsyncThunk('reports/fetchEmissionsByTop10EPS', async (payload) => {
  try {
    const response = await fetchEmissionsByTop10EPSApi(payload.orgId, payload.data);
    return response;
  } catch (error) {
    throw error;
  }
});

export const fetchEmissionsByTop10Contributors = createAsyncThunk(
  'reports/fetchEmissionsByTop10Contributors',
  async (payload) => {
    try {
      const response = await fetchEmissionsByTop10ContributorsApi(payload.orgId, payload.data);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchEntities = createAsyncThunk('reports/fetchEntities', async (payload) => {
  try {
    const response = await fetchEntitiesApi(payload.orgId, payload.selectedEntity);
    return response;
  } catch (error) {
    throw error;
  }
});

export const fetchEmissionsByAsset = createAsyncThunk('reports/fetchEmissionsByAsset', async (payload) => {
  try {
    const response = await fetchEmissionsByAssetApi(payload.orgId, payload.data);
    return response;
  } catch (error) {
    throw error;
  }
});

export const fetchTransactionReport = createAsyncThunk('reports/fetchTransactionReport', async (payload) => {
  try {
    const response = await fetchTransactionReportApi(payload.orgId, payload.data);
    return response;
  } catch (error) {
    throw error;
  }
});

export const fetchDataGap = createAsyncThunk('reports/fetchDataGap', async (payload) => {
  try {
    const response = await fetchDataGapApi(payload.orgId, payload.data);
    return response;
  } catch (error) {
    throw error;
  }
});

export const fetchDataTemplateIDsReport = createAsyncThunk('reports/fetchDataTemplateIDsReport', async (payload) => {
  try {
    const response = await fetchDataTemplateIDsReportApi(payload.orgId, payload.data);
    return response;
  } catch (error) {
    throw error;
  }
});

export const fetchDataValidation = createAsyncThunk('reports/fetchDataValidation', async (payload) => {
  try {
    const response = await fetchDataValidationApi(payload.orgId, payload.data);
    return response;
  } catch (error) {
    throw error;
  }
});

export const fetchEmissionsByEntityGroup = createAsyncThunk('reports/fetchEmissionsByEntityGroup', async (payload) => {
  try {
    const response = await fetchEmissionsByEntityGroupApi(payload.orgId, payload.data);
    return response;
  } catch (error) {
    throw error;
  }
});

export const fetchScopes = createAsyncThunk('reports/fetchScopes', async (orgId) => {
  try {
    const response = await fetchScopesApi(orgId);
    return response;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});
export const fetchCarbonFootprintByFullEmissionProfile = createAsyncThunk(
  'reports/fetchCarbonFootprintByFullEmissionProfile',
  async (payload) => {
    try {
      const response = await fetchCarbonFootprintByFullEmissionProfileApi(payload.orgId, payload.data);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchIntensityEmissionsByEntity = createAsyncThunk('reports/fetchIntensityEmissions', async (payload) => {
  try {
    const response = await fetchIntensityEmissionsByEntityApi(payload.orgId, payload.data);
    return response;
  } catch (error) {
    throw error;
  }
});

export const fetchCarbonFootprintByScope = createAsyncThunk('reports/fetchCarbonFootprintByScope', async (payload) => {
  try {
    const response = await fetchCarbonFootprintByScopeApi(payload.orgId, payload.data);
    return response;
  } catch (error) {
    throw error;
  }
});
export const fetchIntensityBusinessMeasuresByEntity = createAsyncThunk(
  'reports/fetchIntensityBusinessMeasuresByEntity',
  async (payload) => {
    try {
      const response = await fetchIntensityBusinessMeasuresByEntityApi(payload.orgId, payload.data);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchBusinessMeasures = createAsyncThunk('reports/fetchBusinessMeasures', async (payload) => {
  try {
    const { orgId } = payload;
    const response = await fetchBusinessMeasuresApi(orgId);
    return response;
  } catch (error) {
    throw error;
  }
});

export const fetchEmissionsByEntityOrMetric = createAsyncThunk(
  'reports/fetchEmissionsByEntityOrMetric',
  async (payload) => {
    try {
      const response = await fetchEmissionsByEntityOrMetricApi(payload.orgId, payload.data);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

const reportsSlice = createSlice({
  name: 'reports',
  initialState: {
    carbonFootPrintByEntity: {},
    movingAverage: {},
    scopesTree: [],
    intensityEmissionsByEntity: [],
    intensityEmissionsByEntityStatus: LoadingState.idle,
    intensityEmissionsOverBM: [],
    intensityEmissionsOverBMStatus: LoadingState.idle,
    businessMeasures: [],
    businessMeasuresStatus: LoadingState.idle,
    intensityBusinessMeasuresByEntity: [],
    intensityBusinessMeasuresByEntityStatus: LoadingState.idle,
    emissionsByTop10Entities: [],
    emissionsByTop10EPS: [],
    emissionsByTop10Contributors: [],
    entityList: null,
    emissionsByAsset: [],
    dataGap: [],
    dataValidation: [],
    emissionsByEntityGroup: [],
    carbonFootPrintByScope: [],
    carbonFootPrintByFullEmissionProfile: [],
    emissionsByEntityOrMetric: [],
    carbonFootPrintStatus: LoadingState.idle,
    movingAverageStatus: LoadingState.idle,
    carbonFootPrintByScopeStatus: LoadingState.idle,
    scopeTreeStatus: LoadingState.idle,
    emissionsByTop10EntitiesStatus: LoadingState.idle,
    emissionsByTop10EPSStatus: LoadingState.idle,
    emissionsByTop10ContributorsStatus: LoadingState.idle,
    entityListStatus: LoadingState.idle,
    emissionsByAssetStatus: LoadingState.idle,
    dataGapStatus: LoadingState.idle,
    dataValidationStatus: LoadingState.idle,
    emissionsByEntityGroupStatus: LoadingState.idle,
    carbonFootPrintFullEmissionStatus: LoadingState.idle,
    emissionsByEntityOrMetricStatus: LoadingState.idle,
    error: null
  },
  reducers: {},
  extraReducers: {
    [fetchCarbonFootprintByEntity.pending]: (state) => {
      state.carbonFootPrintStatus = LoadingState.loading;
    },
    [fetchCarbonFootprintByEntity.fulfilled]: (state, action) => {
      state.carbonFootPrintStatus = LoadingState.succeeded;
      state.carbonFootPrintByEntity = action.payload;
    },
    [fetchCarbonFootprintByEntity.rejected]: (state, action) => {
      state.carbonFootPrintStatus = LoadingState.failed;
      state.error = action.error.message;
    },
    [fetchMovingAverage.pending]: (state) => {
      state.movingAverageStatus = LoadingState.loading;
    },
    [fetchMovingAverage.fulfilled]: (state, action) => {
      state.movingAverageStatus = LoadingState.succeeded;
      state.movingAverage = action.payload;
    },
    [fetchMovingAverage.rejected]: (state, action) => {
      state.movingAverageStatus = LoadingState.failed;
      state.error = action.error.message;
    },
    [fetchEmissionsByTop10Entities.pending]: (state) => {
      state.emissionsByTop10EntitiesStatus = LoadingState.loading;
    },
    [fetchEmissionsByTop10Entities.fulfilled]: (state, action) => {
      state.emissionsByTop10EntitiesStatus = LoadingState.succeeded;
      state.emissionsByTop10Entities = action.payload;
    },
    [fetchEmissionsByTop10Entities.rejected]: (state, action) => {
      state.emissionsByTop10EntitiesStatus = LoadingState.failed;
      state.error = action.error.message;
    },
    [fetchEmissionsByTop10EPS.pending]: (state) => {
      state.emissionsByTop10EPSStatus = LoadingState.loading;
    },
    [fetchEmissionsByTop10EPS.fulfilled]: (state, action) => {
      state.emissionsByTop10EPSStatus = LoadingState.succeeded;
      state.emissionsByTop10EPS = action.payload;
    },
    [fetchEmissionsByTop10EPS.rejected]: (state, action) => {
      state.emissionsByTop10EPSStatus = LoadingState.failed;
      state.error = action.error.message;
    },
    [fetchEmissionsByTop10Contributors.pending]: (state) => {
      state.emissionsByTop10ContributorsStatus = LoadingState.loading;
    },
    [fetchEmissionsByTop10Contributors.fulfilled]: (state, action) => {
      state.emissionsByTop10ContributorsStatus = LoadingState.succeeded;
      state.emissionsByTop10Contributors = action.payload;
    },
    [fetchEmissionsByTop10Contributors.rejected]: (state, action) => {
      state.emissionsByTop10ContributorsStatus = LoadingState.failed;
      state.error = action.error.message;
    },
    [fetchEmissionsByAsset.pending]: (state) => {
      state.emissionsByAssetStatus = LoadingState.loading;
    },
    [fetchEmissionsByAsset.fulfilled]: (state, action) => {
      state.emissionsByAssetStatus = LoadingState.succeeded;
      state.emissionsByAsset = action.payload;
    },
    [fetchEmissionsByAsset.rejected]: (state, action) => {
      state.emissionsByAssetStatus = LoadingState.failed;
      state.error = action.error.message;
    },
    [fetchDataGap.pending]: (state) => {
      state.dataGapStatus = LoadingState.loading;
    },
    [fetchDataGap.fulfilled]: (state, action) => {
      state.dataGapStatus = LoadingState.succeeded;
      state.dataGap = action.payload;
    },
    [fetchDataGap.rejected]: (state, action) => {
      state.dataGapStatus = LoadingState.failed;
      state.error = action.error.message;
    },
    [fetchDataValidation.pending]: (state) => {
      state.dataValidationStatus = LoadingState.loading;
    },
    [fetchDataValidation.fulfilled]: (state, action) => {
      state.dataValidationStatus = LoadingState.succeeded;
      state.dataValidation = action.payload;
    },
    [fetchDataValidation.rejected]: (state, action) => {
      state.dataValidationStatus = LoadingState.failed;
      state.error = action.error.message;
    },
    [fetchEntities.pending]: (state) => {
      state.entityListStatus = LoadingState.loading;
    },
    [fetchEntities.fulfilled]: (state, action) => {
      state.entityListStatus = LoadingState.succeeded;
      state.entityList = action.payload;
    },
    [fetchEntities.rejected]: (state, action) => {
      state.entityListStatus = LoadingState.failed;
      state.error = action.error.message;
    },
    [fetchEmissionsByEntityGroup.pending]: (state) => {
      state.emissionsByEntityGroupStatus = LoadingState.loading;
    },
    [fetchEmissionsByEntityGroup.fulfilled]: (state, action) => {
      state.emissionsByEntityGroupStatus = LoadingState.succeeded;
      state.emissionsByEntityGroup = action.payload;
    },
    [fetchEmissionsByEntityGroup.rejected]: (state, action) => {
      state.emissionsByEntityGroupStatus = LoadingState.failed;
      state.error = action.error.message;
    },
    [fetchCarbonFootprintByScope.pending]: (state) => {
      state.carbonFootPrintByScopeStatus = LoadingState.loading;
    },
    [fetchCarbonFootprintByScope.fulfilled]: (state, action) => {
      state.carbonFootPrintByScopeStatus = LoadingState.succeeded;
      state.carbonFootPrintByScope = action.payload;
    },
    [fetchCarbonFootprintByScope.rejected]: (state, action) => {
      state.carbonFootPrintByScopeStatus = LoadingState.failed;
      state.error = action.error.message;
    },
    [fetchScopes.pending]: (state) => {
      state.scopeTreeStatus = LoadingState.loading;
    },
    [fetchScopes.fulfilled]: (state, action) => {
      state.scopeTreeStatus = LoadingState.succeeded;
      state.scopesTree = action.payload;
    },
    [fetchScopes.rejected]: (state, action) => {
      state.scopeTreeStatus = LoadingState.failed;
      state.error = action.error.message;
    },
    [fetchCarbonFootprintByFullEmissionProfile.pending]: (state) => {
      state.carbonFootPrintFullEmissionStatus = LoadingState.loading;
    },
    [fetchCarbonFootprintByFullEmissionProfile.fulfilled]: (state, action) => {
      state.carbonFootPrintFullEmissionStatus = LoadingState.succeeded;
      state.carbonFootPrintByFullEmissionProfile = action.payload;
    },
    [fetchCarbonFootprintByFullEmissionProfile.rejected]: (state, action) => {
      state.carbonFootPrintFullEmissionStatus = LoadingState.failed;
      state.error = action.error.message;
    },
    [fetchIntensityEmissionsByEntity.pending]: (state) => {
      state.intensityEmissionsByEntityStatus = LoadingState.loading;
    },
    [fetchIntensityEmissionsByEntity.fulfilled]: (state, action) => {
      state.intensityEmissionsByEntityStatus = LoadingState.succeeded;
      state.intensityEmissionsByEntity = action.payload;
    },
    [fetchIntensityEmissionsByEntity.rejected]: (state, action) => {
      state.intensityEmissionsByEntityStatus = LoadingState.failed;
      state.error = action.error.message;
    },
    [fetchBusinessMeasures.pending]: (state) => {
      state.businessMeasuresStatus = LoadingState.loading;
    },
    [fetchBusinessMeasures.fulfilled]: (state, action) => {
      state.businessMeasuresStatus = LoadingState.succeeded;
      state.businessMeasures = action.payload;
    },
    [fetchBusinessMeasures.rejected]: (state, action) => {
      state.businessMeasuresStatus = LoadingState.failed;
      state.error = action.error.message;
    },
    [fetchIntensityBusinessMeasuresByEntity.pending]: (state) => {
      state.intensityBusinessMeasuresByEntityStatus = LoadingState.loading;
    },
    [fetchIntensityBusinessMeasuresByEntity.fulfilled]: (state, action) => {
      state.intensityBusinessMeasuresByEntityStatus = LoadingState.succeeded;
      state.intensityBusinessMeasuresByEntity = action.payload;
    },
    [fetchIntensityBusinessMeasuresByEntity.rejected]: (state, action) => {
      state.intensityBusinessMeasuresByEntityStatus = LoadingState.failed;
      state.error = action.error.message;
    },
    [fetchEmissionsByEntityOrMetric.pending]: (state) => {
      state.emissionsByEntityOrMetricStatus = LoadingState.loading;
    },
    [fetchEmissionsByEntityOrMetric.fulfilled]: (state, action) => {
      state.emissionsByEntityOrMetricStatus = LoadingState.succeeded;
      state.emissionsByEntityOrMetric = action.payload;
    },
    [fetchEmissionsByEntityOrMetric.rejected]: (state, action) => {
      state.emissionsByEntityOrMetricStatus = LoadingState.failed;
      state.error = action.error.message;
    }
  }
});

export default reportsSlice.reducer;
