import { config } from '../../utilities/config';
import keycloak from '../../keycloak';

export async function activeSms2FaApi(currentPassword, mobileNumber) {
  const response = await fetch(`${config.API_BASE_URL}/user/sms-2fa`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    },
    body: JSON.stringify({ currentPassword, mobileNumber }),
  });
  const data = await response.json();
  if (!response.ok) throw new Error(data.message);
  return data;
}

export async function verifySms2FaApi(code) {
  const response = await fetch(`${config.API_BASE_URL}/user/sms-2fa`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    },
    body: JSON.stringify({ code })
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function removeSms2FaApi(currentPassword) {
  const response = await fetch(`${config.API_BASE_URL}/user/sms-2fa`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    },
    body: JSON.stringify({ currentPassword })
  });
  const data = await response.json();
  if (!response.ok) throw new Error(data.message);
  return data;
}

