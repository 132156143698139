import React, { useState, useEffect } from 'react';
import { Form, Table } from 'react-bootstrap';
import {
  useAsyncDebounce,
  useGlobalFilter,
  useRowState,
  useTable,
  useRowSelect,
  useSortBy,
  usePagination
} from 'react-table';
import Sorting from './Sorting';
import PaginationComponent from './Pagination';
import InputGroup from 'react-bootstrap/InputGroup';
import Col from 'react-bootstrap/Col';
import { IoSearchOutline } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';

const DataTable = ({
  columns,
  data,
  onEditSubmit,
  onDeleteRow,
  onRowSelect,
  autoResetPage = true,
  dataTableColor,
  allowMultiSelect = false,
  footer,
  actions = {},
  headerActions = null,
  defaultSort,
  isDescending = true,
  controlledPageIndex,
  setControlledPage,
  pageLimit = 20,
}) => {
  const tableInstance = useTable(
    {
      columns,
      data,
      initialRowStateAccessor: () => ({ isEditing: false }),
      initialCellStateAccessor: ({ value }) => ({ updatedValue: value }),
      initialState: {
        pageIndex: controlledPageIndex === undefined ? 0 : controlledPageIndex,
        pageSize: pageLimit,
        sortBy: [
          {
            id: defaultSort,
            desc: isDescending
          }
        ]
      },
      autoResetPage
      // useControlledState: (state) => {
      //   return React.useMemo(
      //     () => ({
      //       ...state,
      //       pageIndex: controlledPageIndex === undefined ? state.pageIndex : controlledPageIndex
      //     }),
      //     [state, controlledPageIndex]
      //   );
      // }
    },
    useRowState,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );
  const {
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    pageCount,
    gotoPage,
    state: { pageIndex, pageSize },
    visibleColumns,
    preGlobalFilteredRows,
    globalFilteredRows,
    setGlobalFilter,
    state,
    toggleAllRowsSelected
  } = tableInstance;

  useEffect(() => {
    if (setControlledPage) {
      setControlledPage(pageIndex);
    }
  }, [state, setControlledPage, pageIndex]);

  return (
    <div>
      <Table
        className={dataTableColor ? 'custom-table ' + dataTableColor : 'custom-table'}
        responsive
        {...getTableBodyProps()}
      >
        <thead>
          <tr className={dataTableColor ? 'search-row ' + dataTableColor : 'search-row'}>
            <th colSpan={visibleColumns.length}>
              <div className="d-flex align-items-center">
                <div style={{ flex: '1' }}>
                  <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                  />
                </div>
                {headerActions}
              </div>
            </th>
          </tr>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <Sorting column={column} />
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            const { isEditing } = row.state;
            const editRow = () => row.setState({ isEditing: true });
            const cancelEditRow = () => row.setState({ isEditing: false });
            const saveRow = () => {
              const { cells } = row;
              const updatedRow = cells
                .filter((c) => c.column.editable)
                .reduce(
                  (acc, current) => ({
                    ...acc,
                    [current.column.id]: current.state.updatedValue
                  }),
                  {}
                );
              onEditSubmit(updatedRow, row.original);
              cancelEditRow();
            };
            const deleteRow = () => onDeleteRow(row.original);

            const rowClick = () => {
              if (onRowSelect) {
                if (!allowMultiSelect) toggleAllRowsSelected(false);
                row.toggleRowSelected();
                onRowSelect(row.original);
              }
            };
            return (
              <tr
                {...row.getRowProps()}
                onClick={rowClick}
                className={row.isSelected ? 'tiles-table-component-active' : ''}
              >
                {row.cells.map((cell) => {
                  const onCellValueChange = (v) => cell.setState({ updatedValue: v });
                  const cellType =
                    (cell.column.editable || cell.column.actionColumn) && isEditing ? 'EditCell' : 'Cell';

                  return (
                    <td {...cell.getCellProps()} style={{ verticalAlign: 'middle' }}>
                      {cell.render(cellType, {
                        editRow,
                        cancelEditRow,
                        onCellValueChange,
                        saveRow,
                        deleteRow,
                        ...actions,
                        updatedValue: cell.state.updatedValue
                      })}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        {footer}
      </Table>
      <PaginationComponent
        pageIndex={pageIndex}
        pageSize={pageSize}
        pageCount={pageCount}
        length={globalFilteredRows.length}
        gotoPage={gotoPage}
        pageOptions={pageOptions}
      />
    </div>
  );
};

const GlobalFilter = ({ globalFilter, setGlobalFilter }) => {
  const [value, setValue] = useState(globalFilter);
  const { t } = useTranslation();
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div>
      <Form.Group as={Col} md="8">
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text>
              {' '}
              <IoSearchOutline />{' '}
            </InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            className="custom-form-control search-input"
            type="text"
            value={value || ''}
            onChange={(e) => {
              setValue(e.target.value);
              onChange(e.target.value);
            }}
            placeholder={t("Search")}
          />
        </InputGroup>
      </Form.Group>
    </div>
  );
};

export const isEnableUpdateBtn = (cellData, requireFields) => {
  if (!cellData?.row?.cells) {
    return false;
  }
  const values = cellData.row.cells
    .filter(item => requireFields.includes(item?.column?.id) && item?.state?.updatedValue);
  return values.length === requireFields.length;
};

export const getTargetUpdateValue = (cellData, cellId, callbackValue = (val) => val) => {
  if (!cellData?.row?.cells) {
    return null;
  }
  const { state } = cellData.row.cells.find(item => item?.column?.id === cellId) || {};
  return state?.updatedValue ? callbackValue(state?.updatedValue) : null;
};

export default DataTable;
