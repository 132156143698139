import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { IoAddCircleOutline } from 'react-icons/io5';
import ReactDatePicker from 'react-datepicker';
import { datePickerValue, parseUserInfo } from '../../utilities/common';
import Select from 'react-select';
import { useSelector } from 'react-redux';

const AddRowTargetEmissionForm = ({ onSubmit, bsnsMeasures, isLoading = false }) => {
  const [addData, setAddData] = useState({
    trgtemissnStartDate: null,
    trgtemissnEndDate: null,
    trgtemissnType: '',
    trgtemissnEmissnData: '',
    trgtemissnEmissnReduction: '',
    trgtemissnTargetScope: '',
    trgtemissnTargetScopeLabel: '',
    trgtemissnDesc: ''
  });
  const { usrDateFormat } = parseUserInfo(useSelector((state) => state.users.user));
  const targetEmissionTypeOptions = [
    { value: 'Planned', label: 'Planned' },
    { value: 'Forecast', label: 'Forecast' }
  ];

  const targetEmissionScopeOptions = [{ value: 'Emissions', label: 'Emissions' }];

  bsnsMeasures.map((value, index) => {
    const obj = { value: value.value, label: value.label };
    return targetEmissionScopeOptions.push(obj);
  });

  const handleChangeStartDate = (startDate) => {
    setAddData((prevState) => ({
      ...prevState,
      trgtemissnStartDate: startDate
    }));
  };

  const handleChangeEndDate = (endDate) => {
    setAddData((prevState) => ({
      ...prevState,
      trgtemissnEndDate: endDate
    }));
  };

  const handleChangeAddType = (event) => {
    setAddData((prevState) => ({
      ...prevState,
      trgtemissnType: event.value
    }));
  };

  const handleChangeAddTargetScope = (event) => {
    setAddData((prevState) => ({
      ...prevState,
      trgtemissnTargetScope: event.value,
      trgtemissnTargetScopeLabel: event.label
    }));
  };

  const handleChangeAddTargetEmissionData = (event) => {
    setAddData((prevState) => ({
      ...prevState,
      trgtemissnEmissnData: event.target.value
    }));
  };

  const handleChangeAddTargetEmissionReduction = (event) => {
    setAddData((prevState) => ({
      ...prevState,
      trgtemissnEmissnReduction: event.target.value
    }));
  };

  const handleChangeAddTargetEmissionDesc = (event) => {
    setAddData((prevState) => ({
      ...prevState,
      trgtemissnDesc: event.target.value
    }));
  };

  const handleSubmit = () => {
    onSubmit(addData);
  };

  const isEnabledSave = addData.trgtemissnStartDate && addData.trgtemissnEndDate && addData.trgtemissnType &&
    addData.trgtemissnTargetScope && (addData.trgtemissnEmissnReduction || addData.trgtemissnEmissnData);
  return (
    <tfoot>
      <tr>
        <td>
          <Select
            className="select"
            options={targetEmissionTypeOptions}
            menuPortalTarget={document.body}
            onChange={handleChangeAddType}
            value={{ label: addData.trgtemissnType, value: addData.trgtemissnType }}
            menuPlacement="top"
          />
        </td>
        <td style={{ verticalAlign: 'middle' }}>
          <ReactDatePicker
            isClearable
            maxDate={addData.trgtemissnEndDate || null}
            className="form-control"
            dateFormat={usrDateFormat}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            {...datePickerValue(addData.trgtemissnStartDate, handleChangeStartDate)}
          />
        </td>
        <td style={{ verticalAlign: 'middle' }}>
          <ReactDatePicker
            minDate={addData.trgtemissnStartDate || null}
            isClearable
            className="form-control"
            dateFormat={usrDateFormat}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            {...datePickerValue(addData.trgtemissnEndDate, handleChangeEndDate)}
          />
        </td>
        <td style={{ verticalAlign: 'middle' }}>
          <input onChange={handleChangeAddTargetEmissionData} value={addData.trgtemissnEmissnData} />
        </td>
        <td style={{ verticalAlign: 'middle' }}>
          <input onChange={handleChangeAddTargetEmissionReduction} value={addData.trgtemissnEmissnReduction} />
        </td>
        <td>
          <Select
            className="select"
            options={targetEmissionScopeOptions}
            menuPortalTarget={document.body}
            onChange={handleChangeAddTargetScope}
            value={{ label: addData.trgtemissnTargetScopeLabel, value: addData.trgtemissnTargetScope }}
            menuPlacement="top"
          />
        </td>
        <td style={{ verticalAlign: 'middle' }}>
          <input onChange={handleChangeAddTargetEmissionDesc} value={addData.trgtemissnDesc} />
        </td>
        <td>
          <div>
            <Button disabled={!isEnabledSave || isLoading} variant="dark" className="table-add-button" onClick={handleSubmit}>
              <IoAddCircleOutline />
            </Button>
          </div>
        </td>
      </tr>
    </tfoot>
  );
};

export default AddRowTargetEmissionForm;
