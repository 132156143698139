import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { IoCloseOutline, IoSaveOutline, IoTrashOutline } from 'react-icons/io5';
import { AiOutlineEdit } from 'react-icons/ai';
import { LoadingState } from '../../utilities/constants';
import DataTable from '../../components/DataTable';
import { deleteFactor, editFactor, fetchData } from './EnergyContentSlice';
import DeleteConfirmModal from '../../components/DeleteConfirmModal';

const EnergyContentTable = ({ dispatch, t, factors, fetchStatus }) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState();

  const closeConfirmModal = () => setShowConfirmModal(false);
  const openConfirmModal = (original) => {
    setSelectedRow(original);
    setShowConfirmModal(true);
  };

  const onEditCell = useCallback(
    () =>
      ({ cell, onCellValueChange }) => {
        const { updatedValue } = cell.state;
        return (
          <input
            type="number"
            value={updatedValue}
            onChange={(e) => {
              onCellValueChange(e.target.value);
            }}
          />
        );
      },
    []
  );
  const tableColumns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id'
      },
      {
        Header: 'Unit',
        accessor: 'unit',
        sortType: 'string'
      },
      {
        Header: 'Factor',
        accessor: 'factor',
        editable: true,
        EditCell: onEditCell()
      },
      {
        Header: '',
        accessor: 'actions',
        actionColumn: true,
        disableGlobalFilter: false,
        disableSortBy: true,
        EditCell: (props) => {
          const { cancelEditRow, saveRow } = props;
          return (
            <div className="d-flex action-buttons">
              <Button size="sm" className="mr-1" variant="outline-secondary" onClick={saveRow}>
                <IoSaveOutline />
              </Button>
              <Button size="sm" variant="outline-secondary" onClick={cancelEditRow}>
                <IoCloseOutline />
              </Button>
            </div>
          );
        },
        Cell: (props) => {
          const { editRow, deleteRow } = props;
          return (
            <div className="d-flex action-buttons">
              <Button size="sm" className="mr-1" variant="outline-secondary" onClick={editRow}>
                <AiOutlineEdit />
              </Button>
              <Button size="sm" variant="outline-secondary" onClick={deleteRow}>
                <IoTrashOutline />
              </Button>
            </div>
          );
        }
      }
    ],
    [onEditCell]
  );
  const handleEditData = async (edited, original) => {
    try {
      await dispatch(editFactor({ id: original.id, data: { ...original, ...edited } }));
    } catch (e) {
      console.log(e);
    }
  };
  const handleRemoveData = async () => {
    try {
      await dispatch(deleteFactor({ id: selectedRow.id }));
    } catch (e) {
      console.log(e);
    }
    closeConfirmModal();
    setSelectedRow(null);
  };
  useEffect(() => {
    dispatch(fetchData());
  }, [dispatch]);

  if (fetchStatus === LoadingState.loading) return <Spinner animation="grow" />;
  return (
    <div>
      <DataTable columns={tableColumns} data={factors} onEditSubmit={handleEditData} onDeleteRow={openConfirmModal} />
      <DeleteConfirmModal
        isShowModal={showConfirmModal}
        messageRender={(t) => t('Are you sure you want to delete this target ?')}
        closeConfirmModal={closeConfirmModal}
        handleDeleteAction={handleRemoveData} />
    </div>
  );
};

export default EnergyContentTable;
