import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { useParams } from 'react-router';
import DataTable from '../../components/DataTable';
import { getAssigedEmissionSources } from './assetsSlice';
import { useTranslation } from 'react-i18next';

const ViewAssignedEmissionSources = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { assetId } = useParams();
  const assignedEmissionSources = useSelector((state) => state.assets.assignedEmissionSources);
  const orgId = useSelector((state) => state.users.user.usrOrgId);

  useEffect(() => {
    dispatch(getAssigedEmissionSources({ orgId, assetId }));
  }, [orgId, assetId, dispatch]);

  const assignedTableColumns = useMemo(
    () => [
      {
        Header: t('Emission Name'),
        accessor: 'eprflsrcName'
      },
      {
        Header: t('Emission Description'),
        accessor: 'eprflsrcDesc'
      }
    ],
    [t]
  );
  if (assignedEmissionSources === null) return <Spinner animation="grow" />;
  return (
    <div>
      <h5 className="my-3 text-light">{t('Emission Source Assignments')}</h5>
      <DataTable columns={assignedTableColumns} data={assignedEmissionSources} />
    </div>
  );
};

export default ViewAssignedEmissionSources;
