import { Line } from 'react-chartjs-2';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { Legend, LegendContainer } from '../style-components/styles';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../../utilities/common';

export function KPIDataGraph({ kpiData }) {
  const chartRef = useRef();
  const { t } = useTranslation();
  const [data, setData] = useState([]);

  const createDataset = useCallback((kpiData) => {
    const labels = [];
    const data = [];
    for (let i = 0; i < kpiData.length; i++) {
      data.push(kpiData[i]['rating']);
      const date = kpiData[i]['updatedDate']
        ? formatDate(kpiData[i]['updatedDate'])
        : formatDate(kpiData[i]['createdDate']);
      labels.push(date);
    }
    return {
      labels: labels,
      datasets: [
        {
          data: data,
          borderColor: '#80b6f4',
          pointBorderColor: '#80b6f4',
          pointBackgroundColor: '#80b6f4',
          pointHoverBackgroundColor: '#80b6f4',
          pointHoverBorderColor: '#80b6f4',
          pointBorderWidth: 10,
          pointHoverRadius: 10,
          pointHoverBorderWidth: 1,
          pointRadius: 3,
          fill: false,
          borderWidth: 4,
          lineTension: 0,
          backgroundColor: 'rgba(244, 144, 128, 0.8)'
        }
      ]
    };
  }, []);

  useEffect(() => {
    if (kpiData) {
      setData(createDataset(kpiData));
    }
  }, [kpiData, createDataset]);

  const options = {
    responsive: true,
    legend: {
      display: false,

      position: 'bottom'
    },
    layout: {
      padding: {
        top: 5,
        left: 5
      }
    },
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: t('Ratings')
          },
          ticks: {
            fontColor: 'rgba(0,0,0,0.5)',
            fontStyle: 'bold',
            beginAtZero: true,
            maxTicksLimit: 5,
            padding: 20
          },
          gridLines: {
            // drawTicks: false,
            // display: false
          }
        }
      ],
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: t('Date')
          },
          gridLines: {
            // zeroLineColor: 'transparent'
          },
          ticks: {
            padding: 20,
            fontColor: 'rgba(0,0,0,0.5)',
            fontStyle: 'bold'
          }
        }
      ]
    }
  };

  return (
    <Fragment>
      <Fragment>
        {chartRef && data && <Line ref={chartRef} options={options} data={data} />}

        <LegendContainer>
          <Legend id='kpi-data-legend' />
        </LegendContainer>
      </Fragment>
    </Fragment>
  );
}
