import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { LoadingState } from '../../utilities/constants';
import {
  createEntityGroupApi,
  editEntityGroupApi,
  fetchEntityGroupsApi,
  removeEntityGroupApi,
  fetchAssignedEntitiesForEGApi,
  fetchAvailableEntitiesToEGApi,
  assignMultiEntitiesApi,
} from './entityGroupsApi';

export const fetchEntityGroups = createAsyncThunk('entityGroups/fetchEntityGroups', async (payload) => {
  try {
    const response = await fetchEntityGroupsApi(payload.orgId);
    return response;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const createEntityGroup = createAsyncThunk('entityGroups/createEntityGroup', async (payload) => {
  try {
    const entityGroup = await createEntityGroupApi(payload.orgId, payload.data);
    toast.success('Successfully saved.');
    return entityGroup;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const editEntityGroup = createAsyncThunk('entityGroups/editEntityGroup', async (payload, { dispatch }) => {
  try {
    const { orgId, egId, data } = payload;
    const entityGroup = await editEntityGroupApi(orgId, egId, data);
    toast.success('Successfully updated.');
    dispatch(fetchEntityGroups({ orgId }));
    return entityGroup;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const removeEntityGroup = createAsyncThunk('entityGroups/removeEntityGroup', async (payload, { dispatch }) => {
  try {
    const { orgId, egId } = payload;
    const entityGroup = await removeEntityGroupApi(orgId, egId);
    dispatch(fetchEntityGroups({ orgId }));
    return entityGroup;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const fetchAssignedEntitiesForEG = createAsyncThunk(
  'entityGroups/fetchAssignedEntitiesForEG',
  async (payload) => {
    try {
      const response = await fetchAssignedEntitiesForEGApi(payload.orgId, payload.egId);
      return response;
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  }
);

export const fetchAvailableEntitiesToEG = createAsyncThunk(
  'entityGroups/fetchAvailableEntitiesToEG',
  async (payload) => {
    try {
      const response = await fetchAvailableEntitiesToEGApi(payload.orgId, payload.egId);
      return response;
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  }
);

export const assignMultiEntities = createAsyncThunk('entityGroups/assign-entities', async (payload, { dispatch }) => {
  const { orgId, egId, type, divIds } = payload;
  try {
    await assignMultiEntitiesApi(orgId, egId, { type, divIds });
    await dispatch(fetchAssignedEntitiesForEG({ orgId, egId }));
    await dispatch(fetchAvailableEntitiesToEG({ orgId, egId }));
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

const entityGroupsSlice = createSlice({
  name: 'entityGroups',
  initialState: {
    entityGroups: [],
    assignedEntitiesToEntityGroup: [],
    availableEntitiesToEntityGroup: [],
    status: LoadingState.idle,
    assignmentStatus: LoadingState.idle,
    error: null
  },
  reducers: {},
  extraReducers: {
    [fetchEntityGroups.pending]: (state) => {
      state.status = LoadingState.loading;
    },
    [fetchEntityGroups.fulfilled]: (state, action) => {
      state.status = LoadingState.succeeded;
      state.entityGroups = action.payload;
    },
    [fetchEntityGroups.rejected]: (state, action) => {
      state.status = LoadingState.failed;
      state.error = action.error.message;
    },
    [fetchAssignedEntitiesForEG.pending]: (state) => {
      state.assignmentStatus = LoadingState.loading;
    },
    [fetchAssignedEntitiesForEG.fulfilled]: (state, action) => {
      state.assignmentStatus = LoadingState.succeeded;
      state.assignedEntitiesToEntityGroup = action.payload;
    },
    [fetchAssignedEntitiesForEG.rejected]: (state, action) => {
      state.assignmentStatus = LoadingState.failed;
      state.error = action.error.message;
    },
    [fetchAvailableEntitiesToEG.fulfilled]: (state, action) => {
      state.availableEntitiesToEntityGroup = action.payload;
    },
    [editEntityGroup.fulfilled]: (state, action) => {
      const existingIndex = state.entityGroups.findIndex((i) => i.egId === action.payload.egId);
      if (existingIndex >= 0) state.entityGroups[existingIndex] = action.payload;
    }
  }
});

export const selectEntityGroupById = (state, egId) => {
  if (!state.entityGroups.entityGroups?.length === 0) return null;
  const parsedId = parseInt(egId);
  const found = state.entityGroups.entityGroups.find((a) => a.egId === parsedId);
  return found ?? null;
};

export default entityGroupsSlice.reducer;
