import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import PageHeader from '../../components/PageHeader';
import { LoadingState } from '../../utilities/constants';
import UserForm from './UserForm';
import { useTranslation } from 'react-i18next';
import { createUserApi } from './usersApi';
import { toast } from 'react-toastify';

const NewUser = () => {
  const [saveUserState, setSaveUserState] = useState(LoadingState.idle);
  const history = useHistory();
  const { t } = useTranslation();

  const isBusy = saveUserState === LoadingState.loading;
  const userInfo = useSelector((state) => state.users.user);
  const orgId = userInfo.usrOrgId;

  const handleCreateUser = async (data) => {
    try {
      setSaveUserState(LoadingState.loading);
      await createUserApi(orgId, data);
      toast.success(t('New user created'));
      history.push('/admin/users/manage');
    } catch (e) {
      setSaveUserState(LoadingState.idle);
      toast.error(e.message);
      throw e;
    }
  };

  const handleCancelUser = () => {
    history.push('/admin/users/manage');
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <PageHeader title={t('Create User')} />
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <UserForm
            onSubmit={handleCreateUser}
            localeOptions={userInfo.localeOptions || []}
            initialValues={{}}
            buttonText={t('Create')}
            disabled={isBusy}
            onCancel={handleCancelUser}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default NewUser;
