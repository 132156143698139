import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import PageHeader from '../../components/PageHeader';
import { LoadingState } from '../../utilities/constants';
import { useTranslation } from 'react-i18next';
import { createFavouriteScheduleApi } from './scheduleApi';
import { toast } from 'react-toastify';
import FavouriteScheduleForm from './FavouriteScheduleForm';
import { useLocation } from 'react-router-dom';

const NewFavouriteSchedule = () => {
  const location = useLocation();
  const favouriteId = (new URLSearchParams(location.search)).get('favouriteId');
  const initialValues = { cvFrId: favouriteId ? Number(favouriteId) : null };
  const [saveScheduleState, setSaveScheduleState] = useState(LoadingState.idle);
  const history = useHistory();
  const { t } = useTranslation();

  const isBusy = saveScheduleState === LoadingState.loading;
  const orgId = useSelector((state) => state.users.user.usrOrgId);

  const handleCreateSchedule = async (data) => {
    try {
      setSaveScheduleState(LoadingState.loading);
      await createFavouriteScheduleApi(orgId, data);
      toast.success(t('New schedule created'));
      history.push('/admin/reports/scheduled');
    } catch (e) {
      setSaveScheduleState(LoadingState.idle);
      toast.error(e.message);
      throw e;
    }
  };

  const handleCancelSchedule = () => {
    history.push('/admin/reports/scheduled');
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <PageHeader title={t('Create new a schedule')} />
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <FavouriteScheduleForm
            onSubmit={handleCreateSchedule}
            initialValues={initialValues}
            buttonText={t('Create')}
            disabled={isBusy}
            onCancel={handleCancelSchedule}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default NewFavouriteSchedule;
