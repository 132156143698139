import React, { useMemo } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAssignedUsers, fetchAvailableUsers, assignUser, unAssignUser } from './accountsSlice';
import DataTable from '../../components/DataTable';
import { IoTrashOutline, IoAddOutline } from 'react-icons/io5';

const AssignUsers = ({ accountId }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const assignedUsers = useSelector((state) => state.accounts.assignedUsers);
  const availableUsers = useSelector((state) => state.accounts.availableUsers);
  const orgId = useSelector((state) => state.users.user.usrOrgId);

  const handleAssignUser = async (userId) => {
    try {
      setIsLoading(true);
      const resultAction = await dispatch(assignUser({ orgId, accountId, userId }));
      unwrapResult(resultAction);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleUnAssignUser = async (userId) => {
    try {
      setIsLoading(true);
      const resultAction = await dispatch(unAssignUser({ orgId, accountId, userId }));
      unwrapResult(resultAction);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const assignedUsersTableColumns = useMemo(
    () => [
      {
        Header: 'User Name',
        accessor: 'usrName'
      },
      {
        Header: 'First Name',
        accessor: 'usrFirstname'
      },
      {
        Header: 'Last Name',
        accessor: 'usrLastname'
      },
      {
        Header: '',
        accessor: 'actions',
        actionColumn: true,
        disableGlobalFilter: false,
        disableSortBy: true,
        Cell: (props) => {
          const { handleUnAssignUser, row } = props;
          return (
            <div className="d-flex action-buttons">
              <Button size="sm" variant="outline-secondary" onClick={() => handleUnAssignUser(row.original.usrId)}>
                <IoTrashOutline />
              </Button>
            </div>
          );
        }
      }
    ],
    []
  );

  const availableUsersTableColumns = useMemo(
    () => [
      {
        Header: 'User Name',
        accessor: 'usrName'
      },
      {
        Header: 'First Name',
        accessor: 'usrFirstname'
      },
      {
        Header: 'Last Name',
        accessor: 'usrLastname'
      },
      {
        Header: '',
        accessor: 'actions',
        actionColumn: true,
        disableGlobalFilter: false,
        disableSortBy: true,
        Cell: (props) => {
          const { handleAssignUser, row } = props;
          return (
            <div className="d-flex action-buttons">
              <Button size="sm" variant="outline-secondary" onClick={() => handleAssignUser(row.original.usrId)}>
                <IoAddOutline />
              </Button>
            </div>
          );
        }
      }
    ],
    []
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resultAction = await dispatch(fetchAssignedUsers({ orgId, accountId }));
        const resultAction2 = await dispatch(fetchAvailableUsers({ orgId, accountId }));
        unwrapResult(resultAction);
        unwrapResult(resultAction2);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [orgId, accountId, dispatch]);

  if (isLoading) return <Spinner animation="grow" />;

  return (
    <div>
      <h5 className="my-3 text-light">Assigned Users</h5>
      <DataTable
        columns={assignedUsersTableColumns}
        data={assignedUsers}
        actions={{
          handleUnAssignUser
        }}
      />
      <h5 className="mt-5 mb-3 text-light">Available Users</h5>
      <DataTable
        columns={availableUsersTableColumns}
        data={availableUsers}
        actions={{
          handleAssignUser
        }}
      />
    </div>
  );
};

export default AssignUsers;
