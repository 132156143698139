import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { AiOutlineQuestionCircle } from 'react-icons/ai';

const CircleTooltip = ({ title }) => {
  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={(props) => (
        <Tooltip {...props}>
          <span style={{ fontSize: '12px' }}>{title}</span>
        </Tooltip>
      )}
    >
      <AiOutlineQuestionCircle
        className='ml-2'
      />
    </OverlayTrigger>
  )
};

export default CircleTooltip;
