import React from 'react';
import { FiDownload } from 'react-icons/fi';
import { Button } from 'react-bootstrap';

const DownloadHelper = ({ getLinkObject, label = 'Download' }) => {
  const handleDownload = async () => {
    const result = await getLinkObject();
    const anchor = document.createElement('a');
    document.body.appendChild(anchor);
    anchor.href = result.objectUrl;
    anchor.setAttribute('download', result.fileName);
    anchor.click();
    anchor.target = '_blank';
    anchor.parentNode.removeChild(anchor);
  };
  return (
    <Button variant="link" onClick={handleDownload}>
      {label} <FiDownload className="ml-1" />
    </Button>
  );
};

export default DownloadHelper;
