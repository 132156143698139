import React, { useState } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import SecondarySidebar from '../../components/Sidebar/SecondarySidebar';
import RouteNotFound from '../../RouteNotFound';
import { ManageTargets } from './targets/ManageTargets';
import { ManageActions } from './actions/ManageActions';
import ViewAction from './actions/ViewAction';
import NewAction from './actions/NewAction';
import EditAction from './actions/EditAction';
import ReductionForecast from './forecast/ReductionForecast';

const ReductionPlanHome = () => {
  const [expandedClass, setExpandedClass] = useState('');
  const match = useRouteMatch();
  const { t } = useTranslation();

  const routes = [
    {
      path: `${match.url}/forecast`,
      name: t('Forecast'),
      isLink: true
    },
    {
      path: `${match.url}/goals`,
      name: t('Goals'),
      isLink: true
    },
    {
      path: `${match.url}/actions`,
      name: t('Actions'),
      isLink: true
    }
  ];

  return (
    <div className="d-flex flex-row mw-100">
      <SecondarySidebar
        items={routes}
        onCollapse={(isExpanded) => setExpandedClass(isExpanded ? 'offset-expanded' : '')}
      />
      <div className={`offset-second ${expandedClass}`}>
        <Switch>
          <Route exact path={`${match.path}/forecast`}>
            <ReductionForecast />
          </Route>
          <Route exact path={`${match.path}/goals`}>
            <ManageTargets />
          </Route>
          <Route exact path={match.path}>
            <Redirect to={`${match.path}/forecast`} />
          </Route>
          <Route exact path={`${match.path}/actions`}>
            <ManageActions />
          </Route>
          <Route exact path={`${match.path}/actions/:actId/view`}>
            <ViewAction />
          </Route>
          <Route exact path={`${match.path}/actions/new`}>
            <NewAction />
          </Route>
          <Route exact path={`${match.path}/actions/:actId/edit`}>
            <EditAction />
          </Route>
          <Route path="*">
            <RouteNotFound />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default ReductionPlanHome;
