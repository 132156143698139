import { unwrapResult } from '@reduxjs/toolkit';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row, Spinner } from 'react-bootstrap';
import Select from 'react-select';
import PageHeader from '../../components/PageHeader';
import { HiUpload } from 'react-icons/hi';
import DataTable from '../../components/DataTable';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchBulkUploads,
  getBulkInsertDownloadLink,
} from './bulkInsertSlice';
import DownloadHelper from '../../components/DownloadHelper';
import { LoadingState } from '../../utilities/constants';
import Empty from '../../components/Empty';
import Asset_template from './data/Asset_tempalte.csv';
import Entity_template from './data/Entity_tempalte.csv';
import EPS_template from './data/EPS_template.csv';
import * as yup from 'yup';
import _ from 'lodash';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { createBulkInsertApi, uploadBulkInsertApi } from './bulkInsertApi';
import { toast } from 'react-toastify';
import LoadingButton from '../../components/LoadingButton';
import { useTranslation } from 'react-i18next';
import { parseUserInfo, formatDate } from '../../utilities/common';

const schema = yup.object().shape({
  selectedFile: yup.mixed().required().required('File is required')
});

const BulkInsert = (factory, deps) => {
  const {
    control,
    setValue,
    getValues,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange'
  });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { orgId } = parseUserInfo(useSelector((state) => state.users.user));
  const uploadedData = useSelector((state) => state.bulkInsert.bulkUploads);
  const bulkUploadsStatus = useSelector((state) => state.bulkInsert.bulkUploadsStatus);
  const [uploadType, setUploadType] = useState('Asset');
  const [uploadDescription, setUploadDescription] = useState('');
  const [isUploading, setUploading] = useState(false);
  useWatch({ name: 'selectedFile', control });

  const orderedUploadedData = _.orderBy(uploadedData, "upldflCreatedDate");

  useEffect(() => {
    dispatch(fetchBulkUploads({ orgId }));
  }, [dispatch, orgId]);

  const [showModal, setShowModal] = useState(false);

  const handleBulkInsertFileDownload = useCallback(
    async (upldflId, errorFile) => {
      try {
        const resultAction = await dispatch(getBulkInsertDownloadLink({ upldflId, errorFile }));
        const response = unwrapResult(resultAction);
        return response;
      } catch (error) { }
    },
    [dispatch]
  );

  const columns = useMemo(
    () => [
      {
        Header: t('Name'),
        accessor: 'upldflName',
        sortType: 'string',
        Cell: ({ row, value }) => {
          if (row.original.upldflStatus === 'BAD_FILE') {
            return value;
          } else {
            return (
              <DownloadHelper
                getLinkObject={async () => await handleBulkInsertFileDownload(row.original.upldflId)}
                label={value}
              />
            );
          }
        }
      },
      {
        Header: t('Type'),
        accessor: 'upldflSourceType',
        sortType: 'string',
        Cell: ({ value }) => {
          return value ? value.replace("BULK_", "").replace("_", " ") : "";
        }
      },
      {
        Header: t('File Description'),
        accessor: 'upldflDesc',
        sortType: 'string'
      },
      {
        Header: t('Created By'),
        accessor: 'upldflCreatedBy',
        sortType: 'string'
      },
      {
        Header: t('Created Date'),
        accessor: 'upldflCreatedDate',
        sortType: 'string',
        sortbyOrder: 'asc',
        Cell: ({ value }) => {
          return formatDate(new Date(value));
        }
      },
      {
        Header: t('Status'),
        accessor: 'upldflStatus',
        sortType: 'string'
      },
      {
        Header: t('Errors'),
        accessor: 'upldflErrorCount',
        Cell: ({ row, value }) => {
          return value > 0 ? (
            <DownloadHelper
              getLinkObject={async () => await handleBulkInsertFileDownload(row.original.upldflId, true)}
              label={value}
            />
          ) : (
            <span style={{ padding: '0.375rem 0.75rem' }}>{value}</span>
          );
        }
      }
    ],
    [handleBulkInsertFileDownload, t]
  );
  const uploadTypeOpts = ['Asset', 'Entity', 'Emission Profile'].map((x) => ({ label: x, value: "BULK_" + x.toUpperCase().replace(" ", "_") }));
  const handleUploadTypeSelect = (e) => {
    setUploadType(e.value);
  };
  const handleDescriptionChange = (e) => {
    setUploadDescription(e.target.value);
  };
  const handleCloseModal = () => setShowModal(false);
  const closeModal = () => setShowModal(false);
  const isUploadBtnDisabled = !getValues('selectedFile') || !uploadDescription || !!isUploading;
  const handleFileUpload = async () => {
    setUploading(true);
    try {
      const newFile = getValues('selectedFile');
      const { key, name } = await uploadBulkInsertApi(orgId, uploadType, newFile)
      const data = {
        key,
        name,
        uploadType,
        desc: uploadDescription,
      };
      await createBulkInsertApi(orgId, data);
      dispatch(fetchBulkUploads({ orgId }));
      setShowModal(false);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setUploading(false);
    }
  };
  useEffect(() => {
    setUploading(false);
    setUploadType('BULK_ASSET');
    setValue('selectedFile', null);
    setUploadDescription(null);
  }, [showModal, setValue]);
  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <PageHeader title={t("Bulk Insert Data")} enableBackButton={true} />
          </Col>
        </Row>
        <Row>
          <Col>
            {(bulkUploadsStatus === LoadingState.loading) && (
              <Spinner animation="grow" />
            )}
            {bulkUploadsStatus === LoadingState.succeeded && (
              <DataTable
                autoResetPage={false}
                headerActions={
                  <Button onClick={() => setShowModal(true)} variant="secondary">
                    <HiUpload />
                    {t('Upload File')}
                  </Button>
                }
                columns={columns}
                data={orderedUploadedData}
              />
            )}
            {(bulkUploadsStatus === LoadingState.failed) && (
              <Empty title={t("Sorry, Something went wrong")} />
            )}
          </Col>
          <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header>
              <Modal.Title>{t('Upload File')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="modalForm.ControlInput1">
                  <Controller
                    name="selectedFile"
                    control={control}
                    render={({ field }) => {
                      const handleSelectFile = (e) => field.onChange(e.target.files[0]);
                      return (
                        <Form.File
                          id="emissionFileUploadControl"
                          label={t("Select File")}
                          accept=".csv"
                          className="form-content"
                          onChange={handleSelectFile}
                          isInvalid={errors.selectedFile}
                        />
                      );
                    }}
                  />
                  {errors.selectedFile && <span className="required-span">{errors.selectedFile.message}</span>}
                </Form.Group>
                <Form.Group controlId="modalForm.ControlInput2">
                  <Select
                    contentEditable={false}
                    options={uploadTypeOpts}
                    defaultValue={uploadTypeOpts[0]}
                    value={uploadTypeOpts.find((a) => uploadType === a.value)}
                    onChange={handleUploadTypeSelect}
                  />
                </Form.Group>
                <Form.Group controlId="modalForm.ControlInput3">
                  <Form.Label>{t('Description')}</Form.Label>
                  <Form.Control value={uploadDescription} as="textarea" rows={3} onChange={handleDescriptionChange} />
                </Form.Group>
              </Form>
              {uploadType === 'BULK_ASSET' ? (
                <a href={Asset_template} download="Asset_template.csv">
                  <strong>
                    <u>{t('Download Sample File')}</u>
                  </strong>
                </a>
              ) : (uploadType === 'BULK_ENTITY' ? (
                <a href={Entity_template} download="Entity_template.csv">
                  <strong>
                    <u>{t('Download Sample File')}</u>
                  </strong>
                </a>
              ) : (
                <a href={EPS_template} download="EPS_template.csv">
                  <strong>
                    <u>{t('Download Sample File')}</u>
                  </strong>
                </a>
              ))}
            </Modal.Body>
            <Modal.Footer>
              <LoadingButton isLoading={isUploading} />
              <Button variant="secondary" onClick={closeModal}>
                {t('Cancel')}
              </Button>
              <Button onClick={handleFileUpload} variant="primary" disabled={isUploadBtnDisabled}>
                {t('Upload')}
              </Button>
            </Modal.Footer>
          </Modal>
        </Row>
      </Container>
    </>
  );
};
export default BulkInsert;
