import { toast } from 'react-toastify';
import { Fragment, useEffect } from 'react';
import { KPIDataGraph } from './KPIDataGraph';
// import { KpiDataCard } from './KPIDataCard';
import { unwrapResult } from '@reduxjs/toolkit';
import { IoChevronBack } from 'react-icons/io5';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingState } from '../../../utilities/constants';
import { KPICompactDetailCard } from './KPICompactDetailCard';
import { Link, useHistory, useParams } from 'react-router-dom';
import placeholderAnalytics from '../../../assets/images/analytics.svg';
import { TableContainer } from '../../../components/CustomTable/TableContainer';
import { Button, Col, Nav, NavDropdown, Row, Spinner } from 'react-bootstrap';
import { deleteKpiData, getKpiDataBySetup, getSdgDataByOrg } from '../disclosureSlice';
import { useTranslation } from 'react-i18next';
import { parseUserInfo, formatDate } from '../../../utilities/common';

export function KpiDetails() {
  const { t } = useTranslation();
  const { kpiSetupId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { usrOrgId, usrKeycloakId } = parseUserInfo(useSelector((state) => state.users.user));

  const { kpiData, getKpiDataBySetupStatus, deleteKpiDataStatus } = useSelector((state) => state.disclosure);

  const handleGoBack = () => {
    history.push('/my-disclosure/sdgs');
  };

  const lastUpdated = (createdDate, updatedDate) =>
    updatedDate ? formatDate(updatedDate) : formatDate(createdDate);

  useEffect(() => {
    if (kpiSetupId && usrOrgId) {
      dispatch(
        getKpiDataBySetup({
          orgId: usrOrgId,
          kpiSetupId: kpiSetupId
        })
      );
    }
  }, [kpiSetupId, dispatch, usrOrgId]);

  const deleteRecord = async (id) => {
    const deleteResponse = await dispatch(
      deleteKpiData({
        orgId: usrOrgId,
        kpiId: id,
        keycloackId: usrKeycloakId
      })
    );
    const deleteResult = unwrapResult(deleteResponse);

    if (!deleteResult.error) {
      await dispatch(
        getKpiDataBySetup({
          orgId: usrOrgId,
          kpiSetupId: kpiSetupId
        })
      );
      await dispatch(getSdgDataByOrg(usrOrgId));
      toast.success(t('KPI record deleted successfully'));
    }
  };

  const columns = [
    {
      Header: t('Date'),
      accessor: 'createdDate',
      disableFilters: true,
      Cell: (data) => {
        return lastUpdated(data.row.original.createdDate, data.row.original.updatedDate);
      }
    },
    {
      Header: t('Rating'),
      accessor: 'rating',
      disableFilters: true
    },
    {
      Header: t('Comment'),
      accessor: 'comment',
      disableFilters: true
    },
    {
      Header: t('Actions'),
      actionColumn: true,
      Cell: (data) => {
        return (
          <>
            {data.row.original.sdgSetupApplicableStatus === 'Yes' && (
              <Nav>
                <NavDropdown title={<BsThreeDotsVertical />}>
                  {/* <NavDropdown.Item onClick={() => fetchInitialData(data.row.original)}>Edit</NavDropdown.Item> */}
                  <NavDropdown.Item
                    disabled={
                      deleteKpiDataStatus === LoadingState.loading ||
                      data.row.original.sdgSetupApplicableStatus === 'No'
                    }
                    onClick={() => deleteRecord(data.row.original.id)}
                  >
                    {t('Delete')}
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            )}
          </>
        );
      }
    }
  ];

  return (
    <Fragment>
      <section>
        {getKpiDataBySetupStatus === LoadingState.loading && (
          <div className='loader-container'>
            <Spinner variant='primary' className='loader' animation='border' />
          </div>
        )}
      </section>
      <div className='inner-content disclosure-page container-fluid'>
        <div className='ss-back'>
          <Button className='back-button text-success p-0 mb-3 mt-3' variant='link' onClick={handleGoBack}>
            <IoChevronBack /> {t('Back to SDGs')}
          </Button>
        </div>
        {getKpiDataBySetupStatus === LoadingState.succeeded && kpiData && Object.keys(kpiData).length > 0 && (
          <Fragment>
            <KPICompactDetailCard
              color={kpiData?.sdgGoal.color}
              kpiName={kpiData?.sdgKpi.kpiName}
              orderNo={kpiData?.sdgGoal.orderNo}
              goalName={kpiData?.sdgGoal.goalName}
              imagePath={kpiData?.sdgGoal.imagePath}
              kpiDescription={kpiData?.sdgKpi?.description}
              esgIndicators={kpiData?.sdgGoal.esgIndicators}
            />
            <hr />
            {kpiData?.kpiData && kpiData?.kpiData.length > 0 ? (
              <Row>
                <Col md={{ span: 7, offset: 2 }} className='mt-5'>
                  <KPIDataGraph kpiData={kpiData?.kpiData} />
                </Col>

                {/* <Col md={12}>
                  {kpiData?.kpiData.map((kpiRecord) => (
                    <KpiDataCard kpiRecord={kpiRecord} />
                  ))}
                </Col> */}
                <Col md={12} className='mt-5'>
                  <TableContainer columns={columns} globalSearch={true} data={kpiData?.kpiData} />
                </Col>
              </Row>
            ) : (
              <div className='inner-content planned-actions w-100'>
                <div className='d-lg-flex align-items-center justify-content-between'>
                  <div className='placeholder'>
                    <img src={placeholderAnalytics} alt='Planned Actions Placeholder' className='img-fluid' />
                    <h5>{t('You havent added any KPI records yet.')}</h5>
                    <Link className='btn btn-secondary btn-dashboard' to={'/my-disclosure/sdgs'}>
                      {t('Go to SDGs')}
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </Fragment>
        )}
      </div>
    </Fragment>
  );
}
