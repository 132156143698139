import React from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import history from './history';
import AppLayout from './components/Layout/AppLayout';
import PrivateRoute from './components/PrivateRoute';
import RouteNotFound from './RouteNotFound';
import AdminHome from './features/admin/AdminHome';
import RootAdminHome from './features/root-admin/RootAdminHome';
import ReportsHome from './features/reports/ReportsHome';
import AccountHome from './features/accounts/AccountHome';
import { config } from './utilities/config';
import EmissionHome from './features/emissions/EmissionHome';
import Dashboard from './features/dashboard/Dashboard';
import DisclosureHome from './features/disclosure/DisclosureHome';
import ReductionPlanHome from './features/reduction-plan/ReductionPlanHome';
import keycloak from './keycloak';

const ALL_USER_ROLES = ['cv_root', 'cv_admin', 'cv_open', 'cv_structured', 'cv_restricted', 'cv_audit', 'cv_restricted_audit'];

const Routes = () => {
  if (!keycloak.authenticated) {
    keycloak.login();
    return null;
  }
  return (
    <Router history={history}>
      <AppLayout>
        <Switch>
          <PrivateRoute roles={ALL_USER_ROLES} exact path="/">
            {keycloak.hasResourceRole('cv_structured', config.KEYCLOAK_BACKEND_CLIENT_ID) ? (
              <Redirect to="/emissions" />
            ) : (
              <Redirect to="/dashboard" />
            )}
          </PrivateRoute>
          <PrivateRoute roles={ALL_USER_ROLES} path='/my-disclosure' component={DisclosureHome} />
          <PrivateRoute roles={ALL_USER_ROLES} path='/reduction-plan' component={ReductionPlanHome} />
          <PrivateRoute roles={['cv_admin', 'cv_root']} component={AdminHome} path="/admin" />
          <PrivateRoute roles={['cv_root']} component={RootAdminHome} path="/rootadmin" />
          <PrivateRoute
            roles={['cv_root', 'cv_admin', 'cv_open', 'cv_restricted', 'cv_audit', 'cv_restricted_audit']}
            component={Dashboard}
            path="/dashboard"
          />
          <PrivateRoute roles={ALL_USER_ROLES} component={EmissionHome} path="/emissions" />
          <PrivateRoute roles={['cv_root', 'cv_admin', 'cv_open', 'cv_restricted', 'cv_audit', 'cv_restricted_audit']} component={ReportsHome} path="/reports" />
          <PrivateRoute roles={ALL_USER_ROLES} component={AccountHome} path="/accounts" />
          <Route path="*">
            <RouteNotFound />
          </Route>
        </Switch>
      </AppLayout>
    </Router>
  );
};

export default Routes;
