import G01 from '../../../assets/images/sdg_icons/G01.svg';
import G02 from '../../../assets/images/sdg_icons/G02.svg';
import G03 from '../../../assets/images/sdg_icons/G03.svg';
import G04 from '../../../assets/images/sdg_icons/G04.svg';
import G05 from '../../../assets/images/sdg_icons/G05.svg';
import G06 from '../../../assets/images/sdg_icons/G06.svg';
import G07 from '../../../assets/images/sdg_icons/G07.svg';
import G08 from '../../../assets/images/sdg_icons/G08.svg';
import G09 from '../../../assets/images/sdg_icons/G09.svg';
import G10 from '../../../assets/images/sdg_icons/G10.svg';
import G11 from '../../../assets/images/sdg_icons/G11.svg';
import G12 from '../../../assets/images/sdg_icons/G12.svg';
import G13 from '../../../assets/images/sdg_icons/G13.svg';
import G14 from '../../../assets/images/sdg_icons/G14.svg';
import G15 from '../../../assets/images/sdg_icons/G15.svg';
import G16 from '../../../assets/images/sdg_icons/G16.svg';
import G17 from '../../../assets/images/sdg_icons/G17.svg';

export function SDGLogoComponent(props) {
  const logo = (props) => {
    switch (props) {
      case 'G01':
        return G01;
      case 'G02':
        return G02;
      case 'G03':
        return G03;
      case 'G04':
        return G04;
      case 'G05':
        return G05;
      case 'G06':
        return G06;
      case 'G07':
        return G07;
      case 'G08':
        return G08;
      case 'G09':
        return G09;
      case 'G10':
        return G10;
      case 'G11':
        return G11;
      case 'G12':
        return G12;
      case 'G13':
        return G13;
      case 'G14':
        return G14;
      case 'G15':
        return G15;
      case 'G16':
        return G16;
      case 'G17':
        return G17;
      default:
        return G01;
    }
  };

  return <img alt='card-logo' style={{ height: 100, width: 100, borderRadius: '15px' }} src={logo(props)} />;
}
