import { apiRequest } from '../../utilities/apiHelper';

export async function fetchFavouriteScheduleApi(organizationId) {
  const url = `/schedule-reports/${organizationId}/favourite-report`;
  return await apiRequest('GET', url);
};

export async function createFavouriteScheduleApi(organizationId, data) {
  const url = `/schedule-reports/${organizationId}/favourite-report`;
  return await apiRequest('POST', url, data, false);
};

export async function updateFavouriteScheduleApi(organizationId, scheduleId, data) {
  const url = `/schedule-reports/${organizationId}/favourite-report/${scheduleId}`;
  return await apiRequest('PATCH', url, data, false);
};

export async function deleteFavouriteScheduleApi(organizationId, scheduleId) {
  const url = `/schedule-reports/${organizationId}/favourite-report/${scheduleId}`;
  return await apiRequest('DELETE', url, null, false);
};
