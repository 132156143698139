import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import PageHeader from '../../components/PageHeader';
import { LoadingState } from '../../utilities/constants';
import EmissionForm from './EmissionForm';
import { getSelectedEntity, getSelectedEP } from './emissionSlice';
import { toast } from 'react-toastify';
import { createEmissionApi } from './emissionApi';

const NewEmission = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [saveEmissionState, setSaveEmissionState] = useState(LoadingState.idle);
  const selectedEntity = useSelector(getSelectedEntity);
  const selectedEP = useSelector(getSelectedEP);
  const isBusy = saveEmissionState === LoadingState.loading;
  const orgId = useSelector((state) => state.users.user.usrOrgId);

  const handleAddEmission = async (data) => {
    try {
      setSaveEmissionState(LoadingState.loading);
      const requestData = { ...data, eDivId: selectedEntity.divId, eEprflsrcId: selectedEP.eprflsrcId };
      await createEmissionApi(orgId, requestData);
      toast.success(t('New emission is added'));
      setSaveEmissionState(LoadingState.succeeded);
      history.push('/emissions/data');
    } catch(error) {
      setSaveEmissionState(LoadingState.idle);
      toast.error(error.message);
    }
  };

  if (!selectedEntity || !selectedEP) {
    return <Redirect to={`/emissions/data`} />;
  }

  return (
    <Container fluid>
      <Row>
        <Col>
          <PageHeader
            title={t('Create Emission')}
            enableBackButton
          />
          <EmissionForm
            onSubmit={handleAddEmission}
            EPName={selectedEP?.eprflsrcName}
            entityName={selectedEntity?.divName}
            disabled={isBusy}
            buttonText={t('Add')}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default NewEmission;
