import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import ChartJs from 'react-chartjs-2';
import {
  fetchIntensityBusinessMeasuresByEntity,
  fetchIntensityEmissionsByEntity,
  fetchBusinessMeasures,
} from '../reportsSlice';
import { LoadingState } from '../../../utilities/constants';
import { Select } from '../configurations/Select';
import { useLocation } from 'react-router-dom';
import ReportContainer from '../ReportContainer';
import _ from 'lodash';
import {
  parseFavouriteData,
  formatNumber,
  createInitChartConfig,
  getCommonFilterPeriodRange,
  getCurrentReportPeriod,
  getReportRangePeriod,
  getStartDatePeriod,
  getMonthAndQuarterNames,
} from '../reportHelper';
import { config } from '../../../utilities/config';
import { useTranslation } from 'react-i18next';

const IntensityReports = () => {
  const { t } = useTranslation();
  const chartRef = useRef(null);
  const { filterData } = parseFavouriteData(useLocation().search);
  const userInfo = useSelector((state) => state.users.user);
  const currentReportPeriod = getCurrentReportPeriod(userInfo.usrOrgOptions);
  const reportRangePeriod = getReportRangePeriod(userInfo.usrOrgOptions, currentReportPeriod.reportYear);
  const [filterParams, setFilterParams] = useState({
    intervalType: 'monthly',
    businessTypeId: Number(config.BUSINESS_TYPE_REVENUE_ID),
    ...(filterData || {})
  });
  const defaultBusinessTypeOptions = useMemo(() => ({
    value: filterParams.businessTypeId,
    label: t('Revenue ($)'),
  }), [t, filterParams.businessTypeId]);
  const [chartEmissionsData, setChartEmissionsData] = useState({});
  const [chartBusinessMeasuresData, setChartBusinessMeasuresData] = useState({});
  const entityListStatus = useSelector((state) => state.reports.entityListStatus);
  const businessMeasures = useSelector((state) => state.reports.businessMeasures);
  // const businessMeasuresStatus = useSelector((state) => state.reports.businessMeasuresStatus);
  const intensityEmissionsByEntity = useSelector((state) => state.reports.intensityEmissionsByEntity);
  const intensityEmissionsByEntityStatus = useSelector((state) => state.reports.intensityEmissionsByEntityStatus);
  const intensityBusinessMeasuresByEntity = useSelector((state) => state.reports.intensityBusinessMeasuresByEntity);
  const intensityBusinessMeasuresByEntityStatus = useSelector(
    (state) => state.reports.intensityBusinessMeasuresByEntityStatus
  );
  const [startDatePeriod, setStartDatePeriod] = useState(getStartDatePeriod(reportRangePeriod, 'Calendar Year'));

  const parameters = [
    {
      key: t('Interval Type'),
      value: filterParams.intervalType,
    },
    {
      key: t('Business Measure'),
      value: 'Revenue ($)',
    }
  ];
  const fetchIntensityReportsHandler = async (dispatch, payload) => {
    payload.data = {
      intervalType: filterParams.intervalType,
      businessTypeId: filterParams.businessTypeId,
      ...payload.data,
    };
    const data = await dispatch(fetchIntensityBusinessMeasuresByEntity(payload));
    await dispatch(fetchIntensityEmissionsByEntity(payload));
    dispatch(fetchBusinessMeasures({ orgId: payload.orgId }));
    return data;
  };

  const getFavouriteFilterDataHandler = (params, filters) => {
    const paramsData = {
      ...params,
      intervalType: filters.intervalType,
      businessTypeId: Number(filters.businessMeasure),
    };
    setFilterParams(paramsData);
    setStartDatePeriod(getStartDatePeriod(reportRangePeriod, 'Calendar Year'));
    return paramsData;
  };

  const getBusinessMeasuresOptions = useCallback(() => businessMeasures.length ?
    businessMeasures.map(({ bsnsmsrtypId, bsnsmsrtypName }) => ({
      value: bsnsmsrtypId,
      label: bsnsmsrtypName
    }))
    : [defaultBusinessTypeOptions], [businessMeasures, defaultBusinessTypeOptions]);

  const getSelectedChartUnit = useCallback(() => {
    const selectedBM = getBusinessMeasuresOptions()
      .find(item => Number(item.value) === Number(filterParams.businessTypeId));
    if (selectedBM) {
      return `tCO2e/ ${selectedBM.label}`;
    }
    return 'tCO2e/ Revenue $';
  }, [filterParams, getBusinessMeasuresOptions]);

  const compare = (a, b) => {
    if (a.quarter) return a.quarter - b.quarter + (a.year - b.year) * 4;
    return a.month - b.month + (a.year - b.year) * 12;
  }
  const getEmissionsLabels = useCallback(() => {
    const dataset = [];
    if (intensityEmissionsByEntityStatus === LoadingState.succeeded) {
      if (intensityEmissionsByEntity.length > 0 && intensityBusinessMeasuresByEntity.length > 0
        && (
          (intensityBusinessMeasuresByEntity[0].quarter && intensityEmissionsByEntity[0].quarter)
          || (intensityBusinessMeasuresByEntity[0].month && intensityEmissionsByEntity[0].month)
        )
      ) {
        const compareValue = compare(intensityBusinessMeasuresByEntity[0], intensityEmissionsByEntity[0]);
        let newIntensityEmissionByEntity = [...intensityEmissionsByEntity];
        if (compareValue > 0) {
          newIntensityEmissionByEntity = newIntensityEmissionByEntity.slice(compareValue)
        }
        let totalMissingPoints = 0;
        for (let index = 0; index < newIntensityEmissionByEntity.length; index++) {
          const bucket = newIntensityEmissionByEntity[index];
          if (index + totalMissingPoints >= intensityBusinessMeasuresByEntity.length || dataset.length >= intensityBusinessMeasuresByEntity.length) break;
          // find and add 'null' to missing data points in intensityEmissionsByEntity
          const indexCompareValue = compare(bucket, intensityBusinessMeasuresByEntity[index + totalMissingPoints]);
          if (index === 0) {
          }
          if (totalMissingPoints + indexCompareValue > 0) {
            totalMissingPoints += indexCompareValue
            dataset.push(...new Array(indexCompareValue).fill(null));
          }
          dataset.push(bucket.values.reduce((total, child) => total + Number(child.emission || 0), 0));
        }
        if (dataset.length < intensityBusinessMeasuresByEntity.length) {
          dataset.push(...new Array(intensityBusinessMeasuresByEntity.length - dataset.length).fill(null));
        }
      }
    }
    return { datasets: dataset };
  }, [intensityEmissionsByEntityStatus, intensityEmissionsByEntity, intensityBusinessMeasuresByEntity]);

  const getBusinessMeasuresLabels = useCallback(() => {
    const labels = [];
    const datasets = [];
    if (intensityBusinessMeasuresByEntityStatus === LoadingState.succeeded) {
      if (intensityBusinessMeasuresByEntity.length > 0) {
        const { monthNames, quarterNames } = getMonthAndQuarterNames(t, startDatePeriod);
        intensityBusinessMeasuresByEntity.forEach((bucket, index) => {
          if ('month' in bucket) labels.push(`${monthNames[bucket.month]} ${bucket.year}`);
          else if ('quarter' in bucket) labels.push(t('{{quarter}} {{year}}', {
            quarter: quarterNames[bucket.quarter],
            year: bucket.year
          }));
          datasets.push(_.isNull(bucket.business_measure) ? datasets[index - 1] : Number(bucket.business_measure));
        });
      }
    }
    return { labels, datasets };
  }, [intensityBusinessMeasuresByEntityStatus, intensityBusinessMeasuresByEntity, startDatePeriod, t]);

  useEffect(() => {
    if (intensityEmissionsByEntityStatus === LoadingState.succeeded) {
      setChartEmissionsData(getEmissionsLabels());
    }
    if (intensityBusinessMeasuresByEntityStatus === LoadingState.succeeded) {
      setChartBusinessMeasuresData(getBusinessMeasuresLabels(1));
    }
  }, [
    entityListStatus,
    intensityBusinessMeasuresByEntityStatus,
    getEmissionsLabels,
    getBusinessMeasuresLabels,
    intensityEmissionsByEntityStatus,
  ]);

  const options = useMemo(() => {
    if (chartEmissionsData?.datasets?.length > 0 && chartBusinessMeasuresData?.datasets?.length > 0) {
      const data = (chartBusinessMeasuresData.datasets || []).map((item, idx) => (chartEmissionsData.datasets[idx] || 0) / chartBusinessMeasuresData.datasets[idx]);
      const maxValue = _.max(data);
      return createInitChartConfig({
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            boxWidth: 12
          }
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                drawOnChartArea: false
              }
            }
          ],
          yAxes: [
            {
              id: 'y1',
              ticks: {
                suggestedMin: 0,
                suggestedMax: 5,
                callback: label => formatNumber(label)
              },
              scaleLabel: {
                display: true,
                labelString: t('Emissions (tCO2e)'),
              }
            },
            {
              id: 'y2',
              spanGaps: true,
              position: 'right',
              ticks: {
                suggestedMin: 0,
                suggestedMax: maxValue + (0.02 * maxValue),
                callback: label => formatNumber(label)
              },
              scaleLabel: {
                display: true,
                labelString: t('Emissions Intensity ({{unit}})', { unit: getSelectedChartUnit() }),
              }
            },
          ]
        },
        tooltips: {
          filter: function (tooltipItem) {
            return tooltipItem.value !== null;
          },
          callbacks: {
            label: (tooltipItem, data) => {
              const { label } = data.datasets[tooltipItem.datasetIndex];
              return `${label}: ${formatNumber(tooltipItem.value)}`;
            }
          }
        }
      });
    }
  }, [t, chartBusinessMeasuresData, chartEmissionsData, getSelectedChartUnit]);

  const chartRender = () => {
    if (chartEmissionsData?.datasets?.length > 0 && chartBusinessMeasuresData?.datasets?.length > 0) {
      const lineData = (chartBusinessMeasuresData.datasets || []).map((item, idx) => (chartEmissionsData.datasets[idx] || 0) / chartBusinessMeasuresData.datasets[idx])
      const data = {
        labels: chartBusinessMeasuresData.labels || [],
        datasets: [
          {
            type: 'line',
            label: t('Emissions Intensity ({{unit}})', { unit: getSelectedChartUnit() }),
            yAxisID: 'y2',
            borderColor: 'rgb(255, 99, 132)',
            borderWidth: 2,
            fill: false,
            data: lineData,
          },
          {
            type: 'bar',
            label: t('Emissions (tCO2e)'),
            yAxisID: 'y1',
            backgroundColor: 'rgb(75, 192, 192)',
            data: chartEmissionsData.datasets || [],
            borderColor: 'white',
            borderWidth: 2,
          },
        ],
      }
      return (
        <>
          <div className="chart-graph">
            <ChartJs
              type="bar"
              data={data}
              options={options}
              ref={chartRef}
            />
          </div>
          <div id="js-legend" className="chart-legend"></div>
        </>
      )
    }
  };

  const exportDataHandler = () => {
    const selectedBM = getBusinessMeasuresOptions()
      .find(item => Number(item.value) === Number(filterParams.businessTypeId));
    const exportData = [
      [
        t('Months'),
        t('Emissions (tCO2e)'),
        t('Business Measure ({{selected}})', { selected: selectedBM?.label || '$' }),
        t('Emissions Intensity ({{unit}})', { unit: getSelectedChartUnit() }),
      ],
    ];
    chartBusinessMeasuresData.labels.forEach((month, idx) => {
      const intensityEsp = (chartEmissionsData.datasets[idx] || 0) / chartBusinessMeasuresData.datasets[idx];
      exportData.push([
        month,
        formatNumber(chartEmissionsData.datasets[idx], '-'),
        formatNumber(chartBusinessMeasuresData.datasets[idx], '-'),
        formatNumber(intensityEsp, '-'),
      ]);
    });
    return exportData;
  };

  const extraConfigRender = (isFavReport) => {
    const intervalTypeOptions = [
      { label: t('Monthly'), value: 'monthly' },
      { label: t('Quarterly'), value: 'quarterly' },
    ];
    return (
      <>
        <Select
          disabled={isFavReport}
          label={t("Interval")}
          name="intervalType"
          options={intervalTypeOptions}
        />
        <Select
          label={t("Business Measure")}
          name="businessMeasure"
          options={getBusinessMeasuresOptions()}
        />
      </>
    );
  };

  const getFilterPeriodRangeHandler = (data) => {
    return getCommonFilterPeriodRange(data, reportRangePeriod);
  };

  return (
    <ReportContainer
      reportName={t('Intensity Reports')}
      reportSubTitle={t('Intensity Reports')}
      fetchReportData={fetchIntensityReportsHandler}
      fetchReportStatus={intensityBusinessMeasuresByEntityStatus}
      reportData={intensityBusinessMeasuresByEntity}
      reportRender={chartRender}
      extraConfigRender={extraConfigRender}
      getFavouriteFilterData={getFavouriteFilterDataHandler}
      extraConfigDefault={{
        intervalType: filterParams.intervalType,
        businessMeasure: filterParams.businessTypeId,
      }}
      chartRef={chartRef}
      extraParameters={parameters}
      exportDataHandler={exportDataHandler}
      getFilterPeriodRange={getFilterPeriodRangeHandler}
    />
  );
};
export default IntensityReports;
