import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { LoadingState } from '../../utilities/constants';
import { editFactorApi, fetchFactorsApi, removeFactorApi } from './emissionFactorsApi';

export const fetchFactors = createAsyncThunk('emissionsource-factor/fetch', async (payload) => {
  try {
    const response = await fetchFactorsApi(payload);
    return response;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const editFactor = createAsyncThunk('emissionsource-factor/edit', async (payload, { dispatch }) => {
  try {
    const factor = await editFactorApi(payload.factorId, payload.data);
    toast.success('Factors successfully updated');
    return factor;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const deleteFactor = createAsyncThunk('emissionsource-factor/delete', async (payload, { dispatch }) => {
  try {
    const factor = await removeFactorApi(payload.factorId);
    dispatch(fetchFactors());
    return factor;
  } catch (error) {
    // toast.error(error.message);
    throw error;
  }
});

const emissionFactorsSlice = createSlice({
  name: 'factors',
  initialState: {
    factors: [],
    status: LoadingState.idle,
    error: null
  },
  reducers: {},
  extraReducers: {
    [fetchFactors.pending]: (state) => {
      state.status = LoadingState.loading;
    },
    [fetchFactors.fulfilled]: (state, action) => {
      state.status = LoadingState.succeeded;
      state.factors = action.payload;
    },
    [fetchFactors.rejected]: (state, action) => {
      state.status = LoadingState.failed;
      state.error = action.error.message;
    },
    [deleteFactor.fulfilled]: (state, action) => {
      const existingIndex = state.factors.findIndex((i) => i.id === action.payload.id);
      if (existingIndex >= 0) state.factors = state.factors.slice(existingIndex, 1);
    }
  }
});

export default emissionFactorsSlice.reducer;
