import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { useKeycloak } from '@react-keycloak/web';
import { isRootAuthorized } from '../../app/userImpersonate';
import { getListReportOptions } from '../reports/reportHelper';
import { timezoneList } from '../../utilities/timezone';
import { setLeavePrompt } from './usersSlice';
import { useDispatch, useSelector } from 'react-redux';
import { displayTimeFromSeconds } from '../../utilities/common';
import { createUserValidateSchema } from './validateSchema';
import { config } from '../../utilities/config';
import LoadingButton from '../../components/LoadingButton';

const UserForm = ({
  onSubmit,
  onCancel,
  onResendEmail,
  resendDelayCount,
  isResendEmail,
  initialValues = {},
  buttonText = 'Submit',
  isEditing = false,
  localeOptions,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const { createUserSchema, editUserSchema } = createUserValidateSchema(t);
  const usrOrgOptions = useSelector((state) => state.users.user.usrOrgOptions);
  const selectedLocale = initialValues?.optLocale || usrOrgOptions?.optLocale || config.DEFAULT_LOCALE;
  const keycloakObject = useKeycloak();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid, isDirty },
    trigger
  } = useForm({
    defaultValues: {
      usrMobileNumber: '',
      usrEnabled: 'Y',
      usrTemporaryPassword: 'N',
      usrChangePassword: 'N',
      ...initialValues,
      optLocale: selectedLocale,
    },
    resolver: yupResolver(isEditing ? editUserSchema : createUserSchema),
    mode: 'onChange'
  });
  const dispatch = useDispatch();

  const onSubmitHandler = async (data) => {
    data.usrName = data.usrEmail;
    try {
      dispatch(setLeavePrompt(false));
      await onSubmit(data);
    } catch (e) {
      dispatch(setLeavePrompt(isDirty));
    }
  };

  useEffect(() => {
    dispatch(setLeavePrompt(isDirty));
  }, [isDirty, dispatch]);

  const onCancelHandler = () => onCancel();

  const [userName, setUserName] = useState();
  const [changePassword, setChangePassword] = useState(!isEditing);

  const handleChange = (event) => {
    setUserName(event.target.value);
  };

  const userRoleOptions = [
    {
      label: "Admin",
      value: "cv_admin",
    },
    {
      label: "Audit",
      value: "cv_audit",
    },
    {
      label: "Open",
      value: "cv_open",
    },
    {
      label: "Restricted",
      value: "cv_restricted",
    },
    {
      label: "Restricted Audit",
      value: "cv_restricted_audit",
    },
    {
      label: "Structured",
      value: "cv_structured",
    },
  ];

  const dateFormatOptions = [
    {
      label: "Default Date Format",
      value: "",
    },
    {
      label: "yyyy-MM-dd",
      value: "yyyy-MM-dd",
    },
    {
      label: "yyyyMMdd",
      value: "yyyyMMdd",
    },
    {
      label: "yyyy.MM.dd",
      value: "yyyy.MM.dd",
    },
    {
      label: "yyyy/MM/dd",
      value: "yyyy/MM/dd",
    },
    {
      label: "MM-dd-yyyy",
      value: "MM-dd-yyyy",
    },
    {
      label: "MMddyyyy",
      value: "MMddyyyy",
    },
    {
      label: "MM.dd.yyyy",
      value: "MM.dd.yyyy",
    },
    {
      label: "MM/dd/yyyy",
      value: "MM/dd/yyyy",
    },
    {
      label: "ddMMyyyy",
      value: "ddMMyyyy",
    },
    {
      label: "dd-MM-yyyy",
      value: "dd-MM-yyyy",
    },
    {
      label: "dd.MM.yyyy",
      value: "dd.MM.yyyy",
    },
    {
      label: "dd/MM/yyyy",
      value: "dd/MM/yyyy",
    },
    {
      label: "yyyyddMM",
      value: "yyyyddMM",
    },
    {
      label: "yyyy-dd-MM",
      value: "yyyy-dd-MM",
    },
    {
      label: "yyyy.dd.MM",
      value: "yyyy.dd.MM",
    },
    {
      label: "yyyy/dd/MM",
      value: "yyyy/dd/MM",
    },
    {
      label: "dd-MMM-yyyy",
      value: "dd-MMM-yyyy",
    },
  ];

  const timeFormatOptions = [
    {
      label: "hh:mm:a",
      value: "hh:mm a",
    },
    {
      label: "HH:mm",
      value: "HH:mm",
    },
  ];

  const numberFormatOptions = [
    {
      label: "1,234.56",
      value: "1,234.56",
    },
    {
      label: "1.234,56",
      value: "1.234,56",
    },
    {
      label: "1234.56",
      value: "1234.56",
    },
  ];

  const numberScalingOptions = [
    {
      label: "1",
      value: "1",
    },
    {
      label: "2",
      value: "2",
    },
    {
      label: "3",
      value: "3",
    },
    {
      label: "4",
      value: "4",
    },
  ];

  const renderReportSettings = () => {
    if (!isRootAuthorized(keycloakObject)) {
      return null;
    }
    const reportOptions = getListReportOptions(t);
    return (
      <>
        <div className="form-divider">
          <span>{t('Report Settings')}</span>
        </div>
        <Row>
          <Col>
            <Form.Group className="custom-form-control">
              <Form.Label>{t('Available reports')}</Form.Label>
              <Controller
                name="optAvailableReports"
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      value={reportOptions.filter(({ value }) => field.value?.includes && field.value?.includes(value))}
                      options={reportOptions}
                      onChange={(d) => field.onChange(d.map(({value}) => value))}
                      placeholder={t('All reports')}
                      isMulti
                    />
                  );
                }}
              />
            </Form.Group>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <Form onSubmit={handleSubmit(onSubmitHandler)}>
      <div className="form-divider">
        <span>{t('General Details')}</span>
      </div>
      <Row>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('User Name')} *</Form.Label>
            <Form.Control
              {...register('usrName', {
                value: userName
              })}
              isInvalid={errors.usrName}
              readOnly={true}
              defaultValue={userName}
            />
            <Form.Control.Feedback type="invalid">{errors.usrName?.message}</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('E-Mail')} *</Form.Label>
            <Form.Control
              {...register('usrEmail')}
              rules={{
                validate: () => {
                  trigger('usrName');
                  return true;
                }
              }}
              onChange={!isEditing ? handleChange.bind() : null}
              isInvalid={errors.usrEmail}
              readOnly={isEditing}
            />
            <Form.Control.Feedback type="invalid">{errors.usrEmail?.message}</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('First Name')}</Form.Label>
            <Form.Control {...register('usrFirstname')} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Last Name')}</Form.Label>
            <Form.Control {...register('usrLastname')} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Mobile Number')}</Form.Label>
            <Form.Control
              {...register('usrMobileNumber')}
              isInvalid={errors.usrMobileNumber}
              placeholder={t('For example: +61282083366')}
            />
            <Form.Control.Feedback type="invalid">{errors.usrMobileNumber?.message}</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Role')} *</Form.Label>
            <Controller
              name="usrRole"
              control={control}
              render={({ field }) => {
                const format = (d) => field.onChange(field.value = d.value);
                return (
                  <Select
                    defaultValue={field.value && userRoleOptions.find((a) => field.value === a.value)}
                    options={userRoleOptions}
                    onChange={format}
                    placeholder={t("Select a role")}
                  />
                );
              }}
            />
            {errors.usrRole && errors.usrRole.type === 'required' && (
              <span className="required-span">{errors.usrRole.message}</span>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col className="d-flex align-items-center">
          <Form.Group className="custom-form-control">
            <Controller
              name="usrEnabled"
              control={control}
              render={({ field }) => {
                const parsed = field.value === 'Y' ? true : false;
                const format = () => {
                  field.onChange(field.value === 'Y' ? 'N' : 'Y');
                };
                return (
                  <Form.Check
                    custom
                    id="enabled"
                    onChange={format}
                    checked={parsed}
                    label={t('Enabled')}
                  />
                );
              }}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="custom-form-control">
            {onResendEmail &&
              <div className="form-control resend-email-container">
                <Button disabled={!isResendEmail || resendDelayCount} variant="info" onClick={onResendEmail}>
                  {isResendEmail === 0 ? <Spinner animation="grow" size="sm" /> : t('Resend activation email')}
                </Button>
                {isResendEmail && resendDelayCount ? <p><em>({t('You can retry in {{minutes}} minutes', { minutes: displayTimeFromSeconds(resendDelayCount) })})</em></p> : null}
              </div>
            }
          </Form.Group>
        </Col>
      </Row>
      <div className="form-divider">
        <span>{t('Localization Settings')}</span>
      </div>
      <Row>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Language')} *</Form.Label>
            <Controller
              name="optLocale"
              control={control}
              render={({ field }) => {
                const format = (d) => field.onChange(field.value = d.value);
                return (
                  <Select
                    value={localeOptions.find((a) => field.value === a.value)}
                    options={localeOptions}
                    defaultValue={localeOptions.find((a) => selectedLocale === a.value)}
                    onChange={format}
                    placeholder=""
                  />
                );
              }}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Date Format')}</Form.Label>
            <Controller
              name="usrDateFormat"
              control={control}
              render={({ field }) => {
                const format = (d) => field.onChange(field.value = d ? d.value : null);
                return (
                  <Select
                    value={field ? dateFormatOptions.find((a) => field.value === a.value) : null}
                    options={dateFormatOptions}
                    onChange={format}
                    isClearable
                    placeholder=""
                  />
                );
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Time Zone')}</Form.Label>
            <Controller
              name="usrTimeZone"
              control={control}
              render={({ field }) => {
                const format = (d) => field.onChange(field.value = d ? d.value : "0");
                return (
                  <Select
                    value={timezoneList.find((a) => field.value === a.value)}
                    options={timezoneList}
                    onChange={format}
                    isClearable
                    placeholder=""
                  />
                );
              }}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Time Format')}</Form.Label>
            <Controller
              name="usrTimeFormat"
              control={control}
              render={({ field }) => {
                const format = (d) => field.onChange(field.value = d ? d.value : "0");
                return (
                  <Select
                    value={timeFormatOptions.find((a) => field.value === a.value)}
                    options={timeFormatOptions}
                    onChange={format}
                    isClearable
                    placeholder=""
                  />
                );
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Number Format')}</Form.Label>
            <Controller
              name="usrNumberFormat"
              control={control}
              render={({ field }) => {
                const format = (d) => field.onChange(field.value = d ? d.value : "0");
                return (
                  <Select
                    value={numberFormatOptions.find((a) => field.value === a.value)}
                    options={numberFormatOptions}
                    onChange={format}
                    isClearable
                    placeholder=""
                  />
                );
              }}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Number Scaling (emission gases)')}</Form.Label>
            <Controller
              name="usrPreferredScaleDigits"
              control={control}
              render={({ field }) => {
                const format = (d) => field.onChange(field.value = d ? d.value : "0");
                return (
                  <Select
                    value={numberScalingOptions.find((a) => field.value?.toString() === a.value)}
                    options={numberScalingOptions}
                    onChange={format}
                    isClearable
                    placeholder=""
                  />
                );
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      {renderReportSettings()}
      {isEditing && (
        <Row>
          <Col>
            <Form.Group className="custom-form-control">
              <Controller
                name="usrChangePassword"
                control={control}
                render={({ field }) => {
                  const parsed = field.value === 'Y' ? true : false;
                  const format = () => {
                    field.onChange(field.value === 'Y' ? 'N' : 'Y');
                    setChangePassword(field.value === 'Y' ? false : true);
                  };
                  return (
                    <Form.Check
                      className="custom-form-control"
                      custom
                      id="changePassword"
                      onChange={format}
                      checked={parsed}
                      label={t('Change user password')}
                    />
                  );
                }}
              />
            </Form.Group>
          </Col>
        </Row>
      )}
      {(!isEditing || (isEditing && changePassword)) && (
        <div>
          <div>
            <div className="form-divider">
              <span>{t('Credential Details')}</span>
              <div></div>
            </div>
            <Row>
              <Col>
                <Form.Group className="custom-form-control">
                  <Form.Label>{t('Password')} *</Form.Label>
                  <Form.Control type="password" {...register('usrPassword')} isInvalid={errors.usrPassword} />
                  <Form.Control.Feedback type="invalid">{errors.usrPassword?.message}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="custom-form-control">
                  <Form.Label>{t('Verify Password')} *</Form.Label>
                  <Form.Control
                    type="password"
                    {...register('usrVerifyPassword')}
                    rules={{
                      validate: () => {
                        trigger('usrPassword');
                        return true;
                      }
                    }}
                    isInvalid={errors.usrVerifyPassword}
                  />
                  <Form.Control.Feedback type="invalid">{errors.usrVerifyPassword?.message}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="custom-form-control">
                  <Controller
                    name="usrTemporaryPassword"
                    control={control}
                    render={({ field }) => {
                      const parsed = field.value === 'Y' ? true : false;
                      const format = () => {
                        field.onChange(field.value === 'Y' ? 'N' : 'Y');
                      };
                      return (
                        <Form.Check
                          custom
                          id="temporaryPassword"
                          onChange={format}
                          checked={parsed}
                          label={t('Temporary Password')}
                        />
                      );
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>
        </div>
      )}
      <Button
        className="mb-5"
        type="submit"
        disabled={!isValid || !isDirty || disabled}
      >
        {buttonText}
      </Button>
      <Button variant="secondary" className="ml-4 mb-5" onClick={onCancelHandler}>
        {t('Cancel')}
      </Button>
      <LoadingButton isLoading={disabled} className="ml-4 mb-5" />
    </Form>
  );
};

export default UserForm;
