import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import PageHeader from '../../components/PageHeader';
import { LoadingState } from '../../utilities/constants';
import AssetForm from './AssetForm';
import { fetchAssets, selectAssetById } from './assetsSlice';
import { useTranslation } from 'react-i18next';
import { editAssetApi } from './assetsApi';
import { toast } from 'react-toastify';

const EditAsset = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { assetId } = useParams();
  const [saveAssetState, setSaveAssetState] = useState(LoadingState.idle);
  const asset = useSelector((state) => selectAssetById(state, assetId));
  const assetState = useSelector((state) => state.assets.status);
  const isBusy = saveAssetState === LoadingState.loading;
  const orgId = useSelector((state) => state.users.user.usrOrgId);

  const handleEditAsset = async (data) => {
    try {
      setSaveAssetState(LoadingState.loading);
      await editAssetApi(orgId, assetId, data);
      toast.success(t('Asset successfully updated'));
      history.push('/admin/setup/assets');
    } catch (e) {
      setSaveAssetState(LoadingState.idle);
      toast.error(e.message);
      throw e;
    }
  };

  const handleCancelAsset = () => {
    history.push('/admin/setup/assets');
  };

  useEffect(() => {
    if (assetState === LoadingState.idle) {
      dispatch(fetchAssets({ orgId }));
    }
  }, [orgId, assetState, dispatch]);

  return (
    <Container fluid>
      <Row>
        <Col>
          <PageHeader title={t('Update Asset')} />
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          {assetState === LoadingState.succeeded && asset && (
            <AssetForm
              onSubmit={handleEditAsset}
              buttonText={t('Save')}
              initialValues={asset}
              disabled={isBusy}
              isEditing
              onCancel={handleCancelAsset}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default EditAsset;
