import React, {useCallback, useEffect, useMemo, useState} from 'react';
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineEdit } from 'react-icons/ai';

import PageHeader from '../../components/PageHeader';
import { LoadingState } from '../../utilities/constants';
import DataTable from '../../components/DataTable';
import { useTranslation } from 'react-i18next';
import { editFactor, fetchFactors } from './emissionFactorsSlice';
import Select from 'react-select';
import {
  getEmissionSources,
  getEmissionSourceTypes,
  getReportingStandards
} from '../emission-profile/emissionProfileSlice';
import _ from 'lodash';
import { IoCloseOutline, IoSaveOutline } from 'react-icons/io5';
import * as yup from 'yup';
import { toast } from 'react-toastify';

const ManageEmissionFactors = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const fetchStatus = useSelector((state) => state.factors.status);
  const factors = useSelector((state) => state.factors.factors);
  const loggedUserStatus = useSelector((state) => state.users.loggedUserStatus);
  const orgId = useSelector((state) => state.users.user.usrOrgId);
  const reportingStandards = useSelector((state) => state.emissionProfiles.reportingStandards);
  const emissionSourceTypes = useSelector((state) => state.emissionProfiles.emissionSourceTypes);
  const emissionSources = useSelector((state) => state.emissionProfiles.emissionSources);

  const [selectedEmisionStd, setSelectedEmisionStd] = useState();
  const [selectedEmissionSourceTyp, setSelectedEmissionSourceTyp] = useState();
  const [selectedEmissionSource, setSelectedEmissionSource] = useState();
  const schema = yup.object().shape({
    co2eFactor: yup.number().nullable().typeError('Invalid Number Value'),
    co2Factor: yup.number().nullable().typeError('Invalid Number Value'),
    ch4Factor: yup.number().nullable().typeError('Invalid Number Value'),
    n2oFactor: yup.number().nullable().typeError('Invalid Number Value'),
    hfcFactor: yup.number().nullable().typeError('Invalid Number Value'),
    sf6Factor: yup.number().nullable().typeError('Invalid Number Value'),
    pfcFactor: yup.number().nullable().typeError('Invalid Number Value'),
    nf3Factor: yup.number().nullable().typeError('Invalid Number Value'),
  });

  const validateSchema = async (data) => {
    try {
      await schema.validate({
        co2eFactor: data.co2eFactor,
        co2Factor: data.co2Factor,
        ch4Factor: data.ch4Factor,
        n2oFactor: data.n2oFactor,
        hfcFactor: data.n2oFactor,
        sf6Factor: data.n2oFactor,
        pfcFactor: data.n2oFactor,
        nf3Factor: data.n2oFactor,
      });
      return true;
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  };
  const onEditCell = useCallback(() => ({ cell, onCellValueChange }) => {
    const { updatedValue } = cell.state;
    return (
        <input
            value={updatedValue}
            onChange={(e) => {
              onCellValueChange(e.target.value);
            }}
        />
    );
  }, [])
  const tableColumns = useMemo(
    () => [
      {
        Header: 'Factor Id',
        accessor: 'id'
      },
      {
        Header: 'Standard Name',
        accessor: (originalRow) => {
          return originalRow?.emissionSource?.esrcEstd?.estdName;
        },
        id: 'estd'
      },
      {
        Header: 'Emission Source Type',
        accessor: (originalRow) => {
          return originalRow?.emissionSource?.esrcEsUiType?.esrcuitypLabel;
        },
        id: 'esUiType'
      },
      {
        Header: 'Emission Source',
        accessor: (originalRow) => {
          return originalRow?.emissionSource?.esrcName;
        },
        id: 'esrc',
        sortType: 'string'
      },
      {
        Header: 'Unit',
        accessor: 'unit',
        sortType: 'string'
      },
      {
        Header: 'CO2e Factor',
        accessor: 'co2eFactor',
        width: 50
      },
      {
        Header: 'CO2 Factor',
        accessor: 'co2Factor',
        editable: true,
        EditCell: onEditCell(),
        width: 50
      },
      {
        Header: 'CH4 Factor',
        accessor: 'ch4Factor',
        editable: true,
        EditCell: onEditCell(),
        width: 50
      },
      {
        Header: 'N20 Factor',
        accessor: 'n2oFactor',
        editable: true,
        EditCell: onEditCell(),
        width: 50
      },
      {
        Header: 'HFC Factor',
        accessor: 'hfcFactor',
        editable: true,
        EditCell: onEditCell(),
        width: 50
      },
      {
        Header: 'SF6 Factor',
        accessor: 'sf6Factor',
        editable: true,
        EditCell: onEditCell(),
        width: 50
      },
      {
        Header: 'PFC Factor',
        accessor: 'pfcFactor',
        editable: true,
        EditCell: onEditCell(),
        width: 50
      },
      {
        Header: 'NF3 Factor',
        accessor: 'nf3Factor',
        editable: true,
        EditCell: onEditCell(),
        width: 50
      },
      {
        Header: '',
        accessor: 'actions',
        actionColumn: true,
        disableGlobalFilter: false,
        disableSortBy: true,
        EditCell: (props) => {
          const { cancelEditRow, saveRow } = props;
          return (
            <div className="d-flex action-buttons">
              <Button size="sm" className="mr-1" variant="outline-secondary" onClick={saveRow}>
                <IoSaveOutline />
              </Button>
              <Button size="sm" variant="outline-secondary" onClick={cancelEditRow}>
                <IoCloseOutline />
              </Button>
            </div>
          );
        },
        Cell: (props) => {
          const { editRow } = props;
          return (
            <div className="d-flex action-buttons">
              <Button size="sm" className="mr-1" variant="outline-secondary" onClick={editRow}>
                <AiOutlineEdit />
              </Button>
            </div>
          );
        }
      }
    ],
    [onEditCell]
  );

  const handleEditData = async (edited, original) => {
    try {
      const validated = await validateSchema(edited);
      if (validated) {
        console.log('Submit Edited');
        edited.co2eFactor = Object.keys(edited).reduce((value, key) => {
          if(key !== 'co2eFactor' && edited[key]) value += parseFloat(edited[key])
          return value
        }, 0)
        await dispatch(editFactor({ factorId: original.id, data: edited }));
        onSubmitHandler();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmitHandler = () => {
    let data = {};
    if (selectedEmisionStd) {
      data.esrcEstdId = selectedEmisionStd.value;
    }
    if (selectedEmissionSourceTyp) {
      data.esrcUiType = selectedEmissionSourceTyp.value;
    }
    if (selectedEmissionSource) {
      data.esrcId = selectedEmissionSource.value;
    }
    console.log('Submit', data);
    dispatch(fetchFactors(data));
  };

  const getReportingStandardsOptions = () => {
    if (!reportingStandards) {
      return [];
    }
    let selectOpts = reportingStandards.map((std) => {
      return {
        key: std.estdId,
        label: std.estdName,
        value: std.estdId
      };
    });
    selectOpts = _.orderBy(selectOpts, 'key', 'desc');
    return selectOpts;
  };

  const getEmissionSourceTypesOptions = () => {
    if (!emissionSourceTypes || !reportingStandards) return [];
    return emissionSourceTypes.map((typ) => {
      return {
        key: typ.esrcuitypEnumOrdinalId,
        value: typ.esrcuitypEnumOrdinalId,
        label: typ.esrcuitypLabel
      };
    });
  };
  const getEmissionSourcesOptions = () => {
    if (!emissionSources || !emissionSourceTypes) return [];
    return emissionSources.map((typ) => {
      return {
        key: typ.esrcId,
        value: typ.esrcId,
        label: typ.esrcName
      };
    });
  };

  const handleChangeReportingStd = (e) => {
    setSelectedEmisionStd(e);
    setSelectedEmissionSourceTyp(null);
    setSelectedEmissionSource(null);
    dispatch(getEmissionSourceTypes({ orgId, standardId: e.value }));
  };
  const handleChangeEmissionSourceTyp = (e) => {
    dispatch(
      getEmissionSources({
        orgId,
        emissionSourceTypeId: e.value,
        emissionStandardId: selectedEmisionStd.value
      })
    );
    setSelectedEmissionSourceTyp(e);
    setSelectedEmissionSource(null);
  };
  const handleChangeEmissionSource = (e) => {
    setSelectedEmissionSource(e);
  };

  useEffect(() => {
    if (loggedUserStatus === LoadingState.succeeded) dispatch(getReportingStandards({ orgId }));
  }, [loggedUserStatus, dispatch, orgId]);

  return (
    <Container fluid>
      <Row>
        <Col>
          <PageHeader title={'Manage Emission Factors'} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Emission Standard')}</Form.Label>
            <Select
              value={selectedEmisionStd}
              options={getReportingStandardsOptions()}
              onChange={handleChangeReportingStd}
              placeholder={t('Select Emission Standard')}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Emission Source Type')}</Form.Label>
            <Select
              value={selectedEmissionSourceTyp}
              options={getEmissionSourceTypesOptions()}
              onChange={handleChangeEmissionSourceTyp}
              placeholder={'Select Emission Source Type'}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Emission Source')}</Form.Label>
            <Select
              value={selectedEmissionSource}
              options={getEmissionSourcesOptions()}
              onChange={handleChangeEmissionSource}
              placeholder={'Select Emission Source'}
            />
          </Form.Group>
        </Col>
        <Col>
          <div style={{ lineHeight: '85px' }}>
            <Button
              className="btn-parameters"
              variant="secondary"
              onClick={() => onSubmitHandler()}
              disabled={!selectedEmisionStd}
            >
              Search Factors
            </Button>
          </div>
        </Col>
      </Row>
      {fetchStatus === LoadingState.loading && <Spinner animation="grow" />}
      {fetchStatus === LoadingState.succeeded && (
        <Row>
          <Col>
            <DataTable columns={tableColumns} data={factors} onEditSubmit={handleEditData} />
          </Col>
        </Row>
      )}
      {fetchStatus === LoadingState.failed && <div>Something Went Wrong</div>}
    </Container>
  );
};

export default ManageEmissionFactors;
