import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LoadingState } from '../../utilities/constants';
import {
  createKpiDataApi,
  createKpiSetupsApi,
  deleteKpiDataApi,
  getKpiDataBySetupApi,
  getSdgDataByOrgApi,
  updateKpiDataApi
} from './disclosureApi';

export const getSdgDataByOrg = createAsyncThunk('disclosure/getSdgDataByOrg', async (data) => {
  try {
    const response = await getSdgDataByOrgApi(data);
    return response;
  } catch (error) {
    throw error;
  }
});

export const createKpiSetups = createAsyncThunk('disclosure/createKpiSetups', async (requestData) => {
  try {
    const response = await createKpiSetupsApi(requestData);
    return response;
  } catch (error) {
    throw error;
  }
});

export const createKpiData = createAsyncThunk('disclosure/createKpiData', async (requestData) => {
  try {
    const response = await createKpiDataApi(requestData);
    return response;
  } catch (error) {
    throw error;
  }
});

export const getKpiDataBySetup = createAsyncThunk('disclosure/getKpiDataBySetup', async (data) => {
  try {
    const response = await getKpiDataBySetupApi(data);
    return response;
  } catch (error) {
    throw error;
  }
});

export const updateKpiData = createAsyncThunk('disclosure/updateKpiData', async (data) => {
  try {
    const response = await updateKpiDataApi(data);
    return response;
  } catch (error) {
    throw error;
  }
});

export const deleteKpiData = createAsyncThunk('disclosure/deleteKpiData', async (data) => {
  try {
    const response = await deleteKpiDataApi(data);
    return response;
  } catch (error) {
    throw error;
  }
});

export const setFocusedGoal = createAsyncThunk('disclosure/focusedGoal', async (focusedGoal) => {
  try {
    return focusedGoal;
  } catch (error) {
    throw error;
  }
});

const disclosureSlice = createSlice({
  name: 'disclosure',
  initialState: {
    sdgData: [],
    kpiData: [],
    focusedGoal : null,
    getSdgDataByOrgStatus: LoadingState.idle,
    createKpiSetupsStatus: LoadingState.idle,
    createKpiDataStatus: LoadingState.idle,
    deleteKpiDataStatus: LoadingState.idle,
    updateKpiDataStatus: LoadingState.idle,
    getKpiDataBySetupStatus: LoadingState.idle
  },
  reducers: {},
  extraReducers: {
    [getSdgDataByOrg.pending]: (state) => {
      state.getSdgDataByOrgStatus = LoadingState.loading;
    },
    [getSdgDataByOrg.fulfilled]: (state, action) => {
      state.getSdgDataByOrgStatus = LoadingState.succeeded;
      state.sdgData = action.payload;
    },
    [getSdgDataByOrg.rejected]: (state, action) => {
      state.getSdgDataByOrgStatus = LoadingState.failed;
      state.error = action.error.message;
    },

    [createKpiSetups.pending]: (state) => {
      state.createKpiSetupsStatus = LoadingState.loading;
    },
    [createKpiSetups.fulfilled]: (state, action) => {
      state.createKpiSetupsStatus = LoadingState.succeeded;
    },
    [createKpiSetups.rejected]: (state, action) => {
      state.createKpiSetupsStatus = LoadingState.failed;
      state.error = action.error.message;
    },

    [createKpiData.pending]: (state) => {
      state.createKpiDataStatus = LoadingState.loading;
    },
    [createKpiData.fulfilled]: (state, action) => {
      state.createKpiDataStatus = LoadingState.succeeded;
    },
    [createKpiData.rejected]: (state, action) => {
      state.createKpiDataStatus = LoadingState.failed;
      state.error = action.error.message;
    },

    [updateKpiData.pending]: (state) => {
      state.updateKpiDataStatus = LoadingState.loading;
    },
    [updateKpiData.fulfilled]: (state, action) => {
      state.updateKpiDataStatus = LoadingState.succeeded;
    },
    [updateKpiData.rejected]: (state, action) => {
      state.updateKpiDataStatus = LoadingState.failed;
      state.error = action.error.message;
    },

    [deleteKpiData.pending]: (state) => {
      state.deleteKpiDataStatus = LoadingState.loading;
    },
    [deleteKpiData.fulfilled]: (state, action) => {
      state.deleteKpiDataStatus = LoadingState.succeeded;
    },
    [deleteKpiData.rejected]: (state, action) => {
      state.deleteKpiDataStatus = LoadingState.failed;
      state.error = action.error.message;
    },

    [getKpiDataBySetup.pending]: (state) => {
      state.getKpiDataBySetupStatus = LoadingState.loading;
    },
    [getKpiDataBySetup.fulfilled]: (state, action) => {
      state.getKpiDataBySetupStatus = LoadingState.succeeded;
      state.kpiData = action.payload;
    },
    [getKpiDataBySetup.rejected]: (state, action) => {
      state.getKpiDataBySetupStatus = LoadingState.failed;
      state.error = action.error.message;
    },

    [setFocusedGoal.fulfilled]: (state, action) => {
      state.focusedGoal = action.payload;
    },
  }
});

export default disclosureSlice.reducer;
