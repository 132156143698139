import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { fetchMovingAverage } from '../reportsSlice';
import { useSelector } from 'react-redux';
import { Line } from 'react-chartjs-2';
import { useLocation } from 'react-router-dom';
import { LoadingState } from '../../../utilities/constants';
import { Select } from '../configurations/Select';
import ReportContainer from '../ReportContainer';
import {
  parseFavouriteData,
  getEmissionTypeOptions,
  generateChartLegend,
  formatNumber,
  reportUnitByTypeId,
  parseUnitJson,
  displayTargetUnit,
  createInitChartConfig,
  createInitChartPlugins,
  dateRangeFilterPeriodOptions,
  getCommonFilterPeriodRange,
  getMonthAndQuarterNames,
  getStartDatePeriod,
  getCurrentReportPeriod,
  getReportRangePeriod,
} from '../reportHelper';
import { useTranslation } from 'react-i18next';
import { subYears } from 'date-fns';

const MovingAverage = () => {
  const { t } = useTranslation();
  const chartRef = useRef(null);
  const intervalTypeOptions = [
    { label: t('Monthly'), value: 'monthly' },
    { label: t('Quarterly'), value: 'quarterly' }
  ];
  const { filterData } = parseFavouriteData(useLocation().search);
  const [filterParams, setFilterParams] = useState({
    emissionTypeId: 1,
    intervalType: 'monthly',
    ...(filterData || {})
  });
  const [chartData, setChartData] = useState({});

  const movingAverage = useSelector((state) => state.reports.movingAverage);
  const movingAverageStatus = useSelector((state) => state.reports.movingAverageStatus);
  const userInfo = useSelector((state) => state.users.user);
  const currentReportPeriod = getCurrentReportPeriod(userInfo.usrOrgOptions);
  const reportRangePeriod = getReportRangePeriod(userInfo.usrOrgOptions, currentReportPeriod.reportYear);
  const [startDatePeriod, setStartDatePeriod] = useState(getStartDatePeriod(reportRangePeriod, 'Calendar Year'));

  const movingDateRangeHandler = (data) =>
    getCommonFilterPeriodRange(data, { endDate: subYears(new Date(), 1) });

  const movingFilterPeriodOptions = (data, t, category, showDetail) =>
    dateRangeFilterPeriodOptions(data, t, 'moving-average-report', showDetail)
      .filter(item => ['1', '5'].includes(item.value));

  const plugins = createInitChartPlugins([]);

  const add = (accumulator, a) => {
    return accumulator + a;
  };

  const exportDataHandler = (type) => {
    if (type === 'csv') {
      const exportData = [
        [
          '',
          t('Total'),
          ...chartData.labels,
        ],
      ];
      chartData.datasets.forEach((item) => {
        exportData.push([
          item.title,
          `${formatNumber(item.total)} ${item.displayUnit}`,
          ...item.data.map(value => `${formatNumber(value)} ${item.displayUnit}`),
        ]);
      });
      return exportData;
    }
    return null;
  };

  const getLabels = useCallback(() => {
    let labels = [];
    let data = [];
    if (movingAverageStatus === LoadingState.succeeded) {
      const { monthNames, quarterNames } = getMonthAndQuarterNames(t, startDatePeriod);
      if (movingAverage.values.title === "Quarterly Emissions") {
        movingAverage.values.periods.forEach((bucket) => {
          labels.push(t('{{quarter}} {{year}}', {
            quarter: quarterNames[bucket.quarter],
            year: bucket.year
          }));
        });
      } else {
        movingAverage.values.periods.forEach((bucket) => {
          labels.push(`${monthNames[bucket.month]} ${bucket.year}`);
        });
      }
      const formattedMonthlyEmissionValues = movingAverage.values.values.map((value) => (value == null ? 0 : value));
      const formattedMovingAverageValues = movingAverage.movingAverages.values.map((value) =>
        value == null ? 0 : value
      );
      const formattedMonthlyEmissionUnits = movingAverage.values.units.map(unit => parseUnitJson(unit));
      const formattedMovingAverageUnits = movingAverage.movingAverages.units.map(unit => parseUnitJson(unit));
      data = [
        {
          label: t(movingAverage.values.title),
          fill: false,
          lineTension: 0,
          backgroundColor: 'rgba(75,192,192,1)',
          borderColor: 'rgba(75,192,192,1)',
          borderWidth: 2,
          data: formattedMonthlyEmissionValues,
          units: formattedMonthlyEmissionUnits,
        },
        {
          label: t(movingAverage.movingAverages.title),
          fill: false,
          lineTension: 0,
          backgroundColor: 'rgba(1, 167, 81, 1)',
          borderColor: 'rgba(1, 167, 81, 1)',
          borderWidth: 2,
          data: formattedMovingAverageValues,
          units: formattedMovingAverageUnits,
        }
      ];
    }
    const { unit } = reportUnitByTypeId(parseInt(filterParams.emissionTypeId), t);
    data.forEach((singleDataset) => {
      singleDataset.title = singleDataset.label;
      singleDataset.total = singleDataset.data.reduce(add, 0);
      singleDataset.displayUnit = displayTargetUnit(singleDataset.units, unit, true);
      const { label, total, displayUnit } = singleDataset;
      singleDataset.label = `${label} (${formatNumber(total)} ${displayUnit})`;
    });
    return { labels: labels, datasets: data };
  }, [
    t,
    movingAverageStatus,
    movingAverage,
    filterParams.emissionTypeId,
    startDatePeriod
  ]);

  const options = useMemo(() => {
    const { title } = reportUnitByTypeId(parseInt(filterParams.emissionTypeId), t);
    return createInitChartConfig({
      scales: {
        xAxes: [
          {
            gridLines: {
              drawOnChartArea: false
            }
          }
        ],
        yAxes: [
          {
            ticks: {
              suggestedMin: 0,
              suggestedMax: 5
            },
            scaleLabel: {
              display: true,
              labelString: title,
            }
          }
        ]
      },
      tooltips: {
        mode: 'nearest',
        intersect: false,
        filter: function (tooltipItem, data) {
          let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return value !== 0;
        },
        callbacks: {
          label: (tooltipItem, data) => {
            const { title, displayUnit } = data.datasets[tooltipItem.datasetIndex];
            return `${title}: ${formatNumber(tooltipItem.value)} ${displayUnit}`;
          }
        },
      }
    });
  }, [t, filterParams.emissionTypeId]);

  const parameters = [
    {
      key: 'Interval type',
      value: filterParams.intervalType
    },
  ];

  useEffect(() => {
    if (movingAverageStatus === LoadingState.loading) {
      setChartData({});
    }
    if (movingAverageStatus === LoadingState.succeeded) {
      setChartData(getLabels());
    }
  }, [
    movingAverageStatus, movingAverage, getLabels,
  ]);

  useEffect(() => {
    generateChartLegend(chartRef);
  }, [movingAverageStatus, chartData]);

  const fetchMovingAverageHandler = async (dispatch, payload) => {
    payload.data = {
      intervalType: filterParams.intervalType,
      emissionTypeId: parseInt(filterParams.emissionTypeId),
      ...payload.data,
    };
    delete payload.data.startDate;
    return await dispatch(fetchMovingAverage(payload));
  };

  const getFavouriteFilterDataHandler = (params, filters) => {
    const paramsData = {
      ...params,
      intervalType: filters.intervalType,
      emissionTypeId: parseInt(filters.emissionTypeId),
    };
    setFilterParams(paramsData);
    setStartDatePeriod(getStartDatePeriod(reportRangePeriod, 'Calendar Year'));
    return paramsData;
  };

  const chartRender = () => {
    return (
      <>
        <div className="chart-graph">
          <Line
            data={chartData}
            options={options}
            ref={chartRef}
            plugins={plugins}
          />
        </div>
        <div id="js-legend" className="chart-legend"></div>
      </>
    )
  };

  const extraConfigRender = (isFavReport) => {
    return (
      <>
        <Select
          disabled={isFavReport}
          label={t("Interval")}
          name="intervalType"
          options={intervalTypeOptions}
        />
        <Select
          disabled={isFavReport}
          label={t("CO2-e (t) or Usage")}
          name="emissionTypeId"
          options={getEmissionTypeOptions(t)}
        />
      </>
    );
  };
  return (
    <ReportContainer
      reportName={t('Moving Average Report')}
      reportSubTitle={t('Moving Average Graph')}
      fetchReportData={fetchMovingAverageHandler}
      fetchReportStatus={movingAverageStatus}
      reportData={movingAverage}
      reportRender={chartRender}
      extraConfigRender={extraConfigRender}
      getFavouriteFilterData={getFavouriteFilterDataHandler}
      extraConfigDefault={{
        intervalType: filterParams.intervalType,
        emissionTypeId: parseInt(filterParams.emissionTypeId),
      }}
      chartRef={chartRef}
      extraParameters={parameters}
      isUseStartDate={false}
      getTimeSelectOptions={movingFilterPeriodOptions}
      getFilterPeriodRange={movingDateRangeHandler}
      exportDataHandler={exportDataHandler}
    />
  );
};

export default MovingAverage;
