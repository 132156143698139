import { Row, Col, Form } from 'react-bootstrap';
import { Controller, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getReportUnitOptions } from '../../reports/reportHelper';
import CreatableSelect from 'react-select/creatable';
import { numericKeydownHandler } from '../../../utilities/common';

const HFCGases = [
  { value: 'HFC_23', label: 'HFC-23' },
  { value: 'HFC_32', label: 'HFC-32' },
  { value: 'HFC_41', label: 'HFC-41' },
  { value: 'HFC_43_10MEE', label: 'HFC-43-10mee' },
  { value: 'HFC_125', label: 'HFC-125' },
  { value: 'HFC_134', label: 'HFC-134' },
  { value: 'HFC_134A', label: 'HFC-134a' },
  { value: 'HFC_143', label: 'HFC-143' },
  { value: 'HFC_143A', label: 'HFC-143a' },
  { value: 'HFC_152A', label: 'HFC-152a' },
  { value: 'HFC_227EA', label: 'HFC-227ea' },
  { value: 'HFC_236FA', label: 'HFC-236fa' },
  { value: 'HFC_245CA', label: 'HFC-245ca' }
];

const PFCGases = [
  { value: 'PERFLUOROMETHANE', label: 'Perfluoromethane' },
  { value: 'PERFLUOROETHANE', label: 'Perfluoroethane' },
  { value: 'PERFLUOROPROPANE', label: 'Perfluoropropane' },
  { value: 'PERFLUOROBUTANE', label: 'Perfluorobutane' },
  { value: 'PERFLUOROCYCLOBUTANE', label: 'Perfluorocyclobutane' },
  { value: 'PERFLUOROPENTANE', label: 'Perfluoropentane' },
  { value: 'PERFLUOROHEXANE', label: 'Perfluorohexane' }
];

const CustomEmissionSource = ({ control, setValue, register, errors, isEditing }) => {
  const { t } = useTranslation();
  const orgReportUnits = useSelector((state) => state.organizations.reportUnits);

  const eprflsrcHfcGwp = useWatch({
    control,
    name: 'eprflsrcHfcGwp'
  });

  const eprflsrcPfcGwp = useWatch({
    control,
    name: 'eprflsrcPfcGwp'
  });

  const custom = useWatch({
    control,
    name: 'custom'
  });

  const consumptionUnit = useWatch({
    control,
    name: 'eprflsrcConsumptionUnit'
  });

  const gas = useWatch({
    control,
    name: 'gas'
  });

  const getHFCGasOptions = () => {
    return HFCGases.map((gas) => (
      <option key={gas.value} value={gas.value} selected={gas.value === eprflsrcHfcGwp}>
        {gas.label}
      </option>
    ));
  };

  const getPFCGasOptions = () => {
    return PFCGases.map((gas) => (
      <option key={gas.value} value={gas.value} selected={gas.value === eprflsrcPfcGwp}>
        {gas.label}
      </option>
    ));
  };

  const handleHFCGasChange = (e) => setValue('eprflsrcHfcGwp', e.target.value);

  const handlePFCGasChaneg = (e) => setValue('eprflsrcPfcGwp', e.target.value);

  return (
    <>
      <Row>
        <Col>
          <Form.Group className="custom-form-control">
            <Controller
              name="custom"
              control={control}
              render={({ field }) => {
                const parsed = field.value === 'Y' ? true : false;
                const format = () => {
                  field.onChange(field.value === 'Y' ? 'N' : 'Y');
                  // Reset the necessary form field values
                  setValue('gas', 'N');
                  setValue('eprflsrcEsrcId', null);
                  setValue('eprflsrcConsumptionUnit', '');
                  setValue('eprflsrcConversionFactor', '');
                  setValue('eprflsrcConversionFactortj', '');
                  setValue('eprflsrcUncertainty', '');
                  setValue('eprflsrcCo2ConversionFactor', '');
                  setValue('eprflsrcCo2Uncertainty', '');
                  setValue('eprflsrcCh4ConversionFactor', '');
                  setValue('eprflsrcCh4Uncertainty', '');
                  setValue('eprflsrcN2oConversionFactor', '');
                  setValue('eprflsrcN2oUncertainty', '');
                  setValue('eprflsrcSf6ConversionFactor', '');
                  setValue('eprflsrcSf6Uncertainty', '');
                  setValue('eprflsrcNf3ConversionFactor', '');
                  setValue('eprflsrcNf3Uncertainty', '');
                  setValue('eprflsrcHfcConversionFactor', '');
                  setValue('eprflsrcHfcGwp', '');
                  setValue('eprflsrcHfcUncertainty', '');
                  setValue('eprflsrcPfcConversionFactor', '');
                  setValue('eprflsrcPfcGwp', '');
                  setValue('eprflsrcPfcUncertainty', '');
                  setValue('showGeneralEmissionFromEps', false);
                };
                return (
                  <Form.Check
                    disabled={isEditing}
                    custom
                    id="custom"
                    onChange={format}
                    checked={parsed}
                    label={t('Custom Emission Source')}
                  />
                );
              }}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="custom-form-control">
            <Controller
              name="eprflsrcMeterReading"
              control={control}
              render={({ field }) => {
                const parsed = field.value === 'Y' ? true : false;
                const format = () => {
                  field.onChange(field.value === 'Y' ? 'N' : 'Y');
                };
                return (
                  <Form.Check
                    disabled={isEditing || custom === 'N'}
                    custom
                    id="eprflsrcMeterReading"
                    onChange={format}
                    checked={parsed}
                    label={t('Meter Reading Input')}
                  />
                );
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      <div className={custom === 'N' ? 'hidden' : ''}>
        <Row>
          <Col>
            <Form.Group className="custom-form-control">
              <Form.Label>{t('Consumption Unit')} *</Form.Label>
              <Controller
                name="eprflsrcConsumptionUnit"
                control={control}
                render={({ field }) => {
                  const format = (d) => field.onChange(d?.value);
                  const options = getReportUnitOptions(t, orgReportUnits);
                  const getSelectedUnit = () => {
                    let selected = { value: consumptionUnit, label: consumptionUnit};
                    options.forEach((item) => {
                      item.options.forEach((unit) => {
                        if (consumptionUnit === unit.value) {
                          selected = unit;
                        }
                      });
                    });
                    return selected;
                  };
                  return (
                    <CreatableSelect
                      value={consumptionUnit ? getSelectedUnit() : null }
                      options={getReportUnitOptions(t, orgReportUnits)}
                      onChange={format}
                      placeholder=""
                      isDisabled={isEditing || custom === 'N'}
                    />
                  );
                }}
              />
              <Form.Control.Feedback type="invalid">{errors.eprflsrcConsumptionUnit?.message}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="custom-form-control">
              <Form.Label>{t('Emission Factor in CO2-e (t)')} *</Form.Label>
              <Form.Control
                onKeyDown={numericKeydownHandler}
                {...register('eprflsrcConversionFactor')}
                readOnly={custom === 'N' || gas === 'Y'}
                isInvalid={errors.eprflsrcConversionFactor}
              />
              <Form.Control.Feedback type="invalid">{errors.eprflsrcConversionFactor?.message}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="custom-form-control">
              <Form.Label>{t('Uncertainty %')}</Form.Label>
              <Form.Control
                onKeyDown={numericKeydownHandler}
                {...register('eprflsrcUncertainty')}
                readOnly={isEditing || custom === 'N' || gas === 'Y'}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="custom-form-control">
              <Form.Label>{t('Energy Content Factor (TJ)')} *</Form.Label>
              <Form.Control
                onKeyDown={numericKeydownHandler}
                {...register('eprflsrcConversionFactortj')}
                readOnly={isEditing || custom === 'N' || gas === 'Y'}
                isInvalid={errors.eprflsrcConversionFactortj}
              />
              <Form.Control.Feedback type="invalid">{errors.eprflsrcConversionFactortj?.message}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="custom-form-control">
              <Controller
                name="gas"
                control={control}
                render={({ field }) => {
                  const parsed = field.value === 'Y' ? true : false;
                  const format = () => {
                    field.onChange(field.value === 'Y' ? 'N' : 'Y');
                    setValue('eprflsrcConversionFactor', '');
                    setValue('eprflsrcConversionFactortj', '');
                    setValue('eprflsrcUncertainty', '');
                    setValue('eprflsrcCo2ConversionFactor', '');
                    setValue('eprflsrcCo2Uncertainty', '');
                    setValue('eprflsrcCh4ConversionFactor', '');
                    setValue('eprflsrcCh4Uncertainty', '');
                    setValue('eprflsrcN2oConversionFactor', '');
                    setValue('eprflsrcN2oUncertainty', '');
                    setValue('eprflsrcSf6ConversionFactor', '');
                    setValue('eprflsrcSf6Uncertainty', '');
                    setValue('eprflsrcNf3ConversionFactor', '');
                    setValue('eprflsrcNf3Uncertainty', '');
                    setValue('eprflsrcHfcConversionFactor', '');
                    setValue('eprflsrcHfcGwp', '');
                    setValue('eprflsrcHfcUncertainty', '');
                    setValue('eprflsrcPfcConversionFactor', '');
                    setValue('eprflsrcPfcGwp', '');
                    setValue('eprflsrcPfcUncertainty', '');
                  };
                  return (
                    <Form.Check
                      custom
                      id="gas"
                      onChange={format}
                      checked={parsed}
                      label={t('Individual Gases')}
                      disabled={isEditing || custom === 'N'}
                    />
                  );
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <div className={gas === 'N' ? 'hidden' : ''}>
          <Row>
            <Col>
              <Form.Group className="custom-form-control">
                <Form.Label>{t('CO2 Conversion Factor')}</Form.Label>
                <Form.Control
                  onKeyDown={numericKeydownHandler}
                  {...register('eprflsrcCo2ConversionFactor')}
                  readOnly={isEditing || gas === 'N'}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="custom-form-control">
                <Form.Label>{t('CO2 Uncertainty %')}</Form.Label>
                <Form.Control
                  onKeyDown={numericKeydownHandler}
                  {...register('eprflsrcCo2Uncertainty')}
                  readOnly={isEditing || gas === 'N'}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="custom-form-control">
                <Form.Label>{t('CH4 Conversion Factor')}</Form.Label>
                <Form.Control
                  onKeyDown={numericKeydownHandler}
                  {...register('eprflsrcCh4ConversionFactor')}
                  readOnly={isEditing || gas === 'N'}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="custom-form-control">
                <Form.Label>{t('CH4 Uncertainty %')}</Form.Label>
                <Form.Control
                  onKeyDown={numericKeydownHandler}
                  {...register('eprflsrcCh4Uncertainty')}
                  readOnly={isEditing || gas === 'N'}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="custom-form-control">
                <Form.Label>{t('N2O Conversion Factor')}</Form.Label>
                <Form.Control
                  onKeyDown={numericKeydownHandler}
                  {...register('eprflsrcN2oConversionFactor')}
                  readOnly={isEditing || gas === 'N'}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="custom-form-control">
                <Form.Label>{t('N2O Uncertainty %')}</Form.Label>
                <Form.Control
                  onKeyDown={numericKeydownHandler}
                  {...register('eprflsrcN2oUncertainty')}
                  readOnly={isEditing || gas === 'N'} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="custom-form-control">
                <Form.Label>{t('SF6 Conversion Factorr')}</Form.Label>
                <Form.Control
                  onKeyDown={numericKeydownHandler}
                  {...register('eprflsrcSf6ConversionFactor')}
                  readOnly={isEditing || gas === 'N'}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="custom-form-control">
                <Form.Label>{t('SF6 Uncertainty %')}</Form.Label>
                <Form.Control
                  onKeyDown={numericKeydownHandler}
                  {...register('eprflsrcSf6Uncertainty')}
                  readOnly={isEditing || gas === 'N'}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="custom-form-control">
                <Form.Label>{t('NF3 Conversion Factor')}</Form.Label>
                <Form.Control
                  onKeyDown={numericKeydownHandler}
                  {...register('eprflsrcNf3ConversionFactor')}
                  readOnly={isEditing || gas === 'N'}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="custom-form-control">
                <Form.Label>{t('NF3 Uncertainty %')}</Form.Label>
                <Form.Control
                  onKeyDown={numericKeydownHandler}
                  {...register('eprflsrcNf3Uncertainty')}
                  readOnly={isEditing || gas === 'N'}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="custom-form-control">
                <Form.Label>{t('HFC Conversion Factor')}</Form.Label>
                <Form.Control
                  onKeyDown={numericKeydownHandler}
                  {...register('eprflsrcHfcConversionFactor')}
                  readOnly={isEditing || gas === 'N'}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="custom-form-control">
                <Form.Label>{t("HFC Gas")}</Form.Label>
                <Form.Control as="select" onChange={handleHFCGasChange} disabled={isEditing || gas === 'N'}>
                  <option value="" hidden>
                    {t('Select HFC Gas')}
                  </option>
                  {getHFCGasOptions()}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="custom-form-control">
                <Form.Label>{t("HFC Uncertainty %")}</Form.Label>
                <Form.Control
                  onKeyDown={numericKeydownHandler}
                  {...register('eprflsrcHfcUncertainty')}
                  readOnly={isEditing || gas === 'N'}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="custom-form-control">
                <Form.Label>{t("PFC Conversion Factor")}</Form.Label>
                <Form.Control
                  onKeyDown={numericKeydownHandler}
                  {...register('eprflsrcPfcConversionFactor')}
                  readOnly={isEditing || gas === 'N'}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="custom-form-control">
                <Form.Label>{t("PFC Gas")}</Form.Label>
                <Form.Control as="select" onChange={handlePFCGasChaneg} disabled={isEditing || gas === 'N'}>
                  <option value="" hidden>
                    {t('Select PFC Gas')}
                  </option>
                  {getPFCGasOptions()}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="custom-form-control">
                <Form.Label>{t("PFC Uncertainty %")}</Form.Label>
                <Form.Control
                  onKeyDown={numericKeydownHandler}
                  {...register('eprflsrcPfcUncertainty')}
                  readOnly={isEditing || gas === 'N'}
                />
              </Form.Group>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default CustomEmissionSource;
