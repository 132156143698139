import { config } from '../../utilities/config';
import keycloak from '../../keycloak';

export async function fetchWhitelabelsApi() {
  const response = await fetch(`${config.API_BASE_URL}/whitelabel/list`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    }
  });
  const data = await response.json();
  if (!response.ok) throw new Error(data.message);
  return data;
}

export async function createWhitelabelApi(data) {
  const response = await fetch(`${config.API_BASE_URL}/whitelabel/create`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function editWhitelabelApi(id, data) {
  const response = await fetch(`${config.API_BASE_URL}/whitelabel/${id}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}
