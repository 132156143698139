import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import { fetchEmissionsByAsset } from '../reportsSlice';
import { LoadingState } from '../../../utilities/constants';
import { useSelector } from 'react-redux';
import { Bar } from 'react-chartjs-2';
import { Select } from '../configurations/Select';
import { useLocation } from 'react-router-dom';
import ReportContainer from '../ReportContainer';
import { useHistory } from 'react-router-dom';
import {
  setupReportRangePeriod,
  getCommonFilterPeriodRange,
  getCurrentReportPeriod,
  getReportYearTypeOptions,
  getReportRangePeriod,
  parseFavouriteData,
  getIntervalTypeOptions,
  reportYearList,
  generateRandomColors,
  generateChartLegend,
  reportUnitByTypeId,
  formatNumber,
  createInitChartConfig,
  createInitChartPlugins,
  getMonthAndQuarterNames,
  getStartDatePeriod,
} from '../reportHelper';
import { useTranslation } from 'react-i18next';

const EmissionsByAsset = () => {
  const { t } = useTranslation();
  const chartRef = useRef(null);
  const { filterData } = parseFavouriteData(useLocation().search);
  const history = useHistory();

  const configRef = useRef(null);
  const userInfo = useSelector((state) => state.users.user);
  const currentReportPeriod = getCurrentReportPeriod(userInfo.usrOrgOptions);
  const [reportYear, setReportYear] = useState(currentReportPeriod.reportYear);
  const reportRangePeriod = getReportRangePeriod(userInfo.usrOrgOptions, reportYear);
  const [filterParams, setFilterParams] = useState({
    reportYearType: 'Calendar Year',
    intervalType: 'quarterly',
    ...currentReportPeriod,
    ...(filterData || {}),
  });
  const [chartData, setChartData] = useState({});
  const [selectedReportYearType, setSelectedReportYearType] = useState(filterParams.reportYearType);
  const emissionsByAsset = useSelector((state) => state.reports.emissionsByAsset);
  const emissionsByAssetStatus = useSelector((state) => state.reports.emissionsByAssetStatus);
  const [startDatePeriod, setStartDatePeriod] = useState(getStartDatePeriod(reportRangePeriod, filterParams.reportYearType));

  const changeDateRangePeriod = (usrOrgOptions, year) => {
    const rangePeriod = getReportRangePeriod(usrOrgOptions, year);
    if (rangePeriod) {
      configRef.current.setFormValue('startDate', rangePeriod.startDate);
      configRef.current.setFormValue('endDate', rangePeriod.endDate);
    }
  };
  const reportYearHanlder = (event) => {
    setReportYear(event.target.value);
    changeDateRangePeriod(userInfo.usrOrgOptions, event.target.value);
  };
  const selectedReportYearTypeHandler = ({ target }) => {
    if (target.value === 'SETUP') {
      setupReportRangePeriod(history);
    } else {
      setSelectedReportYearType(target.value);
      if (target.value === 'Calendar Year') {
        changeDateRangePeriod({}, null);
      } else {
        changeDateRangePeriod(userInfo.usrOrgOptions, reportYear);
      }
    }
  };
  const getFilterPeriodRangeHandler = (data) => {
    return getCommonFilterPeriodRange(data, reportRangePeriod);
  };

  const fetchEmissionsByAssetHandler = async (dispatch, payload) => {
    payload.data = {
      intervalType: filterParams.intervalType,
      reportYear: parseInt(filterParams.reportYear || reportYear),
      reportYearType: filterParams.reportYearType,
      ...payload.data,
      reportPeriodEnding: currentReportPeriod.reportPeriodEnding,
    };
    return await dispatch(fetchEmissionsByAsset(payload));
  };

  const getFavouriteFilterDataHandler = (params, filters) => {
    const paramsData = {
      ...params,
      intervalType: filters.intervalType,
      reportYear: parseInt(filters.reportYear || reportYear),
      reportYearType: filters.reportYearType,
      reportPeriodEnding: currentReportPeriod.reportPeriodEnding,
    };
    setFilterParams(paramsData);
    setStartDatePeriod(getStartDatePeriod(reportRangePeriod, filters.reportYearType));
    return paramsData;
  };
  const add = (accumulator, a) => {
    return accumulator + a;
  };

  const getLabels = useCallback(() => {
    const labels = [];
    const dataset = [];
    const { unit } = reportUnitByTypeId(1, t);
    if (emissionsByAssetStatus === LoadingState.succeeded) {
      let assets = [];
      const { monthNames, quarterNames } = getMonthAndQuarterNames(t, startDatePeriod);
      emissionsByAsset.forEach((bucket) => {
        if ('month' in bucket) labels.push(`${monthNames[bucket.month]} ${bucket.year}`);
        else if ('quarter' in bucket) labels.push(t('{{quarter}} {{year}}', {
          quarter: quarterNames[bucket.quarter],
          year: bucket.year
        }));
        else labels.push(bucket.year);
        bucket.children.forEach((child) => assets.push(child));
      });
      assets = assets.filter((asset, index, assets) => assets.map((a) => a.asset_id).indexOf(asset.asset_id) === index);
      generateRandomColors(true);
      assets.forEach((a) => {
        const data = [];
        emissionsByAsset.forEach((bucket) => {
          const found = bucket.children.find((child) => {
            if (child.asset_id === a.asset_id) return child;
            return null;
          });
          if (found) {
            if (isNaN(found.value)) {
              data.push(0);
            } else {
              data.push(parseFloat(found.value));
            }
          } else data.push(0);
        });
        dataset.push({
          axis: 'y',
          label: a.asset_name,
          data,
          backgroundColor: generateRandomColors()
        });
      });
      dataset.forEach((singleDataset) => {
        singleDataset.total = singleDataset.data.reduce(add, 0);
        singleDataset.title = singleDataset.label;
        singleDataset.displayUnit = unit;
        const { title, total } = singleDataset;
        singleDataset.label = `${title} (${formatNumber(total)} ${unit})`;
      });
    }
    return { labels: labels, datasets: dataset };
  }, [
    t,
    emissionsByAssetStatus,
    emissionsByAsset,
    startDatePeriod
  ]);

  const plugins = createInitChartPlugins([]);

  useEffect(() => {
    if (emissionsByAssetStatus === LoadingState.loading) {
      setChartData({});
    }
    if (emissionsByAssetStatus === LoadingState.succeeded) {
      setChartData(getLabels());
    }
  }, [
    emissionsByAssetStatus, emissionsByAsset, getLabels,
  ]);

  useEffect(() => {
    generateChartLegend(chartRef);
  }, [emissionsByAssetStatus, chartData]);

  const options = useMemo(() => {
    const totalArray = [];
    if (chartData.datasets && chartData.datasets.length > 0 && emissionsByAssetStatus === LoadingState.succeeded) {
      chartData.datasets[0].data.map((dataPoint, index) => {
        let total = dataPoint;
        let i = 1;
        while (i < chartData.datasets.length) {
          total += chartData.datasets[i].data[index];
          i++;
        }
        return totalArray.push(total);
      });
    }
    const { unit, title } = reportUnitByTypeId(1, t);
    return createInitChartConfig({
      scales: {
        xAxes: [
          {
            stacked: true,
            gridLines: {
              drawOnChartArea: false
            }
          }
        ],
        yAxes: [
          {
            stacked: true,
            ticks: {
              beginAtZero: true,
              suggestedMax: Math.max(...totalArray) + (5 / 100) * Math.max(...totalArray)
            },
            scaleLabel: {
              display: true,
              labelString: title,
            }
          }
        ]
      },
      tooltips: {
        callbacks: {
          label: (tooltipItem, data) => {
            const { title } = data.datasets[tooltipItem.datasetIndex];
            return `${title}: ${formatNumber(tooltipItem.value)} ${unit}`;
          },
          beforeBody: (tooltipItem, data) => {
            let total = 0;
            data.datasets.forEach((dataset, datasetIndex) => {
              if (chartRef.current.chartInstance.isDatasetVisible(datasetIndex)) {
                total += dataset.data[tooltipItem[0].index] || 0;
              }
            });
            return t('Total: {{number}}', { number: formatNumber(total) });
          }
        },
      },
    })
  }, [t, chartData.datasets, emissionsByAssetStatus]);

  const chartRender = () => {
    return (
      <>
        <div className="chart-graph">
          <Bar
            data={chartData}
            options={options}
            ref={chartRef}
            plugins={plugins}
          />
        </div>
        <div id="js-legend" className="chart-legend"></div>
      </>
    )
  };

  const extraConfigRender = (isFavReport) => {
    return (
      <>
        <Select
          disabled={isFavReport}
          label={t("Report Year Type")}
          name="reportYearType"
          options={getReportYearTypeOptions(t, reportRangePeriod)}
          onChange={selectedReportYearTypeHandler}
        />
        <Select
          disabled={isFavReport || selectedReportYearType === 'Calendar Year'}
          label={t("Report Year")}
          name="reportYear"
          onChange={reportYearHanlder}
          options={reportYearList()}
        />
        <Select
          disabled={isFavReport}
          label={t("Interval")}
          name="intervalType"
          options={getIntervalTypeOptions(t)}
        />
      </>
    );
  };

  return (
    <ReportContainer
      reportName={t('Emissions by Asset')}
      reportSubTitle={t('Emission Footprint Overview by Asset')}
      configRef={configRef}
      fetchReportData={fetchEmissionsByAssetHandler}
      fetchReportStatus={emissionsByAssetStatus}
      reportData={emissionsByAsset}
      reportRender={chartRender}
      extraConfigRender={extraConfigRender}
      getFavouriteFilterData={getFavouriteFilterDataHandler}
      getFilterPeriodRange={getFilterPeriodRangeHandler}
      extraConfigDefault={{
        reportYearType: filterParams.reportYearType,
        intervalType: filterParams.intervalType,
        reportYear: parseInt(filterParams.reportYear),
      }}
      extraConfigFormState={{
        reportYearType: selectedReportYearType,
      }}
      chartRef={chartRef}
    />
  );
};

export default EmissionsByAsset;
