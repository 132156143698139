import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  assignMultiEntities,
  fetchAvailableEntitiesToEG,
  fetchAssignedEntitiesForEG,
} from './entityGroupsSlice';
import PageHeader from '../../components/PageHeader';
import { useParams } from 'react-router-dom';
import DataMultiAssignment from '../../components/DataMultiAssignment';
import { useTranslation } from 'react-i18next';

const AssignEntitiesToEG = () => {
  const dispatch = useDispatch();
  const { egId } = useParams();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const availableEntities = useSelector((state) => state.entityGroups.availableEntitiesToEntityGroup);
  const assignedEntities = useSelector((state) => state.entityGroups.assignedEntitiesToEntityGroup);
  const orgId = useSelector((state) => state.users.user.usrOrgId);

  const handleAssignData = async (actionType, divIds) => {
    try {
      setIsLoading(true);
      const data = { type: actionType === 'Assign' ? 'ADD' : 'REMOVE', divIds };
      const resultAction = await dispatch(assignMultiEntities({ orgId, egId, ...data }));
      unwrapResult(resultAction);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    dispatch(fetchAvailableEntitiesToEG({ orgId, egId }));
    dispatch(fetchAssignedEntitiesForEG({ orgId, egId }));
  }, [orgId, egId, dispatch]);

  return (
    <Container fluid>
      <Row>
        <Col>
          <PageHeader title={t('Assign Entities')} enableBackButton={true} />
        </Col>
      </Row>
      <DataMultiAssignment
        isLoading={isLoading}
        multiTitle={t("Entities")}
        singleTitle={t("Entity")}
        dataKey="divId"
        dataName="divName"
        dataSearch="divAssignedId"
        availableData={availableEntities}
        assignedData={assignedEntities}
        handleAssignData={handleAssignData}
      />
    </Container>
  );
};

export default AssignEntitiesToEG;
