import { Fragment, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { HiDownload } from 'react-icons/hi';
import { getKpiDataByOrgApi } from '../disclosureApi';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const DownloadSummary = ({ orgId, sdgData, sDGGoalSummaryData }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const appendCanvas = (container, id) => {
    const source = document.getElementById(id);
    return new Promise((resolve) => {
      html2canvas(source).then((canvas) => {
        canvas.style.width = '90%';
        canvas.style.height = '90%';
        container.appendChild(canvas);
        resolve(canvas);
      });
    });
  };
  const createDomTable = (tableData) => {
    const table = document.createElement('table');
    const tableBody = document.createElement('tbody');
    table.className = 'table table-bordered table-hover';
    table.style.width = '90%';
    tableData.forEach((rowData, idx) => {
      const row = document.createElement('tr');
      rowData.forEach(function (cellData) {
        const cell = document.createElement('td');
        cell.style.fontSize = '1.7rem';
        if (idx === 0) {
          cell.style.fontWeight = '700';
        }
        cell.appendChild(document.createTextNode(cellData));
        row.appendChild(cell);
      });
      tableBody.appendChild(row);
    });
    table.appendChild(tableBody);
    return table;
  }

  const downloadHandler = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    try {
      await processDownload();
      setIsLoading(false);
    } catch (err) {
      toast.error(err.message);
      setIsLoading(false);
    }
  };

  const processDownload = async () => {
    const container = document.createElement('div');
    const pdf = new jsPDF({ orientation: 'p', unit: 'pt', format: 'a4' });
    const width = pdf.internal.pageSize.getWidth();
    const height = pdf.internal.pageSize.getHeight();
    await appendCanvas(container, 'goal-summary-chart');
    // await appendCanvas(container, 'goal-legend');
    const kpiData = await getKpiDataByOrgApi(orgId);
    const kpiDataset = {};
    sdgData.forEach((item) => {
      item.kpis.forEach(({ setupId, kpiName, description }) => {
        kpiDataset[setupId] = {
          kpiName,
          description,
          goalName: item.goalName,
          orderNo: item.orderNo,
        }
      });
    });
    const tableData = kpiData.map((item) => {
      const kpi = kpiDataset[item.setupId];
      const insight =  sDGGoalSummaryData.find(item => item.goalName === kpi.goalName);
      if (!insight) {
        return false;
      }
      return [
        t(kpi.goalName),
        t(kpi.kpiName),
        item.rating,
        item.comment,
        insight.goal,
      ]
    }).filter(item => item !== false);
    tableData.unshift([t('Goal'), t('Kpi'), t('Rating'), t('Comment'), t('Progress')]);
    const table = createDomTable(tableData);
    table.style.marginTop = '30px';
    container.appendChild(table);
    pdf.html(container, {
      width: width,
      height: height,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      margin: [30, 30, 30, 30],
      callback: function (pdf) {
        pdf.setTextColor(0, 0, 0);
        pdf.setFont('helvetica');
        pdf.setFontSize(20);
        pdf.text('KPI Performance Report', pdf.internal.pageSize.getWidth() / 2, 20, {
          align: 'center'
        });
        pdf.save(`sgd-KPI-Performance-Report.pdf`);
      }
    });
  };

  return (
    <Button
      variant='outline-primary'
      onClick={downloadHandler}
    >
      {isLoading ? (
        <Spinner animation='border' />
      ) : (
        <Fragment>
          <HiDownload /> {t('Download')}
        </Fragment>
      )}
    </Button>
  );
};

export default DownloadSummary;
