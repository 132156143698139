export const timezoneList = [
  {
    value: "Africa/Addis_Ababa",
    label: "Africa/Addis_Ababa",
  },
  {
    value: "Africa/Algiers",
    label: "Africa/Algiers",
  },
  {
    value: "Africa/Asmara",
    label: "Africa/Asmara",
  },
  {
    value: "Africa/Bangui",
    label: "Africa/Bangui",
  },
  {
    value: "Africa/Blantyre",
    label: "Africa/Blantyre",
  },
  {
    value: "Africa/Brazzaville",
    label: "Africa/Brazzaville",
  },
  {
    value: "Africa/Bujumbura",
    label: "Africa/Bujumbura",
  },
  {
    value: "Africa/Cairo",
    label: "Africa/Cairo",
  },
  {
    value: "Africa/Ceuta",
    label: "Africa/Ceuta",
  },
  {
    value: "Africa/Dar_es_Salaam",
    label: "Africa/Dar_es_Salaam",
  },
  {
    value: "Africa/Djibouti",
    label: "Africa/Djibouti",
  },
  {
    value: "Africa/Douala",
    label: "Africa/Douala",
  },
  {
    value: "Africa/Gaborone",
    label: "Africa/Gaborone",
  },
  {
    value: "Africa/Harare",
    label: "Africa/Harare",
  },
  {
    value: "Africa/Johannesburg",
    label: "Africa/Johannesburg",
  },
  {
    value: "Africa/Juba",
    label: "Africa/Juba",
  },
  {
    value: "Africa/Kampala",
    label: "Africa/Kampala",
  },
  {
    value: "Africa/Khartoum",
    label: "Africa/Khartoum",
  },
  {
    value: "Africa/Kigali",
    label: "Africa/Kigali",
  },
  {
    value: "Africa/Kinshasa",
    label: "Africa/Kinshasa",
  },
  {
    value: "Africa/Lagos",
    label: "Africa/Lagos",
  },
  {
    value: "Africa/Libreville",
    label: "Africa/Libreville",
  },
  {
    value: "Africa/Luanda",
    label: "Africa/Luanda",
  },
  {
    value: "Africa/Lubumbashi",
    label: "Africa/Lubumbashi",
  },
  {
    value: "Africa/Lusaka",
    label: "Africa/Lusaka",
  },
  {
    value: "Africa/Malabo",
    label: "Africa/Malabo",
  },
  {
    value: "Africa/Maputo",
    label: "Africa/Maputo",
  },
  {
    value: "Africa/Maseru",
    label: "Africa/Maseru",
  },
  {
    value: "Africa/Mbabane",
    label: "Africa/Mbabane",
  },
  {
    value: "Africa/Mogadishu",
    label: "Africa/Mogadishu",
  },
  {
    value: "Africa/Nairobi",
    label: "Africa/Nairobi",
  },
  {
    value: "Africa/Ndjamena",
    label: "Africa/Ndjamena",
  },
  {
    value: "Africa/Niamey",
    label: "Africa/Niamey",
  },
  {
    value: "Africa/Porto-Novo",
    label: "Africa/Porto-Novo",
  },
  {
    value: "Africa/Tripoli",
    label: "Africa/Tripoli",
  },
  {
    value: "Africa/Tunis",
    label: "Africa/Tunis",
  },
  {
    value: "Africa/Windhoek",
    label: "Africa/Windhoek",
  },
  {
    value: "Antarctica/Davis",
    label: "Antarctica/Davis",
  },
  {
    value: "Antarctica/Mawson",
    label: "Antarctica/Mawson",
  },
  {
    value: "Antarctica/Syowa",
    label: "Antarctica/Syowa",
  },
  {
    value: "Antarctica/Vostok",
    label: "Antarctica/Vostok",
  },
  {
    value: "Arctic/Longyearbyen",
    label: "Arctic/Longyearbyen",
  },
  {
    value: "Asia/Aden",
    label: "Asia/Aden",
  },
  {
    value: "Asia/Almaty",
    label: "Asia/Almaty",
  },
  {
    value: "Asia/Amman",
    label: "Asia/Amman",
  },
  {
    value: "Asia/Aqtau",
    label: "Asia/Aqtau",
  },
  {
    value: "Asia/Aqtobe",
    label: "Asia/Aqtobe",
  },
  {
    value: "Asia/Ashgabat",
    label: "Asia/Ashgabat",
  },
  {
    value: "Asia/Baghdad",
    label: "Asia/Baghdad",
  },
  {
    value: "Asia/Bahrain",
    label: "Asia/Bahrain",
  },
  {
    value: "Asia/Baku",
    label: "Asia/Baku",
  },
  {
    value: "Asia/Bangkok",
    label: "Asia/Bangkok",
  },
  {
    value: "Asia/Beirut",
    label: "Asia/Beirut",
  },
  {
    value: "Asia/Bishkek",
    label: "Asia/Bishkek",
  },
  {
    value: "Asia/Colombo",
    label: "Asia/Colombo",
  },
  {
    value: "Asia/Damascus",
    label: "Asia/Damascus",
  },
  {
    value: "Asia/Dhaka",
    label: "Asia/Dhaka",
  },
  {
    value: "Asia/Dubai",
    label: "Asia/Dubai",
  },
  {
    value: "Asia/Dushanbe",
    label: "Asia/Dushanbe",
  },
  {
    value: "Asia/Gaza",
    label: "Asia/Gaza",
  },
  {
    value: "Asia/Hebron",
    label: "Asia/Hebron",
  },
  {
    value: "Asia/Ho_Chi_Minh",
    label: "Asia/Ho_Chi_Minh",
  },
  {
    value: "Asia/Hovd",
    label: "Asia/Hovd",
  },
  {
    value: "Asia/Jakarta",
    label: "Asia/Jakarta",
  },
  {
    value: "Asia/Jerusalem",
    label: "Asia/Jerusalem",
  },
  {
    value: "Asia/Kabul",
    label: "Asia/Kabul",
  },
  {
    value: "Asia/Karachi",
    label: "Asia/Karachi",
  },
  {
    value: "Asia/Kathmandu",
    label: "Asia/Kathmandu",
  },
  {
    value: "Asia/Kolkata",
    label: "Asia/Kolkata",
  },
  {
    value: "Asia/Krasnoyarsk",
    label: "Asia/Krasnoyarsk",
  },
  {
    value: "Asia/Kuwait",
    label: "Asia/Kuwait",
  },
  {
    value: "Asia/Manila",
    label: "Asia/Manila",
  },
  {
    value: "Asia/Muscat",
    label: "Asia/Muscat",
  },
  {
    value: "Asia/Nicosia",
    label: "Asia/Nicosia",
  },
  {
    value: "Asia/Novokuznetsk",
    label: "Asia/Novokuznetsk",
  },
  {
    value: "Asia/Novosibirsk",
    label: "Asia/Novosibirsk",
  },
  {
    value: "Asia/Omsk",
    label: "Asia/Omsk",
  },
  {
    value: "Asia/Oral",
    label: "Asia/Oral",
  },
  {
    value: "Asia/Phnom_Penh",
    label: "Asia/Phnom_Penh",
  },
  {
    value: "Asia/Pontianak",
    label: "Asia/Pontianak",
  },
  {
    value: "Asia/Qatar",
    label: "Asia/Qatar",
  },
  {
    value: "Asia/Qyzylorda",
    label: "Asia/Qyzylorda",
  },
  {
    value: "Asia/Riyadh",
    label: "Asia/Riyadh",
  },
  {
    value: "Asia/Samarkand",
    label: "Asia/Samarkand",
  },
  {
    value: "Asia/Shanghai",
    label: "Asia/Shanghai",
  },
  {
    value: "Asia/Tashkent",
    label: "Asia/Tashkent",
  },
  {
    value: "Asia/Tbilisi",
    label: "Asia/Tbilisi",
  },
  {
    value: "Asia/Tehran",
    label: "Asia/Tehran",
  },
  {
    value: "Asia/Thimphu",
    label: "Asia/Thimphu",
  },
  {
    value: "Asia/Urumqi",
    label: "Asia/Urumqi",
  },
  {
    value: "Asia/Vientiane",
    label: "Asia/Vientiane",
  },
  {
    value: "Asia/Yekaterinburg",
    label: "Asia/Yekaterinburg",
  },
  {
    value: "Australia/Adelaide",
    label: "Australia/Adelaide",
  },
  {
    value: "Australia/Brisbane",
    label: "Australia/Brisbane",
  },
  {
    value: "Australia/Darwin",
    label: "Australia/Darwin",
  },
  {
    value: "Australia/Eucla",
    label: "Australia/Eucla",
  },
  {
    value: "Australia/Lord_Howe",
    label: "Australia/Lord_Howe",
  },
  {
    value: "Australia/Melbourne",
    label: "Australia/Melbourne",
  },
  {
    value: "Australia/Perth",
    label: "Australia/Perth",
  },
  {
    value: "Australia/Sydney",
    label: "Australia/Sydney",
  },
  {
    value: "Europe/Amsterdam",
    label: "Europe/Amsterdam",
  },
  {
    value: "Europe/Andorra",
    label: "Europe/Andorra",
  },
  {
    value: "Europe/Athens",
    label: "Europe/Athens",
  },
  {
    value: "Europe/Belgrade",
    label: "Europe/Belgrade",
  },
  {
    value: "Europe/Berlin",
    label: "Europe/Berlin",
  },
  {
    value: "Europe/Bratislava",
    label: "Europe/Bratislava",
  },
  {
    value: "Europe/Brussels",
    label: "Europe/Brussels",
  },
  {
    value: "Europe/Bucharest",
    label: "Europe/Bucharest",
  },
  {
    value: "Europe/Budapest",
    label: "Europe/Budapest",
  },
  {
    value: "Europe/Busingen",
    label: "Europe/Busingen",
  },
  {
    value: "Europe/Chisinau",
    label: "Europe/Chisinau",
  },
  {
    value: "Europe/Copenhagen",
    label: "Europe/Copenhagen",
  },
  {
    value: "Europe/Dublin",
    label: "Europe/Dublin",
  },
  {
    value: "Europe/Gibraltar",
    label: "Europe/Gibraltar",
  },
  {
    value: "Europe/Guernsey",
    label: "Europe/Guernsey",
  },
  {
    value: "Europe/Helsinki",
    label: "Europe/Helsinki",
  },
  {
    value: "Europe/Isle_of_Man",
    label: "Europe/Isle_of_Man",
  },
  {
    value: "Europe/Istanbul",
    label: "Europe/Istanbul",
  },
  {
    value: "Europe/Jersey",
    label: "Europe/Jersey",
  },
  {
    value: "Europe/Kaliningrad",
    label: "Europe/Kaliningrad",
  },
  {
    value: "Europe/Kiev",
    label: "Europe/Kiev",
  },
  {
    value: "Europe/Lisbon",
    label: "Europe/Lisbon",
  },
  {
    value: "Europe/Ljubljana",
    label: "Europe/Ljubljana",
  },
  {
    value: "Europe/London",
    label: "Europe/London",
  },
  {
    value: "Europe/Luxembourg",
    label: "Europe/Luxembourg",
  },
  {
    value: "Europe/Madrid",
    label: "Europe/Madrid",
  },
  {
    value: "Europe/Malta",
    label: "Europe/Malta",
  },
  {
    value: "Europe/Mariehamn",
    label: "Europe/Mariehamn",
  },
  {
    value: "Europe/Minsk",
    label: "Europe/Minsk",
  },
  {
    value: "Europe/Monaco",
    label: "Europe/Monaco",
  },
  {
    value: "Europe/Moscow",
    label: "Europe/Moscow",
  },
  {
    value: "Europe/Oslo",
    label: "Europe/Oslo",
  },
  {
    value: "Europe/Paris",
    label: "Europe/Paris",
  },
  {
    value: "Europe/Podgorica",
    label: "Europe/Podgorica",
  },
  {
    value: "Europe/Prague",
    label: "Europe/Prague",
  },
  {
    value: "Europe/Riga",
    label: "Europe/Riga",
  },
  {
    value: "Europe/Rome",
    label: "Europe/Rome",
  },
  {
    value: "Europe/Samara",
    label: "Europe/Samara",
  },
  {
    value: "Europe/San_Marino",
    label: "Europe/San_Marino",
  },
  {
    value: "Europe/Sarajevo",
    label: "Europe/Sarajevo",
  },
  {
    value: "Europe/Simferopol",
    label: "Europe/Simferopol",
  },
  {
    value: "Europe/Skopje",
    label: "Europe/Skopje",
  },
  {
    value: "Europe/Sofia",
    label: "Europe/Sofia",
  },
  {
    value: "Europe/Stockholm",
    label: "Europe/Stockholm",
  },
  {
    value: "Europe/Tallinn",
    label: "Europe/Tallinn",
  },
  {
    value: "Europe/Tirane",
    label: "Europe/Tirane",
  },
  {
    value: "Europe/Uzhgorod",
    label: "Europe/Uzhgorod",
  },
  {
    value: "Europe/Vaduz",
    label: "Europe/Vaduz",
  },
  {
    value: "Europe/Vatican",
    label: "Europe/Vatican",
  },
  {
    value: "Europe/Vienna",
    label: "Europe/Vienna",
  },
  {
    value: "Europe/Vilnius",
    label: "Europe/Vilnius",
  },
  {
    value: "Europe/Volgograd",
    label: "Europe/Volgograd",
  },
  {
    value: "Europe/Warsaw",
    label: "Europe/Warsaw",
  },
  {
    value: "Europe/Zagreb",
    label: "Europe/Zagreb",
  },
  {
    value: "Europe/Zaporozhye",
    label: "Europe/Zaporozhye",
  },
  {
    value: "Europe/Zurich",
    label: "Europe/Zurich",
  },
  {
    value: "Indian/Antananarivo",
    label: "Indian/Antananarivo",
  },
  {
    value: "Indian/Chagos",
    label: "Indian/Chagos",
  },
  {
    value: "Indian/Christmas",
    label: "Indian/Christmas",
  },
  {
    value: "Indian/Cocos",
    label: "Indian/Cocos",
  },
  {
    value: "Indian/Comoro",
    label: "Indian/Comoro",
  },
  {
    value: "Indian/Kerguelen",
    label: "Indian/Kerguelen",
  },
  {
    value: "Indian/Mahe",
    label: "Indian/Mahe",
  },
  {
    value: "Indian/Maldives",
    label: "Indian/Maldives",
  },
  {
    value: "Indian/Mauritius",
    label: "Indian/Mauritius",
  },
  {
    value: "Indian/Mayotte",
    label: "Indian/Mayotte",
  },
  {
    value: "Indian/Reunion",
    label: "Indian/Reunion",
  },
  {
    value: "Pacific/Auckland",
    label: "Pacific/Auckland",
  },
];

