import React, { useCallback, useEffect, useState } from 'react';
import { Col, Container, Row, Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Select } from '../reports/configurations/Select';
import { updateReportPeriod } from './OrganizationApi';
import { fetchUser, setLeavePrompt } from '../manage-users/usersSlice';
import { addMonths, subMonths } from 'date-fns';

const ManageReportPeriod = () => {
  const [isLoading, setIsLoading] = useState(false);
  const userInfo = useSelector((state) => state.users.user);
  const history = useHistory();
  const [usrOrgOptions, setUsrOrgOptions] = useState(userInfo?.usrOrgOptions);
  const currentPeriodType = usrOrgOptions?.optReportPeriodType || 0;
  const currentPeriodEnding = usrOrgOptions?.optReportPeriodEnding || 12;
  const [optReportPeriodType, setOptReportPeriodType] = useState(currentPeriodType);
  const [optReportPeriodEnding, setOptReportPeriodEnding] = useState(currentPeriodEnding);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isCanBack = useSelector((state) => state.users.historyStack.length > 1);
  const goBack = () => isCanBack ? history.goBack() : history.push('/');

  const onSubmitHandler = async () => {
    setIsLoading(true);
    try {
      await updateReportPeriod(userInfo.usrOrgId, {
        optReportPeriodType,
        optReportPeriodEnding,
      });
      dispatch(setLeavePrompt(false));
      setUsrOrgOptions({
        ...usrOrgOptions,
        optReportPeriodType,
        optReportPeriodEnding
      });
      dispatch(await fetchUser({ userKeycloakId: userInfo.usrKeycloakId }));
      toast.success(t('The report period has been saved.'));
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      toast.error(t('System error, please try again.'));
      setIsLoading(false);
    }
  };

  const reportYearTypeChangeHandler = (type) => {
    setOptReportPeriodType(type);
    if (!type) {
      setOptReportPeriodEnding(12);
    } else {
      setOptReportPeriodEnding(currentPeriodEnding);
    }
  };

  const reportPeriodChangeHandler = (event, isEnding) => {
    const value = Number(event.target.value);
    if (isEnding) {
      setOptReportPeriodEnding(value);
    } else {
      const period = getReportPeriod(value, false);
      setOptReportPeriodEnding(period);
    }
  };

  const getIsDirty = useCallback(() => {
    if ((usrOrgOptions?.optReportPeriodType || 0) !== optReportPeriodType
      || (usrOrgOptions?.optReportPeriodEnding || 12) !== optReportPeriodEnding) {
      return true;
    }
    return false;
  }, [usrOrgOptions, optReportPeriodType, optReportPeriodEnding]);

  useEffect(() => {
    const isDirty = getIsDirty();
    dispatch(setLeavePrompt(isDirty));
  }, [getIsDirty, dispatch]);

  const getReportPeriod = (value, isEnding) => {
    const monthString = `0${value}`.slice(-2);
    const objDate = new Date(`2023-${monthString}-01 00:00:00`);
    return isEnding
      ? subMonths(objDate, 11).getMonth() + 1
      : addMonths(objDate, 11).getMonth() + 1;
  };
  const reportMonthsList = () => {
    const yearList = [
      { value: 1, label: t('January') },
      { value: 2, label: t('February') },
      { value: 3, label: t('March') },
      { value: 4, label: t('April') },
      { value: 5, label: t('May') },
      { value: 6, label: t('June') },
      { value: 7, label: t('July') },
      { value: 8, label: t('August') },
      { value: 9, label: t('September') },
      { value: 10, label: t('October') },
      { value: 11, label: t('November') },
      { value: 12, label: t('December') },
    ];
    return yearList;
  };

  return (
    <Container fluid>
      <Row className="my-2 manage-report-page">
        <Col>
          <h4>{t('Manage Report Period')}</h4>
          <Row className="my-2 mt-2">
            <Col md={8}>
              <Form>
                <Row>
                  <Col>
                    <p><strong>{t('Default Report Period')}</strong></p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="custom-form-control">
                      <em>{t('Please select a report year type. The selected option will be applied as default to every report in the system.')}</em>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="custom-form-control">
                      <Form.Check
                        type="radio"
                        value="0"
                        checked={optReportPeriodType === 0}
                        label={t('Calendar year')}
                        onChange={() => reportYearTypeChangeHandler(0)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="custom-form-control">
                      <Form.Check
                        type="radio"
                        value="1"
                        checked={optReportPeriodType === 1}
                        label={t('Financial year')}
                        onChange={() => reportYearTypeChangeHandler(1)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p><strong>{t('Applied period')}</strong></p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="custom-form-control report-year-range">
                      <Select
                        register={() => { }}
                        disabled={optReportPeriodType === 0}
                        label={t('From')}
                        name="reportYearFrom"
                        value={getReportPeriod(optReportPeriodEnding, true)}
                        options={reportMonthsList()}
                        onChange={(e) => reportPeriodChangeHandler(e, false)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="custom-form-control report-year-range">
                      <Select
                        disabled={optReportPeriodType === 0}
                        register={() => { }}
                        value={optReportPeriodEnding}
                        label={t('To')}
                        name="reportYearTo"
                        options={reportMonthsList()}
                        onChange={(e) => reportPeriodChangeHandler(e, true)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <em>{t('Select the first month for your desired 12-month period.')}</em>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                className="mt-3 mb-5 mr-2"
                type="button"
                onClick={goBack}
                variant="secondary">
                {t('Cancel')}
              </Button>
              <Button
                onClick={onSubmitHandler}
                className="mt-3 mb-5"
                type="submit"
                disabled={isLoading || !getIsDirty()}>
                {t('Save')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default ManageReportPeriod;
