import { useEffect, useState } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { useWatch, Controller } from 'react-hook-form';
import { recursiveMap, recursiveFind } from '../../../utilities/treeHelpers';
import TreeViewModal from '../../../components/TreeViewModal';
import NodeIcon from '../../../components/NodeIcon';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { fetchSingleEmissionSourceData } from '../emissionProfileSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const EmissionOtherSourceLink = ({ control, setValue, tree, selectedEmissionSource, emissionProfile }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [showTreeSelector, setShowTreeSelector] = useState(false);
  const [selectedNodes, setSelectedNodes] = useState(null);

  const openModal = () => setShowTreeSelector(true);
  const closeModal = () => setShowTreeSelector(false);

  const eprflsrcOtherLinkedIds = useWatch({
    control,
    name: 'eprflsrcOtherLinkedIds'
  });

  const otherLinked = useWatch({
    control,
    name: 'otherLinked'
  });

  const setSelectedTarget = (n, removeIdx = null) => {
    const newNodes = [...selectedNodes ?? []];
    if (removeIdx === null) {
      newNodes.push(n);
    } else {
      newNodes.splice(removeIdx, 1);
    }
    setValue(
      'eprflsrcOtherLinkedIds',
      newNodes.map(n => n.eprflsrcId), { shouldDirty: true },
    );
    setSelectedNodes(newNodes);
  };

  const handleChangeLinkedEps = async (n, idx) => {
    if (idx !== -1) {
      setSelectedTarget(n, idx);
      return;
    }
    const isCustomProfile = emissionProfile?.eprflsrcEsrcId === null;
    if (isCustomProfile) {
      if (n?.eprflsrcConsumptionUnit === emissionProfile?.eprflsrcConsumptionUnit) {
        setSelectedTarget(n);
      } else {
        toast.error('No relevant emission factor found for given profile and unit');
      }

      if (emissionProfile?.eprflsrcEsrcId) {
        const epsEsrcId = emissionProfile.eprflsrcEsrcId;
        const resultAction = await dispatch(fetchSingleEmissionSourceData({ epsEsrcId }));
        const selectedES = unwrapResult(resultAction);
        const factor = selectedES.factors?.find((f) => f.unit === n.eprflsrcConsumptionUnit);
        if (!factor) {
          toast.error('No relevant emission factor found for given profile and unit');
        } else {
          setSelectedTarget(n);
        }
      }
    } else {
      if (selectedEmissionSource && n?.eprflsrcEsrc?.eprflsrcEsrcFactors) {
        const resultAction = await dispatch(fetchSingleEmissionSourceData({ selectedEmissionSource }));
        const selectedES = unwrapResult(resultAction);
        const contributionUnit = selectedES.factors.filter((obj1) =>
          n.eprflsrcEsrc?.eprflsrcEsrcFactors?.some((obj2) => obj1.unit === obj2.unit)
        );
        if (contributionUnit.length === 0) {
          toast.error('No relevant emission factor found for given profile and unit');
        } else {
          setSelectedTarget(n);
        }
      } else {
        if (selectedEmissionSource) {
          const resultAction = await dispatch(fetchSingleEmissionSourceData({ selectedEmissionSource }));
          const selectedES = unwrapResult(resultAction);
          const factor = selectedES.factors?.find((f) => f.unit === n.eprflsrcConsumptionUnit);
          if (!factor) {
            toast.error('No relevant emission factor found for given profile and unit');
          } else {
            setSelectedTarget(n);
          }
        } else {
          toast.error('No relevant emission factor found for given profile and unit');
        }
      }
    }
  };
  const recursiveTree = recursiveMap(tree ?? {}, (n) => {
    if (n.eprflId) {
      return {
        value: n.eprflId,
        labelRaw: n.eprflName,
        label: <span>{n.eprflName}</span>,
        isActive: false
      };
    }
    if (n.eprflcatId) {
      return {
        value: n.eprflcatId,
        labelRaw: n.eprflcatName,
        label: (
          <span>
            <NodeIcon node={n} />
            {n.eprflcatName}
          </span>
        ),
        showCheckbox: true,
        isActive: false
      };
    }
    if (n.eprflsrcId) {
      if (emissionProfile && n.eprflsrcId === emissionProfile.eprflsrcId) {
        return {
          value: n.eprflsrcId,
          labelRaw: n.eprflsrcName,
          label: (<span>{n.eprflsrcName}</span>),
          isActive: true,
        };
      }
      const idx = (selectedNodes || []).findIndex(item => item.eprflsrcId === n.eprflsrcId);
      return {
        value: n.eprflsrcId,
        labelRaw: n.eprflsrcName,
        label: (
          <span onClick={() => handleChangeLinkedEps(n, idx)}>
            <Form.Check custom checked={idx !== -1} label={n.eprflsrcName} />
          </span>
        ),
        isActive: eprflsrcOtherLinkedIds?.length && eprflsrcOtherLinkedIds.includes(n.eprflsrcId)
      };
    }
  });
  recursiveTree.isRoot = true;

  useEffect(() => {
    if (tree && eprflsrcOtherLinkedIds && eprflsrcOtherLinkedIds.length && selectedNodes === null) {
      const newNodes = eprflsrcOtherLinkedIds.map(id => {
        const found = recursiveFind(tree, (n) => n.eprflsrcId === parseInt(id));
        return found || { eprflsrcName: 'Error' };
      });
      setSelectedNodes(newNodes);
    }
  }, [eprflsrcOtherLinkedIds, selectedNodes, tree, setValue]);

  const renderSelectedNodes = () => {
    if (!selectedNodes || !selectedNodes.length) {
      return null;
    }
    return selectedNodes.map((item, idx) => (
      <Col md={12} style={{ paddingLeft: 0 }}>
        <span key={`selected-node-${idx}`} className="ml-3">
          {item.eprflsrcName}
        </span>
      </Col>
    ));
  };

  return (
    <>
      <Col>
        <Form.Group className="custom-form-control">
          <Controller
            name="otherLinked"
            control={control}
            render={({ field }) => {
              const parsed = field.value === 'Y' ? true : false;
              const format = () => {
                field.onChange(field.value === 'Y' ? 'N' : 'Y');
                setValue('eprflsrcOtherLinkedIds', []);
                setSelectedNodes([]);
              };
              return (
                <Form.Check
                  custom
                  id="otherLinked"
                  onChange={format}
                  checked={parsed}
                  label={t('Other Linked EPS')}
                />
              );
            }}
          />
        </Form.Group>
      </Col>
      {otherLinked === 'Y' && (
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Scope 1, 2 & 3 Linked EPS')}</Form.Label>
            <Row>
              <Col style={{ flexGrow: 0 }}>
                <Button onClick={openModal} variant="outline-secondary">
                  {t('Select')}
                </Button>
              </Col>
              <Col>
                <Row>
                  {renderSelectedNodes()}
                </Row>
              </Col>
            </Row>
          </Form.Group>
          <TreeViewModal
            tree={recursiveTree}
            closeModal={closeModal}
            show={showTreeSelector}
            title={t('Select Emission Profile')}
          />
        </Col>
      )}
    </>
  );
};

export default EmissionOtherSourceLink;
