import React, { useMemo } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { assignEntity, unAssignEntity, fetchAssignedEntities, fetchAvailableEntities } from './accountsSlice';
import DataTable from '../../components/DataTable';
import { IoAddOutline, IoTrashOutline } from 'react-icons/io5';

const AssignedEntities = ({ accountId }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const assignedEntities = useSelector((state) => state.accounts.assignedEntities);
  const availableEntities = useSelector((state) => state.accounts.availableEntities);
  const orgId = useSelector((state) => state.users.user.usrOrgId);

  const handleAssignEntity = async (entityId) => {
    try {
      setIsLoading(true);
      const resultAction = await dispatch(assignEntity({ orgId, accountId, entityId }));
      unwrapResult(resultAction);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleUnAssignEntity = async (entityId) => {
    try {
      setIsLoading(true);
      const resultAction = await dispatch(unAssignEntity({ orgId, accountId, entityId }));
      unwrapResult(resultAction);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const assignedEntitiesTableColumns = useMemo(
    () => [
      {
        Header: 'Entity Id',
        accessor: 'divAssignedId'
      },
      {
        Header: 'Entity',
        accessor: 'divName'
      },
      {
        Header: 'Parent',
        accessor: 'divParentName'
      },
      {
        Header: '',
        accessor: 'actions',
        actionColumn: true,
        disableGlobalFilter: false,
        disableSortBy: true,
        Cell: (props) => {
          const { handleUnAssignEntity, row } = props;
          return (
            <div className="d-flex action-buttons">
              <Button size="sm" variant="outline-secondary" onClick={() => handleUnAssignEntity(row.original.divId)}>
                <IoTrashOutline />
              </Button>
            </div>
          );
        }
      }
    ],
    []
  );

  const availableEntitiesTableColumns = useMemo(
    () => [
      {
        Header: 'Entity Id',
        accessor: 'divAssignedId'
      },
      {
        Header: 'Entity',
        accessor: 'divName'
      },
      {
        Header: 'Parent',
        accessor: 'divParentName'
      },
      {
        Header: '',
        accessor: 'actions',
        actionColumn: true,
        disableGlobalFilter: false,
        disableSortBy: true,
        Cell: (props) => {
          const { handleAssignEntity, row } = props;
          return (
            <div className="d-flex action-buttons">
              <Button size="sm" variant="outline-secondary" onClick={() => handleAssignEntity(row.original.divId)}>
                <IoAddOutline />
              </Button>
            </div>
          );
        }
      }
    ],
    []
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resultAction = await dispatch(fetchAssignedEntities({ orgId, accountId }));
        const resultAction2 = await dispatch(fetchAvailableEntities({ orgId, accountId }));
        unwrapResult(resultAction);
        unwrapResult(resultAction2);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [orgId, accountId, dispatch]);

  if (isLoading) return <Spinner animation="grow" />;

  return (
    <div>
      <h5 className="my-3 text-light">Assigned Entities</h5>
      <DataTable
        columns={assignedEntitiesTableColumns}
        data={assignedEntities}
        actions={{
          handleUnAssignEntity
        }}
      />
      <h5 className="mt-5 mb-3 text-light">Available Entities</h5>
      <DataTable
        columns={availableEntitiesTableColumns}
        data={availableEntities}
        actions={{
          handleAssignEntity
        }}
      />
    </div>
  );
};

export default AssignedEntities;
