import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { LoadingState } from '../../utilities/constants';
import EntityFrom from './EntityForm';
import { fetchEntityTree } from './entitySlice';
import { useTranslation } from 'react-i18next';
import { createEntityApi } from './entityApi';
import { toast } from 'react-toastify';

const NewEntity = () => {
  const [saveEntityState, setSaveEntityState] = useState(LoadingState.idle);
  const { entityId: parentId } = useParams();
  const entityStatus = useSelector((state) => state.entities.status);
  const orgId = useSelector((state) => state.users.user.usrOrgId);
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleCreateEntity = async (data) => {
    try {
      setSaveEntityState(LoadingState.loading);
      const result = await createEntityApi(orgId, parentId, data);
      dispatch(fetchEntityTree({ orgId }));
      toast.success(t('New entity is created'));
      history.push(`/admin/setup/entity/${result.divId}/edit`);
    } catch (error) {
      toast.error(error.message);
      setSaveEntityState(LoadingState.idle);
      throw error;
    }
  };

  const handleCancelEntity = () => {
    history.push('/admin/setup/entity');
  };

  useEffect(() => {
    if (entityStatus === LoadingState.idle) {
      dispatch(fetchEntityTree({ orgId }));
    }
  }, [orgId, entityStatus, dispatch]);

  return (
    <Container fluid>
      <Row className="my-2">
        <Col>
          <h4>{t('Add New Entity')}</h4>
          <Row className="my-2">
            <Col md={8}>
              <EntityFrom
                parentId={Number(parentId)}
                buttonText={t('Create')}
                onSubmit={handleCreateEntity}
                disabled={saveEntityState !== LoadingState.idle}
                onCancel={handleCancelEntity}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default NewEntity;
