import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { LoadingState } from '../../utilities/constants';
import {fetchOrganizationsApi, fetchReportUnitsApi} from "./OrganizationApi";


export const fetchOrganizations = createAsyncThunk('organizations/fetch', async () => {
  try {
    const response = await fetchOrganizationsApi();
    return response;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const fetchReportUnits = createAsyncThunk('organizations/report-units', async (orgId) => {
  try {
    const response = await fetchReportUnitsApi(orgId);
    return response;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

const organizationSlice = createSlice({
  name: 'organizations',
  initialState: {
    organizations: [],
    status: LoadingState.idle,
    unitStatus: LoadingState.idle,
    error: null
  },
  reportUnits: {},
  reducers: {},
  extraReducers: {
    [fetchOrganizations.pending]: (state) => {
      state.status = LoadingState.loading;
    },
    [fetchOrganizations.fulfilled]: (state, action) => {
      state.status = LoadingState.succeeded;
      state.organizations = action.payload;
    },
    [fetchOrganizations.rejected]: (state, action) => {
      state.status = LoadingState.failed;
      state.error = action.error.message;
    },
    [fetchReportUnits.pending]: (state) => {
      state.unitStatus = LoadingState.loading;
    },
    [fetchReportUnits.fulfilled]: (state, action) => {
      state.unitStatus = LoadingState.succeeded;
      state.reportUnits = action.payload;
    },
    [fetchReportUnits.rejected]: (state, action) => {
      state.unitStatus = LoadingState.failed;
      state.error = action.error.message;
    },
  }
});

export const selectOrganizationById = (state, id) => {
  if (!state.organizations.organizations?.length === 0) return null;
  const parsedId = parseInt(id);
  const found = state.organizations.organizations.find((a) => a.orgId === parsedId);
  return found ?? null;
};

export const convertToTree = (state) => ({ children: state.organizations.organizations });

export default organizationSlice.reducer;
