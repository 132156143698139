import React, { useMemo, useEffect, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { Button, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { IoTrashOutline } from 'react-icons/io5';
import { IoAddOutline } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';

import DataTable from '../../components/DataTable';
import { assignAsset, fetchAssignedAssets, fetchAvailableAssets, unAssignAsset } from './entitySlice';

const AssignAssets = ({ entityId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const assignedAssets = useSelector((state) => state.entities.assignedAssets);
  const availableAssets = useSelector((state) => state.entities.availableAssets);
  const orgId = useSelector((state) => state.users.user.usrOrgId);

  const handleAssignAsset = async ({ assetId }) => {
    try {
      setIsLoading(true);
      const resultAction = await dispatch(assignAsset({ orgId, entityId, assetId }));
      unwrapResult(resultAction);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleUnAssignAsset = async ({ assetId }) => {
    try {
      setIsLoading(true);
      const resultAction = await dispatch(unAssignAsset({ orgId, entityId, assetId }));
      unwrapResult(resultAction);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const assignedTableColumns = useMemo(
    () => [
      {
        Header: t('Asset ID'),
        accessor: 'assetAssignedId'
      },
      {
        Header: t('Asset Name'),
        accessor: 'assetName',
        sortType: 'string'
      },
      {
        Header: t('Description'),
        accessor: 'assetDesc',
        sortType: 'string'
      },
      {
        Header: '',
        accessor: 'actions',
        actionColumn: true,
        disableGlobalFilter: false,
        disableSortBy: true,
        Cell: (props) => {
          const { handleUnAssignAsset, row } = props;
          return (
            <div className="d-flex action-buttons">
              <Button size="sm" variant="outline-secondary" onClick={() => handleUnAssignAsset(row.original)}>
                <IoTrashOutline />
              </Button>
            </div>
          );
        }
      }
    ],
    [t]
  );

  const availableTableColumns = useMemo(
    () => [
      {
        Header: t('Asset ID'),
        accessor: 'assetAssignedId'
      },
      {
        Header: t('Asset Name'),
        accessor: 'assetName',
        sortType: 'string'
      },
      {
        Header: t('Description'),
        accessor: 'assetDesc',
        sortType: 'string'
      },
      {
        Header: '',
        accessor: 'actions',
        actionColumn: true,
        disableGlobalFilter: false,
        disableSortBy: true,
        Cell: (props) => {
          const { handleAssignAsset, row } = props;
          return (
            <div className="d-flex action-buttons">
              <Button size="sm" variant="outline-secondary" onClick={() => handleAssignAsset(row.original)}>
                <IoAddOutline />
              </Button>
            </div>
          );
        }
      }
    ],
    [t]
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resultAction = await dispatch(fetchAssignedAssets({ orgId, entityId }));
        const resultAction2 = await dispatch(fetchAvailableAssets({ orgId, entityId }));
        unwrapResult(resultAction);
        unwrapResult(resultAction2);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [orgId, entityId, dispatch]);

  if (isLoading) return <Spinner animation="grow" />;

  return (
    <div>
      <h5 className="my-3 text-light">{t('Assigned Assets')}</h5>
      <DataTable
        columns={assignedTableColumns}
        data={assignedAssets}
        actions={{
          handleUnAssignAsset
        }}
      />
      <h5 className="mt-5 mb-3 text-light">{t('Available Assets')}</h5>
      <DataTable
        columns={availableTableColumns}
        data={availableAssets}
        actions={{
          handleAssignAsset
        }}
      />
    </div>
  );
};

export default AssignAssets;
