import React from 'react';
import { useTranslation } from 'react-i18next';
import Empty from '../../../components/Empty';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

export const ErrorForecastPage = ({
  setShowBaseValueModal,
}) => {
  const { t } = useTranslation();

  return (
    <div className='forecast-list'>
      <Empty title={t('To calculate forecast emission reduction, emission data, action plans & target must be input.')} />
      <ul>
        <li><Link to={`/reduction-plan/goals`}>{t('Set your target')}</Link></li>
        <li><Link to={`/reduction-plan/actions`}>{t('Set your action plans')}</Link></li>
        <li><Link to={`/admin/settings/report-period`}>{t('Set your emission data')}</Link></li>
        <li><Link to='#' onClick={() => setShowBaseValueModal(true)}>{t('Use a temporary base value for emission data')}</Link></li>
      </ul>
    </div>
  );
};
export default ErrorForecastPage;
