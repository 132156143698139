import { unwrapResult } from '@reduxjs/toolkit';
import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import PageHeader from '../../components/PageHeader';
import BusinessMeasureForm from './BusinessMeasureForm';
import { createBusinessMeasure } from './businessMeasuresSlice';
import { useTranslation } from 'react-i18next';

const NewBusinessMeasure = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const orgId = useSelector((state) => state.users.user.usrOrgId);

  const handleCreateBsnsMeasure = async (data) => {
    try {
      setIsLoading(true);
      const resultAction = await dispatch(createBusinessMeasure({ orgId, data }));
      unwrapResult(resultAction);
      history.push('/admin/setup/business-measures');
    } catch {
      setIsLoading(false);
    }
  };

  const handleCancelAsset = () => {
    history.push('/admin/setup/business-measures');
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <PageHeader title={t('Create Business Measure')} />
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <BusinessMeasureForm
            onSubmit={handleCreateBsnsMeasure}
            buttonText={t('Create')}
            isLoading={isLoading}
            onCancel={handleCancelAsset}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default NewBusinessMeasure;
