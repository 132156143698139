import { Dropdown } from 'react-bootstrap';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable'
import html2canvas from 'html2canvas';
import { HiDownload } from 'react-icons/hi';
import { legend_configuration } from '../style-configurations';
import { exportCsv } from '../../../utilities/common';
import { useTranslation } from 'react-i18next';
import fZHeiFontSrc from '../../../assets/fonts/FZHei-B01-Regular.font';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { formatNumber } from '../reportHelper';

const Download = ({
  dataRef,
  chartRef,
  options = ['csv'],
  reportName = 'Report',
  reportSubTitle,
  isCanvas = true,
  notScroll = false,
  exportDataHandler = null,
  disabled,
  locale,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const dropdownItems = [];
  options.forEach((o, idx) => {
    switch (o) {
      case 'csv':
        dropdownItems.push(<Dropdown.Item key={`dwl-${idx}`} onClick={() => handleDownload(o)}>CSV</Dropdown.Item>);
        break;
      case 'pdf':
        dropdownItems.push(<Dropdown.Item key={`dwl-${idx}`} onClick={() => handleDownload(o)}>PDF</Dropdown.Item>);
        break;
      case 'png':
        dropdownItems.push(<Dropdown.Item key={`dwl-${idx}`} onClick={() => handleDownload(o)}>PNG</Dropdown.Item>);
        break;
      case 'jpeg':
        dropdownItems.push(<Dropdown.Item key={`dwl-${idx}`} onClick={() => handleDownload(o)}>JPEG</Dropdown.Item>);
        break;
      default:
    }
  });

  const imageGenerator = async (input) => {
    const canvas = await html2canvas(input, { scale: 1 });
    return canvas.toDataURL('image/png', 1.0);
  };

  const loadLocaleFont = async () => {
    const loadFont = async (fontSrc) => {
      const res = await fetch(fontSrc);
      const font = await res.text();
      return font;
    };
    switch (locale) {
      case 'zh_CN':
        const content = await loadFont(fZHeiFontSrc);
        return {
          name: 'FZHei-B01T',
          type: 'normal',
          content,
        };
      default:
        return null;
    }
  };

  const handleDownload = async (option) => {
    try {
      setIsLoading(true);
      await processDownload(option);
    } catch (e) {
      console.log(e);
      toast.error(t('Something went wrong'));
    } finally {
      setIsLoading(false);
    }
  };

  const createHiddenContainer = (pageWidth) => {
    const wrapper = document.createElement('div');
    wrapper.style.position = 'absolute';
    wrapper.style.top = '-10000px';
    wrapper.style.width = `${pageWidth}px`;
    return wrapper;
  };

  const convertToCanvas = async (doc) => {
    const header = document.querySelector('.report-header') || { style: {} };
    header.style.display = 'block';
    const canvasPromise = html2canvas(doc, { scale: 1 });
    header.style.display = 'none';
    return await canvasPromise;
  };

  const createReportTitleImage = async (pageWidth) => {
    const wrapper = createHiddenContainer(pageWidth);
    const title = document.createElement('h1');
    title.innerText = reportSubTitle;
    title.style.fontSize = '18px';
    title.style.float = 'left';
    wrapper.appendChild(title);
    let image;
    try {
      document.body.appendChild(wrapper);
      image = await imageGenerator(title);
    } finally {
      wrapper.remove();
    }
    return image;
  };

  const createTableImage = async (tableData, pageWidth) => {
    const wrapper = createHiddenContainer(pageWidth);
    const table = document.createElement('table');
    const tableBody = document.createElement('tbody');
    table.className = 'table table-bordered table-hover';
    table.style.fontSize = '12px';
    table.style.width = '100%';
    table.style.margin = '0 auto';
    tableData.forEach((rowData) => {
      const row = document.createElement('tr');
      rowData.forEach(function (cellData) {
        const cell = document.createElement('td');
        cell.appendChild(document.createTextNode(cellData));
        row.appendChild(cell);
      });
      tableBody.appendChild(row);
    });
    table.appendChild(tableBody);
    wrapper.appendChild(table);
    let image;
    try {
      document.body.appendChild(wrapper);
      image = await imageGenerator(wrapper);
    } finally {
      wrapper.remove();
    }
    return image;
  }

  const processDownload = async (option) => {
    let image;
    let anchor;
    let ctx;
    let canvas;

    if (!!isCanvas) {
      let doc = document.getElementById(dataRef.current.id);
      if (!notScroll) doc.querySelector('.chart-legend').style['height'] = 'auto';

      switch (option) {
        case 'csv':
          let csvRows = exportDataHandler ? exportDataHandler('csv') : null;
          if (!csvRows) {
            const { legendItems } = chartRef.current.chartInstance.legend;
            const chartData = chartRef.current.props.data.datasets.filter(
              (dataset) => legendItems.find(item => item.text === dataset.label)?.hidden !== true
            );
            csvRows = [['', t('Total'), t('Unit'), ...chartRef.current.props.data.labels,]];
            chartData.forEach((dataset) => {
              const { title, total, displayUnit } = dataset;
              csvRows.push([
                title,
                formatNumber(total),
                displayUnit,
                ...dataset.data.map(num => formatNumber(num))
              ]);
            });
          }
          exportCsv(csvRows, reportName);
          if (!notScroll) doc.querySelector('.chart-legend').style['height'] = legend_configuration.chart_legend_height;
          break;
        case 'pdf':
          const pdf = new jsPDF({ orientation: 'p', unit: 'pt', format: 'a4' });
          const width = pdf.internal.pageSize.getWidth();
          let pdfImage;
          const exportData = exportDataHandler ? exportDataHandler('pdf') : null;
          if (exportData) {
            pdfImage = await createTableImage(exportData, width);
          }
          if (!pdfImage) {
            pdfImage = await imageGenerator(doc);
          }
          const titleImage = await createReportTitleImage(width);
          pdf.addImage(titleImage, 'png', 20, 10);
          const imgProps = pdf.getImageProperties(pdfImage);
          const pdfHeight = (imgProps.height * (width - 40)) / imgProps.width;
          pdf.addImage(pdfImage, 'png', 20, 50, width - 40, pdfHeight);
          pdf.save(`${reportName}.pdf`);
          break;
        case 'png':
        case 'jpeg':
          canvas = await convertToCanvas(doc);
          image = canvas.toDataURL(`image/${option}`, 1.0).replace(`image/${option}`, 'image/octet-stream');
          anchor = document.createElement('a');
          document.body.appendChild(anchor);
          anchor.setAttribute('download', `${reportName}.${option}`);
          anchor.setAttribute('href', image);
          anchor.click();
          anchor.parentNode.removeChild(anchor);
          if (!notScroll)
            doc.querySelector('.chart-legend').style['height'] = legend_configuration.chart_legend_height;
          break;
        case 'svg':
          break;
        default:
      }
    } else {
      let source = document.getElementById(chartRef.current.id);
      const rows = [].slice.call(chartRef.current.rows).map((row) => row.innerText.split('\t'));
      switch (option) {
        case 'csv':
          exportCsv(rows, reportName);
          break;
        case 'pdf':
          const pdf = new jsPDF({ orientation: 'l', unit: 'pt', format: 'a4' });
          const font = await loadLocaleFont();
          const defaultStyle = {};
          if (font) {
            pdf.addFileToVFS(`${font.name}.ttf`, font.content);
            pdf.addFont(`${font.name}.ttf`, font.name, font.type);
            pdf.setFont(font.name);
            defaultStyle.font = font.name;
          }
          autoTable(pdf, {
            html: source,
            styles: {
              ...defaultStyle,
              fontStyle: 'normal',
            },
            headStyles: {
              lineWidth: 0.25,
              lineColor: 200,
              fontSize: 9,
              fontStyle: 'normal',
            },
            bodyStyles: {
              lineWidth: 0.25,
              lineColor: 200,
              fontSize: 8
            },
            didDrawPage: function (data) {
              pdf.setFontSize(12);
              pdf.setTextColor(40);
              pdf.text(reportSubTitle, pdf.internal.pageSize.getWidth() / 2, 20, {
                align: 'center'
              });
            }
          });
          pdf.save(`${reportName}.pdf`);
          break;
        case 'png':
        case 'jpeg':
          source.classList.remove('responsive-table');
          canvas = await convertToCanvas(source);
          ctx = canvas.getContext('2d');
          ctx.globalCompositeOperation = 'destination-over';
          ctx.fillStyle = 'white';
          ctx.fillRect(0, 0, canvas.width, canvas.height);
          image = canvas.toDataURL('image/jpeg', 1.0).replace(`image/${option}`, 'image/octet-stream');
          anchor = document.createElement('a');
          document.body.appendChild(anchor);
          anchor.setAttribute('download', `${reportName}.${option}`);
          anchor.setAttribute('href', image);
          anchor.click();
          anchor.parentNode.removeChild(anchor);
          source.classList.add('responsive-table');
          break;
        case 'svg':
          break;
        default:
      }
    }
  };

  return (
    <Dropdown className="ml-3">
      <Dropdown.Toggle
        variant="secondary"
        className="d-flex align-items-center justify-content-center"
        disabled={!chartRef || isLoading || disabled}
      >
        <div className="mr-3">
          <HiDownload fontSize={17} />
        </div>
        {t('Download As')}
      </Dropdown.Toggle>
      <Dropdown.Menu>{dropdownItems}</Dropdown.Menu>
    </Dropdown>
  );
};

export default Download;
