import { unwrapResult } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAssignedAssets, getAvailableAssets, assignMultiAssets } from './emissionProfileSlice';
import DataMultiAssignment from '../../components/DataMultiAssignment';
import { useTranslation } from 'react-i18next';

const AssignAssets = ({ emissionProfileSourceId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const availableAssets = useSelector((state) => state.emissionProfiles.availableAssets);
  const assignedAssets = useSelector((state) => state.emissionProfiles.assignedAssets);
  const orgId = useSelector((state) => state.users.user.usrOrgId);

  const handleAssignData = async (actionType, assetIds) => {
    try {
      setIsLoading(true);
      const data = { type: actionType === 'Assign' ? 'ADD' : 'REMOVE', assetIds };
      const resultAction = await dispatch(assignMultiAssets({ orgId, emissionProfileSourceId, ...data }));
      unwrapResult(resultAction);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    dispatch(getAvailableAssets({ orgId, emissionProfileSourceId }));
    dispatch(getAssignedAssets({ orgId, emissionProfileSourceId }));
  }, [orgId, emissionProfileSourceId, dispatch]);

  return (
    <DataMultiAssignment
      isLoading={isLoading}
      multiTitle={t("Assets")}
      singleTitle={t("Asset")}
      dataKey="assetId"
      dataName="assetName"
      dataSearch="assetAssignedId"
      availableData={availableAssets}
      assignedData={assignedAssets}
      handleAssignData={handleAssignData}
    />
  );
};

export default AssignAssets;
