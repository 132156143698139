import {config} from "./config";
import axios from "axios";
import keycloak from '../keycloak';
import qs from "qs";
import { format } from "date-fns";
import { toast } from "react-toastify";

const baseURL = `${config.API_BASE_URL}`;

export async function put(url, data, authenticationRequired = false) {
    let options = {
        data,
        baseURL,
        url,
        method: 'put',
        responseType: 'json'
    };

    const token = getToken();
    options = await addRequestHeaders(options, authenticationRequired, token);
    return axios(options);
}

export async function patch(url, data, authenticationRequired = false) {
    let options = {
        data,
        baseURL,
        url,
        method: 'patch',
        responseType: 'json'
    };

    const token = getToken();
    options = await addRequestHeaders(options, authenticationRequired, token);
    return axios(options);
}

export async function post(url, data, authenticationRequired = false) {
    let options = {
        data,
        baseURL,
        url,
        method: 'post',
        responseType: 'json'
    };

    const token = getToken();
    options = await addRequestHeaders(options, authenticationRequired, token);
    return axios(options);
}

export async function get(url, authenticationRequired = false) {
    let options = {
        baseURL,
        url,
        method: 'get',
        responseType: 'json'
    }

    const token = getToken();
    options = await addRequestHeaders(options, authenticationRequired, token);
    return axios(options);
}

export async function remove(url, authenticationRequired = false) {
    let options = {
        baseURL,
        url,
        method: 'delete',
        responseType: 'json'
    }

    const token = getToken();
    options = await addRequestHeaders(options, authenticationRequired, token);
    return axios(options);
}

async function addRequestHeaders(options, authenticationRequired, token) {
    options.headers = {};
    if (authenticationRequired) {
        options.headers.Authorization = `Bearer ${token}`;
    }
    return options;
}

export async function uploadToS3(signedPost, file) {
    const formData = new FormData();
    Object.entries(signedPost.fields).forEach(([field, value]) => {
      formData.append(field, value);
    });
    formData.append('file', file); 
    const response = await fetch(signedPost.url, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
      body: formData
    });
    if (!response.ok) throw new Error('Upload Failed. Please try again.');
    return response;
  }

export function getToken() {
    return keycloak.token;
}

let isInitRedirect = false;
let isRedirecting = false;
export function initRedirectToLoginOnfetch(t) {
  if (isInitRedirect) {
    return;
  }
  isInitRedirect = true;
  const originalFetch = window.fetch;
  const redirectIfUnauthorized = (status, headers) => {
    if (status === 401 && headers?.Authorization && !isRedirecting) {
      keycloak.logout();
      isRedirecting = true;
      alert(t('You are being logged out due to inactivity'));
    }
  };
  window.fetch = async function () {
    const res = await originalFetch.apply(this, arguments);
    redirectIfUnauthorized(res.status, arguments[1]?.headers);
    return res;
  };
  axios.interceptors.response.use((res) => res, (error) => {
    const { config, status } = error?.response || {};
    redirectIfUnauthorized(status, config?.headers);
    return error;
  });
}

axios.interceptors.request.use((config) => {
    config.paramsSerializer = (params) => qs.stringify(params, {
        serializeDate: (date) => format(date, "yyyy-MM-dd'T'HH:mm:ss") });
    return config;
});

// @todo remove the duplicate code of old devs in future
export async function apiRequest(method, url, body = null, isToastError = true, extraParams = {}) {
  try {
    if (body) {
      extraParams.body = JSON.stringify(body);
    }
    const response = await fetch(
      `${config.API_BASE_URL}${url}`,
      {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${keycloak.token}`
        },
        ...extraParams,
      },
    );
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message);
    }
    return data;
  } catch (err) {
    if (isToastError) {
      toast.error(err.message);
    }
    throw err;
  }
};
