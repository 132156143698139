import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import { datePickerValue, parseUserInfo } from '../../utilities/common';
import { IoAddCircleOutline } from 'react-icons/io5';
import { useSelector } from 'react-redux';

const AddDataControl = ({ onSubmit, isLoading = false }) => {
  const [addStartDate, setAddStartDate] = useState(null);
  const [addEndDate, setAddEndDate] = useState(null);
  const { usrDateFormat } = parseUserInfo(useSelector((state) => state.users.user));

  const handleChangeStart = (startDate) => {
    setAddStartDate(startDate);
  };

  const handleChangeEnd = (endDate) => {
    setAddEndDate(endDate);
  };

  const handleSubmit = () => {
    onSubmit(addStartDate, addEndDate);
    setAddStartDate(null);
    setAddEndDate(null);
  };

  return (
    <tfoot>
      <tr>
        <td>
          <ReactDatePicker
            isClearable
            className="form-control"
            dateFormat={usrDateFormat}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            maxDate={addEndDate || null}
            {...datePickerValue(addStartDate, handleChangeStart)}
          />
        </td>
        <td>
          <ReactDatePicker
            isClearable
            className="form-control"
            dateFormat={usrDateFormat}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            minDate={addStartDate || null}
            {...datePickerValue(addEndDate, handleChangeEnd)}
          />
        </td>
        <td>
          <div>
            <Button disabled={!addStartDate || !addEndDate || isLoading} variant="dark" className="table-add-button" onClick={handleSubmit}>
              <IoAddCircleOutline />
            </Button>
          </div>
        </td>
      </tr>
    </tfoot>
  );
};

export default AddDataControl;
