import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import PageHeader from '../../components/PageHeader';
import { LoadingState } from '../../utilities/constants';
import DataTable from '../../components/DataTable';
import {
  fetchEntityGroups,
  fetchAssignedEntitiesForEG,
  createEntityGroup,
  removeEntityGroup,
  selectEntityGroupById,
  editEntityGroup
} from './entityGroupsSlice';
import Select from 'react-select';
import { BsPencil } from 'react-icons/bs';
import { IoTrashOutline } from 'react-icons/io5';
import { unwrapResult } from '@reduxjs/toolkit';
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ManageEntityGroups = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const { egId } = useParams();
  const history = useHistory();
  const { t } = useTranslation();

  const assignmentStatus = useSelector((state) => state.entityGroups.assignmentStatus);
  const entityGroups = useSelector((state) => state.entityGroups.entityGroups);
  const assignedEntitiesToEntityGroup = useSelector((state) => state.entityGroups.assignedEntitiesToEntityGroup);
  const [showEditEGModal, setShowEditEGModal] = useState(false);
  const [showCreateEGModal, setShowCreateEGModal] = useState(false);
  const [showDeleteEGModal, setShowDeleteEGModal] = useState(false);
  const [selectedEGId, setSelectedEGId] = useState(egId);
  const [selectedEGName, setSelectedEGName] = useState();
  const [editEGName, setEditEGName] = useState();
  const [isCreatingEG, setIsCreatingEG] = useState(false);
  const [isEditingEG, setIsEditingEG] = useState(false);
  const entityGroup = useSelector((state) => selectEntityGroupById(state, egId));
  const orgId = useSelector((state) => state.users.user.usrOrgId);
  const loggedUserStatus = useSelector((state) => state.users.loggedUserStatus);

  const canCreateEG = selectedEGName && !isCreatingEG;
  const canEditEG = editEGName && !isEditingEG;

  const openCreateModal = () => setShowCreateEGModal(true);
  const openEditModal = () => {
    setEditEGName(entityGroup.egName);
    setShowEditEGModal(true);
  };
  const openDeleteModal = () => setShowDeleteEGModal(true);
  const closeCreateModal = () => {
    setSelectedEGName(null);
    setShowCreateEGModal(false);
  };
  const closeEditModal = () => {
    setEditEGName(null);
    setShowEditEGModal(false);
  };
  const closeDeleteModal = () => {
    setShowDeleteEGModal(false);
  };

  const assignedEntitiesGroupsTableColumns = useMemo(
    () => [
      {
        Header: t('Entity'),
        accessor: 'divName',
        sortType: 'string'
      },
      {
        Header: t('First Level Parent Entity'),
        accessor: 'divTopDivName',
        sortType: 'string'
      },
      {
        Header: t('Parent Entity'),
        accessor: 'divParentName',
        sortType: 'string'
      }
    ],
    [t]
  );

  const handleInputChange = async (event) => {
    const egId = event.value;
    setSelectedEGId(egId);
    const resultAction = await dispatch(fetchAssignedEntitiesForEG({ orgId, egId }));
    unwrapResult(resultAction);
    history.push(`/admin/settings/entity-groups/${egId}`);
  };

  const handleCreateEntityGroup = async () => {
    setIsCreatingEG(true);
    try {
      const data = {
        egName: selectedEGName
      };
      const resultAction = await dispatch(createEntityGroup({ orgId, data }));
      const resultAction2 = await dispatch(fetchEntityGroups({ orgId }));
      const egId = resultAction.payload.egId;
      const resultAction3 = await dispatch(fetchAssignedEntitiesForEG({ orgId, egId }));
      unwrapResult(resultAction);
      unwrapResult(resultAction2);
      unwrapResult(resultAction3);
      closeCreateModal();
      setSelectedEGName(null);
      history.push(`/admin/settings/entity-groups/${egId}`);
    } catch (error) {
    } finally {
      setIsCreatingEG(false);
    }
  };

  const handleEditEntityGroup = async () => {
    setIsEditingEG(true);
    try {
      const data = {
        egName: editEGName
      };
      const egId = entityGroup.egId;
      const resultAction = await dispatch(editEntityGroup({ orgId, egId, data }));
      unwrapResult(resultAction);
      closeEditModal();
      setEditEGName(null);
    } catch (error) {
    } finally {
      setIsEditingEG(false);
    }
  };

  const handleDeleteEntityGroup = async () => {
    try {
      const egId = entityGroup.egId;
      const resultAction = await dispatch(removeEntityGroup({ orgId, egId }));
      unwrapResult(resultAction);
      closeDeleteModal();
      history.push(`/admin/settings/entity-groups`);
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    if (assignmentStatus === LoadingState.idle && loggedUserStatus === LoadingState.succeeded) {
      dispatch(fetchEntityGroups({ orgId }));
      dispatch(fetchAssignedEntitiesForEG({ orgId, egId }));
    }
  }, [orgId, loggedUserStatus, assignmentStatus, egId, dispatch]);

  const entityGroupsOptions = entityGroups.map((value) => {
    return { value: value.egId, label: value.egName };
  });

  return (
    <Container fluid>
      <Row>
        <Col>
          {entityGroups?.length > 0 && (
            <PageHeader
              title={t('Manage Entity Group')}
              actions={
                <div className="d-flex align-items-center form-group">
                  <span className="mr-4 form-label">{t('Entity Groups')}</span>
                  <Select
                    className="select"
                    options={entityGroupsOptions}
                    value={
                      selectedEGId && entityGroup
                        ? entityGroupsOptions.filter(function (e) {
                            return e.value === entityGroup.egId;
                          })
                        : entityGroupsOptions[0]
                    }
                    defaultValue={
                      selectedEGId && entityGroup
                        ? entityGroupsOptions.filter(function (e) {
                            return e.value === entityGroup.egId;
                          })
                        : entityGroupsOptions[0]
                    }
                    onChange={handleInputChange}
                  />
                </div>
              }
            />
          )}
          {entityGroups?.length > 0 && (
            <div className="d-flex flex-row align-items-center justify-content-between my-4 pageheader page-header">
              {entityGroup ? (
                <div className="d-flex align-items-center action-buttons">
                  <h5 className="mb-0 mr-3 font-weight-bold">{entityGroup.egName}</h5>
                  <span className="page-header-edit-icon mr-2" onClick={openEditModal}>
                    <BsPencil size={15} className="mt-1" />
                  </span>
                  <span className="page-header-edit-icon" onClick={openDeleteModal}>
                    <IoTrashOutline size={15} className="mt-1" />
                  </span>
                </div>
              ) : (
                <div className="d-flex align-items-center action-buttons" />
              )}
              <div className="pageheader__actions">
                <Button onClick={openCreateModal}>+ {t('Create Group')}</Button>
              </div>
            </div>
          )}
        </Col>
      </Row>
      {assignmentStatus === LoadingState.loading && <Spinner animation="grow" />}
      {assignmentStatus === LoadingState.succeeded && entityGroups && (
        <Row>
          <Col>
            <DataTable
              columns={assignedEntitiesGroupsTableColumns}
              data={assignedEntitiesToEntityGroup}
              headerActions={
                <Button variant="secondary" to={`${match.url}/assign-entities`} as={Link}>
                  {t('Assign Entities')}
                </Button>
              }
            />
          </Col>
        </Row>
      )}
      <Modal show={showCreateEGModal} onHide={closeCreateModal} className="sisde-modal">
        <Modal.Header>
          <Modal.Title>{t('New Entity Group')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="custom-form-control">
              <Form.Label>{t('Group Name')} *</Form.Label>
              <Form.Control value={selectedEGName} onChange={(e) => setSelectedEGName(e.target.value)} type="text" />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeCreateModal}>
            {t('Cancel')}
          </Button>
          <Button variant="primary" onClick={handleCreateEntityGroup} disabled={!canCreateEG}>
            {t('Create')}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showEditEGModal} onHide={closeEditModal} className="sisde-modal">
        <Modal.Header>
          <Modal.Title>{t('Edit Entity Group')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="custom-form-control">
              <Form.Label>{t('Group Name')} *</Form.Label>
              <Form.Control value={editEGName} onChange={(e) => setEditEGName(e.target.value)} type="text" />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeEditModal}>
            {t('Cancel')}
          </Button>
          <Button variant="primary" onClick={handleEditEntityGroup} disabled={!canEditEG}>
            {t('Save')}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showDeleteEGModal} onHide={closeDeleteModal} className="sisde-modal">
        <Modal.Header closeButton>
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>{'Are you sure you want to delete this entity group?'}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeDeleteModal}>
            {t('Close')}
          </Button>
          <Button type="submit" variant="danger" onClick={handleDeleteEntityGroup}>
            {t('Delete')}
          </Button>
        </Modal.Footer>
      </Modal>
      {assignmentStatus === LoadingState.failed && <div>No such entity found</div>}
    </Container>
  );
};

export default ManageEntityGroups;
