import { get, post, put, remove } from '../../utilities/apiHelper';

// Create | Update KPI setups
export const createKpiSetupsApi = async (requestData) => {
  const { orgId } = requestData;
  try {
    const result = await post(`/disclosure/${orgId}/sdgs/kpi-setups`, requestData, true);
    if (!result.data) throw new Error(result.data.message);
    return result.data;
  } catch (error) {
    throw error;
  }
};

// Get all sdgs by Org
export const getSdgDataByOrgApi = async (requestData) => {
  try {
    let orgId = requestData;

    const result = await get(`/disclosure/${orgId}/sdgs`, true);
    if (!result.data) throw new Error(result.data.message);
    return result.data;
  } catch (error) {
    throw error;
  }
};

// Get all kpi data by Org
export const getKpiDataByOrgApi = async (requestData) => {
  try {
    let orgId = requestData;
    const result = await get(`/disclosure/${orgId}/sdgs/kpi-data`, true);
    if (!result.data) throw new Error(result.data.message);
    return result.data;
  } catch (error) {
    throw error;
  }
};

// Get kpi data by kpiSetupId
export const getKpiDataBySetupApi = async (requestData) => {
  const { orgId, kpiSetupId } = requestData;
  try {
    const result = await get(`/disclosure/${orgId}/sdgs/kpi-setups/${kpiSetupId}/kpi-data`, true);
    if (!result.data) throw new Error(result.data.message);
    return result.data;
  } catch (error) {
    throw error;
  }
};

// Create KPI data
export const createKpiDataApi = async (requestData) => {
  const { orgId } = requestData;
  try {
    const result = await post(`/disclosure/${orgId}/sdgs/kpi-data`, requestData, true);
    if (!result.data) throw new Error(result.data.message);
    return result.data;
  } catch (error) {
    throw error;
  }
};

// Create KPI data
export const updateKpiDataApi = async (requestData) => {
  const { orgId } = requestData;
  try {
    const result = await put(`/disclosure/${orgId}/sdgs/kpi-data`, requestData, true);
    if (!result.data) throw new Error(result.data.message);
    return result.data;
  } catch (error) {
    throw error;
  }
};

// Create KPI data
export const deleteKpiDataApi = async (requestData) => {
  const { orgId, kpiId } = requestData;

  try {
    const result = await remove(`/disclosure/${orgId}/sdgs/kpi-data/${kpiId}`, requestData, true);
    if (!result.data) throw new Error(result.data.message);
    return result.data;
  } catch (error) {
    throw error;
  }
};
