import keycloak from '../../keycloak';
import { config } from '../../utilities/config';

export async function fetchUnitListApi() {
  const response = await fetch(`${config.API_BASE_URL}/energycontent-factor/unit-list`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    }
  });
  const data = await response.json();
  if (!response.ok) throw new Error(data.message);
  return data;
}

export async function fetchEnergyContentFactorUploadsApi() {
  const response = await fetch(`${config.API_BASE_URL}/energycontent-factor/list`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    }
  });
  const data = await response.json();
  if (!response.ok) throw new Error(data.message);
  return data;
}

export async function createEnergyContentFactorApi(data) {
  const response = await fetch(`${config.API_BASE_URL}/energycontent-factor/create`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function updateEnergyContentFactorApi(id, data) {
  const response = await fetch(`${config.API_BASE_URL}/energycontent-factor/${id}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function removeEnergyContentFactorApi(id) {
  const response = await fetch(`${config.API_BASE_URL}/energycontent-factor/${id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    }
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}
