import { useTranslation } from "react-i18next";
import { config } from "../../../../utilities/config";
import { Button } from "react-bootstrap";

const GetStartedNow = () => {
  const { t } = useTranslation();
  return (
    <Button
      variant="success"
      href={config.CORIOLIS_STARTED_URL}
      className="mb-2 pl-4 pr-4"
      target="_blank">
      {t('Get Started Now')}
    </Button>
  );
};

export default GetStartedNow;
