import _ from 'lodash';
import {
  registerLocale,
  setDefaultLocale
} from 'react-datepicker';
import { setDefaultOptions, isMatch, parse, format, startOfDay } from 'date-fns';
import enAU from 'date-fns/locale/en-AU';
import zhCN from 'date-fns/locale/zh-CN';

const localeMapping = {
  'en': enAU,
  'zh_CN': zhCN,
};

export const defaultDateFormat = 'dd/MM/yyyy';
export const fullDateFormat = 'yyyy-MM-dd HH:mm:ss';

let currentDateFormat = defaultDateFormat;

export const initAppLocale = (defaultLocale) => {
  _.values(localeMapping).map(locale => registerLocale(locale));
  setAppLocale(defaultLocale);
};

export const setAppLocale = (locale) => {
  const dateLocale = localeMapping[locale] ? localeMapping[locale] : localeMapping.en;
  setDefaultOptions({ locale: dateLocale });
  setDefaultLocale();
};

export const parseUserInfo = (userInfo) => {
  const parsedInfo = {
    ...userInfo,
    orgId: userInfo.usrOrgId,
    userId: userInfo.usrId,
    usrDateFormat: userInfo.usrDateFormat || defaultDateFormat,
    usrTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  currentDateFormat = parsedInfo.usrDateFormat;
  return parsedInfo;
};

export const convertToDate = (date) => _.isString(date) && isMatch(date, defaultDateFormat)
  ? parse(date, defaultDateFormat, new Date())
  : new Date(date);

export const formatDate = (date, defaultValue = '') => {
  if (!date) {
    return defaultValue;
  }
  return format(convertToDate(date), currentDateFormat);
};


export const modifyDate = (dateObject, newObject) => {
  dateObject.setTime(newObject.getTime());
  return dateObject;
};

export const downloadCsv = (csvContent, fileName) => {
  const csvBlob = new Blob([csvContent], { type: 'text/csv' });
  const blobUrl = URL.createObjectURL(csvBlob);
  const anchor = document.createElement('a');
  document.body.appendChild(anchor);
  anchor.href = blobUrl;
  anchor.setAttribute('download', fileName);
  anchor.click();
  anchor.parentNode.removeChild(anchor);
};

export const displayTimeFromSeconds = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const displayNumber = (number) => `0${number}`.slice(-2);
  return `${displayNumber(minutes)}:${displayNumber(seconds - minutes * 60)}`;
};

export const exportCsv = (rows, fileName) => {
  const parseRow = (row) => row.map(text => {
    const val = _.trim(String(text), "\n")
      .replace(/"/g, '""')
      .replace(/[\n\r]/g, ' ');
    if (val.includes(',')) {
      return `"${val}"`;
    }
    return val;
  }).join(',');
  const csvContent = rows.map(parseRow).join('\n');
  downloadCsv(csvContent, fileName);
};

export const datePickerClass = (isInvalid) => {
  if (isInvalid) {
    return {
      className: 'form-control is-invalid',
      wrapperClassName: 'is-invalid',
    };
  }
  return {
    className: 'form-control',
    wrapperClassName: 'is-valid',
  };
};

export const datePickerValue = (current, callback) => {
  const onChangeHandler = (dt) => {
    const val = dt && !isNaN(dt) ? format(startOfDay(dt), fullDateFormat) : null;
    callback(val);
  };
  return {
    selected: current ? new Date(current) : null,
    onChange: onChangeHandler
  };
};

export const numericKeydownHandler = (event) => {
  if (![8, 37, 39, 46].includes(event.keyCode) &&
    !/^-?\d*\.?\d*$/.test(`${event.target.value}${event.key}`)) {
    event.preventDefault();
  }
};

export const getMonthDateFormat = (dateFormat) => {
  const matchRegx = dateFormat.match(/[-./]/);
  if (!matchRegx) {
    return dateFormat;
  }
  return dateFormat.split(matchRegx[0])
    .filter(path => path !== 'dd')
    .join(matchRegx[0]);
};
