import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Spinner, Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import ReactDatePicker from 'react-datepicker';
import { datePickerValue, formatDate, parseUserInfo } from '../../utilities/common';
import Select from 'react-select';
import DataTable, { getTargetUpdateValue, isEnableUpdateBtn } from '../../components/DataTable';
import AddEntityBusinessMeasure from './AddEntityBusinessMeasure';
import {
  fetchEntityBusinessMeasures,
  assignEntityBusinessMeasure,
  updateEntityBusinessMeasure,
  removeEntityBusinessMeasure
} from './entityBusinessMeasureSlice';
import { fetchBusinessMeasures } from '../business-measure/businessMeasuresSlice';
import { LoadingState } from '../../utilities/constants';

import { AiOutlineEdit } from 'react-icons/ai';
import { IoTrashOutline } from 'react-icons/io5';
import { IoCloseOutline } from 'react-icons/io5';
import { IoSaveOutline } from 'react-icons/io5';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const ManageEntityBusinessMeasure = ({ entityId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const entityBusinessMeasuresStatus = useSelector((state) => state.entityBusinessMeasures.status);
  const entityBusinessMeasures = useSelector((state) => state.entityBusinessMeasures.entityBusinessMeasures);
  const businessMeasuresStatus = useSelector((state) => state.businessMeasures.status);
  const businessMeasures = useSelector((state) => state.businessMeasures.businessMeasures);
  const { orgId, usrDateFormat } = parseUserInfo(useSelector((state) => state.users.user));
  const loggedUserStatus = useSelector((state) => state.users.loggedUserStatus);

  const getBusinessMeasuresOptions = useCallback(() => {
    return businessMeasures.map((businessMeasure) => {
      return { value: businessMeasure.bsnsmsrtypId, label: businessMeasure.bsnsmsrtypName };
    });
  }, [businessMeasures]);

  const getBusinessMeasureName = useCallback(
    (bsnsmsrtypId) => {
      for (let i = 0; i < businessMeasures.length; i++) {
        if (businessMeasures[i].bsnsmsrtypId === bsnsmsrtypId) return businessMeasures[i].bsnsmsrtypName;
      }
    },
    [businessMeasures]
  );

  const addEntityBuisnessMeasure = async (bsnsmsrtypId, startDate, divData) => {
    try {
      if (bsnsmsrtypId && startDate && divData) {
        const data = {
          bsnsmsrBsnsmsrtypId: bsnsmsrtypId,
          bsnsmsrStartDate: startDate,
          bsnsmsrDivData: parseInt(divData)
        };
        setIsLoading(true);
        const resultAction = await dispatch(assignEntityBusinessMeasure({ orgId, entityId, data }));
        unwrapResult(resultAction);
        dispatch(fetchEntityBusinessMeasures({ orgId, entityId }));
        dispatch(fetchBusinessMeasures({ orgId }));
      } else {
        toast.error("Business Measures Data Can't Be Empty");
      }
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };
  const handleDeleteEntityBusinessMeasure = async ({ bsnsmsrId }) => {
    try {
      setIsLoading(true);
      const resultAction = await dispatch(removeEntityBusinessMeasure({ orgId, entityId, bsnsmsrId }));
      unwrapResult(resultAction);
      dispatch(fetchEntityBusinessMeasures({ orgId, entityId }));
      dispatch(fetchBusinessMeasures({ orgId }));
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };
  const handleEditEntityBusinessMeasure = async (edited, original) => {
    try {
      if (edited.bsnsmsrBsnsmsrtypId && edited.bsnsmsrStartDate && edited.bsnsmsrDivData) {
        setIsLoading(true);
        const resultAction = await dispatch(
          updateEntityBusinessMeasure({
            orgId,
            entityId,
            bsnsmsrId: original.bsnsmsrId,
            data: {
              bsnsmsrDivData: parseInt(edited.bsnsmsrDivData),
              bsnsmsrBsnsmsrtypId:
                typeof edited.bsnsmsrBsnsmsrtypId === 'string'
                  ? original.bsnsmsrBsnsmsrtypId
                  : edited.bsnsmsrBsnsmsrtypId,
              bsnsmsrStartDate: edited.bsnsmsrStartDate,
            }
          })
        );
        unwrapResult(resultAction);
        dispatch(fetchEntityBusinessMeasures({ orgId, entityId }));
        dispatch(fetchBusinessMeasures({ orgId }));
      } else {
        toast.error("Business Measures Data Can't Be Empty");
      }
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: t('Business Measure'),
        id: 'bsnsmsrBsnsmsrtypId',
        accessor: (row) => getBusinessMeasureName(row.bsnsmsrBsnsmsrtypId),
        editable: true,
        EditCell: ({ cell, onCellValueChange }) => {
          const handleChange = (v) => onCellValueChange(v.value);
          return (
            <Select
              defaultValue={{
                label: getBusinessMeasureName(cell.row.original.bsnsmsrBsnsmsrtypId),
                value: cell.row.original.bsnsmsrBsnsmsrtypId
              }}
              options={getBusinessMeasuresOptions()}
              menuPortalTarget={document.body}
              onChange={handleChange}
              menuPlacement="auto"
            />
          );
        }
      },
      {
        Header: t('Start Date'),
        accessor: 'bsnsmsrStartDate',
        editable: true,
        Cell: ({ row }) => formatDate(row.original?.bsnsmsrStartDate),
        EditCell: ({ cell, onCellValueChange }) => {
          const { updatedValue } = cell.state;
          return (
            <ReactDatePicker
              isClearable
              dateFormat={usrDateFormat}
              className="form-control"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              {...datePickerValue(updatedValue, onCellValueChange)}
            />
          );
        }
      },
      {
        Header: t('Data'),
        accessor: 'bsnsmsrDivData',
        editable: true,
        EditCell: ({ cell, onCellValueChange }) => {
          const { updatedValue } = cell.state;
          const handleChange = (e) => onCellValueChange(e.target.value);
          return (
            <Form.Group className={!(updatedValue === "" || (updatedValue > 0 && updatedValue % 1 === 0)) ? "pt-3 mb-0" : "mb-0"}>
              <Form.Control
                value={updatedValue}
                min={1}
                type="number"
                onChange={handleChange}
                isInvalid={!(updatedValue === "" || (updatedValue > 0 && updatedValue % 1 === 0))}
              />
              <Form.Control.Feedback type="invalid">{t("Business Measure must be integer and greater than 0.")}</Form.Control.Feedback>
            </Form.Group>
          )
        }
      },
      {
        Header: '',
        accessor: 'actions',
        actionColumn: true,
        disableGlobalFilter: false,
        disableSortBy: true,
        EditCell: ({ cell, cancelEditRow, saveRow }) => {
          const requireFields = ['bsnsmsrBsnsmsrtypId', 'bsnsmsrDivData', 'bsnsmsrStartDate'];
          const msrDivData = getTargetUpdateValue(cell, 'bsnsmsrDivData');
          return (
            <div className="action-buttons">
              <Button
                disabled={!isEnableUpdateBtn(cell, requireFields) || msrDivData % 1 !== 0}
                size="sm"
                className="mr-1"
                variant="outline-secondary"
                onClick={saveRow}
              >
                <IoSaveOutline />
              </Button>
              <Button size="sm" variant="outline-secondary" onClick={cancelEditRow}>
                <IoCloseOutline />
              </Button>
            </div>
          );
        },
        Cell: (props) => {
          const { deleteRow, editRow } = props;
          return (
            <div className="d-flex action-buttons">
              <Button size="sm" className="mr-1" variant="outline-secondary" onClick={editRow}>
                <AiOutlineEdit />
              </Button>
              <Button size="sm" variant="outline-secondary" onClick={deleteRow}>
                <IoTrashOutline />
              </Button>
            </div>
          );
        }
      }
    ],
    [getBusinessMeasureName, getBusinessMeasuresOptions, t, usrDateFormat]
  );

  useEffect(() => {
    if (loggedUserStatus === LoadingState.succeeded) {
      dispatch(fetchBusinessMeasures({ orgId }));
      dispatch(fetchEntityBusinessMeasures({ orgId, entityId }));
    }
  }, [orgId, loggedUserStatus, entityId, dispatch]);

  return (
    <div>
      <h5 className="my-3 text-light">{t('Business Measures')}</h5>
      {businessMeasuresStatus === LoadingState.loading && entityBusinessMeasuresStatus && LoadingState.loading && (
        <Spinner animation="grow" />
      )}
      {businessMeasuresStatus === LoadingState.succeeded && entityBusinessMeasuresStatus && LoadingState.succeeded && (
        <>
          <DataTable
            columns={columns}
            data={entityBusinessMeasures}
            onEditSubmit={handleEditEntityBusinessMeasure}
            onDeleteRow={handleDeleteEntityBusinessMeasure}
            pageLimit={100}
            footer={
              <AddEntityBusinessMeasure
                onSubmit={addEntityBuisnessMeasure}
                isLoading={isLoading}
                businessMeasureOptions={getBusinessMeasuresOptions()}
              />
            }
          />
        </>
      )}
    </div>
  );
};

export default ManageEntityBusinessMeasure;
