import { Card, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export function KPIDetailCard({ kpiItem, goalId, onChangeHandle }) {
  const { t } = useTranslation();
  const { isApplicable, kpiName, description, id } = kpiItem;
  return (
    <Card className='mb-3 kpi-card'>
      <Card.Header>
        <div className='kpi-version-label'>{t(kpiName)}</div>
      </Card.Header>

      <Card.Body>
        <p className='description mt-2'>{t(description)}</p>

        <label>{t('Is this applicable to you?')} </label>

        <Form.Group>
          <Form.Check
            onChange={onChangeHandle}
            inline
            type='radio'
            aria-label='radio 1'
            label={t('Yes')}
            name={`goalSelection_${goalId}_${id}`}
            id={`goalSelection_${goalId}_${id}_${0}`}
            value={'Yes'}
            defaultChecked={isApplicable === 'Yes'}
          />
          <Form.Check
            onChange={onChangeHandle}
            inline
            type='radio'
            aria-label='radio 2'
            label={t('No')}
            name={`goalSelection_${goalId}_${id}`}
            id={`goalSelection_${goalId}_${id}_${1}`}
            value={'No'}
            defaultChecked={isApplicable === 'No'}
          />
        </Form.Group>
      </Card.Body>
    </Card>
  );
}
