import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAsyncDebounce } from 'react-table';

export const Filter = ({ column }) => {
  return <div style={{ marginTop: 5 }}>{column.canFilter && column.render('Filter')}</div>;
};

export const DefaultColumnFilter = ({
  column: {
    filterValue,
    setFilter,
    Header,
    preFilteredRows: { length }
  }
}, t) => {
  return (
    <input
      className='form-control'
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      // placeholder={`search (${length}) ...`}
      placeholder={t('Search by {{Header}}', { Header })}
    />
  );
};

export const SelectColumnFilter = ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {
  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  return (
    <select
      id='custom-select'
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value=''>All</option>
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};

export function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
  // const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const { t } = useTranslation();
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      <input
        className='form-control border'
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        // placeholder={`${count} records...`}
        placeholder={t('Search by keyword ...')}
        style={{
          fontSize: '1.1rem',
          border: '0'
        }}
      />
    </span>
  );
}
