import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LoadingState } from '../../utilities/constants';
import { fetchFavouriteReportsApi } from '../add-to-favourite/favReportsApi';

export const fetchFavReports = createAsyncThunk('favReports/fetchfavReports', async (payload) => {
  try {
    const response = await fetchFavouriteReportsApi(payload.orgId);
    return response;
  } catch (error) {
    throw error;
  }
});

const favouriteRportsSlice = createSlice({
  name: 'favouriteReports',
  initialState: {
    favReports: [],
    status: LoadingState.idle,
    error: null
  },
  reducers: {},
  extraReducers: {
    [fetchFavReports.pending]: (state) => {
      state.status = LoadingState.loading;
    },
    [fetchFavReports.fulfilled]: (state, action) => {
      state.status = LoadingState.succeeded;
      state.favReports = action.payload;
    },
    [fetchFavReports.rejected]: (state, action) => {
      state.status = LoadingState.failed;
      state.error = action.error.message;
    },
  }
});

export default favouriteRportsSlice.reducer;
