import { useState, useEffect } from 'react';
import { Card, Row, Spinner, Modal, Button } from 'react-bootstrap';
import { IoChevronBackOutline, IoChevronForwardOutline } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import { Form, InputGroup } from 'react-bootstrap';
import { IoSearchOutline } from 'react-icons/io5';

const DataMultiAssignment = ({
  isLoading,
  dataKey,
  dataName,
  dataSearch,
  multiTitle = 'Data',
  singleTitle,
  availableData,
  assignedData,
  handleAssignData,
  groupSelectionRender,
}) => {
  const { t } = useTranslation();
  const [timeID, setTimeID] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [actionType, setActionType] = useState('');

  const [availableSearch, setAvailableSearch] = useState(null);
  const [assignedSearch, setAssignedSearch] = useState(null);
  const [cacheAvailableData, setCacheAvailableData] = useState(null);
  const [cacheAssignedData, setCacheAssignedData] = useState(null);

  const minSearchLength = 1;

  useEffect(() => {
    if (availableData) {
      setCacheAvailableData(availableData);
    }
  }, [availableData]);

  useEffect(() => {
    if (assignedData) {
      setCacheAssignedData(assignedData);
    }
  }, [assignedData]);

  const [selectedItems, setSelectedItems] = useState({
    Assign: [],
    Unassign: []
  });

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const handleOnClick = (actionType) => {
    setActionType(actionType);
    handleOpenModal();
  };

  const handleSubmitData = () => {
    handleCloseModal();
    handleAssignData(actionType, selectedItems[actionType]);
    if(actionType === 'Assign') {
      selectedItems.Unassign = selectedItems.Unassign.concat(selectedItems[actionType])
    }
    if(actionType === 'Unassign') {
      selectedItems.Assign = selectedItems.Assign.concat(selectedItems[actionType])
    }
    setSelectedItems({
      ...selectedItems,
      [actionType]: []
    });
  };

  const handleOnOptionClick = (type) => {
    if (type === 'select') {
      const assignIds = (availableData || [])
        .filter((dataObj) => (availableSearch ? searchResults(dataObj, availableSearch) : true))
        .map((item) => item[dataKey]);
      const unassignIds = (assignedData || [])
        .filter((dataObj) => (assignedSearch ? searchResults(dataObj, assignedSearch) : true))
        .map((item) => item[dataKey]);
      setSelectedItems({ Assign: assignIds, Unassign: unassignIds });
    } else {
      setSelectedItems({ Assign: [], Unassign: [] });
    }
  };

  const handleOnToggle = (actionType, id) => {
    const idx = selectedItems[actionType].indexOf(id);
    if (idx > -1) {
      selectedItems[actionType].splice(idx, 1);
    } else {
      selectedItems[actionType].push(id);
    }
    setSelectedItems({
      ...selectedItems
    });
  };
  const searchResults = (data, search) => `${data[dataName]}`.toLowerCase().includes(`${search}`.toLowerCase()) ||
      `${data[dataSearch]}`.toLowerCase().includes(`${search}`.toLowerCase());
  const renderAvailableData = () => {
    function compare(a, b) {
      const aKeyName = Object.keys(a).find((key) => key === 'assetName' || key === 'divName');
      const bKeyName = Object.keys(b).find((key) => key === 'assetName' || key === 'divName');
      if (!!a[aKeyName] && !!b[bKeyName]) {
        if (a[aKeyName].toLowerCase() < b[bKeyName].toLowerCase()) {
          return -1;
        }
        if (a[aKeyName].toLowerCase() > b[bKeyName].toLowerCase()) {
          return 1;
        }
      }
      return 0;
    }
    if (!availableData && !cacheAvailableData) {
      return <div className="text-muted">Loading {multiTitle}...</div>;
    }
    const data = availableData && availableData.length ? availableData : cacheAvailableData || [];
    return data.length ? (
      data
        .slice()
        .filter((dataObj) =>
          data.length > minSearchLength && availableSearch ? searchResults(dataObj, availableSearch) : true
        )
        .sort(compare)
        .map((dataObj) => {
          const value = dataObj[dataKey];
          const classNames = ['available-entity-row', 'card-text', `item-id-${value}`];
          if (selectedItems.Assign.includes(value)) {
            classNames.push('item-selected');
          }
          return (
            <div
              className={classNames.join(' ')}
              key={dataObj[dataKey]}
              onClick={() => handleOnToggle('Assign', value)}
            >
              <span>{dataObj[dataName]}<span className="text-black-50">({dataObj[dataSearch]})</span></span>
              <IoChevronForwardOutline />
            </div>
          );
        })
    ) : (
      <div className="text-muted">
        {t('No Available {{kind}}', { kind: multiTitle })}
      </div>
    );
  };

  const renderAssignedData = () => {
    if (!assignedData && !cacheAssignedData) {
      return <div className="text-muted">Loading {multiTitle}...</div>;
    }
    const data = assignedData && assignedData.length ? assignedData : cacheAssignedData || [];
    return data.length ? (
      data
        .filter((dataObj) =>
          data.length > minSearchLength && assignedSearch ? searchResults(dataObj, assignedSearch) : true
        )
        .map((dataObj) => {
          const value = dataObj[dataKey];
          const classNames = ['available-entity-row', 'card-text', `item-id-${value}`];
          if (selectedItems.Unassign.includes(value)) {
            classNames.push('item-selected');
          }
          return (
            <div
              className={classNames.join(' ')}
              key={dataObj[dataKey]}
              onClick={() => handleOnToggle('Unassign', value)}
            >
              <IoChevronBackOutline />
              <span>{dataObj[dataName]}<span className="text-black-50">({dataObj[dataSearch]})</span></span>
            </div>
          );
        })
    ) : (
      <div className="text-muted">
        {t('No Assigned {{kind}}', { kind: multiTitle })}
      </div>
    );
  };

  const renderSearchBox = (isValidable) => {
    const searchOnChange = (value) => {
      clearTimeout(timeID);
      setTimeID(
        setTimeout(() => {
          if (isValidable) {
            setAvailableSearch(value);
          } else {
            setAssignedSearch(value);
          }
        }, 500)
      );
    };
    return (
      <Form.Group className="search-box">
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text>
              {' '}
              <IoSearchOutline />{' '}
            </InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            className="custom-form-control search-input"
            type="text"
            defaultValue={isValidable ? availableSearch : assignedSearch}
            onChange={(e) => searchOnChange(e.target.value)}
            placeholder={t("Search")}
          />
        </InputGroup>
      </Form.Group>
    );
  };

  return (
    <Row className="mb-5 assign-rows multi-assignment">
      <>
        <div className="assignment-list">
          <Card>
            <Card.Body>
              <Card.Subtitle className="mb-2 text-muted font-weight-bold">
                {t('Available {{kind}}', { kind: multiTitle })}
              </Card.Subtitle>
              {availableData && availableData.length > minSearchLength ? renderSearchBox(true) : null}
              <div className="assignment-container">{renderAvailableData()}</div>
            </Card.Body>
          </Card>
        </div>
        <div className="assignment-action">
          <Button
            disabled={isLoading || !selectedItems.Assign.length}
            variant="primary"
            onClick={() => handleOnClick('Assign')}
          >
            {t('Assign')}
          </Button>
          <Button
            disabled={isLoading || !selectedItems.Unassign.length}
            variant="secondary"
            onClick={() => handleOnClick('Unassign')}
          >
            {t('Unassign')}
          </Button>
          <Button variant="secondary" onClick={() => handleOnOptionClick('select')}>
            {t('Select All')}
          </Button>
          <Button
            disabled={isLoading || (!selectedItems.Assign.length && !selectedItems.Unassign.length)}
            variant="secondary"
            onClick={() => handleOnOptionClick('deselect')}
          >
            {t('Deselect All')}
          </Button>
          { groupSelectionRender ? groupSelectionRender(setSelectedItems) : '' }
          {isLoading === true && <Spinner animation="border" variant="primary" />}
        </div>
        <div className="assignment-list">
          <Card>
            <Card.Body>
              <Card.Subtitle className="mb-2 text-muted font-weight-bold">
                {t('Assigned {{kind}}', { kind: multiTitle })}
              </Card.Subtitle>
              {assignedData && assignedData.length > minSearchLength ? renderSearchBox(false) : null}
              <div className="assignment-container">{renderAssignedData()}</div>
            </Card.Body>
          </Card>
        </div>
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header>
            <Modal.Title>
              {t('{{actionType}} {{kind}}', { actionType, kind: singleTitle })}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {t(`Are you sure you want to {{action}} selected {{title}}(s)?`, {
              action: t(actionType.toLowerCase()),
              title: singleTitle.toLowerCase()
            })}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                handleCloseModal();
              }}
            >
              {t('Close')}
            </Button>
            <Button disabled={isLoading} variant="primary" onClick={handleSubmitData}>
              {t(actionType)}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    </Row>
  );
};

export default DataMultiAssignment;
