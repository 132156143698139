import React, { useEffect } from 'react';
import { Container, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useRouteMatch } from 'react-router';
import Empty from '../../components/Empty';
import { LoadingState } from '../../utilities/constants';
import { fetchEntityTree, selectRootEntity } from './entitySlice';

const EntityHome = () => {
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const entityStatus = useSelector((state) => state.entities.status);
  const rootEntity = useSelector(selectRootEntity);
  const orgId = useSelector((state) => state.users.user.usrOrgId);
  const loggedUserStatus = useSelector((state) => state.users.loggedUserStatus);

  useEffect(() => {
    if (entityStatus === LoadingState.idle && loggedUserStatus === LoadingState.succeeded) {
      dispatch(fetchEntityTree({ orgId }));
    }
  }, [orgId, loggedUserStatus, entityStatus, dispatch]);

  return (
    <Container>
      {entityStatus === LoadingState.loading && <Spinner animation="grow" />}
      {entityStatus === LoadingState.succeeded && <Redirect to={`${match.url}/${rootEntity.divId}/edit`} />}
      {entityStatus === LoadingState.failed && <Empty title="Sorry, Something went wrong" />}
    </Container>
  );
};

export default EntityHome;
