import React, { useEffect } from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Empty from '../../../components/Empty';
import PageHeader from '../../../components/PageHeader';
import { LoadingState } from '../../../utilities/constants';
import TitleDivider from '../../../components/TitleDivider';
import { getActionsByActId } from '../reductionPlanSlice';
import { format } from 'date-fns';
import { formatNumber } from '../../reports/reportHelper';
import { getMonthDateFormat, parseUserInfo } from '../../../utilities/common';

const ViewAction = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actId } = useParams();
  const { action, viewActionStatus } = useSelector((state) => state.reductionPlan);
  const { orgId, usrDateFormat } = parseUserInfo(useSelector((state) => state.users.user));
  const isBusy = viewActionStatus === LoadingState.loading;

  const monthDateFormat = getMonthDateFormat(usrDateFormat);
  const actionRows = Object.keys(action).length > 0
    ? [
      [
        { label: t('Name'), value: action.actName },
        [
          { label: t('Start Month'), value: format(new Date(action.actStartMonth), monthDateFormat) },
          { label: t('End Month'), value: format(new Date(action.actEndMonth), monthDateFormat) },
        ],
      ],
      [
        { label: t('Purpose'), value: action.actPurpose },
        { label: t('Description'), value: action.actDescription },
      ],
      [
        { label: t('Total CO2-e reduction (tonnes)'), value: formatNumber(action.actTotalReduction) },
      ]
    ]
    : [];

  useEffect(() => {
    if (orgId && actId) {
      dispatch(getActionsByActId({ orgId, actId }));
    }
  }, [orgId, actId, dispatch]);

  return (
    <Container fluid>
      {isBusy && <Spinner animation="grow" />}
      {!isBusy && action && (
        <>
          <Row>
            <Col>
              <PageHeader title={t('View action plan')} enableBackButton={true} />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              {actionRows.map((row, i) => (
                <Row key={i} className="mt-4">
                  {row.map((col) => {
                    if (Array.isArray(col)) {
                      return col.map((item) => (
                        <Col md={3} key={item.label}>
                          <div className="data-view">
                            <label className="label">{item.label}</label>
                            <div className="data">{item.value || '-'}</div>
                          </div>
                        </Col>
                      ))
                    } else {
                      return (
                        <Col md={6} key={col.label}>
                          <div className="data-view">
                            <label className="label">{col.label}</label>
                            <div className="data">{col.value || '-'}</div>
                          </div>
                        </Col>
                      )
                    }
                  })}
                </Row>
              ))}

              <TitleDivider title={t('Applied to')} />
              <Row className="mt-4">
                <Col>
                  <div className="data-view">
                    <label className="label">{t('Entity')}</label>
                    <div className="data">{action.actDiv?.divName || '-'}</div>
                  </div>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col md={3} key={t('Selected EPS')}>
                  <div className="data-view">
                    <label className="label">{t('Selected EPS')}</label>
                  </div>
                </Col>
                <Col md={2} key={t('Reduction principal value')}>
                  <div className="data-view">
                    <label className="label">{t('Reduction principal value')}</label>
                  </div>
                </Col>
                <Col md={1} key={t('Target')}>
                  <div className="data-view">
                    <label className="label">{t('Target')}</label>
                  </div>
                </Col>
                <Col md={2} key={t('Unit')}>
                  <div className="data-view">
                    <label className="label">{t('Unit')}</label>
                  </div>
                </Col>
                <Col md={3} key={t('Planned CO2-e reduction (tonnes)')}>
                  <div className="data-view">
                    <label className="label">{t('Planned CO2-e reduction (tonnes)')}</label>
                  </div>
                </Col>
              </Row>
              {action.actEprflsrcs?.map((actEprflsrc) =>
                actEprflsrc.reductions?.map((reduction) =>
                  <Row key={reduction.reductionId}>
                    <Col md={3}>
                      <div className="data-view">
                        <div className="data">{actEprflsrc.eprflsrc?.eprflsrcName || '-'}</div>
                      </div>
                    </Col>
                    <Col md={2}>
                      <div className="data-view">
                        <div className="data">{formatNumber(reduction.reductionPrincipalValue, '-')}</div>
                      </div>
                    </Col>
                    <Col md={1}>
                      <div className="data-view">
                        <div className="data">{formatNumber(reduction.reductionTarget, '-')}</div>
                      </div>
                    </Col>
                    <Col md={2}>
                      <div className="data-view">
                        <div className="data">{reduction.reductionUnit || '-'}</div>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="data-view">
                        <div className="data">{formatNumber(reduction.plannedReduction, '-')}</div>
                      </div>
                    </Col>
                  </Row>
                )
              )}
            </Col>
          </Row>
        </>
      )}
      {!isBusy && !action && <Empty title={t('Sorry, action was not found')} />}
    </Container>
  );
};

export default ViewAction;
