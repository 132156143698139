import React, { useEffect } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Card, Col, Container, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import PageHeader from '../../components/PageHeader';
import { useDispatch, useSelector } from 'react-redux';
import { dateRangeFilterPeriodOptions, getListReportOptions } from './reportHelper';
import { fetchFavReports } from '../add-to-favourite/favReportSlice';
import { LoadingState } from '../../utilities/constants';
import { useTranslation } from 'react-i18next';
import { isAuthorized } from '../../app/userImpersonate';
import { FaRegCalendarAlt, FaUser, FaUserFriends } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';

const ViewReports = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const favReports = useSelector((state) => state.favouriteReports.favReports);
  const favReportsStatus = useSelector((state) => state.favouriteReports.status);
  const match = useRouteMatch();
  const dispatch = useDispatch();

  const routes = getListReportOptions(t).map((item) => ({
    name: item.label,
    path: item.path,
    route: `${match.path}/${item.path}`,
    key: item.value,
  }));

  const { usrOrgId: orgId, optAvailableReports } =
    useSelector((state) => state.users.user);

  const checkShowReport = (reportName) => {
    if (!optAvailableReports?.length) {
      return true;
    }
    return optAvailableReports.includes(reportName);
  };

  useEffect(() => {
    if (favReportsStatus === LoadingState.idle) {
      dispatch(fetchFavReports({ orgId }));
    }
  }, [dispatch, orgId, favReportsStatus]);

  const filterredFavReports = favReports.filter(({ frRepCategoryName }) => {
    const route = routes.find(({ path }) => path === frRepCategoryName);
    return checkShowReport(route?.key);
  });

  const renderAddSchedule = (frId) => {
    if (!isAuthorized(['cv_root', 'cv_admin'])) {
      return null;
    }
    return (
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={(props) => (
          <Tooltip {...props}>
            <span style={{ fontSize: '12px' }}>{t('Schedule report')}</span>
          </Tooltip>
        )}
      >
        <FaRegCalendarAlt
          className="add-schedule-icon"
          size={'1.5em'}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            history.push({
              pathname: '/admin/reports/scheduled/create',
              search: `?favouriteId=${frId}`,
            });
          }}
        />
      </OverlayTrigger>
    );
  };

  const renderFavouriteVisibility = (favReport) => {
    if (favReport?.frShared === 'Y') {
      return (
        <label className="fav-visibility">
          <FaUserFriends size="1.2em" className="mr-1" />
          {t('Shared in organization')}
        </label>
      )
    }
    return (
      <label className="fav-visibility">
        <FaUser className="mr-1" /> {t('Only me')}
      </label>
    );
  }

  return (
    <Container fluid>
      <Row className="pr-4 mt-2">
        {filterredFavReports && filterredFavReports.length ?
          (
            <Col sm={12}>
              <PageHeader.Secondary title={t("Favourite Reports")} />
            </Col>
          ) : null}
        {filterredFavReports &&
          filterredFavReports.map((doc) => {
            const filterParams = JSON.parse(doc.frRepParamJson);
            const params = new URLSearchParams({
              filter: btoa(doc.frRepParamJson),
              frName: doc.frName,
              frUsrId: doc.frUsrId,
            });
            const dateRange = dateRangeFilterPeriodOptions(filterParams, t, doc.frRepCategoryName, true);
            const target = dateRange.find(item => item.value === filterParams.frRepFilterPeriodParam);
            return (
              <Col
                key={doc.frName}
                sm={4}
                className="pr-10 pt-3 favourite-report-block"
                to={`${match.path}/${doc.frRepCategoryName}?${params.toString()}`}
                as={Link}
              >
                <Card>
                  <Card.Body>
                    {renderAddSchedule(doc.frId)}
                    <p className="text-center">
                      <strong>{doc.frName}</strong>
                      {renderFavouriteVisibility(doc)}
                      <label className="date-range-label">{target?.label || t('Unknown')}</label>
                    </p>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
      </Row>
      <Row className="mt-4 pr-4">
        <Col sm={12}>
          <PageHeader.Secondary title={t("General Reports")} />
        </Col>
        {routes.filter(({ key }) => checkShowReport(key)).map(({ route, name }) => {
          return (
            <Col key={name} sm={4} className="pr-10 pt-3" to={route} as={Link}>
              <Card className="general-report-card">
                <Card.Body>
                  <p className="text-center">
                    <strong>{name}</strong>
                  </p>
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default ViewReports;
