import React, { useEffect } from 'react';
import { Col, Container, Row, Spinner, Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Empty from '../../components/Empty';
import PageHeader from '../../components/PageHeader';
import { LoadingState } from '../../utilities/constants';
import { chunkArray, toYesNoUnknown } from '../../utilities/formatting';
import { fetchAssets, selectAssetById } from './assetsSlice';
import ViewAssignedEmissionSources from './ViewAssignedEmissionSources';
import ViewAssignedEntities from './ViewAssignedEntities';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../utilities/common';

const ViewAsset = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { assetId } = useParams();
  const asset = useSelector((state) => selectAssetById(state, assetId));
  const assetState = useSelector((state) => state.assets.status);
  const isBusy = assetState === LoadingState.loading;
  const orgId = useSelector((state) => state.users.user.usrOrgId);

  const displayItems = asset
    ? [
      { label: t('Asset ID'), value: asset.assetAssignedId },
      { label: t('Date Introduced'), value: formatDate(asset.assetDateIntroduced) },
      { label: t('Asset Name'), value: asset.assetName },
      { label: t('Date Out of Service'), value: formatDate(asset.assetDateOutOfService) },
      { label: t('Description'), value: asset.assetDesc },
      { label: t('Reason Removed from Service'), value: asset.assetReasonRemovedFromService },
      { label: t('Active'), value: toYesNoUnknown(asset.assetEnabled, t) },
      { label: t('GL Code'), value: asset.assetGlCode },
      { label: t('ID Type'), value: asset.assetIdType },
      { label: t('Business Unit'), value: asset.assetBusinessUnit },
      { label: t('Serial Number'), value: asset.assetSerialNumber },
      { label: t('Address'), value: asset.assetAddress },
      { label: t('Suburb'), value: asset.assetSuburb },
      { label: t('Postcode'), value: asset.assetPostcode }
    ]
    : [];

  const itemRows = chunkArray(displayItems, 2);

  useEffect(() => {
    if (assetState === LoadingState.idle) {
      dispatch(fetchAssets({ orgId }));
    }
  }, [orgId, assetState, dispatch]);

  return (
    <Container fluid>
      {isBusy && <Spinner animation="grow" />}
      {!isBusy && asset && (
        <>
          <Row>
            <Col>
              <PageHeader enableBackButton title={t('View Asset')} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Tabs defaultActiveKey="details" id={`${asset.assetId}-tabs`} className="custom-tab-bar">
                <Tab eventKey="details" title={t('Asset Details')} className="custom-tab-content">
                  <Row>
                    <Col lg={8}>
                      {itemRows.map((row, i) => (
                        <Row key={i} className="mt-4">
                          {row.map((col) => (
                            <Col md={6} key={col.label}>
                              <div className="data-view">
                                <label className="label">{col.label}</label>
                                <div className="data">{col.value || '-'}</div>
                              </div>
                            </Col>
                          ))}
                        </Row>
                      ))}
                    </Col>
                  </Row>
                </Tab>
                <Tab
                  eventKey="entity-assignments"
                  title={t('Entity Assignments')}
                  className="custom-tab-content"
                >
                  <ViewAssignedEntities />
                </Tab>
                <Tab
                  eventKey="emission-source-assignments"
                  title={t('Emission Source Assignments')}
                  className="custom-tab-content"
                >
                  <ViewAssignedEmissionSources />
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </>
      )}
      {!isBusy && !asset && <Empty title="Sorry, asset was not found" />}
    </Container>
  );
};

export default ViewAsset;
