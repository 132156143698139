import { toast } from 'react-toastify';
import { Fragment, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { KPIDetailCard } from './KPIDetailCard';
import { GoalsCardHeader } from './GoalsCardHeader';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingState } from '../../../utilities/constants';
import { Button, Modal, Nav, Spinner, Tab } from 'react-bootstrap';
import { createKpiSetups, getSdgDataByOrg } from '../disclosureSlice';
import { setLeavePrompt } from '../../manage-users/usersSlice';
import DiscardModal from '../../../components/DiscardModal';
import { useTranslation } from 'react-i18next';

export function DisclosureSetupModal({
  setupModalShow,
  setSetupModalShow,
  sdgsList,
  setSdgsList,
  userInfo,
  modalName = null,
  refList
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isLeavePrompt = useSelector((state) => state.users.isLeavePrompt);
  const { createKpiSetupsStatus, getSdgDataByOrgStatus } = useSelector((state) => state.disclosure);
  const [currentTab, setCurrentTab] = useState(['0']);
  const [showInformationDisplayModal, setShowInformationDisplayModal] = useState(false);

  const tabOnchange = (key) => {
    // dispatch(setFocusedGoal([key]));
    setCurrentTab([key]);
  };

  const setSetupModalShowHandler = () => {
    setSetupModalShow(false);
    dispatch(setLeavePrompt(false));
  }

  const handleExit = () => {
    setSetupModalShowHandler();
    setShowInformationDisplayModal(false);
  };
  const handleCancel = () => {
    setShowInformationDisplayModal(false);
  };

  const goToNextTab = () => {
    setCurrentTab([Number(currentTab[0]) + 1]);
  };

  const goToPrevTab = () => {
    setCurrentTab([Number(currentTab[0]) - 1]);
  };

  const checkIsKpiSetup = (sdgData) => {
    const selectedKpis = [];
    sdgData.forEach((sdgGoal) => {
      sdgGoal.kpis.forEach((kpi) => {
        if (kpi.isApplicable !== 'N/A') {
          selectedKpis.push(kpi);
        }
      });
    });
    return selectedKpis.length > 0 ? true : false;
  };

  const ButtonGroup = (currentTab) => {
    const currentTabIndex = Number(currentTab[0]);
    if (currentTabIndex === 0) {
      return (
        <Fragment>
          <Button
            onClick={onClickSave}
            disabled={
              !checkIsKpiSetup(sdgsList) || createKpiSetupsStatus === LoadingState.loading || getSdgDataByOrgStatus === LoadingState.loading 
            }
          >
            {createKpiSetupsStatus === LoadingState.loading || getSdgDataByOrgStatus === LoadingState.loading ? (
              <Spinner animation='border' />
            ) : (
              <Fragment>{t('Save')}</Fragment>
            )}
          </Button>
          <Button onClick={goToNextTab}>{t('Next')}</Button>
        </Fragment>
      );
    } else if (currentTabIndex < sdgsList.length - 1) {
      return (
        <Fragment>
          <Button onClick={goToPrevTab}>{t('Back')}</Button>
          <Button
            onClick={onClickSave}
            disabled={
              createKpiSetupsStatus === LoadingState.loading || getSdgDataByOrgStatus === LoadingState.loading
            }
          >
            {createKpiSetupsStatus === LoadingState.loading || getSdgDataByOrgStatus === LoadingState.loading ? (
              <Spinner animation='border' />
            ) : (
              <Fragment>{t('Save')}</Fragment>
            )}
          </Button>
          <Button onClick={goToNextTab}>{t('Next')}</Button>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Button onClick={goToPrevTab}>{t('Back')}</Button>
          <Button
            onClick={onClickSave}
            disabled={
              createKpiSetupsStatus === LoadingState.loading || getSdgDataByOrgStatus === LoadingState.loading
            }
          >
            {createKpiSetupsStatus === LoadingState.loading || getSdgDataByOrgStatus === LoadingState.loading ? (
              <Spinner animation='border' />
            ) : (
              <Fragment>{t('Save')}</Fragment>
            )}
          </Button>
        </Fragment>
      );
    }
  };

  const onClickSave = async () => {
    let submitType = checkIsKpiSetup(refList);
    
    const setupsResponse = await dispatch(
      createKpiSetups({
        orgId: userInfo?.usrOrgId,
        keycloakId: userInfo?.usrKeycloakId,
        sdgsList
      })
    );

    const setupsResult = unwrapResult(setupsResponse);
    if (!setupsResult.error) {
      const sdgResponse = await dispatch(getSdgDataByOrg(userInfo?.usrOrgId));
      const sdgResult = unwrapResult(sdgResponse);
      if (!sdgResult.error) {
        toast.success(t('Disclosure setup {{action}} successfully', { action : submitType ? 'updated' : 'added' }));
        setSetupModalShowHandler();
      }
    }
  };

  const onChangeHandle = (e) => {
    dispatch(setLeavePrompt(true));
    const { id, value } = e.target;
    const selectedGoalId = id.split('_')[1];
    const selectedKpiId = id.split('_')[2];

    setSdgsList(
      sdgsList.map((item) => {
        if (item.id === selectedGoalId) {
          let kpis = item.kpis.map((kpiItem) => {
            if (kpiItem.id === selectedKpiId) {
              return { ...kpiItem, isApplicable: value };
            } else {
              return kpiItem;
            }
          });
          let isSelected = isChecked(kpis);
          return { ...item, kpis, isSelected };
        } else {
          return item;
        }
      })
    );
  };

  const isChecked = (kpis) => {
    let selectedKpiCount = kpis.filter((kpi) => kpi.isApplicable === 'Yes' || kpi.isApplicable === 'No').length;
    if (selectedKpiCount > 0) {
      return true;
    } else {
      return false;
    }
  };

  const showCheckMark = () => {
    return <img src='https://i.ibb.co/0Z1HSB7/Tick.png' style={{ height: '15px', width: '15px' }} alt='check-mark' />;
  };

  return (
    <Modal
      animation={false}
      show={setupModalShow}
      onHide={() => {
        if (isLeavePrompt) {
          setShowInformationDisplayModal(true);
        } else {
          setSetupModalShowHandler();
        }
      }}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      backdrop='static'
      className='activities-modal disclosure-modal disclosure-custom'
      dialogClassName='modal-90w'
    >
      <Modal.Header closeButton>
        <h5>{modalName} {t('Sustainable Development Goals')}</h5>
      </Modal.Header>

      <Modal.Body>
        <Tab.Container
          id='left-tabs-example'
          defaultActiveKey={currentTab}
          activeKey={currentTab}
          onSelect={tabOnchange}
        >
          <div className='container-wrapper'>
            <div className='navigation-menu'>
              <Nav variant='pills' className='flex-column text-left'>
                {sdgsList &&
                  sdgsList.length > 0 &&
                  sdgsList.map((sdgItem, idx) => {
                    return (
                      <Fragment key={idx}>
                        <Nav.Item className='mb-1'>
                          <Nav.Link eventKey={idx} style={{ color: 'black', fontWeight: '600' }}>
                            {t('{{orderNo}}. {{goalName}}', { goalName: t(sdgItem.goalName), orderNo: Number(sdgItem.orderNo) })}{' '}
                            {sdgsList[idx]['isSelected'] && showCheckMark()}
                            {/* {sdgsList[idx]['isSelected'] && '</AiFillCheckCircle>'} */}
                          </Nav.Link>
                        </Nav.Item>
                      </Fragment>
                    );
                  })}
              </Nav>
            </div>
            <div className='tab-content-side'>
              <Tab.Content>
                {sdgsList &&
                  sdgsList.length > 0 &&
                  sdgsList.map((sdgItem, idx) => {
                    return (
                      <Tab.Pane key={idx} eventKey={idx}>
                        <GoalsCardHeader
                          color={sdgItem.color}
                          goalName={sdgItem.goalName}
                          imagePath={sdgItem?.imagePath}
                          description={sdgItem.description}
                          esgIndicators={sdgItem.esgIndicators}
                        />
                        <div className='mt-4 kpi-card-wrapper'>
                          {sdgItem.kpis &&
                            sdgItem.kpis.length > 0 &&
                            sdgItem.kpis.map((kpiItem, idx) => {
                              return (
                                <KPIDetailCard
                                  key={idx}
                                  kpiItem={kpiItem}
                                  goalId={sdgItem.id}
                                  onChangeHandle={onChangeHandle}
                                />
                              );
                            })}
                        </div>
                      </Tab.Pane>
                    );
                  })}
              </Tab.Content>
            </div>
          </div>
        </Tab.Container>
      </Modal.Body>

      <Modal.Footer>{ButtonGroup(currentTab)}</Modal.Footer>

      <DiscardModal
        onCancelHandler={handleCancel}
        isShowModal={showInformationDisplayModal}
        onDiscardHandler={handleExit} />
    </Modal>
  );
}
