import React, { useEffect, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { Col, Container, Row, Spinner, Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PageHeader from '../../components/PageHeader';
import TitleDivider from '../../components/TitleDivider';
import { LoadingState } from '../../utilities/constants';
import { chunkArray, toYesNo } from '../../utilities/formatting';
import { fetchSingleEmissionData } from './emissionSlice';
import { handleFileDownload } from './upload-data/UploadDataTable';
import DownloadHelper from '../../components/DownloadHelper';
import { parseUserInfo, formatDate } from '../../utilities/common';
import { formatNumber } from '../reports/reportHelper';

const ViewEmission = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { emissionId } = useParams();
  const [emissionState, setEmissionState] = useState(LoadingState.idle);
  const [emission, setEmission] = useState(null);
  const isBusy = emissionState === LoadingState.loading;
  parseUserInfo(useSelector((state) => state.users.user));

  const formatCurrencyLabel = (currencyId) => {
    switch (currencyId) {
      case 1:
        return 'USD';
      case 2:
        return 'AUD';
      case 5:
        return 'GBP';
      default:
        return '-';
    }
  };

  const calculationsItems = emission
    ? [
      { label: t('CO2-e (t)'), value: formatNumber(emission.eCo2emission, '-') },
      { label: t('Usage (TJ)'), value: formatNumber(emission.eTerajoulesUsage, '-') },
      {
        label: t('CO2 contribution'),
        value: emission.eCo2Contribution || '-'
      },
      {
        label: t('CH4 contribution'),
        value: emission.eCh4Contribution || '-'
      },
      { label: t('CO2 Uncertainty %'), value: '-' },
      { label: t('CH4 Uncertainty %'), value: '-' },
      {
        label: t('N2O contribution'),
        value: emission.eN2oContribution || '-'
      },
      { label: t('HFC contribution'), value: '-' },
      { label: t('N2O Uncertainty %'), value: '-' },
      { label: t('HFC Uncertainty %'), value: '-' },
      { label: t('PFC contribution'), value: '-' },
      { label: t('SF6 contribution'), value: '-' },
      { label: t('PFC Uncertainty %'), value: '-' },
      { label: t('SF6 Uncertainty %'), value: '-' }
    ]
    : [];

  const calculationsRows = chunkArray(calculationsItems, 3);

  const consumptionItems = emission
    ? [
      { label: t('Emission Source'), value: emission.eEprflsrc?.eprflsrcName },
      { label: t('Entity'), value: emission.eDiv?.divName },
      { label: t('Period Start Date'), value: formatDate(emission.eStartDate) },
      { label: t('Period End Date'), value: formatDate(emission.eEndDate) },
      { label: t('Contribution'), value: emission.eContribution || '-' },
      { label: t('Unit'), value: emission.eContributionUnit },
      { label: t('Cost'), value: emission.eCost || '-' },
      { label: t('Currency'), value: formatCurrencyLabel(emission.eCurrencyId) },
      { label: t('Bill/Invoice Number'), value: emission.eContributorId },
      { label: t('Provider'), value: emission.eProvider },
      { label: t('Comment'), value: emission.eComment },
      { label: t('Estimate'), value: toYesNo(emission.eEstimate, t) },
      { label: t('ID'), value: emission.eId }
    ]
    : [];

  const consumptionRows = chunkArray(consumptionItems, 2);

  const auditItems = emission
    ? [
      { label: t('Created By'), value: emission.eCreatedBy },
      { label: t('Created Date'), value: formatDate(emission.eCreatedDate) },
      { label: t('Last Updated By'), value: emission.eModifiedBy },
      { label: t('Last Updated Date'), value: formatDate(emission.eModifiedDate) }
    ]
    : [];

  const auditRows = chunkArray(auditItems, 2);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setEmissionState(LoadingState.loading);
        const resultAction = await dispatch(fetchSingleEmissionData({ emissionId }));
        if (resultAction.payload) {
          const result = unwrapResult(resultAction);
          setEmission(result);
        }
        setEmissionState(LoadingState.succeeded);
      } catch (error) {
        setEmissionState(LoadingState.failed);
      }
    };
    fetchData();
  }, [dispatch, emissionId]);

  return (
    <Container fluid>
      {isBusy && <Spinner animation="grow" />}
      {!isBusy && emission && (
        <>
          <Row>
            <Col>
              <PageHeader enableBackButton title={t('View Emission')} />
            </Col>
          </Row>
          {emission && (
            <Row>
              <Col>
                <Tabs defaultActiveKey="details" id={`${emission.eId}-tabs`} className="custom-tab-bar">
                  <Tab
                    eventKey="details"
                    title={t('Details')}
                    className="custom-tab-content"
                  >
                    <Row>
                      <Col lg={9}>
                        {/* Calculations */}
                        <TitleDivider title={t('Emission Calculations')} />
                        {calculationsRows.map((row, i) => (
                          <Row key={i} className="mt-4">
                            {row.map((col) => (
                              <Col key={col.label}>
                                <div className="data-view">
                                  <label className="label">{col.label}</label>
                                  <div className="data">{col.value || '-'}</div>
                                </div>
                              </Col>
                            ))}
                          </Row>
                        ))}

                        {/* Consumptions */}
                        <TitleDivider title={t('Consumption Data')} />
                        {consumptionRows.map((row, i) => (
                          <Row key={i} className="mt-4">
                            {row.map((col) => (
                              <Col md={6} key={col.label}>
                                <div className="data-view">
                                  <label className="label">{col.label}</label>
                                  <div className="data">{col.value || '-'}</div>
                                </div>
                              </Col>
                            ))}
                          </Row>
                        ))}

                        {/* File Attachment */}
                        <TitleDivider title={t('File Attachment')} />
                        <Row>
                          <Col>
                            <div className="data-view">
                              {
                                emission.eAttachment ?
                                  <DownloadHelper getLinkObject={() => handleFileDownload(dispatch, emission.eAttachment.upldflId)} label={emission.eAttachment.upldflName} /> :
                                  <label className="label">{t('No Attachment')}</label>
                              }
                            </div>
                          </Col>
                        </Row>

                        {/* Audit Information */}
                        <TitleDivider title={t('Audit Information')} />
                        {auditRows.map((row, i) => (
                          <Row key={i} className="mt-4">
                            {row.map((col) => (
                              <Col md={6} key={col.label}>
                                <div className="data-view">
                                  <label className="label">{col.label}</label>
                                  <div className="data">{col.value || '-'}</div>
                                </div>
                              </Col>
                            ))}
                          </Row>
                        ))}
                      </Col>
                    </Row>
                  </Tab>
                </Tabs>
              </Col>
            </Row>
          )}
        </>
      )}
    </Container>
  );
};

export default ViewEmission;
