import * as yup from 'yup';

export const createUserValidateSchema = (t) => {
  const createScheduleSchema = yup.object().shape({
    cvFrId: yup.string().required(t('this is required field')),
    sendingInterval: yup.string().required(t('this is required field')),
    receiveDate: yup.string().required(t('this is required field')),
    emails: yup.array(yup.string().required())
      .min(1, t('this is required field'))
      .required(t('this is required field')),
  });

  const editScheduleSchema = yup.object().shape({
    cvFrId: yup.string().required(t('this is required field')),
    sendingInterval: yup.string().required(t('this is required field')),
    receiveDate: yup.string().required(t('this is required field')),
    emails: yup.array(yup.string().required())
      .min(1, t('this is required field'))
      .required(t('this is required field')),
  });
  return { createScheduleSchema, editScheduleSchema };
};

export const createEmailInputValidateSchema = () => yup.object().shape({
  email: yup.string().email(),
});
