import React from 'react';
import Empty from './components/Empty';
import { useTranslation } from 'react-i18next';

function RouteNotFound() {
  const { t  } = useTranslation();
  return <Empty title={t("Page Under Construction")} />;
}

export default RouteNotFound;
