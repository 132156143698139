import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, CloseButton, Form, Modal, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { LoadingState } from '../../../utilities/constants';
import {
  fetchEmissionProfileFactorByEPS,
  fetchEmissionProfileUnitsByEPS,
} from '../../emissions/emissionSlice';
import { useFieldArray, useWatch } from 'react-hook-form';
import { unwrapResult } from '@reduxjs/toolkit';
import ReductionForm from './ReductionForm';
import { IoLeafOutline } from 'react-icons/io5';
import { fetchCarbonFootprintByEntity } from '../../reports/reportsSlice';
import { toast } from 'react-toastify';
import { endOfYear, parseISO, startOfYear, subYears } from 'date-fns';

const EPSForm = ({
  register,
  errors,
  control,
  setValue,
  getValues,
  actEprflsrc,
  actEprflsrcIdx,
  handleDeleteEPS,
  watchStartMonth,
  orgId,
  divId,
  actTotalReduction,
  usrTimezone,
  usrDateFormat,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [unitState, setUnitState] = useState(LoadingState.idle);
  const [units, setUnits] = useState([]);
  const [factor, setFactor] = useState();

  const [emissionData, setEmissionData] = useState();
  const [deleteReductionIdx, setDeleteReductionIdx] = useState();
  const [showDeleteReductionWarningModal, setShowDeleteReductionWarningModal] = useState(false);
  const closeConfirmModal = () => setShowDeleteReductionWarningModal(false);

  const { fields: reductions, append: appendReduction, remove: removeReduction } = useFieldArray({
    control,
    name: `actEprflsrcs[${actEprflsrcIdx}].reductions`
  });

  const watchReductions = useWatch({
    control,
    name: `actEprflsrcs[${actEprflsrcIdx}].reductions`
  })

  const fetchUnits = useCallback(async (emissionProfileSource) => {
    try {
      setUnitState(LoadingState.loading);
      const resultAction = await dispatch(fetchEmissionProfileUnitsByEPS(emissionProfileSource));
      if (resultAction.payload) {
        const result = unwrapResult(resultAction);
        const newUnits = [
          { label: "%", value: "%" },
          ...result.map((item) => {
            return { label: t(item), value: item }
          })
        ];
        setUnits(newUnits)
      }
      setUnitState(LoadingState.succeeded);
    } catch {
      setUnitState(LoadingState.failed);
    }
  }, [dispatch, t]);

  const getEmissionData = useCallback(async () => {
    let startMonth = subYears(parseISO(watchStartMonth), 1);
    const payload = {
      orgId,
      data: {
        usrTimezone,
        usrDateFormat,
        startDate: startOfYear(startMonth),
        endDate: endOfYear(startMonth),
        entitiesIdsToInclude: [divId],
        epsIdsToInclude: [actEprflsrc.eprflsrcId],
      }
    };
    try {
      const response = await dispatch(fetchCarbonFootprintByEntity(payload));
      if (response.payload) {
        const results = unwrapResult(response);
        setEmissionData(Number(results.summarizedCo2_e_t))
      }
    } catch (e) {
      toast.error(e.message);
    }
  }, [dispatch, divId, orgId, watchStartMonth, usrTimezone, usrDateFormat, actEprflsrc.eprflsrcId]);

  const getEmissionFactor = useCallback(async () => {
    try {
      const resultFactor = await dispatch(fetchEmissionProfileFactorByEPS({ eprflsrc: actEprflsrc.eprflsrc }));
      if (resultFactor.payload) {
        const result = unwrapResult(resultFactor);
        setFactor(result)
        return result;
      }
    } catch (e) {
      toast.error(e.message);
    }
  }, [dispatch, actEprflsrc.eprflsrc]);

  useEffect(() => {
    if (actEprflsrc?.eprflsrc) {
      fetchUnits(actEprflsrc?.eprflsrc);
      getEmissionFactor();
    }
  }, [fetchUnits, actEprflsrc?.eprflsrc, getEmissionFactor])

  useEffect(() => {
    if (watchStartMonth) {
      getEmissionData();
    }
  }, [watchStartMonth, getEmissionData])

  const handleDeleteReduction = (reductionIdx) => {
    let isFilled = false;
    if (watchReductions[reductionIdx]) {
      for (let j = 0; j < Object.values(watchReductions[reductionIdx]).length; j++) {
        const value = Object.values(watchReductions[reductionIdx])[j];
        if (value) {
          isFilled = true;
          break;
        }
      }
    }
    if (isFilled) {
      setShowDeleteReductionWarningModal(true);
      setDeleteReductionIdx(reductionIdx);
    } else {
      setValue("actTotalReduction", actTotalReduction - parseFloat(watchReductions[reductionIdx].plannedReduction)); //recalculate total reduction
      removeReduction(reductionIdx);
    }
  }

  const handleConfirmDeleteReduction = () => {
    setValue("actTotalReduction", actTotalReduction - parseFloat(watchReductions[deleteReductionIdx].plannedReduction)); //recalculate total reduction
    removeReduction(deleteReductionIdx);
    setDeleteReductionIdx(null);
    closeConfirmModal()
  }

  return (
    <>
      {unitState === LoadingState.loading ? (
        <Spinner animation="grow" />
      ) : (
        <Card className='mb-4'>
          <Card.Body>
            <Card.Title><IoLeafOutline /> {actEprflsrc?.eprflsrc?.eprflsrcName}<CloseButton onClick={() => handleDeleteEPS(actEprflsrc, actEprflsrcIdx)} /></Card.Title>
            <Form.Control
              readOnly
              hidden
              {...register(`actEprflsrcs[${actEprflsrcIdx}].eprflsrcId`)}
              isInvalid={errors.actEprflsrcs?.[actEprflsrcIdx]?.eprflsrcId}
              value={actEprflsrc.eprflsrcId}
            />
            {reductions?.map((reduction, reductionIdx) => (
              <ReductionForm
                key={reduction.id}
                register={register}
                errors={errors}
                setValue={setValue}
                getValues={getValues}
                control={control}
                actEprflsrcIdx={actEprflsrcIdx}
                reductionIdx={reductionIdx}
                units={units}
                unitState={unitState}
                handleDeleteReduction={handleDeleteReduction}
                watchReductions={watchReductions}
                watchStartMonth={watchStartMonth}
                factor={factor}
                emissionData={emissionData}
                reductions={reductions}
                actTotalReduction={actTotalReduction}
              />
            ))}
            <Button className='mt-2' variant="secondary" disabled={reductions?.length >= units.length} onClick={() => appendReduction({
              isGetPreviousYearEmission: false,
              reductionPrincipalValue: null,
              reductionTarget: null,
              reductionUnit: null,
              plannedReduction: 0,
            })}>+ {t('Add new reduction')}</Button>
          </Card.Body>
        </Card>
      )}
      <Modal show={showDeleteReductionWarningModal} onHide={closeConfirmModal} className="sisde-modal">
        <Modal.Header>
          <Modal.Title>{t('Warning')}!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {t('Are you sure that you want to permanently delete this reduction?')}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleConfirmDeleteReduction}>
            {t('Delete')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EPSForm;
