import React, { useState } from 'react';
import { Button, Form, Modal, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { fetchEmissionDataUploads } from './uploadDataSlice';
import UploadDataConfirmForm from './UploadDataConfirmForm';
import * as yup from 'yup';
import { createEmissionDataApi, fetchEmissionDataUploadsApi, uploadEmissionDataApi } from './uploadDataApi';
import { toast } from 'react-toastify';
import LoadingButton from '../../../components/LoadingButton';

const TEMPLATE_TYPE = {
  STANDARD: 'STANDARD',
  CUSTOM: 'CUSTOM',
  BID: 'BID'
};
const SUPPORTED_FORMATS = ['text/csv', 'application/vnd.ms-excel', 'text/x-csv', 'text/plain', '.csv', 'csv'];

const UploadDataForm = ({ showModal, closeModal, onUploadHandler }) => {
  const { t } = useTranslation();
  const schema = yup.object().shape({
    selectedFile: yup
      .mixed()
      .required()
      .required(t('File is required'))
      .test('fileFormat', t('Unsupported File Format!'), (value) => {
        const type = !!value ? (!!value.type ? value.type : value.name.split('.').slice(-1).pop()) : null;
        return !!value && SUPPORTED_FORMATS.includes(type);
      })
  });
  const {
    control,
    clearErrors,
    setValue,
    getValues,
    formState: { errors, isValid }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange'
  });
  const dispatch = useDispatch();
  const [isUploading, setIsUploading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const orgId = useSelector((state) => state.users.user.usrOrgId);
  // const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileDescription, setSelectedFileDescription] = useState();
  const [templateType, setTemplateType] = useState(TEMPLATE_TYPE.STANDARD);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  useWatch({ name: 'selectedFile', control });
  const canUpload = !!getValues('selectedFile') && selectedFileDescription && !isUploading && isValid;

  const resetForm = () => {
    setSelectedFileDescription(null);
    setValue('selectedFile', null);
    setTemplateType(TEMPLATE_TYPE.STANDARD);
    setIsUploading(false);
    clearErrors(['selectedFile']);
  };

  const closeModalHandler = () => {
    resetForm();
    setShowConfirmationModal(false);
    closeModal();
  };

  const handleTemplateChange = (event) => setTemplateType(event.target.value);

  const handleUploadData = async () => {
    setIsUploading(true);
    try {
      const newFile = getValues('selectedFile');
      const listUploadedFiles = await fetchEmissionDataUploadsApi(orgId);
      listUploadedFiles.sort((a, b) => a.upldflId - b.upldflId);
      const duplicateFiles = listUploadedFiles.find(file => newFile.name === file.upldflName);
      if (duplicateFiles) {
        setUploadedFile(newFile);
        closeModal();
        setShowConfirmationModal(true);
      } else {
        await processUpload();
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsUploading(false);
    }
  };

  const uploadDataHandler = async () => {
    setIsUploading(true);
    try {
      await processUpload();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsUploading(false);
    }
  };

  const processUpload = async () => {
    const newFile = getValues('selectedFile') || uploadedFile;
    const { key, name } = await uploadEmissionDataApi(orgId, newFile);
    const data = ({
      key,
      name,
      desc: selectedFileDescription,
      templateType
    });
    await createEmissionDataApi(orgId, data);
    dispatch(fetchEmissionDataUploads());
    setShowConfirmationModal(false);
    closeModalHandler();
    if (onUploadHandler) {
      await onUploadHandler();
    }
  };

  return (
    <Container>
      <Modal show={showModal} onHide={closeModalHandler} className="sisde-modal">
        <Modal.Header>
          <Modal.Title>{t('Upload Emission Data')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group style={{ marginBottom: 0 }}>
              <Controller
                name="selectedFile"
                control={control}
                render={({ field }) => {
                  const handleSelectFile = (e) => field.onChange(e.target.files[0]);
                  return (
                    <Form.File
                      id="emissionFileUploadControl"
                      label={t("Select File")}
                      accept=".csv"
                      className="form-content"
                      onChange={handleSelectFile}
                      isInvalid={errors.selectedFile}
                    />
                  );
                }}
              />
              {errors.selectedFile && <span className="required-span">{errors.selectedFile.message}</span>}
            </Form.Group>
            <p style={{ color: 'red' }}>
              {t('Note: All dates must be in the {{dateFormat}} format', {
                dateFormat: templateType === TEMPLATE_TYPE.BID ? 'DD/MMM/YYYY' : 'YYYY/MM/DD'
              })}
              <br />
              {t('Separators as \'/\', \' \', \'-\', \'.\', \'\\\' are all accepted.')}
            </p>
            <Form.Group className="custom-form-control">
              <Form.Check
                type="radio"
                id={TEMPLATE_TYPE.STANDARD}
                value={TEMPLATE_TYPE.STANDARD}
                checked={templateType === TEMPLATE_TYPE.STANDARD}
                label={t('Standard Template')}
                onChange={handleTemplateChange}
              />

              <Form.Check
                type="radio"
                id={TEMPLATE_TYPE.CUSTOM}
                value={TEMPLATE_TYPE.CUSTOM}
                checked={templateType === TEMPLATE_TYPE.CUSTOM}
                label={t('Custom Template')}
                onChange={handleTemplateChange}
              />

              <Form.Check
                type="radio"
                id={TEMPLATE_TYPE.BID}
                value={TEMPLATE_TYPE.BID}
                checked={templateType === TEMPLATE_TYPE.BID}
                label={t('BID energy Template')}
                onChange={handleTemplateChange}
              />
            </Form.Group>
            <Form.Group className="custom-form-control">
              <Form.Label> {t('Description')} *</Form.Label>
              <Form.Control
                value={selectedFileDescription}
                onChange={(e) => setSelectedFileDescription(e.target.value)}
                type="text"
                as="textarea"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <LoadingButton isLoading={isUploading} />
          <Button variant="secondary" onClick={closeModalHandler}>
            {t('Cancel')}
          </Button>
          <Button variant="primary" onClick={handleUploadData} disabled={!canUpload}>
            {t('Upload File')}
          </Button>
        </Modal.Footer>
      </Modal>
      <UploadDataConfirmForm
        showConfirmationModal={showConfirmationModal}
        closeConfirmationModal={closeModalHandler}
        uploadDataHandler={uploadDataHandler}
        isUploading={isUploading}
      />
    </Container>
  );
};

export default UploadDataForm;
