import { config } from '../../utilities/config';
import keycloak from '../../keycloak';
import { apiRequest } from '../../utilities/apiHelper';

export async function fetchEmissionProfilesTreeApi(organizationId) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/emission-profile/tree`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    }
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function fetchExcludedEPSsApi(organizationId) {
  const url = `/${organizationId}/emission-profile-sources/excluded`;
  return await apiRequest('GET', url);
}

export async function addExcludedEPSsApi(organizationId, data, isToastError = false) {
  const url = `/${organizationId}/emission-profile-sources/excluded`;
  return await apiRequest('POST', url, data, isToastError);
}

export async function removeExcludedEPSsApi(organizationId, id, isToastError = false) {
  const url = `/${organizationId}/emission-profile-sources/excluded/${id}`;
  return await apiRequest('DELETE', url, null, isToastError);
}

export async function fetchEmissionProfilesTreeByScopeApi(organizationId) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/emission-profile/tree-by-scope`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    }
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function getReportingStandardsApi(organizationId) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/emission-profile/reporting-standards`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    }
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function getEmissionSourceTypesApi(organizationId, standardId) {
  const response = await fetch(
    `${config.API_BASE_URL}/${organizationId}/emission-profile/emission-source-type/emission-standard/${standardId}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${keycloak.token}`
      }
    }
  );
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function getEmissionSourcesApi(organizationId, emissionSourceTypeId, emissionStandardId) {
  const response = await fetch(
    `${config.API_BASE_URL}/${organizationId}/emission-profile/emission-source/emission-source-type/${emissionSourceTypeId}/${emissionStandardId}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${keycloak.token}`
      }
    }
  );
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}
export async function getEmissionSourceFactorByEsrcIdApi(organizationId, emissionSourceId) {
  const response = await fetch(
      `${config.API_BASE_URL}/${organizationId}/emission-profile/emission-source/${emissionSourceId}/factors`, {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${keycloak.token}`
          }
      }
  );
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function getEmissionProfileSourceFactorApi(organizationId, emissionProfileSourceId) {
  const response = await fetch(
      `${config.API_BASE_URL}/${organizationId}/emission-profile/emission-profile-source/${emissionProfileSourceId}/factor`, {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${keycloak.token}`
          }
      }
  );
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function createEmissionProfileCategoryApi(organizationId, parentId, data) {
  const response = await fetch(
    `${config.API_BASE_URL}/${organizationId}/emission-profile/emission-profile-category/${parentId}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${keycloak.token}`
      },
      body: JSON.stringify(data)
    }
  );
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function updateEmissionProfileCategoryApi(organizationId, emissionProfileId, data) {
  const response = await fetch(
    `${config.API_BASE_URL}/${organizationId}/emission-profile/emission-profile-category/${emissionProfileId}`,
    {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${keycloak.token}`
      },
      body: JSON.stringify(data)
    }
  );
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function removeEmissionProfileCategoryApi(organizationId, emissionProfileCategoryId) {
  const response = await fetch(
    `${config.API_BASE_URL}/${organizationId}/emission-profile/emission-profile-category/${emissionProfileCategoryId}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${keycloak.token}`
      }
    }
  );
  if (!response.ok) {
    const result = await response.json();
    throw new Error(result.message);
  }
}

export async function createEmissionProfileSourceApi(organizationId, data) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/emission-profile/emission-profile-source`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function updateEmissionProfileSourceApi(organizationId, emissionProfileSourceId, data) {
  const response = await fetch(
    `${config.API_BASE_URL}/${organizationId}/emission-profile/emission-profile-source/${emissionProfileSourceId}`,
    {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${keycloak.token}`
      },
      body: JSON.stringify(data)
    }
  );
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function removeEmissionProfileSourceApi(organizationId, emissionProfileSourceId) {
  const response = await fetch(
    `${config.API_BASE_URL}/${organizationId}/emission-profile/emission-profile-source/${emissionProfileSourceId}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${keycloak.token}`
      }
    }
  );
  if (!response.ok) {
    const result = await response.json();
    throw new Error(result.message);
  }
}

export async function assignAssetApi(organizationId, emissionProfileSourceId, data) {
  const response = await fetch(
    `${config.API_BASE_URL}/${organizationId}/emission-profile/emission-profile-source/${emissionProfileSourceId}/assigned-asset`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${keycloak.token}`
      },
      body: JSON.stringify(data)
    }
  );
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function unassignAssetApi(organizationId, emissionProfileSourceId, assetId) {
  const response = await fetch(
    `${config.API_BASE_URL}/${organizationId}/emission-profile/emission-profile-source/${emissionProfileSourceId}/asset/${assetId}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${keycloak.token}`
      }
    }
  );
  if (!response.ok) {
    const result = await response.json();
    throw new Error(result.message);
  }
}

export async function getAvailableAssetsApi(organizationId, emissionProfileSourceId) {
  const response = await fetch(
    `${config.API_BASE_URL}/${organizationId}/emission-profile/emission-profile-source/${emissionProfileSourceId}/available-asset`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${keycloak.token}`
      }
    }
  );
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function getAssignedAssetsApi(organizationId, emissionProfileSourceId) {
  const response = await fetch(
    `${config.API_BASE_URL}/${organizationId}/emission-profile/emission-profile-source/${emissionProfileSourceId}/assigned-asset`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${keycloak.token}`
      }
    }
  );
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function assignEntitiesApi(organizationId, emissionProfileSourceId, data) {
  const url = `/${organizationId}/emission-profile/emission-profile-source/${emissionProfileSourceId}/assigned-entities`;
  return await apiRequest('POST', url, data, false);
}

export async function assignAssetsApi(organizationId, emissionProfileSourceId, data) {
  const url = `/${organizationId}/emission-profile/emission-profile-source/${emissionProfileSourceId}/assigned-assets`;
  return await apiRequest('POST', url, data, false);
}

export async function changeEmissionProfileCategoryApi(organizationId, emissionProfileSourceId, data) {
  const url = `/${organizationId}/emission-profile/emission-profile-source/${emissionProfileSourceId}/changes-category`;
  return await apiRequest('PATCH', url, data, false);
}

export async function assignEntityApi(organizationId, emissionProfileSourceId, data) {
  const response = await fetch(
    `${config.API_BASE_URL}/${organizationId}/emission-profile/emission-profile-source/${emissionProfileSourceId}/assigned-entity`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${keycloak.token}`
      },
      body: JSON.stringify(data)
    }
  );
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function unassignEntityApi(organizationId, emissionProfileSourceId, entityId) {
  const response = await fetch(
    `${config.API_BASE_URL}/${organizationId}/emission-profile/emission-profile-source/${emissionProfileSourceId}/entity/${entityId}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${keycloak.token}`
      }
    }
  );
  if (!response.ok) {
    const result = await response.json();
    throw new Error(result.message);
  }
}

export async function getAvailableEntitiesApi(organizationId, emissionProfileSourceId) {
  const response = await fetch(
    `${config.API_BASE_URL}/${organizationId}/emission-profile/emission-profile-source/${emissionProfileSourceId}/available-entity`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${keycloak.token}`
      }
    }
  );
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function getAssignedEntitiesApi(organizationId, emissionProfileSourceId) {
  const response = await fetch(
    `${config.API_BASE_URL}/${organizationId}/emission-profile/emission-profile-source/${emissionProfileSourceId}/assigned-entity`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${keycloak.token}`
      }
    }
  );
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function fetchSingleEmissionSourceDataApi(organizationId, emissionSourceId) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/emission-source/${emissionSourceId}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    }
  });
  const data = await response.json();
  if (!response.ok) throw new Error(data.message);
  return data;
}
