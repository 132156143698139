import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { LoadingState } from '../../utilities/constants';
import { createDataControlApi, editDataControlApi, fetchDataControlsApi, removeDataControlApi } from './dataControlApi';

export const fetchDataControls = createAsyncThunk('dataControls/fetchDataControl', async (payload) => {
  try {
    const response = await fetchDataControlsApi(payload.orgId, payload.entityId);
    return response;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const createDataControl = createAsyncThunk('dataControls/createDataControl', async (payload, { dispatch }) => {
  try {
    const { orgId , entityId, data } = payload;
    await createDataControlApi(orgId, entityId, data);
    dispatch(fetchDataControls({ orgId, entityId }));
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const editDataControl = createAsyncThunk('dataControls/editDataControl', async (payload) => {
  try {
    const dataControl = await editDataControlApi(payload.orgId, payload.entityId, payload.dataControlId, payload.data);
    return dataControl;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const removeDataControl = createAsyncThunk('dataControls/removeDataControl', async (payload) => {
  try {
    const dataControl = await removeDataControlApi(payload.orgId, payload.entityId, payload.dataControlId);
    return dataControl;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

const dataControlSlice = createSlice({
  name: 'dataControls',
  initialState: {
    dataControls: [],
    status: LoadingState.idle,
    error: null
  },
  reducers: {},
  extraReducers: {
    [fetchDataControls.pending]: (state) => {
      state.status = LoadingState.loading;
    },
    [fetchDataControls.fulfilled]: (state, action) => {
      state.status = LoadingState.succeeded;
      state.dataControls = action.payload;
    },
    [fetchDataControls.rejected]: (state, action) => {
      state.status = LoadingState.failed;
      state.error = action.error.message;
    },
    [editDataControl.fulfilled]: (state, action) => {
      const existingIndex = state.dataControls.findIndex((i) => i.datactrlId === action.payload.dataControlId);
      if (existingIndex >= 0) state.dataControls[existingIndex] = action.payload;
    }
  }
});

export default dataControlSlice.reducer;
