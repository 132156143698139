import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Container, Row, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import PageHeader from '../../components/PageHeader';
import { LoadingState } from '../../utilities/constants';
import { fetchFavouriteSchedules } from './schedulesSlice';
import { AiOutlineEdit } from 'react-icons/ai';
import DataTable from '../../components/DataTable';
import { Trans, useTranslation } from 'react-i18next';
import Empty from '../../components/Empty';
import { IoTrashOutline } from 'react-icons/io5';
import { fetchFavReports } from '../add-to-favourite/favReportSlice';
import { toast } from 'react-toastify';
import { deleteFavouriteScheduleApi } from './scheduleApi';
import { getSendingIntervalOptions, parseDateRangeValue } from '../reports/reportHelper';
import { FaUser, FaUserFriends } from 'react-icons/fa';
import DeleteConfirmModal from '../../components/DeleteConfirmModal';

const ManageFavouriteSchedules = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const { t } = useTranslation();
  const favouriteSchedulesStatus = useSelector((state) => state.schedules.status);
  const favouriteSchedules = useSelector((state) => state.schedules.favouriteSchedules);
  const favReports = useSelector((state) => state.favouriteReports.favReports);
  const { usrOrgId: orgId } = useSelector((state) => state.users.user);
  const [deletingSchedule, setDeletingSchedule] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDeleteAction = async () => {
    try {
      setIsDeleting(true);
      await deleteFavouriteScheduleApi(orgId, deletingSchedule.id);
      dispatch(fetchFavouriteSchedules({ orgId }));
      setDeletingSchedule(null);
      toast.success(t('The schedule has been successfully deleted.'));
    } catch (e) {
      toast.error(t('An error occurred while trying to delete this schedule. Please try again.'));
    } finally {
      setIsDeleting(false);
    }
  };

  const closeConfirmModal = () => setDeletingSchedule(null);
  const findFavReport = useCallback((id) =>
    favReports.find((item) => id === item.frId), [favReports]);
  const sendingIntervalOptions = getSendingIntervalOptions(t);

  const scheduleTableColumns = useMemo(
    () => [
      {
        Header: t('Report Name'),
        accessor: (row) => {
          return findFavReport(row.cvFrId)?.frName;
        }
      },
      {
        Header: t('Date Range'),
        accessor: (row) => {
          return parseDateRangeValue(favReports, row.cvFrId, t);
        }
      },
      {
        Header: t('Interval'),
        accessor: (row) => {
          return sendingIntervalOptions
            .find(item => item.value === row.sendingInterval)?.label;
        }
      },
      {
        Header: t('Accessibility'),
        accessor: (row) => {
          if (row?.favReport?.frShared === 'Y') {
            return (
              <span><FaUserFriends size="1.2em" className="mr-1" /> {t('Shared in organization')}</span>
            )
          }
          return <span><FaUser className="mr-1" /> {t('Only me')}</span>
        }
      },
      {
        Header: t('Emails'),
        accessor: (row) => {
          return row.emails.join(', ');
        }
      },
      {
        Header: '',
        accessor: 'actions',
        actionColumn: true,
        disableGlobalFilter: false,
        disableSortBy: true,
        Cell: ({ row }) => {
          const schedule = row.original;
          return (
            <div className="d-flex action-buttons">
              <Link to={`${match.url}/${schedule.id}/edit`}>
                <Button variant="outline-secondary">
                  <AiOutlineEdit />
                </Button>
              </Link>
              <Button variant="outline-secondary" className="mr-3" onClick={() => setDeletingSchedule(schedule)}>
                <IoTrashOutline />
              </Button>
            </div>
          );
        }
      }
    ],
    [match.url, t, sendingIntervalOptions, favReports, findFavReport]
  );

  useEffect(() => {
    dispatch(fetchFavouriteSchedules({ orgId }));
  }, [orgId, dispatch]);

  useEffect(() => {
    dispatch(fetchFavReports({ orgId }));
  }, [dispatch, orgId]);

  return (
    <Container fluid>
      <Row>
        <Col>
          <PageHeader
            title={t('Schedules Favourite Reports')}
            actions={
              <Button to={`${match.url}/create`} as={Link}>
                + {t('Create')}
              </Button>
            }
          />
        </Col>
      </Row>
      {favouriteSchedulesStatus === LoadingState.loading && <Spinner animation="grow" />}
      {favouriteSchedulesStatus === LoadingState.succeeded && (
        <Row>
          <Col>
            <DataTable columns={scheduleTableColumns} data={favouriteSchedules} />
          </Col>
        </Row>
      )}
      {favouriteSchedulesStatus === LoadingState.failed && <Empty title="Sorry, Something went wrong" />}
      <DeleteConfirmModal
        isShowModal={!!deletingSchedule}
        closeConfirmModal={closeConfirmModal}
        handleDeleteAction={handleDeleteAction}
        isLoading={isDeleting}
        messageRender={() => (
          <p>
            <Trans
              i18nKey="Would you like to delete the schedule for <bold>{{reportName}}</bold>? This action cannot be undone."
              values={{ reportName: findFavReport(deletingSchedule?.cvFrId)?.frName }}
              components={{ bold: <strong /> }}
            />
          </p>
        )}
      />
    </Container>
  );
};

export default ManageFavouriteSchedules;
