import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PageHeader from '../../../components/PageHeader';
import { LoadingState } from '../../../utilities/constants';
import ActionForm from './ActionForm';
import { getActionsByActId, updateAction } from '../reductionPlanSlice';
import { useParams } from 'react-router-dom/cjs/react-router-dom';

const EditAction = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { actId } = useParams();
  const orgId = useSelector((state) => state.users.user.usrOrgId);
  const [saveActionState, setSaveActionState] = useState(LoadingState.idle);
  const { action, viewActionStatus } = useSelector((state) => state.reductionPlan);
  const isBusy = saveActionState === LoadingState.loading;

  const handleUpdateAction = async (data) => {
    try {
      setSaveActionState(LoadingState.loading);
      const resultAction = await dispatch(updateAction({ orgId, actId, data }));
      unwrapResult(resultAction);
      history.push('/reduction-plan/actions');
    } catch {
      setSaveActionState(LoadingState.idle);
    }
  };

  const handleCancelAction = () => {
    history.push('/reduction-plan/actions');
  };

  useEffect(() => {
    if (orgId && actId) {
      dispatch(getActionsByActId({ orgId, actId }));
    }
  }, [dispatch, orgId, actId]);

  return (
    <Container fluid>
      {isBusy && <Spinner animation="grow" />}
      {!isBusy && viewActionStatus === LoadingState.succeeded && action && (
        <Row>
          <Col>
            <PageHeader
              title={t('Edit action')}
              enableBackButton
            />
            <ActionForm
              onSubmit={handleUpdateAction}
              buttonText={t('Save')}
              initialValues={action}
              disabled={isBusy}
              onCancel={handleCancelAction}
              isEditing
            />
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default EditAction;
