import { config } from '../../utilities/config';
import keycloak from '../../keycloak';

export async function fetchFactorsApi(data) {
  const response = await fetch(`${config.API_BASE_URL}/emissionsource-factor/list?` + new URLSearchParams(data), {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    }
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function editFactorApi(factorId, data) {
  const response = await fetch(`${config.API_BASE_URL}/emissionsource-factor/${factorId}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function removeFactorApi(factorId) {
  const response = await fetch(`${config.API_BASE_URL}/emissionsource-factor/${factorId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    }
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}
