import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const DiscardModal = ({ isShowModal, onCancelHandler, onDiscardHandler, title, message }) => {
  const { t } = useTranslation();
  return (
    <Modal show={isShowModal} onHide={onCancelHandler}>
      <Modal.Header>
        <Modal.Title>
          {title || t('Discard changes?')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {message || t('You have unsaved changes. Are you sure to discard them and leave the screen?')}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={onCancelHandler}
        >
          {t('Cancel')}
        </Button>
        <Button
          variant="primary"
          onClick={onDiscardHandler}
        >
          {t('Confirm')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DiscardModal;
