import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { LoadingState } from '../../utilities/constants';
import {
  fetchBulkInsertUploadsApi,
  uploadBulkInsertApi, downloadBulkInsertFileApi
} from './bulkInsertApi';

export const fetchBulkUploads = createAsyncThunk('bulkInsert/fetch-bulk-uploads', async (payload) => {
  try {
    const { orgId} = payload;
    return await fetchBulkInsertUploadsApi(orgId);
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const uploadBulkInsert = createAsyncThunk('bulkInsert/upload-bulk-insert', async (payload, { dispatch }) => {
  try {
    const { orgId, data, uploadType } = payload;
    const formData = new FormData();
    formData.append('file', data.file);
    formData.append('name', data.file.name)
    formData.append('desc', data.desc)
    formData.append('uploadType', uploadType)
    const response = await uploadBulkInsertApi(orgId, formData);
    await dispatch(fetchBulkUploads({ orgId }));
    return response;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const getBulkInsertDownloadLink = createAsyncThunk('bulkInsert/get-bulk-download-link', async (payload, thunkAPI) => {
  try {
    const { usrOrgId } = thunkAPI.getState().users.user;
    const response = await downloadBulkInsertFileApi(usrOrgId, payload.upldflId, !!payload.errorFile);
    return response;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

const bulkInsertSlice = createSlice({
  name: 'bulkInsert',
  initialState: {
    assetUploads: [],
    entityUploads: [],
    epsUploads: [],
    bulkUploads: [],
    bulkUploadsStatus: LoadingState.idle,
    error: null
  },
  reducers: {},
  extraReducers: {
    [fetchBulkUploads.pending]: (state) => {
      state.bulkUploadsStatus = LoadingState.loading;
    },
    [fetchBulkUploads.fulfilled]: (state, action) => {
      state.bulkUploadsStatus = LoadingState.succeeded;
      state.bulkUploads = action.payload;
    },
    [fetchBulkUploads.rejected]: (state) => {
      state.bulkUploadsStatus = LoadingState.failed;
    },
  }
});

export default bulkInsertSlice.reducer;
