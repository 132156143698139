import React, { Fragment } from 'react';
import { useTable, useSortBy, useFilters, useExpanded, usePagination, useGlobalFilter } from 'react-table';
import { Table, Row, Col, Button, ButtonGroup, ButtonToolbar } from 'react-bootstrap';
import { 
  // Filter, 
  DefaultColumnFilter, GlobalFilter } from './filters';
import { BiChevronDown, BiChevronUp, BiChevronLeft, BiChevronRight, BiChevronsLeft, BiChevronsRight  } from 'react-icons/bi';
import { Trans, useTranslation } from 'react-i18next';

export const TableContainer = ({
  columns,
  data,
  renderRowSubComponent,
  globalSearch = false,
  columnSort = false,
  columnFilter = false,
  rowsPerPage = 5
}) => {
  const { t } = useTranslation();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    preGlobalFilteredRows,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter }
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: (props) => DefaultColumnFilter(props, t) },
      initialState: { pageIndex: 0, pageSize: rowsPerPage }
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (
      column.isSortedDesc ? (
        <span className='ml-1 table-sort-span'>
          <BiChevronUp className='table-sort-icon table-sort-selected' />
          <BiChevronDown />
        </span>
      ) : (
        <span className='ml-1 table-sort-span'>
          <BiChevronUp className='table-sort-icon' />
          <BiChevronDown className='table-sort-selected' />
        </span>
      )
    ) : (
      <span className='ml-1 table-sort-span'>
        <BiChevronUp className='table-sort-icon' />
        <BiChevronDown />
      </span>
    );
    // return column.isSortedDesc ? ' 🔽' : ' 🔼';
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  return (
    <Fragment>
      <Row>
        <Col lg='5'>
          {globalSearch && (
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          )}
        </Col>
      </Row>

      <Table className='custom-table' responsive {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, idx) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>
                  <div {...column.getSortByToggleProps()}>
                    {column.render('Header')}
                    {columnSort && <Fragment key={idx}>{generateSortingIndicator(column)}</Fragment>}
                  </div>
                  {/* {columnFilter && <Filter column={column} />} */}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <Fragment key={row.getRowProps().key}>
                <tr>
                  {row.cells.map((cell) => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                  })}
                </tr>
                {/* {row.isExpanded && (
                  <tr>
                    <td colSpan={visibleColumns.length}>
                      {renderRowSubComponent(row)}
                    </td>
                  </tr>
                )} */}
              </Fragment>
            );
          })}
        </tbody>
      </Table>

      <Row className='new-pagination'>
        <Col>
          <div>
            <Trans
            i18nKey="Page <bold>{{from}} of {{total}}</bold>"
            values={{ 
              from: pageIndex + 1, 
              total: pageOptions.length,
            }}
            components={{ bold: <strong /> }}
          />
          </div>
        </Col>
        <Col className='pagination-controler d-flex justify-content-end'>
          <div>
            <ButtonToolbar>
              <ButtonGroup>
                <Button size='sm' variant='secondary' className='mr-1' onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                  <BiChevronsLeft/>
                </Button>
                <Button size='sm' variant='secondary' className='mr-1' onClick={previousPage} disabled={!canPreviousPage}>
                  <BiChevronLeft/>
                </Button>
              </ButtonGroup>
            </ButtonToolbar>
          </div>

        <div className='mr-1'>
          <input
            className='form-control'
            type='number'
            min={1}
            style={{ width: 70 }}
            max={pageOptions.length}
            defaultValue={pageIndex + 1}
            onChange={onChangeInInput}
          />
        </div>

        <div className='mr-1'>
          <select className='form-control' value={pageSize} onChange={onChangeInSelect}>
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {t('Show {{pageSize}}', { pageSize })}
              </option>
            ))}
          </select>
          </div>

        <div>
          <ButtonToolbar>
            <ButtonGroup>
              <Button size='sm' variant='secondary' className='mr-1' onClick={nextPage} disabled={!canNextPage}>
                <BiChevronRight/>
              </Button>
              <Button size='sm' variant='secondary' onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                <BiChevronsRight/>
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
          </div>
        </Col>
      </Row>

    </Fragment>
  );
};

export default TableContainer;
