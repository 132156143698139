import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { LoadingState } from '../../../utilities/constants';
import {
  fetchEmissionDataUploadsApi,
  getDownloadLinkApi,
} from './uploadDataApi';

export const fetchEmissionDataUploads = createAsyncThunk(
  'uploadData/fetchEmissionDataUploads',
  async (payload, thunkAPI) => {
    try {
      const { usrOrgId } = thunkAPI.getState().users.user;
      const response = await fetchEmissionDataUploadsApi(usrOrgId);
      return response;
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  }
);

export const getDownloadLink = createAsyncThunk('uploadData/getDownloadLink', async (payload, thunkAPI) => {
  try {
    const { usrOrgId } = thunkAPI.getState().users.user;
    const response = await getDownloadLinkApi(usrOrgId, payload.upldflId, payload.errorFile ? true : false);
    return response;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

const uploadDataSlice = createSlice({
  name: 'uploadData',
  initialState: {
    uploads: [],
    status: LoadingState.idle
  },
  reducers: {},
  extraReducers: {
    [fetchEmissionDataUploads.pending]: (state) => {
      state.status = LoadingState.loading;
    },
    [fetchEmissionDataUploads.fulfilled]: (state, action) => {
      state.status = LoadingState.succeeded;
      state.uploads = action.payload;
    }
  }
});

export default uploadDataSlice.reducer;
