import React, { useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PageHeader from '../../components/PageHeader';
import TreeViewModal from '../../components/TreeViewModal';
import { LoadingState } from '../../utilities/constants';
import { recursiveMap } from '../../utilities/treeHelpers';
import {
  fetchEmissionProfileTreeByEntity,
  getSelectedEntity,
  getSelectedEP,
  setSelectedEntity,
  setSelectedEP
} from './emissionSlice';
import { ImStack } from 'react-icons/im';
import { IoLeafOutline } from 'react-icons/io5';

const EntityEPSelector = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const orgId = useSelector((state) => state.users.user.usrOrgId);

  const loadingEntities = useSelector((state) => state.entities.status) === LoadingState.loading;
  const loadingEP = useSelector((state) => state.emissions.status) === LoadingState.loading;

  const selectedEntity = useSelector(getSelectedEntity);
  const entityTree = useSelector((state) => state.entities.tree);
  const [showEntitySelector, setShowEntitySelector] = useState(!selectedEntity);

  const selectedEP = useSelector(getSelectedEP);
  const EPTree = useSelector((state) => state.emissions.entityEPTree);
  const [showEPSelector, setShowEPSelector] = useState(false);

  const openEntityModal = () => setShowEntitySelector(true);
  const closeEntityModal = () => setShowEntitySelector(false);

  const openEPModal = () => {
    setShowEPSelector(true);
    dispatch(fetchEmissionProfileTreeByEntity({ orgId, entityId: selectedEntity.divId }));
  }
  const closeEPModal = () => setShowEPSelector(false);

  const handleSelectEntity = (entityId) => {
    dispatch(setSelectedEntity(entityId));
    dispatch(fetchEmissionProfileTreeByEntity({ orgId, entityId }));
    closeEntityModal();
    setShowEPSelector(true);
  };

  const handleSelectEP = (id) => {
    dispatch(setSelectedEP(id));
    closeEPModal();
  };

  const transformedEntityTree = recursiveMap(entityTree ?? {}, (n) => ({
    value: n.divId,
    valueId: n.divAssignedId,
    labelRaw: n.divName,
    isAssigned: n.divIsAssigned ?? false,
    label: (
      <div className={`flex-grow-1 ${n.divIsAssigned ? "enabled": "disabled"}`} onClick={() => n.divIsAssigned ? handleSelectEntity(n.divId) : null}>
        {n.divName}
      </div>
    )
  }));

  const transformedEPTree = recursiveMap(EPTree ?? {}, (n) => {
    return {
      value: n.eprflId,
      valueId: n.eprflsrcAssignedId ? n.eprflsrcAssignedId : null,
      labelRaw: !!n.eprflsrcName ? n.eprflsrcName : !!n.eprflName ? n.eprflName : n.eprflcatName,
      label:
        n.eprflsrcId !== null && n.eprflsrcId !== undefined ? (
          <div className="flex-grow-1" style={{ cursor: 'pointer' }} onClick={() => handleSelectEP(n.eprflsrcId)}>
            <IoLeafOutline />
            {n.eprflsrcName}
          </div>
        ) : (
          <span>
            <ImStack /> {n.eprflName || n.eprflcatName}
          </span>
        )
    };
  });

  return (
    <>
      {loadingEntities ? (
        <Spinner animation="grow" />
      ) : (
        <PageHeader
          title={t('Manage Emission Data')}
          actions={
            <Button variant="secondary" onClick={openEntityModal}>
              {t('Select Entities')}
            </Button>
          }
        />
      )}
      {selectedEntity &&
        (loadingEP ? (
          <Spinner animation="grow" />
        ) : (
          <PageHeader.Secondary
            title={selectedEntity.divName}
            actions={
              <Button size="sm" variant="secondary" onClick={openEPModal}>
                {t('Select Emission Source')}
              </Button>
            }
          />
        ))}
      {selectedEntity && selectedEP && <PageHeader.Secondary title={selectedEP.eprflsrcName} />}
      <TreeViewModal
        tree={transformedEntityTree}
        closeModal={closeEntityModal}
        show={showEntitySelector}
        backdrop={!!selectedEntity}
        title={t('Select Entity')}
      />
      <TreeViewModal
        tree={transformedEPTree}
        closeModal={closeEPModal}
        show={showEPSelector}
        title={t('Select Emission Profile')}
      />
    </>
  );
};

export default EntityEPSelector;
