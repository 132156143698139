import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FiPlus } from 'react-icons/fi';
import FormData from './FormData';
import EnergyContentTable from './EnergyContentTable';
import { createFactor, fetchUnitList } from './EnergyContentSlice';
import { useDispatch, useSelector } from 'react-redux';
import PageHeader from '../../components/PageHeader';

const ManageEnergyContentFactor = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [showCreateModal, setShowCreateModal] = useState(false);
  const units = useSelector((state) => state.energyContent.units);
  const factors = useSelector((state) => state.energyContent.factors);
  const fetchStatus = useSelector((state) => state.energyContent.status);

  const closeModal = () => setShowCreateModal(false);
  const openModal = () => setShowCreateModal(true);
  const handleSubmitForm = async (data) => {
    try {
      await dispatch(createFactor({ data }));
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    dispatch(fetchUnitList());
  }, [dispatch]);

  const filterUnits = () => {
      let res = Object.assign({}, units)
      factors.forEach(f => {
          if(res[f.unit]) delete res[f.unit]
      })
    return res
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <PageHeader
            title={t('Energy Content Management')}
            actions={
              <Button onClick={openModal}>
                <FiPlus className="mr-1" />
                {t('Create Energy Content')}
              </Button>
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EnergyContentTable dispatch={dispatch} t={t} factors={factors} fetchStatus={fetchStatus} />
        </Col>
      </Row>
      <FormData
        dispatch={dispatch}
        t={t}
        showModal={showCreateModal}
        closeModal={closeModal}
        units={filterUnits()}
        onSubmitHandler={handleSubmitForm}
      />
    </Container>
  );
};

export default ManageEnergyContentFactor;
