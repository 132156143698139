import React, { useEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { setLeavePrompt } from '../manage-users/usersSlice';
import { useDispatch } from 'react-redux';
import LoadingButton from '../../components/LoadingButton';

const createSchema = yup.object().shape({
  domain: yup.string()
    .matches(
      /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/,
      'Please enter a valid domain. For example: v5.carbon-view.com',
    ).required('Domain is required'),
  name: yup.string().max(128).required(),
  logoUrl: yup.string().url('Logo is must be a valid URL').required('Logo is required'),
  loginLogoUrl: yup.string().url('Login Logo is must be a valid URL').nullable().notRequired(),
  loginBackgroundUrl: yup.string().url('Login background is must be a valid URL').nullable().notRequired(),
  supportEmail: yup.string().email('Support Email is must be a valid email address').nullable().notRequired()
});

const editSchema = yup.object().shape({
  domain: yup.string()
    .matches(
      /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/,
      'Please enter a valid domain. For example: v5.carbon-view.com',
    ).required('Domain is required'),
  name: yup.string().max(128).required(),
  logoUrl: yup.string().url('Logo is must be a valid URL').required('Logo is required'),
  loginLogoUrl: yup.string().url('Login Logo is must be a valid URL').nullable().notRequired(),
  loginBackgroundUrl: yup.string().url('Login background is must be a valid URL').nullable().notRequired(),
  supportEmail: yup.string().email('Support Email is must be a valid email address').nullable().notRequired(),
});

const WhitelabelForm = ({ onSubmit, onCancel, initialValues = {}, buttonText = 'Create', isEditing = false, disabled }) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid, isDirty }
  } = useForm({
    defaultValues: { ...initialValues },
    resolver: yupResolver(isEditing ? editSchema : createSchema),
    mode: 'onChange'
  });
  const { t } = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLeavePrompt(isDirty));
  }, [isDirty, dispatch]);

  const onSubmitHandler = async (data) => {
    try {
      dispatch(setLeavePrompt(false));
      await onSubmit(data);
    } catch (e) {
      dispatch(setLeavePrompt(isDirty));
    }
  };

  const onCancelHandler = () => onCancel();

  return (
    <Form onSubmit={handleSubmit(onSubmitHandler)}>
      <Row>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>Domain *</Form.Label>
            <Form.Control {...register('domain')} isInvalid={errors.domain} />
            <Form.Control.Feedback type="invalid">{errors.domain?.message}</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>Logo *</Form.Label>
            <Form.Control {...register('logoUrl')} isInvalid={errors.logoUrl} />
            <Form.Control.Feedback type="invalid">{errors.logoUrl?.message}</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>Name *</Form.Label>
            <Form.Control {...register('name')} isInvalid={errors.name} />
            <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>Product Name</Form.Label>
            <Form.Control {...register('productName')} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>Login Logo </Form.Label>
            <Form.Control {...register('loginLogoUrl')} />
            <Form.Control.Feedback type="invalid">{errors.loginLogoUrl?.message}</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>Login Background</Form.Label>
            <Form.Control {...register('loginBackgroundUrl')} isInvalid={errors.loginBackgroundUrl} />
            <Form.Control.Feedback type="invalid">{errors.loginBackgroundUrl?.message}</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>Support Email</Form.Label>
            <Form.Control {...register('supportEmail')} isInvalid={errors.supportEmail} />
            <Form.Control.Feedback type="invalid">{errors.supportEmail?.message}</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>Support Phone</Form.Label>
            <Form.Control {...register('supportPhone')} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>UI Primary Color</Form.Label>
            <Form.Control type="color" defaultValue="#edf1fb" title="Choose your color" {...register('uiPrimaryColor')} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>UI Second Color</Form.Label>
            <Form.Control type="color" defaultValue="#1867c0" title="Choose your color" {...register('uiSecondColor')} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>Terms and Condtions (Maskdown format)</Form.Label>
            <Form.Control as="textarea" rows="5" style={{ resize: 'none' }} {...register('uiTermsContent')} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>Privacy Statement (Maskdown format)</Form.Label>
            <Form.Control as="textarea" rows="5" style={{ resize: 'none' }} {...register('uiPrivacyContent')} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>Mailgun Region</Form.Label>
            <Controller
              name="mailgunRegion"
              control={control}
              render={({ field }) => {
                const parsed = field.value || 'Global';
                const format = (d) => field.onChange(d);
                return (
                  <select value={parsed} onChange={format} className="form-control">
                    <option value="Global">Global Region</option>
                    <option value="Eu">Eu Region</option>
                  </select>
                );
              }}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>Mailgun Verified Domain</Form.Label>
            <Form.Control {...register('mailgunDomain')} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>Mailgun Key</Form.Label>
            <Form.Control {...register('mailgunKey')} />
          </Form.Group>
        </Col>
      </Row>
      <Button className="mt-3 mb-5" type="submit" disabled={disabled || !isValid || !isDirty}>
        {buttonText}
      </Button>
      <Button variant="secondary" className="ml-4 mt-3 mb-5" onClick={onCancelHandler}>
        {t('Cancel')}
      </Button>
      <LoadingButton isLoading={disabled} className="ml-4 mt-3 mb-5" />
    </Form>
  );
};

export default WhitelabelForm;
