import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import TreeView from './TreeView';
import _ from 'lodash';

const TreeViewModal = ({ tree, show, closeModal, title, footerHandler = null, enableSearch = true }) => {
  const [treeState, setTreeState] = useState({ isSearching: false, action: null, payload: {} });
  const renderFooter = () => {
    if (!_.isFunction(footerHandler)) {
      return null;
    }
    return (
      <Modal.Footer className="custom-footer">
        {footerHandler()}
      </Modal.Footer>
    );
  };
  return (
    <Modal show={show} onHide={closeModal} className="side-modal">
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TreeView
          tree={tree}
          enableSearch={enableSearch}
          treeState={treeState}
          setTreeState={setTreeState}
          searchFunction={(n, searchText) => (n.labelRaw && n.labelRaw.toLowerCase().includes(searchText.toLowerCase())) ||
            (n.valueId && n.valueId.toLowerCase().includes(searchText.toLowerCase()))}
        />
      </Modal.Body>
      {renderFooter()}
    </Modal>
  );
};

export default TreeViewModal;
