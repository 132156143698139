import keycloak from '../../../keycloak';
import { uploadToS3 } from '../../../utilities/apiHelper';
import { config } from '../../../utilities/config';

export async function fetchEmissionDataUploadsApi(organizationId) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/emission-upload`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    }
  });
  const data = await response.json();
  if (!response.ok) throw new Error(data.message);
  return data;
}

export async function uploadEmissionDataApi(organizationId, file) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/emission-upload/generate`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    }
  });
  const signedPost = await response.json();
  if (!response.ok) throw new Error(signedPost.message);
  await uploadToS3(signedPost, file);
  return { key: signedPost.fields.key, name: file.name };
}

export async function createEmissionDataApi(organizationId, data) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/emission-upload`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${keycloak.token}`
    },
    body: JSON.stringify(data),
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function getDownloadLinkApi(organizationId, uploadedFileId, errorFile = false) {
  let url = `${config.API_BASE_URL}/${organizationId}/emission-upload/${uploadedFileId}`;
  if (errorFile) url = `${url}/errors`;
  const response = await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    }
  });
  const data = await response.json();
  if (!response.ok) throw new Error(data.message);
  return data;
}
