import React, { useEffect } from 'react';
import { Container, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useRouteMatch } from 'react-router';
import Empty from '../../components/Empty';
import { LoadingState } from '../../utilities/constants';
import { fetchAssignedEpsForEpsGroup, fetchEpsGroups } from './epsGroupSlice';
import NewEpsGroup from './NewEpsGroup';

const EpsGroupsHome = () => {
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const epsGroupStatus = useSelector((state) => state.epsGroups.status);
  const epsGroups = useSelector((state) => state.epsGroups.epsGroups);
  const orgId = useSelector((state) => state.users.user.usrOrgId);

  useEffect(() => {
    if (epsGroupStatus === LoadingState.idle) {
      dispatch(fetchEpsGroups({ orgId }));
    }

    if (epsGroupStatus === LoadingState.succeeded && epsGroups?.length > 0) {
      const epsGroupId = epsGroups[0].epgId;
      dispatch(fetchAssignedEpsForEpsGroup({ orgId, epsGroupId }));
    }
  }, [orgId, epsGroupStatus, epsGroups, dispatch]);

  return (
    <Container style={{ paddingLeft: '0px', paddingRight: '0px' }}>
      {epsGroupStatus === LoadingState.loading && <Spinner animation="grow" />}
      {epsGroupStatus === LoadingState.succeeded && epsGroups?.length > 0 ? (
        <Redirect to={`${match.url}/${epsGroups[0].epgId}`} />
      ) : (
        <NewEpsGroup />
      )}
      {epsGroupStatus === LoadingState.failed && <Empty title="Sorry, Something went wrong" />}
    </Container>
  );
};

export default EpsGroupsHome;
