import React, {
  Suspense,
  useCallback,
  useEffect,
  useState,
} from 'react';
import '../styles/style.scss';
import Routes from '../Routes';
import keycloak from '../keycloak';
import { config } from '../utilities/config';
import { Spinner } from 'react-bootstrap';
import i18next from 'i18next';
import { initReactI18next, I18nextProvider } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import { useDispatch } from 'react-redux';
import useIdle from '../utilities/useIdle';
import { fetchUser } from '../features/manage-users/usersSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import * as userImpersonate from './userImpersonate';
import { initAppLocale } from '../utilities/common';
import KeycloakProvider from './KeycloakProvider';

const App = () => {
  const dispatch = useDispatch();
  const initOptions = { pkceMethod: 'S256' };
  const defaultIdleTimeout = 60 * 60; // 60 minutes
  const idleTimeout = Number(config.USER_IDLE_TIMEOUT);
  const isIdle = useIdle(idleTimeout || defaultIdleTimeout);
  const [updateToken, setUpdateToken] = useState(null);

  const getKeycloakLifeSpan = useCallback(() => {
    const lifeSpan = keycloak?.tokenParsed?.exp - keycloak?.tokenParsed?.iat;
    return lifeSpan >= 60 ? lifeSpan : 300;
  }, []);

  const updateKeycloakToken = useCallback(async () => {
    try {
      if (isIdle) {
        throw new Error('The user is idle');
      }
      const lifeSpan = getKeycloakLifeSpan();
      await keycloak.updateToken(lifeSpan);
      setUpdateToken(new Date());
    } catch {
      keycloak.logout();
    }
  }, [isIdle, getKeycloakLifeSpan]);

  useEffect(() => {
    let timeoutId;
    if (updateToken) {
      const lifeSpan = getKeycloakLifeSpan();
      const delayTime = (lifeSpan - 30) * 1000;
      // console.log('Update token in :', new Date(new Date().getTime() + delayTime));
      timeoutId = setTimeout(updateKeycloakToken, delayTime);
    }
    return () => clearTimeout(timeoutId);
  }, [updateToken, updateKeycloakToken, getKeycloakLifeSpan]);

  const handleOnEvent = async (event) => {
    if (event === 'onAuthSuccess') {
      if (keycloak.authenticated) {
        await handleUser(keycloak);
        setUpdateToken(new Date());
      }
    } else if (event === 'onTokenExpired') {
      await updateKeycloakToken();
    }
  };

  const handleUser = async (data) => {
    try {
      userImpersonate.setupApiRequests(data.subject);
      const userKeycloakId = userImpersonate.getKeycloakId(data.subject);
      const isRootAdmin = data.hasResourceRole('cv_root', config.KEYCLOAK_BACKEND_CLIENT_ID);
      const resultAction = await dispatch(fetchUser({ userKeycloakId }));
      if (resultAction.payload) {
        unwrapResult(resultAction);
        const { optLocale, usrOrgId, usrOrgOptions } = resultAction.payload;
        const locale = optLocale || usrOrgOptions?.optLocale || config.DEFAULT_LOCALE;
        initAppLocale(locale);
        i18next
          .use(initReactI18next)
          .use(HttpApi)
          .init({
            fallbackLng: false,
            lng: locale,
            saveMissing: isRootAdmin,
            keySeparator: false,
            nsSeparator: false,
            pluralSeparator: false,
            contextSeparator: false,
            interpolation: { escapeValue: false },
            backend: {
              loadPath: `${config.API_BASE_URL}/${usrOrgId}/translation/{{lng}}`,
              addPath: `${config.API_BASE_URL}/organization/translation`,
              customHeaders: {
                Authorization: userImpersonate.getToken(`Bearer ${keycloak.token}`),
              }
            }
          });
      } else {
        keycloak.logout();
      }
    } catch (e) {
      console.log(e);
      keycloak.logout();
    }
  };

  const loadingComponent = (
    <div className="loader-container">
      <Spinner className="loader" animation="grow" />
    </div>
  );

  return (
    <Suspense fallback={loadingComponent}>
      <I18nextProvider i18n={i18next}>
        <KeycloakProvider
          authClient={keycloak}
          autoRefreshToken={false}
          initOptions={initOptions}
          LoadingComponent={loadingComponent}
          onEvent={(event, error) => handleOnEvent(event, error)}
        >
          <Routes />
        </KeycloakProvider>
      </I18nextProvider>
    </Suspense>
  );
};

export default App;
