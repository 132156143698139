import React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

const RemoveSms2FaForm = ({
  onSubmit,
  initialValues = {},
  disabled,
}) => {
  const { t } = useTranslation();
  const validateSchema = yup.object().shape({
    currentPassword: yup.string().max(128).required(t('Please enter current password')),
  });
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm({
    defaultValues: {
      currentPassword: '',
      mobileNumber: '',
      ...initialValues,
    },
    resolver: yupResolver(validateSchema),
    mode: 'onChange'
  });
  const onSubmitHandler = (data) => onSubmit(data);

  return (
    <Form onSubmit={handleSubmit(onSubmitHandler)}>
      <div className={'form-divider mb-4 mt-4'}>
        <span>{t('You’re protected by SMS two-factor authentication')}</span>
        <div></div>
      </div>
      <div className="mb-6">
        <p>
          {t('Your Account has SMS two-factor authentication, a proven way to protect your account and keep the internet safer from cyberattacks')}
        </p>
      </div>
      <Row>
        <Col md={12}>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Current Password')} *</Form.Label>
            <Form.Control
              type="password"
              {...register('currentPassword')}
              isInvalid={errors.currentPassword}
              placeholder={t('Enter your current password')}
            />
            <Form.Control.Feedback type="invalid">{errors.currentPassword?.message}</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md={12}>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Mobile Number')} *</Form.Label>
            <Form.Control {...register('mobileNumber')} isInvalid={errors.mobileNumber} readOnly />
            <Form.Control.Feedback type="invalid">{errors.mobileNumber?.message}</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Button type="submit" className="btn-danger" disabled={!isValid || !isDirty || disabled}>
        {t('Turn Off')}
      </Button>
    </Form>
  );
};

export default RemoveSms2FaForm;
