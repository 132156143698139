import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import PageHeader from '../../components/PageHeader';
import AccountForm from './AccountForm';
import { useTranslation } from 'react-i18next';
import { createAccountApi } from './accountsApi';
import { toast } from 'react-toastify';

const NewAccount = () => {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();
  const orgId = useSelector((state) => state.users.user.usrOrgId);

  const handleCreateUser = async (data) => {
    try {
      setIsLoading(true);
      await createAccountApi(orgId, data);
      toast.success(t('New Account created'));
      history.push('/admin/users/accounts');
    } catch (error) {
      setIsLoading(false);
      toast.error(error.message);
      throw error;
    }
  };

  const handleCancelAccount = () => {
    history.push('/admin/users/accounts');
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <PageHeader title={t('Create Account')} />
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <AccountForm
            onSubmit={handleCreateUser}
            buttonText={t('Create')}
            isLoading={isLoading}
            onCancel={handleCancelAccount}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default NewAccount;
