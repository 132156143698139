import * as yup from 'yup';
import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingState } from '../../utilities/constants';
import { fetchFavReports } from './favReportSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import LoadingButton from '../../components/LoadingButton';
import { createFavReportApi } from './favReportsApi';

const AddToFavourite = ({ reportPath, filterPeriodOptions, filterJsonParams, show = false, manageModal }) => {
  const { t } = useTranslation();
  const schema = yup.object().shape({
    frName: yup.string().required(t('Report name is required')),
    frShared: yup.string().nullable().notRequired(),
    frRepFilterPeriodParam: yup.string().required(t('Date range is a required field'))
  });
  const {
    handleSubmit,
    control,
    register,
    setValue,
    reset,
    formState: { errors, isDirty, isValid }
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(schema),
    mode: 'onChange'
  });

  const dispatch = useDispatch();
  const [saveFavReportState, setSaveFavReportState] = useState(LoadingState.idle);
  const isBusy = saveFavReportState === LoadingState.loading;
  const orgId = useSelector((state) => state.users.user.usrOrgId);

  const [showModal, setShowModal] = useState(show);
  const handleCloseModal = () => {
    manageModal(false);
    setShowModal(false);
  };
  const closeModal = () => {
    manageModal(false);
    setShowModal(false);
    setValue('frName', null);
    setValue('frShared', null);
    setValue('frRepFilterPeriodParam', null);
    reset();
  };

  const handleFavReportData = async (data) => {
    const { frRepFilterPeriodParam, ...formattedData } = data;
    const filtersWithConfiguration = {
      ...formattedData,
      frRepParamJson: { ...filterJsonParams, frRepFilterPeriodParam: data.frRepFilterPeriodParam }
    };

    const combinedData = {
      ...filtersWithConfiguration,
      frRepCategoryName: reportPath
    };

    try {
      setSaveFavReportState(LoadingState.loading);
      await createFavReportApi(orgId, combinedData);
      setSaveFavReportState(LoadingState.succeeded);
      dispatch(fetchFavReports({ orgId }));
      toast.success(t('A new favorite report has been created.'));
      closeModal();
    } catch(err) {
      toast.error(t('The favorite report name must be unique.'));
      setSaveFavReportState(LoadingState.failed);
    }
  };

  return (
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header>
        <Modal.Title>{t('Favourite Report')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(handleFavReportData)}>
          <Form.Group>
            <Form.Label>{t('Name')}*</Form.Label>
            <Form.Control {...register('frName')} isInvalid={errors.frName} />
            <Form.Control.Feedback type="invalid">{errors.frName?.message}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>{t('Date range')}*</Form.Label>
            <Controller
              {...register('frRepFilterPeriodParam')}
              control={control}
              render={({ field, fieldState: { invalid, error } }) => {
                const parsed = field.value ? field.value : null;
                const format = (d) => {
                  field.onChange(d.value);
                };
                return (
                  <Select
                    options={filterPeriodOptions}
                    selected={parsed}
                    onChange={format}
                    placeholder={t('Select')}
                    isInvalid={invalid}
                  />
                );
              }}
            />
            <Form.Control.Feedback type="invalid">{errors.frRepFilterPeriodParam?.message}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="custom-form-control" controlId="modalForm.ControlInput3">
            <Controller
              {...register('frShared')}
              control={control}
              defaultValue="N"
              render={({ field }) => {
                const parsed = field.value === 'Y' ? true : false;
                const format = () => {
                  field.onChange(field.value === 'Y' ? 'N' : 'Y');
                };
                return (
                  <Form.Check
                    custom id="frShared"
                    onChange={format}
                    checked={parsed}
                    label={t("Share in organization")} />
                );
              }}
            />
          </Form.Group>
          <Button
            className="mr-2"
            variant="secondary"
            onClick={() => {
              closeModal();
            }}
          >
            {t('Cancel')}
          </Button>
          <Button type="submit" variant="primary" disabled={!isDirty || !isValid || isBusy}>
            {t('Create')}
          </Button>
          <LoadingButton isLoading={isBusy} />
        </Form>
      </Modal.Body>
    </Modal>
  );
};
export default AddToFavourite;
