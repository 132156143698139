import React from 'react';
import { useForm } from 'react-hook-form';
import { Button, Form } from 'react-bootstrap';

function Configuration({ defaultValues, children, onSubmit, primaryBtnText }) {
  const { register, handleSubmit, control } = useForm({
    defaultValues
  });
  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="configuration-form-control">
      <div className="configuration-row">
        {Array.isArray(children)
          ? children.map((child) => {
              return child.props.name
                ? React.createElement(child.type, {
                    ...{
                      ...child.props,
                      register,
                      control,
                      key: child.props.name
                    }
                  })
                : child;
            })
          : children}
        <div className="submit-button">
          <Button variant="primary" type="submit">
            {primaryBtnText}
          </Button>
        </div>
      </div>
    </Form>
  );
}

export default Configuration;
