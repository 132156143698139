import React, { forwardRef, useImperativeHandle } from 'react';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { Button, Form, Accordion } from 'react-bootstrap';
import DeleteButton from '../../add-to-favourite/DeleteButton';
import { IoSettingsOutline } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';

const getCacheKey = (key) => `report-config-${key}`;

export const getDisplayColumns = (key, favActive, defaultActive = {}) => {
  if (favActive) {
    return favActive;
  }
  try {
    const saveColumns = JSON.parse(localStorage.getItem(getCacheKey(key)));
    if (saveColumns) {
      return saveColumns;
    }
  } catch (e) {

  }
  return defaultActive;
};

const Configuration = forwardRef(({
  defaultValues,
  children,
  onSubmit,
  onFormChange,
  primaryBtnText,
  dropFavOption = false,
  reportRarameter,
  setReportRarameter,
  favReportName = null,
  isFavReport = false,
  isOwnerFavReport = false,
  isSubmitting = false,
  reportColumnConfig = {
    key: null,
    items: [],
    active: {},
    setActive: () => { },
  },
}, ref) => {
  const { t } = useTranslation();

  if (!primaryBtnText) {
    primaryBtnText = t('Generate Report');
  }
  const { register, handleSubmit, control, setValue } = useForm({
    defaultValues
  });
  useImperativeHandle(ref, () => ({
    setFormValue: (field, value) => {
      onFormChange(field, value);
      return setValue(field, value);
    },
  }));
  const onActiveColumnsHandler = (id, checked) => {
    const { key, active, setActive } = reportColumnConfig;
    const newActiveColumns = {
      ...active,
      [id]: checked,
    };
    setActive(newActiveColumns);
    localStorage.setItem(getCacheKey(key), JSON.stringify(newActiveColumns));
  };
  const onSubmitGenerateReport = handleSubmit(onSubmit);
  const onSubmitFavouriteReport = handleSubmit(onSubmit);
  const renderReportRarameter = () => {
    if (!setReportRarameter) {
      return null;
    }
    return (
      <div className="report-param">
        <Button
          className="btn-reports"
          variant="secondary"
          onClick={() => setReportRarameter(false)}
          disabled={!reportRarameter}
        >
          {t('Report')}
        </Button>
        <Button
          className="btn-parameters"
          variant="secondary"
          onClick={() => setReportRarameter(true)}
          disabled={reportRarameter}
        >
          {t('Parameters')}
        </Button>
      </div>
    );
  };
  const renderFormElement = (child, lv) => {
    if (child && child.type === React.Fragment && Array.isArray(child.props.children)) {
      return child.props.children.map(item => renderFormElement(item));
    }
    if (child && child.props && child.props.name) {
      return React.createElement(child.type, {
        ...{
          ...child.props,
          disabled: isFavReport || !!child.props?.disabled,
          register,
          control,
          key: child.props.name,
        }
      });
    }
    return child;
  };
  const renderDisplayColumns = () => {
    if (_.isEmpty(reportColumnConfig?.items)) {
      return;
    }
    const { items, active } = reportColumnConfig;
    return (
      <Accordion className="configuration-col display-columns">
        <Accordion.Toggle
          as={Button} variant="link"
          eventKey="display-columns"
        >
          <IoSettingsOutline /><span>{t('Attribute Columns')}</span>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="display-columns">
          <div className="report-columns">
            {items.map((item) => (
              <Form.Check
                id={`switch-${item.value}`}
                key={item.value}
                type="switch"
                onChange={(e) => { onActiveColumnsHandler(item.value, e.target.checked) }}
                defaultChecked={!!active[item.value]}
                label={item.label}
              />
            ))}
          </div>
        </Accordion.Collapse>
      </Accordion>
    );
  };
  return (
    <Form className="configuration-form-control">
      {renderReportRarameter()}
      <div className="configuration-row">
        {Array.isArray(children) ? children.map((child) => renderFormElement(child)) : children}
        {renderDisplayColumns()}
        <div className="submit-button">
          {!isFavReport &&
            (<Button
              disabled={isSubmitting}
              variant="primary"
              type="submit"
              className="mr-3"
              name="generate-report"
              id="generate-report"
              onClick={onSubmitGenerateReport}
            >
              {primaryBtnText}
            </Button>
            )}
          {isOwnerFavReport && (<DeleteButton frName={favReportName} />)}
          {(!dropFavOption && !isFavReport) && (
            <Button
              variant="secondary"
              type="submit"
              name="add-to-favourite"
              id="add-to-favourite"
              onClick={onSubmitFavouriteReport}
            >
              {t('Add To Favourite')}
            </Button>
          )}
        </div>
      </div>
    </Form>
  );
});

export default Configuration;
