import React, { useEffect, useCallback, useState } from 'react';
import { ReactKeycloakProvider } from '@react-keycloak/web';

const KeycloakProvider = ({ authClient, LoadingComponent, children, onEvent, ...props }) => {
  const [appTokenType, setAppTokenType] = useState('');

  const parseJwt = useCallback((token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
  }, []);

  useEffect(() => {
    if (appTokenType) {
      return;
    }
    const cveToken = (new URLSearchParams(window.location.search)).get('cve-token');
    if (!cveToken) {
      setAppTokenType('keycloak');
      return;
    }
    const tokenParsed = parseJwt(cveToken);
    authClient.authenticated = true;
    authClient.subject = tokenParsed.sub;
    authClient.token = cveToken;
    authClient.tokenParsed = tokenParsed;
    authClient.resourceAccess = tokenParsed.resource_access;
    authClient.updateToken = () => {
      console.log('Cve: Update token')
    };
    authClient.logout = () => {
      console.log('Cve: Token expired')
    };
    setAppTokenType('init');
    const initApp = async () => {
      await onEvent('onAuthSuccess');
      setAppTokenType('cve');
      console.log('load app');
    };
    initApp();
  }, [authClient, appTokenType, parseJwt, onEvent]);

  switch (appTokenType) {
    case 'cve':
      return (<div className="cve-auth-provider">{children}</div>);
    case 'keycloak':
      return (
        <ReactKeycloakProvider
          authClient={authClient}
          onEvent={onEvent}
          LoadingComponent={LoadingComponent}
          {...props}
        >
          {children}
        </ReactKeycloakProvider >
      );
    default:
      return LoadingComponent;
  }
};

export default KeycloakProvider;
