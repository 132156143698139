import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  assignAssetApi,
  assignEntityApi,
  assignEntitiesApi,
  assignAssetsApi,
  fetchEmissionProfilesTreeApi,
  fetchEmissionProfilesTreeByScopeApi,
  fetchSingleEmissionSourceDataApi,
  getAssignedAssetsApi,
  getAssignedEntitiesApi,
  getAvailableAssetsApi,
  getAvailableEntitiesApi,
  getEmissionSourcesApi,
  getEmissionSourceTypesApi,
  getReportingStandardsApi,
  removeEmissionProfileCategoryApi,
  removeEmissionProfileSourceApi,
  unassignAssetApi,
  unassignEntityApi,
  getEmissionSourceFactorByEsrcIdApi,
  fetchExcludedEPSsApi,
} from './emissionProfileApi';
import { LoadingState } from '../../utilities/constants';
import { toast } from 'react-toastify';
import { recursiveFind } from '../../utilities/treeHelpers';
import {fetchEmissionProfileSourceUnitApi, fetchEmissionSourceUnitsApi} from "../emissions/emissionApi";

export const fetchEmissionProfilesTree = createAsyncThunk('emissionProfiles/fetch-tree', async (payload) => {
  try {
    const response = await fetchEmissionProfilesTreeApi(payload.orgId);
    return response;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const fetchExcludedEPSs = createAsyncThunk('emissionProfiles/fetch-excluded-eps', async (payload) =>
  fetchExcludedEPSsApi(payload.orgId));

export const fetchEmissionProfilesTreeByScope = createAsyncThunk(
  'emissionProfiles/fetch-tree-by-scope',
  async (payload) => {
    try {
      const response = await fetchEmissionProfilesTreeByScopeApi(payload.orgId);
      return response;
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  }
);

export const getReportingStandards = createAsyncThunk('emissionProfiles/get-standards', async (payload) => {
  try {
    const response = await getReportingStandardsApi(payload.orgId);
    return response;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const getEmissionSourceTypes = createAsyncThunk('emissionProfiles/get-source-types', async (payload) => {
  try {
    const { orgId, standardId } = payload;
    const response = await getEmissionSourceTypesApi(orgId, standardId);
    return response;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const getEmissionSources = createAsyncThunk('emissionProfiles/get-emission-sources', async (payload) => {
  try {
    const { orgId, emissionSourceTypeId, emissionStandardId } = payload;
    const response = await getEmissionSourcesApi(orgId, emissionSourceTypeId, emissionStandardId);
    return response;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const getEmissionSourceFactorByEsrcId = createAsyncThunk('emissionProfiles/get-emission-factor-by-source-id', async (payload) => {
  try {
    const { orgId, emissionSourceId } = payload;
    const response = await getEmissionSourceFactorByEsrcIdApi(orgId, emissionSourceId);
    return response;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const getEmissionProfileUnits = createAsyncThunk(
    'emissionProfiles/get-emission-sources-units',
    async (payload) => {
      try {
        const { orgId, emissionProfileId, eprflsrcEsrcId } = payload;
        let response;
        if (eprflsrcEsrcId) {
          response = await fetchEmissionSourceUnitsApi(orgId, eprflsrcEsrcId);
        } else if(emissionProfileId){
          response = await fetchEmissionProfileSourceUnitApi(orgId, emissionProfileId);
        }
        return response;
      } catch (error) {
        toast.error(error.message);
        throw error;
      }
    }
);

export const removeEmissionProfileCategory = createAsyncThunk(
  'emissionProfiles/remove-category',
  async (payload, { dispatch }) => {
    try {
      const { orgId, emissionProfileCategoryId, data } = payload;
      await removeEmissionProfileCategoryApi(orgId, emissionProfileCategoryId, data);
      toast.success('Category removed successfully.');
      dispatch(fetchEmissionProfilesTree({ orgId }));
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  }
);

export const removeEmissionProfileSource = createAsyncThunk(
  'emissionProfiles/remove-profile-source',
  async (payload, { dispatch }) => {
    try {
      const { orgId, emissionProfileSourceId } = payload;
      await removeEmissionProfileSourceApi(orgId, emissionProfileSourceId);
      dispatch(fetchEmissionProfilesTree({ orgId }));
      toast.success('Emission Source has been removed.');
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  }
);

export const getAvailableAssets = createAsyncThunk('emissionProfiles/get-available-assets', async (payload) => {
  try {
    const { orgId, emissionProfileSourceId } = payload;
    const response = await getAvailableAssetsApi(orgId, emissionProfileSourceId);
    return response;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const getAssignedAssets = createAsyncThunk('emissionProfiles/get-assigned-assets', async (payload) => {
  try {
    const { orgId, emissionProfileSourceId } = payload;
    const response = await getAssignedAssetsApi(orgId, emissionProfileSourceId);
    return response;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const assignAsset = createAsyncThunk('emissionProfiles/assign-asset', async (payload, { dispatch }) => {
  try {
    const { orgId, emissionProfileSourceId, data } = payload;
    await assignAssetApi(orgId, emissionProfileSourceId, data);
    await dispatch(getAvailableAssets({ orgId, emissionProfileSourceId }));
    await dispatch(getAssignedAssets({ orgId, emissionProfileSourceId }));
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const unAssignAsset = createAsyncThunk('emissionProfiles/unassign-asset', async (payload, { dispatch }) => {
  try {
    const { orgId, emissionProfileSourceId, assetId } = payload;
    await unassignAssetApi(orgId, emissionProfileSourceId, assetId);
    await dispatch(getAvailableAssets({ orgId, emissionProfileSourceId }));
    await dispatch(getAssignedAssets({ orgId, emissionProfileSourceId }));
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const getAvailableEntities = createAsyncThunk('emissionProfiles/get-available-entities', async (payload) => {
  try {
    const { orgId, emissionProfileSourceId } = payload;
    const response = await getAvailableEntitiesApi(orgId, emissionProfileSourceId);
    return response;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const getAssignedEntities = createAsyncThunk('emissionProfiles/get-assigned-entities', async (payload) => {
  try {
    const { orgId, emissionProfileSourceId } = payload;
    const response = await getAssignedEntitiesApi(orgId, emissionProfileSourceId);
    return response;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const assignEntity = createAsyncThunk('emissionProfiles/assign-entity', async (payload, { dispatch }) => {
  try {
    const { orgId, emissionProfileSourceId, data } = payload;
    await assignEntityApi(orgId, emissionProfileSourceId, data);
    await dispatch(getAvailableEntities({ orgId, emissionProfileSourceId }));
    await dispatch(getAssignedEntities({ orgId, emissionProfileSourceId }));
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const unAssignEntity = createAsyncThunk('emissionProfiles/unassign-entity', async (payload, { dispatch }) => {
  try {
    const { orgId, emissionProfileSourceId, entityId } = payload;
    await unassignEntityApi(orgId, emissionProfileSourceId, entityId);
    await dispatch(getAvailableEntities({ orgId, emissionProfileSourceId }));
    await dispatch(getAssignedEntities({ orgId, emissionProfileSourceId }));
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const assignMultiEntities = createAsyncThunk('emissionProfiles/assign-entities', async (payload, { dispatch }) => {
  try {
    const { orgId, emissionProfileSourceId, type, divIds } = payload;
    const result = await assignEntitiesApi(orgId, emissionProfileSourceId, { type, divIds });
    await dispatch(getAvailableEntities({ orgId, emissionProfileSourceId }));
    await dispatch(getAssignedEntities({ orgId, emissionProfileSourceId }));
    return result;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const assignMultiAssets = createAsyncThunk('emissionProfiles/assign-assets', async (payload, { dispatch }) => {
  try {
    const { orgId, emissionProfileSourceId, type, assetIds } = payload;
    await assignAssetsApi(orgId, emissionProfileSourceId, { type, divIds: assetIds });
    await dispatch(getAvailableAssets({ orgId, emissionProfileSourceId }));
    await dispatch(getAssignedAssets({ orgId, emissionProfileSourceId }));
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
});

export const fetchSingleEmissionSourceData = createAsyncThunk(
  'emissions/fetchSingleEmissionData',
  async (payload, thunkAPI) => {
    try {
      const { usrOrgId } = thunkAPI.getState().users.user;
      const response = await fetchSingleEmissionSourceDataApi(usrOrgId, payload.selectedEmissionSource);
      return response;
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  }
);

export const emissionProfileSlice = createSlice({
  name: 'emissionProfiles',
  initialState: {
    tree: null,
    scopedTree: null,
    emissionSource: [],
    reportingStandards: null,
    emissionSourceTypes: null,
    emissionSources: null,
    emissionSourceUnits: [],
    availableAssets: null,
    assignedAssets: null,
    assignedEntities: null,
    availableEntities: null,
    emissionProfiles: [],
    emissionProfileCategories: [],
    status: LoadingState.idle,
    scopedTreeStatus: LoadingState.idle,
    emissionSourceStatus: LoadingState.idle,
    excludedEPSs: [],
    error: null
  },
  reducers: {},
  extraReducers: {
    [getAssignedEntities.pending]: (state, action) => {
      state.assignedEntities = null;
    },
    [getAssignedEntities.fulfilled]: (state, action) => {
      state.assignedEntities = action.payload;
    },
    [getAvailableEntities.pending]: (state, action) => {
      state.availableEntities = null;
    },
    [getAvailableEntities.fulfilled]: (state, action) => {
      state.availableEntities = action.payload;
    },
    [getAssignedAssets.pending]: (state, action) => {
      state.assignedAssets = null;
    },
    [getAssignedAssets.fulfilled]: (state, action) => {
      state.assignedAssets = action.payload;
    },
    [getAvailableAssets.pending]: (state, action) => {
      state.availableAssets = null;
    },
    [getAvailableAssets.fulfilled]: (state, action) => {
      state.availableAssets = action.payload;
    },
    [getEmissionSources.pending]: (state, action) => {
      state.emissionSources = null;
    },
    [getEmissionSources.fulfilled]: (state, action) => {
      state.emissionSources = action.payload;
    },
    [getEmissionProfileUnits.pending]: (state, action) => {
      state.emissionSourceUnits = null;
    },
    [getEmissionProfileUnits.fulfilled]: (state, action) => {
      state.emissionSourceUnits = action.payload;
    },
    [getEmissionSourceFactorByEsrcId.pending]: (state, action) => {
      state.emissionFactors = null;
    },
    [getEmissionSourceFactorByEsrcId.fulfilled]: (state, action) => {
      state.emissionFactors = action.payload?.factors;
    },
    [getEmissionSourceTypes.pending]: (state, action) => {
      state.emissionSourceTypes = null;
      state.emissionSources = null;
    },
    [getEmissionSourceTypes.fulfilled]: (state, action) => {
      state.emissionSourceTypes = action.payload;
    },
    [getReportingStandards.pending]: (state, action) => {
      state.reportingStandards = null;
      state.emissionSourceTypes = null;
    },
    [getReportingStandards.fulfilled]: (state, action) => {
      state.reportingStandards = action.payload;
    },
    [fetchEmissionProfilesTree.pending]: (state, action) => {
      state.status = LoadingState.loading;
    },
    [fetchEmissionProfilesTree.fulfilled]: (state, action) => {
      state.status = LoadingState.succeeded;
      state.tree = action.payload;
    },
    [fetchEmissionProfilesTree.rejected]: (state, action) => {
      state.status = LoadingState.failed;
    },
    [fetchEmissionProfilesTreeByScope.pending]: (state) => {
      state.scopedTreeStatus = LoadingState.loading;
    },
    [fetchEmissionProfilesTreeByScope.fulfilled]: (state, action) => {
      state.scopedTreeStatus = LoadingState.succeeded;
      state.scopedTree = action.payload;
    },
    [fetchEmissionProfilesTreeByScope.rejected]: (state) => {
      state.scopedTreeStatus = LoadingState.failed;
    },
    [fetchSingleEmissionSourceData.pending]: (state, action) => {
      state.emissionSourceStatus = LoadingState.loading;
    },
    [fetchSingleEmissionSourceData.fulfilled]: (state, action) => {
      state.emissionProfiles = action.payload;
    },
    [fetchSingleEmissionSourceData.rejected]: (state, action) => {
      state.emissionSourceStatus = LoadingState.failed;
      state.error = action.error.message;
    },
    [fetchExcludedEPSs.fulfilled]: (state, action) => {
      state.excludedEPSs = action.payload;
    },
  }
});

export const selectEmissionProfileCategoryById = (state, id) => {
  if (!state.emissionProfiles.tree) return null;
  const found = recursiveFind(state.emissionProfiles.tree, (n) => n.eprflcatId === parseInt(id));
  return found ? { ...found, children: null } : null;
};

export const selectEmissionProfileSourceById = (state, id) => {
  if (!state.emissionProfiles.tree) return null;
  const found = recursiveFind(state.emissionProfiles.tree, (n) => n.eprflsrcId === parseInt(id));
  return found ? { ...found, children: null } : null;
};

export const selectRootEmissionProfileCategory = (state) => {
  if (!state.emissionProfiles.tree) return null;
  const categories = state.emissionProfiles.tree.children;
  return categories.length !== 0 ? { ...categories[0], children: null } : {};
};

export default emissionProfileSlice.reducer;
