import React from 'react';
import PrimarySidebar from '../Sidebar/PrimarySidebar';
import { IoGridOutline } from 'react-icons/io5';
import { IoSettingsOutline } from 'react-icons/io5';
import { IoAnalyticsOutline } from 'react-icons/io5';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { ReactComponent as BriefcaseIcon } from '../../assets/images/briefcase.svg';
import { ReactComponent as FlagIcon } from '../../assets/images/flag.svg';
import { isAuthorized } from '../../app/userImpersonate';

export let routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: <IoGridOutline />,
  },
  {
    path: '/my-disclosure',
    name: 'My Disclosure',
    icon: <BriefcaseIcon />,
  },
  {
    path: '/reduction-plan',
    name: 'Reduction Plan',
    icon: <FlagIcon />,
  },
  {
    path: '/admin',
    icon: <IoSettingsOutline />,
    name: 'Admin',
  },
  {
    path: '/rootadmin',
    icon: <IoSettingsOutline />,
    name: 'Root Admin'
  },
  {
    path: '/emissions',
    icon: <IoAnalyticsOutline />,
    name: 'Emission Data'
  },
  {
    path: '/reports',
    icon: <IoDocumentTextOutline />,
    name: 'Reports',
  },
];

const Main = (props) => {
  if (!isAuthorized(['cv_root'])) {
    routes = routes.filter((value) => {
      return value.path !== '/rootadmin';
    });
  }
  if (!isAuthorized(['cv_root', 'cv_admin'])) {
    routes = routes.filter((value) => {
      return value.path !== '/admin';
    });
  }

  if (isAuthorized(['cv_structured'])) {
    routes = routes.filter((value) => {
      return value.path !== "/reports" && value.path !== "/dashboard";
    });
  }
  return (
    <div className="d-flex flex-row mw-100">
      <PrimarySidebar items={routes} />
      <div className="offset-main">{props.children}</div>
    </div>
  );
};

export default Main;
