import { createAsyncThunk, createSlice, unwrapResult } from '@reduxjs/toolkit';
import {
  fetchActions,
  createActionApi,
  updateActionApi,
  fetchAllTargets,
  createTargetApi,
  updateTargetApi,
  fetchSuggestedActions,
  fetchActionByActId,
  deleteActionApi,
  fetchReductionPlanByScope,
  fetchReductionPlanByEPS,
  getFinancialStartMonthApi,
} from './reductionPlanApi';
import { LoadingState } from '../../utilities/constants';
import { toast } from 'react-toastify';

// ======================================== Reduction plan old start ======================================

export const getActions = createAsyncThunk('reduction-plan/actions', async (payload) => {
  const actionsRes = await fetchActions(payload.orgId);
  return actionsRes;
});

export const getActionsByActId = createAsyncThunk('reduction-plan/action', async (payload) => {
  const actionsRes = await fetchActionByActId(payload.orgId, payload.actId);
  return actionsRes;
});

export const getSuggestedActions = createAsyncThunk('reduction-plan/suggested-actions', async () => {
  const response = await fetchSuggestedActions();
  return response;
});

export const createAction = createAsyncThunk('reduction-plan/create-actions', async (payload, { dispatch }) => {
  const { orgId, data } = payload;
  try {
    const response = await createActionApi(orgId, data);
    if (response) {
      const result = await dispatch(getActions({ orgId: orgId }));
      unwrapResult(result);
      if (result) {
        toast.success('Action added successfully');
      }
    }
    return response;
  } catch (error) {
    toast.error(error.response.data.message);
    throw error;
  }
});

export const updateAction = createAsyncThunk('reduction-plan/update-actions', async (payload, { dispatch }) => {
  const { orgId, actId, data } = payload;
  try {
    const response = await updateActionApi(orgId, actId, data);
    if (response) {
      const result = await dispatch(getActions({ orgId: orgId }));
      unwrapResult(result);
      if (result) {
        toast.success('Action updated successfully');
      }
    }
    return response;
  } catch (error) {
    toast.error(error.response.data.message);
    throw error;
  }
});

export const deleteAction = createAsyncThunk('reduction-plan/delete-action', async (payload, { dispatch }) => {
  const { orgId, actId } = payload;
  try {
    const response = await deleteActionApi(orgId, actId);
    if (response) {
      const result = await dispatch(getActions({ orgId: orgId }));
      unwrapResult(result);
      if (result) {
        toast.success('Action deleted successfully');
      }
    }
    return response;
  } catch (error) {
    toast.error(error.response.data.message);
    throw error;
  }
});

// ======================================== Reduction plan old Ends ======================================

//Targets
export const getTargets = createAsyncThunk('reduction-plan/targets', async (payload) => {
  const actionsRes = await fetchAllTargets(payload.orgId);
  return actionsRes;
});

export const createTarget = createAsyncThunk('reduction-plan/create-targets', async (payload, { dispatch }) => {
  const { data, orgId } = payload;
  try {
    const response = await createTargetApi(orgId, data);
    if (response) {
      const result = await dispatch(getTargets({ orgId }));
      unwrapResult(result);
      if (result) {
        toast.success('Goal added successfully');
      }
    }
    return response;
  } catch (error) {
    toast.error(error.response.data.message);
    throw error;
  }
});

export const updateTarget = createAsyncThunk('reduction-plan/update-targets', async (payload, { dispatch }) => {
  const { data, orgId, targetId } = payload;
  try {
    const response = await updateTargetApi(orgId, targetId, data);
    if (response) {
      const result = await dispatch(getTargets({ orgId }));
      unwrapResult(result);
      if (result) {
        toast.success('Goal updated successfully');
      }
    }
    return response;
  } catch (error) {
    toast.error(error.response.data.message);
    throw error;
  }
});

// Forecast
export const getForecastData = createAsyncThunk('reduction-plan/forecast', async (payload) => {
  const { orgId, data } = payload;
  try {
    if (data.forecastType === 'By emission profile source') {
      return await fetchReductionPlanByEPS(orgId, data);
    }
    return await fetchReductionPlanByScope(orgId, data);
  } catch (error) {
  }
});

export const getFinancialStartMonth = createAsyncThunk('reduction-plan/financial-start-month', async (payload) => {
  try {
    const financialStartMonth = await getFinancialStartMonthApi(payload.orgId, payload);
    return financialStartMonth;
  } catch (error) {
    toast.error(error.response.data.message);
    throw error;
  }
});

const reductionSlice = createSlice({
  name: 'reduction-plan',
  initialState: {
    suggestedActions: [],
    actions: [],
    action: {},
    targets: [],
    forecastData: [],
    suggestedActionStatus: LoadingState.idle,
    actionStatus: LoadingState.idle,
    viewActionStatus: LoadingState.idle,
    targetStatus: LoadingState.idle,
    createTargetStatus: LoadingState.idle,
    updateTargetStatus: LoadingState.idle,
    createActionStatus: LoadingState.idle,
    updateActionStatus: LoadingState.idle,
    forecastDataStatus: LoadingState.idle,
  },
  reducers: {},
  extraReducers: {
    [getActions.pending]: (state) => {
      state.actionStatus = LoadingState.loading;
    },
    [getActions.fulfilled]: (state, action) => {
      state.actionStatus = LoadingState.succeeded;
      state.actions = action.payload;
    },
    [getActions.rejected]: (state) => {
      state.actionStatus = LoadingState.failed;
    },

    [getActionsByActId.pending]: (state) => {
      state.viewActionStatus = LoadingState.loading;
    },
    [getActionsByActId.fulfilled]: (state, action) => {
      state.viewActionStatus = LoadingState.succeeded;
      state.action = action.payload;
    },
    [getActionsByActId.rejected]: (state) => {
      state.viewActionStatus = LoadingState.failed;
    },

    [getTargets.pending]: (state) => {
      state.targetStatus = LoadingState.loading;
    },
    [getTargets.fulfilled]: (state, action) => {
      state.targetStatus = LoadingState.succeeded;
      state.targets = action.payload;
    },
    [getTargets.rejected]: (state) => {
      state.targetStatus = LoadingState.failed;
    },

    [getSuggestedActions.pending]: (state) => {
      state.suggestedActionStatus = LoadingState.loading;
    },
    [getSuggestedActions.fulfilled]: (state, action) => {
      state.suggestedActionStatus = LoadingState.succeeded;
      state.suggestedActions = action.payload;
    },
    [getSuggestedActions.rejected]: (state) => {
      state.suggestedActionStatus = LoadingState.failed;
    },

    [createTarget.pending]: (state) => {
      state.createTargetStatus = LoadingState.loading;
    },
    [createTarget.fulfilled]: (state) => {
      state.createTargetStatus = LoadingState.succeeded;
    },
    [createTarget.rejected]: (state) => {
      state.createTargetStatus = LoadingState.failed;
    },

    [updateTarget.pending]: (state) => {
      state.updateTargetStatus = LoadingState.loading;
    },
    [updateTarget.fulfilled]: (state) => {
      state.updateTargetStatus = LoadingState.succeeded;
    },
    [updateTarget.rejected]: (state) => {
      state.updateTargetStatus = LoadingState.failed;
    },

    [createAction.pending]: (state) => {
      state.createActionStatus = LoadingState.loading;
    },
    [createAction.fulfilled]: (state) => {
      state.createActionStatus = LoadingState.succeeded;
    },
    [createAction.rejected]: (state) => {
      state.createActionStatus = LoadingState.failed;
    },

    [updateAction.pending]: (state) => {
      state.updateActionStatus = LoadingState.loading;
    },
    [updateAction.fulfilled]: (state) => {
      state.updateActionStatus = LoadingState.succeeded;
    },
    [updateAction.rejected]: (state) => {
      state.updateActionStatus = LoadingState.failed;
    },

    [getForecastData.pending]: (state) => {
      state.forecastDataStatus = LoadingState.loading;
    },
    [getForecastData.fulfilled]: (state, data) => {
      state.forecastDataStatus = LoadingState.succeeded;
      state.forecastData = data.payload;
    },
    [getForecastData.rejected]: (state) => {
      state.forecastDataStatus = LoadingState.failed;
    },
  }
});

export const selectActionById = (state, actId) => {
  if (!state.actions?.length === 0) return null;
  const parsedId = parseInt(actId);
  const found = state.actions.find((a) => a.actId === parsedId);
  return found ?? null;
};

export default reductionSlice.reducer;
