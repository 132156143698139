import React, { useState } from 'react';
import Table from 'react-bootstrap/Table';
import { subYears } from 'date-fns';
import { useSelector } from 'react-redux';
import { fetchDataValidation } from '../reportsSlice';
import { DatePicker } from '../configurations/DatePicker';
import { Check } from '../configurations/Check';
import { Input } from '../configurations/Input';
import { useLocation } from 'react-router-dom';
import ReportContainer from '../ReportContainer';
import {
  formatNumber,
  getCommonFilterPeriodRange,
  getCurrentReportPeriod,
  getReportRangePeriod,
  parseFavouriteData,
} from '../reportHelper';
import { useTranslation } from 'react-i18next';
import { numericKeydownHandler, parseUserInfo } from '../../../utilities/common';

const DataValidation = () => {
  const { t } = useTranslation();
  const { filterData } = parseFavouriteData(useLocation().search, ['comparisonStartDate', 'comparisonEndDate']);
  const userInfo = parseUserInfo(useSelector((state) => state.users.user));
  const currentReportPeriod = getCurrentReportPeriod(userInfo.usrOrgOptions);
  const reportRangePeriod = getReportRangePeriod(userInfo.usrOrgOptions, currentReportPeriod.reportYear);
  const dataValidation = useSelector((state) => state.reports.dataValidation);
  const dataValidationStatus = useSelector((state) => state.reports.dataValidationStatus);

  const [filterParams, setFilterParams] = useState({
    comparisonStartDate: subYears(reportRangePeriod.startDate, 1),
    comparisonEndDate: subYears(reportRangePeriod.endDate, 1),
    tolerance: 10,
    isAboveTolerenceOnly: false,
    ...(filterData || {}),
  });

  const [formData, setFormData] = useState({
    comparisonStartDate: filterParams.comparisonStartDate,
    comparisonEndDate: filterParams.comparisonEndDate,
  });

  const getFilterPeriodRangeHandler = (data) => {
    return getCommonFilterPeriodRange(data, reportRangePeriod);
  };

  const fetchDataValidationHandler = async (dispatch, payload) => {
    payload.data = {
      currentStartDate: payload.data.startDate,
      currentEndDate: payload.data.endDate,
      comparisonStartDate: filterParams.comparisonStartDate,
      comparisonEndDate: filterParams.comparisonEndDate,
      tolerance: filterParams.tolerance,
      isAboveTolerenceOnly: filterParams.isAboveTolerenceOnly ? true : false,
      ...payload.data,
    };
    delete payload.data.startDate;
    delete payload.data.endDate;
    return await dispatch(fetchDataValidation(payload));
  };

  const getFavouriteFilterDataHandler = (params, filters) => {
    const paramsData = {
      ...params,
      comparisonStartDate: filters.comparisonStartDate,
      comparisonEndDate: filters.comparisonEndDate,
      tolerance: filters.tolerance,
      isAboveTolerenceOnly: filters.isAboveTolerenceOnly,
    };
    setFilterParams(paramsData);
    return paramsData;
  };

  const onFormChangeHandler = (field, value) => {
    if (value !== formData[field]) {
      setFormData((prevState) => ({
        ...prevState,
        [field]: value
      }));
    }
  };

  const extraConfigRender = (isFavReport) => {
    return (
      <>
        <DatePicker
          disabled={isFavReport}
          label={t("Comparison Period Start Date")}
          name="comparisonStartDate"
          maxDate={formData.comparisonEndDate}
          dateFormat={userInfo.usrDateFormat}
          onChangeHandler={onFormChangeHandler}
        />
        <DatePicker
          disabled={isFavReport}
          label={t("Comparison Period End Date")}
          minDate={formData.comparisonStartDate}
          name="comparisonEndDate"
          dateFormat={userInfo.usrDateFormat}
          onChangeHandler={onFormChangeHandler}
        />
        <Input
          disabled={isFavReport}
          name="tolerance"
          label={t("Tolerance (%)")}
          onKeyDown={numericKeydownHandler}
        />
        <Check
          disabled={isFavReport}
          name="isAboveTolerenceOnly"
          formLabellabel={t('Above Tolerance Records Only')}
          label={t("Display")}
        />
      </>
    );
  };

  const tableRender = (tableRef) => {
    return (
      <div style={{ overflowX: 'auto' }}>
        <Table bordered hover id="data-validation-table" ref={tableRef}>
          <thead>
            <tr>
              <th>{t('Entity')}</th>
              <th>{t('Emission Source')}</th>
              <th>{t('Units')}</th>
              <th>{t('Comparison Usage')}</th>
              <th>{t('Comparison CO2-e (t)')}</th>
              <th>{t('Current Usage')}</th>
              <th>{t('Current CO2-e (t)')}</th>
              <th>{t('Variance Usage')}</th>
              <th>{t('Variance CO2-e (t)')}</th>
              <th>{t('Above Tolerance Threshold?')}</th>
            </tr>
          </thead>
          <tbody>
            {dataValidation.map((d, idx) => {
              return (
                <tr key={idx}>
                  <td>{d.div_name}</td>
                  <td>{d.eps_name}</td>
                  <td>{d.unit}</td>
                  <td>{formatNumber(d.comparison_usage)}</td>
                  <td>{formatNumber(d.comparison_co2_e_t)}</td>
                  <td>{formatNumber(d.current_usage)}</td>
                  <td>{formatNumber(d.current_co2_e_t)}</td>
                  <td>{formatNumber(d.variance_usage)}</td>
                  <td>{formatNumber(d.variance_co2_e_t)}</td>
                  <td>{d.tolerance_check}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  };
  return (
    <ReportContainer
      reportName={t('Data Validation')}
      reportSubTitle={t('Data Validation Report')}
      fetchReportData={fetchDataValidationHandler}
      fetchReportStatus={dataValidationStatus}
      reportData={dataValidation}
      reportRender={tableRender}
      extraConfigRender={extraConfigRender}
      getFavouriteFilterData={getFavouriteFilterDataHandler}
      getFilterPeriodRange={getFilterPeriodRangeHandler}
      startDateLabel={t("Current Period Start Date")}
      endDateLabel={t("Current Period End Date")}
      extraConfigDefault={{
        comparisonStartDate: filterParams.comparisonStartDate,
        comparisonEndDate: filterParams.comparisonEndDate,
        tolerance: filterParams.tolerance,
        isAboveTolerenceOnly: filterParams.isAboveTolerenceOnly,
      }}
    />
  );
};

export default DataValidation;
