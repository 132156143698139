import React, { useEffect, useMemo, useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AiOutlineEdit } from 'react-icons/ai';
import { IoEyeOutline, IoTrashOutline } from 'react-icons/io5';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import DataTable from '../../components/DataTable';
import { LoadingState } from '../../utilities/constants';
import { deleteEmission, fetchEmissionData } from './emissionSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { formatDate, parseUserInfo } from '../../utilities/common';
import { formatNumber } from '../reports/reportHelper';

const mapStateToProps = (state) => ({
  selectedEP: state.emissions.selectedEP
});

let EmissionDataTable = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const [showDeleteWarningModal, setShowDeleteWarningModal] = useState(false);
  const [deleteEmissionRow, setDeleteEmissionRow] = useState(null);
  const [disablePopup, setDisablePopup] = useState(false);

  const { orgId } = parseUserInfo(useSelector((state) => state.users.user));
  const emissionDataStatus = useSelector((state) => state.emissions.emissionDataStatus);
  const { emissionData } = useSelector((state) => state.emissions);
  const { usrRole } = useSelector((state) => state.users.user);

  const handleDeleteEmission = (row) => {
    setDeleteEmissionRow(row);
    setShowDeleteWarningModal(true);
  };

  const handleConfirmDeleteEmission = async () => {
    try {
      setDisablePopup(true);
      const emissionId = deleteEmissionRow.eId;
      const resultAction = await dispatch(deleteEmission({ orgId, emissionId }));
      unwrapResult(resultAction);
      dispatch(fetchEmissionData());
    } catch (error) {
    } finally {
      setDisablePopup(false);
      setDeleteEmissionRow(null);
      setShowDeleteWarningModal(false);
    }
  };

  const closeConfirmModal = () => setShowDeleteWarningModal(false);

  const tableColumns = useMemo(
    () => [
      {
        Header: t('Bill/Invoice Number'),
        accessor: 'eContributorId'
      },
      {
        Header: t('Provider'),
        accessor: 'eProvider'
      },
      {
        Header: t('Contribution'),
        accessor: 'eContribution',
        Cell: ({ value }) => value
      },
      {
        Header: t('Unit'),
        accessor: 'eContributionUnit'
      },
      {
        Header: t('Period Start Date'),
        accessor: 'eStartDate',
        Cell: ({ value }) => formatDate(value)
      },
      {
        Header: t('Period End Date'),
        accessor: 'eEndDate',
        Cell: ({ value }) => formatDate(value)
      },
      {
        Header: t('CO2-e (t)'),
        disableGlobalFilter: false,
        accessor: 'eCo2emission',
        Cell: ({ value }) => formatNumber(value, '-')
      },
      {
        Header: t('Last Updated'),
        accessor: 'eModifiedDate',
        Cell: ({ value }) => formatDate(value)
      },
      {
        Header: '',
        accessor: 'actions',
        actionColumn: true,
        disableGlobalFilter: false,
        disableSortBy: false,
        Cell: (props) => {
          const { handleDeleteEmission, row } = props;
          const { original } = row;
          return usrRole !== 'cv_audit' && usrRole !== 'cv_restricted_audit' ? (
            <div className="action-buttons">
              <Button
                to={`${match.url}/${original.eId}`}
                size="sm"
                className="mr-1"
                variant="outline-secondary"
                as={Link}
              >
                <IoEyeOutline />
              </Button>
              <Button
                to={`${match.url}/${original.eId}/edit`}
                size="sm"
                className="mr-1"
                variant="outline-secondary"
                as={Link}
                disabled
              >
                <AiOutlineEdit />
              </Button>
              <Button
                size="sm"
                className="mr-1"
                variant="outline-secondary"
                onClick={() => handleDeleteEmission(row.original)}
              >
                <IoTrashOutline />
              </Button>
            </div>
          ) : (
            <div className="action-buttons">
              <Button
                to={`${match.url}/${original.eId}`}
                size="sm"
                className="mr-1"
                variant="outline-secondary"
                as={Link}
              >
                <IoEyeOutline />
              </Button>
            </div>
          );
        }
      }
    ],
    [match.url, t, usrRole]
  );

  useEffect(() => {
    dispatch(fetchEmissionData());
  }, [dispatch, props]);

  if (emissionDataStatus === LoadingState.loading) return <Spinner animation="grow" />;

  return (
    <div>
      {emissionDataStatus === LoadingState.succeeded && (
        <DataTable
          columns={tableColumns}
          data={emissionData}
          headerActions={
            <Button variant="secondary" to={`${match.url}/new`} as={Link}>
              {t('Add Emission')}
            </Button>
          }
          actions={{ handleDeleteEmission }}
        />
      )}
      <Modal show={showDeleteWarningModal} onHide={closeConfirmModal} className="sisde-modal">
        <Modal.Header>
          <Modal.Title>{t('Delete emission')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {t('Are you sure that you want to permanently delete this emission and all linked emissions? This action cannot be undone.')}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeConfirmModal}>
            {t('Cancel')}
          </Button>
          <Button variant="danger" onClick={handleConfirmDeleteEmission} disabled={disablePopup}>
            {t('Confirm')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
EmissionDataTable = connect(mapStateToProps)(EmissionDataTable);
export default EmissionDataTable;
