import React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

const ActiveSms2FaForm = ({
  onSubmit,
  initialValues = {},
  disabled,
}) => {
  const { t } = useTranslation();
  const mobileMsg = t('Please enter a valid international mobile number. For example: +61282083366');
  const validateSchema = yup.object().shape({
    currentPassword: yup.string().max(128).required(t('Please enter current password')),
    mobileNumber: yup.string()
      .required(mobileMsg)
      .matches(/^\+[1-9]{1}[0-9]{3,14}$/, mobileMsg)
  });
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm({
    defaultValues: {
      currentPassword: '',
      mobileNumber: '',
      ...initialValues,
    },
    resolver: yupResolver(validateSchema),
    mode: 'onChange'
  });
  const onSubmitHandler = (data) => onSubmit(data);

  return (
    <Form onSubmit={handleSubmit(onSubmitHandler)}>
      <div className={'form-divider mb-4 mt-4'}>
        <span>{t('Protect your account with SMS two-factor authentication')}</span>
        <div></div>
      </div>
      <div className="mb-6">
        <p>
          {t('Prevent hackers from accessing your account with an additional layer of security.')} &nbsp;
          {t('When you sign in, two-factor authentication helps make sure your personal information stays private, safe and secure.')}
        </p>
      </div>
      <Row>
        <Col md={12}>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Current Password')} *</Form.Label>
            <Form.Control
              {...register('currentPassword')}
              isInvalid={errors.currentPassword}
              type="password"
              placeholder={t('Enter your current password')}
            />
            <Form.Control.Feedback type="invalid">{errors.currentPassword?.message}</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md={12}>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Mobile Number')} *</Form.Label>
            <Form.Control
              {...register('mobileNumber')}
              isInvalid={errors.mobileNumber}
              placeholder={t('For example: +61282083366')}
            />
            <Form.Control.Feedback type="invalid">{errors.mobileNumber?.message}</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Button type="submit" disabled={!isValid || !isDirty || disabled}>
        {t('Turn On')}
      </Button>
    </Form>
  );
};

export default ActiveSms2FaForm;
