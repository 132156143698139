import { config } from '../../utilities/config';
import keycloak from '../../keycloak';

export async function getFullDataTemplatesApi(organizationId) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/full-data-template`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    }
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function getDataTemplatesApi(organizationId) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/data-template`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    }
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}
export async function updateDataTemplatesApi(organizationId, data) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/data-template`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}
