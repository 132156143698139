import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import { LoadingState } from '../../../utilities/constants';
import { useDispatch, useSelector } from 'react-redux';
import { Bar } from 'react-chartjs-2';
import {
    setupReportRangePeriod,
    generateRandomColors,
    generateChartLegend,
    createInitChartConfig,
    createInitChartPlugins,
    getReportYearTypeOptions,
    getReportRangePeriod,
    formatNumber,
} from '../../reports/reportHelper';
import ReportContainer from '../../reports/ReportContainer';
import { Select } from '../../reports/configurations/Select';
import { getFinancialStartMonth, getForecastData, getTargets } from '../reductionPlanSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { fetchCarbonFootprintByFullEmissionProfile } from '../../reports/reportsSlice';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { fetchReductionPlanByScope } from '../reductionPlanApi';
import BaseValueForm from './BaseValueForm';
import { parseUserInfo } from '../../../utilities/common';
import ErrorForecastPage from './ErrorForecastPage';
import { AiOutlineEdit } from 'react-icons/ai';
import { Button } from 'react-bootstrap';
import { endOfMonth } from 'date-fns';

const ReductionForecast = () => {
    const dispatch = useDispatch();
    const chartRef = useRef(null);
    const { t } = useTranslation();
    const history = useHistory();
    const { orgId, usrTimezone, usrDateFormat, ...userInfo } = parseUserInfo(useSelector((state) => state.users.user));
    const { targets, targetStatus } = useSelector((state) => state.reductionPlan);
    const { carbonFootPrintFullEmissionStatus } = useSelector((state) => state.reports);
    const currentYear = new Date().getUTCFullYear();
    const [filterParams, setFilterParams] = useState(null);
    const [yearList, setYearList] = useState([]);
    const [chartData, setChartData] = useState({});
    const [targetDataset, setTargetDataset] = useState({});
    const [forecastDataWithPlannedReduction, setForecastDataWithPlannedReduction] = useState(null);
    const [firstYearEmission, setFirstYearEmission] = useState(null);
    const [firstYearEmissionByScope, setFirstYearEmissionByScope] = useState(null);
    // const [firstYearEmissionByEPS, setFirstYearEmissionByEPS] = useState(null);
    const [suggestedMin, setSuggestedMin] = useState(0);
    const reportRangePeriod = getReportRangePeriod(userInfo.usrOrgOptions);
    const { forecastData, forecastDataStatus } = useSelector((state) => state.reductionPlan);
    const [newForecastData, setNewForecastData] = useState(null);
    const [hasNegative, setHasNegative] = useState(false);
    const [baseValue, setBaseValue] = useState("");
    const [showBaseValueModal, setShowBaseValueModal] = useState(false);
    const [hiddenLegends, setHiddenLegends] = useState([]);
    const [isUseBaseValue, setIsUseBaseValue] = useState(false);
    const forecastTypeOptions = [
        { label: t('By scope'), value: 'By scope' },
        { label: t('By emission profile source'), value: 'By emission profile source' },
    ];

    const closeBaseValueModal = () => setShowBaseValueModal(false);

    const handleConfirmBaseValue = (value) => {
        const formatedValue = Number(value)
        setBaseValue(formatedValue)
        setFirstYearEmission(formatedValue);
        setIsUseBaseValue(true);
        closeBaseValueModal();
    };

    const plugins = createInitChartPlugins([
        {
            beforeDatasetsDraw: function (chart) {
                if (chart?.config?.data?.datasets.length >= 2) {
                    const ctx = chart.ctx;
                    const forecastMeta = chart.getDatasetMeta(1);
                    const firstBarMeta = chart.getDatasetMeta(2);
                    const spaceBetweenTwoBars =
                        forecastMeta.data.length > 2 ? firstBarMeta.data[1]._model.x - firstBarMeta.data[0]._model.x - firstBarMeta.data[1]._model.width : 0;
                    const barWidth = firstBarMeta.data[0]._model.width;
                    const yAxis = chart.scales['y-axis-0'];
                    const tickPosition = yAxis.getPixelForTick(yAxis.ticksAsNumbers.indexOf(0));

                    for (let index = 0; index < forecastMeta.data.length; index++) {
                        const barModel = forecastMeta.data[index]._model;
                        if (index < forecastMeta.data.length - 1) {
                            ctx.save();
                            ctx.strokeStyle = 'grey';
                            ctx.lineWidth = 2;
                            ctx.setLineDash([10, 10]);
                            ctx.beginPath();
                            ctx.moveTo(barModel.x + barWidth / 2 + spaceBetweenTwoBars, barModel.y);
                            ctx.lineTo(barModel.x + barWidth / 2, barModel.y);
                            ctx.stroke();
                            ctx.restore();
                        }

                        ctx.save();
                        ctx.strokeStyle = 'grey';
                        ctx.lineWidth = 2;
                        ctx.beginPath();
                        ctx.moveTo(barModel.x - barWidth / 2, barModel.y);
                        ctx.lineTo(barModel.x + barWidth / 2, barModel.y);
                        ctx.stroke();
                        ctx.restore();

                        ctx.save();
                        ctx.strokeStyle = 'grey';
                        ctx.lineWidth = 2;
                        ctx.beginPath();
                        ctx.moveTo(barModel.x - barWidth / 2, barModel.y);
                        ctx.lineTo(barModel.x - barWidth / 2, tickPosition);
                        ctx.stroke();
                        ctx.restore();
                    }
                }
            }
        }
    ]);

    const getTargetDataset = useCallback(() => {
        const data = [];
        if (targetStatus === LoadingState.succeeded) {
            const target = targets[0];
            const startYear = filterParams ? filterParams.startYear : currentYear;
            const endYear = filterParams ? filterParams.endYear : target.targetYear;
            data.push(firstYearEmission);
            const getForecastOfYear = (year) => {
                return firstYearEmission - firstYearEmission * (year - startYear) / (target.targetYear - startYear);
            }
            for (let i = startYear + 1; i <= endYear; i++) {
                data.push(getForecastOfYear(i));
            }
        }
        return data;
    }, [targets, targetStatus, filterParams, firstYearEmission, currentYear]);

    const getStartMonth = useCallback(async () => {
        if (filterParams?.yearType) {
            const response = await dispatch(getFinancialStartMonth({ orgId, yearType: filterParams.yearType }));
            if (response.payload) {
                return unwrapResult(response);
            }
        }
    }, [dispatch, filterParams?.yearType, orgId])

    const getStartDate = (startMonth, year) => {
        return new Date(`${year}-${startMonth}-1`);
    }

    const getEndDate = (startMonth, year) => {
        const endMonth = startMonth === 1 ? 12 : startMonth - 1;
        const endYear = startMonth === 1 ? year : year + 1;
        const endDate = endOfMonth(new Date(endYear, endMonth - 1, 1))
        return endDate;
    }
    const getPreviousYearEmissionData = useCallback(async (payload, firstYear, isEPS, startMonth) => {
        payload.data.startDate = getStartDate(startMonth, firstYear - 1);
        payload.data.endDate = getEndDate(startMonth, firstYear - 1);
        const previousYearResponse = await dispatch(fetchCarbonFootprintByFullEmissionProfile(payload));
        if (!previousYearResponse.payload) {
            return;
        }
        const result = unwrapResult(previousYearResponse);

        let totalReduction = 0;
        const firstYearEmissionList = [];
        const payloadForReductionPlan = {
            forecastType: filterParams.forecastType,
            startYear: firstYear - 1,
            endYear: firstYear - 1,
            yearType: filterParams.yearType,
        };
        const reductionPlan = await (fetchReductionPlanByScope(orgId, payloadForReductionPlan));

        const modifiedReductionPlan = [];
        reductionPlan.forEach((item) => {
            const plannedReduction = item?.reductionPlans?.reduce((total, reductionPlan) => total + reductionPlan?.plannedReduction[0], 0);
            totalReduction = (totalReduction * 10 + plannedReduction * 10) / 10;
            modifiedReductionPlan.push({
                scope_id: reductionPlan.eprflcatId,
                plannedReduction: plannedReduction
            });
        })
        result.forEach((scope) => {
            const reductionPlan = modifiedReductionPlan.find((item) => item.scope_id === scope.scope_id)
            if (reductionPlan) {
                scope.total_co2_e_t = (scope.total_co2_e_t * 10 - reductionPlan.plannedReduction * 10) / 10
            }
            firstYearEmissionList.push(scope);
        })
        return { result: result, firstYearEmissionList: isEPS ? null : firstYearEmissionList, totalReduction: totalReduction };
    }, [dispatch, filterParams, orgId])

    const getFirstYearEmission = useCallback(async () => {
        const firstYear = filterParams ? filterParams.startYear : currentYear;
        const isEPS = filterParams?.forecastType === 'By emission profile source';
        const startMonth = filterParams?.yearType !== 'Calendar Year' ? await getStartMonth() : 1;
        const payload = {
            orgId: orgId,
            data: {
                usrTimezone,
                usrDateFormat,
                startDate: getStartDate(startMonth, firstYear),
                endDate: getEndDate(startMonth, firstYear),
                entitiesIdsToInclude: [],
                epsIdsToInclude: [],
                isApplyAllocation: false
            }
        }
        try {
            const response = await dispatch(fetchCarbonFootprintByFullEmissionProfile(payload));
            if (response.payload) {
                const result = unwrapResult(response);
                setFirstYearEmission(Number(result.reduce((total, scope) => total + scope.total_co2_e_t, 0)));
                if (!isEPS) {
                    setFirstYearEmissionByScope(result);
                }
            } else { // get the previous year emission data
                const previousYearResult = await getPreviousYearEmissionData(payload, firstYear, isEPS, startMonth);
                if (previousYearResult.firstYearEmissionList) setFirstYearEmissionByScope(previousYearResult.firstYearEmissionList);
                if (previousYearResult.result) {
                    setFirstYearEmission(previousYearResult.result.reduce((total, scope) => total + scope.total_co2_e_t, 0) - previousYearResult.totalReduction);
                }
            }
            setIsUseBaseValue(false);
        } catch (error) {
            if (baseValue) {
                setFirstYearEmission(baseValue);
                setIsUseBaseValue(true);
            }
            else setFirstYearEmission(null);
        }
    }, [filterParams, baseValue, currentYear, dispatch, orgId, getPreviousYearEmissionData, getStartMonth, usrDateFormat, usrTimezone]);

    const getLabelsAndDatasets = useCallback(() => {
        setSuggestedMin(0)
        const labels = [];
        const datasets = [];
        const isEPS = filterParams?.forecastType === 'By emission profile source';
        if (firstYearEmission && (isEPS || (!isEPS && (firstYearEmissionByScope || baseValue))) && forecastData) {
            generateRandomColors(true);
            const others = [];
            let firstAllPositiveDatasetIndex = -1;
            const forecastDataWithPR = forecastData.map((item, index) => {
                const fullZeroArray = new Array(filterParams.endYear - filterParams.startYear + 1).fill(0);
                let plannedReduction = [];
                let totalPlannedReduction = 0;
                let totalPlannedReductionAfterYear = [];
                const previousEmission = [];
                const newDataset = {
                    type: 'bar',
                    yAxisID: 'stack-bar-y',
                    label: item.eprflcatName ? item.eprflcatName : item.eprflsrcName,
                    borderWidth: 1,
                    backgroundColor: generateRandomColors(),
                };
                if (hiddenLegends && hiddenLegends.includes(index + 2)) {
                    plannedReduction = fullZeroArray
                    newDataset.hidden = true;
                } else {
                    newDataset.hidden = false;
                    if (firstAllPositiveDatasetIndex === -1) {
                        firstAllPositiveDatasetIndex = index;
                    }
                    plannedReduction = item.reductionPlans.reduce((newArray, reductionPlan) => {
                        return newArray.map((sum, newItem) => (sum * 10 + Number(reductionPlan.plannedReduction[newItem]) * 10) / 10);
                    }, fullZeroArray);
                }
                plannedReduction.forEach((reduction) => {
                    if (!isEPS && !isUseBaseValue) {
                        const emissionOfItem = Number(firstYearEmissionByScope.find((scope) => scope.scope_id === item.eprflcatId)?.total_co2_e_t || 0)
                        previousEmission.push((emissionOfItem * 10 - totalPlannedReduction * 10) / 10);
                    }
                    totalPlannedReduction = (totalPlannedReduction * 10 + reduction * 10) / 10;
                    totalPlannedReductionAfterYear.push(totalPlannedReduction)
                })
                newDataset.data = plannedReduction;
                datasets.push(newDataset);
                return { ...item, plannedReduction: plannedReduction, previousEmission: previousEmission, totalPlannedReductionAfterYear: totalPlannedReductionAfterYear };
            });

            setForecastDataWithPlannedReduction(forecastDataWithPR);

            let emission = firstYearEmission;
            let newEmission = 0;
            let negativeValueYearIndex = -1;
            let negativeValueDatasetIndex = -1;
            for (let i = 0; i <= filterParams.endYear - filterParams.startYear; i++) {
                labels.push(i + filterParams.startYear);
                for (let j = 0; j < forecastDataWithPR.length; j++) {
                    newEmission = (emission * 10 - forecastDataWithPR[j].plannedReduction[i] * 10) / 10;

                    // hide stacked bar after the first year which has negative data 
                    if (negativeValueYearIndex > -1 && i > negativeValueYearIndex) {
                        datasets[j].data[i] = null;
                    } else if (negativeValueYearIndex === -1 && newEmission < 0) {
                        negativeValueYearIndex = i;
                        negativeValueDatasetIndex = j;
                        datasets[j].data[i] = [emission, newEmission]; // part of dataset crosses x axis (spec part)
                        setSuggestedMin(newEmission);
                    }
                    emission = newEmission;
                }
                if (negativeValueYearIndex === -1) {
                    setHasNegative(null)
                    others.push(emission);
                }
            }

            // if there are not any year which has negative data, then make the first part float
            if (negativeValueYearIndex > -1) {
                others.push(null);
                for (let j = negativeValueDatasetIndex + 1; j < forecastDataWithPR.length; j++) {
                    // all parts after spec part of the has-negative-value year will get negative values
                    datasets[j].data[negativeValueYearIndex] = - datasets[j].data[negativeValueYearIndex];
                }
                if (negativeValueDatasetIndex !== 0) {
                    setHasNegative(negativeValueDatasetIndex);
                    datasets.unshift(datasets.splice(negativeValueDatasetIndex, 1)[0]);
                    const newData = [...forecastData];
                    newData.unshift(newData.splice(negativeValueDatasetIndex, 1)[0])
                    setNewForecastData(newData);
                    datasets[firstAllPositiveDatasetIndex + 1].data[negativeValueYearIndex] =
                        [datasets[0].data[negativeValueYearIndex][0], datasets[0].data[negativeValueYearIndex][0] +
                            datasets[firstAllPositiveDatasetIndex + 1].data[negativeValueYearIndex]];
                }
            }

            datasets.unshift(
                {
                    type: 'line',
                    label: t('Forecast'),
                    fill: false,
                    borderWidth: 2,
                    borderColor: "#24F2EF",
                    backgroundColor: "#24F2EF",
                    data: others,
                },
                {
                    type: 'bar',
                    yAxisID: 'stack-bar-y',
                    label: 'other',
                    backgroundColor: 'transparent',
                    data: others,
                }
            );
        }
        return { labels: labels, datasets: datasets };
    }, [hiddenLegends, isUseBaseValue, forecastData, firstYearEmission, baseValue, filterParams, firstYearEmissionByScope, t]);

    const fetchForecastDataHandler = useCallback(async (dispatch, payload) => {
        setHiddenLegends([]);
        payload.data = {
            forecastType: filterParams.forecastType,
            startYear: parseInt(filterParams.startYear),
            endYear: parseInt(filterParams.endYear),
            yearType: filterParams.yearType,
            ...payload.data,
        };
        await getTargetDataset();
        return await dispatch(getForecastData(payload));
    }, [filterParams, getTargetDataset]);

    const generateYearList = useCallback(() => {
        const list = [];
        for (let i = targets[0].targetStartYear; i <= targets[0].targetYear; i++) {
            list.push({
                label: i,
                value: i
            });
        }
        setYearList(list)
    }, [targets]);

    const getFavouriteFilterDataHandler = (params, filters) => {
        const paramsData = {
            ...params,
            forecastType: filters.forecastType,
            startYear: parseInt(filters.startYear),
            endYear: parseInt(filters.endYear),
            yearType: filters.yearType,
        };
        setFilterParams(paramsData);
        return paramsData;
    };

    const setHiddenLegendsHandler = useCallback((index) => {
        if (index > 1) {
            index--;
            if (hasNegative) {
                if (index === 2) index = hasNegative + 2;
                else if (index <= hasNegative + 2) index--;
            }
            let newHiddenLegends = [...hiddenLegends];
            if (newHiddenLegends.includes(index)) {
                newHiddenLegends = newHiddenLegends.filter((legendIndex) => index !== legendIndex);
            } else {
                newHiddenLegends.push(index);
            }
            setHiddenLegends(newHiddenLegends);
        }
    }, [hasNegative, hiddenLegends]);

    useEffect(() => {
        dispatch(getTargets({ orgId }));
    }, [dispatch, orgId]);

    useEffect(() => {
        if (targets.length > 0) {
            generateYearList();
            if (!filterParams) {
                setFilterParams({
                    forecastType: 'By scope',
                    startYear: currentYear,
                    endYear: parseInt(targets[0].targetYear),
                    yearType: 'Calendar Year',
                });
            }
            setTargetDataset(getTargetDataset());
        }
    }, [targets, getTargetDataset, generateYearList, currentYear, filterParams])

    useEffect(() => {
        if (filterParams) {
            getFirstYearEmission();
        }
    }, [filterParams, getFirstYearEmission]);

    useEffect(() => {
        if (filterParams)
            fetchForecastDataHandler(dispatch, { orgId, data: {} });
    }, [filterParams, firstYearEmission, setFirstYearEmission, dispatch, fetchForecastDataHandler, orgId]);

    useEffect(() => {
        if (forecastDataStatus === LoadingState.loading) {
            setChartData({});
        }
        if (forecastDataStatus === LoadingState.succeeded) {
            setChartData(getLabelsAndDatasets());
        }
    }, [forecastDataStatus, getLabelsAndDatasets, hiddenLegends, setHiddenLegends]);

    useEffect(() => {
        generateChartLegend(chartRef, 2, setHiddenLegendsHandler);
    }, [forecastDataStatus, chartData, setHiddenLegendsHandler]);

    const customTooltipHandler = useCallback((tooltipModel, chart) => {
        // Tooltip Element
        let tooltipEl = document.getElementById('chartjs2-tooltip');

        const isEPS = filterParams?.forecastType === 'By emission profile source';
        let dataPoint;
        // Create element on first render
        if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.style.background = 'white';
            tooltipEl.style.borderRadius = '3px';
            tooltipEl.style.color = 'black';
            tooltipEl.style.opacity = 1;
            tooltipEl.style.pointerEvents = 'none';
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.transition = 'all .1s ease';
            tooltipEl.id = 'chartjs2-tooltip';
            const headerDiv = document.createElement('div');
            headerDiv.className = 'header-div';
            tooltipEl.appendChild(headerDiv);

            const table = document.createElement('table');
            table.style.margin = '0px';
            table.style.width = '100%';
            tooltipEl.appendChild(table);

            document.body.appendChild(tooltipEl);
        }
        // Hide if no tooltip
        if (tooltipModel.opacity === 0 || tooltipModel.dataPoints[0]?.datasetIndex === 0 || tooltipModel.dataPoints[0]?.datasetIndex === 2) {
            tooltipEl.style.opacity = 0;
            return;
        }
        dataPoint = tooltipModel.dataPoints[0];

        const headerDiv = tooltipEl.querySelector('div');
        // Remove old children
        while (headerDiv.firstChild) {
            headerDiv.firstChild.remove();
        }

        const tooltipTitle = document.createElement('h6');
        const tooltipLine = tooltipModel.body[0]?.lines[0]
        const splitIndex = tooltipLine?.lastIndexOf(': ')
        const forecastTypeTitle = isEPS ? t('EPS') : t('Scope');
        tooltipTitle.textContent = dataPoint.datasetIndex === 1
            ? t('Reduction plan by {{forecastTypeTitle}} - {{year}}', { forecastTypeTitle: forecastTypeTitle, year: tooltipModel.title[0] })
            : t('Reduction action plan of {{scopeName}} - {{year}}', { scopeName: tooltipLine?.substr(0, splitIndex), year: tooltipModel.title[0] });
        headerDiv.appendChild(tooltipTitle);

        if (dataPoint.datasetIndex === 1) { // forecast line
            const unitTitle = document.createElement('p');
            unitTitle.textContent = t('Unit: tonnes');
            unitTitle.style.marginRight = '5px';

            headerDiv.appendChild(unitTitle);
        } else { // stack bar
            const noteDiv = document.createElement('div');
            noteDiv.className = 'total-div';

            const totalTitle = document.createElement('h6');
            totalTitle.textContent = t('Total');
            totalTitle.style.color = 'red';
            totalTitle.style.marginRight = '5px';
            noteDiv.appendChild(totalTitle);

            const totalValue = document.createElement('h6');
            if (dataPoint.value[0] === '[') {
                const valueArray = dataPoint.value.substr(1, dataPoint.value.length - 2).split(', ');
                totalValue.textContent = (valueArray[1] * 10 - valueArray[0] * 10) / 10;
            } else {
                totalValue.textContent = dataPoint.value;
            }
            totalValue.textContent *= totalValue.textContent > 0 ? 1 : -1;
            totalValue.textContent = formatNumber(totalValue.textContent);
            noteDiv.appendChild(totalValue);

            headerDiv.appendChild(noteDiv);
        }

        // Set caret Position
        tooltipEl.classList.remove('above', 'below', 'no-transform');
        if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
        } else {
            tooltipEl.classList.add('no-transform');
        }

        const tableBody = document.createElement('tbody');
        const tableHead = document.createElement('thead');

        const headRow = document.createElement('tr');
        const header = dataPoint.datasetIndex === 1 ? [
            forecastTypeTitle,
            t('Previous emission'),
            t('Reduction plan'),
            t('Net plan emission'),
        ] : [
            t('Action Name'),
            t('Entity'),
            t('Emission Profile Source'),
            t('Target'),
            t('Unit'),
            t('CO2-e (t)')
        ]
        headRow.innerHTML = header.map(val => `<th>${val}</th>`).join('');
        tableHead.appendChild(headRow);

        // create table row
        const createTableRow = (cols, isBold = false) => {
            const tr = document.createElement('tr');
            tr.style.borderWidth = 0;
            tr.innerHTML = isBold ? cols.map(val => `<td><b>${val}</b></td>`).join('') : cols.map(val => `<td>${val}</td>`).join('');
            tableBody.appendChild(tr);
        };

        // chart data info
        if (dataPoint.datasetIndex === 1) { // forecast line
            let totalPreviousEmission = 0;
            if (!isEPS && !isUseBaseValue) {
                let totalReductionPlan = 0;
                forecastDataWithPlannedReduction.forEach((item) => {
                    const previousEmission = item.previousEmission[dataPoint.index];
                    const reductionPlan = item.plannedReduction[dataPoint.index];
                    const modifiedReductionPlan = Array.isArray(reductionPlan) ? reductionPlan[1] - reductionPlan[0] : reductionPlan;
                    const name = item.eprflcatName ? item.eprflcatName : item.eprflsrcName
                    totalReductionPlan = (totalReductionPlan * 10 + modifiedReductionPlan * 10) / 10;
                    totalPreviousEmission = (totalPreviousEmission * 10 + previousEmission * 10) / 10;
                    createTableRow([
                        name,
                        formatNumber(previousEmission),
                        formatNumber(modifiedReductionPlan),
                        formatNumber((previousEmission * 10 - modifiedReductionPlan * 10) / 10),
                    ]);
                })
                const haveReductionScopeIds = forecastDataWithPlannedReduction.map((item) => item.eprflcatId);
                firstYearEmissionByScope.forEach((scope) => {
                    if (!haveReductionScopeIds.includes(scope.scope_id)) {
                        const totalCo2ET = Number(scope.total_co2_e_t);
                        createTableRow([scope?.records?.[0].scope, formatNumber(totalCo2ET), 0, formatNumber(totalCo2ET)])
                    }
                })
                createTableRow([
                    t('Total'),
                    formatNumber(totalPreviousEmission),
                    formatNumber(totalReductionPlan),
                    formatNumber((totalPreviousEmission * 10 - totalReductionPlan * 10) / 10),
                ], true)
            } else {
                if (dataPoint.index > 0) {
                    const totalPlannedReductionAfterYear = forecastDataWithPlannedReduction.reduce((total, item) => {
                        return (total * 10 + item.totalPlannedReductionAfterYear[dataPoint.index - 1] * 10) / 10;
                    }, 0)
                    totalPreviousEmission = (firstYearEmission * 10 - totalPlannedReductionAfterYear * 10) / 10;
                } else {
                    totalPreviousEmission = firstYearEmission
                }
                createTableRow([
                    t('Total'),
                    formatNumber(totalPreviousEmission),
                    formatNumber((totalPreviousEmission * 10 - dataPoint.value * 10) / 10),
                    formatNumber(dataPoint.value),
                ], true)
            }
        } else { // stack bar
            const data = newForecastData ? newForecastData : forecastData;
            data[dataPoint.datasetIndex - 3].reductionPlans.forEach((item) => createTableRow([
                item.actionName,
                item.divName,
                item.eprflsrcName,
                formatNumber(item.reductionTarget[dataPoint.index]),
                item.reductionUnit,
                formatNumber(item.plannedReduction[dataPoint.index])
            ]));
        }
        const tableRoot = tooltipEl.querySelector('table');
        // Remove old children
        while (tableRoot.firstChild) {
            tableRoot.firstChild.remove();
        }
        // Add new children
        tableRoot.appendChild(tableHead);
        tableRoot.appendChild(tableBody);

        const position = chart.canvas.getBoundingClientRect();
        const tooltipElPosition = tooltipEl.getBoundingClientRect();
        const firstBarMeta = chart.getDatasetMeta(2);
        const barWidth = firstBarMeta.data[0]._model.width;
        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1;
        tooltipEl.style.left = position.left + tooltipModel.caretX + window.scrollX
            + (tooltipModel.caretX > position.width / 2 ? - (barWidth / 2 + tooltipElPosition.width + 5) : (barWidth / 2 + 5)) + 'px';
        let tooltipTop = position.top + tooltipModel.caretY + window.scrollY
        if (tooltipTop + tooltipElPosition.height > position.top + position.height) {
            tooltipTop -= tooltipElPosition.height
        }
        if (tooltipTop < 0) {
            tooltipTop = 0
        }
        tooltipEl.style.top = tooltipTop + 'px';
        tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
        tooltipEl.style.fontSize = tooltipModel.bodyFontSize;
        tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
    }, [forecastData, isUseBaseValue, filterParams, firstYearEmissionByScope, forecastDataWithPlannedReduction, newForecastData, firstYearEmission, t]);

    const options = useMemo(() => {
        const roundNestest = 10 ** (Math.max(String(Math.round(suggestedMin * (suggestedMin < 0 ? -1 : 1))).length, String(Math.round(firstYearEmission)).length) - 1);
        const min = Math.floor(suggestedMin / roundNestest) * roundNestest;
        const max = Math.ceil(firstYearEmission / roundNestest) * roundNestest;
        return createInitChartConfig({
            scales: {
                xAxes: [
                    {
                        stacked: true,
                        gridLines: {
                            drawOnChartArea: false,
                        }
                    }
                ],
                yAxes: [
                    {
                        stacked: false,
                        ticks: {
                            beginAtZero: true,
                            max: max,
                            min: Math.min(min, 0)
                        },
                        scaleLabel: {
                            display: true,
                            labelString: '(' + t('tonnes') + ')',
                        },
                    },
                    {
                        id: 'stack-bar-y',
                        stacked: true,
                        ticks: {
                            beginAtZero: true,
                            max: max,
                            min: Math.min(min, 0)
                        },
                        display: false,
                    },
                ]
            },
            tooltips: {
                enabled: false,
                custom: function (tooltipModel) {
                    customTooltipHandler(tooltipModel, this._chart);
                }
            },
        });
    }, [firstYearEmission, suggestedMin, customTooltipHandler, t]);

    const chartRender = () => {
        const datasets = chartData?.datasets?.length > 0 ? [
            {
                type: 'line',
                label: t('Target'),
                borderColor: '#FF5733',
                backgroundColor: '#FF5733',
                borderWidth: 2,
                pointRadius: 0,
                pointHoverRadius: 0,
                fill: false,
                data: targetDataset,
            },
            ...chartData.datasets] : [];
        const data = {
            labels: chartData.labels || [],
            datasets: datasets
        }
        return (
            <>
                <div className="chart-graph">
                    <Bar
                        data={data}
                        options={options}
                        ref={chartRef}
                        plugins={plugins}
                    />
                </div>
                <div id="js-legend" className="chart-legend"></div>
            </>
        )
    };

    const selectedReportYearTypeHandler = ({ target }) => {
        if (target.value === 'SETUP') {
            setupReportRangePeriod(history);
        }
    };

    const extraConfigRender = () => {
        return (
            <>
                <Select
                    label={t("Forecast Type")}
                    name="forecastType"
                    options={forecastTypeOptions}
                />
                <Select
                    label={t("Start Year")}
                    name="startYear"
                    options={yearList}
                />
                <Select
                    label={t("End Year")}
                    name="endYear"
                    options={yearList}
                />
                <Select
                    label={t("Year Type")}
                    name="yearType"
                    options={getReportYearTypeOptions(t, reportRangePeriod)}
                    onChange={selectedReportYearTypeHandler}
                />
            </>
        );
    };
    const underHeaderNote = () => {
        if (carbonFootPrintFullEmissionStatus === LoadingState.failed && firstYearEmission && baseValue)
            return (
                <p className='ml-2'>{t('The forecast is based on the temporary emission value of {{baseValue}} tonnes of CO2-e.', { baseValue })}
                    <Button variant="outline-secondary" onClick={() => setShowBaseValueModal(true)} style={{ border: 0 }}>
                        <AiOutlineEdit />
                    </Button>
                </p>
            )
    }

    return (
        <>
            {targetStatus === LoadingState.succeeded && targets.length > 0 && filterParams && (
                <ReportContainer
                    reportName={t('Reduction Forecast')}
                    reportSubTitle={t('Reduction Forecast')}
                    fetchReportData={fetchForecastDataHandler}
                    fetchReportStatus={forecastDataStatus}
                    reportData={!!(forecastData && targets.length > 0 && firstYearEmission)}
                    reportRender={chartRender}
                    errorRender={() => {
                        if ((!firstYearEmission && carbonFootPrintFullEmissionStatus === LoadingState.failed)
                            || forecastDataStatus === LoadingState.failed
                            || (forecastDataStatus === LoadingState.succeeded && !forecastData
                            ))
                            return <ErrorForecastPage setShowBaseValueModal={() => setShowBaseValueModal(true)} />
                    }}
                    getFavouriteFilterData={getFavouriteFilterDataHandler}
                    extraConfigRender={extraConfigRender}
                    downloadOptions={['pdf', 'png', 'jpeg']}
                    extraConfigDefault={{
                        forecastType: filterParams.forecastType,
                        startYear: parseInt(filterParams.startYear),
                        endYear: parseInt(filterParams.endYear),
                        yearType: filterParams.yearType,
                    }}
                    isUseEndDate={false}
                    isUseStartDate={false}
                    isUseEntityTree={false}
                    isUseEmissionProfileTree={false}
                    chartRef={chartRef}
                    primaryBtnText={t('Generate')}
                    dropFavOption={true}
                    underHeaderNote={() => underHeaderNote()}
                />
            )}
            {(targetStatus === LoadingState.failed || (targetStatus === LoadingState.succeeded && targets.length === 0)) && (
                <ErrorForecastPage setShowBaseValueModal={setShowBaseValueModal} />
            )}

            <BaseValueForm
                baseValue={baseValue}
                showBaseValueModal={showBaseValueModal}
                closeBaseValueModal={closeBaseValueModal}
                handleConfirmBaseValue={handleConfirmBaseValue}
            />
        </>
    );
};

export default ReductionForecast;
