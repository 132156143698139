import React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import LoadingButton from '../../components/LoadingButton';

const createSchema = yup.object().shape({
  bsnsmsrtypName: yup.string().max(128).required('Business Measure name is required'),
  bsnsmsrtypAssignedId: yup
    .string()
    .matches(/^([A-Za-z0-9-]){1,15}$/, {
      message: 'Id may contain only characters, numbers, hyphen and less than 15 characters'
    })
    .required('Business Measure ID is required')
});

const BusinessMeasureForm = ({
  onSubmit,
  onCancel,
  initialValues = {},
  buttonText = 'Submit',
  isLoading = false,
  isEditing = false
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty }
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(createSchema),
    mode: 'onChange'
  });

  const { t } = useTranslation();

  const onSubmitHandler = (data) => onSubmit(data);
  const onCancelHandler = () => onCancel();

  return (
    <Form onSubmit={handleSubmit(onSubmitHandler)}>
      <Row>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Business Measure Id')} *</Form.Label>
            <Form.Control
              {...register('bsnsmsrtypAssignedId')}
              readOnly={isEditing}
              isInvalid={errors.bsnsmsrtypAssignedId}
            />
            <Form.Control.Feedback type="invalid">{errors.bsnsmsrtypAssignedId?.message}</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Business Measure Name')} *</Form.Label>
            <Form.Control {...register('bsnsmsrtypName')} isInvalid={errors.bsnsmsrtypName} />
            <Form.Control.Feedback type="invalid">{errors.bsnsmsrtypName?.message}</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Form.Group className="custom-form-control">
        <Form.Label>{t('Description')}</Form.Label>
        <Form.Control as="textarea" {...register('bsnsmsrtypDesc')} />
      </Form.Group>
      <Button className="mt-3 mb-5" type="submit" disabled={!isValid || !isDirty || isLoading}>
        {buttonText}
      </Button>
      <Button variant="secondary" className="ml-4 mt-3 mb-5" onClick={onCancelHandler}>
        {t('Cancel')}
      </Button>
      <LoadingButton isLoading={isLoading} className="ml-4 mt-3 mb-5" />
    </Form>
  );
};

export default BusinessMeasureForm;
