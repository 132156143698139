import React, { useEffect, useMemo } from 'react';
import { Button, Col, Container, Row, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import PageHeader from '../../components/PageHeader';
import { LoadingState } from '../../utilities/constants';
import { fetchAccounts } from './accountsSlice';
import { IoEyeOutline } from 'react-icons/io5';
import { AiOutlineEdit } from 'react-icons/ai';
import DataTable from '../../components/DataTable';
import Empty from '../../components/Empty';
import { useTranslation } from 'react-i18next';

const ManageAccount = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const { t } = useTranslation();
  const accountStatus = useSelector((state) => state.accounts.status);
  const accounts = useSelector((state) => state.accounts.accounts);
  const orgId = useSelector((state) => state.users.user.usrOrgId);
  const loggedUserStatus = useSelector((state) => state.users.loggedUserStatus);

  const accountTableColumns = useMemo(
    () => [
      {
        Header: t('Account Id'),
        accessor: 'acntAssignedId',
        sortType: 'string'
      },
      {
        Header: t('Account Name'),
        accessor: 'acntName',
        sortType: 'string'
      },
      {
        Header: t('Description'),
        accessor: 'acntDesc',
        sortType: 'string'
      },
      {
        Header: '',
        accessor: 'actions',
        actionColumn: true,
        disableGlobalFilter: false,
        disableSortBy: true,
        Cell: ({ row }) => {
          const account = row.original;
          return (
            <div className="d-flex action-buttons">
              <Link to={`${match.url}/${account.acntId}`}>
                <Button variant="outline-secondary" className="mr-3">
                  <IoEyeOutline />
                </Button>
              </Link>
              <Link to={`${match.url}/${account.acntId}/edit`}>
                <Button variant="outline-secondary">
                  <AiOutlineEdit />
                </Button>
              </Link>
            </div>
          );
        }
      }
    ],
    [match.url, t]
  );

  useEffect(() => {
    if (accountStatus === LoadingState.idle && loggedUserStatus === LoadingState.succeeded) {
      dispatch(fetchAccounts({ orgId }));
    }
  }, [orgId, loggedUserStatus, accountStatus, dispatch]);

  return (
    <Container fluid>
      <Row>
        <Col>
          <PageHeader
            title={t('Manage Accounts')}
            actions={
              <Button to={`${match.url}/new`} as={Link}>
                + {t('Create Account')}
              </Button>
            }
          />
        </Col>
      </Row>
      {accountStatus === LoadingState.loading && <Spinner animation="grow" />}
      {accountStatus === LoadingState.succeeded && (
        <Row>
          <Col>
            <DataTable columns={accountTableColumns} data={accounts} />
          </Col>
        </Row>
      )}
      {accountStatus === LoadingState.failed && <Empty title="Sorry, Something went wrong" />}
    </Container>
  );
};

export default ManageAccount;
