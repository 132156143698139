import keycloak from '../../keycloak';
import { uploadToS3 } from '../../utilities/apiHelper';
import { config } from '../../utilities/config';

export async function fetchEmissionDataApi(organizationId, entityId, emissionProfileId) {
  const response = await fetch(
    `${config.API_BASE_URL}/${organizationId}/emission/eps/${emissionProfileId}/entity/${entityId}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${keycloak.token}`
      }
    }
  );
  const data = await response.json();
  if (!response.ok) throw new Error(data.message);
  return data;
}

export async function fetchSingleEmissionDataApi(organizationId, emissionId) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/emission/${emissionId}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    }
  });
  const data = await response.json();
  if (!response.ok) throw new Error(data.message);
  return data;
}

export async function createEmissionApi(organizationId, data) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/emission`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function deleteEmissionApi(organizationId, emissionId) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/emission/${emissionId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    }
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function updateEmissionApi(organizationId, emissionId, data) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/emission/${emissionId}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function fetchEmissionSourceUnitsApi(organizationId, emissionSourceId) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/emission-source/${emissionSourceId}/units`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    }
  });
  const data = await response.json();
  if (!response.ok) throw new Error(data.message);
  return data;
}

export async function uploadAttachmentApi(organizationId, file) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/emission-attachment/generate`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
    },
  });
  const signedPost = await response.json();
  if (!response.ok) throw new Error(signedPost.message);
  await uploadToS3(signedPost, file);
  return { key: signedPost.fields.key, name: file.name };
}

export async function fetchEmissionProfileSourceUnitApi(organizationId, emissionProfileId) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/emission-profile/${emissionProfileId}/units`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    }
  });
  const data = await response.json();
  if (!response.ok) throw new Error(data.message);
  return data;
}
