import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Col, Container, Row, Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingState } from '../../utilities/constants';
import PageHeader from '../../components/PageHeader';
import { recursiveMap } from '../../utilities/treeHelpers';
import { useTranslation } from 'react-i18next';
import { exportCsv, formatDate, parseUserInfo } from '../../utilities/common';
import { fetchOrganizations } from '../organization/OrganizationSlice';
import { fetchEmissionProfilesTreeApi } from '../organization/OrganizationApi';
import Select from 'react-select';
import { toast } from 'react-toastify';
import LoadingButton from '../../components/LoadingButton';

const CustomEmissionProfileSourceReport = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  parseUserInfo(useSelector((state) => state.users.user));
  const organizations = useSelector((state) => state.organizations.organizations);
  const loggedUserStatus = useSelector((state) => state.users.loggedUserStatus);
  const [selectedOrg, setSelectedOrg] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const onSubmitGenerateReport = async () => {
    setIsLoading(true);
    try {
      const orgId = selectedOrg?.value;
      const emissionProfileTree = await fetchEmissionProfilesTreeApi(orgId);
      const exportData = [
        [
          'No.',
          'Emission Source ID',
          'Emission Source Name',
          'Emission Source Category',
          'Emission Standard',
          `Emission Source Type`,
          `Emission Source`,
          'Description',
          'Period Start Date',
          'Period End Date',
          'Consumption Unit',
          'Emission Factor in CO2-e (t)',
          'Energy Content Factor (TJ)',
          'Linked Emission for Full Fuel Cycle',
        ],
      ];
      let idx = 1;
      recursiveMap(emissionProfileTree ?? {}, (n, parentNode) => {
        if (n.eprflId || n.eprflcatId || !n.eprflsrcId) {
          return;
        }
        exportData.push([
          idx++,
          n.eprflsrcAssignedId,
          n.eprflsrcName,
          parentNode?.eprflcatName || '',
          n.eprflsrcEsrc?.esrcEstd?.estdName || 'Custom Emission Source',
          n.eprflsrcEsrc?.esrcUiType?.esrcuitypUiname || '',
          n.eprflsrcEsrc?.esrcName || '',
          n.eprflsrcDesc || '',
          formatDate(n.eprflsrcStartDate),
          formatDate(n.eprflsrcEndDate),
          n.eprflsrcConsumptionUnit || '',
          n.eprflsrcConversionFactor || '',
          n.eprflsrcConversionFactortj || '',
          n.eprflsrcLinkedId?.eprflsrcName || '',
        ]);
        return;
      });
      const fileName = _.startCase(selectedOrg?.label || 'eps-report');
      exportCsv(exportData, `${fileName} EPS Report`);
    } catch (err) {
      toast.error(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const reportOrgList = () => organizations?.map(item =>
    ({ label: item.orgName, value: item.orgId }));
  useEffect(() => {
    if (loggedUserStatus === LoadingState.succeeded) {
      dispatch(fetchOrganizations());
    }
  }, [loggedUserStatus, dispatch]);

  return (
    <Container fluid>
      <Row>
        <Col>
          <PageHeader title={t('Custom Emission Profile Report')} />
        </Col>
      </Row>
      <Row>
        {loggedUserStatus === LoadingState.succeeded && (
          <Col>
            <Form className="configuration-form-control">
              <div className="configuration-row">
                <Select
                  placeholder="Select a organization"
                  className="select"
                  options={reportOrgList()}
                  menuPortalTarget={document.body}
                  onChange={setSelectedOrg}
                  menuPlacement="top" />
                <div className="ml-3">
                  <Button
                    variant="primary"
                    type="button"
                    disabled={!selectedOrg?.value || isLoading}
                    onClick={onSubmitGenerateReport}
                  >
                    {t('Download Report')}
                  </Button>
                  <LoadingButton isLoading={isLoading} />
                </div>
              </div>
            </Form>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default CustomEmissionProfileSourceReport;
