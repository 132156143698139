import React, { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import { datePickerValue, parseUserInfo } from '../../utilities/common';
import { IoAddCircleOutline } from 'react-icons/io5';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const AddEntityBusinessMeasure = ({ onSubmit, businessMeasureOptions, isLoading = false }) => {
  const [addBusinessMeasure, setAddBusinessMeasure] = useState(null);
  const [addStartDate, setAddStartDate] = useState(new Date());
  const [addDivData, setAddDivData] = useState("");
  const [isEnabled, setIsEnabled] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const { t } = useTranslation();
  const { usrDateFormat } = parseUserInfo(useSelector((state) => state.users.user));

  useEffect(() => {
    setIsEnabled(addBusinessMeasure && addDivData > 0);
    setIsValid(addDivData === "" || (addDivData > 0 && addDivData % 1 === 0));
  }, [addBusinessMeasure, addDivData]);

  const handleChangeBusinessMeasure = (value) => setAddBusinessMeasure(value);

  const handleChangeStartDate = (date) => {
    let bmStartDate;
    if (!date) {
      bmStartDate = null;
    } else {
      bmStartDate = date;
    }
    setAddStartDate(bmStartDate);
    if (bmStartDate) {
      setIsEnabled(true);
    } else {
      setIsEnabled(false);
    }
  };

  const handleChangeDivData = (e) => setAddDivData(e.target.value);

  const handleSubmit = () => onSubmit(addBusinessMeasure.value, addStartDate, addDivData);

  return (
    <tfoot>
      <tr>
        <td>
          <Select
            options={businessMeasureOptions}
            value={addBusinessMeasure}
            menuPortalTarget={document.body}
            onChange={handleChangeBusinessMeasure}
            menuPlacement="top"
          />
        </td>

        <td>
          <ReactDatePicker
            isClearable
            className="form-control"
            dateFormat={usrDateFormat}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            {...datePickerValue(addStartDate, handleChangeStartDate)}
          />
        </td>
        <td className="w-30percent">
          <Form.Group className={!isValid ? "pt-3 mb-0" : "mb-0"}>
            <Form.Control
              value={addDivData}
              min={1}
              type="number"
              onChange={handleChangeDivData}
              isInvalid={!isValid}
            />
            <Form.Control.Feedback type="invalid">{t("Business Measure must be integer and greater than 0.")}</Form.Control.Feedback>
          </Form.Group>
        </td>
        <td>
          <div>
            <Button disabled={!isEnabled || !isValid || isLoading} variant="dark" className="table-add-button" onClick={handleSubmit}>
              <IoAddCircleOutline />
            </Button>
          </div>
        </td>
      </tr>
    </tfoot>
  );
};

export default AddEntityBusinessMeasure;
