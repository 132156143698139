import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { LoadingState } from '../../utilities/constants';
import {
  fetchEntityBusinessMeasuresApi,
  assignEntityBusinessMeasureApi,
  updateEntityBusinessMeasureApi,
  removeEntityBusinessMeasureApi
} from './entityBusinessMeasureApi';

export const fetchEntityBusinessMeasures = createAsyncThunk(
  'entityBusinessMeasures/fetchBusinessMeasures',
  async (payload) => {
    try {
      const response = await fetchEntityBusinessMeasuresApi(payload.orgId, payload.entityId);
      return response;
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  }
);

export const assignEntityBusinessMeasure = createAsyncThunk(
  'entityBusinessMeasures/assignEntityBusinessMeasure',
  async (payload) => {
    try {
      await assignEntityBusinessMeasureApi(payload.orgId, payload.entityId, payload.data);
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  }
);

export const updateEntityBusinessMeasure = createAsyncThunk(
  'entityBusinessMeasures/assignEntityBusinessMeasure',
  async (payload) => {
    try {
      await updateEntityBusinessMeasureApi(payload.orgId, payload.entityId, payload.bsnsmsrId, payload.data);
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  }
);

export const removeEntityBusinessMeasure = createAsyncThunk(
  'entityBusinessMeasures/removeEntityBusinessMeasure',
  async (payload) => {
    try {
      await removeEntityBusinessMeasureApi(payload.orgId, payload.entityId, payload.bsnsmsrId);
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  }
);

const entityBusinessMeasuresSlice = createSlice({
  name: 'entityBusinessMeasures',
  initialState: {
    entityBusinessMeasures: [],
    status: LoadingState.idle,
    error: null
  },
  reducers: {},
  extraReducers: {
    [fetchEntityBusinessMeasures.pending]: (state) => {
      state.status = LoadingState.loading;
    },
    [fetchEntityBusinessMeasures.fulfilled]: (state, action) => {
      state.status = LoadingState.succeeded;
      state.entityBusinessMeasures = action.payload;
    },
    [fetchEntityBusinessMeasures.rejected]: (state, action) => {
      state.status = LoadingState.failed;
      state.error = action.error.message;
    }
  }
});

export default entityBusinessMeasuresSlice.reducer;
