import { Container, Row, Col, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import PageHeader from '../../../components/PageHeader';
import Configuration from '../configurations/Configuration';
import { DatePicker } from '../configurations/DatePicker';
import { TreeViewSelector } from '../configurations/TreeViewSelector';
import { fetchEntityTree } from '../../entity/entitySlice';
import { fetchEmissionProfilesTree } from '../../emission-profile/emissionProfileSlice';
import { fetchAssets, convertToTree } from '../../assets/assetsSlice';
import { useEffect, useState } from 'react';
import { LoadingState } from '../../../utilities/constants';
import { unwrapResult } from '@reduxjs/toolkit';
import { fetchTransactionReport } from '../reportsSlice';
import { toast } from 'react-toastify';
import {
  getCurrentReportPeriod,
  getReportRangePeriod,
  transCsvHeader,
} from '../reportHelper';
import { downloadCsv, parseUserInfo } from '../../../utilities/common';
import { useTranslation } from 'react-i18next';

const Transaction = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { orgId, usrDateFormat, usrTimezone } = parseUserInfo(useSelector((state) => state.users.user));
  const loggedUserStatus = useSelector((state) => state.users.loggedUserStatus);
  const entityTree = useSelector((state) => state.entities.tree);
  const emissionProfileTree = useSelector((state) => state.emissionProfiles.tree);
  const assetsTree = useSelector((state) => convertToTree(state));
  const entityTreeStatus = useSelector((state) => state.entities.status);
  const emissionProfileTreeStatus = useSelector((state) => state.emissionProfiles.status);
  const assetsStatus = useSelector((state) => state.assets.status);

  const [selectedEntity, setSelectedEntity] = useState([]);
  const [selectedEPS, setSelectedEPS] = useState([]);
  const [selectedAsset, setSelectedAsset] = useState([]);

  const title = t('Transaction Report');

  const [isLoading, setIsLoading] = useState(false);
  const userInfo = useSelector((state) => state.users.user);
  const currentReportPeriod = getCurrentReportPeriod(userInfo.usrOrgOptions);
  const reportRangePeriod = getReportRangePeriod(userInfo.usrOrgOptions, currentReportPeriod.reportYear);

  const [formData, setFormData] = useState({
    startDate: reportRangePeriod.startDate,
    endDate: reportRangePeriod.endDate,
  });
  const onFormChangeHandler = (field, value) => {
    if (value !== formData[field]) {
      setFormData((prevState) => ({
        ...prevState,
        [field]: value
      }));
    }
  };

  const onSubmitHandler = async (filters) => {
    setIsLoading(true);
    const data = {
      startDate: filters.startDate,
      endDate: filters.endDate,
      entitiesIdsToInclude: selectedEntity,
      epsIdsToInclude: selectedEPS,
      assetsIdsToInclude: selectedAsset,
      usrDateFormat,
      usrTimezone,
    };
    try {
      const resultAction = await dispatch(fetchTransactionReport({ orgId, data }));
      if (!resultAction.error) {
        unwrapResult(resultAction);
      }
      const csvContent = transCsvHeader(resultAction.payload?.data);
      if (csvContent === false) {
        toast.error(t('Invalid csv file content!'));
      } else {
        downloadCsv(csvContent, 'transaction_report.csv');
      }
    } catch (e) {
      toast.error(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (loggedUserStatus === LoadingState.succeeded) {
      dispatch(fetchEntityTree({ orgId }));
      dispatch(fetchEmissionProfilesTree({ orgId }));
      dispatch(fetchAssets({ orgId }));
    }
  }, [dispatch, loggedUserStatus, orgId]);

  return (
    <Container fluid className="mt-3">
      <Col>
        <PageHeader.Reports title={title} enableBackButton />
        <Row>
          {entityTreeStatus === LoadingState.succeeded &&
            emissionProfileTreeStatus === LoadingState.succeeded &&
            assetsStatus === LoadingState.succeeded && (
              <Col>
                <Configuration
                  onSubmit={onSubmitHandler}
                  defaultValues={{
                    startDate: reportRangePeriod.startDate,
                    endDate: reportRangePeriod.endDate,
                  }}
                  primaryBtnText={t("Download Report")}
                  dropFavOption={true}
                  isSubmitting={isLoading}
                >
                  <DatePicker 
                    dateFormat={usrDateFormat}
                    label={t("Start Date")} name="startDate"
                    onChangeHandler={onFormChangeHandler}
                    maxDate={formData.endDate}
                  />
                  <DatePicker 
                    dateFormat={usrDateFormat} 
                    label={t("End Date")}
                    name="endDate"
                    onChangeHandler={onFormChangeHandler}
                    minDate={formData.startDate}
                  />
                  <TreeViewSelector
                    label={t("Entity")}
                    options={entityTree}
                    selectedEntity={selectedEntity}
                    setSelectedEntity={setSelectedEntity}
                  />
                  <TreeViewSelector
                    label={t("Emission Source")}
                    options={emissionProfileTree}
                    selectedEPS={selectedEPS}
                    setSelectedEPS={setSelectedEPS}
                  />
                  <TreeViewSelector
                    label={t("Asset")}
                    options={assetsTree}
                    selectedAsset={selectedAsset}
                    setSelectedAsset={setSelectedAsset}
                  />
                </Configuration>
              </Col>
            )}
          {entityTreeStatus === LoadingState.loading &&
            emissionProfileTreeStatus === LoadingState.loading &&
            assetsStatus === LoadingState.loading && <Spinner animation="grow" />}
        </Row>
      </Col>
    </Container>
  );
};

export default Transaction;
