import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import PageHeader from '../../components/PageHeader';
import { LoadingState } from '../../utilities/constants';
import DataTable from '../../components/DataTable';
import {
  fetchEpsGroups,
  fetchAssignedEpsForEpsGroup,
  createEpsGroup,
  removeEpsGroup,
  selectEpsGroupById,
  editEpsGroup
} from './epsGroupSlice';
import Select from 'react-select';
import { BsPencil } from 'react-icons/bs';
import { unwrapResult } from '@reduxjs/toolkit';
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { IoTrashOutline } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';

const ManageEpsGroups = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const { epsGroupId } = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  const assignmentStatus = useSelector((state) => state.epsGroups.assignmentStatus);
  const epsGroups = useSelector((state) => state.epsGroups.epsGroups);
  const assignedEpsToEpsGroup = useSelector((state) => state.epsGroups.assignedEpsToEpsGroup);
  const [showEditEpsGroupModal, setShowEditEpsGroupModal] = useState(false);
  const [showCreateEpsGroupModal, setShowCreateEpsGroupModal] = useState(false);
  const [showDeleteEpsGroupModal, setShowDeleteEpsGroupModal] = useState(false);
  const [selectedEpsGroupId, setSelectedEpsGroupId] = useState(epsGroupId);
  const [selectedEpsGroupName, setSelectedEpsGroupName] = useState();
  const [editEpsGroupName, setEditEpsGroupName] = useState();
  const [isCreatingEpsGroup, setIsCreatingEpsGroup] = useState(false);
  const [isEditingEpsGroup, setIsEditingEpsGroup] = useState(false);
  const epsGroup = useSelector((state) => selectEpsGroupById(state, epsGroupId));
  const orgId = useSelector((state) => state.users.user.usrOrgId);
  const loggedUserStatus = useSelector((state) => state.users.loggedUserStatus);

  const canCreateEpsGroup = selectedEpsGroupName && !isCreatingEpsGroup;
  const canEditEpsGroup = editEpsGroupName && !isEditingEpsGroup && epsGroup.epgName !== editEpsGroupName;
  useEffect(() => {
    if (!!showEditEpsGroupModal) {
      setEditEpsGroupName(epsGroup.epgName);
    }
  }, [showEditEpsGroupModal, epsGroup]);
  const openCreateModal = () => setShowCreateEpsGroupModal(true);
  const openEditModal = () => setShowEditEpsGroupModal(true);
  const openDeleteModal = () => setShowDeleteEpsGroupModal(true);
  const closeCreateModal = () => {
    setSelectedEpsGroupName(null);
    setShowCreateEpsGroupModal(false);
  };
  const closeEditModal = () => {
    setEditEpsGroupName(null);
    setShowEditEpsGroupModal(false);
  };
  const closeDeleteModal = () => setShowDeleteEpsGroupModal(false);

  const sortStrings = (rowA, rowB, columnId, desc) => {
    if (!rowA.values[columnId] && !rowB.values[columnId]) {
      return 0;
    }

    if (!rowA.values[columnId]) {
      return desc ? -1 : 1;
    }

    if (!rowB.values[columnId]) {
      return desc ? 1 : -1;
    }

    return rowA.values[columnId].localeCompare(rowB.values[columnId]);
  };

  const columns = useMemo(
    () => [
      {
        Header: t('Emission Profile Source'),
        accessor: 'eprflsrcName',
        sortType: 'string'
      },
      {
        Header: t('Emission Source'),
        id: 'eprflsrcEsrc',
        accessor: (row) => row.eprflsrcEsrc?.esrcName,
        sortType: sortStrings
      },
      {
        Header: t('Profile Category'),
        id: 'eprflsrcEprflcat',
        accessor: (row) => row.eprflsrcEprflcat?.eprflcatName,
        sortType: 'string'
      },
      {
        Header: t('Top Level Category'),
        id: 'eprflsrcTopEprflcat',
        accessor: (row) => row.eprflsrcTopEprflcat?.eprflcatName,
        sortType: 'string'
      },
      {
        Header: t('Consumption Unit'),
        accessor: 'eprflsrcConsumptionUnit',
        sortType: sortStrings
      }
    ],
    [t]
  );

  const handleInputChange = async (event) => {
    const epsGroupId = event.value;
    setSelectedEpsGroupId(epsGroupId);
    const resultAction = await dispatch(fetchAssignedEpsForEpsGroup({ orgId, epsGroupId }));
    unwrapResult(resultAction);
    history.push(`/admin/settings/emission-profile-groups/${epsGroupId}`);
  };

  const handleCreateEpsGroup = async () => {
    setIsCreatingEpsGroup(true);
    try {
      const data = {
        epgName: selectedEpsGroupName
      };
      const resultAction = await dispatch(createEpsGroup({ orgId, data }));
      const resultAction2 = await dispatch(fetchEpsGroups({ orgId }));
      const epsGroupId = resultAction.payload.epgId;
      const resultAction3 = await dispatch(fetchAssignedEpsForEpsGroup({ orgId, epsGroupId }));
      unwrapResult(resultAction);
      unwrapResult(resultAction2);
      unwrapResult(resultAction3);
      closeCreateModal();
      setSelectedEpsGroupName(null);
      history.push(`/admin/settings/emission-profile-groups/${epsGroupId}`);
    } catch (error) {
    } finally {
      setIsCreatingEpsGroup(false);
    }
  };

  const handleEditEpsGroup = async () => {
    setIsEditingEpsGroup(true);
    try {
      const data = {
        epgName: editEpsGroupName
      };
      const epsGroupId = epsGroup.epgId;
      const resultAction = await dispatch(editEpsGroup({ orgId, epsGroupId, data }));
      unwrapResult(resultAction);
      closeEditModal();
      setEditEpsGroupName(null);
    } catch (error) {
    } finally {
      setIsEditingEpsGroup(false);
    }
  };

  const handleDeleteEpsGroup = async () => {
    try {
      const epsGroupId = epsGroup.epgId;
      const resultAction = await dispatch(removeEpsGroup({ orgId, epsGroupId }));
      unwrapResult(resultAction);
      closeDeleteModal();
      history.push(`/admin/settings/emission-profile-groups`);
    } catch (error) {
    } finally {
    }
  };

  const epsGroupOptions = epsGroups.map((value) => {
    return { value: value.epgId, label: value.epgName };
  });

  useEffect(() => {
    if (assignmentStatus === LoadingState.idle && loggedUserStatus === LoadingState.succeeded) {
      dispatch(fetchEpsGroups({ orgId }));
      dispatch(fetchAssignedEpsForEpsGroup({ orgId, epsGroupId }));
    }
  }, [orgId, loggedUserStatus, assignmentStatus, epsGroupId, dispatch]);

  const dropDownStyle = {
    marginRight: '50px'
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          {epsGroups?.length > 0 && (
            <PageHeader
              title={t('Manage Emission Profile Groups')}
              actions={
                <div style={dropDownStyle} className="d-flex align-items-center form-group">
                  <span className="mr-4 form-label">
                    {t('Emission Profile Groups')}
                  </span>
                  <Select
                    className="select"
                    options={epsGroupOptions}
                    value={
                      selectedEpsGroupId && epsGroup
                        ? epsGroupOptions.filter(function (e) {
                            return e.value === epsGroup.epgId;
                          })
                        : epsGroupOptions[0]
                    }
                    defaultValue={
                      selectedEpsGroupId && epsGroup
                        ? epsGroupOptions.filter(function (e) {
                            return e.value === epsGroup.epgId;
                          })
                        : epsGroupOptions[0]
                    }
                    onChange={handleInputChange}
                  />
                </div>
              }
            />
          )}
          {epsGroups?.length > 0 && (
            <div className="d-flex flex-row align-items-center justify-content-between my-4 pageheader page-header">
              {epsGroup ? (
                <div className="d-flex align-items-center action-buttons">
                  <h5 className="mb-0 mr-3 font-weight-bold">{epsGroup.epgName}</h5>
                  <span className="page-header-edit-icon mr-2" onClick={openEditModal}>
                    <BsPencil size={15} className="mt-1" />
                  </span>
                  <span className="page-header-edit-icon" onClick={openDeleteModal}>
                    <IoTrashOutline size={15} className="mt-1" />
                  </span>
                </div>
              ) : (
                <div className="d-flex align-items-center action-buttons" />
              )}

              <div className="pageheader__actions">
                <Button onClick={openCreateModal}>+ {t('Create New Group')}</Button>
              </div>
            </div>
          )}
        </Col>
      </Row>
      {assignmentStatus === LoadingState.loading && <Spinner animation="grow" />}
      {assignmentStatus === LoadingState.succeeded && epsGroups && (
        <Row>
          <Col>
            <DataTable
              columns={columns}
              data={assignedEpsToEpsGroup}
              headerActions={
                <Button variant="secondary" to={`${match.url}/assign-eps`} as={Link}>
                  {t('Assign Emission Profile Sources')}
                </Button>
              }
            />
          </Col>
        </Row>
      )}
      <Modal show={showCreateEpsGroupModal} onHide={closeCreateModal} className="sisde-modal">
        <Modal.Header>
          <Modal.Title>{t('New Emission Profile Group')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="custom-form-control">
              <Form.Label>{t('Group Name')} *</Form.Label>
              <Form.Control
                value={selectedEpsGroupName}
                onChange={(e) => setSelectedEpsGroupName(e.target.value)}
                type="text"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeCreateModal}>
            {t('Cancel')}
          </Button>
          <Button variant="primary" onClick={handleCreateEpsGroup} disabled={!canCreateEpsGroup}>
            {t('Create')}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showEditEpsGroupModal} onHide={closeEditModal} className="sisde-modal">
        <Modal.Header>
          <Modal.Title>{t('Edit Emission Profile Group')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="custom-form-control">
              <Form.Label>{t('Group Name')} *</Form.Label>
              <Form.Control
                value={editEpsGroupName}
                onChange={(e) => setEditEpsGroupName(e.target.value)}
                type="text"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeEditModal}>
            {t('Cancel')}
          </Button>
          <Button variant="primary" onClick={handleEditEpsGroup} disabled={!canEditEpsGroup}>
            {t('Save')}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showDeleteEpsGroupModal} onHide={closeDeleteModal} className="sisde-modal">
        <Modal.Header closeButton>
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>{'Are you sure you want to delete this emission profile source group?'}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeDeleteModal}>
            {t('Close')}
          </Button>
          <Button type="submit" variant="danger" onClick={handleDeleteEpsGroup}>
            {t('Delete')}
          </Button>
        </Modal.Footer>
      </Modal>
      {assignmentStatus === LoadingState.failed && <div>No such emission profile source found</div>}
    </Container>
  );
};

export default ManageEpsGroups;
