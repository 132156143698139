import React, { useEffect, useCallback } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { setHistoryStack } from '../../features/manage-users/usersSlice';
import { ToastContainer } from 'react-toastify';

// Components
import Header from './Header';
import Main from './Main';
import LeavePrompt from '../LeavePrompt';
import { useTranslation } from 'react-i18next';
import { setDefaultTranslation } from '../../features/reports/reportHelper';
import { initRedirectToLoginOnfetch } from '../../utilities/apiHelper';

const AppLayout = (props) => {
  console.log('load layout')
  const userInfo = useSelector((state) => state.users.user);
  const isLeavePrompt = useSelector((state) => state.users.isLeavePrompt);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  setDefaultTranslation(t);
  const location = useLocation();
  initRedirectToLoginOnfetch(t);

  useEffect(() => {
    dispatch(setHistoryStack(location.pathname));
  }, [location, dispatch]);

  const handleUnload = useCallback((e) => {
    if (!isLeavePrompt) {
      return;
    }
    const event = e || window.event;
    const message = t('You have unsaved changes. Are you sure to discard them and leave the screen?');
    if (event) {
      event.returnValue = message;
    }
    return message;
  }, [isLeavePrompt, t]);

  useEffect(() => {
    window.addEventListener('beforeunload', handleUnload)
    return () => window.removeEventListener('beforeunload', handleUnload)
  }, [handleUnload]);

  const loadingComponent = (
    <div className="loader-container">
      <Spinner className="loader" animation="grow" />
    </div>
  );

  return (
    <>
      <div className="flex-column" style={{ minHeight: '100vh', backgroundColor: '#FDFDFD' }}>
        <Header />
        <Main>{_.isEmpty(userInfo) ? loadingComponent : props.children}</Main>
        <ToastContainer />
      </div>
      <LeavePrompt isLeavePrompt={isLeavePrompt} />
    </>
  );
};

export default AppLayout;
