import React, { useMemo } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAssignedEntities } from './accountsSlice';
import DataTable from '../../components/DataTable';
import { useTranslation } from 'react-i18next';

const ViewAssignUsers = ({ accountId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const assignedEntities = useSelector((state) => state.accounts.assignedEntities);
  const orgId = useSelector((state) => state.users.user.usrOrgId);

  const viewAssignedEntitiesTableColumns = useMemo(
    () => [
      {
        Header: t('Entity'),
        accessor: 'divName'
      },
      {
        Header: t('Parent'),
        accessor: 'divParentName'
      },
      {
        Header: t('First Level Parent'),
        accessor: 'divTopDivName'
      }
    ],
    [t]
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resultAction = await dispatch(fetchAssignedEntities({ orgId, accountId }));
        unwrapResult(resultAction);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [orgId, accountId, dispatch]);

  if (isLoading) return <Spinner animation="grow" />;

  return (
    <div>
      <h5 className="my-3 text-light">{t('Assigned Entities')}</h5>
      <DataTable columns={viewAssignedEntitiesTableColumns} data={assignedEntities} />
    </div>
  );
};

export default ViewAssignUsers;
