import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { fetchFavReports } from './favReportSlice';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { removeFavReportApi } from './favReportsApi';
import DeleteConfirmModal from '../../components/DeleteConfirmModal';
import LoadingButton from '../../components/LoadingButton';

const DeleteButton = ({ frName }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isShowConfirmModal, setShowConfirmModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const orgId = useSelector((state) => state.users.user.usrOrgId);

  const handleDelete = async (isForceDelete) => {
    try {
      setIsLoading(true);
      const data = { frName, isForceDelete };
      const result = await removeFavReportApi(orgId, data);
      if (result.success) {
        toast.success(t('Favourite report removed successfully.'));
        dispatch(fetchFavReports({ orgId }));
        history.push(`/reports`);
      } else if (!data.isForceDelete) {
        setShowConfirmModal(true);
      }
    } catch {
      toast.error(t('Unable to delete the favorite report. Please try again.'));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Button
        variant="secondary"
        className="align-items-center justify-content-center"
        onClick={() => handleDelete(false)}
        disabled={isLoading}
      >
        <div className="mr-3"></div>
        <div>{t('Delete')}</div>
      </Button>
      <LoadingButton isLoading={isLoading} className="btn" />
      <DeleteConfirmModal
        isShowModal={isShowConfirmModal}
        isLoading={isLoading}
        handleDeleteAction={() => handleDelete(true)}
        closeConfirmModal={() => setShowConfirmModal(false)}
        messageRender={() => t('Once the report is deleted, the corresponding schedules are consequently removed. Do you confirm to delete this report?')}
      />
    </>
  );
};
export default DeleteButton;
