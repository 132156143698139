import React, { useEffect } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { Controller, useForm, useWatch } from 'react-hook-form';
import * as yup from 'yup';
import Select from 'react-select';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { displayTimeFromSeconds } from '../../utilities/common';
import { setLeavePrompt } from '../manage-users/usersSlice';
import { config } from '../../utilities/config';
import LoadingButton from '../../components/LoadingButton';

const createSchema = yup.object().shape({
  orgWlbId: yup.number().required('This field is a required field'),
  optLocale: yup.string().required('This field is a required field'),
  orgName: yup.string().max(128).required('This field is a required field'),
  usrEmail: yup.string().required('This field is a required field').email('This field is must be a valid email address')
});

const OrganizationForm = ({
  isResendEmail,
  resendDelayCount,
  onResendEmail,
  onSubmit,
  onCancel,
  initialValues = {},
  buttonText = 'Create',
  isEditing = false,
  disabled,
}) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid, isDirty }
  } = useForm({
    defaultValues: {
      ...initialValues,
      optLocale: initialValues?.optLocale || config.DEFAULT_LOCALE
    },
    resolver: yupResolver(createSchema),
    mode: 'onChange'
  });
  const { t } = useTranslation();
  const userInfo = useSelector((state) => state.users.user);
  const orgWlbId = useWatch({
    control,
    name: 'orgWlbId'
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setLeavePrompt(isDirty));
  }, [isDirty, dispatch]);

  const onSubmitHandler = async (data) => {
    try {
      dispatch(setLeavePrompt(false));
      await onSubmit(data);
    } catch (e) {
      dispatch(setLeavePrompt(isDirty));
    }
  };

  const onCancelHandler = () => onCancel();
  const whitelabels = useSelector((state) => state.whitelabels.whitelabels);
  const getWhitelabelOptions = () => {
    if (!whitelabels) return null;
    return whitelabels.map((wl) => {
      return (
        <option
          key={wl.wlbId}
          value={wl.wlbId}
          defaultValue={wl.wlbId === orgWlbId}
        >
          {wl.name}
        </option>
      );
    });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmitHandler)}>
      <Row>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>Organization Name *</Form.Label>
            <Form.Control {...register('orgName')} isInvalid={errors.orgName} />
            <Form.Control.Feedback type="invalid">{errors.orgName?.message}</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>&nbsp;</Form.Label>
            {onResendEmail &&
              <div className="form-control resend-email-container">
                <Button disabled={!isResendEmail || resendDelayCount} variant="info" onClick={onResendEmail}>
                  {isResendEmail === 0 ? <Spinner animation="grow" size="sm" /> : 'Resend activation email'}
                </Button>
                {isResendEmail && resendDelayCount ? <p><em>(You can retry in {displayTimeFromSeconds(resendDelayCount)} minutes)</em></p> : null}
              </div>
            }
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>Description </Form.Label>
            <Form.Control as="textarea" rows="5" style={{ resize: 'none' }} {...register('orgDesc')} />
          </Form.Group>
        </Col>
        <Col></Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>Whitelabel *</Form.Label>
            <Form.Control {...register('orgWlbId')} as="select">
              <>
                {!isEditing ? (
                  <option key={-1} defaultValue="" hidden>
                    Select Whitelabel
                  </option>
                ) : (<></>)}
                {getWhitelabelOptions()}
              </>
            </Form.Control>
            <Form.Control.Feedback type="invalid">{errors.orgWlbId?.message}</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>{t('Language')} *</Form.Label>
            <Controller
              name="optLocale"
              control={control}
              render={({ field }) => {
                const format = (d) => field.onChange(field.value = d.value);
                const localeOptions = userInfo.localeOptions || [];
                return (
                  <Select
                    value={localeOptions.find((a) => field.value === a.value)}
                    options={localeOptions}
                    defaultValue={localeOptions.find((a) => initialValues.optLocale === a.value)}
                    onChange={format}
                    placeholder=""
                  />
                );
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>Email of admin *</Form.Label>
            <Form.Control {...register('usrEmail')} readOnly={isEditing} isInvalid={errors.usrEmail} />
            <Form.Control.Feedback type="invalid">{errors.usrEmail?.message}</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>First Name of admin</Form.Label>
            <Form.Control {...register('usrFirstname')} readOnly={isEditing} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="custom-form-control">
            <Form.Label>Last Name of admin</Form.Label>
            <Form.Control {...register('usrLastname')} readOnly={isEditing} />
          </Form.Group>
        </Col>
      </Row>
      <Button className="mt-3 mb-5" type="submit" disabled={disabled || (!isValid || !isDirty)}>
        {buttonText}
      </Button>
      <Button variant="secondary" className="ml-4 mt-3 mb-5" onClick={onCancelHandler}>
        {t('Cancel')}
      </Button>
      <LoadingButton isLoading={disabled} className="ml-4 mt-3 mb-5" />
    </Form>
  );
};

export default OrganizationForm;
