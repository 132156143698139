import React, { useState } from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { LoadingState } from '../../utilities/constants';
import PageHeader from '../../components/PageHeader';
import Form from './form/EmissionProfileCategoryForm';
import { fetchEmissionProfilesTree } from './emissionProfileSlice';
import { useTranslation } from 'react-i18next';
import { createEmissionProfileCategoryApi } from './emissionProfileApi';
import { toast } from 'react-toastify';
import LoadingButton from '../../components/LoadingButton';

const NewEmissionProfileCategory = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { emissionProfileCategoryId } = useParams();
  const [saveEmissionCategoryState, setSaveEmissionCategoryState] = useState(LoadingState.idle);
  const orgId = useSelector((state) => state.users.user.usrOrgId);

  const handleCreateEmissionProfileCategory = async (data) => {
    try {
      setSaveEmissionCategoryState(LoadingState.loading);
      const result = await createEmissionProfileCategoryApi(orgId, parseInt(emissionProfileCategoryId), data);
      toast.success('New Category created.');
      dispatch(fetchEmissionProfilesTree({ orgId }));
      history.push(`/admin/emission-profile/category/${result.eprflcatId}/edit`);
    } catch (e) {
      setSaveEmissionCategoryState(LoadingState.idle);
      toast.error(e.message);
      throw e;
    }
  };

  const renderActionButtons = ({ isValid, isDirty }) => {
    return (
      <>
        <Button
          type="submit"
          className="mt-3 mb-5"
          disabled={saveEmissionCategoryState !== LoadingState.idle || !isValid || !isDirty}
        >
          {t('Create')}
        </Button>
        <Button variant="secondary" className="ml-4 mt-3 mb-5" onClick={onCancelHandler}>
          {t('Cancel')}
        </Button>
        <LoadingButton isLoading={saveEmissionCategoryState !== LoadingState.idle} className="ml-4 mt-3 mb-5" />
      </>
    );
  };

  const onCancelHandler = () => {
    history.push(`/admin/emission-profile/category/${emissionProfileCategoryId}/edit`);
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <PageHeader title={t('Create Profile Category')} />
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <Form
            buttonText={t('Submit')}
            onSubmit={handleCreateEmissionProfileCategory}
            actionButtons={renderActionButtons}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default NewEmissionProfileCategory;
