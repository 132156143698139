import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import { IoChevronDownOutline } from 'react-icons/io5';

const SecondarySidebar = ({ items = [], onCollapse = () => { } }) => {
  const location = useLocation();
  const cacheKey = 'sidebar-collapsed';
  const [isCollapsed, setIsCollapsed] = useState(!!localStorage.getItem(cacheKey));
  const [isShown, setIsShown] = useState(false);
  let timeId;
  const setIsShownHandler = (val) => {
    if (!isCollapsed) {
      return;
    }
    clearTimeout(timeId);
    timeId = setTimeout(() => setIsShown(val), 500);
  };
  // Set current active path
  const formattedRoutes = items.map((path) => ({ ...path, active: location.pathname.includes(path.path) }));
  const activeName = !isShown && isCollapsed ? 'sidebar-collapsed' : '';
  const hoverName = isShown && isCollapsed ? 'sidebar-hover-collapsed' : '';

  const [accordionState, setAccordionState] = useState(formattedRoutes.map(item =>
    ({ path: item.path, active: item.active })));
  const getAccordionClass = (key) =>
    accordionState.find(item => item.path === key && item.active) ? 'show' : '';
  const toggleAccordionState = (key) => {
    const targetItem = accordionState.find(item => item.path === key);
    targetItem.active = !targetItem.active;
    setAccordionState([...accordionState]);
  };
  useEffect(() => {
    onCollapse(activeName || hoverName);
  }, [activeName, hoverName, onCollapse]);

  return (
    <div
      className={`sidebar sidebar--secondary flex-column flex-shrink-0 ${activeName} ${hoverName}`}
      onMouseEnter={() => setIsShownHandler(true)}
      onMouseLeave={() => setIsShownHandler(false)}
    >
      <span
        className="sidebar-collapser"

      >
        <Button
          variant="icon"
          title="Show or Hide the sidebar"
          onClick={() => {
            setIsShown(false);
            setIsCollapsed(!isCollapsed);
            localStorage.setItem(cacheKey, isCollapsed ? '' : '1');
          }}
        >
          <svg width="24" height="24" viewBox="0 0 24 24" role="presentation">
            <path
              d="M10.294 9.698a.988.988 0 010-1.407 1.01 1.01 0 011.419 0l2.965 2.94a1.09 1.09 0 010 1.548l-2.955 2.93a1.01 1.01 0 01-1.42 0 .988.988 0 010-1.407l2.318-2.297-2.327-2.307z"
              fill="currentColor"
              fillRule="evenodd"
            >
            </path>
          </svg>
        </Button>
      </span>
      <Accordion>
        {formattedRoutes.map((path) => (
          <Card key={path.path}>
            {path.isLink ? (
              <NavLink className="sidebar__link" to={path.path}>
                <Accordion.Toggle
                  className={path.active ? 'sidebar-item__active' : null}
                  as={Card.Header}
                  eventKey={path.path}
                >
                  <span>{path.name}</span>
                </Accordion.Toggle>
              </NavLink>
            ) : (
              <Card.Header
                className={path.active ? 'sidebar-item__active' : null}
                onClick={() => toggleAccordionState(path.path)}
              >
                <span>{path.name}</span> <IoChevronDownOutline />
              </Card.Header>
            )}
            {path.subPaths && path.subPaths.length > 0 && (
              <Accordion.Collapse className={getAccordionClass(path.path)}>
                <Card.Body>
                  <Nav defaultActiveKey={path.subPaths[0]?.path} className="flex-column">
                    {path.subPaths.map((item) => (
                      <NavLink key={item.path} className="sidebar__link" to={item.path}>
                        <div className={`nav-item ${item.pending ? 'pending' : ''}`}>{item.name}</div>
                      </NavLink>
                    ))}
                  </Nav>
                </Card.Body>
              </Accordion.Collapse>
            )}
          </Card>
        ))}
      </Accordion>
    </div>
  );
};

export default SecondarySidebar;
