import { Link } from 'react-router-dom';
import { Card, Button } from 'react-bootstrap';
import { Fragment, useEffect, useState } from 'react';
import { Range, getTrackBackground } from 'react-range';
import { KPIDataCaptureFormModal } from './KPIDataCaptureFormModal';
import { useTranslation } from 'react-i18next';

export function KPIDataCaptureCard({ kpiItem, goalId, goalName, isApplicable }) {
  const { t  } = useTranslation();
  const { kpiName, description, id, latestRecord, setupId } = kpiItem;
  const [rangeValue, setRangeValue] = useState([1]);
  const [timeID, setTimeID] = useState(null);
  const [lastRangeValue, setLastRangeValue] = useState(null);
  const minRange = 1;
  const maxRange = 7;
  const step = 1;

  const [kpiDataFormModalShow, setKpiDataFormModalShow] = useState(false);
  const showKpiModal = () => {
    if (kpiItem.isApplicable !== 'No') {
      setKpiDataFormModalShow(true);
    }
  };
  const handlerModalClose = (isResetValue) => {
    setKpiDataFormModalShow(false);
    if (isResetValue && lastRangeValue) {
      setRangeValue(lastRangeValue);
      setLastRangeValue(null);
    }
  };
  const onChangeHandle = (x) => {
    setRangeValue(x);
    setLastRangeValue(lastRangeValue || rangeValue);
    clearTimeout(timeID);
    setTimeID(setTimeout(showKpiModal, 1000));
  };

  useEffect(() => {
    setRangeValue([Number(latestRecord?.rating || 1)]);
  }, [latestRecord]);

  const stringToPath = (string) => {
    string = string.replace(/\s+/g, '-').toLowerCase();
    return string;
  };

  const renderButton = () => {
    if (latestRecord?.rating) {
      return (
        <Button
          onClick={showKpiModal}
          style={{ marginRight: '10px' }}
          variant='primary'
        >
          {t('START')}
        </Button>
      );
    }
    return (
      <Button
        onClick={showKpiModal}
        style={{ marginRight: '10px' }}
        variant='primary'
      >
        {t('Create First Entry')}
      </Button>
    );
  }
  return (
    <Fragment>
      <Fragment>
        <KPIDataCaptureFormModal
          key={kpiItem.id}
          kpiName={kpiItem.kpiName}
          kpiDesc={description}
          kpiDataFormModalShow={kpiDataFormModalShow}
          handlerModalClose={handlerModalClose}
          rating={lastRangeValue ? rangeValue[0] : latestRecord?.rating || '1'}
          setupId={setupId}
          isApplicable={isApplicable}
        />
      </Fragment>

      {kpiItem && (
        <Card className={`mb-3 kpi-card ${kpiItem.isApplicable === 'No' ? 'bg-light' : ''}`}>
          <Card.Header>
            <div>
              <span className='kpi-version-label'>{t(kpiName)}</span>
              <span className='not-applicable'>{kpiItem.isApplicable === 'No' && <b> {t('Not applicable')}</b>}</span>
            </div>
          </Card.Header>
          <Card.Body>
            <p className='description mt-2'>{t(description)}</p>
            <div
              className='kpi-card-slider mt-4'
            >
              <Range
                disabled={false}
                style={{ zIndex: '1000' }}
                key={`${goalId}-${id}`}
                values={rangeValue}
                step={step}
                min={minRange}
                max={maxRange}
                onChange={onChangeHandle}
                renderTrack={({ props, children }) => (
                  <div
                    // onMouseDown={props.onMouseDown}
                    onTouchStart={props.onTouchStart}
                    style={{
                      ...props.style,
                      height: '36px',
                      display: 'flex',
                      width: '100%'
                    }}
                  >
                    <div
                      ref={props.ref}
                      style={{
                        height: '8px',
                        width: '100%',
                        borderRadius: '4px',
                        background: getTrackBackground({
                          values: rangeValue,
                          colors: ['#092159', '#c9d3de'],
                          min: minRange,
                          max: maxRange
                        }),
                        alignSelf: 'center'
                      }}
                    >
                      {children}
                    </div>
                  </div>
                )}
                renderThumb={({ props, isDragged }) => (
                  <div
                    {...props}
                    style={{
                      ...props.style,
                      height: '20px',
                      width: '20px',
                      borderRadius: '50%',
                      backgroundColor: '#092159',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <div
                      style={{
                        position: 'absolute',
                        top: '-18px',
                        color: '#102A43',
                        fontWeight: '600',
                        fontSize: '12px'
                      }}
                    >
                      {rangeValue}
                    </div>
                  </div>
                )}
              />
            </div>
            {renderButton()}
            {
              latestRecord?.rating && <Link
                disabled={!latestRecord?.rating}
                size='sm'
                to={{ pathname: `/my-disclosure/sdgs/${stringToPath(goalName)}/${stringToPath(kpiName)}/${setupId}` }}
                className='btn btn-secondary'
              >
                {t('View Progress')}
              </Link>
            }
          </Card.Body>
        </Card>
      )}
    </Fragment>
  );
}
