import React, { useEffect, useMemo } from 'react';
import { Button, Col, Container, Row, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import { AiOutlineEdit } from 'react-icons/ai';
import { IoEyeOutline } from 'react-icons/io5';
import { FiDownload } from 'react-icons/fi';

import PageHeader from '../../components/PageHeader';
import { LoadingState } from '../../utilities/constants';
import { fetchAssets } from './assetsSlice';
import DataTable from '../../components/DataTable';
import { useTranslation } from 'react-i18next';
import { exportCsv } from '../../utilities/common';

const ManageAssets = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const match = useRouteMatch();
  const assetStatus = useSelector((state) => state.assets.status);
  const assets = useSelector((state) => state.assets.assets);
  const orgId = useSelector((state) => state.users.user.usrOrgId);
  const loggedUserStatus = useSelector((state) => state.users.loggedUserStatus);

  const tableColumns = useMemo(
    () => [
      {
        Header: t('Asset ID'),
        accessor: 'assetAssignedId'
      },
      {
        Header: t('Asset Name'),
        accessor: 'assetName',
        sortType: 'string'
      },
      {
        Header: t('Description'),
        accessor: 'assetDesc',
        sortType: 'string'
      },
      {
        Header: t('Assigned Entities'),
        accessor: 'assignedEntities',
        sortType: 'string'
      },
      {
        Header: t('Status'),
        accessor: (row) => {
          return row.assetEnabled === 'Y' ? 'Active' : 'Inactive';
        }
      },
      {
        Header: '',
        accessor: 'actions',
        actionColumn: true,
        disableGlobalFilter: false,
        disableSortBy: true,
        Cell: ({ row }) => {
          const { original } = row;
          return (
            <div className="action-buttons">
              <Button
                to={`${match.url}/${original.assetId}`}
                size="sm"
                className="mr-1"
                variant="outline-secondary"
                as={Link}
              >
                <IoEyeOutline />
              </Button>
              <Button
                to={`${match.url}/${original.assetId}/edit`}
                size="sm"
                className="mr-1"
                variant="outline-secondary"
                as={Link}
              >
                <AiOutlineEdit />
              </Button>
            </div>
          );
        }
      }
    ],
    [match.url, t]
  );

  const downloadAssetsHandler = () => {
    const rows = assets.map((item, idx) => ([
      item.assetAssignedId,
      item.assetName,
      item.assetDesc,
      `"${String(item.assignedEntities).replace(/"/g, '')}"`,
      item.assetEnabled === 'Y' ? 'Active' : 'Inactive',
    ])).sort((x,y) => x[0] > y[0] ? 1 : -1);
    rows.unshift([
      t('Asset ID'),
      t('Asset Name'),
      t('Description'),
      t('Assigned Entities'),
      t('Status'),
    ]);
    exportCsv(rows, 'organization-assets.csv');
  };

  useEffect(() => {
    if (loggedUserStatus === LoadingState.succeeded)
      dispatch(fetchAssets({ orgId }));
  }, [orgId, loggedUserStatus, dispatch]);

  return (
    <Container fluid>
      <Row>
        <Col>
          <PageHeader
            title={t('Manage Assets')}
            actions={
              <>
                <Button to={`${match.url}/new`} as={Link} className="mr-2">
                  + {t('Create Asset')}
                </Button>
                <Button onClick={downloadAssetsHandler} variant="secondary">
                  <FiDownload /> {t('Download')}
                </Button>
              </>
            }
          />
        </Col>
      </Row>
      {assetStatus === LoadingState.loading && <Spinner animation="grow" />}
      {assetStatus === LoadingState.succeeded && (
        <Row>
          <Col>
            <DataTable columns={tableColumns} data={assets} />
          </Col>
        </Row>
      )}
      {assetStatus === LoadingState.failed && <div>Something Went Wrong</div>}
    </Container>
  );
};

export default ManageAssets;
