import { unwrapResult } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAssignedEntities, getAvailableEntities, assignMultiEntities } from './emissionProfileSlice';
import DataMultiAssignment from '../../components/DataMultiAssignment';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import _ from "lodash";
import {fetchAssignedEntitiesForEG, fetchEntityGroups} from "../entity-groups/entityGroupsSlice";
import Select from 'react-select';
import {Form} from "react-bootstrap";

const AssignEntities = ({ emissionProfileSourceId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState();
  const availableEntities = useSelector((state) => state.emissionProfiles.availableEntities);
  const assignedEntities = useSelector((state) => state.emissionProfiles.assignedEntities);
  const entityGroups = useSelector((state) => state.entityGroups.entityGroups);
  const orgId = useSelector((state) => state.users.user.usrOrgId);

  const handleAssignData = async (actionType, divIds) => {
    try {
      setIsLoading(true);
      const data = { type: actionType === 'Assign' ? 'ADD' : 'REMOVE', divIds };
      const resultAction = await dispatch(assignMultiEntities({ orgId, emissionProfileSourceId, ...data }));
      const errorEntities = resultAction.payload
        .filter(item => !item.success)
        .map((item) => {
          const entity = availableEntities.find(div => div.divId === item.divId) 
            || assignedEntities.find(div => div.divId === item.divId);
          return entity?.divName || 'Unknown';
        });
      unwrapResult(resultAction);
      if (errorEntities?.length) {
        const msg = 'The following entities could not be unassigned because there is ‘Usage’ data recorded against them';
        toast.warn(`${msg}: ${errorEntities.join(', ')}`); 
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  const getGroupsOptions = () => {
    if (!entityGroups) {
      return [];
    }
    let selectOpts = entityGroups.map((entityGroup) => {
      return {
        key: entityGroup.egId,
        label: entityGroup.egName,
        value: entityGroup.egId
      };
    });
    selectOpts = _.orderBy(selectOpts, 'label', 'asc');
    return selectOpts;
  };

  const onGroupSelectedChange = async (data, setSelectedItems) => {
    setSelectedGroup(data)
    if(!assignedGroup[data.key]) {
      const results = await dispatch(fetchAssignedEntitiesForEG({ orgId, egId: data.key }));
      const unwrapData = unwrapResult(results);
      assignedGroup[data.key] = (unwrapData || []).map(d => d.divId)
    }

    const assignIds = (availableEntities || [])
        .filter((item) => assignedGroup[data.key].indexOf(item.divId) !== -1)
        .map((item) => item.divId);
    const unassignIds = (assignedEntities || [])
        .filter((item) => assignedGroup[data.key].indexOf(item.divId) !== -1)
        .map((item) => item.divId);
    setSelectedItems({ Assign: assignIds, Unassign: unassignIds });

  };
  const assignedGroup = {};
  const groupSelectionRender = (setSelectedItems) => {
    return (
        <Form.Group className="custom-form-control mx-1">
          <Form.Label>{t('Select by Entity Group')}</Form.Label>
          <Select
              name="entitiesGroup"
              value={selectedGroup}
              options={getGroupsOptions()}
              onChange={(e) => onGroupSelectedChange(e, setSelectedItems)}
              placeholder={t('Select an Entity Group')}
          />
        </Form.Group>
    )
  }
  useEffect(() => {
    dispatch(getAvailableEntities({ orgId, emissionProfileSourceId }));
    dispatch(getAssignedEntities({ orgId, emissionProfileSourceId }));
    dispatch(fetchEntityGroups({ orgId }));
  }, [orgId, emissionProfileSourceId, dispatch]);

  return (
    <DataMultiAssignment
      isLoading={isLoading}
      multiTitle={t("Entities")}
      singleTitle={t("Entity")}
      dataKey="divId"
      dataSearch="divAssignedId"
      dataName="divName"
      availableData={availableEntities}
      assignedData={assignedEntities}
      handleAssignData={handleAssignData}
      groupSelectionRender={ groupSelectionRender}
    />
  );
};

export default AssignEntities;
