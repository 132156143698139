import { config } from '../../utilities/config';
import keycloak from '../../keycloak';
import { uploadToS3 } from '../../utilities/apiHelper';

export async function fetchEntityTreeApi(organizationId) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/entity/tree`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    }
  });
  const data = await response.json();
  if (!response.ok) throw new Error(data.message);
  return data;
}

export async function createEntityApi(organizationId, parentId, data) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/entity/${parentId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function fetchAssignedAssetsApi(organizationId, entityId) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/entity/${entityId}/asset`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    }
  });
  const data = await response.json();
  if (!response.ok) throw new Error(data.message);
  return data;
}

export async function fetchAvailableAssetsApi(organizationId, entityId) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/entity/${entityId}/available-asset`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    }
  });
  const data = await response.json();
  if (!response.ok) throw new Error(data.message);
  return data;
}

export async function assignAssetApi(organizationId, entityId, data) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/entity/${entityId}/asset`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function editEntityApi(organizationId, entityId, data) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/entity/${entityId}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function removeEntityApi(organizationId, entityId) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/entity/${entityId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    }
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function unAssignAssetApi(organizationId, entityId, assetId) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/entity/${entityId}/asset/${assetId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    }
  });
  if (!response.ok) {
    const result = await response.json();
    throw new Error(result.message);
  }
}

export async function fetchDocumentsApi(organizationId, entityId) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/entity/${entityId}/attachment`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    }
  });
  const data = await response.json();
  if (!response.ok) throw new Error(data.message);
  return data;
}

export async function createDocumentApi(organizationId, entityId, data) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/entity/${entityId}/attachment`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${keycloak.token}`
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function uploadDocumentApi(organizationId, entityId, file) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/entity/${entityId}/attachment/generate`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    }
  });
  const signedPost = await response.json();
  if (!response.ok) throw new Error(signedPost.message);
  await uploadToS3(signedPost, file);
  return { key: signedPost.fields.key, name: file.name };
}

export async function generateAttachmentDownloadUrlApi(organizationId, entityId, uploadedFileId) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/entity/${entityId}/attachment/${uploadedFileId}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    }
  });
  const data = await response.json();
  if (!response.ok) throw new Error(data.message);
  return data;
}

export async function deleteAttachmentApi(organizationId, entityId, fileId) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/entity/${entityId}/attachment/${fileId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    }
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function fetchEmissionProfileTreeByEntityApi(organizationId, entityId) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/entity/${entityId}/emission-profile/tree`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    }
  });
  const data = await response.json();
  if (!response.ok) throw new Error(data.message);
  return data;
}

export async function fetchAssignedUsersToSourcesApi(organizationId, entityId, epsId) {
  const response = await fetch(
    `${config.API_BASE_URL}/${organizationId}/entity/${entityId}/emission-profile/emission-profile-sources/${epsId}/assigned-users`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${keycloak.token}`
      }
    }
  );
  const data = await response.json();
  if (!response.ok) throw new Error(data.message);
  return data;
}

export async function changeEntityParent(organizationId, entityId, params) {
  const response = await fetch(
    `${config.API_BASE_URL}/${organizationId}/entity/${entityId}/changes-parent`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${keycloak.token}`
      },
      body: JSON.stringify(params)
    }
  );
  const data = await response.json();
  if (!response.ok) throw new Error(data.message);
  return data;
}

export async function fetchAvailableUsersToSourcesApi(organizationId, entityId, epsId) {
  const response = await fetch(
    `${config.API_BASE_URL}/${organizationId}/entity/${entityId}/emission-profile/emission-profile-sources/${epsId}/available-users`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${keycloak.token}`
      }
    }
  );
  const data = await response.json();
  if (!response.ok) throw new Error(data.message);
  return data;
}

export async function assignUserToSourceApi(organizationId, entityId, epsId, userId) {
  const response = await fetch(
    `${config.API_BASE_URL}/${organizationId}/entity/${entityId}/emission-profile/emission-profile-sources/${epsId}/assigned-users/${userId}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${keycloak.token}`
      }
    }
  );
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function unAssignUserToSourceApi(organizationId, entityId, epsId, userId) {
  const response = await fetch(
    `${config.API_BASE_URL}/${organizationId}/entity/${entityId}/emission-profile/emission-profile-sources/${epsId}/assigned-users/${userId}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${keycloak.token}`
      }
    }
  );
  if (!response.ok) {
    const result = await response.json();
    throw new Error(result.message);
  }
}
