import { Button, Form, Modal } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { recursiveMap } from '../../../utilities/treeHelpers';
import TreeView from '../../../components/TreeView';
import { useTranslation } from 'react-i18next';
import NodeIcon from '../../../components/NodeIcon';

export function TreeViewSelector({
  options,
  label,
  selectedEntity,
  setSelectedEntity,
  selectedEPS,
  setSelectedEPS,
  selectedAsset,
  setSelectedAsset,
  disabled,
}) {
  const { t } = useTranslation();

  const [treeState, setTreeState] = useState({ isSearching: false, action: null, payload: {} });
  const [showEntitySelector, setShowEntitySelector] = useState(false);
  const closeModal = () => setShowEntitySelector(false);
  const openModal = () => setShowEntitySelector(true);

  useEffect(() => {
    closeModal();
  }, []);

  const EmissionProfileSourceItem = ({ emissionProfileSource }) => {
    const labelName =
      emissionProfileSource.eprflName ?? emissionProfileSource.eprflcatName ?? emissionProfileSource.eprflsrcName;
    const handleSelectEPS = () => {
      try {
        const epsId =
          emissionProfileSource.eprflId ?? emissionProfileSource.eprflcatId ?? emissionProfileSource.eprflsrcId;
        const index = selectedEPS.findIndex((value) => value === epsId);
        if (index === -1) {
          selectedEPS.push(epsId);
          addRecursiveChild(emissionProfileSource, false);
          setSelectedEPS([...selectedEPS]);
        } else {
          selectedEPS = selectedEPS.filter(function (e) {
            return e !== epsId;
          });
          removeRecursiveChild(emissionProfileSource, false);
          setSelectedEPS([...selectedEPS]);
        }
      } catch (e) {
        setSelectedEPS([]);
      }
    };
    return (
      <span onClick={handleSelectEPS}>
        {emissionProfileSource.eprflEstdId ? null : emissionProfileSource.eprflEstdId === null ? null : (
          <NodeIcon node={emissionProfileSource} />
        )}
        {labelName}
      </span>
    );
  };

  const EntityItem = ({ entity }) => {
    const handleSelectEntity = () => {
      try {
        const index = selectedEntity.findIndex((value) => value === entity.divId);
        if (index === -1) {
          selectedEntity.push(entity.divId);
          addRecursiveChild(entity, true);
          setSelectedEntity([...selectedEntity]);
        } else {
          selectedEntity = selectedEntity.filter(function (e) {
            return e !== entity.divId;
          });
          removeRecursiveChild(entity, true);
          setSelectedEntity([...selectedEntity]);
        }
      } catch (e) {
        setSelectedEntity([]);
      }
    };
    return <span className={`flex-grow-1 ${entity.divIsAssigned ? "enabled": "disabled"}`} style={{ cursor: entity.divIsAssigned ? 'pointer' : 'none' }} onClick={() => entity.divIsAssigned ? handleSelectEntity(entity.divId) : null}>{entity.divName}</span>;
  };

  const AssetItem = ({ asset }) => {
    const handleSelectAsset = () => {
      try {
        const index = selectedAsset.findIndex((value) => value === asset.assetId);
        if (index === -1) {
          selectedAsset.push(asset.assetId);
          setSelectedAsset([...selectedAsset]);
        } else {
          selectedAsset = selectedAsset.filter(function (e) {
            return e !== asset.assetId;
          });
          setSelectedAsset([...selectedAsset]);
        }
      } catch (e) {
        setSelectedAsset([]);
      }
    };
    return <span onClick={handleSelectAsset}>{asset.assetName}</span>;
  };

  function addRecursiveChild(node, isFromEntity) {
    if (node.children) {
      node.children.forEach(function (child) {
        if (isFromEntity) {
          const index = selectedEntity.findIndex((value) => value === child.divId);
          if (index === -1) {
            selectedEntity.push(child.divId);
          }
          addRecursiveChild(child, true);
        } else {
          const epsId = child.eprflId ?? child.eprflcatId ?? child.eprflsrcId;

          const index = selectedEPS.findIndex((value) => value === epsId);
          if (index === -1) {
            selectedEPS.push(epsId);
          }
          addRecursiveChild(child, false);
        }
      });
    }
  }

  function removeRecursiveChild(node, isFromEntity) {
    if (node.children) {
      node.children.forEach(function (child) {
        if (isFromEntity) {
          selectedEntity = selectedEntity.filter(function (e) {
            return e !== child.divId;
          });
          removeRecursiveChild(child, true);
        } else {
          const epsId = child.eprflId ?? child.eprflcatId ?? child.eprflsrcId;

          selectedEPS = selectedEPS.filter(function (e) {
            return e !== epsId;
          });
          removeRecursiveChild(child, false);
        }
      });
    }
  }

  function generateHandlerObject(node) {
    if (node.divId)
      return {
        value: node.divId,
        valueId: node.divAssignedId,
        labelRaw: node.divName,
        label: <EntityItem entity={node} />,
        isAssigned: node.divIsAssigned ?? false,
        isActive: selectedEntity.some((arrVal) => node.divId === arrVal)
      };
    if (node.eprflId)
      return {
        weight: node.weight,
        value: node.eprflId,
        labelRaw: node.eprflName,
        label: <EmissionProfileSourceItem emissionProfileSource={node} />,
        isActive: selectedEPS.some((arrVal) => node.eprflId === arrVal)
      };
    if (node.eprflsrcId)
      return {
        weight: node.weight,
        value: node.eprflsrcId,
        valueId: node.eprflsrcAssignedId,
        labelRaw: node.eprflsrcName,
        label: <EmissionProfileSourceItem emissionProfileSource={node} />,
        isActive: selectedEPS.some((arrVal) => node.eprflsrcId === arrVal)
      };
    if (node.eprflcatId)
      return {
        weight: node.weight,
        value: node.eprflcatId,
        labelRaw: node.eprflcatName,
        label: <EmissionProfileSourceItem emissionProfileSource={node} />,
        isActive: selectedEPS.some((arrVal) => node.eprflcatId === arrVal)
      };
    if (node.assetId)
      return {
        value: node.assetId,
        valueId: node.assetAssignedId,
        labelRaw: node.assetName,
        label: <AssetItem asset={node} />,
        isActive: selectedAsset.some((arrVal) => node.assetId === arrVal)
      };
  }

  const transformedTree = recursiveMap(options ?? {}, (n) => generateHandlerObject(n));
  transformedTree.isRoot = true;

  return (
    <div className="configuration-col selector">
      <Form.Group className="custom-form-control">
        <Form.Label className="d-flex">{label}</Form.Label>
        <Button variant="secondary" onClick={openModal} disabled={!!disabled}>
          {t('Select')}
        </Button>
      </Form.Group>
      <Modal show={showEntitySelector} onHide={closeModal} className="side-modal">
        <Modal.Header>
          <Modal.Title>
            {label === 'Entity' && t("Select Entity")}
            {label === 'Emission Source' && t('Select Emission Profile')}
            {label === 'Asset' && t('Select Asset')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TreeView
            tree={transformedTree}
            treeState={treeState}
            setTreeState={setTreeState}
            searchFunction={(n, searchText) => {
              if (n.labelRaw) return n.labelRaw.toLowerCase().includes(searchText.toLowerCase())
                  || (n.valueId && n.valueId.toLowerCase().includes(searchText.toLowerCase()));
            }}
            enableSearch
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}
