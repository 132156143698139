import * as yup from 'yup';

export const createUserValidateSchema = (t) => {
  const createUserSchema = yup.object().shape({
    usrName: yup.string(),
    usrEmail: yup
      .string()
      .email(t('You have entered an invalid email address. Please try again'))
      .required(t('Email is required')),
    usrFirstname: yup.string().max(128),
    usrLastname: yup.string().max(128),
    usrEnabled: yup.string(),
    optLocale: yup.string(),
    usrRole: yup.string().required('User role is required'),
    usrPassword: yup
      .string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
        t('Must contain 8 characters, one uppercase, one lowercase, one number and one special case character')
      )
      .required(),
    usrVerifyPassword: yup.string().oneOf([yup.ref('usrPassword'), null], t('Passwords must match')),
    usrMobileNumber: yup.string()
      .transform(value => !value ? null : value)
      .nullable()
      .matches(/^\+[1-9]{1}[0-9]{3,14}$/, t('Please enter a valid international mobile number. For example: +61282083366')),
    usrTemporaryPassword: yup.string()
  });

  const editUserSchema = createUserSchema.shape({
    usrChangePassword: yup.string(),
    optLocale: yup.string().nullable(),
    usrPassword: yup.string().when('usrChangePassword', {
      is: 'Y',
      then: yup
        .string()
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          t('Must contain 8 characters, one uppercase, one lowercase, one number and one special case character')
        )
        .required(t('Password is required'))
    }),
    usrMobileNumber: yup.string()
      .transform(value => !value ? null : value)
      .nullable()
      .matches(/^\+[1-9]{1}[0-9]{3,14}$/, t('Please enter a valid international mobile number. For example: +61282083366')),
    usrVerifyPassword: yup.string().oneOf([yup.ref('usrPassword'), null], t('Passwords must match')),
    usrTemporaryPassword: yup.string()
  });
  return { createUserSchema, editUserSchema };
};
