import React, { useState } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import SecondarySidebar from '../../components/Sidebar/SecondarySidebar';
import RouteNotFound from '../../RouteNotFound';
import { ManageDisclosure } from './ManageDisclosure';
import { ManageSDGs } from './sdgs/ManageSDGs';
import { KpiDetails } from './disclosure-components/KpiDetails';
import ManageCoriolis from './coriolis/ManageCoriolis';

const DisclosureHome = () => {
  const [expandedClass, setExpandedClass] = useState('');
  const match = useRouteMatch();
  const { t } = useTranslation();

  const routes = [
    {
      path: `${match.url}/disclosure`,
      name: t('Disclosure'),
      isLink: true
    },
    {
      path: `${match.url}/sdgs`,
      name: t('SDGs'),
      isLink: true
    },
    {
      path: `${match.url}/coriolis`,
      name: t('Coriolis'),
      isLink: true
    }
  ];

  return (
    <div className="d-flex flex-row mw-100">
      <SecondarySidebar
        items={routes}
        onCollapse={(isExpanded) => setExpandedClass(isExpanded ? 'offset-expanded' : '')}
      />
      <div className={`offset-second ${expandedClass}`}>
        <Switch>
          <Route exact path={`${match.path}/disclosure`}>
            <ManageDisclosure />
          </Route>
          <Route exact path={`${match.path}/sdgs/:goalName/:kpiName/:kpiSetupId`}>
            <KpiDetails />
          </Route>
          <Route path={`${match.path}/sdgs`}>
            <ManageSDGs />
          </Route>
          <Route path={`${match.path}/coriolis`}>
            <ManageCoriolis />
          </Route>
          <Route exact path={match.path}>
            <Redirect to={`${match.path}/disclosure`} />
          </Route>
          <Route path="*">
            <RouteNotFound />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default DisclosureHome;
