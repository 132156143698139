import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { LoadingState } from '../../utilities/constants';
import {
  createTranslationBundleApi,
  createTranslationValueApi,
  getTranslationBundlesApi,
  getTranslationGroupsApi,
  getTranslationValuesByGroupApi,
  updateTranslationValueApi
} from './translationsApi';

export const getTranslationBundles = createAsyncThunk(
  'translations/getTranslationBundles',
  async (payload, { dispatch, getState }) => {
    try {
      const response = await getTranslationBundlesApi(payload.orgId);
      await dispatch(getTranslationGroups());
      const state = getState();
      await dispatch(
        getTranslationValuesByGroup({
          translationBundleId: response[0].uiresbndlId,
          groupId: state.translations.translationGroups[0].uiresgrpId,
          orgId: payload.orgId
        })
      );
      return response;
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  }
);

export const getTranslationGroups = createAsyncThunk(
  'translations/getTranslationGroups',
  async (payload, { dispatch }) => {
    try {
      const response = await getTranslationGroupsApi();
      return response;
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  }
);

export const getTranslationValuesByGroup = createAsyncThunk(
  'translations/getTranslationValues',
  async (payload, { dispatch }) => {
    const { translationBundleId, groupId, orgId } = payload;
    try {
      const response = await getTranslationValuesByGroupApi(orgId, translationBundleId, groupId);
      return response;
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  }
);

export const createTranslationValue = createAsyncThunk(
  'translations/createTranslationValue',
  async (payload, { dispatch }) => {
    const { groupId, data, orgId } = payload;
    try {
      await createTranslationValueApi(orgId, data);
      dispatch(getTranslationValuesByGroup({ orgId, translationBundleId: data.uiresBundleId, groupId }));
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  }
);

export const updateTranslationValue = createAsyncThunk(
  'translations/updateTranslationValue',
  async (payload, { dispatch }) => {
    const { translationValueId, translationBundleId, groupId, data, orgId } = payload;
    try {
      await updateTranslationValueApi(orgId, translationValueId, data);
      dispatch(getTranslationValuesByGroup({ orgId, translationBundleId, groupId }));
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  }
);

export const createTranslationBundle = createAsyncThunk(
  'translations/createTranslationBundle',
  async (payload, { dispatch }) => {
    const { data, orgId } = payload;
    try {
      await createTranslationBundleApi(orgId, data);
      toast.success(`Successfully added bundle "${data.uiresbndlValue}"`);
      dispatch(getTranslationBundles({ orgId }));
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  }
);

const translationsSlice = createSlice({
  name: 'translations',
  initialState: {
    translationBundles: [],
    translationGroups: [],
    translationValues: [],
    status: LoadingState.idle,
    translationValuesState: LoadingState.idle,
    error: null
  },
  reducers: {},
  extraReducers: {
    [getTranslationBundles.pending]: (state) => {
      state.status = LoadingState.loading;
    },
    [getTranslationBundles.fulfilled]: (state, action) => {
      state.status = LoadingState.succeeded;
      state.translationBundles = action.payload;
    },
    [getTranslationBundles.rejected]: (state, action) => {
      state.status = LoadingState.failed;
      state.error = action.error.message;
    },
    [getTranslationGroups.pending]: (state) => {
      state.status = LoadingState.loading;
    },
    [getTranslationGroups.fulfilled]: (state, action) => {
      state.translationGroups = action.payload;
    },
    [getTranslationGroups.rejected]: (state, action) => {
      state.status = LoadingState.failed;
      state.translationGroups = action.error.message;
    },
    [getTranslationValuesByGroup.pending]: (state) => {
      state.translationValuesState = LoadingState.loading;
    },
    [getTranslationValuesByGroup.fulfilled]: (state, action) => {
      state.translationValuesState = LoadingState.succeeded;
      state.translationValues = action.payload;
    }
  }
});

export default translationsSlice.reducer;
