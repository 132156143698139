import { config } from '../../utilities/config';
import keycloak from '../../keycloak';

export async function fetchOrganizationsApi() {
  const response = await fetch(`${config.API_BASE_URL}/organization/list`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    }
  });
  const data = await response.json();
  if (!response.ok) throw new Error(data.message);
  return data;
}

export async function fetchEmissionProfilesTreeApi(organizationId) {
  const url = `organization/${organizationId}/emission-profile/tree`;
  const response = await fetch(`${config.API_BASE_URL}/${url}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    }
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function createOrganizationApi(data) {
  const response = await fetch(`${config.API_BASE_URL}/organization/create`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function editOrganizationApi(id, data) {
  const response = await fetch(`${config.API_BASE_URL}/organization/${id}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  if (!response.ok) throw new Error(result.message);
  return result;
}

export async function updateReportPeriod(organizationId, data) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/settings/report-period`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  if (!response.ok) throw new Error(response.statusText);
  return result;
}

export async function updateReportUnit(organizationId, data) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/settings/report-units`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  if (!response.ok) throw new Error(response.statusText);
  return result;
}

export async function fetchReportUnitsApi(organizationId) {
  const response = await fetch(`${config.API_BASE_URL}/${organizationId}/settings/report-units`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`
    }
  });
  const data = await response.json();
  if (!response.ok) throw new Error(data.message);
  return data;
}
